import React from 'react';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Sidebar } from '../components';
import Buildings from '../views/Locations/Buildings.js';
import { draftValidator, saveUIParams } from '../../common/ui/actions';
import theme from '../assets/css/theme';
import withRouterHOC from '../components/Router/util/withRouterHOC.js';

class SidebarBuilding extends React.Component {
	constructor(props) {
		super(props);
		this.onLocationClick = this.onLocationClick.bind(this);
	}

	componentDidMount() {
		this.props.saveUIParams({ sidebarLoaded: new Date().getTime() });
	}

	onLocationClick(locationsIds) {
		const { selectedProjectId, location, contentType, draftValidator, history, onChange } = this.props;
		const searchParams = new URLSearchParams(location?.search);
		if (onChange) {
			['buildingId', 'floorId', 'unitId'].forEach(key => {
				if (locationsIds[key]) {
					searchParams.set(key, locationsIds[key]);
				}
			});
			history.push({
				pathname: location.pathname,
				search: '?' + searchParams.toString(),
			});
			return onChange(locationsIds);
		}

		let locationPageURL = 'locationContainerPage';
		let query = location.getNested(['search'], '');
		let params = selectedProjectId + '/' + contentType + '/' + locationPageURL;
		['buildingId', 'floorId', 'unitId'].forEach(key => {
			params += '/' + (locationsIds[key] ? locationsIds[key] : '_');
		});

		const url = '/main/projectContainerPage/' + params + query;

		draftValidator(() => history.push(url));
	}

	render() {
		const {
			intl,
			rtl,
			marginTop,
			showBadges,
			style,
			filteredPosts,
			navigationParams: navigationParamsProp,
			selectedLocation,
			location,
		} = this.props;
		const searchParams = Object.fromEntries(new URLSearchParams(location.search));
		
		const navigationParams = {
			...navigationParamsProp,
			...selectedLocation,
			...searchParams,
		};

		return (
			<Sidebar
				intl={intl}
				rtl={rtl}
				style={style || {}}
				mainCompontent={
					<Buildings
						contentType={navigationParams.getNested(['contentType'])}
						selectedLocation={{
							buildingId: navigationParams.getNested(['buildingId']) == '_' ? null : navigationParams.getNested(['buildingId']),
							floorId: navigationParams.getNested(['floorId']) == '_' ? null : navigationParams.getNested(['floorId']),
							unitId: navigationParams.getNested(['unitId']) == '_' ? null : navigationParams.getNested(['unitId']),
						}}
						showBadges={showBadges}
						style={{
							marginTop,
							visibility: 'visible',
							width: '100%',
							height: 'inherit',
							backgroundColor: theme.backgroundColor,
						}}
						maxUnitsInFloor={4}
						onClick={this.onLocationClick}
						filteredPosts={filteredPosts}
					/>
				}
			/>
		);
	}
}

const WrappedSidebarBuilding = withRouterHOC(SidebarBuilding);
const enhance = compose(
	withRouterHOC,
	connect(
		null,
		{ saveUIParams, draftValidator },
	),
);
export default enhance(WrappedSidebarBuilding);
