import React from 'react';
import trash from '../../assets/img/icons/trash.png';
import { v4 as uuidV4 } from 'uuid';

/**
 * @typedef {{ icon?: string, style?: object, hoverStyle?: object, onClick?: () => void, component?: React.ReactNode }} Icon
 * @typedef EditStripProps
 * @property {boolean} [showIcon] - Wether to show the icons in the strip or not
 * @property {Icon[]} [icons]
 * @property {{}} [style]
 */
/**
 * @extends {React.Component<EditStripProps>}
 */
class EditStrip extends React.Component {
	constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.uniqueComponentId = uuidV4();
		this.state = {
      hoverIndex: null,
    };
  }

  setHover(index) {
    this.setState({ hoverIndex: index })
  }

	render() {
    const { icons, style = {}, showIcon = true } = this.props;
    const { hoverIndex } = this.state;
    
    const { stripContainer, iconStyle, iconHoverStyle } = styles;

    icons.loopEach((i, icon) => {
      if (icon.icon === trash)
        icon._style = { transform: 'translateY(-0.7px)' };
    });

    return (
			<>
      <div style={{ ...stripContainer, ...(style || {})}}>
        {(icons || []).map(({ icon, style = {}, _style = {}, hoverStyle = {}, onClick = () => {}, component = null }, index) => (
          <div
            key={`${this.uniqueComponentId}-${index}`}
            onMouseLeave={() => this.setHover(null)}
            onMouseEnter={() => this.setHover(index)}  
            style={{ ...iconStyle, opacity: 0.3, transition: 'opacity 0.1s', ...(showIcon ? {} : {visibility: 'hidden'}), ...style, ...(hoverIndex === index ? { ...iconHoverStyle, ...hoverStyle  } : {})  }}
          >
            {Boolean(component)
              ? component
              : (
              <img
                key={'editStripItem-' + index}
                style={{ height: '100%', ..._style }} 
                src={icon} 
                onClick={e => {e.stopPropagation(); onClick(e);}}
              />
            )}
          </div>
        ))}
      </div>
      
			</>
    );
	}
}

const styles = {
  stripContainer: {
    height: 'min-content',
    width: 'min-content',
    display: 'flex',
    alignItems: 'center',
  },
  iconStyle: {
    cursor: 'pointer',
    height: 15,
    margin: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconHoverStyle: {
    opacity: 0.7,
  },
}

export default EditStrip;
