import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';

import sidebarStyle from '../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import pagesHeaderStyle from '../../assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx';
import logo from '../../assets/img/logo.png';
import withRouterHOC from '../Router/util/withRouterHOC.js';

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) this.setState({ open: false });
  }
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({ [' ' + classes[color]]: color });

    var list = null;
    return (
      <AppBar position='static' className={classes.appBar + appBarClasses}>
        <div>
          <Hidden smDown>
            <div className={classes.flex} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <a href='https://www.cemento.ai/'>
                <img src={logo} alt='logo' className={classes.img} />
              </a>
            </div>
          </Hidden>
          <Hidden mdUp>{list}</Hidden>
          <Hidden smDown>{list}</Hidden>
        </div>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

PagesHeader = withStyles(sidebarStyle)(PagesHeader);
export default withStyles(pagesHeaderStyle)(withRouterHOC(PagesHeader));
