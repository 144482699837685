import React, { useCallback, useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import * as propertyTypes from '../../common/propertiesTypes/propertiesTypes';
import { handleSelectionListChange } from '../../common/app/standardInputFuncs';
import { injectIntl } from '../app/myInjectIntl';


const PROPERTIES_VIEW_TYPES = propertyTypes.PROPERTIES_VIEW_TYPES

/**
 * @typedef {PROPERTIES_VIEW_TYPES[keyof PROPERTIES_VIEW_TYPES]} ViewTypes
 * 
 * @typedef SelectionListSettings
 * @property {boolean} [isMulti] - default is false
 * @property {ViewTypes} [viewType] - default is string
 * 
 * @typedef {{ [valueId: string]: string }} SelectionListValue
 * 
 * @typedef SelectionListValueDefinition
 * @property {string} id
 * @property {{ [lang: string]: string }} title
 * @property {{ [lang: string]: string }} [subTitle]
 * @property {{ uri: string }} [image]
 * 
 * @typedef {{ id: string, title: string, subTitle?: string, image?: { uri: string } }} SimpleValueDefinition
 * 
 * @typedef SelectionListChildrenProps
 * @property {(newValueId: string) => void} onChange
 * @property {boolean} isMulti
 * @property {ViewTypes} viewType
 * @property {{ [valueId: string]:  }} selectedValueDefinitionsMap
 * @property {SimpleValueDefinition[]} simpleValues
 * 
 * @typedef SelectionListHOCProps
 * @property {SelectionListSettings} [settings]
 * @property {(newValue: SelectionListValue) => void} [onChange]
 * @property {SelectionListValue} [value]
 * @property {SelectionListValueDefinition[]} [values]
 * @property {(props: SelectionListChildrenProps) => React.ReactNode} children
 */

/**
 * @param {SelectionListHOCProps} props 
 * @returns 
 */
let SelectionListHOC = (props) => {
  const { settings, onChange, children, value, values, intl } = props;

  const [isMulti, setIsMulti] = useState(false);
  const [viewType, setViewType] = useState(PROPERTIES_VIEW_TYPES.string);
  const [selectedValueDefinitionsMap, setSelectedValueDefinitionsMap] = useState({});
  const [_values, set_values] = useState([]);

  useEffect(() => {
    let new_values = [];

    (values || []).map(value => {
      if (!_.get(value, 'id'))
        return;

      let simpleValue = { id: value.id };

      let titleString = '';

      if (_.get(value, ['title', 'defaultMessage']))
        titleString = intl.formatMessage(value.title);
      else
        titleString = value.getCementoTitle();
      
      simpleValue.title = titleString;

      if (value.subTitle) {
        let subTitleString = '';

        if (_.get(value, ['subTitle', 'defaultMessage']))
          subTitleString = intl.formatMessage(value.subTitle);
        else
          subTitleString = ({ title: value.subTitle }).getCementoTitle();

        simpleValue.subTitle = subTitleString;
      }

      if (_.get(value, ['image', 'uri']))
        simpleValue.image = value.image;

      new_values.push(simpleValue);
    });

    set_values(new_values.sort((a, b) => a.title.localeCompare(b.title)));
  }, [values]);

  /**
   * Runs everytime values change and recalculates them
   */
  useEffect(() => {
    let newSelectedValueDefinitionsMap = {};

    if (value)
      (_values).forEach(_value => {
        if (!value[_value.id])
          return;

        newSelectedValueDefinitionsMap[_value.id] = _value;
      });

    if (!_.isEqual(selectedValueDefinitionsMap, newSelectedValueDefinitionsMap))
      setSelectedValueDefinitionsMap(newSelectedValueDefinitionsMap);
  }, [_values, value]);

  /**
   * This hook runs everytime the settings change to find a set the isMulti and viewType state params
   */
  useEffect(() => {
    const nextIsMulti = Boolean(_.get(settings, 'isMulti'));
    if (isMulti !== nextIsMulti)
      setIsMulti(nextIsMulti);

    const nextViewType = nextIsMulti ? _.get(settings, 'viewType', PROPERTIES_VIEW_TYPES.string) : PROPERTIES_VIEW_TYPES.string;
    if (viewType !== nextViewType)
      setViewType(nextViewType);
  }, [settings]);

  const _onChange = useCallback((newValue) => {
    if (onChange && !_.isEqual(value, newValue))
      onChange(newValue);
  }, [onChange, value]);

  const handleChange = useCallback((newValueId) => {
    const newValue = handleSelectionListChange(value, isMulti, newValueId);
    _onChange(newValue);
  }, [value, isMulti, _onChange]);

  const memoizedChildrenProps = useMemo(() => ({
    onChange: handleChange,
    isMulti,
    viewType,
    selectedValueDefinitionsMap,
    simpleValues: _values,
  }), [
    handleChange,
    isMulti,
    viewType,
    selectedValueDefinitionsMap,
    _values,
  ]);

  return children(memoizedChildrenProps);
}

SelectionListHOC = injectIntl(SelectionListHOC);
export default SelectionListHOC;