import _ from 'lodash';
import { getDispatch } from "../../configureMiddleware";
import { employeeSchema, equipmentSchema, propertyInstanceSchema } from "../realm/schema";
import { saveObjects, findAndUploadFiles } from "../../app/actions";
import { platformActions } from "../../platformActions";
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { upsertForm } from '../../forms/actions';
import { startLoading, hideLoading } from '../../../common/app/actions'

const SERVER_TYPES = {
	propertyInstanceSchema: ['id', 'propId', 'propType', 'data', 'updatedTS', 'isDeleted', 'parentId'],
	equipmentSchema: ['id', 'updatedTS', 'isDeleted'],
	employeeSchema: ['id', 'updatedTS', 'isDeleted'],
};

const getRelevantFieldsForServer = (schemaName, schemaProperties) =>
	Object.keys(schemaProperties).filter(key => SERVER_TYPES[schemaName].includes(key));
/**
 * @callback PreProcessObjectForLocalSaveFunc
 * @param {any} object
 * @param {number} index
 * @param {Array<any>} fullObjectArray
 * @returns {any}
 */

/**
 * @callback ProcessRealmObjectsOutputFunc
 * @param {any} realmObject
 * @param {number} index
 * @param {Array<any>} fullRealmObjectsArray
 * @returns {any}
 */

/**
 * @callback FolderNameBuilder
 * @param {{ projectId: string } & Record<string, any>}
 * @returns {string}
 */

 /**
 * @callback FileNameBuilder
 * @param {{ projectId: string }}
 * @returns {function(any): string}
 */

/**
 * @typedef FileSaveConfig
 * @property {FolderNameBuilder} folderNameBuilder - Function that receives projectId (in "default" func config) and returns a string of the folder name
 * @property {FileNameBuilder} fileNameBuilder - Function that receives the projectId (in "default" func config) and returns a builder function that receive the object in question to create file name as it will be saved in the server
 */

/**
 * @typedef SchemaInfos
 * @property {string} schemaType - *MANDATORY which realm collection (used for realmInstance[schemaType]) 
 * @property {string} schemaName - *MANDATORY name of the objects (used for realmInstance[schemaType].objects(schemaName)) 
 * @property {{ web: boolean, mobile: boolean }} enabled - *MANDATORY which platforms we allow offline activity 
 * @property {function({ projectId: string } & Record<string, any>):Array<string>} dbUpdatePathBuilder - *MANDATORY function that receives the projectId and must return the path root (up until the objectId) of where to save the object in the server 
 * @property {Array<string>} onlyFields - *MANDATORY a whitelist array of the name of the properties that should be allowed to be uploaded to the server (calculated by the above function base on the schema properties by setting "saveToServer: true" to the properties you want to whitelist)
 * @property {PreProcessObjectForLocalSaveFunc} [preProcessObjectForLocalSaveFunc] - *OPTIONAL function that is given to an Array.map function before saving the objects to realm, you should format the objects the way you want for saving into realm 
 * @property {string} [lastUpdateTStypeId] - *OPTIONAL name of object type for the lastUpdateTS schema (used to update the lastupdatedTS of that schema) 
 * @property {ProcessRealmObjectsOutputFunc} [processRealmObjectsOutputFunc] - *OPTIONAL function to process objects coming from realm before we use them as original objects
 * @property {FileSaveConfig} [fileSaveConfig] - *OPTIONAL/MANDATORY if object needs to upload files (if given, all properties are mandatory in filesSaveConfig)
 */

/** @type {{ [x: string]: SchemaInfos }} */
export const schemasInfo = {
  /* 
  exampleConfig: { 
    schemaType: 'someSchemaTypy',                                                        --> *MANDATORY which realm collection (used for realmInstance[schemaType]) 
    schemaName: 'someSchemaName',                                                        --> *MANDATORY name of the objects (used for realmInstance[schemaType].objects(schemaName)) 
    enabled: { web: false, mobile: true },                                               --> *MANDATORY which platforms we allow offline activity 
    dbUpdatePathBuilder: ({ projectId }) => ['some', 'object', 'path', projectId],       --> *MANDATORY function that receives the projectId and must return the path root (up until the objectId) of where to save the object in the server 
    onlyFields: getRelevantFieldsForServer(propertyInstanceSchema.properties),           --> *MANDATORY a whitelist array of the name of the properties that should be allowed to be uploaded to the server (calculated by the above function base on the schema properties by setting "saveToServer: true" to the properties you want to whitelist)
    preProcessObjectForLocalSaveFunc: (object, index, fullObjectArray) => object         --> *OPTIONAL function that is given to an Array.map function before saving the objects to realm, you should format the objects the way you want here for realm 
    lastUpdateTStypeId: 'lastUpdateTStypeId',                                            --> *OPTIONAL name of object type for the lastUpdateTS schema (used to update the lastupdatedTS of that schema) 
    processRealmObjectsOutputFunc: (realmObject, index, fullObjectsArr) => realmObject   --> *OPTIONAL function to process objects coming from realm before we use them as original objects
    fileSaveConfig: {                                                                    --> *OPTIONAL/MANDATORY if object needs to upload files (if given, all properties are mandatory in filesSaveConfig)
      folderNameBuilder: ({ projectId }) => `${projectId}_some_folder_name`, --> function that receives projectId (in "default" func config) and returns a string of the folder name
      fileNameBuilder: ({ projectId }) => (object) => `${projectId}/${object.something_in_object}/${object.id}_${Date.now()}`, --> function that receives the projectId (in "default" func config) and returns a builder function that receive the object in question to create file name as it will be saved in the server
    },
  }, 
  */

  // posts: {
  //   schemaType: 'posts',
  //   schemaName: postsSchema.name,
  //   lastUpdateTStypeId: 'posts',
  //   enabled: { web: false, mobile: false },
  //   fileSaveConfig: {},
  //   onlyFields: getRelevantFieldsForServer(postsSchema.properties),
  // },

  propertyInstances: { // This config has its own retry function
    schemaType: 'propertyInstances',
    schemaName: propertyInstanceSchema.schema.name,
    lastUpdateTStypeId: subjectName => `propertyInstances_${subjectName}`,
    enabled: { web: false, mobile: true },
    fileSaveConfig: {
      folderNameBuilder: ({ subjectName }) => `${subjectName}`,
      fileNameBuilder: ({ projectId }) => instance => `${projectId}/${instance.propId}/${instance.id}_${Date.now()}`,
    },
    dbUpdatePathBuilder: ({ projectId, subjectName }) => ['properties', 'instances', 'projects', projectId, subjectName],
    onlyFields: getRelevantFieldsForServer('propertyInstanceSchema', propertyInstanceSchema.schema.properties),
    preProcessObjectForLocalSaveFunc: instance => {
      if (!_.isNil(instance.data))
        instance = { ...instance, data: JSON.stringify(instance.data) };

      return instance;
    },
    processRealmObjectsOutputFunc: realmInstance => {
      if (realmInstance && realmInstance.data)
        try {
          const parsedData = JSON.parse(realmInstance.data);
          realmInstance.data = parsedData;
        } catch (error) {}

      return realmInstance;
    }
  },

  equipment: {
    schemaType: 'equipment',
    schemaName: equipmentSchema.schema.name,
    lastUpdateTStypeId: 'equipment',
    dbUpdatePathBuilder: ({ projectId }) => ['equipment', 'projects', projectId],
    enabled: { web: false, mobile: true },
    onlyFields: getRelevantFieldsForServer('equipmentSchema', equipmentSchema.schema.properties),
  },

  employees: {
    schemaType: 'employees',
    schemaName: employeeSchema.schema.name,
    lastUpdateTStypeId: 'employees',
    dbUpdatePathBuilder: ({ projectId }) => ['employees', 'projects', projectId],
    enabled: { web: false, mobile: true },
    onlyFields: getRelevantFieldsForServer('employeeSchema', employeeSchema.schema.properties),
  },
}









/**
 * 
 * @param {{ projectId: string, objectsToSave: Object<string, Object<string, any>> | Array<Object<string, any>>, originalObjects: Object<string, Object<string, any>> | Array<Object<string, any>>, schemaInfo: SchemaInfos, isRetry?: boolean }} param0 
 * @returns {Promise<{ success: boolean }>}
 */
export const uploadObjectsDispatcher = async ({ projectId, objectsToSave, originalObjects, schemaInfo, isRetry }) => {
  /** @type SchemaInfos */
  const { onlyFields, fileSaveConfig, preProcessObjectForLocalSaveFunc, dbUpdatePathBuilder, lastUpdateTStypeId, schemaName, schemaType, enabled, processRealmObjectsOutputFunc } = schemaInfo;
  const { folderNameBuilder = () => {}, fileNameBuilder = () => {} } = fileSaveConfig || {};
  
  const allowOfflineActivity = platformActions.app.getPlatform() === 'web' ? enabled.web : enabled.mobile;
  const dispatch = getDispatch();

  let uploadFunc = null;
  switch (schemaName) {
    case (schemasInfo.propertyInstances.schemaName): {
      uploadFunc = async () => {
        let instancesBySubjectNameMap = {};

        Object.values(objectsToSave || {}).forEach(localObject => {
          if (localObject.id && localObject.subjectName)
            _.set(instancesBySubjectNameMap, [localObject.subjectName, localObject.id], localObject);
        });

        let dispatchPromises = [];
        Object.entries(instancesBySubjectNameMap).forEach(async ([subjectName, instances]) => {

          let instancesToSave = instances;
          let instancesToSaveOnlyLocally = {};

          if (subjectName === 'employeesInfo') { // Special case to handle certificate with upsertForm and all that sh*t
            instancesToSave = {};
            let operationId = null;
            if (!isRetry) {
              operationId = `trying_to_upsert_forms_for_certificates_${subjectName}_${Object.values(instances).map(i => i.id).join('_')}`;
              dispatch(startLoading({ operationId, isWithTimeout: false }));
            }

            for (const currInstanceKey in instances) {
              const currInstance = instances[currInstanceKey];

              let shouldSaveInstance = true;
              if (currInstance.propType === propertyTypes.CERTIFICATION && _.get(currInstance, ['data'])) {

                for (const currCertDataKey in currInstance?.data) {
                  const currCertData = currInstance?.data?.[currCertDataKey];

                  if (!_.get(currCertData, ['certificateMetaData', 'upsertFormParams']))
                    continue;

                  shouldSaveInstance = false;

                  const { projectId, viewer, type } = currCertData.certificateMetaData.upsertFormParams;
                  let form = currCertData.certificateMetaData.upsertFormParams.form;
                  const { hadFilesToUpload, success: uploadSigsSuccess, updatedObjects } = await findAndUploadFiles({ objects: [form], fileServerFolderName: `signatures/${projectId}/`, targetFileNameBuilder: (form, { uriPathInObject }) => `signature_${uriPathInObject[uriPathInObject.length - 1]}_${Date.now()}` });

                  if (!hadFilesToUpload || (hadFilesToUpload && uploadSigsSuccess)) {
                    form = Object.values(updatedObjects || {})[0];
                    if (form) {
                      const { reportId: formId, success: upsertFormSuccess } = await dispatch(upsertForm(projectId, viewer, form, type, !isRetry));
                      if (upsertFormSuccess) {
                        currCertData.formId = formId;
                        delete currCertData.certificateMetaData;
                        shouldSaveInstance = true;
                      }
                    }
                  }
                }
              }

              (shouldSaveInstance ? instancesToSave : instancesToSaveOnlyLocally)[currInstance.id] = currInstance;
            }

            if (operationId)
              dispatch(hideLoading(operationId));
          }
          const saveObjectsParams = {
            allowOfflineActivity,
            dbRootPathArr: dbUpdatePathBuilder({ projectId, subjectName }),
            fileServerFolderName: folderNameBuilder({ subjectName }),
            lastUpdateTStypeId: lastUpdateTStypeId(subjectName), // TODO: fix the lastupdatets id, should be withtout subjectname, ask tomer what to do with that
            originalObjects,
            projectId,
            schemaName,
            schemaType,
            targetFileNameBuilder: fileNameBuilder({ projectId }),
            isRetry,
            preProcessObjectForLocalSaveFunc,
            serverOnlyFields: onlyFields,
            processRealmObjectsOutputFunc,
          };

          if (Object.keys(instancesToSave).length)
            dispatchPromises.push(dispatch(saveObjects({ ...saveObjectsParams, objectsToSave: instancesToSave })));

          if (Object.keys(instancesToSaveOnlyLocally).length)
            dispatchPromises.push(dispatch(saveObjects({ ...saveObjectsParams, objectsToSave: instancesToSaveOnlyLocally, skipUploadToServer: true })));
        });

        return { success: (await Promise.all(dispatchPromises)).every(payload => Boolean(payload) && payload.success) };
      }
      break;
    }

    default:
      uploadFunc = () => dispatch(saveObjects({
        allowOfflineActivity, 
        dbRootPathArr: dbUpdatePathBuilder({ projectId }),
        objectsToSave,
        originalObjects,
        projectId, 
        schemaName, 
        schemaType, 
        lastUpdateTStypeId,
        isRetry,
        preProcessObjectForLocalSaveFunc,
        fileServerFolderName: folderNameBuilder({ projectId }),
        fileNameBuilder: fileNameBuilder({ projectId }),
        serverOnlyFields: onlyFields,
        processRealmObjectsOutputFunc,
      }));
  }

  return uploadFunc();
}