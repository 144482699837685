import React from "react";
import systemMessages from '../../../common/app/systemMessages.js';
import theme from "../../assets/css/theme.js";
import Text from "../../components/CementoComponents/Text.js";
import { connect } from "react-redux";

class NotSupported extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime))
      this.props.history.push('/');
  }

  render() {
    const { isRtl } = this.props;
    return (
      <div style={{ flex: 1, zIndex: theme.zIndexesLevels.max, display: 'flex', flexDirection: 'column', direction: isRtl ? 'rtl' : 'ltr' }}>
        <Text style={{ ...theme.defaultFont, fontSize: 26, color: 'white', fontWeight: theme.strongBold, flex: 1, textAlign: 'center' }}>
          {systemMessages.notSupportedTitle}
        </Text>
        <Text style={{ ...theme.defaultFont, fontSize: 26, color: 'white', fontWeight: theme.strongBold, flex: 1, textAlign: 'center' }}>
          {systemMessages.notSupportedDetails}
        </Text>
      </div>
    );
  }
}

NotSupported = connect(state => ({ rtl: state.app.rtl }), {})(NotSupported);
export default NotSupported;
