import { defineMessages } from 'react-intl';

export default defineMessages({
  thanksForDownloading: {
    defaultMessage: 'Thanks for downloading.',
    id: 'auth.registration.thanksForDownloading',
  },
  thankYouDetails: {
    defaultMessage: 'To get the most out of Cemento, you need to be added to a team. Tap below to request a team invite.',
    id: 'auth.registration.thankYouDetails',
  },
  requestInvite: {
    defaultMessage: 'REQUEST INVITE',
    id: 'auth.registration.requestInvite',
  },
  tellUsAboutTitle: {
    defaultMessage: 'Tell us a little bit about yourself.',
    id: 'auth.registration.tellUsAboutTitle',
  },
  name: {
    defaultMessage: 'Name',
    id: 'auth.registration.name',
  },
  companyType: {
    defaultMessage: 'Company type',
    id: 'auth.registration.companyType',
  },
  companyName: {
    defaultMessage: 'Company name',
    id: 'auth.registration.companyName',
  },
  title: {
    defaultMessage: 'Title',
    id: 'auth.registration.title',
  },
  extraInfoPlaceholder: {
    defaultMessage: 'Anything else you want to tell us...',
    id: 'auth.registration.extraInfoPlaceholder',
  },
  send: {
    defaultMessage: 'SEND',
    id: 'auth.registration.send',
  },
  waitingListTitle: {
    defaultMessage: 'Thanks{displayName}, you\'re on the wait list.',
    id: 'auth.registration.waitingListTitle',
  },
  waitingListDetails: {
    defaultMessage: 'Once your invite is processed, we\'ll contact you with the registered phone number for instructions on how to join your team.',
    id: 'auth.registration.waitingListDetails',
  },
  registerAnotherPhone: {
    defaultMessage: 'REGISTER ANOTHER PHONE NUMBER',
    id: 'auth.registration.registerAnotherPhone',
  }
});
