import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../views/Dashboard/Dashboard';

export default {
  path: 'tasksView',
  children: [
    {
      index: true,
      element: <Navigate to={'dashboard'} />,
    },
    {
      path: 'dashboard',
      element: <Dashboard contentType={'issues'} />,
    },
  ],
};
