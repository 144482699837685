import { Record } from '../transit';

const checklistSubscription = Record({
  id: null,
  title: null,
  buildingId: null,
  stageId: null,
  users: null,
  companies : null,
  emails: null,
  trades : null,
  type : null,
}, 'checklistSubscription', false);

export default checklistSubscription;
