import React from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  FocusManagerContext,
  FOCUS_ELEMENT_ID_ATTRIBUTE_NAME,
  IS_DISABLED_DATA_ATTRIBUTE,
} from "./common";
import { connectContext } from "react-connect-context";

/**
 * @typedef ElementRenderChildrenProps
 * @property {boolean} isFocus
 * @property {boolean} isFocusWithin
 * @property {string} elementId
 * @property {string} parentElementId
 *
 * @typedef ElementProps
 * @property {string} [elementId]
 * @property {string} [parentElementId]
 * @property {(focusProps: ElementRenderChildrenProps) => React.Element} children
 * @property {React.CSSProperties} [style]
 * @property {Boolean} scrollToElementOnFocus
 * @property {Boolean} scrollToElementOnFocusWithin
 * @property {Boolean} focusElementOnMount
 * @property {Boolean} [isDisabled] - default false. If true, will not trigger focus on click
 */

/** @extends {React.Component<ElementProps>} */
class Element extends React.Component {
  /** @param {ElementProps} props */
  constructor(props) {
    super(props);
    this.state = {};
    this.elementId = props.elementId ? props.elementId : uuidv4();
    this.elementRef = React.createRef();
    this.prevElementStateRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { registerElement } = this.props;

    if (registerElement)
      registerElement(this.elementId, this.props.parentElementId);
  }

  componentDidMount() {
    const { focusElementOnMount, setElementFocus } = this.props;

    if (focusElementOnMount && setElementFocus)
      setTimeout(() => setElementFocus(this.elementId), 0); // setTiemout hack to make sure the setElementFocus side effects run properly
  }

  componentWillUnmount() {
    const { unregisterElement } = this.props;

    if (unregisterElement) unregisterElement(this.elementId);
  }

  scrollToElement = () => {
    if ((this.elementRef.current || {}).scrollIntoView)
      this.elementRef.current.scrollIntoView();
  };

  render() {
    const {
      children,
      style,
      scrollToElementOnFocus,
      scrollToElementOnFocusWithin,
      elementProps,
      isDisabled,
    } = this.props;
    const elementState =
      _.get(this.props, ["elementsState", this.elementId]) || {};

    const { isFocus, isFocusWithin, parentId } = elementState;
    const prevElementState = this.prevElementStateRef.current || {};
    if (
      (scrollToElementOnFocus &&
        isFocus &&
        isFocus !== prevElementState.isFocus) ||
      (scrollToElementOnFocusWithin &&
        !isFocus &&
        isFocusWithin &&
        isFocusWithin !== prevElementState.isFocusWithin)
    )
      setTimeout(() => this.scrollToElement(), 0); // setTimeout hack to have it set into view when components in split screens

    this.prevElementStateRef.current = elementState;

    const _elementProps = elementProps || {};
    const dataAttributes = Object.assign(
      { [`data-${FOCUS_ELEMENT_ID_ATTRIBUTE_NAME}`]: this.elementId },
      Boolean(isDisabled) && { [`data-${IS_DISABLED_DATA_ATTRIBUTE}`]: true }
    );

    return (
      <div
        ref={this.elementRef}
        {..._elementProps}
        {...dataAttributes}
        style={Object.assign({ height: "100%", width: "100%" }, style)}
      >
        {children({
          isFocus: Boolean(isFocus),
          isFocusWithin: Boolean(isFocusWithin),
          elementId: this.elementId,
          parentElementId: parentId,
        })}
      </div>
    );
  }
}

Element = connectContext(FocusManagerContext.Consumer)(Element);
Element.displayName = "FocusManagerElement";
export default Element;
