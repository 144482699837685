import { envParams } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import { onError } from '../app/funcs';
import ExtraError from '../lib/errors/extraError';
import jwtDecode from 'jwt-decode';

export async function getNewAccessTokenFromServer(authToken, userPhoneNumber, platform) {
  const { apiServer } = envParams;
  
  let newTokens = null;
  try {
    let resp = await platformActions.net.fetch(apiServer + '/v1/services/renewAccessToken', {
      method: 'POST',
      body: JSON.stringify({ platform, authToken, userPhoneNumber })
    });

    if (resp && resp.getJson) {
      const res = await resp.getJson();

      if (res && res.tokens)
        newTokens = res.tokens;
    }
  }
  catch (error) {
    onError({
      errorMessage: 'error while fetching from api server /v1/services/renewAccessToken',
      error,
      methodMetaData: {
        name: 'getNewAccessTokenFromServer',
        args: { authToken, userPhoneNumber, platform },
      },
    });

    throw new ExtraError('getNewAccessTokenFromServer error:', { authToken, userPhoneNumber, platform }, error);
  }
  
  return newTokens;
}

function getIssuer(token) {
  if (!token) {
    return '';
  }

  return jwtDecode(token)?.iss;
}

export function valideTokenIssuer(token) {
  return Boolean(token) && (getIssuer(token) === envParams.auth0.issuer);
}