import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import DrawingsPage from '../../../../views/Locations/DrawingsPage';

export default {
  path: 'drawings',
  children: [
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId?/:floorId?/:unitId?',
          element: <DrawingsPage contentType={'drawings'} />,
        },
      ],
    },
  ],
};
