import { defineMessages } from 'react-intl';

export default defineMessages({
  floorNumber: {
    defaultMessage: "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Floor",
    id: 'floors.setting.number'
  },
  floorDescription: {
    defaultMessage: "Floor description",
    id: 'floors.setting.description'
  },
  floorDescriptionPlaceholder: {
    defaultMessage: "What is the purpose of this floor? (optional)",
    id: 'floors.setting.descriptionPlaceholder'
  },
  allFloors: {
    defaultMessage: "All floors",
    id: "projects.allFloors"
  },
  floor: {
    defaultMessage: 'Floor',
    id: 'projects.newProject.floor'
  },
});
