import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'No notifications for the project',
    id: 'notifications.notifications.empty'
  },
  loading: {
    defaultMessage: 'Loading notifications',
    id: 'notifications.notifications.loading'
  },
  create: {
    id: 'notifications.notifications.createdTask',
    defaultMessage: 'Task created by {name}',
  },
  changedStatusDone: {
    id: 'notifications.notifications.changedStatusDone',
    defaultMessage: '{name} resolved the task',
  },
  changedStatusOpen: {
    id: 'notifications.notifications.changedStatusOpen',
    defaultMessage: '{name} opened the task',
  },
  changedStatusClose: {
    id: 'notifications.notifications.changedStatusClosed',
    defaultMessage: '{name} closed the task',
  },
  forwardMessage: {
    id: 'notifications.notifications.forwardMessage',
    defaultMessage: 'The task was reassigned to {name}',
  },
  checklistItemInstance : {
    status : {
      99 : { 
        id: 'notifications.checklistItemInstance.inspected',
        defaultMessage: 'Inspected by {name}',
      },
      100 : {
        id: 'notifications.checklistItemInstance.confirmed',
        defaultMessage: 'Confirmed by {name}',
      },
      125 : {
        id: 'notifications.checklistItemInstance.partial',
        defaultMessage: 'Partially confirmed by {name}',
      },
      150 : {
        id: 'notifications.checklistItemInstance.resolved',
        defaultMessage: 'Resolved by {name}',
      },
      200 : {
        id: 'notifications.checklistItemInstance.rejected',
        defaultMessage: 'Rejected by {name}',
      },
      300 : {
        id: 'notifications.checklistItemInstance.none',
        defaultMessage: 'Cleared by {name}',
      },
      301 : {
        id: 'notifications.checklistItemInstance.irrelevant',
        defaultMessage: 'Marked as irrelevant by {name}',
      }
    }
  }
});
