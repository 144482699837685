import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import * as companyTypes from './companiesTypes';

export function getMergedCompanyFromState(companyId, projectId) {
  if (!companyId)
    return null;

  let company = getAppState().getNested(['companies', 'map', companyId],{});
  if (projectId) {
  	const companyProjects = getAppState().getNested(['companies', 'map', companyId, 'projects', projectId], {});
  	companyProjects.loopEach((projectId, projectMeta) => company = company.set(projectId, projectMeta));
  }

  return company;
}

export const fetchCompanyById = async (companyId) => {
  const firestore = ((platformActions.firebase.getFirebase() || {}).firestore || (() => {}))();
  
  let company = null;

  if (companyId && firestore) {
    const querySnapshot = await (
      firestore
        .collection("companies")
        .doc(companyId)
        .get()
    );

    company = querySnapshot.data();
    if (company && !company.id) 
      company.id = companyId;
  }

  return company;
}

export function getProjectGCs(projectId) {	
  var projectGCs = [];

  var companies = getAppState().getNested(['companies', 'map'],{});
  companies.loopEach((compId, comp) => {
    if (comp && comp.getNested(['projects', projectId, 'types', companyTypes.COMPANY_TYPES_GC]))
      projectGCs.push(comp);
  })
  return projectGCs;
}