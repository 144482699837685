// ##############################
// // // Header styles
// #############################

import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  primaryBoxShadow,
  boxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "../../material-dashboard-pro-react.jsx";


const headerStyle = theme => ({
  appBar: {
    position: 'absolute',
    top:'0px',
    backgroundColor: "black",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "sticky",
    width: "100%",
    zIndex: "1029",
    color: "#555555",
    border: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    boxShadow: "0px 0px 0px 0 rgba(0, 0, 0, 0.14)",
  },
  container: {
    ...containerFluid,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  background: {
    '-webkit-filter': 'blur(5px)',
    transition: 'backgroundImage .3s ease', 
    filter: 'blur(5px)',
    position: "absolute",
    height: "120%",
    width: "120%",
    display: "block",
    top: "-8px",
    left: "-8px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    ...defaultBoxShadow,
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: "#555555"
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important"
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px"
  }
});

export default headerStyle;
