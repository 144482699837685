import {GridContainer, GridItem} from '../index';
import Image from '../CementoComponents/Image';
import React from 'react';

const ImageTileList = ({ imageURIs, onImageClick, customCtnStyle, }) => {

  if (!imageURIs?.length) {
    return null;
  }
  const ctnStyle = {
    marginTop: 0,
    ...customCtnStyle,
  };

  return (
    <GridContainer
      spacing={16}
      style={ctnStyle}
    >
      {imageURIs.map((uri, i) =>
        Boolean(uri) && (
          <GridItem
            key={uri}
            xs={
              i === imageURIs.length - 1 && i % 2 === 0
                ? 12
                : 6
            }
          >
            <Image
              src={uri}
              containerStyle={{ cursor: "pointer" }}
              onClick={() => onImageClick?.({uri, i})}
            />
          </GridItem>
        )
      )}
    </GridContainer>
  );
};

export default ImageTileList;