import { Record } from '../transit';

const ChecklistStatusData = Record({
  confirmedItems: 0,
  rejectedItems: 0,
  resolvedItems: 0, 
  confirmed2Items: 0,
  totalItems: 1,
  issuesCounter: 0,
}, 'checklistStatusData', false);

export default ChecklistStatusData;
