import { defineMessages } from 'react-intl';

export default defineMessages({
  welcomeText: {
    defaultMessage: 'The #1 solution for multi-unit construction projects',
    id: 'auth.login.welcome',
  },
  getStartedText: {
    defaultMessage: 'GET STARTED',
    id: 'auth.login.getStarted',
  },
  enterPhoneNumber: {
    defaultMessage: 'Enter your phone number to request\n a registration code.',
    id: 'auth.login.enterPhoneNumber',
  },
  country: {
    defaultMessage: 'Country',
    id: 'auth.login.country',
  },
  areaCodeAndPhoneNumber:{
    defaultMessage: 'Area code & phone number',
    id: 'auth.login.areaCodeAndPhoneNumber',
  },
  requestCode: {
    defaultMessage: 'REQUEST CODE',
    id: 'auth.login.requestCode',
  },
  waitingForVerificationCode: {
    defaultMessage: 'We just sent a text messages to {phoneNumber} with a verification code',
    id: 'auth.login.waitingForVerificationCode',
  },
  countDownMessage: {
    defaultMessage: 'You will receive a text message in {timerSeconds} seconds',
    id: 'auth.login.countDownMessage',
  },
  requestNewCode:{
    defaultMessage: 'Did not received, send again',
    id: 'auth.login.requestNewCode',
  }, 
  verifyCode:{
    defaultMessage: 'VERIFY CODE',
    id: 'auth.login.verifyCode',
  }, 
  wrongVerificationCode: {
    defaultMessage: 'Wrong verification code',
    id: 'auth.login.wrongVerificationCode',
  }, 
  connecting: {
    defaultMessage: 'Connecting',
    id: 'auth.login.connecting',
  }, 
  continue: {
    defaultMessage: 'Continue',
    id: 'auth.login.continue',
  },
  verificationApproved: {
    defaultMessage: 'Verification code approved, Welcome to Cemento',
    id: 'auth.login.verificationApproved',
  },
  welcomeWebTitle: {
    defaultMessage: 'Welcome to Cemento',
    id: 'auth.login.welcomeWebTitle',
  },
  welcomeWebContent: {
    defaultMessage: 'Please enter you mobile phone number to login',
    id: 'auth.login.welcomeWebContent',
  },
  privacyPolicy: {
    alertTitle: {
      defaultMessage: 'Privacy Policy',
      id: 'auth.login.privacyPolicy.alertTitle',    
    },
    alertText: {
      defaultMessage: 'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
      id: 'auth.login.privacyPolicy.alertText',    
    },
    text: {
      defaultMessage: 'I agree to the Cemento ',
      id: 'auth.login.privacyPolicy.text',    
    },
    linkText: {
      defaultMessage: 'Terms of Service and Privacy Policy',
      id: 'auth.login.privacyPolicy.linkText',    
    }
  }
});
