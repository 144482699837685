import React from 'react';
import { connect } from 'react-redux';

// Components
import Text from './Text';
import Image from './Image';

// Other
import pdfIcon from '../../assets/img/icons/pdf.png';
import fileIcon from '../../assets/img/icons/genericFile.svg';
import buttonMessages from '../../../common/app/buttonsMessages';
import systemMessages from '../../../common/app/systemMessages';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import theme from '../../assets/css/theme';
import { startToast } from '../../../common/app/actions';

class FilesDropzone extends React.Component {
	constructor(props) {
		super(props);
		this.toggleDragOver = this.toggleDragOver.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.calcSrc = this.calcSrc.bind(this);

		this.state = {
			isDragOver: false,
			isMaxNumFilesReached: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value, multiple, maxNumOfFiles } = nextProps;
		const { isMaxNumFilesReached } = this.state;

		if (value && !isMaxNumFilesReached)
			if ((multiple && value.length >= maxNumOfFiles) || (!multiple && value.length === 1)) this.setState({ isMaxNumFilesReached: true });
	}

	handleUpload(e) {
		e.preventDefault();
		const { isMaxNumFilesReached, isDragOver } = this.state;
		const { onChange, id, value, startToast, maxNumOfFiles, multiple, accept } = this.props;

		if (isDragOver) this.toggleDragOver();

		let uploadFiles = e.target.id === 'fileInput' + id ? e.target.files : e.dataTransfer.files;
		let totalNumOfFiles = uploadFiles.length + value.length;

		if (isMaxNumFilesReached || (multiple && totalNumOfFiles > maxNumOfFiles) || (!multiple && totalNumOfFiles > 1)) return;

		let readFilesArray = [];

		uploadFiles.loopEach((_, file) => {
			const reader = new FileReader();
			let nameArray = ((file || {}).name || '').split('.');
			let extension = nameArray[nameArray.length - 1];

			reader.onloadend = () => {
				readFilesArray.push({
					file,
					extension,
					name: (file || {}).name,
					uri: reader.result,
				});

				if (onChange && uploadFiles.length === readFilesArray.length) {
					if (multiple) onChange([...value, ...readFilesArray]);
					else onChange(readFilesArray[0]);

        if ((!isMaxNumFilesReached && multiple && value.length === maxNumOfFiles) || (!multiple && value.length === 1))
          this.setState({ isMaxNumFilesReached: true });
				}
			};

			if (file && accept.split(',').includes('.' + (extension || "").toLowerCase())) {
				if (typeof file === 'object') reader.readAsDataURL(file);
			} else {
				startToast({ title: systemMessages.unsupportedFile });
			}
		});
	}

	toggleDragOver() {
		this.setState(prevState => ({ isDragOver: !prevState.isDragOver }));
	}

	handleDragOver(e) {
		e.preventDefault();
		const { isDragOver } = this.state;

		if (!isDragOver) this.toggleDragOver();
	}

	handleDragLeave(e) {
		e.preventDefault();
		const { isDragOver } = this.state;

		if (isDragOver) this.toggleDragOver();
	}

	handleDelete(indexOfImage) {
		const { onChange, value, multiple } = this.props;

		let newValue = Array.from(value);
		if (((value[indexOfImage] || {}).uri || '').startsWith('http')) newValue[indexOfImage] = null;
		else newValue = newValue.filter((_, index) => index !== indexOfImage);

		if (onChange) {
			if (multiple) onChange(newValue);
			else onChange(newValue[0]);
		}

		this.setState({ isMaxNumFilesReached: false });
  }
  
  calcSrc(file) {
    const { picsOnly } = this.props;

    if (picsOnly) return file.uri ? file.uri : file.data;

    const { type, extension, uri, data } = file;
    let typeToCheck = type || extension;
    let imageTypes = ['png', 'jpeg', 'jpg'];

    if (!typeToCheck)
      typeToCheck = (uri || data).split('.').pop().split('?')[0];

    if (imageTypes.includes(typeToCheck) || imageTypes.map(val => `image/${val}`).includes(typeToCheck))
      return file.uri ? file.uri : file.data;
    else if (typeToCheck === 'pdf' || typeToCheck === 'application/pdf' || typeToCheck === 'pdf' || typeToCheck === 'application/pdf') 
      return pdfIcon;
    else
      return fileIcon;

  }

  abbreviate(str, desiredLength) {
    let newStrArray = [];
    desiredLength = desiredLength / 2;

    newStrArray = str.split('').filter((letter, index) => {
      return (index < desiredLength || index > str.length - desiredLength)
    });
    for (let i = -1; i < 2; i++)
      newStrArray[desiredLength + i] = '.';
    
    return newStrArray.join('');
  }

	render() {
		const { isDragOver, isMaxNumFilesReached } = this.state;
		const { rtl, key, id, value, accept, disabled = false, multiple } = this.props;

		styles.mainContainer = { ...styles.mainContainer, [rtl ? 'borderTopLeftRadius' : 'borderTopRightRadius']: 0 };
		styles.uploadFileContainer = { ...styles.uploadFileContainer, [rtl ? 'borderTopLeftRadius' : 'borderTopRightRadius']: 0 };

		const { mainContainer, uploadFileContainer, enabledMainContainer, imageContainer, fileName, uploadButton, dragOver, deleteButton } = styles;

		if (disabled && (value || []).length === 0) return <NoValueComponent valueText={''} defaultText={propertiesMessages.empty} />;

		return (
			<div key={key}>
				<div style={{ ...mainContainer, ...(!disabled ? enabledMainContainer : {}) }}>
					<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', /* maxWidth: '100%' */ }}>
						{Boolean(value) &&
							value.map((file, key) => (
								<>
									{Boolean(file.uri || file.data) && (
										<div key={key} style={{ ...imageContainer, ...( disabled ? { margin: 0 } : {})}}>
											<Image
												regularImage
												key={key + 'image_for_manager' + (file.name || '')}
												src={this.calcSrc(file)}
												showButtonsOnHover={!disabled}
												onRemove={() => this.handleDelete(key)}
												imageStyle={{ height: '90%', objectFit: 'contain', maxWidth: '14em' }}
                        imageContainerStyle={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                        buttonStyle={deleteButton}
											/>
											<div style={fileName}>
												{Boolean(file.name) ? file.name.length > 30 ? this.abbreviate(file.name, 20) : file.name : ''}
											</div>
										</div>
									)}
								</>
							))}
						{Boolean(!disabled && !isMaxNumFilesReached) && (
							<div
								style={{
									...uploadFileContainer,
									...(isDragOver ? dragOver : {}),
								}}
								onDrop={this.handleUpload}
								onDragOver={this.handleDragOver}
								onDragLeave={this.handleDragLeave}
							>
								<Text style={{ marginBottom: theme.padding, textAlign: 'center' }}>{buttonMessages.dragAndDrop}</Text>
								<label htmlFor={'fileInput' + id} style={uploadButton}>
									<Text style={{ transform: 'translateY(-1px)' }}>{buttonMessages.uploadAFile}</Text>
								</label>
								<input
									id={'fileInput' + id}
									type='file'
									style={{ display: 'none' }}
									onInput={this.handleUpload}
									multiple={multiple}
									accept={accept}
								/>
							</div>
						)}
						{/* Only to keep the mainContainer size when small image at the end and upload box disapears */}
						<div style={{ display: 'hidden', height: uploadFileContainer.height, width: 0, margin: uploadFileContainer.margin + 'px 0' }} />
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	mainContainer: {
		borderRadius: theme.borderRadius,
		height: 'min-content',
		display: 'flex',
	},
	enabledMainContainer: {
    marginTop: theme.verticalMargin,
		border: '1px solid',
		borderColor: 'rgba(0, 0, 0, 0.3)',
	},
	uploadFileContainer: {
		borderRadius: theme.borderRadius,
		height: theme.padding + 2 + 'em',
		display: 'flex',
		border: '1px dashed',
		borderColor: 'rgba(0, 0, 0, 0.3)',
		flexDirection: 'column',
		width: '15%',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: theme.strongBold,
		margin: theme.margin,
		minWidth: '230px',
	},
	imageContainer: {
		height: '100%',
		margin: theme.margin,
		maxHeight: '12em',
		width: 'fit-content',
	},
	fileName: {
		opacity: theme.activeOpacity,
		fontSize: theme.fontSizeH6,
		transform: 'translateY(' + ( -theme.margin - 5) + 'px)',
	},
	uploadButton: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.brandPrimary,
		borderRadius: theme.borderRadius * 4,
		cursor: 'pointer',
		color: 'white',
		padding: theme.padding * 2 + 'px',
		fontSize: 'inherit',
		fontWeight: theme.strongBold,
		height: 7 * theme.verticalMargin,
	},
	dragOver: {
		backgroundColor: 'rgba(242, 125, 34, 0.5)', // rgba value of theme.brandPrimary
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.brandPrimary,
    fontSize: theme.fontSizeH2,
  },
};

FilesDropzone = connect(() => ({}), { startToast })(FilesDropzone);

export default FilesDropzone;

// #################################################################################
class NoValueComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { valueText, defaultText } = this.props;
		return (
			<Text style={{ color: valueText ? theme.headerColorDark : theme.brandNeutral, fontSize: 18, lineHeight: '18px' }}>
				{valueText || defaultText}
			</Text>
		);
	}
}
