import { envParams, getAppState, getFirebaseDeps, writeLog } from '../../configureMiddleware';
import { platformActions } from '../../platformActions';
import ExtraError from '../errors/extraError';
import serverSDK from '@cemento-sdk/server'
import _ from 'lodash';
import { track } from '../reporting/actions';
import { writeLogOnce } from '../utils/utils';

const checkIfProxyEnabled = async ({ projectId, type }) => {
	try {
		const state = getAppState();
		const isEnabled = state.getNested(['configurations', 'map', projectId, 'clientMS', 'V2', 'updates', type]);

		return isEnabled;
	} catch (error) {
		writeLog('error', 'checkIfProxyEnabled_ERROR', 'mixpanelTrack', {
			error,
			projectId,
			type,
		});
		return false;
	}
};

/**
 * Updates Firebase data using a proxy server, if the proxy is enabled for the specified project and type.
 * Otherwise, updates the Firebase data directly.
 *
 * @async
 * @param {Object} options - The options for the update.
 * @param {string} options.projectId - The ID of the project.
 * @param {string} options.type - The type of data to update.
 * @param {Object} options.updates - The updates to apply to the data.
 * @param {Function} [options.callback] - The callback function to call if the update is successful.
 * @param {Object} options.enableFirebaseProxy - Enable the Firebase proxy regardless of the configuration.
 * @returns {Promise<Object>} A Promise that resolves to the result of the update.
 */
export const updateUsingFirebaseProxy = async ({ projectId, type, updates, callback, enableFirebaseProxy }) => {
  const isConnected = getAppState?.()?.getNested(['app', 'isConnected'], false);
  if (!isConnected) {
    throw new ExtraError('updateUsingFirebaseProxy - no reception', { projectId, type });
  }

	let firebaseDeps, shouldUseProxy;
	try {
		firebaseDeps = getFirebaseDeps();
		shouldUseProxy = enableFirebaseProxy || (await checkIfProxyEnabled({ projectId, type, firebaseDeps }));
	} catch (error) {
		writeLog('error', 'updateUsingFirebaseProxy_ERROR - failed on shouldUseProxy' + error?.message, 'mixpanelTrack', {
			projectId,
			type,
		});
	}

	if (shouldUseProxy) {
		try {
			const url = `${envParams.apiServer}/v1/firebase-proxy/update`;

			const res = await platformActions.net.fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					updates,
				}),
			});

			const result = await res.getJson();

			if (res.ok && callback) {
				return callback();
			}

			writeLogOnce('info', 'updateUsingFirebaseProxy - writing Server', {
				scope: 'projects',
				scopeId: projectId,
				type,
			});

			return result;
		} catch (error) {
			writeLog('error', 'updateUsingFirebaseProxy_ERROR', 'mixpanelTrack', {
				error,
				projectId,
				type,
				updates,
			});
			callback?.(error);
			throw new ExtraError('updateUsingFirebaseProxy error:', { projectId, type }, error);
		}
	} else {
		writeLogOnce('info', 'updateUsingFirebaseProxy - writing Firebase', {
			scope: 'projects',
			scopeId: projectId,
			type,
		});

		return firebaseDeps.firebase.update(updates, callback);
	}
};

let didServerInit = false;

const doesResponseContainJson = (res) => {
  return res?.headers?.get('content-type')?.includes('application/json');
};

const clientFetchFunctionForSDK = async ({ url, method, body, headers: _headers }) => {
	track('serverSDKFetch', { url, method });
	const headers = _.assign(
    {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    _headers
  );
  try {
    let res = await platformActions.net.fetch(
      url,
      {
        headers,
        method,
        body: method !== 'GET' ? JSON.stringify(body) : undefined,
      },
    );
    if (doesResponseContainJson(res)) {
      res = await res?.getJson();
    }
    return res;
  } catch (error) {
		track('serverSDKFetch failed', { error, url, method });
		platformActions.sentry.notify('failed fetching using server SDK', { error, url, method, body });
    throw error;
  }
};

export const didSDKServerInit = () => didServerInit;

export const setServerSdkAuthClientParams = ({
  cementoApiServer = envParams.apiServer,
  clientAuthToken,
  projectId,
  companyId,
}) => {
  let clientParams = {
    clientAuthToken,
    projectId,
    companyId,
  };

  if (!didServerInit) {
    _.assign(clientParams, {
      cementoApiServer,
      clientFetchFunction: clientFetchFunctionForSDK,
    });

    didServerInit = true;
  }

  clientParams = _.pickBy(clientParams, _.identity);
  serverSDK.client.setClientParams(clientParams);
  return clientParams;
};

export const getNewId = () => serverSDK.client.getId();