import * as actions from './actions';
import User from './user';
import * as authActions from '../auth/actions';
import * as membersActions from '../members/actions'
import * as quasiStaticsActions from '../quasiStatics/actions'
import { REPLACE_USER } from '../auth/actions';

import { Record, fromJSON } from '../transit';
import { Seq, Map } from 'immutable';
import { firebaseActions } from '../lib/redux-firebase';
import { SET_LAST_LOCATION_REPORTED } from "./actions";
import { platformActions } from "../platformActions";
import { registerUserScopeLogs, writeLog, getAppState } from "../configureMiddleware";
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
    viewer: null,
    originViewer: null,
    lastLocationReported: null,
    lastVisitedProject : null
}, 'users');

const initialState = new InitialState;

function setSuperProperties(properties) {
  let props = registerUserScopeLogs(properties);
  platformActions.mixpanel.registerSuperProperties(props);
}

function track(title, props) {
  if (!getAppState() || !getAppState().getNested(['users','viewer', 'id']))
    return;

    if (!title)
        return;
    else if (props)
        platformActions.mixpanel.trackWithProperties(title, props);
    else
        platformActions.mixpanel.track(title);

    writeLog('info', title, 'mixpanelTrack', props);
}

export default function usersReducer(state = new InitialState, action) {
  switch (action.type) {


    case membersActions.COPY_GLOBAL_VIEWER_TO_SCOPE: {
      if (!action.payload || !action.payload.projectId)
        return state;

      const { projectId } = action.payload;
      var currViewer = state.get('viewer');
      state = state.setIn(['projectViewer', projectId], currViewer);      

        return state;
    }

    case actions.AUTH0_SAVE_USER + "_SUCCESS": 
    case actions.GET_MY_USER_DETAILS: {
      if (!action.payload)
        return state;
      const { user } = action.payload;
      if (!user)
        return state;

      var prev = state.getNested(['viewer'], {});   
      var userRecord = new User({
        ...user, 
        adminMode: state.getNested(['viewer', 'adminMode'])
      }); 

      state = state.set('viewer', userRecord);
        
      return state;
    }

    case actions.UPDATE_MY_METADATA + '_SUCCESS': {
      const { key, newValue, values, myId } = action.payload;
      if (!myId)
        return state;
      
      if (values) {
        values.loopEach((key, value) => {
          try {
            state = state.updateIn(['viewer', key], value => value);
          }
          catch (error) {
            console.log('error: ' + error)
          }
        });
      }

      if (key && newValue)
        state = state.updateIn(['viewer', key], value => newValue);

      return state; //setProjectsViewer(state, state.get('viewer'));
    }
    
    case quasiStaticsActions.GET_QUASISTATICS: {
      if (!action?.payload?.quasiStatics?.adminUsers)
        return state;

      const { quasiStatics } = action.payload;
      if (quasiStatics?.adminUsers) {
        let isAdmin = (quasiStatics.adminUsers[state.getNested(['viewer', 'id'])]);
        if (Boolean(isAdmin) !== Boolean(state.getNested(['viewer', 'adminMode']))) 
          state = state.updateIn(['viewer', 'adminMode'], value => Boolean(isAdmin));
      }
      return state;
    }

    case actions.SET_ADMIN_MODE:  {
      const { newMode } = action.payload;
      return state.updateIn(['viewer', 'adminMode'], value => newMode);
    }

    // case authActions.SMS_VERIFICATION_VERIFIED: {
    //   const { number, userId } = action.payload;

    //   if (!state.viewer)
    //     state = state.set('viewer', new User())

    //   var id = userId;
    //   state = state.updateIn(['viewer', 'id'], value => id);
    //   return state.updateIn(['viewer', 'phoneNumber'], value => number);
    // }



    case REPLACE_USER + "_SUCCESS": {
      const { fakeUser, originViewer } = action.payload;

      state = initialState;
      if (!fakeUser) {
        state = state.set('viewer', originViewer)
        state = state.delete('originViewer')
      }
      else {
        var user_metadata = fakeUser.user_metadata;
        state = state.set('viewer', new User({...user_metadata}))
        state = state.set('originViewer', originViewer)
      }

      return state;
    }

      case actions.SET_LAST_LOCATION_REPORTED + "_SUCCESS": {
        const { lastLocationReported } = action.payload;
        return state.set('lastLocationReported',lastLocationReported);
      }

      case actions.SET_LAST_VISITED_PROJECT_ID: {
        const {currentProjectIdInUse, newVisitedProject, lastVisitedProject, didEnterProject, missedLeavingSite, isPhysicalSite } = action.payload;

        setSuperProperties({ onSite: didEnterProject && currentProjectIdInUse === newVisitedProject.id,
                             currentLocationProjectId : didEnterProject ? newVisitedProject.id : '' ,
                             currentLocationProjectTitle : didEnterProject ? newVisitedProject.title : '' });

        if (isPhysicalSite) {
          if (missedLeavingSite)
            track("missedLeavingSite", {"projectId": lastVisitedProject.id});

          track(didEnterProject ? "enterSite" : "leaveSite",
                {"projectId": didEnterProject ? newVisitedProject.id : lastVisitedProject.id,
                    "projectTitle": didEnterProject ? newVisitedProject.title : lastVisitedProject.title});
        }
        
        return state.setIn(['lastVisitedProject'], didEnterProject ? newVisitedProject : false);
      }

      case CLEAR_ALL_DATA + '_SUCCESS': {
        return initialState;
      }
  }

  return state;
}
