
import React from "react";
import collapse from '../../assets/img/icons/collapse.png';
import Collapse from '@kunukn/react-collapse';
import theme from '../../assets/css/theme';
import Text from "./Text";
import { CountBar } from "./CountBar";

class CollapsibleSection_DEPRECATED extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.state = {
      open: props.alwaysOpen || false,
      hover: false,
    }
  }

  setHover() {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  }

  setOpen() {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = this.state;
    const { expand } = nextProps;
    
    if (expand != this.props.expand)
      this.setState({ open: expand });
  }
  
  render() {
    const { children, title, height, alwaysOpen, margin, rtl, countBarValue, countBarTotalValue, containerStyle, contentStyle, titleBorderBottom = true, titleStyle, toggleExpend, expand } = this.props;
    const { open, hover } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', boxShadow: '0 0 16px rgba(0, 0, 0, 0.09)', margin: margin || 10, ...containerStyle }}>
        {Boolean(title) &&
          <div style={{ cursor: "pointer", display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", alignItems: 'center', borderBottom: (titleBorderBottom ? 'solid 1px #e1e1e1' : 0), padding: theme.margin, paddingTop: theme.verticalMargin, paddingBottom: theme.verticalMargin, ...titleStyle }}
            onMouseOver={this.setHover}
            onMouseOut={this.setHover}
            onClick={toggleExpend || this.setOpen}>
            <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', display: 'flex', fontSize: 16, height: 44 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {Boolean(!alwaysOpen) && <img src={collapse}
                  style={{ transform: Boolean(open) ? 'rotate(90deg)' : rtl ? 'rotate(180deg)' : '', height: 10, transition: '0.2s' }} />}
                <Text style={{ [rtl ? "marginLeft" : "marginRight"]: 15, color: (hover ? theme.brandPrimary : "") }}>{title}</Text>
              </div>
            </div>
            {Boolean(countBarValue) && <div style={{ width: "25%" }}><CountBar value={countBarValue} totalValue={countBarTotalValue} /></div>}
          </div>}
        <Collapse isOpen={open}>
          <div style={{ height, justifyContent: 'center', alignItems: 'center', display: 'flex', flex: 1, flexDirection: 'column', ...contentStyle }}>
            {children}
          </div>
        </Collapse>
      </div>
    )
  }
}


export default CollapsibleSection_DEPRECATED;
