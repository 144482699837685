import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import withStyles from "@material-ui/core/styles/withStyles";

import { ProjectContext } from "../../../common/projects/contexts";
import { PROJECT_TYPE_COMPLEX_BUILDINGS } from "../../../common/projects/trackProjects.js";
import * as issueStates from "../../../common/issues/issueStates.js";
import postsMessages from "../../../common/posts/postsMessages";

import issuesMessages from "../../../common/issues/issuesMessages";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";
import { getLocationTitle } from "../Locations/funcs";
import TradeBadge from "../../components/CementoComponents/TradeBadge";

class PostHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getLocationTitles = this.getLocationTitles.bind(this);

    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  getLocationTitles(nextProps) {
    const { selectedProjectId, trades, post } = nextProps;

    let tradeText = trades.getNested([(post.trade || {}).id, "getTitle"]);

    let locationsTitle = getLocationTitle(
      nextProps.getNested(["buildings"]),
      nextProps.getNested(["floors"]),
      nextProps.getNested(["units"]),
      nextProps.post.getNested(["location", "building", "id"]),
      nextProps.post.getNested(["location", "floor", "id"]),
      nextProps.post.getNested(["location", "unit", "id"]),
      null,
      nextProps.intl,
      nextProps.projects.getNested([selectedProjectId, "type"]) !=
        PROJECT_TYPE_COMPLEX_BUILDINGS
    );

    return { ...locationsTitle, tradeText };
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      props.post != nextProps.post ||
      props.buildings != nextProps.buildings ||
      props.floors != nextProps.floors ||
      props.units != nextProps.units
    )
      newStateChanges = Object.assign(
        newStateChanges,
        this.getLocationTitles(nextProps)
      );

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const { post, viewMode, rtl } = this.props;
    const { buildingTitle, floorTitle, unitTitle } = this.state;

    if (!post) return null;

    let status = postsMessages.documentation;
    let statusBadgeColor = theme.brandNeutral;
    if (post.issueState == issueStates.ISSUE_STATE_OPENED) {
      status = issuesMessages.openStatus;
      statusBadgeColor = theme.brandDanger;
    } else if (post.issueState == issueStates.ISSUE_STATE_RESOLVED) {
      status = issuesMessages.resolveStatus;
      statusBadgeColor = theme.brandWarning;
    } else if (post.issueState == issueStates.ISSUE_STATE_CLOSED) {
      status = issuesMessages.closeStatus;
      statusBadgeColor = theme.brandSuccess;
    }

    let headerTextStyle = {
      ...theme.subFont,
      color: "#2e231d",
      fontSize: theme.mediumFontSize,
      lineHeight: theme.mediumFontSize + "px",
      width: "auto",
      [rtl ? "marginLeft" : "marginRight"]: theme.paddingSize,
    };
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={styles.postHeaderContainer}>
            <div
              style={{
                height: 7,
                width: 7,
                borderRadius: "50%",
                backgroundColor: statusBadgeColor,
                [rtl ? "marginLeft" : "marginRight"]: 5,
              }}
            />
            <Text style={{ ...headerTextStyle, fontWeight: theme.strongBold }}>
              {status}
            </Text>
          </div>
          {Boolean(buildingTitle) && (
            <div style={styles.postHeaderContainer}>
              <Text style={headerTextStyle}>{buildingTitle}</Text>
            </div>
          )}
          {Boolean(floorTitle) && (
            <div style={styles.postHeaderContainer}>
              <Text style={headerTextStyle}>{floorTitle}</Text>
            </div>
          )}
          {Boolean(unitTitle) && (
            <div style={styles.postHeaderContainer}>
              <Text style={headerTextStyle}>{unitTitle}</Text>
            </div>
          )}
        </div>

        {Boolean(viewMode != "lines") && (
          <div style={styles.postHeaderContainer}>
            {Boolean(post.trade && post.trade.id) && (
              <TradeBadge ids={[post.trade.id]} />
            )}
          </div>
        )}
      </div>
    );
  }
}

let styles = {
  postHeaderContainer: {
    display: "flex",
    alignItems: "center",
  },

  p: {
    overflow: "hidden",
    fontSize: "0.8vw",
    textOverflow: "ellipsis",
    margin: "0px",
    whiteSpace: "wrap",
  },
};

PostHeader = injectIntl(PostHeader);
PostHeader = withStyles(styles)(PostHeader);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    rtl: state.app.rtl,
    trades: state.trades.map,
  }))
);

export default enhance(PostHeader);
