import { uploadImage } from '../images/actions';
import { batchDispatch, isEmptyValue, splitInBatches } from '../app/funcs';
import _ from 'lodash';
import { debugParams, fetchByTS, replaceMaxUpdateTSIfNeeded, notifyLongFunctionDuration } from '../lib/utils/utils';
import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { getAppState } from '../configureMiddleware';
import { platformActions } from "../platformActions";
import * as propertiesTypes from '../propertiesTypes/propertiesTypes';
import { getNewId, updateUsingFirebaseProxy } from '../lib/api';
import ClientServerConnectivityManager from '../lib/ClientServerConnectivityManager';

export const CREATE_PROPERTIES_INSTANCE = 'CREATE_PROPERTIES_INSTANCE';
export const GET_PROPERTIES_INSTANCES = 'GET_PROPERTIES_INSTANCES';
export const PROPERTIES_INSTANCES_DONE_LOADING = 'PROPERTIES_INSTANCES_DONE_LOADING';
export const END_PROPERTIES_INSTANCES_LISTENER = 'END_PROPERTIES_INSTANCES_LISTENER';
export const UPDATE_PROPERTIES_INSTANCE = 'UPDATE_PROPERTIES_INSTANCE';
export const GET_NEW_PROPERTIES_INSTANCE_ID = 'GET_NEW_PROPERTIES_INSTANCE_ID';
export const CLEAN_CACHED_INSTANCES = 'CLEAN_CACHED_INSTANCES';
export const REMOVE_PROPERTIES_INSTANCE = 'REMOVE_PROPERTIES_INSTANCE';
export const GET_NEW_PROPERTY_INSTANCE_ID = 'GET_NEW_PROPERTY_INSTANCE_ID';
export const SAVE_INSTANCES = 'SAVE_INSTANCES';
export const SET_RECENTLY_SIGNING_USERS = 'SET_RECENTLY_SIGNING_USERS';

let localPropInstances = {
  ///     explanation for this weird code: 
  ///     -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  ///      if you connection is really bad but the app doesn't see at as offline mode and you try to upload propInstances:  
  ///         - isConnected = true
  ///         - firebase.update is called with new propInstances
  ///         - firebase listener is called with isLocal:null
  ///         - propInstance may get stuck locally forever
  ///   
  ///      solution:   
  ///         - save each is local prop instance in this map
  ///         - remove from map only when firebase.update().then is happening - it happens only when update is really done
  ///

  map: {}, // locally created or modified prop instances
  isStuckLocal: function (id) {
    return Boolean(this.map[id]);
  },
  set: function (id) {
    this.map[id] = id;
  },
  unset: function (id) {
    if (this.map[id])
      delete this.map[id];
  },

};

export function getPropertiesInstances(viewer, scopeId, subjectName, cleanAll, queryParams = {}) {
  return ({ lokiInstance, realmInstance, platformActions }) => {

    if (cleanAll) {
      setPropertiesInstancesValues([], subjectName, scopeId, realmInstance, lokiInstance, platformActions, cleanAll);
    }

    let didDispatch = false;
    const saveFunc = (_data, isRevoke) => {
      if (debugParams.disableFetchByTSSave)
          return;
      
      setPropertiesInstancesValues(_data, subjectName, scopeId, realmInstance, lokiInstance, platformActions, isRevoke);
      if (!didDispatch) {
        didDispatch = true;
        batchDispatch([{ type: PROPERTIES_INSTANCES_DONE_LOADING, payload: { scopeId, subjectName } }]);
      }
    }

    fetchByTS(
      { scope: 'projects', scopeId },
      { 
        resourceName: 'propertiesInstances',
        firebasePath: `properties/instances/projects/${scopeId}/${subjectName}`,
        queryParams: { ...queryParams, subjectName },
        schemaInfo: schemasInfo.propertyInstances,
        getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, scopeId, subjectName),
        isObjectUseTransactionalDB: true,
      },
      saveFunc,
      viewer,
		)

    return {
      type: GET_PROPERTIES_INSTANCES,
      payload: { scopeId, subjectName }
    };
  };
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId, subjectName) {
  let lastUpdateTS = 0;
  if (platformActions.app.getPlatform() == "web") {
    let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('propertyInstances').chain().find({projectId: scopeId, subjectName}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
  }
  else {
    lastUpdateTS = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId == "${scopeId}" AND subjectName == "${subjectName}"`).max('updatedTS');
  }

  return lastUpdateTS || 0;
}

function filterOutNonIsLocalInstancesThatWasNotUploaded(instance) {
  // check if instance was saved with isLocal:false, but according to localPropInstances.map wasn't uploaded yet
  // for more explanation check out the comments inside localPropInstances object at the top of the file
  return Boolean(instance.isLocal || !localPropInstances.isStuckLocal(instance.id));
}

function setPropertiesInstancesValues(propertiesInstances, subjectName, scopeId, realmInstance, lokiInstance, platformActions, cleanAll) {
  let propInstanceToSave = _.filter(propertiesInstances, filterOutNonIsLocalInstancesThatWasNotUploaded);

  if (propInstanceToSave.length || cleanAll) {
    if (platformActions.app.getPlatform() == "web") {
      saveToLoki(propertiesInstances, subjectName, undefined, scopeId, lokiInstance, null, cleanAll);
    } else
      notifyLongFunctionDuration(() => saveToRealm(propertiesInstances, subjectName, undefined, scopeId, realmInstance, null, cleanAll, platformActions),
        `saveToRealm ~ The function took too long`, `propertiesInstances`)
  }
}

function saveToLoki(propertiesInstances = {}, subjectName, lastUpdateTS, scopeId, lokiInstance, ignoreTimestamp, cleanAll) {
  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return
  
  let allInstances = [];
  let allDeletedIds = [];

  propertiesInstances = Object.values(propertiesInstances).sort((propInstanceA, propInstanceB) => ((propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1));


  (propertiesInstances).forEach(propInstance => {
    propInstance.isDeleted
      ? allDeletedIds.push(propInstance.id)
      : allInstances.push({ ...propInstance.realmToObject(), subjectName: subjectName, projectId: scopeId });
  });

  if (cleanAll) {
    lokiInstance.getCollection('propertyInstances').cementoDelete({ projectId: scopeId, subjectName: subjectName });
    }  

  if (allDeletedIds.length)
    lokiInstance.getCollection('propertyInstances').cementoDelete({ id: { '$in': allDeletedIds } });

  lokiInstance.getCollection('propertyInstances').cementoUpsert(allInstances);
}

function saveToRealm(propertiesInstances, subjectName, lastUpdateTS, scopeId, realmInstance, ignoreTimestamp, cleanAll, platformActions) {
  let instancesWithoutPropId = new Set();
  let instancesWithoutParentId = new Set();
  if (propertiesInstances) propertiesInstances = _.pickBy(propertiesInstances, (x) => {
    if (x.isDeleted)
      return true;

    if (!x.propId) {
      instancesWithoutPropId.add(x.id);
    }
    if (!x.parentId) {
      instancesWithoutParentId.add(x.id);
    }

    return Boolean(x.propId && x.parentId);
  });

  if (instancesWithoutPropId.size) {
    platformActions.sentry.notify('propInstances without propId ', { instancesWithoutPropId: Array.from(instancesWithoutPropId) });
  }
  if (instancesWithoutParentId.size) {
    platformActions.sentry.notify('propInstances without propId ', { instancesWithoutPropId: Array.from(instancesWithoutPropId) });
  }

  if (!cleanAll && Object.keys(propertiesInstances || {}).length == 0)
    return;

  let propertiesInstancesValuesArray = Object.values(propertiesInstances || {});
  if (!cleanAll && propertiesInstancesValuesArray.length == 0)
    return;

  propertiesInstances = propertiesInstancesValuesArray.sort((propInstanceA, propInstanceB) => (propInstanceA.updatedTS || 0) > (propInstanceB.updatedTS || 0) ? -1 : 1);
  let currBatchMaxLastUpdateTS = _.get(propertiesInstances, [0 , 'updatedTS'], 0);
  let realm = realmInstance.propertyInstances;

  const deletedIdList = []

  realm.beginTransaction();
  try {
    if (cleanAll) {
      let allLocPropsInstances = realm.objects('propertyInstance1').filtered(`projectId = "${scopeId}" AND subjectName = "${subjectName}"`);
      realm.delete(allLocPropsInstances);
    }

    (propertiesInstances).forEach(propInstance => {

      let stringData = propInstance && propInstance.data !== null && propInstance.data !== undefined ? JSON.stringify(propInstance.data) : null;

      if (propInstance && propInstance.id) {
        let valueScope = propInstance.valueScope;
     
        if (!propInstance.isDeleted) {
          let propInstanceObj = { ...propInstance.realmToObject(), data: stringData, subjectName: subjectName, projectId: scopeId, valueScope, isLocal: (propInstance.isLocal ? true : null) };
          realm.create('propertyInstance1', propInstanceObj, 'modified');
        }
        else {
          deletedIdList.push(propInstance.id)
        }
           
      }
      else
        platformActions.sentry.notify('propertyInstance missing ID', propInstance);
    });
    
    if (deletedIdList?.length) {
			try {
				const allDeletedInstances = realm
					.objects('propertyInstance1')
					.filtered(`projectId = "${scopeId}" AND id IN {"${deletedIdList.join('", "')}"}`);
        
        realm.delete(allDeletedInstances);
			} catch (error) {
				console.log('____ERRROR', error);
			}
		}

    replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'propertyInstance1', `projectId = "${scopeId}" AND subjectName = "${subjectName}"`, subjectName);

    realm.commitTransaction();
  } catch (e) {
    realm.cancelTransaction();
    throw e;
  }
}

export function removePropertiesInstancesFromRealm(realmInstance, subjectName) {
  let propertiesInstances = realmInstance.propertyInstances;
  let allLocPropInstances = propertiesInstances.objects('propertyInstance1').filtered('subjectName = "' + subjectName + '"');
  
  propertiesInstances.beginTransaction();
  try {
    propertiesInstances.delete(allLocPropInstances);
    propertiesInstances.commitTransaction();
  } catch (e) {
    propertiesInstances.cancelTransaction();
    throw e;
  }
}

export async function removePropertiesInstancesFromLoki(lokiInstance, subjectName, legacyInstancesDelete) {
  await lokiInstance.getCollection('propertyInstances').cementoFullDelete({ subjectName: subjectName });
}

export function uploadPropertiesInstances(projectId, propertiesInstancesArray, subjectName, shouldWaitForUpload = true) {
	return ({ firebaseDatabase, dispatch }) => {
		const getPromise = async () => {
			if (!projectId || !propertiesInstancesArray || propertiesInstancesArray.length === 0)
        return; 
        
			let propertiesInstancesToUpload = [];
			
			propertiesInstancesArray.forEach(currPropInstance => {
        if (!currPropInstance)
          return; // TODO: report

        currPropInstance = currPropInstance.realmToObject();
				if (currPropInstance.id) {
					if (_.isNil(currPropInstance.data))
						currPropInstance.isDeleted = true;
					propertiesInstancesToUpload.push(currPropInstance.realmToObject());
				}
				else {
          let newId = getNewId()
					propertiesInstancesToUpload.push({ id: newId, ...currPropInstance });
				}
			});
      
      const promise = dispatch(saveInstances(projectId, subjectName, propertiesInstancesToUpload));
      if (!shouldWaitForUpload)
        return {
          instances: propertiesInstancesToUpload,
          projectId,
          subjectName,
          success: undefined
        };
      else
        return await promise;
    };
    
    return { 
      type: UPDATE_PROPERTIES_INSTANCE,
      payload: getPromise()
    };
	};
}

const IS_EXPELLED_PROP_ID = '-isExpelled';
const ID_NUMBER_PROP_ID = '-idNumber';
const saveInstances = (projectId, subjectName, instances, originalInstances = undefined) => {
  const getPromise = async () => {
    let success = null;
    if (projectId && subjectName && Object.keys(instances || {}).length) {
      instances = Object.values(instances)
                    .filter(instance => _.get(instance, 'data') !== undefined)
                    .map(instance => {
                      if (instance && instance.data && instance.propType === propertiesTypes.STRING) {
                        instance.data = instance.data.trim();
                      }

                      if (instance && isEmptyValue(instance.data)){
                        instance.isDeleted = true;
                      };

                      return { ...instance, subjectName, projectId };
                    });
  
      const actionRes = await uploadObjectsDispatcher({
        projectId,
        objectsToSave: instances,
        originalObjects: originalInstances,
        schemaInfo: schemasInfo.propertyInstances
      });
  
      success = actionRes.success;
      const employeeExpelledPropInstances = instances.filter(instance => instance.propId === IS_EXPELLED_PROP_ID);
      if (subjectName === propertiesTypes.SUBJECT_NAMES.employees && employeeExpelledPropInstances.length) {
        let filterQuery;
        const isNative = platformActions.app.isNative();
        if (isNative) {
          const parentIdConditions = employeeExpelledPropInstances.map(instance => `parentId == "${instance.parentId}"`).join(" OR ");
          filterQuery = `
          projectId == "${projectId}" AND 
          subjectName == "${subjectName}" AND 
          propId == "${ID_NUMBER_PROP_ID}" AND
          (${parentIdConditions})
        `;
        } else {
          filterQuery = {
            projectId,
            subjectName,
            propId: ID_NUMBER_PROP_ID,
            parentId: { $in: employeeExpelledPropInstances.map(instance => instance.parentId) },
          };
        }
        const localDB = platformActions.localDB.getCementoDB();
        const data = _.groupBy(localDB.get('propertyInstances', filterQuery) || [], 'parentId');
        let expelledEmployeesIds = [];
        let unexpelledEmployeeIds = [];
        employeeExpelledPropInstances.forEach(instance => {
          const parentId = _.get(instance, ['parentId']);
          const idNumber = _.get(data, [parentId, 0, 'data']);
          if (instance.data) {
            expelledEmployeesIds.push(idNumber);
          } else {
            unexpelledEmployeeIds.push(idNumber);
          }
        });
        
        if (expelledEmployeesIds.length) {
          platformActions.mixpanel.trackWithProperties("Expelled employees", { expelledEmployeesIds });
        }

        if (unexpelledEmployeeIds.length) {
          platformActions.mixpanel.trackWithProperties("Unexpelled employees", { unexpelledEmployeeIds });
        }
      }
    }

    return {
      projectId,
      subjectName,
      instances,
      success,
    };
  }

  return {
    type: SAVE_INSTANCES,
    payload: getPromise()
  }
}

export function removePropertiesInstance(projectId, subjectName, instanceId) {
  return ({ firebaseDatabase, removeEmpty, dispatch, realmInstance }) => {
    const getPromise = async () => {
      let success = true;
      if (!projectId || !subjectName || !instanceId)
        return;

      try {
        let path = `properties/instances/projects/${projectId}/${subjectName}/${instanceId}`;
        const updates = {
          [path]: {
            isDeleted: true,
            updatedTS: new Date().getTime()
          }
        };
        await updateUsingFirebaseProxy({ projectId, type: 'properties_instances', updates });
      }
      catch (err) {
        success = false;
      }
      return { success };
    };
    return {
      type: REMOVE_PROPERTIES_INSTANCE,
      payload: getPromise()
    };
  };
}

export function setRecentlySigningUsers(userId, projectId, signaturesContext){
  return {
    type: SET_RECENTLY_SIGNING_USERS,
    payload: { userId, projectId, signaturesContext }
  }
}


export function endPropertiesInstancesListenerByType({ scopeId, subjectName, queryParams = {} }) {	
   ClientServerConnectivityManager.unregisterService({
     scope: 'projects',
     scopeId,
     subject: 'propertiesInstances',
     params: {
       resourceName: 'propertiesInstances',
       firebasePath: `properties/instances/projects/${scopeId}/${subjectName}`,
       queryParams: { ...queryParams, subjectName },
       schemaInfo: schemasInfo.propertyInstances,
       isObjectUseTransactionalDB: true,
     },
   });
   return {
     type: END_PROPERTIES_INSTANCES_LISTENER,
     payload: { scopeId, subjectName, queryParams },
   };
}