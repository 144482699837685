import { batchDispatch } from '../app/funcs';
import { getSnapshotData } from '../lib/utils/utils';

export const GET_TITLES = 'GET_TITLES';
export const TITLES_LOADING = 'TITLES_LOADING';

export function getTitles(viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'global' };
    const resourceParams = {
      resourceName: 'titles',
      firebasePath: `titles`,
      forceMSClientConfig: true,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_TITLES, payload: { titles: data, lastUpdateTS: 1 } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      batchDispatch([{
        type: GET_TITLES,
        payload: { titles: result }
      }]);
    }
  }

  return {
    type: TITLES_LOADING,
    payload: getPromise(),
  };
}
