import React from "react";
import { connectContext } from 'react-connect-context';
import { columnTypes } from '../../../common/analytics/funcs'; 
import { ReportContext } from '../../../common/analytics/contexts'; 

// Assets
import theme from "../../assets/css/theme";
import toggleRight from '../../assets/img/icons/toggleRight.png';
import toggleLeft from '../../assets/img/icons/toggleLeft.png';

import TradeBadge from "../../components/CementoComponents/TradeBadge";
import Text from "../../components/CementoComponents/Text";

export const newColumnWidth = 126-1;
export const columnColor = 'white';

const singleValueTopMargin = 7;
const tradeBadgeSize = 20;

class TableColumnHeader extends React.Component {

  constructor(props) {
    super(props);
    const { currSubColumn } = props;
    let width = 0;
    if (currSubColumn && currSubColumn.columnType == columnTypes.main)
      width = newColumnWidth;
    this.state = {width};
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.setColumnWidth    = this.setColumnWidth.bind(this);
  }

  componentDidMount() {
    const { width } = this.props;
    if (!width)
      this.setColumnWidth(newColumnWidth);
  }

  setColumnWidth(val) {
    const { currSubColumn } = this.props;
    if (currSubColumn && currSubColumn.id == 'locations-group-table-locationsColumn')
      val = 860;
    this.setState({ width: val || 0 });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.state != nextState) || (this.props.columnTrades != nextProps.columnTrades))
      return true;

    if (nextProps.currSubColumn == this.props.currSubColumn && 
        (!this.props.isValDiff(nextProps, ['sortColumn'])) &&
        (!this.props.isValDiff(nextProps, ['expandedColumns'])))
      return false;

    if (((nextProps.currSubColumn == null) && (this.props.currSubColumn != null)) ||
        ((nextProps.currSubColumn != null) && (this.props.currSubColumn == null))) {
      return true;
  }
    var shouldComponentUpdate = (
      (this.props.columnTrades == null || nextProps.columnTrades == null) ||
      (this.props.currSubColumn == null || nextProps.currSubColumn == null) ||
      (this.props.currSubColumn.columnType != nextProps.currSubColumn.columnType) ||
      (this.props.currSubColumn.description != nextProps.currSubColumn.description) ||
      (this.props.currSubColumn.title != nextProps.currSubColumn.title) ||
      (this.props.visibleRows != nextProps.visibleRows) ||
      (this.props.sortColumn != nextProps.sortColumn) ||
      (this.props.expandedColumns != nextProps.expandedColumns)
   )

    return shouldComponentUpdate;
  }

  mouseEnterHandler() {
    this.setState({ isMouseInside: true });
  }

  mouseLeaveHandler() {
    this.setState({ isMouseInside: false });
  }

  render() {
    const { currSubColumn, rtl, mainColumnClickHandler, expandedColumns, sortColumn, columnTrades, isFirstColumn, innerItem, columnType, expandableTable, sortableTable, secRowHeight, textStyle, children } = this.props;
    const { isMouseInside, width } = this.state;
    let isMainColumn = Boolean(columnType == columnTypes.main);
    let isExpandable = Boolean(currSubColumn.subColumns);
    let columnHeaderHeight = isMainColumn ? secRowHeight : secRowHeight-singleValueTopMargin;

    let isExpanded = false;

    if (expandableTable)
      isExpanded = Boolean(expandedColumns) && (expandedColumns == currSubColumn.checklistId);
    
    if (isExpanded && !innerItem)
      return null;

    const zIndex = isExpanded ? theme.zIndexesLevels.twelve : theme.zIndexes.tableColumnHeader + 1;
    return (
      <div 
        style={{
          height: secRowHeight,
          position: 'relative',
          cursor: isExpandable ? 'pointer' : null,
          paddingTop: isMainColumn ? 0 : singleValueTopMargin
        }}
         className={(Boolean(isFirstColumn) ? null : "header")}
         onClick={() => {
              mainColumnClickHandler(currSubColumn);
          }}
      >
      {!isMainColumn && <div style={{background: theme.brandPrimary, position: 'absolute', height: 7, top: 0, [rtl ? 'right' : 'left']: -1, [rtl ? 'left' : 'right']: -1, zIndex: theme.zIndexes.tableColumnHeader}}></div>}
        <div 
          className={(Boolean(isFirstColumn) ? (rtl ? "shadow-right column-shadow-right" : "shadow-left column-shadow-left") : null)}
          style={{
          height: columnHeaderHeight,
          position: 'relative',
        }}>

        <div style={{background: (isExpanded && isMainColumn ? 'rgb(242, 125, 34)' : 'rgb(215, 215, 215)'), position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, zIndex}}/>
          <div 
            onMouseEnter={this.mouseEnterHandler} 
            onMouseLeave={this.mouseLeaveHandler}
            style={{ 
              zIndex,
              maxHeight: columnHeaderHeight,
              height: columnHeaderHeight,
              margin: 0,
              backgroundColor: isExpanded && isMainColumn ? theme.brandPrimary : columnColor,
              textAlign: Boolean(rtl) ? '-webkit-left' : '-webkit-center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              position: 'absolute', 
              top: 0, 
              bottom: 0, 
              right: 0, 
              left: 0,
            }}
            >
            {
              Boolean(children)
                ? children
                : <>
                  <div style={{
                    whiteSpace: 'normal',
                    overflow: 'visible',
                    lineHeight: 'normal',
                    padding: '10px 10px 0px 10px',
                    textAlign: Boolean(rtl) ? 'start' : 'start',
                    textOverflow: 'ellipsis',
                    maxHeight: 90,
                  }}>
                    <Text title={currSubColumn.title} 
                    style={{ 
                      display:'-webkit-box',
                      fontWeight: theme.bold, 
                      color: isExpanded && isMainColumn ? "white" : theme.fontColor, 
                      fontSize: theme.fontSize,
                      textOverflow: 'ellipsis',
                      webkitLineClamp: '3',
                      webkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                       ...textStyle
                        }}>
                      {Boolean(currSubColumn.itemNumber != null) ? currSubColumn.itemNumber.toString().concat(". ").concat(currSubColumn.title) : currSubColumn.title}
                    </Text>

                    {Boolean(expandableTable && isExpandable) &&
                      <img
                        src={Boolean(isExpanded && isMainColumn) ? toggleLeft : toggleRight}
                        style={{
                          transform: rtl ? 'rotate(180deg)' : '',
                          height: 10, opacity: isMouseInside ? 1 : 0, transition: 'opacity 0.5s', position: 'absolute', bottom: tradeBadgeSize + theme.margin, [rtl ? 'left' : 'right']: theme.margin
                        }} />}
                  </div>

                  {Boolean(columnTrades) &&
                    <div style={{ backgroundColor: isExpanded && isMainColumn ? theme.brandPrimary : columnColor, display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'absolute', bottom: 0, width: '100%' }}>
                      <TradeBadge ids={Object.keys(columnTrades)} mode={'min'} maxBadges={3} />
                    </div>}
                </>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connectContext(ReportContext.Consumer)(TableColumnHeader)