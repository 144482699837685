import React from "react";
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import theme from "../../common/app/theme";
import Text from "../components/CementoComponents/Text";

function FeatureNotActivePage (props) {
    const { title, content, intl } = props;
    if (!title)
        return;

    return (
        <div
            style={{
              justifyContent: "start",
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div style={{ flexDirection: "column", margin: theme.margin * 3 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: theme.fontSizeH3,
                  color: theme.brandPrimary,
                }}
              >
                {intl.formatMessage(title)}
              </Text>
              <br />
              {Boolean(content) && (<Text style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
              {intl.formatMessage(content)}
            </Text>)}
            </div>
          </div>
    ) 
}

const enhance = compose(injectIntl);
export default enhance(FeatureNotActivePage);