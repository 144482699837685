import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';

const SubCategory = Record({  
  id: null,
  types: null,
  title: null,
  color: null,
  trade: null,
  systemTitle: null
},'subCategory');

export default SubCategory;
