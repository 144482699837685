import React from 'react';
import { injectIntl } from 'react-intl';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { connectContext } from 'react-connect-context';
import { FiltersSortsContext, ProjectContext } from '../../common/projects/contexts';
import { compose, hoistStatics } from 'recompose';
import { Outlet } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Header } from '../components';
import { headerMainRoutes, containerRoutes } from '../routes/main.js';
import logo from '../assets/img/logo.png';
import theme from '../assets/css/theme';
import { setAdminMode } from '../../common/users/actions';
import { saveUIParams, draftValidator } from '../../common/ui/actions';
import withRouterHOC from '../components/Router/util/withRouterHOC.js';

const qs = require('qs');

class AppContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.onRouteSelected = this.onRouteSelected.bind(this);

    this.state = {
      menuSelectedValues: null,
      secondaryRoutes: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  componentDidMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  onRouteSelected(r) {
    let secondaryRoutes = r.routes;
    let sidebarName = r.sidebar;
    this.setState({ secondaryRoutes, sidebarName });
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    const { adminUsers } = nextProps;

    if (
      props.getNested(['viewer', 'id']) != nextProps.getNested(['viewer', 'id']) ||
      props.getNested(['originViewer', 'id']) != nextProps.getNested(['originViewer', 'id']) ||
      props.getNested(['adminUsers']) != nextProps.getNested(['adminUsers'])
    ) {
      const isCementoTeamViewer =
        (nextProps.viewer && adminUsers && adminUsers.getNested([nextProps.viewer.id])) ||
        (nextProps.originViewer && adminUsers && adminUsers.getNested([nextProps.originViewer.id]));

      nextProps.saveUIParams({ isCementoTeamViewer: isCementoTeamViewer });
      newStateChanges.isCementoTeamViewer = isCementoTeamViewer;
    }

    if (props.width != nextProps.width) nextProps.saveUIParams({ screenBootstrapWidth: nextProps.width });

    if (props.getNested(['location', 'search']) != nextProps.getNested(['location', 'search'])) {
      let res = this.getUrQueryInfo(nextProps);
      newStateChanges.sorts = res.sorts;
      newStateChanges.filters = res.filters;
      newStateChanges.filtersView = res.filtersView;
    }
    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  getUrQueryInfo(nextProps) {
    let info = {};
    // Filters params on URL
    let queryParams = qs.parse(nextProps.getNested(['location', 'search'], '?').replace('?', ''));
    let index = 0;
    info.sorts = [];
    info.filters = [];
    if (queryParams.sort) info.sorts.push(queryParams.sort);
    //while (queryParams && queryParams['key'+index]) {
    //  let obj = { key: queryParams['key'+index].replace(/-/g, '\/'), value: queryParams['value'+index] };
    //  if (queryParams['key'+index] == 'view')
    //    info.filtersView = obj;
    //  else if (queryParams['key'+index] == 'sortMenu')
    //    info.sorts.push(obj)
    //  else
    //    info.filters.push(obj)
    //  index++
    //}

    if (info.filters && info.filters.length == 0) info.filters = null;
    return info;
  }

  render() {
    const { intl, rtl, location } = this.props;
    const { isCementoTeamViewer } = this.state;
    return (
      <FiltersSortsContext.Provider value={this.state}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Header
            rtl={rtl}
            intl={intl}
            logo={logo}
            location={location}
            isCementoTeamViewer={isCementoTeamViewer}
            routes={headerMainRoutes} // NAVIGATION_TODO: fix
            onRouteSelected={this.onRouteSelected}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 'calc(100vh - ' + theme.headerHeight + 'px)',
              backgroundColor: theme.backgroundColorBright,
            }}>
            <Outlet />
          </div>
        </div>
      </FiltersSortsContext.Provider>
    );
  }
}

const enhance = compose(
  injectIntl,
  withRouterHOC,
  withWidth(),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
      headerTitle: state.ui.headerTitle,
      uiParams: state.ui.uiParams,
      originViewer: state.users.originViewer,
      adminUsers: state.quasiStatics.adminUsers,
    }),
    { setAdminMode, saveUIParams, draftValidator }
  )
);
export default enhance(AppContainerPage);
