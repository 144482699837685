import { Record } from '../transit';

const Unit = Record({
  id: null,
  title: null,
  floor: null,
  tenants: null,
  type: null,
  locationType: null,
  ordinalNo: null
}, 'unit');

export default Unit;
