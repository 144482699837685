import _ from 'lodash';
import { ROUTINE } from '../../../checklists/checklistTypes';
import { unknownCompanyId } from '../../../companies/companiesTypes';
import { errorCodes } from '../../../lib/errors/extraError';
import { platformActions } from '../../../platformActions';
import { uploadImage } from '../../../images/actions';
import { v4 as uuidv4 } from "uuid";

export const addChecklistsAndStagesToPosts = (posts, currentStagesMap, checklistsMap, checklistInstancesMap) => {
	if (!checklistsMap || !posts || !checklistInstancesMap || !currentStagesMap) {
		return posts;
	}

	return posts.map(post => {
		if (!post.checklistItemInstance?.id || !checklistInstancesMap[post.checklistItemInstance?.id]) {
			return post;
		}
		const { checklistId, id: checklistItemInstanceId } = checklistInstancesMap[post.checklistItemInstance?.id];

		const {
			stageId,
			description: checklistTitle,
			ordinalNo: checklistOrdinalNo,
			locations,
			type,
		} = checklistsMap.getIn([checklistId], {});
		
		const stage = currentStagesMap?.getIn([stageId], {});
 
		if (!locations && type !== ROUTINE) {
			return post;
		}
		const stageTitle = stage.getCementoTitle();

		return {
			...post,
			stageOrdinalNo: stage.ordinalNo,
			stageId,
			stageTitle,
			checklistOrdinalNo,
			checklistId,
			checklistTitle,
			checklistItemInstanceId,
			checklistFullTitle: `${stageTitle} - ${checklistTitle}`,
		};
	});
};

export const setCompanyIdAndGroupsToOwnerAndAssignToOnPosts = (posts, members) => {
	return Object.values(posts || {}).map(post => {
		let newDataToAssign = {};
		['owner', 'assignTo'].forEach(memberPropKey => {
			const memberId = _.get(post, [memberPropKey, 'id']);
			if (!memberId) return;

			const memberCompanyId = _.get(members, [memberId, 'companyId'], unknownCompanyId);
			const memberGroups = _.get(members, [memberId, 'groups']);
			const memberDisplayName = _.get(members, [memberId, 'displayName']);

			const newMemberProp = { 
				...post[memberPropKey],
				companyId: memberCompanyId,
				groups: memberGroups,
				displayName: memberDisplayName,
			};

			newDataToAssign[memberPropKey] = newMemberProp;
		});

		return { ...post, ...newDataToAssign };
	});
};

export const addInstancesValuesToPosts = (posts, postsInstances) => {
	if (!posts || !postsInstances) return posts;

	let postsMap = {};
	posts.forEach(post => {
		postsMap[post.id] = Object.assign({}, post);
	});

	postsInstances.forEach(instance => {
		const postId = instance.parentId;
		const propId = instance.propId;
		if (!postId || !propId) return;

		const currentProp = postsMap[postId];
		if (currentProp) _.set(currentProp, ['instances', propId], instance);
	});

	return _.values(postsMap);
};

export const addLocationIdToPost = posts => {
	if (!posts) return posts;

	return posts.map(post => {
		const locationId = post.location?.unit?.id || post.location?.floor?.id || post.location?.building?.id;
		if (!locationId) {
			return post;
		}
		return {
			...post,
			locationId: { [locationId]: true },
		};
	});
};

// function to create a query. filterVal = value to query, data = [{source : [array of data], key : which field to search }]
// if the field you want to search is in different array,  pass another object in the data array. Example:  [{ source: allMembers, key: ["companyId"] }, { source: allCompanies, key: ["name"] }] .
// here we are looking for members that their companyName begins with filterVal
export const generateLokiQuery = (filterVal, data, path) => ({
	$or: Object.values(
		data[0].source
			.filter(item =>
				data.length < 2
					? item.getNested(data[0].key, '').toLowerCase().includes(filterVal)
					: data[1].source.getNested([item.getNested(data[0].key)])
					? data[1].source
							.getNested([item.getNested(data[0].key)], {})
							.getNested(data[1].key, '')
							.toLowerCase()
							.includes(filterVal)
					: false,
			)
			.map(item => ({ [path]: item.get('id') }))
			.toJS(),
	),
});

export const getDeletedMembersWithData = ({ selectedProjectId, detailedProjects, allMembers }) => {
	const deletedMembersNaked = detailedProjects?.getNested([selectedProjectId, 'deletedMembers']);
	if (!deletedMembersNaked) return {};
	const allMembersObject = allMembers.toJS();
	const deletedMembersWithData = Object.keys(deletedMembersNaked).reduce((acc, memberId) => {
		acc[memberId] = allMembersObject[memberId];
		return acc;
	}, {});
	return deletedMembersWithData;
};

  
export const uploadPostFiles = async ({ projectId, files, postId, viewer, isUploadRetry }) => {
	let newPostFiles = {};

	for (const file of _.values(files)) {
		if (!file.id) file.id = uuidv4();

		if (file.uri?.startsWith?.('http')) {
			newPostFiles[file.id] = file;
			continue;
		}

		try {
			const targetFileName = `${projectId}/${postId}_${file.id}`;
			const uri = await uploadImage(file, targetFileName, 'posts');

			if (uri?.startsWith('http')) {
				file.uri = uri;
				delete file.isLocal;
				delete file.uploading;
			} else if (
				!file.uri ||
				(file.uri.startsWith?.('file:/') && !(await platformActions.fs.exists(file.uri.replace('file:/', ''))))
			) {
				throw new ExtraError('Post file is missing', null, null, errorCodes.MISSING_FILE);
			}
		} catch (err) {
			let errorMessage = 'error uploading image';
			if (_.get(err, ['errorCode']) == errorCodes.MISSING_FILE) {
				errorMessage = 'error uploading image - post with missing file';
			}
			platformActions.sentry.notify(errorMessage, {
				isUploadRetry,
				fileId: file.id,
				projectId,
				uri: file.uri,
				postId,
				viewerId: _.get(viewer, ['id']),
			});
		} finally {
			newPostFiles[file.id] = file;
		}
	}

	return newPostFiles;
};