import React from "react";
import { injectIntl } from "react-intl";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Floor from "./Floor.js";
// styles
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

class Building extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {
      sortedFloors: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { floors, buildingId } = props;
    let newStateChanges = {};

    if (
      (floors || {}).getNested([buildingId]) !=
      nextProps.floors.getNested([nextProps.buildingId]) ||
      props.isValDiff(nextProps, ['configurations', 'webUIParams', 'reverseFloorOrder'])
    ) {
      let selectedProjectFloors = nextProps.floors.getNested(
        [nextProps.buildingId],
        {}
      );
      newStateChanges.sortedFloors = Object.values(
        selectedProjectFloors.toJS
          ? selectedProjectFloors.toJS()
          : selectedProjectFloors
      ).sort((a, b) => (b.ordinalNo || b.num) - (a.ordinalNo || a.num));

      if (nextProps.getNested(['configurations', 'webUIParams', 'reverseFloorOrder'], false)) {
        newStateChanges.sortedFloors.reverse();
      }
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const {
      buildingId,
      onClick,
      stickyMode,
      maxUnitsInFloor,
      showBadges,
      filter,
      filterLocations,
    } = this.props;
    const { sortedFloors } = this.state;

    let filteredSortedFloors = sortedFloors;
    if (filter.type === "buildings") filteredSortedFloors = [];
    else if (filter.type === "floors")
      filteredSortedFloors = filterLocations(sortedFloors, filter);

    return (
      <div
        style={{
          height: "inherit",
          width: "100%",
          overflow: "initial",
          position: "absolute",
        }}
      >
        {filteredSortedFloors.map((currFloor) => (
          <Floor
            showBadges={showBadges}
            key={currFloor.id}
            stickyMode={stickyMode}
            onClick={onClick ? onClick : undefined}
            buildingId={buildingId}
            floorId={currFloor.id}
            maxUnitsInFloor={maxUnitsInFloor}
          />
        ))}
      </div>
    );
  }
}

Building = withStyles(buttonStyle)(Building);
Building = injectIntl(Building);
const enhance = compose(connectContext(ProjectContext.Consumer));
export default enhance(Building);
