import * as actions from './actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import { Map } from 'immutable';
import { Record } from '../transit';
import { DRAWINGS_KEY } from '../drawings/reducer';
import { CLEAR_ALL_DATA } from '../app/actions';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record(
  {
    map: Map(),
    companiesMap: Map(),
    global: Map(),
    didLoad: Map(),
    debugConfigurations: Map(),
  },
  'configurations'
);

const initialState = new InitialState();

export default function configurationsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case PROJECT_EVENTS.LEAVE_PROJECT: {
      const { projectId } = action.payload || {};
      if (!projectId) return state;

      return state.setIn(['didLoad', projectId], false);
    }
    case actions.CONFIGURATIONS_LOADING + '_SUCCESS': {
      const { scope, scopeId } = action.payload || {};

      if (!scope || (scope !== 'global' && !scopeId)) return state;

      if (!state.getIn(['didLoad', scopeId || 'global'])) {
        state = state.setIn(['didLoad', scopeId || 'global'], false);
      }

      return state;
    }

    case actions.GET_CONFIGURATIONS: {
      const { configurations, scopeType, scopeId } = action.payload || {};

      if (!scopeType || (scopeType !== 'global' && !scopeId)) return state;

      if (configurations) {
        if (scopeType === 'global') {
          state = state.setIn(['global'], new Map({ ...configurations }));
        } else {
          const mergedDefaultConfig = _.merge(
            {},
            scopeType === 'companies' ? {} : DEFAULT_PROJECT_CLIENT_MS_CONFIGURATIONS,
            configurations
          );
          state = state.setIn([scopeType == 'companies' ? 'companiesMap' : 'map', scopeId], mergedDefaultConfig);
        }
      }

      if (!state.getIn(['didLoad', scopeId || 'global'])) {
        state = state.setIn(['didLoad', scopeId || 'global'], true);
      }
      return state;
    }

    case lastUpdatesActions.GET_SYNCED: {
      const projects = _.get(action, ['payload', 'projects']);

      _.forIn(projects, (project, projectId) => {
        if (project.config || _.isEqual(project.config, state.getNested(['debugConfigurations', projectId])))
          state = project.config
            ? state.setIn(['debugConfigurations', projectId], project.config)
            : state.removeIn(['debugConfigurations', projectId]);
      });

      const global = _.get(action, ['payload', 'global']);
      if (global) {
        const newGlobal = new Map({ ...state.global.toJS(), ...global });
        state = state.setIn(['global'], newGlobal);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}

const DEFAULT_PROJECT_CLIENT_MS_CONFIGURATIONS = [
  { type: 'listeners', path: ['propertiesInstances'] },
  { type: 'listeners', path: ['checklistInstances'] },
  { type: 'listeners', path: ['employees'] },
  { type: 'listeners', path: ['equipment'] },
  { type: 'listeners', path: ['forms'] },
  { type: 'listeners', path: ['posts'] },
  { type: 'listeners', path: ['reports'] },

  { type: 'bulk', path: ['configurations'] },
  { type: 'bulk', path: ['stages'] },
  { type: 'bulk', path: ['checklists'] },
  { type: 'bulk', path: ['checklistItems'] },
  { type: 'bulk', path: ['checklistSubscriptions'] },
  { type: 'bulk', path: [DRAWINGS_KEY] },
  { type: 'bulk', path: ['buildings'] },
  { type: 'bulk', path: ['floors'] },
  { type: 'bulk', path: ['units'] },
  { type: 'bulk', path: ['physicalIds'] },
  { type: 'bulk', path: ['projects'] },
  { type: 'bulk', path: ['properties'] },
  { type: 'bulk', path: ['comments'] },
  { type: 'bulk', path: ['users'] },
  { type: 'bulk', path: ['titles']},
  { type: 'bulk', path: ['trades']},
  { type: 'bulk', path: ['grades'] },
].reduce((acc, { type, path }) => {
  return _.set(acc, ['clientMS', 'V2', type, ...path], { isActive: true });
}, {});
