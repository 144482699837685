import React from 'react';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import theme from '../../assets/css/theme';
import Text from './Text';
import systemMessages from '../../../common/app/systemMessages';

const defaultProps = {
  hasModifications: false,
  onClickSave: () => {},
  onClickCancel: () => {},
  onClickEdit: () => {},
  allowToggle: true,
  isEditMode: false,
  mainContainerStyle: {},
}

class EditModeToggle extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick(onClickFunc) {
		const { allowToggle, isEditMode } = this.props;

		if (!allowToggle && !isEditMode)
			return;

		if (onClickFunc) onClickFunc();
	}

	render() {
		const { isEditMode, hasModifications, onClickSave, onClickCancel, onClickEdit, allowToggle, mainContainerStyle } = this.props;
		const { mainContainer, buttonContainer, textButton } = styles;
		return (
			<div style={{ ...mainContainer, ...mainContainerStyle }}>
				{Boolean(isEditMode)
						? (
              <>
                <div style={{ ...buttonContainer, color: (hasModifications ? theme.brandPrimary : theme.brandNeutralLight) }} onClick={() => this.onClick(onClickSave)}>
                  <Text style={{ ...textButton, fontWeight: theme.strongBold }}>{propertiesMessages.save}</Text>
                </div>
                <div style={buttonContainer} onClick={() => this.onClick(onClickCancel)}>
                  <Text style={textButton}>{propertiesMessages.cancel}</Text>
                </div>
              </>
            ) : (
              <div 
                style={{
                  ...buttonContainer,
                  ...(!allowToggle ? { cursor: 'not-allowed', color: theme.brandNeutralLight } : {}),
                }}
                onClick={() => this.onClick(onClickEdit)}
              >
                <Text style={textButton}>{systemMessages.edit}</Text>
              </div>
            )}
			</div>
		);
	}
}

EditModeToggle.defaultProps = defaultProps;

const styles = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
    cursor: 'pointer',
	},
	textButton: {
		margin: theme.verticalMargin,
		fontSize: theme.fontSizeH5 - 2,
	},
};

export default EditModeToggle;