import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { Outlet } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  saveUIParams,
} from "../../../common/ui/actions";
import { envParams } from "../../../common/configureMiddleware";
import {
  startLoading,
  hideLoading,
  setLang,
} from "../../../common/app/actions";
import { HeaderLinks } from "../../components";
import { CompanyContext } from "../../../common/companies/contexts";
import { navigateToProject } from "../../../common/projects/funcs";
import { ProjectContext } from "../../../common/projects/contexts";
import _ from "lodash";
import { platformActions } from "../../../common/platformActions";
import systemMessages from "../../../common/app/systemMessages";
import { v4 as uuidv4 } from "uuid";

import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import companiesMessages from "../../../common/companies/companiesMessages";
import withRouterHOC from "../../components/Router/util/withRouterHOC";
import Paging from "../../components/CementoComponents/Paging";

const PROJECTS_PER_PAGE = 10;
const MIN_PROJECTS_FOR_PAGINATION = 20;

class CompanyContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.handleUnmountBeforeBrowserExit =
      this.handleUnmountBeforeBrowserExit.bind(this);
    this.setHeaderParams = this.setHeaderParams.bind(this);
    this.updateLanguageIfDiff = this.updateLanguageIfDiff.bind(this);
    this.handleNavigateToProject = this.handleNavigateToProject.bind(this);
    this.fetchCompanyTablesData = this.fetchCompanyTablesData.bind(this);
    this.state = {
      currPage: 0,
    };
  }

  handleUnmount(beforeBrowserExit) {
    window.removeEventListener(
      "beforeunload",
      this.handleUnmountBeforeBrowserExit
    );
  }

  handleUnmountBeforeBrowserExit() {
    this.handleUnmount(true);
  }

  componentWillUnmount() {
    this.handleUnmount();
  }

  componentDidMount() {
    const { hideLoading } = this.props;
    hideLoading();
  }

  UNSAFE_componentWillMount() {
    window.addEventListener(
      "beforeunload",
      this.handleUnmountBeforeBrowserExit
    );
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { history, viewer, match } = this.props;
    let containerUrl = history.location.pathname;
    let selectedCompanyId = match.getNested(["params", "selectedCompanyId"]);

    if (this.props.isValDiff(nextProps, ["navigationParams", "scope"]))
      if (nextProps.getNested(["navigationParams", "scope"]) == "project") {
        let selectedProjectId = nextProps.getNested(
          ["navigationParams", "selectedProjectId"],
          "_"
        );
        nextProps.history.push(
          `/main/projectContainerPage/${selectedProjectId}/_`
        );
      }

    if (viewer && (!selectedCompanyId || selectedCompanyId == "_")) {
      let userSelectedCompanyId = viewer.companyId;
      history.push(containerUrl.replace("_", userSelectedCompanyId));
    }

    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    this.updateLanguageIfDiff(nextProps);

    const { selectedCompanyId } = this.state;
    let newStateChanges = {};

    if (props.viewer != nextProps.viewer)
      newStateChanges.isAdmin = Boolean(
        nextProps.getNested(["viewer", "adminMode"], 0) > 0
      );

    if (
      !selectedCompanyId ||
      nextProps.isValDiff(props, ["match", "params", "selectedCompanyId"])
    ) {
      newStateChanges.selectedCompanyId = nextProps.getNested([
        "match",
        "params",
        "selectedCompanyId",
      ]);
    }
    
    if (nextProps.isValDiff(props, ['detailedProjects']) || nextProps.isValDiff(props, ['match', 'params', 'contentType'])) {
      const contentType = nextProps.getNested(['match', 'params', 'contentType']);
      newStateChanges.numberOfProjects = Object.values(nextProps.detailedProjects || {}).filter(
        (project) => project.companyId === selectedCompanyId
      ).length;
      newStateChanges.shouldUsePagination =
        (contentType === 'safety' || contentType === 'qa') && newStateChanges.numberOfProjects > MIN_PROJECTS_FOR_PAGINATION;
    }

    if (
      this.props.configurations != nextProps.configurations ||
      this.props.getNested(['match', 'params', 'contentType']) !==
        nextProps.getNested(['match', 'params', 'contentType']) ||
      this.state.contentType !== nextProps.getNested(['match', 'params', 'contentType'])
    ) {
      let contentType = nextProps.getNested(['match', 'params', 'contentType']);
      if (!contentType) newStateChanges.headerParams = {};
      if (contentType !== this.state.contentType) newStateChanges.currPage = 0;
      newStateChanges.contentType = contentType;
      newStateChanges.isFeatureActive = nextProps.getNested(
        [
          'configurations',
          'features',
          'companyView',
          ...(contentType == 'settings' ? ['settings', 'members'] : [contentType]),
          'isActive',
        ],
        false
      );
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  updateLanguageIfDiff(nextProps) {
    const { setLang } = nextProps;
    if (!this.waitForIntlProviderRerender && (nextProps.project || {}).lang) {
      const currLang = nextProps.lang;
      const newLang = nextProps.getNested(["project", "lang"], "en");
      if (currLang != newLang) {
        this.waitForIntlProviderRerender = true;
        setLang(newLang);
      }
    }
  }

  setHeaderParams(headerParams) {
    const { match, uiParams, menus, saveUIParams } = this.props;
    let contentType = match.params.contentType;
    let isOnlySideNav = Boolean(
      (!headerParams || !headerParams.headerComponent) &&
        menus &&
        menus[contentType]
    );
    if (uiParams.getNested(["onlySideNav"]) != isOnlySideNav)
      saveUIParams({ onlySideNav: isOnlySideNav });

    this.setState({ headerParams });
  }

  handleNavigateToProject(projectId, innerPath) {
    if (!projectId) return null;

    navigateToProject(this.props, projectId, innerPath, false, true);
  }

  setNextPage = (currPage) => {
    this.setState({ currPage });
  }

  async fetchCompanyTablesData(subject, newSelectedCompanyId, startTS, endTS) {
    const { selectedCompanyId, currPage = 0, shouldUsePagination } = this.state;
    const { startLoading, hideLoading, viewer, userId, isPdfMode } = this.props;
    const { apiServer } = envParams;

    let companyId = newSelectedCompanyId || selectedCompanyId;
    let viewerId = isPdfMode ? userId : viewer.id;

    if (
      !(subject == "safety" || subject == "quality") ||
      !companyId ||
      !viewerId
    )
      return null;

    let operationId = uuidv4();
    startLoading({
      title: systemMessages.loadingMessage,
      overlay: true,
      operationId,
    });
    let tablesData;
    let url = `${apiServer}/v1/gateway/${subject}?companyId=${companyId}&viewerId=${viewerId}`;
    if (!_.isNil(startTS)) url += `&startTS=${startTS}`;
    if (!_.isNil(endTS)) url += `&endTS=${endTS}`;
    if (shouldUsePagination) url += `&page=${currPage}&limit=${PROJECTS_PER_PAGE}`;
    
    let res;
    try {
      let resp = await platformActions.net.fetch(url);
      let jsonResp = await resp.json();
      if (!jsonResp) res = null;
      res = jsonResp;
    } catch (error) {
      console.error(error);
      if(shouldUsePagination){
        this.setState({ currPage: currPage == 0 ? 0 : currPage - 1 });
      }
      res = null;
    } finally {
      hideLoading(operationId);
      tablesData = _.isObject(res) ? res : {};
      tablesData = _.entries(tablesData).map(([key, value]) => ({
        id: key,
        ...value,
      }));
    }
    return tablesData;
  }

  render() {
    const {
      menus,
      viewer,
      intl,
      tradesMap,
      formUniversalIdsMap,
      rtl,
      isPdfMode,
    } = this.props;
    const {
      headerParams,
      selectedCompanyId,
      isFeatureActive,
      isAdmin,
      contentType,
      currPage,
      numberOfProjects,
      shouldUsePagination
    } = this.state;

    if (!isFeatureActive && !isAdmin && contentType && contentType != "_" && contentType != "home") {
      return (
        <>
          <div
            style={{
              justifyContent: "start",
              alignItems: "center",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div style={{ flexDirection: "column", margin: theme.margin * 3 }}>
              <Text
                key={`${contentType}Title`}
                style={{
                  fontWeight: "bold",
                  fontSize: theme.fontSizeH3,
                  color: theme.brandPrimary,
                }}
              >
                {_.get(companiesMessages, [
                  "notPermittedFeature",
                  "companyView",
                  `${contentType}Title`,
                ])}
              </Text>
              <br />
              <Text
                key={`${contentType}Content`}
                style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}
              >
                {_.get(companiesMessages, [
                  "notPermittedFeature",
                  "companyView",
                  `${contentType}Content`,
                ])}
              </Text>
            </div>
          </div>
        </>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: headerParams && headerParams.headerComponent ? 'column' : 'row',
          flex: 1,
        }}>
        <HeaderLinks
          key={`HeaderLinks_${contentType}`}
          defaultSelection={null}
          headerParams={headerParams}
          routingMode={false}
          menus={menus}
          menuMode={true}
          intl={intl}
          isSecondary={true}
        />
        <Outlet
          context={{
            selectedCompanyId: selectedCompanyId,
            onProjectClick: this.handleNavigateToProject,
            rtl: rtl,
            tradesMap: tradesMap,
            isPdfMode: isPdfMode,
            setHeaderParams: this.setHeaderParams,
            viewer: viewer,
            fetchCompanyTablesData: this.fetchCompanyTablesData,
            page: currPage,
            formUniversalIdsMap: formUniversalIdsMap,
            shouldUsePagination
          }}
        />
        <CompanySafetyPagination onClick={this.setNextPage} currPage={currPage} numberOfProjects={numberOfProjects} disable={!shouldUsePagination} />
      </div>
    );
  }
}

const CompanySafetyPagination = (props) => {
  const { currPage, numberOfProjects, disable, onClick } = props;
  const maxButtonsToShow = Math.max(Math.ceil(numberOfProjects / 100), 5);
  if (disable) return null;
  return (
    <Paging
      style={{
        display: 'flex',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        left: '50vw',
        bottom: theme.paddingSize,
        zIndex: theme.zIndexes.tablePagination,
      }}
      currPage={currPage}
      onClick={onClick}
      dataCount={numberOfProjects}
      countPerPage={PROJECTS_PER_PAGE}
      maxButtonsToShow={maxButtonsToShow} // TODO: this is a problem in safety table because we don't know the number of pages until we fetch
    />
  );
}
  

const enhance = compose(
  injectIntl,
  withRouterHOC,
  connectContext(CompanyContext.Consumer),
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      projectsMap: state.projects.map,
      lang: state.app.lang,
      rtl: state.app.rtl,
      uiParams: state.ui.uiParams,
      formUniversalIdsMap: state.quasiStatics.formUniversalIdsMap,
      tradesMap: state.trades.map,
      viewer: state.users.viewer,
    }),
    {
      startLoading,
      hideLoading,
      saveUIParams,
      setLang,
    }
  )
);
export default enhance(CompanyContainerPage);
