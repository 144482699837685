import { useMemo, useRef } from 'react';

/**
 * @template T
 * @param {T} value
 * @param {any[]} depsArr
 * @returns
 */
const useRefed = (value, depsArr) => {
	const ref = useRef(value);
	ref.current = useMemo(() => value, depsArr);
	return ref;
};

export default useRefed;
