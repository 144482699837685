import React from 'react';
let PlatformStandardInput = () => null;

export const setPlatformStandardInput = (component) => {
    PlatformStandardInput = component;
};

export default () => PlatformStandardInput;


