import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';

import Unit from './unit';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
}, 'units');

const initialState = new InitialState;

export default function unitReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.UNITS_LOADING + '_SUCCESS': {
      const { projectId } = action.payload;
      if (!state.getIn(['didLoad', projectId])) {
        state = state.setIn(['didLoad', projectId], true);
      }

      return state;
    }

    case actions.GET_UNITS: {
      if (!action.payload?.projectId)
        return state;

      const { projectId, units } = action.payload;

      if (units) {
        let unitsMap = new OrderedMap();
        Object.entries(units).forEach(([buildingId, buildingUnits = {}]) => {
          let buildingUnitsMap = new OrderedMap();
  
          Object.values(buildingUnits).forEach(unit => {
            unit = new Unit({ ...(unit.toJS ? unit.toJS() : unit) });
            buildingUnitsMap = buildingUnitsMap.set(unit.id, unit);
          });
  
          unitsMap = unitsMap.set(buildingId, buildingUnitsMap);
        });
  
        state = state.setIn(['map', projectId], unitsMap);
      }
    
      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}