import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import React, {useEffect, useRef, useState} from 'react';

const ImagePanZoom = ({ src, style = {} }) => {
  const ctnRef = useRef();
  const imgRef = useRef();
  const [initialScale, setInitialScale] = useState(null);
  const [initialX, setInitialX] = useState(null);
  const [initialY, setInitialY] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      // getting image size and calc scale and position to display whole image in the middle of container,
      // according to album or portrait image orientation
      const ctnWidth = ctnRef.current?.clientWidth;
      const ctnHeight = ctnRef.current?.clientHeight;
      const scaleX = ctnWidth / img.width;
      const scaleY = ctnHeight / img.height;
      const scale = Math.min(scaleX, scaleY);
      const offsetX = (ctnWidth - img.width * scale) / 2;
      const offsetY = (ctnHeight - img.height * scale) / 2;
      setInitialScale(scale);
      setInitialX(offsetX);
      setInitialY(offsetY);
    }
    img.src = src;
  }, [src]);

  const onLoad = (ref) => {
    // do some image manipulations if u need, like:
    // ref.centerView(scale);
    // ref.setTransform(0, 0, scale);
  };

  const onClick = (e) => {
    // prevent popup container from closing on drag event
    e.stopPropagation();
  }

  const ctnStyle = {
    height: '100%',
    width: '100%',
    position: 'relative',
    direction: 'ltr',
    ...style,
  };

  const wrapperStyle = {
    overflow: 'visible'
  };

  return (
    <div onClick={onClick} ref={ctnRef} style={ctnStyle}>
      {initialScale && initialX !== null && initialY !== null && <TransformWrapper
        initialScale={initialScale}
        minScale={0.01}
        initialPositionX={initialX}
        initialPositionY={initialY}
        centerOnInit={false}
        limitToBounds={false}
        onInit={onLoad}
      >
        <TransformComponent wrapperStyle={wrapperStyle}>
          <img ref={imgRef} src={src} />
        </TransformComponent>
      </TransformWrapper>}
    </div>
  );
};

export default ImagePanZoom;