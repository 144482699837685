import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
const PropertySelectionValue = Record({
  id: null,
  title: null,
  types: null,
  isDeleted: null
}, 'propertySelectionValue', false);

export default PropertySelectionValue;
