import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';

import InfoBoxCard from '../../components/GMap/components/InfoBoxCard';
import MarkerWithInfo from '../../components/GMap/components/MarkerWithInfo';
import Gmap from '../../components/GMap/Gmap';
import CardList from '../../components/CardList/CardList';
import CardListItem from '../../components/CardList/components/CardListItem';
import CardUser from '../../components/Card/CardUser';
import VerticalMenu from '../../components/VerticalMenu/VerticalMenu';
import defaultProjectImg from '../../assets/img/defaultProject.png';
import defaultUserImg from '../../assets/img/defaultUser.png';
import { envParams } from '../../../common/configureMiddleware';
import { injectIntl } from '../../../common/app/myInjectIntl';
import companiesMessages from '../../../common/companies/companiesMessages';
import useCachedRequest from '../../hooks/useCachedRequest';
import withRouterHOC from '../../components/Router/util/withRouterHOC';
import { track } from '../../../common/lib/reporting/actions';

const getCompanyProjectsRequest = (
	companyId,
	fields = ['projectManager', 'location', 'description', 'title', 'images', 'address', 'features'],
) => {
	let url = `${envParams.apiServer}/v1/projects?companyId=${companyId}&fields=${JSON.stringify(fields)}`;
	return new Request(url, { method: 'GET' });
};

const useStyles = makeStyles(() => ({
	forceRTL: {
		direction: 'rtl !important',
		maxHeight: '100vh',
		overflow: 'hidden',
		'& *': {
			direction: 'rtl !important',
			textAlign: 'right !important',
		},
	},
}));

const CompanyHomePage = ({ selectedCompanyId, setHeaderParams, intl }) => {
	const [projectsMap, setProjectsMap] = useState({});
	const [scrollRefMap, setScrollRefMap] = useState({});
	const [map, setMap] = useState(null);
	const dispatch = useDispatch();

	const projectsArray = useMemo(() => Object.entries(projectsMap), [projectsMap]);

	const rtl = useSelector(state => state.app.rtl);
	const viewer = useSelector(state => state.users.viewer);
	const classes = useStyles();

	const { isLoading } = useCachedRequest({
		request: getCompanyProjectsRequest(selectedCompanyId),
		cacheId: 'companyHome',
		onCache: setProjectsMap,
		onResponse: setProjectsMap,
	});

	useEffect(() => {
		dispatch(track('enterCompanyHomePage', { companyId: selectedCompanyId }));
	}, [])

	useEffect(() => {
		setHeaderParams(null);
	}, [setHeaderParams]);

	useEffect(() => {
		if (!isLoading && map) {
			fitAllProjects(projectsArray);
		}
	}, [isLoading, map]);

	const fitAllProjects = async projects => {
		// Don't do anything if less than 2 projects
		if (projects.length < 2) return;
		try {
			const bounds = new google.maps.LatLngBounds();
			projects.forEach(([, project]) => {
				if (!project?.location?.coords) return;
				const position = new window.google.maps.LatLng(
					project.location.coords.latitude,
					project.location.coords.longitude,
				);

				bounds.extend(position);
			});

			map.fitBounds(bounds);
			map.setZoom(map.getZoom() - 1); // Make sure that no marker is on the edge of the map
		} catch (error) {
			console.log('ERROR_fitAllProjects', { error, map });
		}
	};

	const toggleProperty = (id, type = 'isActive', explicitValue) => {
		setProjectsMap(currentProjectsMap => {
			let newProjectsMap = {};
			for (const projectId in currentProjectsMap) {
				const projectData = currentProjectsMap[projectId];
				newProjectsMap[projectId] = {
					...projectData,
					isActive: false,
					isPreview: false,
					[type]: id === projectId && (_.isNil(explicitValue) ? !projectData[type] : explicitValue),
				};
			}
			return newProjectsMap;
		});
	};

	const focusMap = id => {
		try {
			const position = new google.maps.LatLng(
				projectsMap[id].location.coords.latitude,
				projectsMap[id].location.coords.longitude,
			);
			map.panTo(position);
		} catch (e) {
			console.warn('Was not able to set the center, reason:', e);
		}
	};

	const openQuickMenu = (id, open) => {
		toggleProperty(id, 'isActive');
		scrollRefMap[id]?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'center',
		});
		open && focusMap(id);
	};

	const mapCenter = useMemo(() => {
		try {
			if (projectsArray[0][1]?.location?.coords)
			return {
				lat: projectsArray[0][1].location.coords.latitude,
				lng: projectsArray[0][1].location.coords.longitude,
			};
		} catch {
			return null;
		}
	}, [isLoading]);

	if (isLoading) return null;

	return (
		<Grid container style={{ float: 'left' }}>
			<Grid width={600} className={rtl ? classes.forceRTL : null} style={{ maxHeight: '100%' }}>
				<CardList>
					{projectsArray.map(([projectId, project]) => (
						<CardListItem
							key={projectId}
							title={project.title}
							subtitle={project.address}
							description={project.description}
							imageUrl={project.images?.main || defaultProjectImg}
							isActive={project.isActive}
							onCardClick={() => {
								focusMap(projectId);
								toggleProperty(projectId, 'isPreview');
							}}
							onMenuClick={() => openQuickMenu(projectId, true)}
							onMount={ref => setScrollRefMap(map => ({ ...map, [projectId]: ref }))}
						>
							{({ buttonRef }) => (
								<>
									<Popper
										className={rtl ? classes.forceRTL : null}
										open={Boolean(project.isActive)}
										anchorEl={buttonRef.current}
										placement={'left-start'}
										transition={false}
										onClick={e => e.stopPropagation()}
										modifiers={{
											offset: { offset: rtl ? '8, -48' : '8, -287' },
											flip: { enabled: false },
										}}
									>
										<VerticalMenu
											projectId={projectId}
											menus={project.menus}
											configurations={project.features}
											closeMenu={() => openQuickMenu(projectId, false)}
										/>
									</Popper>
									{project.projectManager ? (
										<CardUser
											name={project.projectManager.displayName}
											description={intl.formatMessage(companiesMessages.projectManagerPerson)}
											image={project.projectManager.avatar || defaultUserImg}
										/>
									) : null}
								</>
							)}
						</CardListItem>
					))}
				</CardList>
			</Grid>
			<Grid item xs style={{ maxHeight: '100%' }}>
				<Gmap center={mapCenter} onLoad={setMap}>
					{projectsArray.map(([projectId, project]) =>
						project?.location?.coords ? (
							<MarkerWithInfo
								key={projectId}
								position={{ lat: project.location.coords.latitude, lng: project.location.coords.longitude }}
								isActive={project.isActive || project.isPreview}
								rtl={rtl}
							>
								<InfoBoxCard
									title={project.title}
									description={project.address}
									imageUrl={project.images?.main || defaultProjectImg}
									onClick={() => openQuickMenu(projectId, true)}
								/>
							</MarkerWithInfo>
						) : null,
					)}
				</Gmap>
			</Grid>
		</Grid>
	);
};

export default injectIntl(withRouterHOC(CompanyHomePage));
