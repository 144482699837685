import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import { CardHeader, Card, CardBody, CardTabs } from "../../components";
import theme from "../../assets/css/theme";
import Text from "../../components/CementoComponents/Text";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import Date from "../../components/CementoComponents/Date";
import drawingsMessages from "../../../common/drawings/drawingsMessages";
import zoomIcon from "../../assets/img/icons/search_icon.png";
import { BUILDING, FLOOR, UNIT } from "../../../common/drawings/actions";
import NoItemsFound from "../../components/CementoComponents/NoItemsFound";

class DrawingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  toggleHover = (hover) => this.setState({ hover });

  render() {
    const { rtl, classes, drawing, onImageSelect, intl } = this.props;
    const { hover } = this.state;

    return !drawing ? (
      <>
        <CardBody
          style={{
            paddingTop: "30%",
            display: "flex",
            justifyContent: "center",
            color: theme.brandPrimary,
          }}
        >
          <Text>{drawingsMessages.empty.general}</Text>
        </CardBody>
      </>
    ) : (
      <>
        <CardHeader
          style={{
            alignItems: "center",
            height: theme.headerHeightSecondary,
            paddingLeft: 3 * theme.paddingSize,
            paddingRight: 3 * theme.paddingSize,
            backgroundColor: theme.backgroundColor,
          }}
        >
          <div style={styles.header}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 400,
                textTransform: "uppercase",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {drawingsMessages.subtitles[drawing.type]}
            </Text>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 400,
                textTransform: "uppercase",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {drawing.name || ""}
            </Text>
            <TradeBadge
              containerStyle={{ margin: 0, justifyContent: "flex-end" }}
              ids={[drawing.category]}
            />
          </div>
        </CardHeader>
        <CardBody style={{ backgroundColor: theme.backgroundColorBright }}>
          <div
            style={{
              padding: 14,
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
            onMouseEnter={() => this.toggleHover(true)}
            onMouseLeave={() => this.toggleHover(false)}
          >
            <img
              src={drawing.uri}
              style={styles.image}
              onClick={() => onImageSelect(drawing)}
            />
            {Boolean(hover) && (
              <div
                style={styles.zoomIcon}
                onClick={() => onImageSelect(drawing)}
              >
                <img src={zoomIcon} style={{ height: 150, width: 150 }} />
              </div>
            )}
          </div>
          <div style={{ padding: "15px 30px", paddingBottom: 0 }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                justifyItems: "start",
              }}
            >
              <div style={{ fontWeight: 400, padding: 5 }}>
                <Text style={theme.subFont}>
                  {intl.formatMessage(drawingsMessages.card.title)}
                </Text>
                <Text style={theme.defaultFont}>{drawing.title || ""}</Text>
              </div>
              <div style={{ fontWeight: 400, padding: 5 }}>
                <Text style={theme.subFont}>
                  {intl.formatMessage(drawingsMessages.card.creationDate)}
                </Text>
                <Date style={theme.defaultFont} intl={intl}>
                  {drawing.date}
                </Date>
              </div>
            </div>
            <div style={{ fontWeight: 400, padding: 5 }}>
              <Text style={theme.subFont}>
                {intl.formatMessage(drawingsMessages.card.location)}
              </Text>
              <DrawingLocation drawing={drawing} props={this.props} />
            </div>
          </div>
        </CardBody>
      </>
    );
  }
}

const styles = {
  header: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
  },
  zoomIcon: {
    position: "absolute",
    top: 0,
    cursor: "pointer",
    zIndex: theme.zIndexes.drawingZoomIcon,
    marginTop: 14,
    height: 300,
    width: 400,
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: theme.backgroundColorBright,
    border: theme.borderLineHeaderInfo,
    opacity: 0.7,
  },
  image: {
    cursor: "pointer",
    width: "100%",
    height: 300,
    objectFit: "contain",
    border: theme.separatorColor,
    borderStyle: "solid",
    borderWidth: 1,
  },
  postImageSize: 160,
  cardSectionsStyles: {
    padding: theme.paddingSize + "px " + 2 * theme.paddingSize + "px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
};

export const DrawingLocation = ({ drawing, style, props }) => {
  const { buildings, floors, units, intl, rtl } = props;
  if (!floors) return;

  const findFloorTitle = (locationFloorNum, building, allFloors) => {
    let foundFloor = allFloors
      .getNested([building])
      .find((floor) => locationFloorNum === floor.num);
    if (foundFloor)
      return foundFloor.getNested(["description"], locationFloorNum);
  };

  let multipleBuilding = buildings.size !== 1;

  let namedLocations = drawing.locations.map((locationObject, i, locations) => {
    let string =
      multipleBuilding || drawing.type === BUILDING
        ? buildings?.get(locationObject.buildingId)?.get("title") || ''
        : "";

    if (drawing.type === FLOOR) {
      [...locationObject.floorNum].map((locationFloor) => {
        if (Array.isArray(locationFloor)) {
          string += intl.formatMessage(drawingsMessages.locations.floors) + " ";
          string += `${findFloorTitle(
            locationFloor[1],
            locationObject.buildingId,
            floors
          )} - ${findFloorTitle(
            locationFloor[0],
            locationObject.buildingId,
            floors
          )}`;
        } else {
          string += intl.formatMessage(drawingsMessages.locations.floor) + " ";
          string += findFloorTitle(
            locationFloor,
            locationObject.buildingId,
            floors
          );
        }
      });
    }

    if (drawing.type === UNIT) {
      let unit = units.getNested([
        locationObject.buildingId,
        locationObject.id,
      ]);
      if (unit) {
        string += intl.formatMessage(drawingsMessages.locations.floor) + " ";
        string +=
          findFloorTitle(
            unit.getNested(["floor", "num"]),
            locationObject.buildingId,
            floors
          ) + " - ";
        // string += intl.formatMessage(drawingsMessages.locations.unit) + " ";
        string += unit.get("title");
      }
    }

    return string;
  });

  return (
    <Text
      rtl={rtl}
      style={{
        ...theme.defaultFont,
        display: "flex ",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {namedLocations.join("\n")}
    </Text>
  );
};

DrawingCard = injectIntl(DrawingCard);
DrawingCard = withStyles(styles)(DrawingCard);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect((state) => ({
    lang: state.app.lang,
    rtl: state.app.rtl,
  }))
);
export default enhance(DrawingCard);
