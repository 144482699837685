import { useState } from 'react';

const HoverComponent = ({ children }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children({ isHover })}
    </div>
  );
};

export default HoverComponent;