// eslint-disable-next-line no-unused-vars
import React, { cloneElement, useMemo } from 'react';
import QueryString from 'qs';
import { useHref, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

const containerToScopeMap = {
  'companyContainerPage': 'company',
  'projectContainerPage': 'project',
};

const isCurrentPage = (page, path) => {
  const pathArr = path.split('/');
  return pathArr[pathArr.length - 1] === page;
};

export const useCementoRouter = () => {
  const location = useLocation();
  const url = useHref();
  const params = useParams();
  const outletContext = useOutletContext();
  const navigate = useNavigate();

  const navigationProps = useMemo(() => {
    const [, root, containerName, containerId, contentType, section] = location.pathname.split('/');

    const extendedParams = {
      root,
      containerName,
      containerId,
      contentType,
      page: contentType,
      scope: containerToScopeMap[containerName],
      queryParams: QueryString.parse((location?.search || '?').replace('?', '')),
      hash: location?.hash,
      containerUrl: location.pathname,
      section,
      ...params,
    };

    return {
      ...(outletContext || {}),
      location,
      navigationParams: extendedParams,
      match: {
        url,
        params: extendedParams,
        isCurrentPage: (page) => isCurrentPage(page, location.pathname),
      },
      history: {
        location,
        push: navigate,
      },
    };
  }, [location, url, navigate, params, outletContext]);

  return navigationProps;
};

// eslint-disable-next-line react/display-name
const withRouterHOC = (Element) => (props) => {
  const navigationProps = useCementoRouter();
  return cloneElement(<Element />, { ...navigationProps, ...props });
};

export default withRouterHOC;
