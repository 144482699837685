import { PROJECT_EVENTS } from './trackProjects';
import { REPLACE_USER } from '../auth/actions';
import _ from "lodash"
import Project from './project';
import Member from '../members/member';

import { Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  map: Map(), 
  didLoad: false,
  detailsMap: Map(),
  projectReducersLoaded: Map(),
  projectLokiLoaded: Map(),
  currentProject: Project(),
  intl: null,
}, 'projects');

const initialState = new InitialState;

export default function projectReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case PROJECT_EVENTS.LOKI_DID_LOAD: {
      if (!action.payload)
        return state;
      const { projectId, status } = action.payload
      state = state.setIn(['projectLokiLoaded', projectId], status);
      return state;
    }

    case PROJECT_EVENTS.SET_PROJECT_INTL: {
      if (!action.payload)
        return state;
      
      const { intl } = action.payload;
      
      if (intl) {
        state = state.set('intl', intl);
      } else {
        state = state.delete('intl');
      }

      return state;
    }

    case PROJECT_EVENTS.GET_PROJECTS: {
      if (!action.payload)
        return state;
      
      const { projects } = action.payload;

      let newMap = new Map();
      if (projects) {
        let retArray = Object.values(projects);
        newMap = newMap.withMutations(mutableMap => {
          retArray.forEach(proj => {
            const project = new Project(proj);
            if (proj.id) mutableMap.set(proj.id, project);
          });
        });
        state = state.setIn(['map'], newMap);
      } else {
        state = state.setIn(['map'], newMap);
      }

      state = state.set('didLoad', true);
      return state;
    }

    case PROJECT_EVENTS.GET_PROJECT_DETAILS: {
      if (!action.payload?.projectId)
        return state;
      
      
      let projectDetails = action.payload.project;

      if (projectDetails) {
        var members = {};
        if (projectDetails.members)
          Object.values(projectDetails.members).map(member => members[member.id] = new Member(member));
  
        var project = new Project({...projectDetails, members: members}); 
        state = state.setIn(['detailsMap', project.id], project);

        if (state.getIn(['map', project.id])) {
          state = state.setIn(['map', project.id, 'images'], project.images);
        }
        
      }

      return state;
    }

    case PROJECT_EVENTS.UPDATE_PROJECT_MEMBERS + '_SUCCESS': {
      if (!action.payload)
        return state;
      const { project } = action.payload;
      if (!project)
        return state;
      return state.setIn(['detailsMap', project.id], project);
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}
