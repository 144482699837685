import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../projects/contexts';
import { safeToJS } from '../permissions/funcs';
import { handleComplexTypeChange } from '../app/standardInputFuncs';

/**
 * @typedef {any} InnerValue
 * @typedef {{ [innerPropId: string]: InnerValue }} ComplexComponentValue
 * @typedef {{
 *  prop: any | null,
 *  innerPropsMap: { [propId: string]: any } | null,
 *  sortedInnerProps: object[],
 *  handleInnerValueChange: (innerPropId: string, value: any) => void,
 * }} ComplexComponentChildrenProps
 * 
 */

/**
 * @typedef ComplexComponentProps
 * @property {ComplexComponentValue} value
 * @property {string} propId
 * @property {string} subjectName
 * @property {(newValue: ComplexComponentValue | null) => void} onChange
 * @property {(props: ComplexComponentChildrenProps) => React.ReactNode} children
 */

/**
 * @param {ComplexComponentProps} props
 * @returns 
 */


let ComplexComponentHOC = (props) => {
  const { propId, subjectName, value, children } = props;
  const { propertiesTypes } = props; // ProjectContext
  
  const [prop, setProp] = useState(null);
  const [innerProps, setInnerProps] = useState(null);

  /*
  || ============================ ||
  ||      LIFE CYCLE METHODS      ||
  || ============================ ||
  */
  useEffect(() => {
    const prop = safeToJS(_.get(propertiesTypes, [subjectName, propId], null));
    
    let innerProps = null;

    if (prop) {
      innerProps = {};

      const innerTypesIds = _.keys(_.get(prop, ['innerTypes'], {}));
      (innerTypesIds).forEach(innerTypeId => {
        const innerProp = _.get(propertiesTypes, [subjectName, innerTypeId]);
        if (innerProp)
          innerProps[innerProp.id] = safeToJS(innerProp);
      });
    }

    setProp(prop);
    setInnerProps(innerProps);
  }, [propId, subjectName, propertiesTypes]);


  /*
  || ============================ ||
  ||           CALLBACKS          ||
  || ============================ ||
  */
  const onChange = useCallback((newValue) => {
    const { onChange } = props;

    if (onChange)
      onChange(newValue);
  }, [props.onChange]);

  const handleChange = useCallback((innerPropId, data) => {
    const newVal = handleComplexTypeChange(value, innerPropId, data);
    onChange(newVal);
  }, [value, onChange]);
  
  /*
  || ============================ ||
  ||            RENDER            ||
  || ============================ ||
  */

  return children({
    prop,
    innerPropsMap: innerProps,
    sortedInnerProps: Object.values(innerProps || {}).filter(Boolean).sort((a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0)),
    handleInnerValueChange: handleChange,
  });
}

ComplexComponentHOC = connectContext(ProjectContext.Consumer)(ComplexComponentHOC);
export default ComplexComponentHOC;