import { getAppState, envParams } from "../configureMiddleware";
import { platformActions } from "../platformActions";


let overWriteValues = {
  companyId: true,
  title: true,
  groups: true,
  trades: true,
};

export function getViewer(projectId) {
  var viewer = getAppState().getNested(["users", "viewer"]);
  if (projectId) {
    var projectValues = getAppState().getNested(
      ["users", "viewer", "projects", projectId],
      {}
    );
    projectValues.loopEach((k, val) => {
      if (overWriteValues[k]) viewer = viewer.set(k, val);
    });
  }

  if (viewer && viewer.toJS) viewer = viewer.toJS();
  return viewer;
}


export async function isUserExist(phoneNumber) {
  const { apiServer } = envParams;
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  let response = await platformActions.net.fetch(`${apiServer}/v1/users/exist?phoneNumber=${encodedPhoneNumber}`);
  response = await response.getJson();
  return Boolean(response?.isUserExist);
}