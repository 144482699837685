import { defineMessages } from 'react-intl';

export default defineMessages({
  roof: {
    defaultMessage: "Roof",
    id: "locations.title.roof"
  },
  buildingNumber: {
    defaultMessage: "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Building",
    id: "locations.title.buildingNumber"
  },
  floorNumber: {
    defaultMessage: "{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Floor",
    id: "locations.title.floorNumber"
  },
  unitNumber: {
    defaultMessage: "Unit {number}",
    id: "locations.title.unitNumber"
  }  
});

