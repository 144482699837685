import React from 'react';
import systemMessages from '../../../common/app/systemMessages';
import Text from './Text';
import nothing_to_display from '../../assets/img/nothing_to_display.png';
import theme from '../../../common/app/theme';
import postsMessages from '../../../common/posts/postsMessages';

const NothingToDisplay = (props) => {
  const { style, textStyle, imageStyle } = props;
  return (
    <div style={Object.assign({ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }, style)}>
      <img style={Object.assign({ margin: theme.margin, transform: 'translateX(-3px)' }, imageStyle)} src={nothing_to_display} />
      <Text style={Object.assign({ margin: theme.margin * 2, fontSize: theme.fontSizeH6 }, textStyle)}>{postsMessages.empty}</Text>
    </div>
  );
}

export default NothingToDisplay;