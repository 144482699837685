import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import DynamicUIRenderer from '../components/DynamicUIRenderer';
import postsMessages from '../../posts/postsMessages';
import { safeFormatMessage } from '../funcs';

/**
 * @param {string} nothingToDisplayMessage 
 * @returns 
 */
 export const GET_NOTHING_TO_DISPLAY_UI_STRUCT = (nothingToDisplayMessage) => [
  { 
    direction: 'row', 
    elements: [
      { 
        valueString: nothingToDisplayMessage, 
        HTMLAttributes: { textStyle: { color: '$theme.placeholderTextColor', fontSize: '$theme.fontSizeH7' } } 
      }
    ], 
    HTMLAttributes: { 
      style: { 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '$theme.nothingToDisplayHeight',
      } 
    } 
  }
];

let NothingToDisplayComponent = (props) => {
  const { intl, children, message } = props;

  const [nothingToDisplayMessage, setNothingToDisplayMessage] = useState('');

  useEffect(() => {
    const nextNothingToDisplayMessage = safeFormatMessage(intl, children || message || postsMessages.empty);
    if (nothingToDisplayMessage !== nextNothingToDisplayMessage)
      setNothingToDisplayMessage(nextNothingToDisplayMessage);
  }, [children, message]);

  return <DynamicUIRenderer UIStruct={GET_NOTHING_TO_DISPLAY_UI_STRUCT(nothingToDisplayMessage)} />;
}

NothingToDisplayComponent = injectIntl(NothingToDisplayComponent);
export default NothingToDisplayComponent;