import ExtraError from '../lib/errors/extraError'
import { hideLoading, startToast } from '../app/actions';
import reportsMessages from '../reports/reportsMessages';
import _ from 'lodash';
import { batchDispatch, onError } from '../app/funcs';
import { syncWithDB } from '../lastUpdates/actions';
import { platformActions } from '../platformActions';
import { debugParams, endProjectListener, getRoundedDate, startProjectFirebaseListener, fetchByTS } from '../lib/utils/utils';
import {getNewId, updateUsingFirebaseProxy} from '../lib/api';
import ClientServerConnectivityManager from '../lib/ClientServerConnectivityManager';
import { getAppState, lokiInstance } from '../configureMiddleware';
import { track } from '../lib/reporting/actions';

export const GET_NEW_FORM_ID = 'GET_NEW_FORM_ID';
export const GET_FORMS_BY_TYPE = 'GET_FORMS_BY_TYPE';
export const GET_FORMS_BY_TYPE_DONE_LOADING = 'GET_FORMS_BY_TYPE_DONE_LOADING';
export const GET_FORMS_BY_TYPE_START_LOADING = 'GET_FORMS_BY_TYPE_START_LOADING';
export const END_FORMS_LISTENER_BY_TYPE = 'END_FORMS_LISTENER_BY_TYPE';
export const START_FORMS_LISTENER_BY_TYPE = 'START_FORMS_LISTENER_BY_TYPE';
export const ADD_NEW_FORM = 'ADD_NEW_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const CREATE_NEW_FORM_ID = 'CREATE_NEW_FORM_ID';
export const SEND_FORM_VIA_MAIL = 'SEND_FORM_VIA_MAIL';
export const SET_FORM_EMAIL = 'SET_FORM_EMAIL';
export const SET_FORM_EMAIL_MODAL_PARAMS = 'SET_FORM_EMAIL_MODAL_PARAMS';
export const UNSET_FORM_EMAIL_MODAL_PARAMS = 'UNSET_FORM_EMAIL_MODAL_PARAMS';

export const getFormsByType = (viewer, projectId, formType, cleanAll) => {
	return ({ realmInstance, lokiInstance, platformActions, dispatch }) => {

		if (cleanAll)
			setFormValues({}, projectId, realmInstance, lokiInstance, platformActions, true);

		const saveFunc = (_data, isRevoke) => {
			if (debugParams.disableFetchByTSSave) return;
			dispatch({ type: GET_FORMS_BY_TYPE, payload: { formType, projectId, forms: _.mapKeys(_data, form => form.id) } })
			return setFormValues(_data, projectId, realmInstance, lokiInstance, platformActions, isRevoke);
		};

		fetchByTS(
			{ scope: 'projects', scopeId: projectId },
			{ 
				resourceName: 'forms',
				firebasePath: `forms/${projectId}/full/${formType}`,
				queryParams: { formType },
				forceMSClientConfig: true,
				getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId, formType),
			},
			saveFunc,
			viewer,
		).then(() => dispatch({ type: GET_FORMS_BY_TYPE_DONE_LOADING, payload: { scopeId: projectId, formType } }));

		return {
			type: GET_FORMS_BY_TYPE_START_LOADING,
			payload: { projectId, formType },
		};
	};
}

function setFormValues(forms, projectId, realmInstance, lokiInstance, platformActions, cleanAll) {
  if (platformActions.app.getPlatform() == "web")
  	saveToLoki(forms, projectId, lokiInstance, cleanAll);
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId, formType) {
  let lastUpdateTS = 0;
	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('forms').chain().find({projectId: scopeId, type: formType, isLocal: { '$ne' : true }}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	} else {
		const allForms = getAppState().getNested(['forms', 'map', scopeId], {}).safeToJS();
		lastUpdateTS = _.values(allForms).reduce((latestTS, form) => {
			if (form.updatedTS && form.type === formType && form.updatedTS > latestTS) {
				return form.updatedTS;
			}

			return latestTS;
		}, 0);
	}

  return lastUpdateTS || 0;
}

export function endFormsListenerByType(projectId, formType) {	
	ClientServerConnectivityManager.unregisterService({
		scope: 'projects',
		scopeId: projectId,
		subject: 'forms',
		params: { formType },
	});

	return {
		type: END_FORMS_LISTENER_BY_TYPE,
		payload: { projectId, formType }
	};
}

export function sendFormViaMailServer(projectId, formId, formType, targetEmails, subject, text) {
	return ({ apiServer, platformActions }) => {
	  const getPromise = async () => {
			 	await platformActions.net.fetch(apiServer + "/v1/services/email/send/forms", { 
					method: 'POST',
					body: JSON.stringify({
						projectId, 
						formId, 
						formType, 
						targetEmails, 
						subject, 
						text, 
					})});

			  return { projectId, formId, formType, targetEmails, subject, text };
	  };
  
	  return {
		type: SEND_FORM_VIA_MAIL,
		payload: getPromise()
	  };
	};
}

export function upsertForm(projectId, viewer, form, type = "general", shouldAlertUser = true) {
	return ({ firebase, firebaseDatabase, removeEmpty, dispatch, getState, realmInstance, lokiInstance, platformActions }) => {
		const getPromise = async () => {
			const originalGenerator = getState().getNested(['forms', 'map', projectId, form.id, 'generator']);
			const generator = originalGenerator || { id: viewer.id, displayName: viewer.displayName };

			if (!type)
				throw new ExtraError('upsertForm error - form type missing', { form, type });

			let newForm = _.pick(form, [
						'parentId',
						'certifications',
						'formTemplateId',
						'id',
						'title',
						'uri',
						'owner',
						'status',
						'checklists',
						'location',
						'universalIds',
						'posts',
						'signatures',
						'reportDate',
						'targetEmails',
						'sections',
						'type',
						'usersToNotify',
						'isDocx',
						'createdTS',
						'parentId',
			]);
      
			if (!newForm.type)
				newForm.type = type;
			
			if (!newForm.createdTS)
				newForm.createdTS = Date.now();

			if (!newForm.reportDate)
				newForm.reportDate = getRoundedDate().timestamp;
			
			if (newForm.location) { 
				const { unitId, buildingId, floorId } = newForm.location;
				const locationId = unitId || floorId || buildingId;

				const shouldSyncPosts = 							getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'posts'],  true);
				const shouldSyncChecklistInstances =  getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'checklistInstances'],  true);
				const shouldSyncPropertiesInstances = getState().getNested(['configurations', 'map', projectId, 'sync', 'beforePdfExport', 'propertiesInstances'], true)

				if (locationId) {
					let syncParam = {
						locationId,
						lastUpdateTS: Date.now(),
						onlyDiff: true,
						shouldUpload: true,
						log: false,
						shouldThrowOnLocalFiles: true
					};

					await Promise.all([
						shouldSyncPosts && dispatch(syncWithDB('posts', viewer, projectId, syncParam)),
						shouldSyncChecklistInstances && dispatch(syncWithDB('checklistInstances', viewer, projectId, syncParam)),
						shouldSyncPropertiesInstances && dispatch(syncWithDB('propertiesInstances', viewer, projectId, syncParam)),
					]);
				}
			}


			newForm.id = newForm.id || getNewId()
			
			if (!newForm.parentId && newForm.type === "dailyReport")
				newForm.parentId = newForm.id 
			
			newForm.updatedTS = Date.now();
			newForm.generator = generator;
			newForm = removeEmpty(newForm, 'upsertForm');

			setFormValues({[newForm.id]: { ...newForm, isLocal: true }}, projectId, realmInstance, lokiInstance, platformActions)

			const timeout = 45 * 1000;
			let promise = function (projectId, type, newForm) {
				return new Promise(async function (resolve, reject) {
					if (!projectId || !newForm)
						reject('Missing projectId or form');

					setTimeout(async () => {
						if (!didResolved) {
							didRejected = true;
							reject('Action canceled by timeout: Could not contact server in a reasonable amount of time');
						}
					}, timeout);

					let updates = {};
					updates['forms/' + projectId + '/full/' + type + '/' + newForm.id] = newForm;

					let didResolved = false;
					let didRejected = false;

					updateUsingFirebaseProxy({
						projectId,
						type: `forms`,
						updates,
						callback: (error) => {
							if (error) {
								didRejected = true;
								reject(error);
							}
							
							if (didRejected) return;
							didResolved = true;
							resolve(newForm);
						},
					});
				});
			};

			let upsertFormRes;
			try {
				upsertFormRes = await (promise(projectId, type, newForm));
				if (upsertFormRes) {
					upsertFormRes = _.omit(upsertFormRes, 'updatedTS');
				}
			} catch (error) {
				onError({
					errorMessage: 'Failed to upsert form',
					error,
					alertParams: !shouldAlertUser ? null : {
						title: reportsMessages.exportErrors.title,
						message: reportsMessages.exportErrors.content,
					},
					methodMetaData: {
						name: 'upsertForm',
						args: { projectId, viewer, form, type },
					},
				});
				hideLoading();

				return { projectId, success: false };
			}

			return { projectId, reportId: newForm.id, form: upsertFormRes, success: true };
		};

		return {
			type: ADD_NEW_FORM,
			payload: getPromise()
		};
	};
}

export function deleteForm(projectId, form, type = 'general') {
	return ({ lokiInstance, platformActions }) => {
	  const getPromise = async () => {
				let updates = {};

				updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/updatedTS'] = Date.now();
			  updates['forms/' + projectId + '/full/' + type + '/' + form.id + '/isDeleted'] = true;
			await updateUsingFirebaseProxy({ projectId, type: `forms_${type}`, updates });
	
			setFormValues({ [form.id]: { ...form, isDeleted: true } }, projectId, undefined, lokiInstance, platformActions, false);
  
			  return { projectId, reportId: form.id, form };
	  };
  
	  return {
		type: DELETE_FORM,
			payload: getPromise(),
	};
	}
}

function saveToLoki(forms = {}, projectId, lokiInstance, cleanAll) {
	if (!cleanAll && Object.keys(forms || {}).length == 0) return;
	
	forms = Object.values(forms).sort((formA, formB) => formA.updatedTS > formB.updatedTS ? -1 : 1);
		
	let allForms = [];
	let deletedFormsIds = [];
	forms.forEach(curr => {
		curr.isDeleted ? 
			deletedFormsIds.push(curr.id) : 
			allForms.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) { 
		lokiInstance.getCollection('forms').cementoDelete({ projectId });
	}
	if (deletedFormsIds.length > 0)
		lokiInstance.getCollection('forms').cementoDelete({ projectId, id: { '$in' : deletedFormsIds }});

	lokiInstance.getCollection('forms').cementoUpsert(allForms);
}

export function setFormEmail({ projectId, formType, formId, selectedEmail }) {
  return ({ platformActions, apiServer }) => {
    const getPromise = async () => {
      if (!projectId || !formType || !formId || !selectedEmail) {
        return;
      }

      const updates = {
        targetEmails: [selectedEmail],
        emailSentDate: null,
        readyToGenerateTS: Date.now(),
      };

      let success = true;
      try {
        await platformActions.net.fetch(apiServer + `/v1/forms/${formId}?projectId=${projectId}&formType=${formType}`, {
          method: 'PATCH',
          body: JSON.stringify(updates),
        });
      } catch (error) {
        success = false;
      } finally {
        const trackText = success ? 'Set Form Email' : 'Set Form Email Failed';
        const toastText = success ? reportsMessages.savedSetFormEmail : reportsMessages.exportErrors.setFromEmailFailed;

        batchDispatch([
          track(trackText, { projectId, formType, formId, selectedEmail }),
          startToast({ title: toastText }),
        ]);

        return { projectId, formType, formId, selectedEmail };
      }
    };

    return {
      type: SET_FORM_EMAIL,
      payload: getPromise(),
    };
  };
};

export const setFormEmailModalParams = ({ isVisible, projectId, formType, formId }) => {
  return {
    type: SET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      projectId,
      formType,
      formId,
      isVisible: _.isNil(isVisible) ? Boolean(projectId && formType && formId) : isVisible,
    },
  };
};

export const unsetFormEmailModalParams = () => {
  return {
    type: UNSET_FORM_EMAIL_MODAL_PARAMS,
    payload: {
      projectId: null,
      formType: null,
      formId: null,
      isVisible: false,
    },
  };
};
