import React from "react";

// Components
import Text from "../../components/CementoComponents/Text";
import EditStrip from "../../components/CementoComponents/EditStrip";
import Dragger from "../../components/CementoComponents/Dragger";

// Others
import theme from '../../assets/css/theme';

const defaultProps = {  
  keyId: null, 
  title: '', 
  fullWidth: false,
  doPropagate: false,
  isDraggable: false, 
  isSelected: false, 
  isEditMode: false, 
  leftSideComp: null, 
  middleComp: null, 
  rightSideComp: null, 
  editIcons: null, 
  maxChars: null, 
  minMode: false,
  onClick: null, 
  titleMaxWidth: null, 
  linksMode: null, 
  mainContainerStyle: {}, 
  rowStyle: {}, 
};


/**
 * @typedef InnerCollapsibleRowProps
 * @property {string} [keyId]
 * @property {string} [linksMode]
 * @property {string | { id: string, defaultMessage: string }} [title]
 * @property {boolean} [minMode]
 * @property {boolean} [isEditMode]
 * @property {boolean} [isSelected]
 * @property {boolean} [doPropagate]
 * @property {boolean} [isDraggable]
 * @property {React.ReactNode} [leftSideComp]
 * @property {React.ReactNode} [middleComp]
 * @property {React.ReactNode} [rightSideComp]
 * @property {number} [maxChars]
 * @property {number} [titleMaxWidth]
 * @property {import('./EditStrip').Icon[]} [editIcons]
 * @property {React.CSSProperties} [mainContainerStyle]
 * @property {React.CSSProperties} [rowStyle]
 * @property {boolean} [fullWidth]
 * @property {() => void} [onClick]
 *
 * @extends React.Component<InnerCollapsibleRowProps>
 */

class InnerCollapsibleRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.setHoverOn = this.setHoverOn.bind(this);
    this.setHoverOff = this.setHoverOff.bind(this);
		this.state = {
			hover: false,
		};
	}

  setHoverOn() {
    if (!this.state.hover) this.setState({ hover: true });
  }

  setHoverOff() {
    if (this.state.hover) this.setState({ hover: false });
  }
	
	onClick(e) {
    const { onClick, doPropagate } = this.props;
    if (!doPropagate)
      e.stopPropagation();
		if (onClick)
			onClick();
  }

	render() {
    const { children, isDraggable = false, leftSideComp, rightSideComp, middleComp, editIcons, maxChars, keyId, isSelected, isEditMode, title, minMode, onClick, titleMaxWidth, linksMode, mainContainerStyle = {}, rowStyle = {}, fullWidth = false } = this.props;
		const { hover } = this.state;
    const { mainContainer, selected, textSelected, row, textHover } = styles;

    const selectedStyle = Object.assign({}, selected, this.props.selectedStyle);

    const sideWidth = 20;
    const hoverAndEditMode = hover && isEditMode;
    return (
      <div
        key={keyId}
        style={{
          ...mainContainer,
          ...(isSelected ? { background: theme.backgroundColorSelected } : {}),
          ...((hover && onClick) || isSelected ? selectedStyle : {}),
          cursor: onClick ? "pointer" : null,
          ...mainContainerStyle,
          // ...(isSelected ? { backgroundColor: theme.innerCollapsibleRowSelectedBackground } : {}),
        }}
        onMouseEnter={this.setHoverOn}
        onMouseLeave={this.setHoverOff}
        onClick={this.onClick}
      >
        <div style={{ ...row, ...rowStyle }}>
          <div style={{ display: 'flex', flex: 3, maxWidth: titleMaxWidth ? titleMaxWidth : 'none' }}>
            {Boolean(!fullWidth) && (
              <div style={{ width: sideWidth }}>
                {Boolean(isDraggable && hoverAndEditMode)
                  ? <Dragger dragId={'dragger'} />
                  : Boolean(leftSideComp) && leftSideComp
                }
              </div>
            )}
            {/* TODO: apply small font only when in sidebar */}
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: `0 ${fullWidth ? 0 : theme.paddingSize}px`, justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {Boolean(title) ?
                <Text
                  linksMode={linksMode}
                  maxLines={minMode ? 2 : null}
                  maxChars={maxChars}
                  style={{
                    ...(isSelected ? textSelected : {}),
                    ...((hover && onClick) ? textHover : {}),
                    maxWidth: titleMaxWidth ? (titleMaxWidth - 20) : 'none',
                    ...(minMode ? { fontSize: theme.fontSizeH6 } : { fontSize: theme.fontSizeH6 })
                  }}
                >
                  {title}
                  </Text>
                  : <div />
              }
              {Boolean(middleComp && !hoverAndEditMode) ? middleComp : <div />}
              </div>
              {children}

            </div>
          </div>
          {Boolean(editIcons || middleComp || rightSideComp)
            ? <div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex', alignSelf: children ? 'flex-start' : 'center', minWidth: '25px' }}>
                {Boolean(hoverAndEditMode)
                  ? Boolean(editIcons) && <EditStrip icons={editIcons} />
                  : Boolean(rightSideComp) && rightSideComp
                }
              </div>
            : Boolean(!fullWidth) && <div style={{ width: sideWidth }} />
          }
        </div>
      </div>
    );
  }
}
const borderColor = theme.headerInfoColor + 70;
const activeBorderColor = theme.innerCollapsibleBrandPrimaryBorders;
const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection:'column',
    flex: 1,
    padding: theme.padding + 'px ' + theme.paddingSize + 'px',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderBottomColor: borderColor,
    borderTopColor: borderColor,
    minHeight: '55px',
    transitionProperty: 'background',
    transitionDuration: '0.2s',
  },
  row: {
    height: theme.rowHeight,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selected: {
    borderTopColor: activeBorderColor,
    borderBottomColor: activeBorderColor,
  },
  textSelected: {
    color: theme.brandPrimary,
    fontWeight: theme.strongBold,
  },
  textHover: {
    color: theme.brandPrimary,
  },
};

InnerCollapsibleRow.defaultProps = defaultProps;

export default InnerCollapsibleRow;