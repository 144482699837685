import { envParams } from "../../../common/configureMiddleware";
import { platformActions } from "../../../common/platformActions";

export const ActionTypes = {
  REBOOT: 'REBOOT',
  SYNC: 'SYNC'
};

const syncProjectEmployees = async (projectId) => {
  await platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/services/employees/sync?projectId=${projectId}&forceSync=true`, { method: 'POST' });
  return;
}

const rebootCamera = async (projectId, cameraId) => {
  await platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/admin/reboot?projectId=${projectId}&cameraLocation=${cameraId}`, { method: 'PUT' });
  return;
}

export async function getMonitor(projectId) {
  let camerasMonitor = await platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/monitor?projectId=${projectId}&subject=cameras`);
  camerasMonitor = camerasMonitor.getJson();

  return camerasMonitor;
}

export async function updateCamera(projectId, camera) {
  await platformActions.net.fetch(
    `${envParams.apiServer}/v1/siteControl/camera/template/update`, {
    method: 'POST',
    body: JSON.stringify({ camera, projectId })
  });
}

export async function getCameras(projectId, fields) {
  let siteControlCameras = await platformActions.net.fetch(`${envParams.apiServer}/v1/siteControl/cameras?projectId=${projectId}&fields=${JSON.stringify(fields)}`);
  siteControlCameras = siteControlCameras.getJson();

  return siteControlCameras;
}

export function CameraActionFactory(actionType) {
  let action;

  switch (actionType) {
    case ActionTypes.REBOOT:
      action = rebootCamera;
      break;
    case ActionTypes.SYNC:
      action = syncProjectEmployees;
      break;
    default:
      break;
  }

  return action;
}
