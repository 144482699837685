
import _ from 'lodash';
import { isEmptyValue, roundTS, localTSToUTC } from './funcs';

/** 
 * Logic for SelectionList input type
 * 
 * @param {{ [valueId: string]: string }} value
 * @param {boolean} isMulti
 * @param {string} newValueId 
 */ 
export const handleSelectionListChange = (value, isMulti, newValueId, isDeleteIfExists = false) => {
  if (!newValueId)
    return value;

  let newValue = {};

  if (isMulti)
    newValue = Object.assign(newValue, value);

  if (isMulti && newValue[newValueId])
    delete newValue[newValueId];
  else if (!isDeleteIfExists || !_.get(value, newValueId))
    newValue[newValueId] = newValueId;

  if (_.isEmpty(newValue))
    newValue = null;

  return newValue;
}

/**
 * Logic for Complex input type
 * 
 * @param {{ [propId: string]: any }} value 
 * @param {string} innerPropId 
 * @param {any} data 
 * @returns 
 */
export const handleComplexTypeChange = (value, innerPropId, data) => {
  let newVal = Object.assign({}, value);
      newVal[innerPropId] = !_.isNil(data) ? data : null; // not just delete cause otherwise it would just not remove it from the db

    if (_.values(newVal).every(v => isEmptyValue(v)))
      newVal = null;

  return newVal;
}

/**
 * 
 * @param {import('moment').MomentInput | null} value 
 * @param {{ timzone: string }} settings 
 * @param {'startOf' | 'endOf'} roundingMode 
 * @returns 
 */
export const handleDateInputChange = (value, settings) => {
  const { timezone: timezoneString } = settings || {};
  const roundingMode = settings?.roundingMode === 'end' ? 'endOf' : 'startOf';
  let retValue = value ? roundTS(value, timezoneString, roundingMode, 'day') : null;
      retValue = localTSToUTC(retValue, timezoneString);
  return retValue;
}