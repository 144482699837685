import { Navigate } from 'react-router-dom';
import Dashboard from '../../../../views/Dashboard/Dashboard';
import Analytics from '../../../../views/Projects/QCAnalytics';
import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import ChecklistPage from '../../../../views/Checklists/ChecklistPage';

export default {
  path: 'qa',
  children: [
    {
      index: true,
      element: <Navigate to={'dashboard'} />,
    },
    {
      path: 'dashboard',
      element: <Dashboard contentType={'qa'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: <Analytics />,
    },
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId/:floorId/:unitId',
          element: <ChecklistPage contentType={'qa'} />,
        },
      ],
    },
  ],
};
