import { Navigate } from 'react-router-dom';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';

export default {
  path: 'forms',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics/dailyReport'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <PropertyAnalytics
          key={'project_PropertyAnalytics_forms_analytics'}
          contentType={'forms'}
          section={'analytics'}
        />
      ),
    },
  ],
};
