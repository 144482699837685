import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../../common/projects/contexts';
import { GridContainer, GridItem } from '../../../components';
import InputField from '../../../components/CementoComponents/InputField';
import Text from '../../../components/CementoComponents/Text';
import theme from '../../../assets/css/theme';
import Collapse from '@kunukn/react-collapse';
import collapse from '../../../assets/img/icons/collapse.png';
import { PrintOutlined } from '@material-ui/icons';
import propertiesMessages from '../../../../common/propertiesTypes/propertiesMessages';
import ReactToPrint from 'react-to-print';
import { convertPdfToImage } from '../../../../common/pdf/actions';
import moment from 'moment';
import { startLoading, hideLoading } from '../../../../common/app/actions';
import * as propertyTypes from '../../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { isEmptyValue } from '../../../../common/app/funcs';
import toggleDown from '../../../assets/img/icons/toggleDown.png';
import useHover from '../../../hooks/useHover';

let UglyPDFReadyToPrint = (props) => {
  const { mode, objectName, sections, allDataByPropId, subjectName, objectId, startLoading, hideLoading, intl, rtl, projects, selectedProjectId } = props;
  const [getReadyToPrint, setGetReadyToPrint] = useState(false);
  const printRef = useRef();
  const printingOperationIdRef = useRef();
  const didLastSectionRendered = useRef(false);
  const pdfAttachmentsStatuses = useRef({});

  const projectData = useMemo(() => projects.getNested([selectedProjectId], {}), [selectedProjectId, projects]);
  const company = useMemo(() => projectData.getNested(['company'], {}), [projectData]);

  useEffect(() => {
    if (!getReadyToPrint) {
      pdfAttachmentsStatuses.current = {};
      didLastSectionRendered.current = false;
    }
  }, [getReadyToPrint]);

  const waitUntilReadyToPrint = useCallback(() => new Promise(resolve => {
    const waitForPrintInterval = setInterval(() => {
      if (didLastSectionRendered.current && Object.values(pdfAttachmentsStatuses.current).every(attachment => Boolean(attachment))) {
        resolve(true);
        clearInterval(waitForPrintInterval);
      }
    }, 500);
  }));

  let pdfAttachments = [];
  return (
    <>
    <ReactToPrint
      pageStyle={'@page { size: auto; margin: 0mm;} @media print { body { -webkit-print-color-adjust: economy; } }'}
      trigger={() => <PrintOutlined style={{ margin: '0px 5px', fontSize: 17, lineHeight: '17px', cursor: 'pointer' }} />}
      content={() => printRef.current}
      onBeforeGetContent={async () => {
        const operationId = uuidv4();
        printingOperationIdRef.current = operationId;
        try {
          startLoading({ title: propertiesMessages.loadingAttachedFiles, overlay: true, operationId });
          setGetReadyToPrint(true);
          await waitUntilReadyToPrint();
        }
        catch (error) {
          console.log("printing props error: ", error);
        }
      }}
      onAfterPrint={() => { 
        hideLoading(printingOperationIdRef.current); 
        setGetReadyToPrint(false);
      }}
    />
    {Boolean(mode === 'page') && (
      <div style={{ display: "none" }}>
        <div ref={printRef}
          style={{
            // padding: theme.padding,
            paddingRight: '10vw',
            paddingLeft: '10vw',
            backgroundColor: theme.backgroundColorBright, direction: rtl ? 'rtl' : 'ltr'
          }}>
          <div style={{ position: 'fixed', top: 0, }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, fontWeight: theme.strongBold, paddingTop: theme.padding, paddingBottom: 3 * theme.padding, marginBottom: 3 * theme.margin }}>
              <Text style={{}}>
                {`${projectData.title && (projectData.title + (Object.values(objectName).length ? ' - ' : ''))}${objectName}`}
              </Text>
              {
                Boolean(company.logo) ?
                  <img style={{ objectFit: 'contain', maxHeight: 50 }} src={company.logo} /> :
                  <Text>{company.title}</Text>
              }
            </div>
          </div>
          {Boolean(getReadyToPrint) && (
            <>
            {sections.map((currSec, sectionIndex) => {
              const currSection = { ...currSec, properties: (currSec.properties || []).filter(prop => !isEmptyValue(allDataByPropId[prop.id])) };

              try { // Try finnally hack to set the last section rendered after return
                if (currSection.properties.length > 0) {
                  let sectionPdfRefs = [];
                    return <GridContainer
                      key={`section_${sectionIndex}`}
                      style={{
                        breakInside: 'avoid',
                        margin: 'auto',
                        paddingTop: 2 * theme.margin,
                        maxWidth: 1000,
                        width: 'auto',
                        display: 'block',
                        flex: 1,
                        flexDirection: 'column'
                      }}>

                      <CollapsableSection open={true} rtl={rtl} section={currSection} titleStyle={{ fontSize: theme.fontSizeH6, color: theme.headerColorDark, marginLeft: 0, marginRight: 0 }} displayArrow={false} noShadow={true}>
                        {currSection.properties.map((prop, propIndex) => {
                          let defaultValue = allDataByPropId[prop.id];
                          if (_.isNil(defaultValue))
                            return null;
                        
                          if ((prop.type == propertyTypes.DRAWINGS_ARRAY || prop.type == propertyTypes.FILES_ARRAY)) {
                            let values = allDataByPropId.getNested([prop.id], [])
                              .map((value, valueIndex) => {
                                if (value.type && value.type.includes('image'))
                                  return <InputField
                                    key={`${prop.id}_${valueIndex}_print`}
                                    id={prop.id}
                                    propId={prop.id}
                                    subjectName={subjectName}
                                    inputKey={prop.id + '_' + objectId}
                                    disabled={true}
                                    settings={prop.settings}
                                    businessType={prop.businessType}
                                    universalId={prop.universalId}
                                    extraTypes={prop.extraTypes}
                                    alignCenter={true}
                                    withResize={false}
                                    name={prop.type == propertyTypes.CERTIFICATION ? null : prop.title}
                                    type={'Picture'}
                                    defaultValue={{ uri: value.uri }}
                                    openPDFInWebPage={false}
                                    innerStyle={{ maxHeight: 250 }}
                                    mode={{ labelStyle: { fontSize: theme.mediumFontSize, marginBottom: -theme.verticalMargin } }}
                                    valueFontSize={theme.fontSize}
                                    style={{ display: 'block', height: 300, padding: '0px 10px',paddingBottom:5 }}
                                  />;
                                else if (value.type == 'pdf') {
                                  let name = `${intl.formatMessage(propertiesMessages.attachment)}  ${pdfAttachments.length + 1}`;
                                  pdfAttachments.push({ name, uri: value.uri });
                                  sectionPdfRefs.push(name);
                                }
                              });
                            return values;
                          }
                          else if (prop.type == propertyTypes.PDF) {
                            let name = prop.title || `${intl.formatMessage(propertiesMessages.attachment)}  ${pdfAttachments.length + 1}`;
                            let uri = allDataByPropId.getNested([prop.id, 'uri']);
                            pdfAttachments.push({ name, uri });
                            sectionPdfRefs.push(name);
                          }
                          else
                            return (<div key={`${prop.id}_print`}>
                              <InputField
                                id={prop.id}
                                propId={prop.id}
                                subjectName={subjectName}
                                inputKey={prop.id + '_' + objectId}
                                style={{ padding: '0px 10px', paddingBottom: 5, fontSize: theme.fontSize }}
                                disabled={true}
                                settings={prop.settings}
                                businessType={prop.businessType}
                                universalId={prop.universalId}
                                extraTypes={prop.extraTypes}
                                alignCenter={true}
                                withResize={false}
                                name={prop.type == propertyTypes.CERTIFICATION ? null : prop.title}
                                type={prop.type}
                                values={prop.values}
                                defaultValue={defaultValue}
                                openPDFInWebPage={false}
                                mode={{ labelStyle: { fontSize: theme.mediumFontSize, marginBottom: -theme.verticalMargin } }}
                                valueFontSize={theme.fontSize}
                                innerStyle={(prop.type == propertyTypes.PICTURE || prop.type == propertyTypes.PDF) ? { display: 'block', height: 500 } : { fontSize: theme.fontSize }}
                              />
                            </div>);
                        }
                        )}
                        {Boolean(sectionPdfRefs.length > 0) && <div style={{ marginTop: theme.verticalMargin }}><Text>{propertiesMessages.seeAttachedFiles}</Text><Text>{sectionPdfRefs.join(', ')}</Text></div>}
                      </CollapsableSection>
                    </GridContainer>
                }
              }
              finally {
                const isLastSectionsLastProp = sectionIndex === sections.length - 1;
                if (isLastSectionsLastProp && !didLastSectionRendered.current)
                  didLastSectionRendered.current = true;
              }

            })}

            {pdfAttachments.map((pdf, index) => {
              const key = `pdf${index}`;
              return <PdfImages key={key} name={pdf.name} uri={pdf.uri} setPdfAttachmentsStatus={(status) =>_.set(pdfAttachmentsStatuses, ['current', key], status)} />;
            })}
            </>
          )}

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', padding: 4 * theme.margin, paddingRight: '20vw', paddingLeft: '20vw', fontSize: 16, paddingBottom: 0 }}>
            <div key={"print_sign"} style={{ breakInside: 'avoid-page' }}>
              <Text>{propertiesMessages.print.sign}</Text><div style={{ height: 20, borderBottom: 'solid 1px', minWidth: 100 }}></div>
            </div>
            <div key={"print_date"} style={{ breakInside: 'avoid-page' }}>
              <Text>{propertiesMessages.print.date}</Text><Text style={{ fontWeight: theme.strongBold }}> {moment().format('l')} </Text>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  )
}

const enhance = compose(
	connectContext(ProjectContext.Consumer),
  injectIntl,
	connect(
		state => ({
			rtl: state.app.rtl,
		}),
		{
			startLoading,
			hideLoading,
		},
	),
);
UglyPDFReadyToPrint = enhance(UglyPDFReadyToPrint);

export default UglyPDFReadyToPrint;


class PdfImages extends React.Component {
	constructor(props) {
		super(props);
		this.state = { doc: null };
		props.setPdfAttachmentsStatus(false);
	}

	async componentDidMount() {
		const { uri, setPdfAttachmentsStatus, convertPdfToImage } = this.props;
		let expires = moment(new Date()).add(2, 'days').format('MM/DD/YYYY');
		let doc = await convertPdfToImage(uri, null, expires);
		if (doc && doc.length > 0) this.setState({ doc });
		setPdfAttachmentsStatus(true);
	}

	render() {
		const { doc } = this.state;
		const { name } = this.props;

		return doc ? (
			<div style={{ breakBefore: 'page', maxWidth: '95vh' }}>
				{Boolean(name) && <Text>{name}</Text>}
				{doc.map((page, pageNum) => (
					<div key={`page_${pageNum}`} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<div>
							<img
								style={{
									maxHeight: '90vh',
									breakInside: 'avoid-page',
									objectFit: 'contain',
									maxWidth: '100%',
								}}
								src={page.uri}
							/>
						</div>
					</div>
				))}
			</div>
		) : (
			<></>
		);
	}
}

PdfImages = connect(null, { convertPdfToImage })(PdfImages);

class CollapsableSection extends React.Component {
	constructor(props) {
		super(props);
		this.collapseTransition = this.collapseTransition.bind(this);
		this.setHover = this.setHover.bind(this);
		this.setOpen = this.setOpen.bind(this);

		this.state = {
			collapseClassName: 'collapse-css-transition',
			open: Boolean(props.open),
			deg: 0,
			hover: false,
		};
	}

	setHover() {
		const { hover } = this.state;
		this.setState({ hover: !hover });
	}

	setOpen() {
		const { open } = this.state;
		this.setState({
			open: !open,
			collapseClassName: 'collapse-css-transition',
		});
	}

	collapseTransition({ isMoving, hasReversed }) {
		if (!isMoving && !hasReversed && this.state.open)
			this.setState({ collapseClassName: 'collapse-css-transition-visible' });
	}

	render() {
		const { rtl, children, section, titleStyle = {}, noShadow, displayArrow = true } = this.props;
		const { open, hover, collapseClassName } = this.state;

		let hoverOrOpen = open || hover;
		return (
			<GridItem
				xs={12}
				onMouseOver={this.setHover}
				onMouseOut={this.setHover}
				style={{
					marginBottom: theme.paddingSize,
					boxShadow: !noShadow && hoverOrOpen ? '0 0 5px rgba(0, 0, 0, 0.2)' : 'none',
					borderRadius: open ? '0 6px 6px' : '4px',
					border: hoverOrOpen ? '1px solid ' + theme.brandPrimary : theme.borderLineNeutralLight + '30',
					backgroundColor: hover && !open ? theme.backgroundColorSelected : theme.backgroundColorBright,
				}}
			>
				<div
					style={{
						overflow: 'hidden',
						alignItems: 'center',
						justifyContent: 'space-between',
						display: 'flex',
						flex: 1,
						paddingLeft: 40,
						paddingRight: 40,
						height: 50,
						cursor: 'pointer',
					}}
					onClick={this.setOpen}
				>
					<div
						style={{
							display: 'flex',
							flex: 1,
							textOverflow: 'ellipsis',
							height: 'inherits',
							alignItems: 'center',
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{Boolean(displayArrow) && (
								<img
									src={collapse}
									style={{
										transform: Boolean(hoverOrOpen) ? 'rotate(90deg)' : rtl ? 'rotate(180deg)' : '',
										padding: 0,
										height: 10,
										transition: '0.2s',
										[rtl ? 'right' : 'left']: theme.paddingSize,
									}}
								/>
							)}
							<span
								style={{
									color: theme.brandPrimary,
									fontWeight: 400,
									fontSize: 20,
									marginLeft: 20,
									marginRight: 20,
									...titleStyle,
								}}
							>
								{section.title}
							</span>
						</div>
					</div>
				</div>

				<Collapse className={collapseClassName} isOpen={open} onChange={this.collapseTransition}>
					<div style={{ padding: '0px 30px 15px 30px' }}>{children}</div>
				</Collapse>
			</GridItem>
		);
	}
}

const MIDDLE_FLEX_BASIS = '80%';
const PrevNextSwitch = props => {
	const { title, onNextClick, onPrevClick, isShowArrows = true } = props;
	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));
	const [arrowPrevRef, isArrowPrevHover] = useHover();
	const [arrowNextRef, isArrowNextHover] = useHover();

	const arrowContainerStyle = {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 45,
		height: '100%',
		alignItems: 'center',
	};
	const arrowImgStyle = {
		height: 7,
		transitionProperty: 'filter',
		transitionDuration: '0.2s',
	};

	return (
		<div
			style={{
				display: 'flex',
				minWidth: 'min-content',
				flexBasis: MIDDLE_FLEX_BASIS,
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: theme.fontSizeH5,
				height: '100%',
			}}
		>
			<div
				ref={arrowPrevRef}
				style={Object.assign({}, arrowContainerStyle, (!onPrevClick || !isShowArrows) && { visibility: 'hidden' })}
        onClick={onPrevClick ? onPrevClick : undefined}
			>
				<img
					src={toggleDown}
					style={Object.assign(
						{ transform: `rotate(${rtl ? '-' : ''}90deg)` },
						arrowImgStyle,
						isArrowPrevHover && { filter: 'invert(30%)' },
					)}
				/>
			</div>

			<div
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					height: '100%',
					alignItems: 'center',
				}}
			>
				<Text style={{ color: theme.brandPrimary }}>{title}</Text>
			</div>

			<div
				ref={arrowNextRef}
				style={Object.assign({}, arrowContainerStyle, (!onNextClick || !isShowArrows) && { visibility: 'hidden' })}
        onClick={onNextClick ? onNextClick : undefined}
			>
				<img
					src={toggleDown}
					style={Object.assign(
						{ transform: `rotate(${rtl ? '' : '-'}90deg)` },
						arrowImgStyle,
						isArrowNextHover && { filter: 'invert(30%)' },
					)}
				/>
			</div>
		</div>
  );
};
