import * as actions from "./actions";
import * as appActions from "../app/actions";
import { Record, Map, OrderedMap } from "immutable";
import { platformActions } from "../platformActions";
import { registerUserScopeLogs } from "../configureMiddleware";
import { PROJECT_EVENTS } from "../projects/trackProjects";

const InitialState = Record({
  isSideMenuOpen: false,
  localImages: Map(),
  currProject: null,
  lastSeenProjects: Map(),
  headerTitle: null,
  uiParams: Map(),
  inDraftMode: false,
  cleanCacheRevokes: 0,
});
const initialState = new InitialState();

function setSuperProperties(properties) {
  let props = registerUserScopeLogs(properties);
  platformActions.mixpanel.registerSuperProperties(props);
}

export default function uiReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.ENTER_DRAFT_MODE: {
      const { draftDetails } = action.payload;
      return state.set("inDraftMode", draftDetails || true);
    }

    case actions.LEAVE_DRAFT_MODE: {
      return state.set("inDraftMode", false);
    }

    case actions.ON_SIDE_MENU_CHANGE: {
      const { isOpen } = action.payload;
      return state.set("isSideMenuOpen", isOpen);
    }

    case actions.TOGGLE_SIDE_MENU:
      return state.update(
        "isSideMenuOpen",
        (isSideMenuOpen) => !isSideMenuOpen
      );

    case PROJECT_EVENTS.ENTER_PROJECT + '_SUCCESS': {
      const { projectId, lastVisitedProjectId } = action.payload;
        setSuperProperties({onSite: lastVisitedProjectId === projectId});
      return state.set('currProject', projectId);
    }

    case PROJECT_EVENTS.LEAVE_PROJECT: {
      const { projectId } = action.payload;
      setSuperProperties({ onSite: false });
      if (state.get("currProject") !== projectId) return state;

      return state.set("currProject", null);
    }

    case actions.SET_UI_PARAM: {
      const { params } = action.payload;
      (params || {}).loopEach(
        (k, v) => (state = state.setIn(["uiParams", k], v))
      );
      return state;
    }

    case appActions.CLEAN_PERMISSION_BASED_DATA + "_SUCCESS": {
      return state.set("cleanCacheRevokes", state.get("cleanCacheRevokes") + 1);
    }

    case actions.SET_HEADER_TITLE: {
      const { title } = action.payload;
      return state.set("headerTitle", title);
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
