import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
const Title = Record({  
  id: null,
  types: null,
  title: null,
  systemTitle: null 
},'title');

export default Title;
