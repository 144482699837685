import ProjectContainerPage from '../../views/Projects/ProjectContainerPage';

import Quality from './nested/project/Quality';
import Safety from './nested/project/Safety';
import Forms from './nested/project/Forms';
import Info from './nested/project/Info';
import SiteControl from './nested/project/SiteControl';
import Issues from './nested/project/Issues';
import Records from './nested/project/Records';
import TasksView from './nested/project/TasksView';
import SelectedProjectRedirects from './nested/project/SelectedProjectRedirects';
import Drawings from './nested/project/Drawings';

export default [
  {
    path: ':selectedProjectId',
    element: <ProjectContainerPage />,
    children: [
      // Header visible
      Safety,
      SiteControl,
      Forms,
      Quality,
      Issues,
      Records,
      Info,
      Drawings,
      // Internal
      TasksView,
      ...SelectedProjectRedirects,
    ],
  },
];
