import React, { Component}  from 'react';
import theme from "../../assets/css/theme";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true
    };
  }

  render() {
    const { mainCompontent, titleComponent, subTitleComponent, rtl, style, width } = this.props;
    return (
      <div style={{ display:'flex', flexDirection:'column', height:'inherit', width: theme.sidebarWidth, minWidth: theme.sidebarWidth, [rtl ? 'borderLeft' : 'borderRight']: (theme.borderLineNeutralLight + '40'), ...style}}>
        {titleComponent}
        {subTitleComponent}
        {mainCompontent}
      </div>
    );
  }
}


export default Sidebar;

