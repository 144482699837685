import { startLoading, startToast } from '../app/actions';
import { platformActions } from "../../common/platformActions";
import { envParams } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import systemMessages from '../app/systemMessages';
import _ from 'lodash';
import { batchDispatch, onError } from '../app/funcs';
import { track } from '../lib/reporting/actions';

export const GET_PROPERTIES_TYPES = 'GET_PROPERTIES_TYPES';
export const PROPERTIES_TYPES_LOADING = 'PROPERTIES_TYPES_LOADING';
export const GET_PROPERTIES_TYPES_BY_ID = 'GET_PROPERTIES_TYPES_BY_ID';
export const PROPERTY_TYPE_BY_ID_LOADING = 'PROPERTY_TYPE_BY_ID_LOADING';
export const PUT_SELECTION_LIST_OPTION = 'PUT_SELECTION_LIST_OPTION';
export const DELETE_SELECTION_LIST_OPTION = 'DELETE_SELECTION_LIST_OPTION';


export function getPropertiesTypes(viewer, projectId, subjectName) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: 'properties',
      firebasePath: `properties/types/projects/${projectId}/${subjectName}`,
      forceMSClientConfig: true,
      queryParams: { subjectName },
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_PROPERTIES_TYPES, payload: { projectId, types: data, subjectName } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);

    if (result) {
      onData(result);
    }

    return { projectId, subjectName };
  }

  return {
    type: PROPERTIES_TYPES_LOADING,
    payload: getPromise(),
  };
}

export function getPropertiesTypeById(viewer, projectId, subjectName, propId) {
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: `properties/${propId}`,
      firebasePath: `properties/types/projects/${projectId}/${subjectName}/properties/${propId}`,
      queryParams: { subjectName },
      forceMSClientConfig: true,
    }
  
    const onData = (data) => {
      let payload = { projectId, subjectName, property: data };
      batchDispatch([{ type: GET_PROPERTIES_TYPES_BY_ID, payload }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      onData(result);
    }

    return { projectId };
  }

  return {
    type: PROPERTY_TYPE_BY_ID_LOADING,
    payload: getPromise(),
  };
}

const checkValues = values => {
  let ret = {};
  if (!_.isArray(values))
    ret.errorMessage = "values is expected to be an array";
  else if (values.some(v => !_.has(v, 'title')))
    ret.errorMessage = "some values are missing a title";
  ret.success = !Boolean(ret.errorMessage);
  return ret;
};

export function putSelectionListOptions({ subjectName, propId, values, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;

      let check = checkValues(values);

      if (!check.success)
        onError({
          errorMessage: check.errorMessage,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId }
          }
        });

      try {
        resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/properties/${propId}/values?projectId=${projectId}&subjectName=${subjectName}`,
          {
            method: 'PUT',
            body: JSON.stringify({ values: _.values(values) })
          });
        resp = await (resp.getJson());

      }
      catch (error) {
        success = false;
        onError({
          alertParams:{
            title: systemMessages.saveFailed
          },
          errorMessage: 'putSelectionListOptions failed',
          error,
          methodMetaData: {
            name: 'putSelectionListOptions',
            args: { subjectName, propId, values, projectId },
          }
        });
      }
      finally {
        dispatch(track('DynamicSelectionList - create options', { subjectName, propId, values, projectId, success }));
        if (success) {
          dispatch(startToast({ title: systemMessages.savedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: PUT_SELECTION_LIST_OPTION,
      payload: getPromise()
    };
  };
}

export function removeSelectionListOption({ subjectName, propId, valueId, projectId }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(startLoading({ title: systemMessages.savingMessage, overlay: true, hideOnBackgroundPress: false }));
      let success = true;
      let resp;
      try {
        resp = await platformActions.net.fetch(`${envParams.apiServer}/v1/properties/${propId}/values?projectId=${projectId}&subjectName=${subjectName}`,
          {
            method: 'DELETE',
            body: JSON.stringify({ valuesToDelete: [valueId] })
          });
        resp = await (resp.getJson());
      }
      catch (error) {
        success = false;
        onError({
          alertParams:{
            title: systemMessages.saveFailed
          },
          errorMessage: 'removeSelectionListOption failed',
          error,
          methodMetaData: {
            name: 'removeSelectionListOption',
            args: { subjectName, propId, valueId, projectId },
          }
        });
      }
      finally {
        dispatch(track('DynamicSelectionList - removed option', { subjectName, propId, valueId, projectId, success }));
        if (success) {
          dispatch(startToast({ title: systemMessages.deletedSuccessfully }));
        }
        return { success, projectId, subjectName, propId, changedValues: _.get(resp, ['values']) };
      }
    };
    return {
      type: DELETE_SELECTION_LIST_OPTION,
      payload: getPromise()
    };
  };
}
