import { startToast } from "../app/actions";
import systemMessages from "../app/systemMessages";

export const ON_SIDE_MENU_CHANGE = "ON_SIDE_MENU_CHANGE";
export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";
export const HIDE_SIDE_MENU = "HIDE_SIDE_MENU";
export const SET_URL_PARAMS = "SET_URL_PARAMS";
export const SET_UI_PARAM = "SET_UI_PARAM";
export const SET_HEADER_TITLE = "SET_HEADER_TITLE";
export const CLEAR_ALL_URL_PARAMS = "CLEAR_ALL_URL_PARAMS";
export const ENTER_DRAFT_MODE = "ENTER_DRAFT_MODE";
export const LEAVE_DRAFT_MODE = "LEAVE_DRAFT_MODE";
export const DRAFT_VALIDATOR = "DRAFT_VALIDATOR";

export function draftValidator(action, onCancel) {
  return ({ dispatch, getState }) => {
    if (getState().ui.inDraftMode)
      dispatch(
        startToast({
          overlay: true,
          mandatory: true,
          title: systemMessages.unsavedAlert.title,
          message: systemMessages.unsavedAlert.content,
          actions: [
            { message: systemMessages.unsavedAlert.yes, onClick: action },
            { message: systemMessages.unsavedAlert.no, color: "success", onClick: onCancel },
          ],
        })
      );
    else action();

    return {
      type: DRAFT_VALIDATOR,
    };
  };
}

export function onDraftModeChange(draftDetails) {
  return {
    type: draftDetails ? ENTER_DRAFT_MODE : LEAVE_DRAFT_MODE,
    payload: { draftDetails: draftDetails },
  };
}

export function onSideMenuChange(isOpen) {
  return {
    type: ON_SIDE_MENU_CHANGE,
    payload: { isOpen },
  };
}

export function setHeaderTitle(title) {
  return {
    type: SET_HEADER_TITLE,
    payload: { title },
  };
}

export function saveUIParams(params) {
  return {
    type: SET_UI_PARAM,
    payload: { params },
  };
}

export function toggleSideMenu() {
  return {
    type: TOGGLE_SIDE_MENU,
  };
}

export function hideSideMenu() {
  return {
    type: HIDE_SIDE_MENU,
  };
}
