import React from "react";
import theme from "../../assets/css/theme";
import iconV from "../../assets/img/icons/check-v.svg";
import iconVV from "../../assets/img/icons/check-vv.svg";
import iconIrrelevant from "../../assets/img/icons/check-irrelevant.svg";
import iconPartial from "../../assets/img/icons/check-partial.svg";
import iconX from "../../assets/img/icons/check-x.svg";
import iconResolve from "../../assets/img/icons/checkResolve.svg";
import iconEmpty from "../../assets/img/icons/check-empty.svg";
import iconDoneV from "../../assets/img/icons/check_done_v.svg";
import iconDoneVV from "../../assets/img/icons/check_done_vv.svg";
import issuesIcon from "../../assets/img/icons/issue.png";
import closedIssue from "../../assets/img/icons/closedIssue.png";
import cameraImage from "../../assets/img/icons/camera-outline.png";
import { columnTypes } from "../../../common/analytics/funcs";
import {
  ReportContext,
  QCReportContext,
} from "../../../common/analytics/contexts";

import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";

export const rowHeight = 20;
export const rowPadding = 5;

export const transitionTimingFunction = "ease-in-outs";
export const transition = "all 1s";

const confirm2BgColor = "#25cb77";
const confirmBgColor = "#32e88c";
const irrelevantBgColor = "#32e88c";
const partialBgColor = "#f27d22";
const resolvedBgColor = "#ffdd67";
const rejectBgColor = "#e63a34";

class QCTableCell extends React.Component {
  constructor(props) {
    super(props);
    this.getIssueBadgeComp = this.getIssueBadgeComp.bind(this);
    this.getDocBadgeComp = this.getDocBadgeComp.bind(this);
    this.getColoredSubCell = this.getColoredSubCell.bind(this);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState != this.state) {
      shouldComponentUpdate = true;
    }

    var currCellId = this.props.cell.row.id.concat(
      this.props.cell.column.HeaderValue
    );

    if (
      this.props.selectedCell != nextProps.selectedCell &&
      ((this.props.selectedCell && this.props.selectedCell.id == currCellId) ||
        (nextProps.selectedCell && nextProps.selectedCell.id == currCellId))
    ) {
      shouldComponentUpdate = true;
    }

    if (
      this.props.showLowerStatus != nextProps.showLowerStatus &&
      nextProps.selectedBadges == this.props.selectedBadges
    )
      shouldComponentUpdate = true;

    if (nextProps.selectedViewType != this.props.selectedViewType)
      shouldComponentUpdate = true;

    if (
      !shouldComponentUpdate &&
      nextProps.row == this.props.row &&
      nextProps.selectedStatus == this.props.selectedStatus &&
      this.props.postsMap == nextProps.postsMap
    )
      return false;

    if (
      (nextProps.row == null && this.props.row != null) ||
      (nextProps.row != null && this.props.row == null)
    ) {
      shouldComponentUpdate = true;
    }

    var shouldComponentUpdate =
      shouldComponentUpdate ||
      this.props.postsMap != nextProps.postsMap ||
      this.props.cell.column.HeaderValue != nextProps.cell.column.HeaderValue ||
      this.props.selectedStatus != nextProps.selectedStatus ||
      this.props.cell.type != nextProps.cell.type ||
      this.props.selectedBadges != nextProps.selectedBadges ||
      this.props.selectedViewType != nextProps.selectedViewType ||
      this.props.cell.cell.values[nextProps.cell.column.HeaderValue] !=
        nextProps.cell.cell.values[nextProps.cell.column.HeaderValue];

    return shouldComponentUpdate;
  }

  widthCalculator(count, total) {
    if (!count)
      return 0;

    return Math.max(count*(100/total), 4);
  }

  getIssueBadgeComp(issuesCount, isOpen) {
    if (!issuesCount)
      return null;

    const { rtl } = this.props;
    const direction = isOpen ? rtl : !rtl;
    const side = direction ? 'left' : 'right';
    const divStyle = { opacity: Boolean(issuesCount) ? 1 : 0, transition:'opacity 1s linear', position: 'absolute', [side]: [direction ? '77%' : '61%'], top: "42%", transform: 'translate(-50%, -50%)'};
    const spanStyle = { color: isOpen ? 'red' : '#5cae65', fontSize: issuesCount > 9 ? 8 : 9,  fontWeight:400, position:"absolute", top:"68%", [side]:  [direction ? '50%' : (issuesCount > 9) ? '14%' : '26%'], transform:"translate(-50%, -50%)" }

    return (
      <div style={divStyle}>
        <img src={isOpen ? issuesIcon : closedIssue} style={{ height: 15 }}/>
        <span style={spanStyle}>
          {issuesCount}
        </span>
      </div>
    )
  }

  getDocBadgeComp(docsCount) {
    if (!docsCount)
      return null;

    const { rtl } = this.props;
    return (
      <div style={{opacity: Boolean(docsCount) ? 1 : 0, transition:'opacity 1s linear', position: 'absolute', [rtl ? 'right' : 'left']:  [rtl ? '66%' : '82%'], top: "48%", transform: 'translate(-50%, -50%)' }}>
        <img src={cameraImage}/>
      </div>
    )
  }

  getColoredSubCell(statusCount, statusColor, isCompleted, itemsCount) {
    return (
      Boolean(statusCount)
      ? <div style={{
          width: (isCompleted ? 0 : this.widthCalculator(statusCount, itemsCount) + '%'),
          height: '100%',
          backgroundColor: statusColor,
          cursor: 'pointer',
          transitionProperty: 'width',
          transitionDuration: '0.6s',
          transitionTimingFunction:'ease-in'
        }}/>
      : null
    )
  }

  getCellBGColor = () => {
    const { selectedStatus } = this.props

    let bgColor = null;
    
    if (selectedStatus.confirm2)
      bgColor = confirm2BgColor;
    else if (selectedStatus.confirm)
      bgColor = confirmBgColor;
    else if (selectedStatus.resolved)
      bgColor = resolvedBgColor;

    return bgColor;
  }

  getCellCounts = () => {
    const { calcAllStatuses, cell, selectedBadges, selectedStatus, postsMap, showLowerStatus } = this.props

    const itemsCount = cell.row.values[cell.column.HeaderValue].total;
    // if (cell.row.values[cell.column.HeaderValue] && cell.row.values[cell.column.HeaderValue].irrelevant)
    //   itemsCount -= cell.row.values[cell.column.HeaderValue].irrelevant;

    let rejectedCount = 0;
    let confirm2Count = 0;
    let confirmedCount = 0;
    let irrelevantCount = 0;
    let partialCount = 0;
    let resolvedCount = 0;
    let grade = null;

    if (Boolean(cell.row.values[cell.column.HeaderValue]) && selectedStatus) {
      rejectedCount = selectedStatus.rejected
        ? cell.row.values[cell.column.HeaderValue].issues
        : 0;
      confirm2Count = cell.row.values[cell.column.HeaderValue].confirm2;
      confirmedCount = cell.row.values[cell.column.HeaderValue].confirmed;
      irrelevantCount = cell.row.values[cell.column.HeaderValue].irrelevant;
      partialCount = cell.row.values[cell.column.HeaderValue].partial;
      resolvedCount = cell.row.values[cell.column.HeaderValue].resolved;
      if (cell.row.values[cell.column.HeaderValue].gradeItems)
        grade =
          (cell.row.values[cell.column.HeaderValue].grade * 100) /
          cell.row.values[cell.column.HeaderValue].gradeItems;
    }

    if (!showLowerStatus) {
      if (selectedStatus.confirm) {
        resolvedCount = 0;
      } else if (selectedStatus.confirm2) {
        resolvedCount = 0;
        confirmedCount = 0;
      } else if (selectedStatus.resolved) {
        // Nothing, this is resolve
      } else {
        // This is a case where only rejected should be displayed
        resolvedCount = 0;
        confirmedCount = 0;
        confirm2Count = 0;
      }
    }

    if (!(selectedStatus.partial && selectedStatus.confirm && selectedStatus.confirm2 && selectedStatus.resolved)) {
      if (selectedStatus.confirm2) {
        
      }
      else if (selectedStatus.confirm) {
        confirmedCount = confirmedCount + confirm2Count;
        confirm2Count = 0;
      } 
      else if (selectedStatus.resolved) {
        resolvedCount = resolvedCount + confirmedCount + confirm2Count;
        confirmedCount = 0;
        confirm2Count = 0;
      } 
      else {
        resolvedCount = 0;
        confirmedCount = 0;
        confirm2Count = 0;
      }
    }


    let itemsWithAnyValueCount = 0;
    if (calcAllStatuses)
      itemsWithAnyValueCount = resolvedCount + confirmedCount + confirm2Count + rejectedCount + partialCount;
    else {
      const isLocationsAggregation = Boolean(cell.row.values[cell.column.HeaderValue].isLocationsAggrigation);

      if (selectedStatus.partial && selectedStatus.confirm && selectedStatus.confirm2 && selectedStatus.resolved)
        itemsWithAnyValueCount = resolvedCount + confirmedCount + confirm2Count + partialCount;
      else if (selectedStatus.partial)
        itemsWithAnyValueCount = partialCount;
      else if (selectedStatus.confirm) {
        itemsWithAnyValueCount = confirm2Count + confirmedCount;
        if (isLocationsAggregation)
          itemsWithAnyValueCount += partialCount;
      }
      else if (selectedStatus.confirm2)
        itemsWithAnyValueCount = confirm2Count;
      else if (selectedStatus.resolved)
        itemsWithAnyValueCount = resolvedCount + confirmedCount + confirm2Count;
    }

    const beforeRangeCount = cell.row.values[cell.column.HeaderValue].beforeRange;
    const afterRangeCount = cell.row.values[cell.column.HeaderValue].afterRange;
    const SHOW_CURRENT_STATUS_COUNTER_ON_CELL = false;
    if (SHOW_CURRENT_STATUS_COUNTER_ON_CELL && (beforeRangeCount || afterRangeCount))
      itemsWithAnyValueCount += Number(beforeRangeCount) + Number(afterRangeCount);
    

    let closedIssues = false;
    let issues = false;
    let docs = false;

    let checklistId = cell.column.mainColumnId;
    let subColumnId = cell.column.subColumnId || "all";
    closedIssues =
      Boolean(
        postsMap &&
          postsMap.closedIssues &&
          postsMap.closedIssues[checklistId] &&
          postsMap.closedIssues[checklistId][subColumnId]
      ) && postsMap.closedIssues[checklistId][subColumnId][cell.row.id];
    issues =
      Boolean(
        postsMap &&
          postsMap.issues &&
          postsMap.issues[checklistId] &&
          postsMap.issues[checklistId][subColumnId]
      ) && postsMap.issues[checklistId][subColumnId][cell.row.id];
    docs =
      Boolean(
        postsMap &&
          postsMap.docs &&
          postsMap.docs[checklistId] &&
          postsMap.docs[checklistId][subColumnId]
      ) && postsMap.docs[checklistId][subColumnId][cell.row.id];

    let issuesCount = 0;
    let closedIssuesCount = 0;
    let docsCount = 0;

    if (selectedBadges) {
      issuesCount = Boolean(issues && selectedBadges.issues)
        ? Object.values(issues).length
        : 0;
      closedIssuesCount = Boolean(closedIssues && selectedBadges.issues)
        ? Object.values(closedIssues).length
        : 0;
      docsCount = Boolean(docs && selectedBadges.docs)
        ? Object.values(docs).length
        : 0;
    }

    return {
      beforeRange: beforeRangeCount,
      afterRange: afterRangeCount,
      
      confirm: confirmedCount,
      confirm2: confirm2Count,
      resolved: resolvedCount,
      rejected: rejectedCount,
      partial: partialCount,
      irrelevant: irrelevantCount,

      total: itemsCount,
      totalWithValues: itemsWithAnyValueCount,
      
      grade,

      issues: issuesCount,
      closedIssues: closedIssuesCount,
      docs: docsCount,
    }
  }

  render() {
    const { cell, cellClickHandler, rtl, selectedViewType, selectedCell, isFirstColumn } = this.props;
    let viewType = selectedViewType && Object.keys(selectedViewType).length ? Object.keys(selectedViewType)[0] : "qcProgress";
    if (cell === null)
      return null;

    let addClassNames = cell.className || "";
    let isSelectedCell = false;
    if (cell.row.values[cell.column.HeaderValue] && selectedCell && selectedCell.id == cell.row.id.concat(cell.column.HeaderValue))
      isSelectedCell = true;

    if (isFirstColumn)
      addClassNames = rtl ? addClassNames.concat(" shadow-right") :  addClassNames.concat(" shadow-left");
    // TODO: Get this from outside?
    let cellStyles = {cursor: 'pointer'};

    if (cell.row.values[cell.column.HeaderValue] == null)
      return <td className={addClassNames.concat(" EmptyCell")}></td>

    Object.assign(cellStyles, cell.style);

    const counts = this.getCellCounts();
    const bgColor = this.getCellBGColor();
    
    let cellContent = null;
    
    let isCompleted = (counts.totalWithValues > 0) && (counts.totalWithValues == counts.total);

    const isLocationsAggregation = Boolean(cell.row.values[cell.column.HeaderValue].isLocationsAggrigation);
    const isAggregatedCell = !((cell.column.columnType == columnTypes.sub) && !isLocationsAggregation);
    if (!isAggregatedCell) {
      let icon = null;

      if (counts.confirm > 0)
        icon = iconV;
      else if (counts.confirm2 > 0)
        icon = iconVV;
      else if (counts.partial > 0)
        icon = iconPartial;
      else if (counts.rejected > 0)
        icon = iconX;
      else if (counts.resolved > 0)
        icon = iconResolve;
      else if (counts.irrelevant > 0)
        icon = iconIrrelevant;
      else
        icon = iconEmpty;

      cellContent = (
        <div
          style={{
            position: "absolute",
            color: "black",
            fontSize: 11,
            verticalAlign: "middle",
          }}
        >
          {Boolean(icon) && (
            <img src={icon} style={{ height: 20, width: 20 }} />
          )}
        </div>
      );
    } else {
      const isCompletedOutOfRange = (counts.beforeRange || counts.afterRange) && (Number(counts.beforeRange) + Number(counts.afterRange) == counts.total)
      if (!isCompletedOutOfRange && (counts.beforeRange || counts.afterRange)) // Case the item was completed during the range - we will show 100% and the colors behind it
        isCompleted = false;

      if (viewType != "qcProgress")
        // In grade view, we do not want the whole cell to be green
        isCompleted = false;

      if (isCompleted) 
        addClassNames = addClassNames.concat(" CompletedCell");

      cellContent = (
        <div
          className="cellContent"
          style={{
            position: "absolute",
            display: "inline-flex",
            width: "100%",
            height: "100%",
          }}
        >
          {isCompleted ? 
          <div
            style={{
              width: (isCompleted ? '100%' : '0%'),              
              height: '100%',
              cursor: 'pointer',
              transitionProperty: 'width',
              transitionDuration: '0.6s',
              transitionTimingFunction:'ease-in',
              backgroundColor: isCompletedOutOfRange ? theme.brandNeutralLight : bgColor,
            }}/> :
          <>
                {this.getIssueBadgeComp(counts.issues, true)}
                {this.getIssueBadgeComp(counts.closedIssues)}
                {this.getDocBadgeComp(counts.docs)}
               
                {this.getColoredSubCell(counts.beforeRange, theme.brandNeutralLight, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.confirm2, confirm2BgColor, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.confirm, confirmBgColor, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.partial, (viewType == "qcProgress") ? confirmBgColor : partialBgColor, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.resolved, resolvedBgColor, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.rejected, rejectBgColor, isCompleted, counts.total)}
                {this.getColoredSubCell(counts.afterRange, theme.brandNeutralLight, isCompleted, counts.total)}
            </>
          }
          </div>
      );
    }

      return (
        <td className={addClassNames} key={cell.row.id} style={cellStyles} 
            onClick={() => {
              cellClickHandler(cell, isAggregatedCell, cell.row.id.concat(cell.column.HeaderValue))}
            }>
          <div
            className={Boolean(isSelectedCell) ? 'main selectedCell' : 'main'}
            style={{
              position: 'relative',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              alignItems: 'center',
            }}
          >
            {cellContent}
            {Boolean(isAggregatedCell) && <div 
              className='number' 
              style={{ 
                position: 'absolute',
                color: 'black', 
                verticalAlign: 'middle',
                top:rowHeight/2-2,
                fontFamily: 'Assistant',
                fontSize: 14,
                fontWeight: 400
              }}>
                
            {Boolean(viewType == "qcProgress" && !isNaN(counts.totalWithValues)) && 
                (!isCompleted && counts.total > 0 ? Math.round(counts.totalWithValues * (100 / counts.total)) + '%' : <img src={counts.confirm2 == counts.totalWithValues ? iconDoneVV : iconDoneV} style={{ height: 20, width: 20 }} />)}
            
            {Boolean(viewType == "grade" && counts.grade != null) ? 
              Math.round(counts.grade) : null}
            
              </div>}
            {this.getIssueBadgeComp(counts.issues, true)}
            {this.getIssueBadgeComp(counts.closedIssues)}
            {this.getDocBadgeComp(counts.docs)}

          </div>
        </td>
      )
    }
}

const enhance = compose(
  connectContext(ReportContext.Consumer),
  connectContext(QCReportContext.Consumer)
);
export default enhance(QCTableCell);
