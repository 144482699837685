import * as actions from "./actions";
import { isMixpanelInit, writeLog, registerUserScopeLogs, getAppState } from "../../configureMiddleware";
import { firebaseActions } from "../redux-firebase";
import * as usersActions from "../../users/actions";
import * as authActions from "../../auth/actions";
import * as checklistActions from "../../checklists/actions";
import { debugParams } from '../utils/utils';

import { platformActions } from "../../platformActions";
import { Record } from "../../transit";
import { Map } from "immutable";
import { CLEAR_ALL_DATA } from '../../app/actions.js';
import { PROJECT_EVENTS } from "../../projects/trackProjects.js";

// TODO: Change this to be middlewere
const InitialState = Record(
  {
    errors: Map(),
    isAdmin: false,
  },
  "reporting"
);

function mixpanelSet(key, value) {
  var obj = {};
  obj[key] = value;
  platformActions.mixpanel.set(obj);
}

function setSuperProperties(properties) {
  let props = registerUserScopeLogs(properties);
  platformActions.mixpanel.registerSuperProperties(props);
}

const trackInternal = (state) => (title, props) => {
  if (debugParams.disableMixpanel)
    return;

  const appState = getAppState();
  if (!appState || !appState.getNested(["users", "viewer", "id"]))
    return;

  // If user have no displayName
  if (!title) return;
  else if (props) platformActions.mixpanel.trackWithProperties(title, props);
  else platformActions.mixpanel.track(title);

  //writeLog('info', title, 'mixpanelTrack', props);
};

const initialState = new InitialState();

export default function reportingReducer(state = initialState, action) {
  if (!isMixpanelInit) return state;
  let Mixpanel = platformActions.mixpanel;
  const track = trackInternal(state);

  switch (action.type) {
    case usersActions.SET_ADMIN_MODE: {
      const { newMode } = action.payload;
      return state.set("isAdmin", newMode);
    }

    case actions.TRACK: {
      const { title, props, withMixpanelCounter } = action.payload;
      track(title, props);
      if (withMixpanelCounter) Mixpanel.increment(title, 1);
      break;
    }

    case actions.TRACK_START: {
      const { trackTitle } = action.payload;
      Mixpanel.timeEvent(trackTitle);
      break;
    }

    case actions.TRACK_END: {
      const { trackTitle } = action.payload;
      track(trackTitle);
      break;
    }

    case checklistActions.EXPORT_CHECKLIST_AS_PDF: {
      const { checklistId, checklistItemId, success, viewer } = action.payload;
      if (action.payload.hasOwnProperty("success")) {
        var eventProps = {
          success,
          checklistId,
          checklistItemId,
          viewer: { displayName: viewer.displayName, id: viewer.id },
        };
        track("exportChecklistPFD", eventProps);
        Mixpanel.increment("exportChecklistPFD", 1);
      }
      break;
    }

    case checklistActions.GET_CHECKLIST_STATUS_JSON: {
      const { success, viewer } = action.payload;
      if (action.payload.hasOwnProperty("success")) {
        var eventProps = {
          success,
          viewer: { displayName: viewer.displayName, id: viewer.id },
        };
        track("filterChecklistStatus", eventProps);
        Mixpanel.increment("filterChecklistStatus", 1);
      }
      break;
    }

    

    case authActions.SIGN_OUT + "_SUCCESS": {
      const { silent } = action.payload;
      if (silent) track("silent_logout");
      else track("logout");

      Mixpanel.reset();
      break;
    }

    case actions.ON_SET_VIEWER_SCOPE: {
      const { superProperties } = action.payload;
      if (!superProperties) return state;

      setSuperProperties(superProperties);
      break;
    }

    case usersActions.ON_PROFILE_UPDATE + "_SUCCESS":
      if (!action.payload) return state;
      track("profileUpdate", { userId: action.payload.user.id });
    case usersActions.GET_MY_USER_DETAILS: {
      if (!action.payload) return state;
      const { user, mixpanelIgnore } = action.payload;
      if (!user || mixpanelIgnore) return state;

      // MixPanel
      if (user.displayName) {
        var nameArr = user.displayName.split(" ");
        var firstName = null;
        var lastName = null;
        if (nameArr.length > 2) {
          lastName = nameArr.pop();
          firstName = nameArr.join(" ");
        } else if (nameArr.length > 1) {
          firstName = nameArr[0];
          lastName = nameArr[nameArr.length - 1];
        } else firstName = nameArr[0];

        mixpanelSet("displayName", user.displayName);
        mixpanelSet("$first_name", firstName);
        if (lastName) mixpanelSet("$last_name", lastName);
      }

      let trades = [];
      let company = state.getNested(["companies", "map", user.companyId], {});
      let title = state.getNested(
        ["titles", "map", user.title, "systemTitle"],
        ""
      );
      Object.values(user.trades || {}).loopEach((i, id) => {
        trades.push(state.getNested(["trades", "map", id, "systemTitle"], ""));
      });

      mixpanelSet("$email", user.email);
      mixpanelSet("companyName", company.name);
      mixpanelSet("companyId", user.companyId);
      mixpanelSet("title", title);
      mixpanelSet("titleId", user.title);
      mixpanelSet("trades", trades);
      mixpanelSet("tradesIds", Object.values(user.trades || {}));
      mixpanelSet("phoneNumber", user.phoneNumber);
      mixpanelSet("address", user.address);
      mixpanelSet("workingArea", user.workingArea);
      break;
    }

    case firebaseActions.FCM_TOKEN_ALREADY_UPDATED:
    case firebaseActions.FCM_TOKEN_SAVED + "_SUCCESS": {
      const { registrationToken } = action.payload;
      if (registrationToken)
        mixpanelSet("registrationToken", registrationToken);
      break;
    }

    case actions.INIT_USER_DATA: {
      if (!action.payload) return state;
      
      const { user } = action.payload;
      Mixpanel.identify(user.id);

      if (user.phoneNumber) mixpanelSet("phoneNumber", user.phoneNumber);

      mixpanelSet("id", user.id);
      break;
    }

    case authActions.SMS_VERIFICATION_VERIFIED: {
      const { number, userId } = action.payload;

      Mixpanel.identify(userId);
      mixpanelSet("phoneNumber", number);
      mixpanelSet("id", userId);
      track("smsVerification");
      break;
    }

    case firebaseActions.FIREBASE_ON_AUTH: {
      mixpanelSet("Created", new Date().toISOString());
      Mixpanel.increment("loginCount", 1);
      break;
    }

    case PROJECT_EVENTS.UPDATE_PROJECT_MEMBERS: {
      const { project } = action.payload;
      track("updateProjectMembers", project);
      break;
    }

    case PROJECT_EVENTS.GET_PROJECTS: {
      if (!action.payload) return state;

      var projectsCounter = 0;
      const { projects } = action.payload;
      if (projects) projectsCounter = Object.values(projects).length;
      //track("projectsLoaded", {projectsCounter});
      break;
    }

    

    // case appsActions.APP_START: {
    //   Mixpanel.sharedInstanceWithToken('8a7dab48cc06c626afe1ebc92c2cea16');
    //   break;
    // }

    // case authActions.TRY_REFRESH_AUTH0_TOKEN: {
    //   const { auth0ClientId, userStorageId } = action.payload;
    //   track("try_refresh_auth0_token", { auth0ClientId, userStorageId });
    //   break;
    // }

    // case usersActions.GET_USER:{
    //   const { user } = action.payload;

    //   // MixPanel
    //   if (user.displayName) {
    //     // Split the name
    //     nameArr = user.displayName.split(' ');

    //     if (nameArr.length > 2) {
    //       var lastName = nameArr.pop();
    //       var firstName = nameArr.join(' ');
    //     } else if (nameArr.length > 1) {
    //         var firstName = nameArr[0];
    //         var lastName = nameArr[nameArr.length - 1];
    //     } else
    //       var firstName = nameArr[0];

    //     mixpanelSet('$first_name', firstName);
    //     if (lastName)
    //       mixpanelSet('$last_name', lastName);

    //     mixpanelSet('displayName', user.displayName);
    //   }

    //   if (user.email)
    //     mixpanelSet('$email', user.email);

    //   if (user.companyName)
    //     mixpanelSet('company', user.companyName);

    //   if (user.title)
    //     mixpanelSet('title', user.title);

    //   if (user.address)
    //     mixpanelSet('address', user.address);

    //   if (user.city)
    //     mixpanelSet('city', user.city);

    //   if (user.state)
    //     mixpanelSet('state', user.state);

    //   if (user.zip)
    //     mixpanelSet('zip', user.zip);

    //   // Firebase Analytics
    //   // Analytics.setUserId(user.id);
    //   // Analytics.setUserProperty('displayName', user.displayName);
    //   // Analytics.setUserProperty('company', user.company);

    //   // Analytics.logEvent('view_item', {
    //   //   'item_id': 'login'
    //   // });
    //   break;
    // }
  }

  return state;
}

