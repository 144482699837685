import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';
export const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';


export function getPermissions(viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'global' };
    const resourceParams = {
      resourceName: 'permissions',
      firebasePath: `permissions/v3`,
      forceMSClientConfig: true,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_PERMISSIONS, payload: { permissions: data, immediateGlobalStorageSave: true, lastUpdateTS: 1 } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      batchDispatch([{
        type: GET_PERMISSIONS,
        payload: { permissions: result, immediateGlobalStorageSave: true },
      }]);
    }
  }

  return {
    type: PERMISSIONS_LOADING,
    payload: getPromise(),
  };
}

