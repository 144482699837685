import { removeProjectsStorage } from '../projects/actions';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import { batchDispatch } from '../app/funcs';
import serverSDK from '@cemento-sdk/server';

export const GET_CHECKLIST_ITEMS = 'GET_CHECKLIST_ITEMS';
export const CHECKLIST_ITEMS_LOADING = 'CHECKLIST_ITEMS_LOADING';
export const SAVE_CHECKLIST_ITEMS = 'SAVE_CHECKLIST_ITEMS';
export const CLEAN_CHECKLIST_ITEMS_CACHE_DATA = 'CLEAN_CHECKLIST_ITEMS_CACHE_DATA';

export function getChecklistItems(viewer, projectId) { 
  const getPromise = async () => {
    const scopeParams = {
      scope: 'projects',
      scopeId: projectId,
    };

    const resourceParams = {
      subject: 'checklistItems',
      getData: () => {
        return serverSDK.checklists.getChecklistItems({ projectId });
      },
    };
  
    const onData = (data) => {
      batchDispatch([{ type: GET_CHECKLIST_ITEMS, payload: { projectId, checklistItems: data } }]);
    }
    
    const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);
    
    if (result){
      onData(result)
    }

    return { projectId };
  }

  return {
    type: CHECKLIST_ITEMS_LOADING,
    payload: getPromise(),
  };
}

export function cleanChecklistItemsCachedData() {  
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['checklistItems', 'map'],
        ['checklistItems', 'didLoad'],
      ];
  
      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      await dispatch(await removeProjectsStorage(projectIdsArray, projectStateToRemove));
  
    };
    return {
      type: CLEAN_CHECKLIST_ITEMS_CACHE_DATA,
      payload: getPromise()
    };
  };
};