import SignatureCanvas from 'react-signature-canvas';
import Modal from '../../web/components/CementoComponents/Modal';
import theme from '../../web/assets/css/theme';
import Button from '../../web/app/standardComponents/Button';
import CloseButton from '../../web/app/CloseButton';
import User from '../../web/components/CementoComponents/User';
import SelectableUsers from '../../web/components/CementoComponents/SelectableUsers';
import * as permissionsFunc from '../../common/permissions/funcs';
import StandardInput from '../../web/components/CementoComponents/StandardInput';
import Select from 'react-select';
import { uploadImage } from './actions';
import { useCallback, useRef, useContext, useState, useEffect, useMemo } from 'react';
import { startToast, startLoading, hideLoading } from '../../common/app/actions';
import { injectIntl } from 'react-intl';
import { REPORT_STATE_CLOSED, REPORT_STATE_AWAITING_SIGNATURE } from '../forms/formsStatusStates';
import { upsertForm } from '../forms/actions';
import { getRoundedDate } from '../../common/lib/utils/utils';
import moment from 'moment-timezone';
import { getDispatch, lokiInstance } from '../../common/configureMiddleware';
import { ProjectContext } from '../../common/projects/contexts';
import systemMessages from '../../common/app/systemMessages';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { track } from '../lib/reporting/actions';

const SignatureModal = ({
  isOpen,
  handleClose,
  signObjectId,
  projectId,
  formReportDate,
  formType,
  intl,
  formTemplateId,
  track,
}) => {
  const { viewer, configurations, projectMembers, lang } = useContext(ProjectContext);
  const [signaturesToUpload, setSignaturesToUpload] = useState({});
  const [isSignInProgress, setIsSignInProgress] = useState(false);
  const [currentSelectedMember, setCurrentSelectedMember] = useState(null);
  const lokiObjectAnalytics = lokiInstance.getCollection('forms');
  const currentForm = lokiObjectAnalytics.cementoFind({ id: signObjectId })[0];
  const formConfigurationsSignatures = useMemo(
    () => configurations?.forms?.[formTemplateId]?.signatures || {},
    [configurations?.forms?.[formTemplateId]?.signatures]
  );
  const [currSelectedLang, setCurrSelectedLang] = useState(lang);
  const signatureIds = useMemo(
    () => Object.keys(formConfigurationsSignatures),
    [formConfigurationsSignatures, currentForm]
  );
  const dispatch = getDispatch();
  const newFormSignatures = useMemo(() => {
    return {
      ...currentForm?.signatures,
      ...signaturesToUpload,
    };
  }, [signaturesToUpload, currentForm?.signatures]);

  const unsignedSignatureIds = useMemo(() => {
    return signatureIds
      .filter((signatureId) => !newFormSignatures[signatureId]?.uri)
      .sort((a, b) => {
        return formConfigurationsSignatures[a].ordinalNo - formConfigurationsSignatures[b].ordinalNo;
      });
  }, [signatureIds, newFormSignatures]);

  const currentSignatureId = useMemo(() => {
    return unsignedSignatureIds[0];
  }, []);

  const currSignatureObject = useMemo(() => {
    return formConfigurationsSignatures?.[currentSignatureId] || {};
  }, [currentSignatureId]);

  const nextSignatureObject = useMemo(() => {
    return formConfigurationsSignatures?.[unsignedSignatureIds[1]];
  }, [currentSignatureId]);

  const titleCaption = useMemo(() => {
    return currSignatureObject.title?.[lang] || '';
  }, [currSignatureObject, lang]);

  const aboveComponents = useMemo(() => {
    return Object.values(currSignatureObject.components || {}).filter((component) => component.placement === 'above');
  }, [currSignatureObject]);

  const embeddedComponents = useMemo(() => {
    return Object.values(currSignatureObject.components || {}).filter((component) => component.placement === 'embeded');
  }, [currSignatureObject]);

  const handleTextInput = useCallback((value, component) => {
    const currSignatures = { ...signaturesToUpload };
    currSignatures[currentSignatureId] = currSignatures[currentSignatureId] || {};
    currSignatures[currentSignatureId] = {
      ...currSignatures[currentSignatureId],
      components: {
        ...currSignatures[currentSignatureId].components,
        [component.id]: { value },
      },
    };
    setSignaturesToUpload(currSignatures);
  });

  const shouldGetNextSignatureMember = useMemo(() => {
    return Boolean(
      Object.keys(projectMembers).length && nextSignatureObject && currSignatureObject.nextSignatureMember?.enabled
    );
  }, [projectMembers, unsignedSignatureIds, currSignatureObject]);

  const filteredProjectMembers = useMemo(() => {
    if (!shouldGetNextSignatureMember) {
      return projectMembers;
    }
    return Object.values(projectMembers).reduce((acc, member) => {
      if (permissionsFunc.isPermitted(member, projectId, 'signatures', 'write', nextSignatureObject)) {
        acc[member.id] = member;
      }
      return acc;
    }, {});
  }, [projectMembers, currSignatureObject]);

  useEffect(() => {
    if (shouldGetNextSignatureMember) {
      setCurrentSelectedMember(null);
      return;
    }
    const member =
      Object.values(filteredProjectMembers).find((member) => member.id === viewer.id) ||
      Object.values(filteredProjectMembers)[0];
    setCurrentSelectedMember(member);
  }, [filteredProjectMembers, shouldGetNextSignatureMember]);

  const translatedLanguageNames = useMemo(() => {
    return Object.keys({
      ...currSignatureObject.multiLangText?.content,
    }).reduce((acc, key) => {
      acc.push({ value: key, label: intl.formatMessage(systemMessages[key]) });
      return acc;
    }, []);
  }, [currSignatureObject]);

  useEffect(() => {
    if (isSignInProgress) {
      dispatch(startLoading({ title: systemMessages.loadingMessage, overlay: true }));
    } else {
      dispatch(hideLoading());
    }
  }, [isSignInProgress]);

  const signatureRef = useRef(null);

  const clearSignature = useCallback(() => {
    signatureRef.current?.clear?.();
  });

  const uploadAllSignatures = useCallback(
    async (signaturesToUpload) => {
      const uploadedSignatures = await Promise.all(
        Object.entries(signaturesToUpload).map(async ([signatureId, signature]) => {
          const uri = await uploadImage(
            signature.uri,
            'signature_' + Date.now() + signatureId + '.jpg',
            'signatures/' + projectId + '/'
          );
          return [
            signatureId,
            { uri, user: signature.user, components: signature.components, usersToNotify: signature.usersToNotify },
          ];
        })
      );
      return Object.fromEntries(uploadedSignatures);
    },
    [signaturesToUpload]
  );

  const onSign = useCallback(async () => {
    if (currSignatureObject.isMandatory && signatureRef.current.isEmpty()) {
      await dispatch(startToast({ title: intl.formatMessage(systemMessages.mandatorySignature), type: 'error' }));
      return;
    }
    const signature = signatureRef.current.toDataURL('image/jpeg');
    const newSignaturesToUpload = {
      ...signaturesToUpload,
      [currentSignatureId]: {
        ...signaturesToUpload[currentSignatureId],
        uri: signature,
        user: { displayName: viewer.displayName, id: viewer.id },
      },
    };

    if (shouldGetNextSignatureMember) {
      newSignaturesToUpload[currentSignatureId].usersToNotify = currentSelectedMember.map((member) => member.value.id);
    }

    if (currSignatureObject.alert) {
      const shouldSign = await new Promise((resolve) => {
        dispatch(
          startToast({
            overlay: true,
            mandatory: true,
            title: currSignatureObject.alert?.title?.[lang] || '',
            message: currSignatureObject.alert?.content?.[lang] || '',
            actions: [
              {
                message: currSignatureObject.alert?.confirm?.[lang] || systemMessages.yes,
                onClick: () => resolve(true),
                color: 'success',
              },
              { message: systemMessages.no, onClick: () => resolve(false) },
            ],
          })
        );
      });

      if (!shouldSign) return;
    }

    setSignaturesToUpload(newSignaturesToUpload);
    setIsSignInProgress(true);

    try {
      let uploadedSignatures = await uploadAllSignatures(newSignaturesToUpload);

      const roundedDate = getRoundedDate();

      const currDate = moment(roundedDate.timestamp).utc();
      let localDate = new Date(currDate.format('YYYY-MM-DD'));
      localDate.setHours(0, new Date().getTimezoneOffset() * -1, 0, 0);
      const newFormStatus = unsignedSignatureIds.length > 1 ? REPORT_STATE_AWAITING_SIGNATURE : REPORT_STATE_CLOSED;
      track('Signed form in web', {
        formId: signObjectId,
        formTemplateId,
        formType,
        didFinishSign: unsignedSignatureIds.length === 1,
      });

      let updatedForm = {
        id: signObjectId,
        status: newFormStatus,
        formTemplateId: currentForm.formTemplateId,
        signatures: {
          ...uploadedSignatures,
          ...currentForm.signatures,
        },
        reportDate: formReportDate || localDate.getTime(),
        type: formType,
      };

      await dispatch(upsertForm(projectId, viewer, updatedForm, formType));
      handleClose();

      dispatch(startToast({ title: intl.formatMessage(systemMessages.signedSuccessfully), type: 'success' }));
    } finally {
      setIsSignInProgress(false);
    }
  }, [currSignatureObject, signatureRef, signaturesToUpload, currentSelectedMember]);

  return (
    <Modal
      hideCloseButton={true}
      onClose={() => {
        clearSignature();
        handleClose();
      }}
      open={isOpen}
      style={{ zIndex: theme.zIndexes.propertyAnalyticsDatePicker }}>
      <div style={{ padding: theme.biggerPadding, maxHeight: '90vh', maxWidth: '632px' }}>
        <div
          style={{
            fontSize: theme.fontSizeH5,
            marginBottom: theme.margin,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          {intl.formatMessage(systemMessages.approveAndSign)}
          {Boolean(translatedLanguageNames?.length > 1) && (
            <Select
              onChange={(selected) => setCurrSelectedLang(selected.value)}
              value={translatedLanguageNames.find((lang) => lang.value === currSelectedLang)}
              options={translatedLanguageNames}></Select>
          )}
        </div>
        <div
          style={{ border: theme.borderLineHeaderInfo, borderRadius: theme.borderRadius, marginBottom: theme.margin }}>
          <div
            style={{
              fontSize: theme.fontSizeH5,
              marginBottom: theme.margin,
              fontWeight: 600,
              padding: theme.padding,
              borderBottom: theme.borderLineHeaderInfo,
            }}>
            {titleCaption}
            {Boolean(currSignatureObject.isMandatory) && <span style={{ color: 'red' }}>*</span>}
            {Boolean(currSignatureObject?.multiLangText?.content?.[currSelectedLang]) && (
              <div style={{ fontWeight: 300 }}>{currSignatureObject?.multiLangText?.content?.[currSelectedLang]} </div>
            )}
          </div>
          <div style={{ position: 'relative', borderBottom: theme.borderLineHeaderInfo, padding: theme.padding }}>
            {Boolean(aboveComponents.length) &&
              aboveComponents.map((component) => {
                return (
                  <StandardInput
                    key={component.id}
                    multiline={component.isMultiline}
                    value={signaturesToUpload[currentSignatureId]?.components?.[component.id]?.value || ''}
                    onChange={(val) => handleTextInput(val, component)}
                    title={component.title?.[lang]}
                    type='String'
                    placeholder={component.placeholder?.[lang]}></StandardInput>
                );
              })}
            <div style={{ position: 'relative' }}>
              <CloseButton
                size={20}
                onClick={clearSignature}
                buttonStyle={{ position: 'absolute', right: 0, top: 0 }}></CloseButton>
              <SignatureCanvas
                backgroundColor={'#fff'}
                ref={signatureRef}
                penColor='black'
                canvasProps={{ width: 550, height: 200, style: { borderBottom: theme.borderLineHeaderInfo } }}
              />
            </div>
            {Boolean(embeddedComponents.length) &&
              embeddedComponents.map((component) => {
                return (
                  <StandardInput
                    key={component.id}
                    multiline={component.isMultiline}
                    value={signaturesToUpload[currentSignatureId]?.components?.[component.id]?.value || ''}
                    onChange={(val) => handleTextInput(val, component)}
                    title={component.title?.[lang]}
                    type='String'
                    placeholder={component.placeholder?.[lang]}></StandardInput>
                );
              })}

            <div>
              <User userId={viewer.id}></User>
              {shouldGetNextSignatureMember && (
                <SelectableUsers
                  placeholder={currSignatureObject?.nextSignatureMember?.caption?.[lang]}
                  isMulti={shouldGetNextSignatureMember}
                  showLine={true}
                  defaultValue={currentSelectedMember}
                  onChange={(assignTo) => {
                    setCurrentSelectedMember(assignTo);
                  }}
                  users={filteredProjectMembers}></SelectableUsers>
              )}
            </div>
          </div>
        </div>

        <Button
          isDisabled={isSignInProgress}
          onClick={onSign}
          shouldInvertColors={true}
          style={{ marginInline: 'auto' }}>
          {intl.formatMessage(systemMessages.sign)}
        </Button>
      </div>
    </Modal>
  );
};
const enhance = compose(connect(null, { track }), injectIntl);

export default enhance(SignatureModal);
