import _ from 'lodash';
import { useContext, useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { deepEqual } from '../../../app/funcs';
import { lokiInstance } from '../../../configureMiddleware';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import { ProjectContext } from '../../../projects/contexts';
import { getDeletedMembersWithData, generateLokiQuery, setCompanyIdAndGroupsToOwnerAndAssignToOnPosts, addInstancesValuesToPosts, addLocationIdToPost, addChecklistsAndStagesToPosts } from './utils';
import { v4 as uuidv4 } from 'uuid';
import useRefed from '../../../hooks/useRefed';
import useChecklistItemInstances from '../useChecklistItemInstances';

/**
 * @typedef {'records' | 'tasks'} PostsType
 *
 * @typedef {'safety'} ContentType
 * @param {import('.').Filters & { filterValue?: string }} postsFilters
 * @returns
 */

const usePosts = postsFilters => {
	// props
	const { allCompanies, allMembers, trades, stages, checklists, postsDidLoadMap } = useSelector(state => ({
		allCompanies: state.companies.map,
		allMembers: state.members.map,
		trades: state.trades.map,
		stages: state.stages.map,
		checklists: state.checklists.map,
		postsDidLoadMap: state.posts.didLoad,
	}));
	const { configurations, selectedProjectId, projectMembers, detailedProjects, projectLokiLoaded } = useContext(ProjectContext);
  const { checklistInstances: checklistItemInstancesMap } = useChecklistItemInstances({ projectId: selectedProjectId });

	const postsDoneLoading = projectLokiLoaded && postsDidLoadMap?.get(selectedProjectId);
	const deletedMembers = useMemo(
		() => getDeletedMembersWithData({ selectedProjectId, detailedProjects, allMembers }),
		[selectedProjectId, detailedProjects, allMembers],
	);

	const currentStages = useMemo(() => stages.getIn([selectedProjectId]), [stages, selectedProjectId]);
	const currentChecklists = useMemo(() => checklists?.getIn([selectedProjectId]), [checklists, selectedProjectId]);

	postsFilters = _.isNil(postsFilters) ? {} : postsFilters;

	// state
	const [currViewPostsMap, setCurrViewPostsMap] = useState({});
	const [currViewPosts, setCurrViewPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);

	// refs
	const lokiPostsRef = useRef(null);
	const lokiPostsInstancesRef = useRef(null);

	const _postsFilters = useMemoizedValue(postsFilters);

	const getPostsQuery = useCallback(() => {
		const { contentType, postsType, filterValue, inIds, ignoreContentType } = _postsFilters;
		
		let postsQuery = { projectId: selectedProjectId, $and: [] };
		if (filterValue) {
			const regExp = {
				$regex: new RegExp(filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'),
			};
			const companysQuery = generateLokiQuery(
				filterValue,
				[
					{ source: allMembers, key: ['companyId'] },
					{ source: allCompanies, key: ['name'] },
				],
				'assignTo.id',
			);
			const tradesQuery = generateLokiQuery(filterValue, [{ source: trades, key: ['getTitle'] }], 'trade.id');
			const membersQuery = generateLokiQuery(
				filterValue,
				[{ source: allMembers, key: ['displayName'] }],
				'assignTo.id',
			);

			postsQuery['$and'].push({
				['$or']: [
					{ title: regExp },
					companysQuery,
					tradesQuery,
					membersQuery,
				],
			});
		}

		if (!ignoreContentType) {
			if (contentType === 'safety') postsQuery['$and'].push({ 'trade.id': '1038' });
			else if (configurations.getNested(['features', 'safety', 'isActive']))
				postsQuery['$and'].push({ 'trade.id': { $ne: '1038' } });
		}

		switch (postsType) {
			case 'records': {
				postsQuery['$and'].push({
					$or: [{ issueState: 0 }, { issueState: { $exists: false } }, { issueState: null }],
				});
				postsQuery['$and'].push({
					$or: [{ isIssue: false }, { isIssue: { $exists: false } }, { isIssue: null }],
				});
				break;
			}

			case 'issues':
			case 'tasks': {
				postsQuery['$and'].push({
					$or: [{ isIssue: true }],
				});
				postsQuery['$and'].push({ issueState: { $and: [{ $exists: true }, { $ne: null }] } });
				break;
			}

			default:
				break;
		}

		if (inIds?.length) {
			postsQuery['$and'].push({
				$or: inIds.map(id => ({ id })),
			});
		}

		const postsInstancesQuery = {
			projectId: selectedProjectId,
			subjectName: 'postsInfo',
		};

		return { postsQuery, postsInstancesQuery };
	}, [
		selectedProjectId,
		configurations,
		_postsFilters.contentType,
		_postsFilters.postsType,
		_postsFilters.filterValue,
		_postsFilters.inIds,
		allCompanies,
		allMembers,
		trades,
	]);

	const [queries, setQueries] = useState(() => getPostsQuery());

	useEffect(() => {
		const newQueries = getPostsQuery();
		if (!deepEqual(newQueries, queries)) {
			setQueries(newQueries);
		}
	}, [getPostsQuery]);

	const getPostsMap = useCallback(() => {
		let nextViewPosts = [];
		
		if (selectedProjectId && lokiPostsRef.current) {
			const { postsQuery: query, postsInstancesQuery } = queries;
			const allProjectMembers = {
				...deletedMembers,
				...projectMembers,
			};

			const relevantPostsInstances = lokiPostsInstancesRef.current.cementoFind(postsInstancesQuery);
			
			nextViewPosts = lokiPostsRef.current.cementoFind(query);
			nextViewPosts = addInstancesValuesToPosts(nextViewPosts, relevantPostsInstances);
			nextViewPosts = addLocationIdToPost(nextViewPosts);
			nextViewPosts = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(nextViewPosts, allProjectMembers); // TODO: do this directly from loki
			nextViewPosts = addChecklistsAndStagesToPosts(
				nextViewPosts,
				currentStages,
				currentChecklists,
				checklistItemInstancesMap
			);
			nextViewPosts.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));
		}

		return {
			arr: nextViewPosts,
			map: _.keyBy(nextViewPosts, 'id'),
		};
  }, [queries, selectedProjectId, projectMembers, checklistItemInstancesMap, currentStages, currentChecklists, lokiPostsInstancesRef.current, lokiPostsRef.current]);
  const getPostsMapRef = useRefed(getPostsMap, [getPostsMap]);

	const postsListener = useCallback(
		collectionName => {
			if (collectionName === 'posts' || collectionName === 'propertyInstances') {
				const { arr, map } = getPostsMapRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
				setCurrViewPosts(arr);
				setCurrViewPostsMap(map);
			}
		},
		[],
	);

	useEffect(() => {
		postsListener('posts');
	}, [checklistItemInstancesMap]);

	// didMount
	useEffect(() => {
		let cleanUp;
    if (!postsDoneLoading) {
      return;
    }

    const lokiPosts = lokiInstance.getCollection('posts');
    lokiPostsRef.current = lokiPosts;

    const lokiPostsPropertiesInstances = lokiInstance.getCollection('propertyInstances');
    lokiPostsInstancesRef.current = lokiPostsPropertiesInstances;

    const { arr, map } = getPostsMapRef.current();
    setCurrViewPosts(arr);
    setCurrViewPostsMap(map);

    const postsListenerId = uuidv4();
    const instancesListenerId = uuidv4();
    lokiPosts.cementoOn(postsListenerId, postsListener);
    lokiPostsPropertiesInstances.cementoOn(instancesListenerId, postsListener);

    cleanUp = () => {
      lokiPosts.cementoOff(postsListenerId);
      lokiPostsPropertiesInstances.cementoOff(instancesListenerId);
    };

		// willUnmount
		return cleanUp;
	}, [queries, postsDoneLoading]);

	useEffect(() => {
		setFilteredPosts(currViewPosts);
	}, [currViewPosts]);

	const memoizedValue = useMemo(
		() => ({
			filteredPosts, // Is only used on web, it is the filtered posts from the url query (is the same as currViewPosts on native)
			currViewPosts,
			currViewPostsMap,
		}),
		[currViewPosts, currViewPostsMap, filteredPosts],
	);

	return memoizedValue;
}

export default usePosts;
