// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useMemo, useState } from 'react';
// core components
import { ProjectContext } from '../../../common/projects/contexts';
import { useCementoRouter } from '../Router/util/withRouterHOC';

const useSavedMenus = ({ contentType: _contentType, section: _section }) => {
  const [selectedFav, setSelectedFav] = useState();

  const { menus } = useContext(ProjectContext);
  const { navigationParams: routeParams, location: routeLocation } = useCementoRouter();

  const selectedReportId = routeParams?.reportId;
  const contentType = useMemo(() => routeParams?.contentType || _contentType, [routeParams, _contentType]);

  const urlQueryParams = useMemo(() => {
    if (routeLocation?.search) {
      return routeLocation.search.replace('?', '');
    }
  }, [routeLocation]);

  const section = useMemo(() => {
    const sectionParam = _section || routeParams.section;
    const sectionMapping = {
      postsAnalytics: 'postsAnalytics',
      locationContainerPage: 'buildingViewSection',
      dashboard: 'dashboardSection',
      analytics: 'analytics',
    };

    return sectionMapping[sectionParam] || 'buildingViewSection';
  }, [routeParams]);

  const nextOptions = useMemo(() => menus?.[contentType]?.[section]?.options || [], [menus, contentType, section]);

  useEffect(() => {
    let newSelectedFav = selectedFav;
    if (!newSelectedFav || nextOptions.length) {
      newSelectedFav = nextOptions.find((x) => routeLocation.pathname.includes(x.id));
      if (urlQueryParams) {
        newSelectedFav =
          nextOptions.find((x) => x.id == selectedReportId || x.query == urlQueryParams) || newSelectedFav;
      }

      if (!newSelectedFav) newSelectedFav = nextOptions[0];
    }

    if (newSelectedFav !== selectedFav) {
      setSelectedFav(newSelectedFav);
    }
  }, [routeLocation.pathname, nextOptions, contentType, section, urlQueryParams, selectedReportId]);

  return { selectedFav };
};

// eslint-disable-next-line react/display-name
export const withSavedMenus = (Component) => (props) => {
  const { selectedFav } = useSavedMenus({
    match: props.match,
    location: props.location,
    contentType: props.contentType,
    section: props.section,
  });
  return <Component {...props} selectedFav={selectedFav} />;
};

export default useSavedMenus;
