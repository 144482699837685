import { Navigate } from 'react-router-dom';

export default [
  {
    index: true,
    element: <Navigate to={'issues/dashboard'} />,
  },
  {
    path: '_',
    element: <Navigate replace to={'../issues/dashboard'} />,
  },
];
