import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "Units information",
    id: 'units.wizard.title'
  },
  subTitle_1: {
    defaultMessage: "1. General",
    id: 'units.wizard.subtitle.1'
  },
  subTitle_2: {
    defaultMessage: "2. Ground floor",
    id: 'units.wizard.subtitle.2'
  },
  subTitle_3: {
    defaultMessage: "3. Units per floor",
    id: 'units.wizard.subtitle.3'
  },
  updateDifferentSumUnitsAlertTitle: {
    defaultMessage: "Update units",
    id: 'units.wizard.save.title'
  },
  updateDifferentSumUnitsAlertMessage: {
    defaultMessage: "Please note the units original number of units was {original} and now is {current}. Are you sure you want to continue?",
    id: 'units.wizard.save.message'
  },
  totalUnitsTitle: {
    defaultMessage: "Total number of units",
    id: 'units.wizard.totalUnits.title'
  },
  totalUnitsSubTitle: {
    defaultMessage: "How many units (apartments/suites) in the building/complex?",
    id: 'units.wizard.totalUnits.subTitle'
  },
  groundFloorTitle: {
    defaultMessage: "Units in ground floor",
    id: 'units.wizard.groundFloor.title'
  },
  groundFloorSubTitle: {
    defaultMessage: "How many units enterence floor?",
    id: 'units.wizard.groundFloor.subTitle'
  },
  unitId: {
    defaultMessage: "Unit ID",
    id: 'units.setting.untiId'
  },
  unitsFloor: {
    defaultMessage: "Unit's floor",
    id: 'units.setting.unitsFloor'
  },
  unitsMaxFloor: {
    defaultMessage: "Unit max floor",
    id: 'units.setting.unitsMaxFloor'
  },
  unitsMinFloor: {
    defaultMessage: "Unit min floor",
    id: 'units.setting.unitsMinFloor'
  },
  tenants: {
    defaultMessage: "Tenants",
    id: 'units.setting.tenants'
  },
  floor: {
    defaultMessage: "Floor",
    id: 'units.building.floor'
  },
  totalUnitsOnComplexTitle: {
    defaultMessage: "Total number of units",
    id: 'units.wizard.totalUnitsOnComplex.title'
  },
  totalUnitsOnComplexSubTitle: {
    defaultMessage: "How many units in the complex?",
    id: 'units.wizard.totalUnitsOnComplex.subTitle'
  },
  roof: {
    defaultMessage: "Roof",
    id: 'units.floors.roof'
  },
  allUnits: {
    defaultMessage: "All units",
    id: "projects.allUnits"
  },
  unit: {
    defaultMessage: "Unit",
    id: "postsMenu.sort.unit"
  },
});
