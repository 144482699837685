import { defineMessages } from 'react-intl';

export default defineMessages({
  filters: {
    defaultMessage: 'Filters',
    id: 'postsMenu.menu.filters'
  },
  filterStatusLine: {
    'owner/id': {
      defaultMessage: 'created by {value}',
      id: 'postsMenu.filterStatusLine.owner'
    },
    'assignTo/id': {
      defaultMessage: 'assigned to {value}',
      id: 'postsMenu.filterStatusLine.assignto'
    },
    'assignTo/id members_companyId': {
      defaultMessage: 'assign to company {value}',
      id: 'postsMenu.filterStatusLine.company'
    },
    'trade/id' : {
      defaultMessage: 'of trade {value}',
      id: 'postsMenu.filterStatusLine.trade'
    },
    'issueState' : {
      defaultMessage: 'with status {value}',
      id: 'postsMenu.filterStatusLine.status'
    },
    'and' : {
      defaultMessage: 'and',
      id: 'postsMenu.filterStatusLine.and'
    }
  },
  dataTypeTasks: {
    defaultMessage: 'tasks',
    id: 'postsMenu.menu.dataTypeTasks'
  },
  dataTypeRecords: {
    defaultMessage: 'records',
    id: 'postsMenu.menu.dataTypeRecords'
  },
  statusDataCount: {
    defaultMessage: '{count} {type}',
    id: 'postsMenu.menu.statusDataCount'
  },
  sortBy: {
    defaultMessage: 'Sort by',
    id: 'postsMenu.menu.sortTitle'
  },
  sortedBy: {
    defaultMessage: 'Sorted by {title}',
    id: 'postsMenu.menu.sortedTitle'
  },  
  filter: {
    defaultMessage: 'Filter by',
    id: 'postsMenu.menu.filterTitle'
  },
  done: {
    defaultMessage: 'Done',
    id: 'postsMenu.menu.done'
  },
  viewType: {
    defaultMessage: 'View',
    id: 'postsMenu.menu.viewType'
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'postsMenu.sort.clear'
  },
  notSet: {
    defaultMessage: "'{type}' not set",
    id: 'postsMenu.sort.notSet'
  },
  issueState: {
    defaultMessage: 'Status',
    id: 'postsMenu.sort.issueState'
  },
  owner: {
    defaultMessage: 'Created By',
    id: 'postsMenu.sort.owner'
  },
  docOwner: {
    defaultMessage: 'Created By',
    id: 'postsMenu.sort.docOwner'
  },
  assignTo: {
    defaultMessage: 'Assigned to',
    id: 'postsMenu.sort.assignTo'
  },
  subCategory: {
    defaultMessage: "Category", 
    id: "posts.newPost.subCategory",
  },
  dueDate: {
    defaultMessage: 'Due date',
    id: 'postsMenu.sort.dueDate'
  },
  lastUpdated: {
    defaultMessage: 'Last updated',
    id: 'postsMenu.sort.lastUpdated'
  },
  createdAt: {
    defaultMessage: 'Creation Date',
    id: 'postsMenu.sort.createdAt'
  },
  myTodo: {
    defaultMessage: 'My to-do',
    id: 'postsMenu.filter.myTodo'
  },
  postType: {
    defaultMessage: 'Post type',
    id: 'postsMenu.filter.postType'
  },
  openedIssues: {
    defaultMessage: 'Opened tasks',
    id: 'postsMenu.filter.postTypeOpen'
  },
  closedIssues: {
    defaultMessage: 'Closed tasks',
    id: 'postsMenu.filter.postTypeClosed'
  },
  resolvedIssues: {
    defaultMessage: 'Resolved tasks',
    id: 'postsMenu.filter.postTypeResolved'
  },
  default: {
    defaultMessage: 'Any',
    id: 'postsMenu.sort.default'
  },
  yesterday: {
    defaultMessage: 'Yesterday',
    id: 'postsMenu.sort.yesterday'
  },
  lastWeek: {
    defaultMessage: '7 Last days',
    id: 'postsMenu.sort.lastWeek'
  },
  weekBeforeLast: {
    defaultMessage: '7-14 Days ago',
    id: 'postsMenu.sort.weekBeforeLast'
  },
  last30Days: {
    defaultMessage: '14-30 Days ago',
    id: 'postsMenu.sort.last30Days'
  },
  moreThen30DaysAgo: {
    defaultMessage: 'More than 30 days ago',
    id: 'postsMenu.sort.moreThen30DaysAgo'
  },  

  yesterdayOrLater: {
    defaultMessage: 'Yesterday or earlier',
    id: 'postsMenu.sort.yesterdayOrLater'
  },
  today: {
    defaultMessage: 'Today',
    id: 'postsMenu.sort.today'
  },
  thisWeek: {
    defaultMessage: 'Next 7 days',
    id: 'postsMenu.sort.thisWeek'
  },
  nextWeek: {
    defaultMessage: '7-14 Days from now',
    id: 'postsMenu.sort.nextWeek'
  },
  next30Days: {
    defaultMessage: '14-30 Days from now',
    id: 'postsMenu.sort.next30Days'
  },
  moreThen30Days: {
    defaultMessage: 'More than 30 days from now',
    id: 'postsMenu.sort.moreThen30Days'
  },  
  noDate: {
    defaultMessage: 'No due date',
    id: 'postsMenu.sort.noDate'
  },
  unreadMessage: {
    defaultMessage: 'Unread updates',
    id: 'postsMenu.sort.unreadMessage'
  },
  readMessage: {
    defaultMessage: 'Read updates',
    id: 'postsMenu.sort.readMessage'
  },
  floor: {
    defaultMessage: 'Floor',
    id: 'postsMenu.sort.floor'
  },
  unit: {
    defaultMessage: 'Unit',
    id: 'postsMenu.sort.unit'
  },
  all: {
    defaultMessage: 'All',
    id: 'postsMenu.filters.all'
  },
  any: {
    defaultMessage: 'Any',
    id: 'postsMenu.filters.any'
  },
  view: {
    defaultMessage: 'View by',
    id: 'postsMenu.filters.view'
  },
  tasks: {
    defaultMessage: 'Tasks',
    id: 'postsMenu.filters.tasks'
  },
  checklists: {
    defaultMessage: 'Checklists',
    id: 'postsMenu.filters.checklists'
  },
  checklist: {
    defaultMessage: 'Checklist',
    id: 'postsMenu.filters.checklist'
  },
  checklistItems: {
    defaultMessage: 'Checklist Items',
    id: 'postsMenu.filters.checklistItems'
  },
  members: {
    defaultMessage: 'Members',
    id: 'postsMenu.filters.members'
  },
  byMe: {
    defaultMessage: 'Created by me',
    id: 'postsMenu.filters.byMe'
  },
  onMe: {
    defaultMessage: 'My to do',
    id: 'postsMenu.filters.onMe'
  },
  exportTitle: {
    defaultMessage: 'Export as PDF',
    id: 'postsOptionsMenu.export.title'
  },
  exportSubTitle: {
    defaultMessage: 'Send task details using email or other apps',
    id: 'postsOptionsMenu.export.subTitle'
  },
  editTitle: {
    defaultMessage: 'Edit task',
    id: 'postsOptionsMenu.edit.title'
  },
  editDocTitle: {
    defaultMessage: 'Edit record',
    id: 'postsOptionsMenu.record.edit.title'
  },
  company: {
    defaultMessage: 'Company',
    id: 'postsOptionsMenu.filters.company'
  },  
  trade: {
    defaultMessage: 'Trade',
    id: 'postsOptionsMenu.filters.trade'
  },
  none: {
    defaultMessage: 'None',
    id: 'postsOptionsMenu.filters.unselectAll',
  },
  ALL: {
    defaultMessage: 'All',
    id: 'postsOptionsMenu.filters.selectAll',
  },
});
