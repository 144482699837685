export const CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME = 'checklistItemInstances';

export const CHECKLIST_ITEMS_INSTANCES_EVENTS = {
  UPSERT_CHECKLIST_ITEM_INSTANCE: 'UPSERT_CHECKLIST_ITEM_INSTANCE',
  CREATE_CHECKLIST_ITEM_INSTANCE: 'CREATE_CHECKLIST_ITEM_INSTANCE',
  FETCH_CHECKLIST_ITEM_INSTANCES: 'FETCH_CHECKLIST_ITEM_INSTANCES',
  END_CHECKLIST_ITEM_INSTANCES_LISTENER: 'END_CHECKLIST_ITEM_INSTANCES_LISTENER',
  UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS: 'UPDATE_CHECKLIST_ITEM_INSTANCE_STATUS',
  UPDATE_BULK_STATUS: 'UPDATE_BULK_STATUS',
  DELETE_CHECKLIST_INSTANCE: 'DELETE_CHECKLIST_INSTANCE',
  GET_NEW_CHECKLIST_ITEM_INSTANCE_ID: 'GET_NEW_CHECKLIST_ITEM_INSTANCE_ID',
  CLEAN_CACHED_ITEMS_INSTANCES: 'CLEAN_CACHED_ITEMS_INSTANCES',
  CHECKLIST_ITEM_INSTANCES_DONE_LOADING: 'CHECKLIST_ITEM_INSTANCES_DONE_LOADING',
  RETRY_UPLOAD_LOCAL_CHECKLISTS: 'RETRY_UPLOAD_LOCAL_CHECKLISTS',
  CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA: 'CLEAN_CHECKLIST_ITEMS_INSTANCES_CACHE_DATA',
};

