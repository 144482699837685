import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import Select from "react-select";
import theme from "../../assets/css/theme";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import Text from "./Text";
import TradeBadge from "./TradeBadge";

class TradesSelector extends React.Component {
  constructor(props) {
    super(props);
    this.fillTradesTitles = this.fillTradesTitles.bind(this);
  }

  fillTradesTitles(entireTradesIds) {
    const { trades, showAllTrades } = this.props;

    let entireTradesList = [];
    let wantedTrades = Object.keys(
      showAllTrades ? trades.toJS() : entireTradesIds || {}
    );
    wantedTrades.forEach((tradeId) => {
      let tradeTitle =
        trades.get(tradeId) && trades.get(tradeId).getCementoTitle();
      if (tradeTitle)
        entireTradesList.push({ value: tradeId, label: tradeTitle });
    });

    return entireTradesList;
  }

  render() {
    const { rtl, options, selectedTrades, onChange, intl, minWidth } =
      this.props;
    let entireTradesList = this.fillTradesTitles(options);

    const TradeOptionComponent = (props) => {
      const { innerProps, innerRef } = props;
      return (
        <div
          ref={innerRef}
          {...innerProps}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.verticalMargin,
            cursor: "pointer",
          }}
        >
          <Text>{props.label}</Text>
          <TradeBadge mode={"min"} ids={[props.value]} />
        </div>
      );
    };

    const TradeLableComponent = (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <TradeBadge ids={[props.data.value]} />
        </div>
      );
    };

    let currTradesSelectStyles = tradesSelectStyles;
    if (minWidth)
      currTradesSelectStyles = Object.assign({}, tradesSelectStyles, {
        container: (styles) => ({
          ...theme.selectStyles.container(styles),
          minWidth,
        }),
      });

    return (
      <Select
        rtl={rtl}
        isMulti={true}
        placeholder={intl.formatMessage(analyticsMessages.trade)}
        isSearchable={true}
        onChange={onChange}
        styles={currTradesSelectStyles}
        options={entireTradesList}
        components={{
          Option: TradeOptionComponent,
          MultiValueLabel: TradeLableComponent,
        }}
        value={selectedTrades}
      />
    );
  }
}

TradesSelector = injectIntl(TradesSelector);
TradesSelector = withStyles({})(TradesSelector);
const enhance = compose(
  connect((state) => ({
    trades: state.trades.map,
    rtl: state.app.rtl,
  }))
);
export default enhance(TradesSelector);

const tradesSelectStyles = {
  ...theme.selectStyles,
  container: (styles) => ({
    ...theme.selectStyles.container(styles),
    minWidth: 220,
  }),
};
