import theme from '../app/theme';


export const CLI_STATUS_CONFIRMED_2 = 99;
export const CLI_STATUS_CONFIRMED = 100;
// export const CLI_STATUS_IN_PROGRESS = 101;
// export const CLI_STATUS_IN_PROGRESS_PLUS_REJ = 102;
export const CLI_STATUS_PARTIAL = 125;
export const CLI_STATUS_RESOLVED = 150;
export const CLI_STATUS_REJECTED = 200;
export const CLI_STATUS_REJECTED_PLUS = 201;
export const CLI_STATUS_NONE = 300;
export const CLI_STATUS_IRRELEVANT = 301;

export const CHECKLIST_STATUS_COLORS = {
    [CLI_STATUS_CONFIRMED_2]: theme.bransSuccessDark,
    [CLI_STATUS_CONFIRMED]: theme.brandSuccess,
    [CLI_STATUS_RESOLVED]: theme.brandWarning,
    [CLI_STATUS_REJECTED]: theme.brandDanger,
    [CLI_STATUS_REJECTED_PLUS]: theme.brandDanger,
    [CLI_STATUS_NONE]: theme.easyDark(theme.brandNeutral),
    [CLI_STATUS_IRRELEVANT]: theme.brandInfo,
    [CLI_STATUS_PARTIAL]: theme.brandSuccess,
}

export function getChecklistColorByStatus(status) {
    return CHECKLIST_STATUS_COLORS[status] || theme.easyDark(theme.brandNeutral);
}