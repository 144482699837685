import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';

export const GET_FLOORS = 'GET_FLOORS';
export const FLOORS_LOADING = 'FLOORS_LOADING';

export function getFloors(projectId, viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: 'floors',
      firebasePath: `floors/${projectId}`,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_FLOORS, payload: { projectId, floors: data } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer)
    if (result) {
      onData(result);
    }

    return { projectId };
  }

  return {
    type: FLOORS_LOADING,
    payload: getPromise(),
  };
}
