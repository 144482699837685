import React, { useState } from 'react';
import { DrawingManager, Autocomplete } from '@react-google-maps/api';
import Gmap from '../Gmap';

const DrawingMap = ({
	center,
	drawingMode,
	mapProps = {},
	drawingManagerProps = {},
	autocompleteProps = {},
	children,
}) => {
	const [autocomplete, setAutocomplete] = useState(null);

	const onLoad = autocomplete => {
		setAutocomplete(autocomplete);
	};

	const onPlaceChanged = () => {
		const place = autocomplete.getPlace();
		const position = place?.geometry?.location;
		if (position && autocompleteProps.onPlaceChanged) {
			autocompleteProps.onPlaceChanged({ position });
		}
	};

	return (
		<Gmap center={center} libraries={['drawing', 'places']} style={{ width: '100%', height: 500 }} {...mapProps}>
			<DrawingManager drawingMode={drawingMode} {...drawingManagerProps} />
			{autocompleteProps.visible ? (
				<Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
					<input
						type='text'
						placeholder='Customized your placeholder'
						onChange={onPlaceChanged}
						style={{
							boxSizing: 'border-box',
							border: '1px solid transparent',
							width: '240px',
							height: '32px',
							padding: '0 12px',
							borderRadius: '3px',
							boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
							fontSize: '14px',
							outline: 'none',
							textOverflow: 'ellipsis',
							position: 'absolute',
							left: '50%',
							marginLeft: '-120px',
							backgroundColor: 'white',
						}}
					/>
				</Autocomplete>
			) : null}
			{children}
		</Gmap>
	);
};

export default DrawingMap;
