import * as appActions from '../app/actions';
import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { DEFAULT_LAST_UPDATE_TS } from '../app/constants';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
  aggregated: Map(),
  lastSelectedEmails: Map(),
  formEmailModalParams: Map(),
}, 'forms');

const initialState = new InitialState;

export default function formsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    case actions.GET_FORMS_BY_TYPE_START_LOADING: {
      const { formType, projectId } = action.payload || {};

      if (!(formType && projectId)) {
        return state;
      }

      return state = state.setIn(['didLoad', projectId, formType], false);
    }

    case actions.ADD_NEW_FORM + '_SUCCESS': {
      const { projectId, reportId, form } = action.payload || {};
      if (!(projectId && reportId && form))
        return state;

      state = state.setIn(['map', projectId, reportId], form);

      return state;
    }
    case actions.GET_FORMS_BY_TYPE: {
      if (!action.payload?.projectId)
        return state;

      const { forms, projectId } = action.payload || {};

      if (forms) {
        let formsMap = state.map.get(projectId) || new OrderedMap();
        Object.entries(forms).forEach(([formId, formDetails = {}]) => {
          formsMap = formsMap.set(formId, formDetails);
        });
        state = state.setIn(['map', projectId], formsMap);
      }
      
      return state;
    }

    case actions.DELETE_FORM + '_SUCCESS': {
      const { projectId, reportId } = action.payload || {};

      if (!(projectId && reportId))
        return state;

      state = state.deleteIn(['map', projectId, reportId]);
      return state;
    }

    case actions.GET_FORMS_BY_TYPE_DONE_LOADING: {
      const { formType, scopeId } = action.payload || {};

      if (!(formType && scopeId)) {
        return state;
      }

      state = state.setIn(['didLoad', scopeId, formType], true);
      return state;
    }

    case actions.END_FORMS_LISTENER_BY_TYPE : {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId, action.payload.formType || 'general'], false);
      return state;
    }
    
    case actions.SET_FORM_EMAIL + '_ERROR':
    case actions.SET_FORM_EMAIL + '_SUCCESS': {
      const { projectId, selectedEmail } = action.payload || {};
      if (projectId && selectedEmail) {
        state = state.setIn(['lastSelectedEmails', projectId], selectedEmail);
      }
      return state;
    }

    case actions.SET_FORM_EMAIL_MODAL_PARAMS: 
    case actions.UNSET_FORM_EMAIL_MODAL_PARAMS: {
      if (action.payload) {
        const { isVisible, projectId, formType, formId } = action.payload || {};
        state = state.setIn(['formEmailModalParams'], { isVisible, projectId, formType, formId });
      }
      return state;
    }
    

    case appActions.CLEAN_CACHE: {
      return state = initialState;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}
