import _ from 'lodash';
import { lokiInstance } from '../../../common/configureMiddleware';
import { getLocationTitle } from '../Locations/funcs';
import { filterByQuery } from '../../../common/permissions/funcs';
import { FILTER_URL_KEY } from '../../app/constants';
import { ALL_BUILDINGS_ID } from '../../../common/app/constants';

export function getObjectsTitlesMap(props, subjectName) {
	let objectsMap = {};
	if (subjectName === 'companiesInfo') {
		Object.values(props.projectCompanies || {}).forEach(v => (objectsMap[v.id] = { id: v.id, title: v.name }));
	} else if (subjectName != 'locationsInfo') {
		let objectsType = subjectName.indexOf('Info') != -1 ? subjectName.replace('Info', '') : subjectName;
		const collection = lokiInstance.getCollection(objectsType);
		const objects = collection ? collection.cementoFind({ 'projectId': props.selectedProjectId }) || [] : [];
		objects.forEach(object => {
			objectsMap[object.id] = { id: object.id, title: object.name };
			if (subjectName === 'postsInfo') _.set(objectsMap, [object.id, 'trade'], object.trade);
		});
	} else {
		let locationsTitlesMap = {};
		let isMultiBuilding = props.buildings.size > 1;
		let unitsIdsMapByNum = {};
		let index = 0;

		_.forIn(props.buildings, (building, bid) => {

			let buildingLocationTitle = getLocationTitle(
				props.buildings,
				props.floors,
				props.units,
				bid,
				null,
				null,
				null,
				props.intl,
				false,
			);
			locationsTitlesMap[bid] = { id: bid, title: buildingLocationTitle.buildingTitle, ordinalNo: index++ };
			props.getNested(['floors', bid], {}).loopEach((fid, curr) => {
				unitsIdsMapByNum[curr.num] = fid;
				let floorLocationTitle = getLocationTitle(
					props.buildings,
					props.floors,
					props.units,
					bid,
					fid,
					null,
					null,
					props.intl,
					false,
				);
				let title = isMultiBuilding ? floorLocationTitle.buildingTitle + ' \\ ' : '';
				title = title + floorLocationTitle.floorTitle;
				locationsTitlesMap[fid] = { id: fid, title: title, ordinalNo: index++ };
			});
			props.getNested(['units', bid], {}).loopEach((uid, curr) => {
				let unitLocationTitle = getLocationTitle(
					props.buildings,
					props.floors,
					props.units,
					bid,
					unitsIdsMapByNum.getNested([curr.getNested(['floor', 'num'])]),
					uid,
					null,
					props.intl,
					false,
				);
				let title = isMultiBuilding ? unitLocationTitle.buildingTitle + ' \\ ' : '';
				title = title + unitLocationTitle.floorTitle + ' \\ ' + unitLocationTitle.unitTitle;
				locationsTitlesMap[uid] = { id: uid, title: title, ordinalNo: index++ };
			});
		});
		objectsMap = locationsTitlesMap;
	}
	return objectsMap;
}

export function getGroupsInstances(selectedProjectId, objectsMap = {}, allGroupsArray, lokiPropertyInstances) {
	if (!selectedProjectId || !objectsMap || (allGroupsArray || []).length === 0) return {};

	let allGroupsIds = (allGroupsArray || []).map(g => g.id);
	let groupsInstancesMap = {};
	allGroupsIds.forEach(gid => (groupsInstancesMap[gid] = { ...objectsMap }));
	lokiPropertyInstances.forEach(curr => {
		allGroupsIds.forEach(gid => {
			groupsInstancesMap[gid][curr.parentId] = {
				...(objectsMap[curr.parentId] || {}),
				instance: curr,
				checked: Boolean(curr.data && curr.data[gid]),
			};
		});
	});
	return groupsInstancesMap;
}

export function getGroupsInstancesMapByParentId(groupsInstancesMap) {
	let instancesMapByParentId = {};
	// groupsInstancesMap contains [groupId](הקבוצה של דרור, הקבוצה של דרור 2) : locations
	groupsInstancesMap.loopEach((groupId, allInstances) => {
		allInstances.loopEach((parentId, info) => {
			if (info.instance) {
				instancesMapByParentId[parentId] = {
					...info.instance,
					data: instancesMapByParentId[parentId] ? instancesMapByParentId[parentId].data : null,
				};
				if (info.checked) {
					instancesMapByParentId[parentId] = instancesMapByParentId[parentId].setNested(['data', groupId], groupId);
				} else {
					if (instancesMapByParentId[parentId].data && instancesMapByParentId[parentId].data[groupId])
						delete instancesMapByParentId[parentId].data[groupId];
					if (instancesMapByParentId[parentId].data && Object.keys(instancesMapByParentId[parentId].data).length == 0)
						instancesMapByParentId[parentId].data = null;
				}
			} else if (info.checked) {
				instancesMapByParentId[parentId] = {
					data: { [groupId]: groupId },
					subjectName: 'locationsInfo',
					propType: 'SelectionList',
					propId: 'groups',
					parentId: parentId,
				};
			}
		});
	});
	return instancesMapByParentId;
}

export const getFilteredRowsMap = ({ searchQuery, originalDataRows, delimeter }) => {
	if (!originalDataRows) return originalDataRows;

	return filterByQuery(originalDataRows, searchQuery, FILTER_URL_KEY, delimeter);
};

export const filterPostsByLocation = ({
	posts = [],
	buildingId = '_',
	floorId = '_',
	unitId = '_',
	showAll = false,
	locationsAggregationView = false,
}) => {
	let _showAll = showAll || (buildingId == ALL_BUILDINGS_ID && unitId == '_' && floorId == '_');
	return posts.filter(post => {
		let isInLocation = _showAll;
		if (!isInLocation && unitId != '_') {
			isInLocation = post.getNested(['location', 'unit', 'id'], '_') === unitId;
		} else if (!isInLocation && floorId != '_') {
			isInLocation = locationsAggregationView
				? post.getNested(['location', 'floor', 'id'], '_') === floorId
				: post.getNested(['location', 'floor', 'id'], '_') === floorId && !post.getNested(['location', 'unit', 'id']);
		} else if (!isInLocation && buildingId != '_') {
			isInLocation = locationsAggregationView
				? post.getNested(['location', 'building', 'id'], '_') === buildingId
				: post.getNested(['location', 'building', 'id'], '_') === buildingId &&
				  !post.getNested(['location', 'floor', 'id']) &&
				  !post.getNested(['location', 'unit', 'id']);
		}
		return isInLocation;
	});
};
