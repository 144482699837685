import { defineMessages } from 'react-intl';

export default defineMessages({
  removeFromProjectTitle: {
    defaultMessage: 'Remove user from project',
    id: 'users.removeFromProject.title'
  }, 
  removeFromProjectMessage: {
    defaultMessage: 'Are you sure you want to remove the user from the project?',
    id: 'users.removeFromProject.message'
  }, 
  profileCreated: {
    defaultMessage: 'Profile created successfully',
    id: 'users.edit.profileCreated'
  }, 
  profileUpdated: {
    defaultMessage: 'Profile updated successfully',
    id: 'users.edit.profileUpdated'
  }, 
  profileDeleted: {
    defaultMessage: 'Profile deleted successfully',
    id: 'users.edit.profileDeleted'
  },
  editTitle: {
    defaultMessage: 'Edit Profile',
    id: 'users.edit.title'
  }, 
  displayNameSelector: {
    defaultMessage: 'Display Name:',
    id: 'users.edit.displayNameSelector'
  }, 
  selectContact: {
    defaultMessage: 'Select contact',
    id: 'users.contact.selectContact'
  }, 
  selectMember: {
    defaultMessage: 'Select member',
    id: 'users.contact.selectMember'
  }, 
  displayNamePlaceholder: {
    defaultMessage: 'First and last name',
    id: 'users.edit.displayNamePlaceholder'
  }, 
  idNumberSelector: {
    defaultMessage: 'ID number:',
    id: 'users.edit.idNumberSelector'
  }, 
  licenceNumberSelector: {
    defaultMessage: 'Licence number:',
    id: 'users.edit.licenceNumberSelector'
  }, 
  tradeSelector: {
    defaultMessage: 'Trade:',
    id: 'users.edit.tradeSelector'
  }, 
  titleSelector: {
    defaultMessage: 'Title: ',
    id: 'users.edit.titleSelector'
  }, 
  titlePlaceholder: {
    defaultMessage: 'Select title',
    id: 'users.edit.titlePlaceholder'
  }, 
  companySelector: {
    defaultMessage: 'Company: ',
    id: 'users.edit.companySelector'
  },
  categorySelector: {
    defaultMessage: 'Category: ',
    id: 'users.edit.categorySelector'
  }, 
  mobileSelector: {
    defaultMessage: 'Mobile:',
    id: 'users.edit.mobileSelector'
  }, 
  emailSelector: {
    defaultMessage: 'Email:',
    id: 'users.edit.emailSelector'
  }, 
  addressSelector: {
    defaultMessage: 'Address: ',
    id: 'users.edit.addressSelector'
  }, 
  citySelector: {
    defaultMessage: 'City: ',
    id: 'users.edit.citySelector'
  }, 
  stateSelector: {
    defaultMessage: 'State: ',
    id: 'users.edit.stateSelector'
  }, 
  zipSelector: {
    defaultMessage: 'Zip: ',
    id: 'users.edit.zipSelector'
  },
  workingArea: {
    defaultMessage: 'Working area:',
    id: 'users.edit.workingArea'
  }, 
  aboutMeSelector: {
    defaultMessage: 'About me:',
    id: 'users.edit.aboutMe'
  },   
  aboutMePlaceholder: {
    defaultMessage: 'Write something about yourself',
    id: 'users.edit.placeholder.aboutMe'
  },   
  details: {
    defaultMessage: 'Details',
    id: 'users.user.details'
  }, 
  createAProfile: {
    defaultMessage: 'Create a profile',
    id: 'users.user.createAProfile'
  }, 
  createUserPleaseWait: {
    defaultMessage: 'Adding new user, Please wait...',
    id: 'users.user.createUserPleaseWait'
  },
  newUserPropertiesValidation : {
    defaultMessage: 'Please make sure you fill the name, phone and company',
    id: 'users.user.newUserPropertiesValidation'
  },
  title: {
    defaultMessage: 'Title',
    id: 'users.user.title'
  }, 
  trade: {
    defaultMessage: 'Trade',
    id: 'users.user.trade'
  }, 
  addMember: {
    defaultMessage: 'Add Member',
    id: 'users.user.addMember'
  }, 
  addMemberNote: {
    defaultMessage: 'Note: the above details are visible to other members of this project.',
    id: 'users.user.addMemberNote'
  }, 
  sendSMS: {
    defaultMessage: 'Send text message',
    id: 'users.user.addMemberSendSMS'
  }, 
  SMS_START: {
    defaultMessage: "Hi {userName}, we are using Cemento.AI application to effectively manage the '{projectName}' project.",
    id: 'users.user.smsStart'
  },
  SMS_END: {
    defaultMessage: 'Please download the application and see what\'s going on - http://onelink.to/4x56bt',
    id: 'users.user.smsEnd'
  },
  smsInvitationSent: {
    defaultMessage: 'Invitation sent',
    id: 'users.alert.smsInvitationSent'
  },
  removeFromProject: {
    defaultMessage: 'Remove from project',
    id: 'users.page.removeFromProject'
  }, 
  welcomeMessage: {
    defaultMessage: 'Welcome to Cemento, lets start by creating your user profile:',
    id: 'users.page.welcomeMessage'
  },
  leaveDetails: {
    defaultMessage: 'Thank you for joining Cemento network. Please enter your invitation code or we will contact you.',
    id: 'users.page.leaveDetails'
  },
  setAsProfileBackground: {
    defaultMessage: 'Set as profile background image',
    id: 'users.menu.setAsProfileBackground'
  },
  addToUserPageGallery: {
    defaultMessage: 'Add to my profile gallery',
    id: 'users.menu.addToUserPageGallery'
  },
  removeFromUserPageGallery: {
    defaultMessage: 'Remove from my profile gallery',
    id: 'users.menu.removeFromUserPageGallery'
  },
  updateSuccess: {
    avatar: {
      defaultMessage: 'Avatar updated successfully',
      id: 'users.updateMessages.avatar.avatarUpdatedSuccess'
    },
    backgroundImage: {
      defaultMessage: 'Background image updated successfully',
      id: 'users.updateMessages.avatar.backgroundImageUpdatedSuccess'
    },
  },
  updateFailed: {
    avatar: {
      defaultMessage: 'Avatar update failed. Please try again',
      id: 'users.updateMessages.avatar.avatarUpdatedFailed'
    },
    backgroundImage: {
      defaultMessage: 'Background image update failed. Please try again',
      id: 'users.updateMessages.avatar.backgroundImageUpdatedFailed'
    },
  },
  titles: {
    projects: {
      defaultMessage: 'Projects',
      id: 'users.titles.projects'
    },
    location: {
      defaultMessage: 'Location',
      id: 'users.titles.location'
    },
    aboutMe: {
      defaultMessage: 'About Me',
      id: 'users.titles.aboutMe'
    },
    workingArea: {
      defaultMessage: 'Working Area',
      id: 'users.titles.workingArea'
    },
    recommendation: {
      defaultMessage: 'Recommendors list ({counter})',
      id: 'users.titles.recommendation'
    },    
    reviews: {
      defaultMessage: 'Reviews ({counter})',
      id: 'users.titles.reviews'
    },
    reviews_counter: {
      defaultMessage: '{counter} reviews',
      id: 'users.titles.reviews_counter'
    },
    muturalConnections: {
      defaultMessage: 'Mutural connections',
      id: 'users.titles.muturalConnections'
    },
  },
  buttons: {
    recommendMe_1: {
      defaultMessage: 'Recommend me',
      id: 'users.buttons.recommendMe_1'
    },
    recommendMe_2: {
      defaultMessage: 'Write Recommendtion',
      id: 'users.buttons.recommendMe_2'
    },
    recommendMe_3: {
      defaultMessage: 'Show recommendtions',
      id: 'users.buttons.recommendMe_3'
    },
    writeRecommendation: {
      defaultMessage: 'Write recommendation',
      id: 'users.buttons.writeRecommendation'
    },
    showRecommendation: {
      defaultMessage: 'Show Recommendations',
      id: 'users.buttons.showRecommendation'
    },
  },
  writeRecommendationTitle: {
    defaultMessage: 'Write recommendation on ',
    id: 'users.title.writeRecommendationTitle'
  },
  writeRecommendationDescription: {
    defaultMessage: 'Remember: When writing you review be respectful, provide a complete description, focus on the facts, write at least 20 words.',
    id: 'users.title.writeRecommendationDescription'
  },
  placeholder: {
    writeRecommendation: {
      defaultMessage: 'Write your recommendation here',
      id: 'users.placeholder.writeRecommendation'
    },
  },
  signOutAlertTitle: {
    defaultMessage: 'Sign out',
    id: 'users.alert.signOutAlertTitle'
  },  
  signOutAlertMessage: {
    defaultMessage: 'Are you sure you want to sign out from Cemento.ai?',
    id: 'users.alert.signOutAlertMessage'
  },
  memberToNotify: {
    defaultMessage: 'Authorized by',
    id: 'users.memberToNotify'
  },  
});

