import React from "react";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { getCompaniesById } from "../../../common/companies/actions";
import Avatar from "./Avatar";

let companiesDirectRequests = {};

class CompanyAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      logo: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { getCompaniesById } = nextProps;

    let newStateChanges = {};
    if (!nextProps.companyId) return null;

    if (
      props.companyId != nextProps.companyId ||
      props.getNested(["companies", nextProps.companyId]) !=
        nextProps.getNested(["companies", nextProps.companyId])
    ) {
      if (
        !companiesDirectRequests[nextProps.companyId] &&
        !nextProps.getNested(["companies", nextProps.companyId])
      ) {
        companiesDirectRequests[nextProps.companyId] = true;
        getCompaniesById({ [nextProps.companyId]: nextProps.companyId });
      } else
        newStateChanges.logo = nextProps.getNested([
          "companies",
          nextProps.companyId,
          "logo",
        ]);
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const { logo } = this.state;
    const { avatarSize, shadow } = this.props;
    let minSize = 25;
    return (
      <div style={{ width: avatarSize, minWidth: minSize, maxWidth: 50 }}>
        <Avatar src={logo} type={"company"} shadow={shadow} />
      </div>
    );
  }
}

const enhance = compose(
  connect(
    (state) => ({
      companies: state.companies.map,
    }),
    { getCompaniesById }
  )
);
export default enhance(CompanyAvatar);
