import { Navigate } from 'react-router-dom';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';

export default {
  path: 'siteControl',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <PropertyAnalytics
          key={'project_PropertyAnalytics_siteControl_analytics'}
          contentType={'siteControl'}
          section={'analytics'}
        />
      ),
    },
  ],
};
