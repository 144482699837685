import { Record } from '../transit';

const InitialState = Record({
  appName: '',
  appVersion: '',
  firebase: '',
  sentryUrl: '',
  mixpanel: '',
  nativeSentryDsn: '',
  webSentryDsn:'',
  notificationServer: '',
  apiServer: '',
  isProd: '',
  auth0: '',
  flavour: '',
  googleServices: {},
}, 'config');

export default function configReducer(state = new InitialState) {
  return state;
}
