import React, { forwardRef } from 'react';

import { getAppState } from '../configureMiddleware';

// this.getAppStateRef = false;
// export function setGlobalInjectIntl(getAppState) {
// 	if (!this.getAppStateRef)
// 			this.getAppStateRef = getAppState;
// 		console.log('setGlobalInjectIntl', Boolean(this.getAppStateRef))
// }
// export function injectIntl(comp) {
// 	console.log('start injectIntl')

// 	console.log('check getAppState exsist')
// 	if (this.getAppStateRef) {
// 		console.log('getAppState exsist')
// 		var intl = this.getAppStateRef().getNested(['projects', 'intl']);
// 	}

// 	console.log('injectIntl intl', Boolean(intl));


// 	if (!intl)
// 		comp = originlInjectIntl(comp);
// 	else {
// 		console.log('NOW IT IS INNN')
// 		comp = Object.assign(comp, { intl });
// 	}

// 	return comp;
// };
;
// export function injectIntl(WrappedComponent) {

//   class InjectIntl extends Component {
//     constructor(props) {
//       super(props);
//       const appState = getAppState?.();
//       this.state = {
//         intl: appState?.projects?.intl || appState?.app?.intl || props.intl,
//       };
//     }

//     componentWillUnmount() {
//       this.setComponentData({ firsMount: true }, this.props);
//     }

//     componentWillReceiveProps(nextProps) {
//       this.setComponentData(this.props, nextProps);
//     }

//     setComponentData(props, nextProps) {
//       const { intl } = this.state;
//       const appState = getAppState?.();
//       const nextIntl = appState?.projects?.intl || appState?.app?.intl || intl;
//       if (props.firsMount || intl !== nextIntl) {
//         console.log('setComponentData', ++count);
//         this.setState({ intl: nextIntl });
//       }
//     }
    
//     render() {
//       const { intl } = this.state;

//       return (
//         <WrappedComponent
//           {...this.props}
//           intl={intl}
//         />
//       );
//     }
//   }

//   return hoistNonReactStatics(InjectIntl, WrappedComponent);

// }

export const injectIntl = WrappedComponent => {
  const IntlWrapped = forwardRef((props, ref) => {
    const appIntl = getAppState?.()?.app?.intl;
    const projectsIntl = getAppState?.()?.projects?.intl;

    const intl = projectsIntl || appIntl;
    
    return <WrappedComponent {...props} ref={ref} intl={intl} />;
  });

  return IntlWrapped;
}
