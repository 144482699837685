import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {hideLoading, startLoading} from '../app/actions';

const useLoadingScreen = () => {
  const dispatch = useDispatch();

  const startLoadingAnimation = useCallback((operationId, msg) => {
    dispatch(startLoading({
      title: msg,
      overlay: true,
      hideOnBackgroundPress: false,
      operationId,
    }));
  }, []);

  const stopLoadingAnimation = useCallback((operationId) => {
    dispatch(hideLoading(operationId));
  }, []);

  return {
    startLoadingAnimation,
    stopLoadingAnimation,
  };
};

export default useLoadingScreen;