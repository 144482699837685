import * as actions from './actions';
import * as authActions from '../auth/actions';
import * as contactsActions from '../contacts/actions';
import * as checklistsActions from '../checklists/actions';
import * as companiesActions from '../companies/actions'
import * as pdfActions from '../pdf/actions';
import * as propInstancesActions from '../propertiesInstances/actions';
import * as propertiesTypesActions from '../propertiesTypes/actions'
import systemMessages from '../app/systemMessages';
import { Map, OrderedMap } from 'immutable';
import { Record } from '../transit';
import _ from 'lodash';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { POST_EVENTS } from '../posts/trackPosts';


const GENERAL_LOADING_MODAL = 'general';
const loadingTimeout = 1000 * 5;

const InitialState = Record({
  online: false,
  storageCleaned: 0,
  storageLoaded: false,
  failedQueries: Map(),
  navNextFunc: null,
  status: '0',
  toast: null,
  alert: null,
  loading: false,
  loadingMap: Map(),
  lang: null,
  rtl: null,
  lastProjectScreen: 'posts',
  intl: null,
  isConnected: true,
  canceledOperations: Map(),
  connectionManager: OrderedMap(),
  connectionViewerVisiblity: Map(),
  configurationMode: false,
}, 'app');

export const rtlLanguages = {
  'he' : true
}

const initialState = new InitialState;

export default function appReducer(state = initialState, action) {
  if (process.env.NODE_ENV !== 'production' && action.type != "REDUX_STORAGE_SAVE" && action.type != 'GET_NATIVE_CONTACTS_COMPLETED' && action.type != "CHECK_PERMISSIONS")
    try {
      // console.log('Action Type: ' + action.type, ' payload:' + JSON.stringify(action.payload || {}));
    } catch (e) {
      console.log('Action Type: ERROR action is not a json');
    }
    

  switch (action.type) {
    case actions.APP_START + '_SUCCESS': {
      // if (didStart)
      //   return state;
        
      if (!action.payload)
        return state;
        
        // TODO: Ask or about his
      const { lang, didChanged, didStart } = action.payload;
      
      if (didStart)
        return;

      if (didChanged) {
        state = state.set('lang', lang);
        state = state.set('rtl', Boolean(rtlLanguages[lang]))
      }
      return state;
    }

    case actions.SET_APP_INTL: {
      if (!action.payload)
        return state;
      
      const { intl } = action.payload;

      if (intl)
        state = state.set('intl', intl);
      
      return state;
    }

    case actions.SET_LANG: {
      if (!action.payload)
        return state;
      const { lang } = action.payload;
      state = state.set('rtl', Boolean(rtlLanguages[lang]))
      return state.set('lang', lang);
    }

    case actions.APP_STORAGE_NOT_LOAD: {
      return state.set('storageLoaded', true);
    }

    case actions.CLEAN_PERMISSION_BASED_DATA + "_SUCCESS": {
      return state.set('storageCleaned', state.get('storageCleaned') + 1);
    }

    // Status change for login
    case authActions.SMS_VERIFICATION_SENT + "_SUCCESS":
    case authActions.START_SMS_LISTENER:
      return state.set('status', '1');

    case contactsActions.GET_NATIVE_CONTACTS + "_SUCCESS":
      return state.set('status', '2');

    // case contactsActions.NATIVE_CONTACTS_LOADED:
    //   return state.set('status', '3');

    case actions.START_TOAST: {
      const { toast } = action.payload;
      return state.set('toast', toast);
    }

    case actions.START_ALERT: {
      const { alert } = action.payload;
      return state.set('alert', alert);
    }

    case actions.HIDE_TOAST:
      return state.set('toast', null);

    case actions.START_LOADING: {
      const { toast, overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId = GENERAL_LOADING_MODAL, startTS } = action.payload;
      let newLoadingObject = { show: true, toast, overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId, startTS };

      if (state.getNested(['loadingMap', operationId]))
        return state;

      state = state.setNested(['loadingMap', operationId], newLoadingObject);
      return state.set('loading', newLoadingObject);
    }    
    
    case POST_EVENTS.EXPORT_POST_AS_PDF: 
    case checklistsActions.EXPORT_CHECKLIST_AS_PDF: 
    case checklistsActions.GET_CHECKLIST_STATUS_JSON: 
    case checklistsActions.DUPLICATE_CHECKLIST:
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA:
    case checklistsActions.DUPLICATE_CHECKLIST + 'ERROR':
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA + 'ERROR':
    case propInstancesActions.UPDATE_PROPERTIES_INSTANCE + 'ERROR':
    case propInstancesActions.REMOVE_PROPERTIES_INSTANCE + 'ERROR':
    case propertiesTypesActions.PUT_SELECTION_LIST_OPTION + "ERROR":
    case propertiesTypesActions.DELETE_SELECTION_LIST_OPTION + "ERROR":
    case companiesActions.CREATE_NEW_COMPANY:    
    case pdfActions.DOWNLOAD_PDF: {
      const { success } = action.payload
      if (action.payload.hasOwnProperty('success') && !success) {
        var alert = {message:systemMessages.error, title: systemMessages.connectionError, date: Date.now() }
        state = state.set('alert', alert);
        state = state.set('loadingMap', state.get('loadingMap').clear());
        state = state.set('loading', false);
      }

      return state;
    }

      
    case actions.HIDE_ALL_LOADING: {
      state = state.set('loadingMap', state.get('loadingMap').clear());
      return state.set('loading', false);
     }

    case actions.HIDE_LOADING:
    case actions.LOADING_TIMEOUT:
    case POST_EVENTS.EXPORT_POST_AS_PDF + "_SUCCESS": 
    case checklistsActions.EXPORT_CHECKLIST_AS_PDF + "_SUCCESS": 
    case pdfActions.EXPORT_FORM_AS_PDF + "_SUCCESS": 
    case checklistsActions.GET_CHECKLIST_STATUS_JSON + "_SUCCESS":
    case checklistsActions.DUPLICATE_CHECKLIST + "_SUCCESS":
    case checklistsActions.EDIT_CHECKLIST_EXTRA_DATA + '_SUCCESS':
    case companiesActions.CREATE_NEW_COMPANY + "_SUCCESS":
    case propInstancesActions.UPDATE_PROPERTIES_INSTANCE + "_SUCCESS":
    case propInstancesActions.REMOVE_PROPERTIES_INSTANCE + "_SUCCESS":
    case propertiesTypesActions.PUT_SELECTION_LIST_OPTION + "_SUCCESS":
    case propertiesTypesActions.DELETE_SELECTION_LIST_OPTION + "_SUCCESS":
    case pdfActions.DOWNLOAD_PDF + "_SUCCESS": {
      const operationId = _.get(action, ['payload','operationId'], GENERAL_LOADING_MODAL);
      state = state.deleteIn(['loadingMap', operationId]);
      let latestLoadingObject = state.get('loadingMap').sort(l => l.startTS).first() || false;
      return state.set('loading', latestLoadingObject);
    }

    case actions.UPDATE_CONNECTION_STATUS: {
      const { isConnected } = action.payload
      if (isConnected) {
        ClientServerConnectivityManagerInstance.syncQueue();
      }

      return state.set('isConnected', isConnected);
    }
    
    case actions.CANCEL_OPERATION: {
      if (action.payload.operationId) {
        const { operationId } = action.payload;
        return state.setIn(['canceledOperations', operationId], true);
      }
      return state;
    }

    case actions.CONNECTION_MANAGER_UPDATE: {
      const { status, size, scope, scopeId, subject, subjectName } = action.payload;

      let fullSubjectName = subject + (subjectName ? `.${subjectName}` : "");

      let lastVal = state.getIn(['connectionManager', scope, scopeId, fullSubjectName]);
      if (lastVal)
        state = state.setIn(['connectionManager', `${scope}_history`, scopeId, fullSubjectName, Date.now()], lastVal);

      return state.setIn(['connectionManager', scope, scopeId, fullSubjectName], {status, size, time:Date.now(), timeSinceLastAction: lastVal ? Date.now() - lastVal.time : null});
    }

    case actions.UPDATE_CONNECTION_VIEWER_VISIBILITY: {
      const { visible } = action.payload
      return state.setIn(['connectionViewerVisiblity', 'visible'], visible);
    }

    case actions.UPDATE_CONFIGURATION_MODE: {
      const { mode } = action.payload
      return state.setIn(['configurationMode'], mode);
    }

    case actions.CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }
  
  return state;
}