import {useContext} from 'react';
import {ProjectContext} from '../../common/projects/contexts';
import {useSelector} from 'react-redux';
import _ from 'lodash';

// must return reference to the same empty array, since [] !== [],
// to prevent infinite loop in lifecycle
const DEFAULT_LOCATIONS_GROUP_VALUE = [];
const DEFAULT_UNITS_GROUP_VALUE = [];
const DEFAULT_FLOORS_GROUP_VALUE = [];
const DEFAULT_BUILDINGS_GROUP_VALUE = [];

const useLocationGroups = () => {
  const { selectedProjectId } = useContext(ProjectContext);
  const locationGroupsValues = useSelector(state =>
    _.get(
      state,
      ['propertiesTypes', 'projectProperties', selectedProjectId, 'locationsInfo', 'groups', 'values'],
      DEFAULT_LOCATIONS_GROUP_VALUE,
    )
  );
  const unitTagsValues = useSelector(state =>
    _.get(
      state,
      ['propertiesTypes', 'projectProperties', selectedProjectId, 'locationsInfo', '-unitsTags', 'values'],
      DEFAULT_UNITS_GROUP_VALUE,
    )
  );
  const floorTagsValues = useSelector(state =>
    _.get(
      state,
      ['propertiesTypes', 'projectProperties', selectedProjectId, 'locationsInfo', '-floorsTags', 'values'],
      DEFAULT_FLOORS_GROUP_VALUE,
    )
  );
  const buildingTagsValues = useSelector(state =>
    _.get(
      state,
      ['propertiesTypes', 'projectProperties', selectedProjectId, 'locationsInfo', '-buildingsTags', 'values'],
      DEFAULT_BUILDINGS_GROUP_VALUE,
    )
  );
  return {
    locationGroupsValues,
    unitTagsValues,
    floorTagsValues,
    buildingTagsValues,
  };
};

export default useLocationGroups;