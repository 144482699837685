import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    defaultMessage: 'You have no buildings.',
    id: 'buildings.buildings.empty'
  },
  buildingNumber: {
    defaultMessage: 'Building number {counter}.',
    id: 'buildings.buildings.buildingNumber'
  },  
  buildingNumberShort: {
    defaultMessage: 'Building {counter}',
    id: 'buildings.buildings.buildingNumberShort'
  },  
  buildingTitle: {
    defaultMessage: 'Building No.{counter} title',
    id: 'buildings.buildings.buildingTitle'
  },
  totalBuildingsOnComplexTitle: {
    defaultMessage: "Total number of buildings",
    id: 'units.wizard.totalBuildingsOnComplex.title'
  },
  totalBuildingsOnComplexSubTitle: {
    defaultMessage: "How many buildings in the complex?",
    id: 'units.wizard.totalBuildingsOnComplex.subTitle'
  },
  buildingTitlePlaceholder: {
    defaultMessage: 'The title of the building',
    id: 'buildings.buildings.buildingTitlePlaceholder'
  },
  AllCategoriesfilter: {
    defaultMessage: 'Any trade',
    id: 'buildings.filter.all.category'
  },
  AllDatesfilter: {
    defaultMessage: 'Any Date',
    id: 'buildings.filter.all.date'
  },
  AllAssignTofilter: {
    defaultMessage: 'Anyone',
    id: 'buildings.filter.all.assignTo'
  },
  locations: {
    "defaultMessage": "Locations",
    "id": "buildings.locations.title"
  },
  allBuildings: {
    defaultMessage: "All Buildings",
    id: "buildings.buildings.allBuildings"
  },
  building: {
    defaultMessage: "Building",
    id: "analytics.axesLabels.building"
  },
});
