import { useEffect, useRef, useState } from "react";

/**
 * 
 * @returns {[React.MutableRefObject<any>, boolean]}
 */
const useHover = () => {
  const [isHover, setIsHover] = useState(false);
  
  const componentRef = useRef(null);

  useEffect(() => {
    const component = componentRef.current;
    if (component) {
      component.onmouseenter = () => setIsHover(true);
      component.onmouseleave = () => setIsHover(false)
    }

    return () => {
      if (component) {
        component.onmouseenter = null;
        component.onmouseleave = null;
      }
    }
  }, [componentRef.current]);

  return [componentRef, isHover];
}

export default useHover;