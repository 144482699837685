import { batchDispatch } from '../app/funcs';
import serverSDK from '@cemento-sdk/server';
import { subscribeToLastUpdates } from '../lib/utils/utils';

export const GET_STAGES = 'GET_STAGES';
export const STAGES_LOADING = 'STAGES_LOADING';

export function getStages(viewer, projectId) {
  const getPromise = async () => {
    const scopeParams = {
      scope: 'projects',
      scopeId: projectId,
    };

    const resourceParams = {
      subject: 'stages',
      getData: () => {
        return serverSDK.checklists.getStages({ projectId });
      },
    };

    const onData = (data) => {
      batchDispatch([{ type: GET_STAGES, payload: { projectId, stages: data } }]);
    };

    const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData);

    if (result) {
      onData(result);
    }

    return { projectId };
  };

  return {
    type: STAGES_LOADING,
    payload: getPromise(),
  };
}
