
import { platformActions } from "../../platformActions";
import { realmInstance } from "../../configureMiddleware";
import _ from 'lodash';

export function updateRetryRealm(newInstances, projectId, realmRetry, schemaName) {
  let realmLocalInstances = realmRetry.objects(schemaName).filtered('projectId = "' + projectId + '"');
  
  try {
    realmRetry.beginTransaction();
    newInstances.forEach(currNewInstance => {
      let objectToSave = {...currNewInstance.realmToObject(), projectId, isLocal: Boolean(currNewInstance.isLocal)}; // islocal is important for the update

      if (objectToSave.isLocal || (realmLocalInstances.filter(x => x.id == objectToSave.id && x.isLocal).length)) { // As long as its local - update all changes, include image urls that got updated
          realmRetry.create(schemaName, objectToSave, true);
      }
    })
    realmRetry.commitTransaction();
  }
  catch (e) {
    realmRetry.cancelTransaction();
    throw e;
  }
}

export const saveToRealm = ({ objectsToSave, schemaType, schemaName, lastUpdateTStypeId, projectId, preProcessObjectForLocalSaveFunc = null, newLastUpdateTS = 0, objectCanBeSavedFunc = (objectToSave) => true, ignoreTimestamp = false, cleanAll = false, forceLocalSave = false }) => {
  lastUpdateTStypeId = lastUpdateTStypeId || schemaType; // WARNING only don't provide "lastUpdateTStypeId" if the you don't care about the last update TS schema
  objectsToSave = Object.values(objectsToSave || {})
    .filter(Boolean)
    .sort((objectA, objectB) => objectA.updatedTS > objectB.updatedTS ? -1 : 1);

  if (typeof preProcessObjectForLocalSaveFunc === 'function')
    objectsToSave = objectsToSave.map(preProcessObjectForLocalSaveFunc);

  const realm = realmInstance[schemaType];
  
  let ignoreList = {}; // NOTE: This entire mechanism can be removed once we are working completely with the api server and the udpate ts is updated before we save as oppose to now when it is updated after we save
  if (!forceLocalSave && objectsToSave.length === 1) { // Run with force local save all the time for now
    objectsToSave.forEach(objectToSave => {
      if (!(objectsToSave || {}).id)
        return;

      const savedObjects = realm.objects(schemaName).filtered(`projectId = "${projectId}" AND id = "${objectToSave.id}"`);
      if (savedObjects.length === 1) {
        const savedObject = savedObjects[0].realmToObject();
        const isSavedObjectStillValid = Boolean(
          !savedObject.updatedTS || (
            objectToSave.isLocal === savedObject.isLocal &&
            (savedObject.updatedTS && savedObject.updatedTS.getTime && (objectToSave.updatedTS === savedObject.updatedTS.getTime()))
          )
        );
        if (isSavedObjectStillValid)
          ignoreList[objectToSave.id] = true;
      }
      else {
        platformActions.sentry.notify(`${lastUpdateTStypeId} for some reason there was more than 1 savedObject with a specific ID`, savedObjects);
      }
    });
  }
  
  if (Object.keys(ignoreList).length < objectsToSave.length) {
    realm.beginTransaction();
    try {
      if (cleanAll) {
        const allProjectObjects = realm.objects(schemaName).filtered(`projectId = "${projectId}"`);
        const allProjectObjectLastUpdateTS = realm.objects('lastUpdateTS').filtered(`projectId = "${projectId}" AND type = "${lastUpdateTStypeId}"`);
        realm.delete(allProjectObjects);
        realm.delete(allProjectObjectLastUpdateTS);
      }
      const deletedIdList = []

      // All current local items
      objectsToSave.forEach(currObjectToSave => {
        if (!(currObjectToSave || {}).id || ignoreList[currObjectToSave.id])
          return;

        if (currObjectToSave && currObjectToSave.id && objectCanBeSavedFunc(currObjectToSave)) {
          if (!currObjectToSave.isDeleted || currObjectToSave.isLocal) {
            const newObjectToSave = { ...currObjectToSave.realmToObject(), isLocal: Boolean(currObjectToSave.isLocal), projectId };
            realm.create(schemaName, newObjectToSave, 'modified');
          }
          else {
						deletedIdList.push(currObjectToSave.id);
					}
        } 
        else {
          console.warn(`${lastUpdateTStypeId} missing ID`) // TODO: Send to bugsnag
          platformActions.sentry.notify(`${lastUpdateTStypeId} missing ID`, {...(currObjectToSave || {}).realmToObject()});
        }
      });
      
      if (deletedIdList?.length) {
        try {
          const allDeletedInstances = realm
            .objects(schemaName)
            .filtered(`projectId = "${projectId}" AND id IN {"${deletedIdList.join('", "')}"}`);
  
          realm.delete(allDeletedInstances);
        } catch (error) {
          console.log('____ERRROR', error);
        }
      }
      
      const noneLocalObjectsMaxTS = objectsToSave.reduce((currMaxUpdateTS, o) => !o.isLocal && o.updatedTS ? Math.max(o.updatedTS, currMaxUpdateTS) : currMaxUpdateTS, false); // TODO: what if for some reason we have both local and none local objects in here
      newLastUpdateTS = newLastUpdateTS || noneLocalObjectsMaxTS;
      if (newLastUpdateTS) { // TODO: + check performance
        if (projectId)
          realm.create('lastUpdateTS', {id: `${projectId}_${lastUpdateTStypeId}`, lastUpdateTS: newLastUpdateTS, projectId, type: lastUpdateTStypeId}, true)
        else {
          platformActions.sentry.notify(`${lastUpdateTStypeId} lastUpdateTS projectId missing`, newLastUpdateTS);
          console.warn(`${lastUpdateTStypeId} lastUpdateTS missing project ID`) // TODO: Send to bugsnag
        }
      }

      realm.commitTransaction();
    } catch (e) {
      realm.cancelTransaction();
      throw e;
    }
  }

  return { savedObjects: objectsToSave };
}

/**
 * 
 * @param {{
 *  idsToGet?: string[],
 *  projectId: string,
 *  schemaType: 'propertyInstances' | 'posts' | 'checklistItemInstances' | 'equipment' | 'employees',
 *  schemaName: 'post24' | 'equipment1' | 'employee1' | 'propertyInstance1' | 'checklistItemInstance1',
 *  query?: string,
 * }} param0 
 * @returns 
 */
export const getFromRealm = ({ idsToGet = [], projectId, schemaName, schemaType, query = '' }) => {
  let objectsToReturn = {};

  if (schemaName && schemaType && projectId) {
    const realmCollection = realmInstance[schemaType].objects(schemaName).filtered(`projectId == "${projectId}" ${query ? 'AND ' + query : ''}`);
    if ((idsToGet || []).length) {
      idsToGet.forEach(idToGet => {
        const objects = realmCollection.filtered(`id == "${idToGet}"`);
        if (objects.length === 1)
          objectsToReturn[objects[0].id] = objects[0].realmToObject();
        else if (objects.length > 1) {
          platformActions.sentry.notify(`Found object ${schemaType} with ID ${idToGet} more than once in realm`, { schemaType, idToGet, projectId });
          console.warn(`Found object ${schemaType} with ID ${idToGet} more than once in realm`) // TODO: Send to bugsnag
        }
      });
    }
    else
      realmCollection.forEach(o => objectsToReturn[o.id] = o.realmToObject());
  }
  
  return { objects: objectsToReturn };
}