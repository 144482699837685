import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import Table from './Table';
import TableColumnHeader from '../Reports/TableColumnHeader';
import Text from '../../components/CementoComponents/Text';
import systemMessages from '../../../common/app/systemMessages';
import _ from 'lodash';

import { columnTypes } from '../../../common/analytics/funcs';
import { ReportContext } from '../../../common/analytics/contexts';
import { filterByQuery } from '../../../common/permissions/funcs';
import SplitViewPage from '../../layouts/SplitViewPage';
import { startToast } from '../../../common/app/actions';

// Assets
import theme from '../../assets/css/theme';

import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import 'react-table/react-table.css';

// core components
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { getUniqueId, isEmptyValue } from '../../../common/app/funcs';
import withRouterHOC from '../../components/Router/util/withRouterHOC';

class TableWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.id = getUniqueId('TableWrapper_');
		this.setComponentData = this.setComponentData.bind(this);
		this.firstColumnCellClickHandler = this.firstColumnCellClickHandler.bind(this);
		this.mainColumnClickHandler = this.mainColumnClickHandler.bind(this);
		this.cellClickHandler = this.cellClickHandler.bind(this);
		this.closeSelectedCell = this.closeSelectedCell.bind(this);
		this.organizeRowData = this.organizeRowData.bind(this);
		this.setTableDimensions = this.setTableDimensions.bind(this);
		this.getContainerRef = this.getContainerRef.bind(this);
		this.getHeaderRef = this.getHeaderRef.bind(this);
		this.getScrollRef = this.getScrollRef.bind(this);
		this.rowsFilterFunction = this.rowsFilterFunction.bind(this);
		this.afterExpandOrFoldColumnFunction = this.afterExpandOrFoldColumnFunction.bind(this);
		this.rowsRefs = React.createRef();
		this.state = {
			visibleRows: {},
			mainColumnClickHandler: this.mainColumnClickHandler,
			cellClickHandler: this.cellClickHandler,
			firstColumnCellClickHandler: this.firstColumnCellClickHandler,
			rowsRefs: this.rowsRefs, // put all this stuff in the state because then the state is the context
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({ firstMount: true }, this.props);

		const { propertiesTypes, rowsLevel, cellHeight, rtl, secRowHeight, expandableTable } = this.props;
		this.setState({
			propertiesTypes,
			rowsLevel,
			cellHeight,
			rtl,
			secRowHeight,
			expandableTable,
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			this.props.selectedProjectId != nextProps.selectedProjectId ||
			this.props.subjectType != nextProps.subjectType ||
			this.props.menus != nextProps.menus ||
			this.props.selectedFilterSet != nextProps.selectedFilterSet ||
			this.props.sideCardObject != nextProps.sideCardObject ||
			this.props.originalRows != nextProps.originalRows ||
			this.props.columns != nextProps.columns ||
			_.get(this.props, ['location', 'search']) !== _.get(nextProps, ['location', 'search'])
		) {
			this.setComponentData(this.props, nextProps);
		}

		// We move all to the state, so then we can send it directly to the context by only this.state (And if the props referneces have not changes, it should not re-render anyway)
		const { propertiesTypes, rowsLevel, cellHeight, rtl, secRowHeight, expandableTable } = nextProps;
		this.setState({
			propertiesTypes,
			rowsLevel,
			cellHeight,
			rtl,
			secRowHeight,
			expandableTable,
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		const shouldComponentUpdate =
			this.props.selectedProjectId != nextProps.selectedProjectId ||
			this.props.menus != nextProps.menus ||
			this.props.propertiesTypes != nextProps.propertiesTypes ||
			this.state.expandedColumns != nextState.expandedColumns ||
			this.state.sideCardObject != nextState.sideCardObject ||
			this.state.visibleRows != nextState.visibleRows ||
			this.state.tableWidth != nextState.tableWidth ||
			this.state.selectedCell != nextState.selectedCell ||
			this.props.selectedFilterSet != nextProps.selectedFilterSet ||
			this.state.allRows != nextState.allRows ||
			this.props.originalRows != nextProps.originalRows;

		return shouldComponentUpdate;
	}

	async setComponentData(props, nextProps) {
		const { originalRows } = nextProps;
		let newStateChanges = {};

		if (nextProps.getNested(['selectedFilterSet', 'id']) != props.getNested(['selectedFilterSet', 'id']))
			newStateChanges.expandedColumns = null;
		else newStateChanges.expandedColumns = this.state.expandedColumns;

		if (props.sideCardObject != nextProps.sideCardObject) {
			newStateChanges.sideCardObject = nextProps.sideCardObject;
			if (this.state.sideCardObject && this.state.selectedCell && !newStateChanges.sideCardObject)
				newStateChanges.selectedCell = null;
		}

		if (
			props.getNested(['uiParams', 'unselectCellAndBackOrCloseSideCard']) !=
			nextProps.getNested(['uiParams', 'unselectCellAndBackOrCloseSideCard'])
		)
			newStateChanges.selectedCell = null;

		if (
			[
				['columns'],
				['rtl'],
				['firstColumnRenderFunc'],
				['cellHeight'],
				['secRowHeight'],
				['primaryColumn'],
				['expandableTable'],
				['shouldExpandSingleFirstLevel'],
				['firstColumnTitle'],
				['location', 'search'],
				['filterPathDelimeter'],
				['shouldCalculateAggregatedCells'],
				['filterUrlKey'],
				['originalRows'],
			].some(path => props.isValDiff(nextProps, path))
		) {
			Object.assign(newStateChanges, this.organizeRowData(originalRows, nextProps, props.firstMount));
			if (newStateChanges.rowsFilterTS === this.state.rowsFilterTS && props.isValDiff(nextProps, ['originalRows'])) {
				newStateChanges.rowsFilterTS = Date.now();
			}
		}

		if (Object.keys(newStateChanges).length != 0) this.setState(newStateChanges);
	}

	calcParentRowAggregatedValues = (columnsMap, row, childRows) => {
		const { subjectType, propertiesTypes } = this.props;
		const totalRows = childRows?.length || 0;
		let valuesCopy = _.entries(row.values).reduce((acc, [columnId, col]) => {
			let newCol = { ...col };
			if (columnsMap[columnId] && !columnsMap[columnId].original?.isPrimary) {
				const showAsSum = _.get(
					propertiesTypes,
					[`${subjectType}Info`, columnId, 'UIStruct', 'table', 0, 'collapsed', 'settings','sum'],
					false,
				);

				const displayType = ['Status', propertyTypes.CERTIFICATION, propertyTypes.NUMBER].includes(columnsMap[columnId].displayType)
					? col.displayType
					: propertyTypes.STRING;

				newCol = {
					...(col || {}),
					displayType,
					displayValue: col.displayValue || (displayType === propertyTypes.NUMBER || showAsSum ? 0 :`0/${totalRows}`),
					displayParams: {
						isCounterRow: true,
						...(col.displayParams || {}),
					},
				};
			}
			return _.set(acc, [columnId], newCol);
		}, {});

		let isColumnInitializedFlags = {};
		const hideCounter = false;
		(childRows || []).forEach(childRow => {
			_.entries(childRow.values).forEach(([columnId, value]) => {
				if (columnsMap[columnId]?.['original']?.isPrimary) return;
				let cellCounter = Number(_.get(valuesCopy, [columnId, 'cellCounter'], 0)) + 1;
				_.set(valuesCopy, [columnId, 'cellCounter'], cellCounter);

				const tableUIStruct = _.get(
						propertiesTypes,
						[`${subjectType}Info`, columnId, 'UIStruct', 'table', 0, 'collapsed', 'settings'],
						{},
					);

				const showAsEmpty = tableUIStruct?.empty; // do not display anything in the summary row
				const showAsSum = tableUIStruct?.sum; // display sum of all numbers in the column
				const showAsCounter = tableUIStruct?.counter; // display X/Y
				
				if (showAsEmpty) return;

				switch (value.displayType) {
					case 'Status':
					case propertyTypes.CERTIFICATION: {
						let valueCopy = valuesCopy[columnId];
						if (!isColumnInitializedFlags[columnId]) {
							valueCopy.displayParams = { items: [] };
							valueCopy.displayType = 'Status';
							isColumnInitializedFlags[columnId] = true;
						}
						const color =
							value.displayType === 'Status' ? value.displayParams?.items?.[0]?.color : value.displayParams?.color;

						if (color) {
							const colorIndex = valueCopy?.displayParams?.items?.findIndex(c => c.color === color);
							if (isNaN(colorIndex)) break;

							if (colorIndex != -1) {
								valueCopy.displayParams.items[colorIndex].message++;
							} else {
								valueCopy.displayParams.items.push({
									id: `counter-${columnId}-${color}-${row.id}`,
									color,
									message: 1,
									issueState: value.originalValue,
								});
							}
						}
						break;
					}
					case propertyTypes.NUMBER: {
						// Counter should prevail
						if (showAsSum && !showAsCounter) {
							const newAggregatedValue =
								(_.toNumber(_.get(valuesCopy, [columnId, 'originalValue'])) || 0) +
								(_.toNumber(value.originalValue) || 0);
							if (!_.isEqual(_.get(valuesCopy, [columnId, 'originalValue']), newAggregatedValue)) {
								_.set(valuesCopy, [columnId, 'originalValue'], newAggregatedValue);
								_.set(valuesCopy, [columnId, 'displayValue'], newAggregatedValue);
							}
							break;
						}
					}
					case propertyTypes.DATE:
					case propertyTypes.BOOLEAN:
					case propertyTypes.STRING:
					default: {
						if (hideCounter) {
							break;
						}
						const newAggregatedValue =
							Number(valuesCopy[columnId]?.originalValue || 0) +
							(isEmptyValue(value.originalValue || value.displayValue) ? 0 : 1);
							
						if (_.get(row, ['values', columnId, 'displayParams', 'isFractionsCounter'], true)){
							_.set(valuesCopy, [columnId, 'displayValue'], `${newAggregatedValue}/${totalRows}`);
						}
						_.set(valuesCopy, [columnId, 'originalValue'], newAggregatedValue);
						_.set(valuesCopy, [columnId, 'displayType'], 'String');
						_.set(valuesCopy, [columnId, 'displayParams'], { direction: 'ltr', isCounterRow: true });
						break;
					}
				}
			});
		});
		return valuesCopy;
	};

	organizeRowData(originalRows, props, isFirstRender = false) {
		if (!Array.isArray(originalRows)) return null;
		const {
			columns,
			rtl,
			firstColumnRenderFunc,
			cellHeight,
			secRowHeight,
			primaryColumn,
			expandableTable,
			shouldExpandSingleFirstLevel,
			firstColumnTitle,
			location,
			filterPathDelimeter,
			filterUrlKey,
		} = props || this.props;
		const { rowsFilterTS: stateRowsFilterTS, visibleRows: stateVisibleRows } = this.state;

		let sortedRows = [...originalRows];

		let sortFunc = props.sortFunc;
		if (!sortFunc) sortFunc = (rowA, rowB) => (rowA.order || 0) - (rowB.order || 0);

		sortedRows.sort(sortFunc);

		// Set on each row if it is the toppest parent
		let parentsRow = {};
		let allRows = {};
		let isCollapsableTable = false;

		sortedRows.forEach(row => {
			if (!allRows[row.id]) allRows[row.id] = row;

			if (!row.parentId && !parentsRow[row.id]) parentsRow[row.id] = row.id;
			else if (row.parentId && !isCollapsableTable) isCollapsableTable = true;
		});

		let rowsFilterTS = stateRowsFilterTS;

		const searchQuary = location && location.search;
		let filteredAllRows = filterByQuery(
			allRows,
			searchQuary,
			filterUrlKey,
			filterPathDelimeter,
			isCollapsableTable,
			parentsRow,
		);
		if (filteredAllRows != allRows) rowsFilterTS = Date.now();

		const childRowsByParentId = _.groupBy(filteredAllRows, 'parentId');

		if (isCollapsableTable && columns?.length) {
			const allColumnsMap = columns.reduce((acc, section) => {
				section.columns?.forEach(col => _.set(acc, col.id, col));
				return acc;
			}, {});
			_.values(filteredAllRows).forEach(row => {
				if (row.parentId) return;

				const childRows = childRowsByParentId[row.id];
				const parentRowValues = this.calcParentRowAggregatedValues(allColumnsMap, row, childRows);
				filteredAllRows[row.id] = Object.assign({}, row, { values: parentRowValues });
			});
		}

		// If only 1 building - open the building on start
		let visibleRows = {};
		if (!isFirstRender) {
			visibleRows = stateVisibleRows;
		} else if (!expandableTable) {
			visibleRows = allRows;
		}
		if (Object.keys(parentsRow).length === 1 && shouldExpandSingleFirstLevel) {
			visibleRows = _.pickBy(allRows, row => row.rowLevel < 3);
		}

		let firstColumnSection = null;

		if (firstColumnRenderFunc) {
			// Add first column
			firstColumnSection = {
				original: { description: 'general' },
				style: {
					backgroundColor: 'white',
					zIndex: theme.zIndexesLevels.eighteen,
					position: 'sticky',
					top: 0,
					[rtl ? 'right' : 'left']: 0,
					outlineOffset: -0.5,
					outline: '0.5px solid rgb(215,215,215)',
				},
				Header: () => (
					<div
						style={{
							borderLeftWidth: 2,
							borderRightWidth: 2,
							display: 'inline-block',
							backgroundColor: 'white',
							width: '100%',
							height: '100%',
							margin: 0,
						}}
					></div>
				),
				columns: [],
			};

			firstColumnSection.columns.push({
				original: {
					description: 'firstColumn',
					alwaysShow: true,
					...(primaryColumn || {}),
				},
				valuesType: Boolean(primaryColumn) && primaryColumn.valuesType,
				columnType: columnTypes.main,
				HeaderValue: Boolean(primaryColumn) && primaryColumn.id,
				mainColumnId: Boolean(primaryColumn) && primaryColumn.id,
				rowStyle: {
					position: 'sticky',
					[rtl ? 'right' : 'left']: 0,
					zIndex: theme.zIndexesLevels.five,
					height: cellHeight,
				}, // The area at top of the first column
				style: {
					position: 'sticky',
					[rtl ? 'right' : 'left']: 0,
					top: 51,
					zIndex: theme.zIndexesLevels.eighteen,
					backgroundColor: 'white',
				},
				Header: (currColumn, extraProps) => {
					return (
						<div
							style={{
								width: '100%',
								marginBottom: 1,
								zIndex: theme.zIndexesLevels.eighteen,
								outlineOffset: -0.5,
								outline: '0.5px solid rgb(215,215,215)',
							}}
						>
							<div
								style={{
									background: 'white',
									top: 0,
									bottom: 0,
									right: 0,
									left: 0,
									height: secRowHeight,
									border: 'none',
								}}
							>
								{Boolean(!primaryColumn) && (
									<div
										onClick={() => this.mainColumnClickHandler(null)}
										style={{
											cursor: 'pointer',
											whiteSpace: 'normal',
											height: 'inherit',
											overflow: 'hidden',
											lineHeight: 'normal',
											padding: '15px 15px 0px 15px',
											textOverflow: 'ellipsis',
										}}
									>
										<Text>{firstColumnTitle}</Text>
									</div>
								)}
								{Boolean(primaryColumn) && (
									<TableColumnHeader
										textStyle={{
											fontWeight: theme.strongBold,
											[rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin + 2,
										}}
										key={'header-'.concat(currColumn.original ? currColumn.original.id : 'general')}
										currSubColumn={currColumn}
										rtl={rtl}
										{...extraProps}
									/>
								)}
							</div>
						</div>
					);
				},
				maxWidth: 125,
				alwaysShow: true,
				isFirst: true,
				Cell: firstColumnRenderFunc,
			});
		}

		let mergedColumns = [];
		if (firstColumnSection) mergedColumns.push(firstColumnSection);
		if (columns) {
			if (primaryColumn)
				mergedColumns = mergedColumns.concat(
					columns
						.map(column =>
							Object.assign(
								{},
								column,
								{ columns: column.columns.filter(c => c.id !== primaryColumn.id) }, // remove duplicate primary column
							),
						)
						.filter(c => c.columns.length), // remove primary column section if it had only the primary column
				);
			else mergedColumns = mergedColumns.concat(columns);
		}

		return {
			visibleRows,
			mergedColumns,
			separateParents: isCollapsableTable,
			allRows: filteredAllRows,
			sortFunc,
			rowsFilterTS,
		};
	}

	firstColumnCellClickHandler(originalRow) {
		const { originalRows, subjectType } = this.props;
		const { visibleRows } = this.state;

		let rowId = originalRow.id;
		// if (sortableTable) {
		//   this.cellClickHandler(originalRow, false, 'firstColumn-'.concat(originalRow.id));
		//   return;
		// }

		var newVisibleRows = Object.assign({}, visibleRows);
		if (newVisibleRows[rowId]) {
			// TODO: If the row is the higest parent, we should remove all of it's opened child and thier childs
			if (!originalRow.parentId) {
				let parentsMap = {};
				let childRowsToHidden = {};
				originalRows.forEach(row => {
					parentsMap[row.id] = { parentId: row.parentId };
				});

				Object.values(visibleRows).forEach(currVisibleRow => {
					if (currVisibleRow.parentId && currVisibleRow.parentId == originalRow.id)
						childRowsToHidden[currVisibleRow.id] = currVisibleRow.id;
					else if (
						currVisibleRow.parentId &&
						parentsMap[currVisibleRow.parentId] &&
						parentsMap[currVisibleRow.parentId].parentId == originalRow.id
					)
						childRowsToHidden[currVisibleRow.id] = currVisibleRow.id;
				});

				Object.values(childRowsToHidden).forEach(x => delete newVisibleRows[x]);
			}

			delete newVisibleRows[rowId];
		} else {
			newVisibleRows[rowId] = originalRow;
		}
		this.setState({ visibleRows: newVisibleRows });
	}

	mainColumnClickHandler(mainColumn) {
		const { expandableTable } = this.props;
		const { expandedColumns } = this.state;

		mainColumn = mainColumn || {};

		if (expandableTable) {
			if (!mainColumn.id || expandedColumns == mainColumn.id) this.setState({ expandedColumns: null });
			else if (Object.values(mainColumn.subColumns || []).length) this.setState({ expandedColumns: mainColumn.id });
		}
	}

	/**
	 *
	 * @param {number} index
	 * @param {'up' | 'down'} direction
	 * @returns
	 */
	handleGoToRowByIndex = (index, direction = 'down') => {
		// only tested with 2 levels, should work with more than 2 levels but needs to be tested
		const { visibleRows } = this.state;
		const compRef = this.rowsRefs.current?.[index]?.current?.component;
		if (!compRef) return;

		const { id, rowsWithChildrens } = compRef.props;
		const isCollapsableRow = Boolean(rowsWithChildrens[id]);
		const isCollapsed = isCollapsableRow && !visibleRows[id];
		const isGoingDown = direction === 'down';

		if (isCollapsableRow) {
			if (isCollapsed) compRef.handleCellClick();
			const rowsIndexes = Object.keys(this.rowsRefs.current).map(Number);
			const nextAvailableRowIndex = rowsIndexes[rowsIndexes.indexOf(index) + (isGoingDown ? 1 : -1)]; // this.rowsRefs.current is an array but the index of the row is the index of the row over all the rows, so it could look like this [{}, {}, <empty slot>, {}]
			const nextIndex = index + (isGoingDown ? 1 : -1);
			if (!isGoingDown && nextAvailableRowIndex !== nextIndex)
				// Open the row before selected a row within it
				this.rowsRefs.current[nextAvailableRowIndex]?.current?.component?.handleCellClick?.();

			const clickInterval = setInterval(() => {
				// Waiting for the row to mount and to have a ref to the component
				if (this.rowsRefs.current[nextIndex]?.current?.component) {
					clearInterval(clickInterval);
					this.handleGoToRowByIndex(nextIndex, direction);
				}
			}, 200);

			setTimeout(() => clearInterval(clickInterval), 15000);
		} else {
			const parentRowId = compRef.props.cell?.row?.parentId;
			if (parentRowId) {
				// Open the row before selected a row within it
				const parentRowRef = this.rowsRefs.current.find(row => row?.current?.component?.props?.id === parentRowId)
					?.current?.component;
				if (parentRowRef && !visibleRows[parentRowId]) parentRowRef.handleCellClick();
			}
			compRef.handleCellClick();
		}
	};

	async cellClickHandler(cell, isAggregatedCell, selectedCellId, rowIndex) {
		const { getSideCardObject, setHasUnSavedChanges, hasUnSavedChanges, startToast } = this.props;
		const { expandedColumns } = this.state;

		if (_.isFunction(hasUnSavedChanges) && hasUnSavedChanges()) {
			let shouldChange = await new Promise(resolve =>
				startToast({
					overlay: true,
					mandatory: true,
					title: systemMessages.manage.leaveWithoutSave,
					message: systemMessages.manage.changesHaveNotBeenSaved,
					actions: [
						{ message: systemMessages.yes, color: 'success', onClick: () => resolve(true) },
						{ message: systemMessages.no, onClick: () => resolve(false) },
					],
				}),
			);

			if (!shouldChange) return;
			if (_.isFunction(setHasUnSavedChanges)) setHasUnSavedChanges(false);
		}

		let isOtherColumnOpened =
			expandedColumns && expandedColumns != cell.column.original.parentId && expandedColumns != cell.column.HeaderValue;
		let isAlreadySelectedCellClick =
			this.state.selectedCell && this.state.selectedCell.id && this.state.selectedCell.id == selectedCellId;
		if (isOtherColumnOpened || isAlreadySelectedCellClick || cell.row.dismissClickHandler) {
			this.setState({
				sideCardObject: null,
				expandedColumns: null,
				selectedCell: null,
			});
			return;
		}

		var selectedCell = { id: selectedCellId, cell };

		if (!getSideCardObject) this.setState({ selectedCell });
		else {
			const tableMethods = {
				goNextRow:
					Boolean((this.rowsRefs.current || [])[rowIndex + 1]) &&
					(() => this.handleGoToRowByIndex(rowIndex + 1, 'down')),
				goPreviousRow:
					Boolean((this.rowsRefs.current || [])[rowIndex - 1]) && (() => this.handleGoToRowByIndex(rowIndex - 1, 'up')),
			};
			let side = getSideCardObject(cell, isAggregatedCell, selectedCell, tableMethods, sideCardObject =>
				this.setState({ sideCardObject, selectedCell, sideStackId: selectedCellId }),
			);
			if (side) this.setState({ sideCardObject: side, sideStackId: selectedCellId, selectedCell });
		}
	}

	closeSelectedCell() {
		const { onSideClose } = this.props;
		this.setState({ selectedCell: null, sideCardObject: null });
		if (onSideClose) onSideClose();
	}

	setTableDimensions(dims) {
		this.setState({ tableWidth: dims.bounds.width });
	}

	getContainerRef(node) {
		this.containerRef = node || this.containerRef;
	}

	getHeaderRef(node) {
		this.headerRef = node || this.headerRef;
	}

	getScrollRef(view, node) {
		this.tableScrollRef = node;
	}

	afterExpandOrFoldColumnFunction() {
		if (this.tableScrollRef && this.tableScrollRef.update) this.tableScrollRef.update();
	}

	rowsFilterFunction(row, visibleRows) {
		const { expandableTable } = this.props;
		return !expandableTable || !row.parentId || Boolean(visibleRows[row.parentId]);
	}

	columnFilterFunction(column) {
		return Boolean(column.columnType == columnTypes.main || column.alwaysShow);
	}

	render() {
		const {
			columns,
			showRowsWithEmptyValue,
			originalRows,
			paginationStep,
			forcePagination,
			customWidth,
			rtl,
			infoCardMode,
			sideStackObjectId,
		} = this.props;
		const { expandedColumns, mergedColumns, allRows, sideCardObject, rowsFilterTS, sideStackId } = this.state;
		
		return (
			<ReportContext.Provider value={this.state}>
				<SplitViewPage
					rtl={rtl}
					onSideClose={this.closeSelectedCell}
					ratio={infoCardMode === 'modal' ? 1 / 2 : undefined}
					withHorizontalScroll={true}
					getMainContainerScroll={this.getScrollRef}
					SideStack={Boolean(sideCardObject) && [sideCardObject]}
					sideStackObjectId={sideStackObjectId || sideStackId}
					mode={infoCardMode}
					Main={
						Boolean(originalRows && columns) && (
							<Table
								paginationStep={paginationStep}
								forcePagination={forcePagination}
								className={'mainTable'}
								expandedColumns={expandedColumns}
								afterExpandOrFoldColumn={this.afterExpandOrFoldColumnFunction}
								rowsFilter={this.rowsFilterFunction}
								columnFilter={this.columnFilterFunction}
								data={allRows}
								columnSections={mergedColumns}
								style={{ position: 'relative' }}
								showRowsWithEmptyValue={showRowsWithEmptyValue}
								clearExpanded={this.mainColumnClickHandler}
								customWidth={customWidth}
								rowsFilterTS={rowsFilterTS}
								hasUnSavedChanges={this.hasUnSavedChanges}
							/>
						)
					}
				/>
			</ReportContext.Provider>
		);
	}
}

TableWrapper.defaultProps = {
	secRowHeight: 129,
};

const enhance = compose(
	injectIntl,
	withStyles(regularFormsStyle),
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			uiParams: state.ui.uiParams,
			rtl: state.app.rtl,
		}),
		{ startToast },
	),
);
export default withRouterHOC(enhance(TableWrapper));