import React from 'react';
import Text from './Text';
import postsMessages from '../../../common/posts/postsMessages';
import NothingToDisplay from '../../../common/app/components/NothingToDisplay';
import theme from '../../assets/css/theme';
import withRouterHOC from '../Router/util/withRouterHOC';

class NoItemsFound extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    //////////////////////////////
    //////////////////////////////
    //////////////////////////////

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const {
      message,
      locationMode,
      minHeight,
      treatEntireProjectAsNoLocation,
      style,
      compStyles = {},
      onClick,
    } = this.props;
    const buildingId = this.props.getNested(['navigationParams', 'buildingId']);
    const floorId = this.props.getNested(['navigationParams', 'floorId']);
    const unitId = this.props.getNested(['navigationParams', 'unitId']);
    const locationId = unitId == '_' ? (floorId == '_' ? buildingId : floorId) : unitId;

    let textStyle = style || {
      color: theme.brandPrimary,
      paddingBottom: minHeight ? '25vh' : null,
      fontSize: theme.fontSizeH6,
      fontWeight: theme.bold,
    };
    let height = minHeight ? minHeight + theme.headerHeight : '50vh';
    const componentStyle = Object.assign(
      {
        display: 'flex',
        flex: 1,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: onClick ? 'pointer' : 'auto',
      },
      compStyles
    );
    if (locationMode && (!locationId || (treatEntireProjectAsNoLocation && buildingId == 'all')))
      return (
        <div onClick={onClick ? onClick : undefined} style={componentStyle}>
          <Text style={textStyle}>{this.props.children || postsMessages.selectLocation}</Text>
        </div>
      );
    return (
      <div onClick={onClick ? onClick : undefined} style={componentStyle}>
        <NothingToDisplay>{message || this.props.children || postsMessages.empty}</NothingToDisplay>
      </div>
    );
  }
}

export default withRouterHOC(NoItemsFound);
