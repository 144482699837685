import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { realmInstance } from '../../../configureMiddleware';
import { ProjectContext } from '../../../projects/contexts';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import { realmSafeAction } from '../../../../native/RealmWrapper/funcs';
import { deepEqual, realmQueryStringBuilder } from '../../../app/funcs';
import { setCompanyIdAndGroupsToOwnerAndAssignToOnPosts } from './utils';
import useRefed from '../../../hooks/useRefed';

/**
 * @typedef {'records' | 'tasks'} PostsType
 *
 * @typedef {'safety'} ContentType
 * @param {import('.').Filters} postsFilters
 * @returns
 */

const usePosts = postsFilters => {
	const { selectedProjectId, members, isSafetyProject } = useContext(ProjectContext);

	postsFilters = _.isNil(postsFilters) ? {} : postsFilters;

	// state
	const [currViewPostsMap, setCurrViewPostsMap] = useState({});
	const [currViewPosts, setCurrViewPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);

	// refs
	const realmPostsRef = useRef(null);

	const _postsFilters = useMemoizedValue(postsFilters);

	const getPostsQuery = useCallback(() => {
    const { contentType, postsType, inIds, ignoreContentType } = _postsFilters;

    let queryArr = [`projectId == "${selectedProjectId}"`];

		if (!ignoreContentType) {
			if (contentType === 'safety') queryArr.push('trade.id == "1038"');
			else if (isSafetyProject) queryArr.push(`trade.id != "1038"`);
		}

		switch (postsType) {
			case 'records': {
				queryArr.push(['issueState == 0', 'issueState == null']);
				queryArr.push(['isIssue == false', 'isIssue == null']);
				break;
			}

			case 'issues':
			case 'tasks': {
				queryArr.push(['isIssue == true']);
				break;
			}
		}

		if (inIds?.length) {
      queryArr.push(inIds.map((id) => `id == "${id}"`));
		}

    return { postsQuery: realmQueryStringBuilder(queryArr) };
  }, [_postsFilters.contentType, _postsFilters.postsType, _postsFilters.inIds, isSafetyProject, selectedProjectId]);

	const [queries, setQueries] = useState(() => getPostsQuery());

	useEffect(() => {
		const newQueries = getPostsQuery();
		if (!deepEqual(newQueries, queries)) {
			setQueries(newQueries);
		}
	}, [getPostsQuery]);

	const getPostsMap = useCallback(
		(posts, changes = null, shouldRevoke = true) => {
			let arr = [];
			posts.forEach((post, index) => {
				const isPostDeleted = post.isDeleted || changes?.deletions.includes(index);
				if (isPostDeleted) return;
				const isPostModified = changes?.newModifications?.includes(index);
				const isPostAdded = changes?.insertions?.includes(index);
				const shouldRevokePost = shouldRevoke || !currViewPostsMap[post.id] || isPostModified || isPostAdded;
				arr.push(
					shouldRevokePost
						? JSON.parse(JSON.stringify(post.realmToObject())) // TODO: CEM-6163 - For the love of god, remove this as soon as possible (change usePost to give the realm cursor instead of map / array)
						: currViewPostsMap[post.id],
				);
			});

			arr = setCompanyIdAndGroupsToOwnerAndAssignToOnPosts(arr, members?.safeToJS());
			arr.sort((a, b) => (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0));

			return { map: _.keyBy(arr, 'id'), arr };
		},
		[members, currViewPostsMap],
	);
	const getRealmPostsMapRef = useRefed(getPostsMap, [getPostsMap]);

	const postsListener = useCallback((values, changes) => {
		const didChange = Boolean(changes.insertions.length || changes.deletions.length || changes.newModifications.length);
		if (didChange) {
			const { map, arr } = getRealmPostsMapRef.current(values, changes); // using a ref because the function is passed to a listener and otherwise would run with old params
			setCurrViewPostsMap(map);
			setCurrViewPosts(arr);
		}
	}, []);

	const updatePosts = useCallback(async () => {
		realmPostsRef.current = await realmInstance.posts.objects('post24').filtered(queries.postsQuery);
		const { arr, map } = getPostsMap(realmPostsRef.current);
		setCurrViewPosts(arr);
		setCurrViewPostsMap(map);

		await realmSafeAction(realmInstance.posts, () => realmPostsRef.current.addListener(postsListener));
	}, [queries, getPostsMap]);

	useEffect(() => {
    updatePosts();
    return () => realmPostsRef.current.removeListener(postsListener);
	}, [queries]);

	useEffect(() => {
		setFilteredPosts(currViewPosts);
	}, [currViewPosts]);

	const memoizedValue = useMemo(
		() => ({
			filteredPosts, // Is only used on web, it is the filtered posts from the url query (is the same as currViewPosts on native)
			currViewPosts,
			currViewPostsMap,
		}),
		[currViewPosts, currViewPostsMap, filteredPosts],
	);

	return memoizedValue;
}

export default usePosts;
