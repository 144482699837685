export const ADD_CURRENT_TAB = 'add-current-tab';
export const REMOVE_CURRENT_TAB = 'remove-current-tab';

export const setCurrentTab = (tabId) => {
  return {
    type: ADD_CURRENT_TAB,
    payload: tabId,
  };
};

export const removeCurrentTab = () => {
  return {
    type: REMOVE_CURRENT_TAB,
  };
};