import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';

export const GET_UNITS = 'GET_UNITS';
export const UNITS_LOADING = 'UNITS_LOADING';

export function getUnits(projectId, viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: 'units',
      firebasePath: `units/${projectId}`,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_UNITS, payload: { projectId, units: data } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer)
    if (result) {
      onData(result);
    }

    return { projectId };
  }

  return {
    type: UNITS_LOADING,
    payload: getPromise(),
  };
}
