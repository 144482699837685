const LAST_UPDATED_COLUMN = 'lastUpdated';
const UPDATES_COUNT_COLUMN = 'updatesCount';

const DEFAULT_MAX_ROWS = 1000;
const DEFAULT_MAX_HISTORY = 100;
const DEFAULT_TIMESTAMP_FORMAT = 'eu-EU';

/**
 * @typedef {{ id: string, [columnName: string]: any }} Row
 */

class TableLogger {
  /**
   * @type {Row[]}
   */
  #rows;

  /**
   * @typedef {Object} Config
   * @property {string} name
   * @property {string[][]} columns
   * @property {string[]} [columnsToShowOnLogs]
   * @property {boolean} [logTableOnChange]
   * @property {(a: Row, b: Row) => number} [sortRowsFunc]
   * @property {boolean} [trackHistory]
   * @property {boolean} [includeUpdatesCount]
   * @property {string} [timestampFormat]
   * @property {number} [maxRows]
   * @property {number} [maxHistory]
   *
   * @param {Config} param0
   */
  constructor({
    name,
    columns,
    logTableOnChange = false,
    sortRowsFunc = () => 0,
    trackHistory = true,
    includeUpdatesCount = true,
    columnsToShowOnLogs = [],
    timestampFormat = DEFAULT_TIMESTAMP_FORMAT,
    maxRows = DEFAULT_MAX_ROWS,
    maxHistory = DEFAULT_MAX_HISTORY,
    enabled,
  }) {
    this.#rows = [];
    this.columns = [['id', 'ID'], ...columns, [LAST_UPDATED_COLUMN, 'Last Updated']];
    this.columnsNameMap = this.columns.reduce((acc, [columnProp, columnName]) => {
      acc[columnProp] = columnName;
      return acc;
    }, {});
    if (includeUpdatesCount) {
      this.columns.push([UPDATES_COUNT_COLUMN, 'Updates Count']);
    }
    this.name = name;
    this.logTableOnChange = logTableOnChange;
    this.sortRowsFunc = sortRowsFunc;
    this.trackHistory = trackHistory;
    this.includeUpdatesCount = includeUpdatesCount;
    this.timestampFormat = timestampFormat;
    this.rowsHistory = {};
    this.maxRows = maxRows;
    this.maxHistory = maxHistory;
    this.columnsToShowOnLogs = columnsToShowOnLogs;
    this.enabled = enabled;
  }

  /**
   *
   * @param {Row} row
   * @param {string} [groupName]
   */
  upsertRow(row, groupName) {
    if (!this.enabled)
      return;

    if (this.#rows.length >= this.maxRows) {
      console.warn(`Max rows limit (${this.maxRows}) reached, not adding new row`);
      return;
    }

    const existingRow = this.#rows.find((r) => r.id === row.id);
    if (this.trackHistory) {
      if (!this.rowsHistory[row.id]) {
        this.rowsHistory[row.id] = [];
      }
      if (this.rowsHistory[row.id].length >= this.maxHistory) {
        this.rowsHistory[row.id].shift(); // Remove the oldest entry
      }
    }

    const lastUpdatedDate = new Date().toLocaleString(this.timestampFormat);
    if (this.trackHistory) {
      this.rowsHistory[row.id].push({ ...row, [LAST_UPDATED_COLUMN]: lastUpdatedDate });
    }

    if (existingRow) {
      Object.assign(existingRow, row, {
        [LAST_UPDATED_COLUMN]: lastUpdatedDate,
      }, this.includeUpdatesCount && {
        [UPDATES_COUNT_COLUMN]: existingRow[UPDATES_COUNT_COLUMN] + 1,
      });
    } else {
      this.#addRow(row);
    }
    if (this.columnsToShowOnLogs) {
      groupName = groupName + '\n' + this.columnsToShowOnLogs.map((column) => `${this.columnsNameMap[column]}: ${(existingRow || row)[column]}`).join('\n');
    }
    this.log(groupName, true);
  }

  /**
   *
   * @param {string} rowId
   */
  removeRow = (rowId) => {
    if (!this.enabled)
      return;

    this.#rows = this.#rows.filter((r) => r.id !== rowId);
    delete this.rowsHistory[rowId];
  };

  /**
   * Adds a row to the table
   * @param {Row} row - An object representing a row, where keys are column names
   */
  #addRow = (row) => {
    /**
     * @type {Row}
     */
    if (!this.enabled)
      return;

    const formattedRow = { id: row.id };
    this.columns.forEach(([column]) => {
      formattedRow[column] = row[column] || 'N/A';
    });
    formattedRow[LAST_UPDATED_COLUMN] = new Date().toLocaleString(this.timestampFormat);
    if (this.includeUpdatesCount) {
      formattedRow[UPDATES_COUNT_COLUMN] = 1;
    }
    this.#rows.push(formattedRow);
  }

  /**
   * Logs the table to the console
   * @param {string} [groupName] - The name of the group to log the table under
   */
  log(groupName, isOnChange = false) {
    if (!this.enabled)
      return;
    
    const logMessage = groupName ? `${this.name}:\n${groupName}` : this.name;
    if (isOnChange && !this.logTableOnChange) {
      console.info(logMessage);
      return;
    }
    console.groupCollapsed(logMessage);
    console.table(
      [...this.#rows].sort(this.sortRowsFunc).map((row) => {
        const formattedRow = {};
        this.columns.forEach(([column, columnName]) => {
          formattedRow[columnName] = row[column];
        });
        return formattedRow;
      })
    );
    if (this.trackHistory) {
      console.group('Rows history');
      Object.entries(this.rowsHistory)
        .sort(([_, history1], [__, history2]) => {
          return history1[history1.length - 1][LAST_UPDATED_COLUMN].localeCompare(
            history2[history2.length - 1][LAST_UPDATED_COLUMN]
          );
        })
        .forEach(([rowId, history]) => {
          console.group(rowId);
          console.table(
            history.map((row) => {
              const formattedRow = {};
              this.columns.forEach(([column, columnName]) => {
                formattedRow[columnName] = row[column];
              });
              return formattedRow;
            })
          );
          console.groupEnd();
        });
      console.groupEnd();
    }
    console.groupEnd();
  }
}

export default TableLogger;
