import { Navigate } from 'react-router-dom';

export default [
  {
    index: true,
    element: <Navigate to={'safety/analytics'} />,
  },
  {
    path: '_',
    element: <Navigate replace to={'../home'} />,
  },
];
