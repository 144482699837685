import { getValidAuth0Token } from "../../auth/actions";
import ExtraError from "../errors/extraError";
import { envParams } from "../../configureMiddleware";
import jwtDecode from "jwt-decode";
import { jws, KEYUTIL} from 'jsrsasign'

export const FIREBASE_OFF_QUERY = "FIREBASE_OFF_QUERY";
export const FIREBASE_ON_AUTH = "FIREBASE_ON_AUTH";
//export const GET_FCM_TOKEN = 'GET_FCM_TOKEN';

export const FIREBASE_ON_PERMISSION_DENIED = "FIREBASE_ON_PERMISSION_DENIED";
export const FIREBASE_ON_QUERY = "FIREBASE_ON_QUERY";
export const FIREBASE_RESET_PASSWORD_ERROR = "FIREBASE_RESET_PASSWORD_ERROR";
export const FIREBASE_RESET_PASSWORD_START = "FIREBASE_RESET_PASSWORD_START";
export const FIREBASE_RESET_PASSWORD_SUCCESS =
  "FIREBASE_RESET_PASSWORD_SUCCESS";
export const FIREBASE_SIGN_IN_ERROR = "FIREBASE_SIGN_IN_ERROR";
export const FIREBASE_SIGN_IN_START = "FIREBASE_SIGN_IN_START";
export const FIREBASE_SIGN_IN_SUCCESS = "FIREBASE_SIGN_IN_SUCCESS";
export const FIREBASE_SIGN_UP_ERROR = "FIREBASE_SIGN_UP_ERROR";
export const FIREBASE_SIGN_UP_START = "FIREBASE_SIGN_UP_START";
export const FIREBASE_SIGN_UP_SUCCESS = "FIREBASE_SIGN_UP_SUCCESS";
export const FIREBASE_START = "FIREBASE_START";
export const FIREBASE_NO_USER_DATA = "FIREBASE_NO_USER_DATA";
export const FIREBASE_LOGGEDIN_VALIDATOR = "FIREBASE_LOGGEDIN_VALIDATOR";

export const AUTH0_FIREBASE_DELEGETION_REQUEST =
  "AUTH0_FIREBASE_DELEGETION_REQUEST";

export const FCM_TOKEN_REQUEST = "FCM_TOKEN_REQUEST";
export const FCM_TOKEN_SAVED = "FCM_TOKEN_SAVED";
export const FCM_TOKEN_UPDATE_START = "FCM_TOKEN_UPDATE_START";
export const FCM_TOKEN_NOT_SAVED = "FCM_TOKEN_NOT_SAVED";
export const FCM_TOKEN_ALREADY_UPDATED = "FCM_TOKEN_ALREADY_UPDATED";
export const DELEGATION_TOKEN_RECIVED = "DELEGATION_TOKEN_RECIVED";

//export const AUTH0_ON_AUTH = 'AUTH0_ON_AUTH';
export const SIGN_OUT = "SIGN_OUT";

let lastUserLoggedInStatus = null;
export function firebaseStart() {
  return ({ dispatch, firebaseAuth }) => {
    firebaseAuth().onAuthStateChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        dispatch({ type: FIREBASE_NO_USER_DATA });
        return;
      }

      var userLoggedInStatus = Boolean(firebaseAuth().currentUser);
      if (lastUserLoggedInStatus === userLoggedInStatus) return;

      lastUserLoggedInStatus = userLoggedInStatus;

      setTimeout(() => {
        dispatch({ type: FIREBASE_ON_AUTH });
      }, 50); 

      //dispatch(checkAndUpdateSettingsFromDB());

      // // Maybe this should be here only on web
      // // Init trades and title
      // var settingsLastUpdates = (await dispatch(checkSettingsLastUpdate())) || {};
      // if (settingsLastUpdates) {
      //   var titlesLastUpdateTS = getState().titles.lastUpdateTS;
      //   var tradesLastUpdateTS = getState().trades.lastUpdateTS;
      //   var quasiStaticsLastUpdateTS = getState().quasiStatics.lastUpdateTS;
      //   var propertiesTypesLastUpdateTS = getState().propertiesTypes.lastUpdateTS;
      //   var permissionsLastUpdateTS = getState().permissions.lastUpdateTS;

      //   if (settingsLastUpdates.getNested(['snapshot', 'permissions', 'v3', 'lastUpdateTS'], 0) > permissionsLastUpdateTS || !getState().permissions.map || !getState().permissions.map.size) dispatch(getPermissions(settingsLastUpdates.getNested(['snapshot', 'permissions', 'v3', 'lastUpdateTS'], 0)));
      //   if (settingsLastUpdates.getNested(['snapshot', 'properties-types', 'lastUpdateTS'], 0) > propertiesTypesLastUpdateTS /*|| !getState().propertiesTypes.globalProperties.size*/) dispatch(getPropertiesTypes('global', settingsLastUpdates.getNested(['snapshot', 'properties-types', 'lastUpdateTS'], 0)));
      //   if (settingsLastUpdates.getNested(['snapshot', 'titles', 'lastUpdateTS'], 0) > titlesLastUpdateTS || !getState().titles.map || !getState().titles.map.size) dispatch(getTitles(settingsLastUpdates.getNested(['snapshot', 'titles', 'lastUpdateTS'], 0)));
      //   if (settingsLastUpdates.getNested(['snapshot', 'trades', 'lastUpdateTS'], 0) > tradesLastUpdateTS || !getState().trades.map || !getState().trades.map.size) {
      //     dispatch(getTrades(settingsLastUpdates.getNested(['snapshot', 'trades', 'lastUpdateTS'], 0)));
      //   }
      //   if (settingsLastUpdates.getNested(['snapshot', 'quasiStatics', 'lastUpdateTS'], 0) > quasiStaticsLastUpdateTS || !getState().quasiStatics.map || !getState().quasiStatics.map.size) dispatch(getQuasiStatics(settingsLastUpdates.getNested(['snapshot', 'quasiStatics', 'lastUpdateTS'], 0)));
      // }
    });

    return { type: FIREBASE_START };
  };
}

export function startAuthLogin(phoneNumber, inId_token) {
  return ({ firebaseAuth, dispatch, platformActions, auth0 }) => {
    const getPromise = async () => {
      if (!inId_token)
        throw new ExtraError("auth0: startAuthLogin error - no id_token", {
          phoneNumber,
        });

      let targetClient =
        platformActions.app.getPlatform() == "web"
          ? auth0.web_client_id
          : auth0.mobile_client_id;

      let connectionStatus = "notConnected";
      let id_token = null;

      try {
        let ret = await dispatch(getValidAuth0Token(inId_token));
        if (!ret || !ret.id_token) throw new ExtraError("No id_token given");

        id_token = ret.id_token;

        let resp = await platformActions.net.fetch(auth0.url + '/delegation', {
					method: 'POST',
					body: JSON.stringify({
						id_token: id_token,
						client_id: targetClient,
						grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
						target: targetClient,
						scope: 'openid',
						api_type: 'firebase',
					}),
				});
        let response = await resp.getJson();
        if (!response.id_token)
          throw new ExtraError(
            "startAuthLogin response error - Missing id_token"
          );

        let firebaseUser;

        try {
					firebaseUser = await firebaseAuth().signInWithCustomToken(
						response.id_token,
					);
				} catch (error) {
					throw new ExtraError('auth0: smsSignIn error', { phoneNumber, token: inId_token }, error);
				}
				
				if (firebaseUser && firebaseUser.user && firebaseUser.user.uid) {
					connectionStatus = 'connected';
					dispatch({ type: FIREBASE_ON_AUTH, payload: { user: firebaseUser } });
				}

        dispatch({
          type: DELEGATION_TOKEN_RECIVED,
          payload: { token: response.id_token },
        });
      } catch (err) {
        console.log("auth0: Logged in error:" + err);
        console.log(err);
        throw new ExtraError(
          "startAuthLogin",
          {
            url: auth0.url,
            phoneNumber,
            id_token,
            default_app_client_id: auth0.default_app_client_id,
            target: targetClient,
          },
          err
        );
      } finally {
        return connectionStatus;
      }
    };
    return {
      type: AUTH0_FIREBASE_DELEGETION_REQUEST,
      payload: getPromise(),
    };
  };
}

export function firebaseLoggedInValidation() {
  return ({ firebaseAuth }) => {
    const firebaseConnected = Boolean(firebaseAuth().currentUser);

    return {
      type: FIREBASE_LOGGEDIN_VALIDATOR,
      payload: { firebaseConnected, immediateGlobalStorageSave: true },
    };
  };
}

export function retriveFCMToken() {
  return ({ dispatch, firebaseMessaging }) => {
    firebaseMessaging()
      .getToken()
      .then((token) => {
        // store fcm token in your server
        dispatch(upsetRegistrationToken(token));
      });

    return {
      type: FCM_TOKEN_REQUEST,
    };
  };
}

export function upsetRegistrationToken(registrationToken) {
  return ({ getState, platformActions }) => {
    const getPromise = async () => {
      let userId = getState().getNested(
        ["users", "viewer", "id"],
        getState().auth.userId
      );
      let authToken = getState().auth.authToken;
      if (!getState().getNested(["users", "viewer", "displayName"]))
        throw new ExtraError("User did not yet registered to cemento", {
          userId,
        });

      if (!registrationToken || !userId)
        throw new ExtraError(
          "No registrationToken value given on upsetRegistrationToken",
          { userId, authToken }
        );

      if (registrationToken == getState().auth.fCMRegistrationToken)
        return { noUpdate: true };

      let lang = platformActions.app.getLang();
      let bodyString = JSON.stringify({
        userDetails: {
          registration_token: registrationToken,
          id: userId,
          lang,
        },
      });

      try {
        let response = await platformActions.net.fetch(
          encodeURI(`${envParams.apiServer}/v1/users/${userId}`),
          {
            method: "PATCH",
            body: bodyString,
          }
        );

        if (!response)
          throw new ExtraError("no response on registrationToken", {
            registrationToken,
            authToken,
            userId,
          });

        if (response.status != 200 || !response.ok) {
          throw new ExtraError("registrationToken", {
            registrationToken,
            responseStatus: response.status,
            responseOk: response.ok,
            authToken,
            userId,
          });
        }

        // let data = JSON.parse(response.body)
        // if (data.error) {
        //   throw new ExtraError('registrationToken', {registrationToken, response:response.status, authToken, userId});
        // }

        return { registrationToken, immediateGlobalStorageSave: true };
      } catch (error) {
        throw new ExtraError("registrationToken", { registrationToken }, error);
      }
    };
    return {
      type: FCM_TOKEN_SAVED,
      payload: getPromise(),
    };
  };
}

export function saveNewRegistrationToken(registrationToken) {
  // TODO: Think of a better name...
  return ({ dispatch }) => {
    dispatch(upsetRegistrationToken(registrationToken));

    return {
      type: FCM_TOKEN_UPDATE_START,
      payload: { registrationToken },
    };
  };
}
