import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import Text from "./Text";
import { getUserByUid } from "../../../common/members/actions";

class DisplayName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userTitle: null,
      userTrades: null,
      userComapanyName: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { getUserByUid } = nextProps;

    let newStateChanges = {};
    if (!nextProps.userId) return null;

    let isViewer = nextProps.userId === (nextProps.viewer || {}).id;

    if (
      nextProps.userId &&
      !nextProps.getNested(["members", nextProps.userId])
    ) {
      getUserByUid(nextProps.userId);
    } else if (
      props.userId != nextProps.userId ||
      (!isViewer &&
        props.getNested(["members", nextProps.userId]) !=
          nextProps.getNested(["members", nextProps.userId])) ||
      (isViewer &&
        props.getNested(["viewer"]) != nextProps.getNested(["viewer"]))
    ) {
      newStateChanges.user = nextProps.getNested(
        ["members", nextProps.userId],
        {}
      );
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const { user } = this.state;
    const { message, messageValueProperty, style } = this.props;

    return (
      <Text style={style} values={{ [messageValueProperty]: user.displayName }}>
        {message || user.displayName}
      </Text>
    );
  }
}

const enhance = compose(
  connect(
    (state) => ({
      viewer: state.users.viewer,
      members: state.members.map,
    }),
    { getUserByUid }
  )
);
export default enhance(DisplayName);
