import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';

export const GET_BUILDINGS = 'GET_BUILDINGS';
export const BUILDINGS_LOADING = 'BUILDINGS_LOADING';

export function getBuildings(projectId, viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: 'buildings',
      firebasePath: `buildings/${projectId}`,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_BUILDINGS, payload: { projectId, buildings: data } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer)
    if (result) {
      onData(result);
    }

    return { projectId };
  }

  return {
    type: BUILDINGS_LOADING,
    payload: getPromise(),
  };
}
