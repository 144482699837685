import { Range } from 'immutable';
import { Map, OrderedMap } from 'immutable';
import { getAppState } from '../configureMiddleware';
import * as companyTypes from '../companies/companiesTypes';
import * as projectStages from '../projects/projectStages';
import _ from 'lodash';
import { deepEqual, isBooleanString, isIndexBased, isNullString, isUndefinedString, parseBooleanString } from '../app/funcs';
import {decodeFiltersFromSearch} from '../../web/app/funcs';

const PERMANENT_FUNCTIONS_KEY = "permFuncs";
const NESTED_TERMS_KEY = "nested";
const ALLOW_ALL_PERMISSION_KEY = 'allowAll';

export function isViewerPermissionsChanged(oldViewer, newViewer, oldCompanies, newCompanies) {
  
  //if (oldViewer == newViewer && oldCompanies == newCompanies)
  if (oldViewer == newViewer)
    return false;

  if (!oldViewer || !newViewer)
    return false;
  
  if (oldViewer.adminMode != newViewer.adminMode)
    return true;
  
  let isViewerPermissionParamsChanged =
    oldViewer.companyId != newViewer.companyId ||
    !deepEqual(oldViewer.groups, newViewer.groups) ||
    !deepEqual(oldViewer.trades, newViewer.trades);

  (newViewer.projects || {}).loopEach((projectId, newProjectScope) => {
    isViewerPermissionParamsChanged = isViewerPermissionParamsChanged || 
      oldViewer.getNested(['projects', projectId, 'companyId']) != newProjectScope.companyId ||
      !deepEqual(oldViewer.getNested(['projects', projectId,'groups']), newProjectScope.groups) ||
      !deepEqual(oldViewer.getNested(['projects', projectId,'trades']), newProjectScope.trades);

    isViewerPermissionParamsChanged = isViewerPermissionParamsChanged || 
                                     (newViewer.getNested(['projects', projectId, 'companyId']) != oldViewer.getNested(['projects', projectId, 'companyId']))

    // let globalCompanyId = newViewer.companyId;
    // if (!isViewrPermissionParamsChanged && globalCompanyId)
    //   isViewrPermissionParamsChanged = isObjectsEqualShallow(newCompanies.getNested([globalCompanyId, 'trades'])) != isObjectsEqualShallow(oldCompanies.getNested([globalCompanyId, 'trades']));
                                   
    // let projectCompanyId = (newViewer.getNested(['projects', projectId, 'companyId']));
    // if (!isViewrPermissionParamsChanged && projectCompanyId)  
    //   isViewrPermissionParamsChanged = newCompanies.getNested(projectCompanyId) != oldCompanies.getNested(projectCompanyId);
  })

  return isViewerPermissionParamsChanged;
}

var viewerAndTarget_sameCompany = function(viewer, target, projectId, targetType, checkTargetOwnerCompany, checkTargetAssigneeCompany) {
  if (!viewer || !target)
    return false;
  
  let targetIds = {};
  let id;
  if (checkTargetOwnerCompany) {
    let owner = getOwner(target, targetType, projectId) 
    if (owner) {
      id = getAppState().getNested(['members', 'map', owner.id, 'projects', projectId, 'companyId']) || 
           getAppState().getNested(['members', 'map', owner.id, 'companyId']) ||
           owner.id;
    }
  }
  else if (checkTargetAssigneeCompany) {
    let assignee = getAssignee(target, targetType, projectId) 
    if (assignee)
      id = getAppState().getNested(['members', 'map', assignee.id, 'projects', projectId, 'companyId']) || 
           getAppState().getNested(['members', 'map', assignee.id, 'companyId']) || 
           assignee.id;
  }
  else
    id = getCompanyId(target, targetType, projectId); 

  if (typeof id === "object")
    targetIds = id;
  else
    targetIds[id] = { id };

  let viewerId = getCompanyId(viewer, 'user', projectId) || viewer.id; 
  let ret = (isIn(viewerId, targetIds) || 
    Boolean(targetType == "post" && targetIds && targetIds[companyTypes.unknownCompanyId] && isIn(viewer.id, targetIds[companyTypes.unknownCompanyId])));
  return ret;
}

export function checklitItemActionsPermission(viewer, target, projectId, permissionKey, actionKey) {
  var viewerCompId = getCompanyId(viewer, 'user', projectId);
  let explicitProjectActionSettingsExists = Boolean(getAppState().getNested(['projects', 'map', projectId, 'permissions', permissionKey, actionKey]));
  let explicitSettingsMatchToViewer = explicitProjectActionSettingsExists && Boolean(getAppState().getNested(['projects', 'map', projectId, 'permissions', permissionKey, actionKey, 'companies', viewerCompId]));
  let ret = false;

  if (actionKey == 'resolve') {
    var viewerCompTypes = getCompanyTypes(viewerCompId, projectId)
    var isViewerSub = !viewerCompId || viewerCompTypes[companyTypes.COMPANY_TYPES_SUB];
    ret = ((!explicitProjectActionSettingsExists && isViewerSub) || explicitSettingsMatchToViewer);
  }
  else if (actionKey == 'irrelevant') {
    ret = (explicitSettingsMatchToViewer && target.getNested(['permissions', 'actions', 'irrelevant']));
  }
  else if (actionKey == 'partial') 
    ret = (explicitSettingsMatchToViewer && target.getNested(['permissions', 'actions', 'partial']));
  else if (actionKey == 'confirm') 
    ret = (explicitSettingsMatchToViewer);
  else if (actionKey == 'confirm2') 
    ret = (explicitSettingsMatchToViewer);
 
  return Boolean(ret);
}

var viewerAndTaget_sameCompanyHierarcyBelow = function(viewer, target, projectId, targetType, checkTargetOwnerCompany, checkTargetAssigneeCompany) {
  return viewerAndTarget_sameCompany(viewer, target, projectId, targetType, checkTargetOwnerCompany, checkTargetAssigneeCompany);
}

var viewerAndTaget_sameCompanyHierarcyAbove = function(viewer, target, projectId, targetType, checkTargetOwnerCompany, checkTargetAssigneeCompany) {
  return viewerAndTarget_sameCompany(viewer, target, projectId, targetType, checkTargetOwnerCompany, checkTargetAssigneeCompany);
}

var targetTradeSameAsViewerCompany = function(viewer, target, projectId, targetType) {
  var wantedTrades = viewer.trades;
  var companyId = viewer.companyId;
  if (companyId) 
    wantedTrades = getTrades({ id: companyId }, 'company', projectId);
  else
    wantedTrades = getTrades(viewer, 'user', projectId);

  let targetTrades = getTrades(target, targetType, projectId);
  var sameTrades = isIn(wantedTrades, targetTrades);
  return sameTrades;
}

var isPermitablePropertyType = function(target, permissionKey, actionKey) {
  let actionPermissions = getAppState().getNested(['permissions', 'map', permissionKey, 'actions', actionKey], []);
  let checkObj = { target };
  let perm = true;
  actionPermissions.forEach(curr => {
    curr.loopEach((keyPath, valuePath) => {
      let path = keyPath.split('-');
      perm = perm && (checkObj.getNested(path) != valuePath);
    })
  })
  return perm;
}

// SHPIRA: WTF?!
var projectMembers = function(viewer, target, targetType) {
  return false;
}

var isGcCompany = function(viewer, target, projectId, targetType) {
  return companyTypeEqualsTo(viewer, target, projectId, targetType, companyTypes.COMPANY_TYPES_GC);
}

var isSubCompany = function(viewer, target, projectId, targetType) {
  return companyTypeEqualsTo(viewer, target, projectId, targetType, companyTypes.COMPANY_TYPES_SUB);
}

var companyTypeEqualsTo = function(viewer, target, projectId, targetType, companyTypeToCheck) {
  var typeFound = false;
  var companyId = null;
  if (target) companyId = getCompanyId(target, targetType, projectId);
  else if (viewer) companyId = getCompanyId(viewer, targetType, projectId);

  if (companyId) {
    let currCompanyTypes = getCompanyTypes(companyId, projectId);
    typeFound = Boolean(currCompanyTypes[companyTypeToCheck])
  }
  else if (!companyId && companyTypeToCheck == companyTypes.COMPANY_TYPES_SUB)
    typeFound = true;

  return Boolean(typeFound);
}

const isIn = function(a, b) {
  let isIn = 
    Boolean(!isIndexBased(b) && _.get(b, [a])) ||
    Boolean(!isIndexBased(a) && _.get(a, [b]));

  if (!isIn) 
    if (Array.isArray(a) && Array.isArray(b))
      Object.entries(safeToJS(a) || []).forEach(([i,v]) => isIn = isIn || isInArray(v, b));
    else
      isIn = isInArray(a, b);

  return isIn;
}

var isInArray = function(a, b) {
  var isInArray = false;
  let array = [];
  let val = null;
  if (Array.isArray(a)) {
    array = a;
    val = b;
  } else if (Array.isArray(b)) {
    array = b;
    val = a;
  } else 
    return false;

  Object.entries(safeToJS(array) || []).every(([i,v]) => { // using every because it breaks the loop on return false;
    isInArray = v == val || isIn(v, val);
    return !isInArray; // false STOPS THE LOOP
  });

  return isInArray;
}

const preDefPermissionFunctions = function({ funcName, viewer, target, projectId, targetType, permissionKey, actionKey }) {
  let ret = false;
  
  switch (funcName) {
    case "viewerAndTaget_sameCompanyHierarcyAbove": { ret = viewerAndTaget_sameCompanyHierarcyAbove(viewer, target, projectId, targetType, false, false); break; }
    case "viewerAndTaget_sameCompanyHierarcyBelow": { ret = viewerAndTaget_sameCompanyHierarcyBelow(viewer, target, projectId, targetType, false, false); break; }
    case "viewerAndTarget_sameCompany": { ret = viewerAndTarget_sameCompany(viewer, target, false, projectId, targetType); break; }
    case "targetTradeSameAsViewerCompany": { ret = targetTradeSameAsViewerCompany(viewer, target, projectId, targetType); break; }
    case "projectMembers": { ret = projectMembers(viewer, target, targetType); break; }
    case "target_isSubCompany":   { ret = isSubCompany(null, target, projectId, targetType); break; }
    case "viewer_isSubCompany":   { ret = isSubCompany(viewer, null, projectId, targetType); break; }
    case "target_isGcCompany": { ret = isGcCompany(null, target, projectId, targetType); break; }
    case "viewer_isGcCompany":   { ret = isGcCompany(viewer, null, projectId, targetType); break; }
    case "viewerAndTagetOwner_sameCompanyHierarcyAbove": { ret = viewerAndTaget_sameCompanyHierarcyAbove(viewer, target, projectId, targetType, true, false); break; }
    case "viewerAndTagetOwner_sameCompanyHierarcyBelow": { ret = viewerAndTaget_sameCompanyHierarcyBelow(viewer, target, projectId, targetType, true, false); break; }
    case "viewerAndTagetAssignee_sameCompanyHierarcyAbove": { ret = viewerAndTaget_sameCompanyHierarcyAbove(viewer, target, projectId, targetType, false, true); break; }
    case "viewerAndTagetAssignee_sameCompanyHierarcyBelow": { ret = viewerAndTaget_sameCompanyHierarcyBelow(viewer, target, projectId, targetType, false, true); break; }
    case "checklitItemActionsPermission" : { ret = checklitItemActionsPermission(viewer, target, projectId, permissionKey, actionKey); break; }
    case "isPermitablePropertyType" : { ret = isPermitablePropertyType(target, permissionKey, actionKey); break; }
  }

  return ret;
}

export function getFilteredMap(viewer, projectId, permissionKey, actionKey, targetList, targetType, forcePermission) {
  return getPermissions(viewer, projectId, permissionKey, actionKey, targetList || [{}], targetType, forcePermission)
}
export function isPermitted(viewer, projectId, permissionKey, actionKey, targetObject, targetType) {
  return (Object.keys(getPermissions(viewer, projectId, permissionKey, actionKey,  [ targetObject ], targetType).permitted).length > 0)
}
export function getActionPermissions(viewer, projectId, permissionKey, actionKey, target) {
  return (Object.keys(getPermissions(viewer, projectId, permissionKey, actionKey, [target || {}], null).permitted).length > 0)
}

/**
 * 
 * @param {any} operand 
 * @returns
 */
const parseOperand = operand => {
  let parsedOperand = operand;

  if (isBooleanString(operand))
    parsedOperand = parseBooleanString(operand);
  else if (isUndefinedString(operand))
    parsedOperand = undefined;
  else if (isNullString(operand))
    parsedOperand = null;

  return parsedOperand;
}

function splitSignFromOperand(op) {
  if (Array.isArray(op)) {
    return {
      sign: true,
      operand: op.map(o => parseOperand(o)),
    };
  }

  let temp = op.toString().split('!');
  let sign = temp.length == 1;
  let operand = parseOperand(temp.length == 1 ? temp[0] : temp[1]);
    
  return { sign, operand };
}

export const safeToJS = (obj) => {
  let ret = null;
  try {
    ret = obj && obj.toJS ? obj.toJS() : obj;
  } catch (error) {
    throw error;
  }
  finally {
    return ret;
  }
}

export const DEFAULT_PERMISSIONS_PATH_DELIMETER = '-';
export function getFilteredResults({
                                     cementoQuery,
                                     targetList,
                                     extraCheckObjects = {},
                                     permFuncParams = {},
                                     forcePermission = false,
                                     pathDelimiter = DEFAULT_PERMISSIONS_PATH_DELIMETER
}) {
  let map = {};
  let unPermittedMap = {};
  let isPermitted = false;

  if (cementoQuery) {
    Object.entries(safeToJS(targetList) || []).forEach(([targetIndex, target]) => {
      let checkObjects = { target, ...extraCheckObjects };
      isPermitted = Boolean(forcePermission);
      if (!isPermitted) 
        isPermitted = isObjectPermitted(
          cementoQuery,
          checkObjects,
          {
            ...permFuncParams,
            target
          },
          pathDelimiter);

      if (isPermitted)
        map[targetIndex] = target;
      else 
        unPermittedMap[targetIndex] = target;
    });
  }

  return { permitted: map, unpermitted: unPermittedMap };
}

export const filterByQuery = (rows, locationSearch, filterUrlKey, filterPathDelimeter, isCollapsableTable, parentsRow) => {
  let filteredAllRows = {};
  const cementoQuery = decodeFiltersFromSearch(
    locationSearch,
    filterUrlKey || undefined,
    filterPathDelimeter || undefined,
  ).cementoQuery;
  if (cementoQuery) {
    filteredAllRows = {};
    const permittedMap = getFilteredResults({
      cementoQuery,
      targetList: rows,
      pathDelimiter: filterPathDelimeter || undefined,
    }).permitted;
  
    _.values(permittedMap).forEach(r => {
      if (r.parentId) filteredAllRows[r.parentId] = rows[r.parentId];
      filteredAllRows[r.id] = r;
    });
  } else {
    filteredAllRows = Array.isArray(rows) ? _.keyBy(rows, 'id') : rows;
  }
  
  return filteredAllRows;
};

export const isObjectPermitted = (cementoQuery, checkObjects, permFuncParams = {}, pathDelimiter = DEFAULT_PERMISSIONS_PATH_DELIMETER) => {
  let isPermitted = false;

  Object.entries(safeToJS(cementoQuery) || []).every(([index, value]) => { // using every because it break loop on return false
    let predicate = false; 
    if (value) {
      predicate = true; 
      // Multiple permission properties calculated as "AND" operations.
      Object.entries(safeToJS(value) || {}).every(([keyPath, valPath]) => { // using every because it break loop on return false
        if (keyPath == PERMANENT_FUNCTIONS_KEY) {
          Object.entries(safeToJS(valPath) || []).every(([i, funcName]) => { // using every because it break loop on return false
            let { operand, sign } = splitSignFromOperand(funcName);
            let funcResult = preDefPermissionFunctions({
              ...permFuncParams,
              funcName: operand
            });
            predicate = (funcResult == sign);
            return predicate  // 'FALSE' => ENDS THE LOOP
          });
        }
        else if (keyPath == ALLOW_ALL_PERMISSION_KEY)
          predicate = true;
        else if (keyPath.includes(NESTED_TERMS_KEY)) {
          const recursiveResult = isObjectPermitted(valPath, checkObjects, permFuncParams, pathDelimiter);
          predicate = recursiveResult;
        } else if (_.isObject(valPath) && !_.isArray(valPath)) {
          const operandA = _.get(checkObjects, keyPath.split(pathDelimiter));

          _.keys(valPath).forEach(logicalOperation => {
            if (logicalOperation === 'AND') {
              predicate = valPath[logicalOperation].reduce((result, expression) => {
                if (!result) {
                  return result;
                }

                return result && processComparison(operandA, expression);
              }, true);
            }
          });
        } else {
          let { operand, sign } = splitSignFromOperand(valPath);
          let operandA = _.get(checkObjects, keyPath.split(pathDelimiter));
          let operandB = Array.isArray(operand) 
                          ? operand 
                          : _.get(checkObjects, valPath.toString().split(pathDelimiter), operand);
          predicate = Boolean(
            processComparison(operandA, operandB) ||
            isIn(operandB, operandA)
          );
          predicate = (predicate == sign)
        }

        return (predicate) // 'FALSE' => ENDS THE LOOP
      });
    }
    isPermitted = isPermitted || predicate;
    
    return (!isPermitted) // 'FALSE' => ENDS THE LOOP
  });

  return isPermitted;
}
/*
  @param {number} - target object value
  @param {string | number} b - complex value from filter, e.g. '>123', '<145', '15', 23
*/
const processComparison = (a, exp) => {
  const {operation, operand: b} = parseOperation(exp);

  switch (operation) {
    case '<':
      return a < b;
    case '>':
      return a > b;
    default:
      return a === b;
  }
}
const parseOperation = (val) => {
  if (_.isString(val) && (val.startsWith('>') || val.startsWith('<'))) {
    return {
      operation: val[0],
      operand: Number(val.substring(1)),
    }
  }
  return {
    operand: val,
  }
}

// TODO: Find better solution to know the objects types instead of sending targetType parameter
function getPermissions(viewer, projectId, permissionKey, actionKey, targetList, targetType, forcePermission, print) {
  let permissionsPath = ['permissions', 'map', permissionKey, 'actions'];
  permissionsPath.push(..._.isArray(actionKey) ? actionKey : [actionKey])
  let actionPermissions = getAppState().getNested(permissionsPath);
  let ret = { permitted: {}, unpermitted: {} };
  if (viewer && actionPermissions)
    ret = getFilteredResults({ 
      cementoQuery: actionPermissions, 
      targetList, 
      extraCheckObjects: { viewer }, 
      permFuncParams: { projectId, targetType, actionKey, viewer, permissionKey }, 
      forcePermission: Boolean(viewer.adminMode > 0 || forcePermission) 
    });
  
  return ret;
}


///////////////////////////////////////////////////////
////////////////// "interfaces get" ///////////////////
///////////////////////////////////////////////////////
function getCompanyId(obj, objTypeName, scopeKey) {
  switch (obj.__proto__._name || objTypeName) {
    case "company": return obj.id;
    case "member": return getMemberCompanyId(obj, scopeKey);
    case "user": return getViewerCompanyId(scopeKey);
    case "post": return obj.taggedCompanies;
    default: return null;
  }
};

function getTrades(obj, objTypeName, scopeKey) {
  switch (obj.__proto__._name || objTypeName) {
    case "checklistItem": return obj.trade;
    case "company": return getCompanyTrades(obj.id, scopeKey);
    case "member": return getMemberTrades(obj.id, scopeKey);
    case "user": return getViewerTrades(obj.id, scopeKey);
    case "post": return obj.trade;
    default: return null;
  }
};

function getOwner(obj, objTypeName, scopeKey) {
  switch (obj.__proto__._name || objTypeName) {
    case "post": return obj.owner;
    default: return null;
  }
};

function getAssignee(obj, objTypeName, scopeKey) {
  switch (obj.__proto__._name || objTypeName) {
    case "post": return obj.assignTo;
    default: return null;
  }
};

function getMemberCompanyId(member, projectId) {
  var targetCompanyId = 
    getAppState().getNested(['members', 'map', member.id, 'projects', projectId, 'companyId'], 
    getAppState().getNested(['members', 'map', member.id, 'companyId']));
  return targetCompanyId;
}

function getViewerCompanyId(projectId) {
  var targetCompanyId = 
    getAppState().getNested(['users', 'viewer', 'projects', projectId, 'companyId'], 
    getAppState().getNested(['users', 'viewer', 'companyId']));
  return targetCompanyId;
}

function getCompanyTrades(companyId, projectId) {
  var trades = 
    getAppState().getNested(['companies', 'map', companyId, 'projects', projectId, 'trades'], 
    getAppState().getNested(['companies', 'map', companyId, 'trades']));
  return trades;
}

function getCompanyTypes(companyId, projectId) {
  var defaultType = {};
  defaultType[companyTypes.COMPANY_TYPES_SUB] = companyTypes.COMPANY_TYPES_SUB;

  var trades = 
    getAppState().getNested(['companies', 'map', companyId, 'projects', projectId, 'types'], 
    getAppState().getNested(['companies', 'map', companyId, 'types'], defaultType));
  return trades;
}

function getMemberTrades(memberId, projectId) {
  var trades = 
    getAppState().getNested(['members', 'map', memberId, 'projects', projectId, 'trades'], 
    getAppState().getNested(['members', 'map', memberId, 'trades']));
  return trades;
}

function getViewerTrades(target, projectId) {
  var trades = 
    getAppState().getNested(['users', 'viewer', 'projects', projectId, 'trades'], 
    getAppState().getNested(['users', 'viewer', 'trades']));
  return trades;
}

export function viewerMathConditions(viewer, viewerPermissionsArr) {
  if (!viewerPermissionsArr || !viewer) return false;
  if (viewerPermissionsArr.length === 0) return true;

  return Boolean(viewerPermissionsArr
    .map(conditions => 
      Object.entries(conditions).map(([pathInViewer, targetVal]) =>
        viewer.getNested(pathInViewer.split('-')) == targetVal
      ).filter(Boolean).length)
    .filter(Boolean).length);
}