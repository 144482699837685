import React from "react";
import { connectContext } from 'react-connect-context';
import { columnTypes } from '../../../common/analytics/funcs'; 
import { ReportContext } from '../../../common/analytics/contexts'; 

// Assets
import theme from "../../assets/css/theme";
const timePerColumn = 0.15;

export const newColumnWidth = 126;
export const columnColor = 'white';

const visiblyStates = {hide: 0, startShowProcess:1, inShowProcess: 2, inHideProcess: 3, show: 4}

class TableExpandColumn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.changeVisibility = this.changeVisibility.bind(this);
    this.buildData = this.buildData.bind(this);
  }

  didVisibleRowChanged(props, nextProps) {
    if ((props.rowsWithValues == nextProps.rowsWithValues))
      return false;

    let didFoundDiff = false;
    Object.entries(nextProps.rowsWithValues).forEach(([key, val]) => { didFoundDiff = didFoundDiff || props.rowsWithValues[key] != val});
    return didFoundDiff;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const visible = Boolean(nextProps.expandedColumns) && nextProps.expandedColumns == nextProps.column.mainColumnId;
    const prevVisible = Boolean(this.props.expandedColumns) && this.props.expandedColumns == this.props.column.mainColumnId;
    if (nextProps.afterExpandOrFold && nextState.visible != this.state.visible && (visiblyStates.show == nextState.visible || visiblyStates.hide == nextState.visible)) 
      nextProps.afterExpandOrFold(nextState.visible);
    let shouldComponentUpdate = (visible != prevVisible) || (nextState != this.state) || (this.props.visibleRows != nextProps.visibleRows) || (this.props.column != nextProps.column) || (this.props.rowsWithValues != nextProps.rowsWithValues);
    return shouldComponentUpdate;
  }

  UNSAFE_componentWillMount() {
    this.setState({visible:visiblyStates.hide});
    this.buildData(visiblyStates.hide);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const visible = Boolean(nextProps.expandedColumns) && nextProps.expandedColumns == nextProps.column.mainColumnId;
    const prevVisible = Boolean(this.props.expandedColumns) && this.props.expandedColumns == this.props.column.mainColumnId;
    
    if (this.props.visibleRows != nextProps.visibleRows || (this.props.column != nextProps.column) || (this.props.rowsWithValues != nextProps.rowsWithValues))
      this.buildData(visible, nextProps);

    if (!prevVisible && visible)
      this.changeVisibility(true);
    else if (prevVisible && !visible)
      this.changeVisibility(false);
  }

  changeVisibility(visible) {
    const { subColumns } = this.props;
    const numOfColumns = Object.values(subColumns || {}).length;
    const animationTime = timePerColumn * numOfColumns;
    
    if (visible) {
      this.buildData(visiblyStates.show);
      setTimeout(() => {
        this.setState({ visible: visiblyStates.inShowProcess });
      }, 20);

      setTimeout(() => {
        if (this.state.visible == visiblyStates.inShowProcess)
        this.setState({ visible: visiblyStates.show });
      }, 20 + animationTime * 1000);

      this.setState({visible: visiblyStates.startShowProcess});
    }
    else {
      this.buildData(visiblyStates.inHideProcess);
      setTimeout(() => {
        if (this.state.visible == visiblyStates.inHideProcess) {
          this.buildData(visiblyStates.hide);
          this.setState({ visible: visiblyStates.hide });
        }
      }, animationTime * 1000);
      this.setState({ visible: visiblyStates.inHideProcess });
    }
  }

  buildData(visible, props) {
    const { column, subColumns, columnFilter, rowsFilter, rowsWithValues, visibleRows, data, separateParents, rowsLevel } = props || this.props;

    let isExpandable = subColumns != null;
    let columnsArray = isExpandable ? Object.values(subColumns || {}).sort((a,b) => a.order < b.order ? -1 : 1) : [column];

    let subColumnsComponents = [];
    let rows = [];
    let isFirstRow = true
    for (let rowIndex=0; Boolean(data) && rowIndex < data.length; rowIndex++) {
      let row = data[rowIndex];
        
      let dataCells = []; 

      if  ((Boolean(rowsWithValues) && !rowsWithValues[row.id]) || 
          (Boolean(rowsFilter) && !rowsFilter(row, visibleRows)))
        continue;

      columnsArray.forEach((currColumn, i) => {

        if (i > 0 && (visible == visiblyStates.hide))
          return null;

        if (Boolean(columnFilter) && !columnFilter(currColumn))
          return null;

          let rowStyle = currColumn.rowStyle;
          
          let currRow = {row, column:currColumn, type: currColumn.valuesType, className: currColumn.className, style: rowStyle, tableRowsLevel: rowsLevel}
          dataCells.push(currColumn.Cell(currRow, { isFirstRow, isFirstColumn: (currColumn.original.columnType == columnTypes.sub && currColumn.original.itemNumber == 1), rowIndex }));
        }
      );

      if (!row.parentId && !isFirstRow && separateParents)
        rows.push(<tr key={'inner-row-sep-'.concat(row.id)}><td colSpan={100} className="rows-separator"></td></tr>);

      rows.push(<tr key={'inner-row-'.concat(row.id)}>{dataCells}</tr>);        
      isFirstRow = false;
    }

    columnsArray.forEach((currColumn, i) => {
      if (i > 0 && (visible == visiblyStates.hide))
          return null;

      if (!Boolean(columnFilter) || columnFilter(currColumn)) {

        let addClassNames = currColumn.headerClassName || "";
        addClassNames.concat(" column");

        subColumnsComponents.push(
          <th className={addClassNames} style={currColumn.style} key={'inner-column-'.concat(currColumn.original.description)}>
            {currColumn.Header(currColumn.original, { columnType: currColumn.columnType, columnTrades:currColumn.trades, isFirstColumn: (currColumn.original.columnType == columnTypes.sub && currColumn.original.itemNumber == 1) })}
          </th>
        )
        }
      }
    )

    this.setState({subColumnsComponents, rows})
  }

  render() {
    const { column, subColumns } = this.props;
    const { visible, subColumnsComponents, rows } = this.state;

    let currWidth = column.style.width ? column.style.width : newColumnWidth - 1;
    let style = {};

    const numOfColumns = Object.values(subColumns || {}).length;
    const expandAndColumnWidth = (currWidth) * numOfColumns;

    if (visible == visiblyStates.show || visible == visiblyStates.inShowProcess)
      currWidth = expandAndColumnWidth;

    const animationTime = timePerColumn * numOfColumns;

    const isExpanded = Boolean(visible != visiblyStates.hide);
    const isInAnimation = (visible == visiblyStates.startShowProcess || visible == visiblyStates.inShowProcess || visible == visiblyStates.inHideProcess);
    if (isExpanded)
      style.zIndex = theme.zIndexes.tableExpandColumn;

    return (
      <th style={style} rowSpan={0} key={'expand-'.concat(column.mainColumnId || "")}>
        <table className="AnalyticsTable innerTable" style={{transition: 'width ' + animationTime + 's', width: currWidth, overflowX: isInAnimation ? 'hidden' : 'inherit'}}>
        <tbody>
          <tr>
            {subColumnsComponents}
          </tr>
          {rows}
        </tbody>
      </table>

      </th>
    )
  }
}

export default connectContext(ReportContext.Consumer)(TableExpandColumn)
