

export const CELL_SECTIONS = {
	PROJECT: 'project',
	TOTAL_STATS: 'totalStats',
	USAGE: 'recentUse',
	OPEN_TASKS_BY_TRADE: 'openTasksByTrade',
	SAFETY_GRADE: 'safetyGrade',
	OPEN_ISSUES: 'openIssues',
	WORKERS_CERTIFICATES: 'employeesCertifications',
	EQUIPMENT_CERTIFICATES: 'equipmentReviews',
	ACTIVITY: 'lastDays'

};

