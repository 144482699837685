import * as actions from '../../../common/images/actions';

export function resizeImages(image, resizePercentFromDefaultResize, callback) {
  var img = document.createElement("img");
  img.onload = () => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let percent = resizePercentFromDefaultResize ? (resizePercentFromDefaultResize / 100) : 1
    var MAX_WIDTH = actions.MAX_IMAGE_WIDTH * percent;
    var MAX_HEIGHT = actions.MAX_IMAGE_WIDTH * percent;
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    var dataurl = canvas.toDataURL("image/png");
    
    if (callback)
      callback(dataurl);
  }
  img.src = image;
}