import { Record } from '../transit';

const Floor = Record({
  id: null,
  num: null,
  description: null,
  type: null,  
  locationType: null,
  ordinalNo: null
}, 'floor');

export default Floor;
