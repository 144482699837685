import { Record } from '../transit';

const User = Record({
  isMember: true,
  avatar: null,
  categories: null,
  displayName: '',
  idNumber: null,
  licenceNumber: null,
  tags: null,
  email: null,
  id: '',
  phoneNumber: null,
  registration_token: null,
  lang: null,
  projects: null,
  trades: null,
  groups: null,
  title: null,
  companyId: null,
  suprv: null,
  workingArea: null,
  about: null,
  adminMode: null,
  backgroundImage: null,
  rank: null,
}, 'user');

export default User;
