import React from "react";
import { connectContext } from 'react-connect-context';
import { ReportContext } from '../../../common/analytics/contexts'; 

// Assets
import theme from "../../assets/css/theme";
import { transitionTimingFunction, transition } from './QCTableCell.js'; 
const timePerColumn = 0.15;

export const newColumnWidth = 250;
export const columnColor = 'white';

//const animationSec = 1;
class TableFirstColumn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.buildData = this.buildData.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    var shouldComponentUpdate = (
      (this.props.isValDiff(nextProps, ['scrollPosition'])) ||
      (this.props.isValDiff(nextProps, ['subColumns'])) ||
      (this.props.isValDiff(nextProps, ['rowsFilter'])) ||
      (this.props.isValDiff(nextProps, ['rowsWithValues'])) ||
      (this.props.isValDiff(nextProps, ['visibleRows'])) ||
      (this.props.isValDiff(nextProps, ['data'])))

    return shouldComponentUpdate;
  }

  buildData() {
    const { subColumns, rowsFilter, rowsWithValues, visibleRows, data, separateParents, rowsRefs } = this.props;

    this.subColumnsComponents = [];
    this.rows = [];
    let isFirstRow = true;

    if (!rowsRefs.current)
      rowsRefs.current = [];

    rowsRefs.current = rowsRefs.current.slice(0, data.length + 1);
    for (let i=0; Boolean(data) && i < data.length; i++) {
      let row = data[i];
        
      let dataCells = []; 

      if  ((Boolean(rowsWithValues) && !rowsWithValues[row.id]) || 
          (Boolean(rowsFilter) && !rowsFilter(row, visibleRows)))
        continue;

      let subColumn = subColumns;
    
      let rowStyle = Object.assign({}, subColumn.rowStyle,{ outlineWidth:0 });
      let currCell = {row, column:subColumn, type:row.type, className: subColumn.className, style: rowStyle}
      if (!rowsRefs.current[i])
        rowsRefs.current[i] = React.createRef();
      dataCells.push(subColumn.Cell(currCell, { isFirstRow: Boolean(i == 0), rowIndex: i, forwardRef: rowsRefs.current[i], colWidth: newColumnWidth }));
      
      if (!row.parentId && !isFirstRow && separateParents)
        this.rows.push(<tr key={'inner-row-sep-'.concat(row.id+'sep')}><td className="rows-separator"></td></tr>);

      this.rows.push(<tr key={'inner-row-sep-'.concat(row.id)}>{dataCells}</tr>);        
      isFirstRow = false;
    }

    let addClassNames = subColumns.headerClassName || "";;
    addClassNames.concat(" column");

    this.subColumnsComponents.push(
      <th className={addClassNames} style={subColumns.style} key={'inner-column-'.concat(subColumns.original.description)}>
        {subColumns.Header(subColumns.original, { columnType: subColumns.columnType, isFirstColumn: true })}
      </th>
    )
  }

  render() {
    const { subColumns, rtl,customWidth  } = this.props;

    this.buildData();
    let currWidth = newColumnWidth;
    let style = { position: 'sticky', [rtl ? 'right' : 'left']: 0, zIndex: theme.zIndexes.tableFirstColumn } // under 5000 to not go over side menu if any
    const numOfColumns = Object.values(subColumns || {}).length;
    const expandAndColumnWidth = (newColumnWidth + 1) * numOfColumns;

    const animationTime = timePerColumn * numOfColumns;

    return (
      <th style={style} rowSpan={0} key={'expand-location'}>
        <table className="AnalyticsTable innerTable" style={{width: customWidth || currWidth}}>
          <tbody>
            <tr>
              {this.subColumnsComponents}
            </tr>
            {this.rows}
          </tbody>
        </table>
      </th>
    )
  }
}

export default connectContext(ReportContext.Consumer)(TableFirstColumn)