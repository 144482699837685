import { batchDispatch } from '../app/funcs';
import {getSnapshotData} from '../lib/utils/utils';

export const LOAD_PROJECT_TRADES = 'LOAD_PROJECT_TRADES';
export const GET_TRADES = 'GET_TRADES';
export const TRADES_LOADING = 'TRADES_LOADING';
export const SET_RECENT_TRADES = 'SET_RECENT_TRADES';

export function getTrades(viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'global' };
    const resourceParams = {
      resourceName: 'trades',
      firebasePath: `trades`,
      forceMSClientConfig: true,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_TRADES, payload: { trades: data, immediateGlobalStorageSave: true, lastUpdateTS: 1 } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      batchDispatch([{
        type: GET_TRADES,
        payload: { trades: result, immediateGlobalStorageSave: true },
      }]);
    }
  }

  return {
    type: TRADES_LOADING,
    payload: getPromise(),
  };
}

export function setProjectTrades(projectId, members, projectChecklistItems) {

  let trades = {};
  (members || []).map(member => {
    Object.keys(member.trades || {}).forEach(tradeId => {
      if (Boolean(!trades[tradeId]))
        trades[tradeId] = { id: tradeId };
    });
  });

  if (projectChecklistItems)
    projectChecklistItems.loopEach((id, checklistItem) => {
      if (checklistItem && checklistItem.trade && !trades[checklistItem.trade])
        trades[checklistItem.trade] = { id: checklistItem.trade };
    });

  return {
    type: LOAD_PROJECT_TRADES,
    payload: { projectId, trades }
  };
}

