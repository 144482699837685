
import React from "react";
import theme from "../../assets/css/theme";
import Text from "../CementoComponents/Text";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import { Hidden } from "@material-ui/core";

class CardTabs extends React.Component {

    constructor(props) {
      super(props);
			this.setComponentData = this.setComponentData.bind(this);
			this.onClick = this.onClick.bind(this);
      this.state = {
				selectedIndex:0
			};
    }

    onClick(index, href) {
      if (this.props.onClick)
        this.props.onClick(href)
			this.setState({selectedIndex:index})
    }

    UNSAFE_componentWillMount() {
      this.setComponentData({}, this.props)
    }
  
    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setComponentData(this.props, nextProps)
    }
  
    setComponentData(props, nextProps) {      
      let newStateChanges = {};
  
      //////////////////////////////
      //////////////////////////////
      //////////////////////////////
  
      if (Object.keys(newStateChanges).length > 0)
        this.setState(newStateChanges);
    }
  
    render() {
      const { tabs, classes, maxChars, maxLines } = this.props;
			const { selectedIndex } = this.state;

      return tabs.map((t, i) => {
				const tabClass = cx({ 
					[classes.tabStyle]: true,
					[classes.tabStyleActive]: selectedIndex == i });
				return (
          <a key={'tabs-' + t.href} className={tabClass} href={'#'+t.href} onClick={() => this.onClick(i, t.href)}>
            <Text maxLines={maxLines} maxChars={maxChars} style={{ fontWeight: theme.bold, color: selectedIndex == i ? theme.headerColorDark : theme.brandNeutral, whiteSpace: 'nowrap', textAlign: 'center', fontSize: theme.mediumFont, lineHeight: theme.mediumFont + 'px' }}>{t.title}</Text>
          </a>
				)
			})
    }
  }
  
  const styles = {
    tabStyle: {
				height: 'inherit',
        padding: 5,
        transition: 'all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s',
        position: "relative",
        width: "auto",
        "&:hover": {
          outline: "none",
          backgroundColor: "rgba(200, 200, 200, 0.2)",
          boxShadow: "none"
        },
        "&,&:hover,&:focus": {
          color: "inherit"
        },
        display: 'flex', 
        flexDirection:'column', 
        justifyContent:'center', 
        alignItems:'center', 
        paddingRight: theme.paddingSize * 1.3, 
        paddingLeft: theme.paddingSize * 1.3, 
        minWidth:40,
        color: theme.backgroundColor
    },
    tabStyleActive: {
        borderBottom:'3px solid ' + theme.headerColorDark,
        color: theme.headerColorDark
    },
  };
  
  CardTabs = withStyles(theme.combineStyles(headerStyle, styles))(CardTabs);  
  export default CardTabs