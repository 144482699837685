import * as actions from './actions';
import SubCategory from './subCategory';
import RequiredAction from './requiredAction';
import { Map } from 'immutable';
import { Record } from '../transit';
import { platformActions } from '../platformActions';
import _ from 'lodash';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  //map: Map(),
  subCategoriesMap: Map(),
  requiredActionsMap: Map(),
  formUniversalIdsMap: Map(),
  didLoad: false,
  projectSubCategories: Map(),
  groupsSubTypes: Map(),
  professions: Map,
  requiredBuildNumber: false,
  optionalBuildNumber: false,
  adminUsers: Map,
}, 'quasiStatics');

const initialState = new InitialState;

export default function quasiStaticsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.QUASISTATICS_LOADING + '_SUCCESS': {
      if (!state.get('didLoad')) {
        state = state.set('didLoad', true);
      }

      return state;
    }

    case actions.GET_QUASISTATICS: {
      if (!action.payload)
        return state;

      const { quasiStatics } = action.payload;
      if (!quasiStatics) {
        return state;
      }

      let subCategoriesMap = new Map();
      let requiredActionsMap = new Map();
      let formUniversalIdsMap = new Map();
      if (quasiStatics && quasiStatics.subCategories)
        (quasiStatics.subCategories || {}).loopEach((tradeKey, TradeSubCategories) => 
          (TradeSubCategories || {}).loopEach((key, subCategory) => { 
            if (subCategory) {
              let subCategoriesVals = { 
                id: subCategory.id, 
                types: subCategory.types, 
                title: subCategory.title, 
                trade: tradeKey,
                systemTitle: subCategory.getNested(['title', 'en'], '')
              };
              if (subCategory.color) subCategoriesVals.color = subCategory.color;
              subCategoriesMap = subCategoriesMap.setIn([tradeKey, subCategory.id], new SubCategory(subCategoriesVals));
            }
          })
        );

        
      if (quasiStatics && quasiStatics.requiredActions)
      (quasiStatics.requiredActions || {}).loopEach((reqKey, reqActions) => {
          if (reqActions) {
            let reqActionsVals = { 
              id: reqActions.id, 
              types: reqActions.subCategory, 
              title: reqActions.title, 
              trade: reqActions.trade
            };
            requiredActionsMap = requiredActionsMap.setIn([reqActions.id], new RequiredAction(reqActionsVals));
          }
        });

      if (quasiStatics && quasiStatics.forms)
        (quasiStatics.forms || {}).loopEach((reqKey, formUniversalId) => {
        if (formUniversalId) {
          let universalIdObject = { 
            id: formUniversalId.id, 
            label:formUniversalId.id + " - " + formUniversalId.getNested(['description','he'], ''),
            title:formUniversalId.id + " - " + formUniversalId.getNested(['description','he'], ''),
            description: formUniversalId.getNested(['description'], {})
          };
          formUniversalIdsMap = formUniversalIdsMap.setIn([universalIdObject.id], universalIdObject);
        }
      });

  
      let standardBusinessTypesList = ['professions', 'groupsSubTypes'];//, 'equipmentTypes', 'inspectorType', 'equipmentStatus', 'excavationsTestReason', 'LevelTowersTestReason'];
      standardBusinessTypesList.forEach(currType => {
        let currTypeMap= new Map();
        if (quasiStatics && quasiStatics[currType])
          (quasiStatics[currType] || {}).loopEach((reqKey, currTypeItem) => {
              if (currTypeItem) {
                currTypeMap = currTypeMap.setIn([currTypeItem.id], currTypeItem);
            }
          });
          state = state.set(currType, currTypeMap);
      });
      if (quasiStatics?.adminUsers) {
        let adminUsersMap = new Map();
        _.forIn(quasiStatics.adminUsers, (user, userId) => {
          adminUsersMap = adminUsersMap.setIn([userId], user);
        });

        state = state.set('adminUsers', adminUsersMap);
      }
      
      if (quasiStatics?.buildNumbers) {
        const currPlatform = platformActions.app.getPlatform();
        const { required, optional } = _.get(quasiStatics, ['buildNumbers', currPlatform], {});

        if (required)
          state = state.set('requiredBuildNumber', required);
        if (optional)
          state = state.set('optionalBuildNumber', optional);
      }

      state = state.set('requiredActionsMap', requiredActionsMap);
      state = state.set('formUniversalIdsMap', formUniversalIdsMap);
      return  state.set('subCategoriesMap', subCategoriesMap);
    }

    case actions.LOAD_PROJECT_QUASISTATICS + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, subCategories } = action.payload;
      
      if (!subCategories || !projectId) 
        return state;

      state = state.setIn(['projectSubCategories', projectId], subCategories);

      return state;
    }

    case actions.CLEAN_CACHED_QUASISTATICS: {
      state.set('subCategoriesMap',  Map());
      return state.set('requiredActionsMap',  Map());
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }
  
  return state;
}
