import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { track, setReportingScope } from '../../../common/lib/reporting/actions';
import Analytics from './QCAnalytics';
import PropertyAnalytics from '../Properties/PropertyAnalytics';
import LocationContainerPage from '../../views/Locations/LocationContainerPage';
import { getBuildings } from '../../../common/buildings/actions';
import { saveUIParams } from '../../../common/ui/actions';
import { getPropertiesTypes, getPropertiesTypeById } from '../../../common/propertiesTypes/actions';
import { getPropertiesMappings } from '../../../common/propertiesMappings/actions';
import { getPropertiesInstances } from '../../../common/propertiesInstances/actions';
import { getFloors } from '../../../common/floors/actions';
import { getUnits } from '../../../common/units/actions';
import { getConfigurations } from '../../../common/configurations/actions';
import { setProjectTrades } from '../../../common/trades/actions';
import { startPostsListener } from '../../../common/posts/funcs.js';
import { getUsersByProject } from '../../../common/members/actions';
import { startCompaniesListener } from '../../../common/companies/actions';
import { setLastVisitedProject } from '../../../common/users/actions';
import {
	getUserProjects,
	getProjectDetails,
	enterProject,
	leaveProject,
	saveProjectStorage,
} from '../../../common/projects/actions';
import { getChecklists } from '../../../common/checklists/actions';
import { getChecklistItems } from '../../../common/checklistItems/actions';
import { startLoading, hideLoading, setLang } from '../../../common/app/actions';
import { endProjectListener } from '../../../common/lib/utils/utils';

import NoItemsFound from '../../components/CementoComponents/NoItemsFound';
import theme from '../../assets/css/theme';
import projectsMessages from '../../../common/projects/projectsMessages';
import { HeaderLinks } from '../../components';
import { getStages } from '../../../common/stages/actions';
import { PostsHOC } from '../../../common/posts/hooks/usePosts';
import { checkAndFetchSpecificProps } from '../../../common/propertiesTypes/funcs';
import { getDrawings } from '../../../common/drawings/actions';
import { shouldLastUpdateV2Fetch } from '../../../common/lastUpdatesV2/funcs';
import { SUBJECT_NAMES, generateQueryParamsForInitialPropertyInstancesFetch } from '../../../common/propertiesTypes/propertiesTypes';
import moment from 'moment-timezone';
import withRouterHOC from '../../components/Router/util/withRouterHOC.js';
import { getChecklistItemsInstances } from '../../../common/checklistItemsInstances/funcs.js';

const qs = require('qs');

class ProjectContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.checkProjectDidLoad = this.checkProjectDidLoad.bind(this);
    this.handleUnmountBeforeBrowserExit = this.handleUnmountBeforeBrowserExit.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.setHeaderParams = this.setHeaderParams.bind(this);
    this.handleEnterProject = this.handleEnterProject.bind(this);
    this.handleLeaveProject = this.handleLeaveProject.bind(this);
    this.handleUnmount = this.handleUnmount.bind(this);
    this.updateLanguageIfDiff = this.updateLanguageIfDiff.bind(this);
    this.closeProjectsListener = this.closeProjectsListener.bind(this);
    this.projectsIndications = { listeners: {}, companiesListener: {} };
    this.loadingInterval = {};
    this.state = {
      filteredPosts: [],
      filterVal: "",
    };
  }

  handleUnmount(beforeBrowserExit) {
    const { selectedProjectId, projectReducersLoaded } = this.props;
    if (selectedProjectId) {
      if (!beforeBrowserExit || (beforeBrowserExit && projectReducersLoaded))
        this.handleLeaveProject(selectedProjectId);
    }
    this.props.hideLoading();
    window.removeEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
  }

  handleUnmountBeforeBrowserExit() {
    this.handleUnmount(true);
  }

  componentWillUnmount() {
    this.handleUnmount();
  }

  componentWillMount() {
    window.addEventListener("beforeunload", this.handleUnmountBeforeBrowserExit);
    let selectedProjectId = this.props.match.params.selectedProjectId;

    if (selectedProjectId)
      this.handleEnterProject({}, this.props);
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match } = this.props;

    this.handleEnterProject(this.props, nextProps);
    if (match.params.selectedProjectId && match.params.selectedProjectId != nextProps.match.params.selectedProjectId)
      this.handleLeaveProject(match.params.selectedProjectId);

    this.setComponentData(this.props, nextProps);
  }

  async handleEnterProject(props, nextProps) {
    const { enterProject, setLastVisitedProject, projects, track, setReportingScope, } = nextProps;
    let URL_projectId = props.getNested(["match", "params", "selectedProjectId"]);
    let nextURL_projectId = nextProps.getNested(["match", "params", "selectedProjectId"]);

    if (nextURL_projectId && !this.waitForIntlProviderRerender) {
      if (URL_projectId != nextURL_projectId) {
        track("enterProject", { eventProjectId: nextURL_projectId });

        // IMPORTANT! - this happens before enterProject() in case the lenguage of the page is going to rerander all page...
        setLastVisitedProject(
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([nextURL_projectId], {}),
          projects.getNested([URL_projectId], {}),
          true,
          false,
        );

        const timeZone = projects.getNested([nextURL_projectId, 'tzLocation'], 'Asia/Jerusalem');
        moment.tz.setDefault(timeZone);

        this.updateLanguageIfDiff(nextProps, nextURL_projectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderReelement=true" and break the sequence

        enterProject(nextURL_projectId);
        setReportingScope({
          viewer: nextProps.viewer,
          projectId: nextURL_projectId,
        });
      } else if (nextURL_projectId == nextProps.selectedProjectId) {
        let projectId = props.selectedProjectId;
        let nextProjectId = nextProps.selectedProjectId;
        if (!nextProjectId || nextProjectId == "null") return null;

        this.updateLanguageIfDiff(nextProps, nextProjectId);
        if (this.waitForIntlProviderRerender) return; // IMPORTANT! - setLang() is going to rerender the intlProvider and everything else, so in that case we "waitForIntlProviderReelement=true" and break the sequence

        if (nextProps.projectReducersLoaded)
          this.checkProjectDidLoad(props, nextProps);

        if (projectId !== nextProjectId && !nextProps.projectReducersLoaded) {
          this.checkProjectDidLoad(props, nextProps);
        }
        // Only after => projectStorage is loaded || we change projects and projectStorage already loaded
        else if (
          nextProps.projectReducersLoaded && 
          nextProps.projectLokiLoaded && (
            props.isValDiff(nextProps, ['storageCleaned']) || 
            projectId !== nextProjectId
          )
        ) {
            if (props.storageCleaned != nextProps.storageCleaned) {
              this.closeProjectsListener(nextProjectId);
            }

            this.getProjectData(props, nextProps);
        }
      }
    }
  }

  updateLanguageIfDiff(nextProps, projectId) {
    const { setLang } = nextProps;
    if (!this.waitForIntlProviderRerender) {
      let newLang = nextProps.getNested(["projectsMap", projectId, "lang"], "en");
      let currLang = nextProps.lang;
      if (currLang != newLang) {
        this.waitForIntlProviderRerender = true;
        setLang(newLang);
      }
    }
  }
  
  closeProjectsListener(projectId) {   
    if (this.projectsIndications.listeners[projectId]) {
      this.projectsIndications.listeners[projectId] = false;
      try {
        endProjectListener(projectId);
      }
      catch (err) {
        this.projectsIndications.listeners[projectId] = true;
      }
    }
  }

  handleLeaveProject(projectId) {
    const { loading, hideLoading, leaveProject } = this.props;
    this.closeProjectsListener(projectId);
    leaveProject(projectId);
    if (loading) hideLoading();
    if (this.saveProjectStorageInterval) clearInterval(this.saveProjectStorageInterval);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};
    if (props.isValDiff(nextProps, ["navigationParams", "scope"]))
      if (nextProps.getNested(["navigationParams", "scope"]) == "company") {
        let selectedCompanyId = nextProps.getNested(["navigationParams", "selectedCompanyId"], "_");
        nextProps.history.push(`/main/companyContainerPage/${selectedCompanyId}/_`);
      }

    if (props.isValDiff(nextProps, ["rtl"]))
      document.body.style.direction = nextProps.rtl ? "rtl" : "ltr";

    if (
      nextProps.projectReducersLoaded &&
      nextProps.projectLokiLoaded && (
        props.isValDiff(nextProps, ['projectReducersLoaded']) ||
        props.isValDiff(nextProps, ['projectLokiLoaded']) ||
        props.isValDiff(nextProps, ['configurationsDidLoad', nextProps.selectedProjectId]) ||
        props.isValDiff(nextProps, ['configurations']) ||
        nextProps.cleanCacheRevokes && props.cleanCacheRevokes != nextProps.cleanCacheRevokes
      )
    ) {
      this.getProjectData(props, nextProps);
    }

    if (props.isValDiff(nextProps, ["filtersView"]) ||
      props.isValDiff(nextProps, ["filters"]) ||
      props.isValDiff(nextProps, ["buildings"]) ||
      props.isValDiff(nextProps, ["viewer"]) ||
      props.isValDiff(nextProps, ["navigationParams", "contentType"]) ||
      props.isValDiff(nextProps, ["navigationParams", "page"]) ||
      props.isValDiff(nextProps, ["location", "search"]) ||
      props.isValDiff(nextProps, ["detailedProjects", nextProps.selectedProjectId]) ||
      props.isValDiff(nextProps, ["selectedProjectId"])){
      newStateChanges = { ...newStateChanges, filterVal: '' }
    }

    this.checkAndHandleSpecificPropsUpdates(props, nextProps)
   
    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }


  checkAndHandleSpecificPropsUpdates(prevProps, nextProps) {
    const { selectedProjectId: projectId, viewer } = nextProps;

    checkAndFetchSpecificProps({
      viewer,
      projectId,
      prevSpecificPropertiesUpdatesAvailable: prevProps.getNested(['specificPropertiesUpdatesAvailable']),
      nextSpecificPropertiesUpdatesAvailable: nextProps.getNested(['specificPropertiesUpdatesAvailable'])
    });
  }

  clearFilterVal = () => {
    this.setState({ filterVal: "" });
  };

  setFilterVal = filterVal => {
    this.setState({ filterVal });
  };

  checkProjectDidLoad(props, nextProps) {
    const { hideLoading, saveProjectStorage, selectedProjectId, startLoading } = nextProps;
    const propsRelevantForLoadingIndication = [
      ["buildingsDidLoad", selectedProjectId],
      ["floorsDidLoad", selectedProjectId],
      ["unitsDidLoad", selectedProjectId],
      ["trades", "size"],
    ];
    const allNextPropsLoaded = propsRelevantForLoadingIndication.every(propPath => nextProps.getNested(propPath));
    const atLeastOneOfPrevPropsWasntLoadedYet = propsRelevantForLoadingIndication.some(propPath => !props.getNested(propPath));


    // Means that the last relevant project storage was loaded
    // if we are in dashboard page URL
    const isDashboardLocation = nextProps.getNested(['location', 'pathname'], '').indexOf('dashboard') != -1;
    if (isDashboardLocation && (nextProps.loading || this.props.loading)) {
      hideLoading();
    }

    // Means that the last relevant project storage was loaded
    if ((allNextPropsLoaded && atLeastOneOfPrevPropsWasntLoadedYet) ||
      (allNextPropsLoaded && props.selectedProjectId != nextProps.selectedProjectId)) {
      if (!this.loadingInterval[nextProps.selectedProjectId])
        this.loadingInterval[nextProps.selectedProjectId] = setInterval((() => {
          if (nextProps.loading || this.props.loading) {
            hideLoading();
            clearInterval(this.loadingInterval[nextProps.selectedProjectId]);
            this.loadingInterval[nextProps.selectedProjectId] = null;
            delete this.loadingInterval[nextProps.selectedProjectId];
          }
        }).bind(this), 100);

      clearInterval(this.saveProjectStorageInterval);
      this.saveProjectStorageInterval = setInterval(() => {
        saveProjectStorage(selectedProjectId);
      }, 40000);
    } else if (!isDashboardLocation && !nextProps.loading && !allNextPropsLoaded)
      startLoading({ title: projectsMessages.loadingProjectData, overlay: true });

    if (!selectedProjectId)
      hideLoading();
  }

  async getProjectData(props, nextProps, force) {
    const {
      getPropertiesTypes, getPropertiesMappings, getPropertiesInstances,
      startCompaniesListener, getProjectDetails, getBuildings,
      getFloors, getUnits, getUsersByProject,
      getConfigurations, getChecklistItems, getChecklists, getStages, getDrawings,
      viewer,
    } = nextProps;

    const nextProjectId = nextProps.selectedProjectId;

    if (!nextProjectId || nextProjectId == "null" || !nextProps.viewer) {
      return null;
    }
    const _shouldLastUpdateV2Fetch = (resource, params) => shouldLastUpdateV2Fetch('projects', nextProjectId, resource, params);
    //load conf in *await* only if first time
    if (!nextProps.getNested(["configurationsDidLoad", nextProjectId])) {
      await getConfigurations('projects', nextProjectId, nextProps.viewer);
      return;
    } else if(_shouldLastUpdateV2Fetch('configurations')) {
      getConfigurations('projects', nextProjectId, nextProps.viewer);
    }

    if (_shouldLastUpdateV2Fetch('stages')) {
      getStages(nextProps.viewer, nextProjectId);
    }

    if (_shouldLastUpdateV2Fetch('checklists')) {
      getChecklists(nextProps.viewer, nextProjectId);
    }

    if (_shouldLastUpdateV2Fetch('checklistItems')) {
      getChecklistItems(nextProps.viewer, nextProjectId);
    }

    if (_shouldLastUpdateV2Fetch('projects'))
      getProjectDetails(nextProps.viewer, nextProjectId);

    if (_shouldLastUpdateV2Fetch('buildings'))
      getBuildings(nextProjectId, nextProps.viewer);

    if (_shouldLastUpdateV2Fetch('floors'))
      getFloors(nextProjectId, nextProps.viewer);

    if (_shouldLastUpdateV2Fetch('units'))
      getUnits(nextProjectId, nextProps.viewer);

    if (_shouldLastUpdateV2Fetch('users')) {
      getUsersByProject(nextProjectId);
    }
    const subjectNames = Object.values(SUBJECT_NAMES);
    subjectNames.forEach(subjectName => {
      if (_shouldLastUpdateV2Fetch('properties', { subjectName })) {
        getPropertiesTypes(viewer, nextProjectId, subjectName);  
      }
      if (_shouldLastUpdateV2Fetch('properties/mappings', { subjectName })) {
        getPropertiesMappings(viewer, nextProjectId, subjectName);
      }
    });

     if (!nextProps.getNested(["propertiesMappingsDidLoad", nextProjectId])) {
      subjectNames.forEach(subjectName => {
        getPropertiesMappings(viewer, nextProjectId, subjectName);  
      });
    }

     if (!nextProps.getNested(["propertiesTypesDidLoad", nextProjectId])) {
      subjectNames.forEach(subjectName => {
        getPropertiesTypes(viewer, nextProjectId, subjectName);  
      });
    }

    if (_shouldLastUpdateV2Fetch('drawings') || !nextProps.getNested(["drawingsDidLoad", nextProjectId])) {
        getDrawings(nextProps.viewer, nextProjectId);
    }

    if (nextProjectId && !this.projectsIndications.listeners[nextProjectId]) {
      try {
        this.projectsIndications.listeners[nextProjectId] = true;
        
        const propertyInstancesSubjects = Object.values(SUBJECT_NAMES);
        propertyInstancesSubjects.forEach(subjectName => {
          if (_shouldLastUpdateV2Fetch('propertiesInstances', { subjectName })) {
            let queryParams = generateQueryParamsForInitialPropertyInstancesFetch(subjectName)
            getPropertiesInstances(nextProps.viewer,nextProjectId, subjectName, false, queryParams);
          }
        });

        if (_shouldLastUpdateV2Fetch('checklistInstances')) {
          getChecklistItemsInstances(nextProps.viewer, nextProjectId, false);
        }

        if (_shouldLastUpdateV2Fetch('posts')) {
          startPostsListener(nextProps.viewer, nextProjectId, false);
        }

        startCompaniesListener(!nextProps.viewer.adminMode ? nextProjectId : null);
      }
      catch (err) {
        this.projectsIndications.listeners[nextProjectId] = false;
      }
    }
  }

  setHeaderParams(headerParams) {
    const { match, uiParams, menus, saveUIParams } = this.props;
    let contentType = match.params.contentType;
    let isOnlySideNav = Boolean((!headerParams || !headerParams.headerComponent) && menus?.[contentType]);
    if (uiParams.getNested(["onlySideNav"]) != isOnlySideNav)
      saveUIParams({ onlySideNav: isOnlySideNav });
    this.setState({ headerParams });
  }

  getPostsFilters = () => {
    const VALID_POSTS_CONTENT_TYPES = {records:true, tasks:true, issues:true};
    const urlContentType = this.props.getNested(['navigationParams', "contentType"]);
    return {
      contentType: urlContentType == "safety" ? 'safety' : null,
      postsType: VALID_POSTS_CONTENT_TYPES[urlContentType] ? urlContentType : this.props.getNested(['navigationParams', "queryParams", "itemType"], null),
      filterValue: this.state.filterVal,
    }
  }

  render() {
    const { match, menus, projectPermitted, intl, location, selectedProjectId } = this.props;
    const { headerParams, filterVal } = this.state;
    
    if (!selectedProjectId)
      return null;

    // 
    // TODO:NAVIGATION Looks like it works without a need of the previously existing redirect
    // But I've added this logic just in case so it can be uncommented at any time if related issues appear
    // 
    // let URL = match.url.endsWith("/") ? match.url : match.url + "/";
    // let query = this.props.getNested(["location", "search"], "");
    // if (this.props.match.isCurrentPage('_')) {
    //   this.props.history.push(URL.replace('/_', '/issues/dashboard') + query);
    // }
    // 

    if (!projectPermitted)
      return (<NoItemsFound key={"projectPermitted"} style={{ fontSize: 22, color: theme.brandPrimary, margin: 5 }} message={projectsMessages.notPermittedToWebProject} />);

    return (
      <PostsHOC filters={this.getPostsFilters()}>
        {({ filteredPosts, currViewPosts }) => (
          <div
            style={{
              display: 'flex',
              flexDirection: headerParams && headerParams.headerComponent ? 'column' : 'row',
              flex: 1,
            }}>
            <HeaderLinks
              key='secondaryHeader'
              defaultSelection={null}
              headerParams={headerParams}
              routingMode={true}
              menus={menus}
              menuMode={true}
              intl={intl}
              location={location}
              isSecondary={true}
            />
            <Outlet
              context={{
                currViewPosts: currViewPosts,
                filteredPosts: filteredPosts,
                filterVal: filterVal,
                clearFilterVal: this.clearFilterVal,
                setFilterVal: this.setFilterVal,
                setHeaderParams: this.setHeaderParams,
              }}
            />
          </div>
        )}
      </PostsHOC>
    );
  }
}

const enhance = compose(
  injectIntl,
  withRouterHOC,
	connectContext(ProjectContext.Consumer),
	connectContext(FiltersSortsContext.Consumer),
	connect(
		state => ({
			allCompanies: state.companies.map,
			allMembers: state.members.map,

			tradesDidLoad: state.trades.didLoad,
			configurationsDidLoad: state.configurations.didLoad,
			buildingsDidLoad: state.buildings.didLoad,  
			floorsDidLoad: state.floors.didLoad,
			unitsDidLoad: state.units.didLoad,
			membersDidLoad: state.members.didLoad,
			drawingsDidLoad: state.drawings.didLoad,
			postsDidLoad: state.posts.didLoad,
			companiesDidLoad: state.companies.didLoad,
			instancesDidLoad: state.checklistItemsInstances.didLoad,
			stagesDidLoad: state.stages.didLoad,
			checklistsDidLoad: state.checklists.didLoad,
			checklistItemsDidLoad: state.checklistItems.didLoad,
			propertiesTypesDidLoad: state.propertiesTypes.didLoad,
			propertiesMappingsDidLoad: state.propertiesMappings.didLoad,

			specificPropertiesUpdatesAvailable: state.propertiesTypes.specificPropertiesUpdatesAvailable,
      membersLastClientUpdatePerProject: state.members.lastClientUpdatePerProject,

			cleanCacheRevokes: state.ui.cleanCacheRevokes,

			projectsMap: state.projects.map,
			lang: state.app.lang,
			rtl: state.app.rtl,
			uiParams: state.ui.uiParams,
			storageCleaned: state.app.storageCleaned,
		}),
		{
			getPropertiesInstances,
			getPropertiesTypes,
			getPropertiesMappings,
			setProjectTrades,
			getBuildings,
			getFloors,
			getUnits,
			getConfigurations,
			setLastVisitedProject,
			saveProjectStorage,
			enterProject,
			leaveProject,
			getChecklistItems,
			getChecklists,
			getStages,
			getUserProjects,
			getProjectDetails,
			getUsersByProject,
			startCompaniesListener,
			startLoading,
			hideLoading,
			setLang,
			track,
			saveUIParams,
			setReportingScope,
			getPropertiesTypeById,
      getDrawings,
		},
	),
);
export default enhance(ProjectContainerPage);
