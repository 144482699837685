import { useState, useEffect, useCallback } from 'react';
import { platformActions } from '../../common/platformActions';

const noop = x => x;

const useCachedRequest = ({ request, cacheId = 'v1', mapResponse = noop, onCache = noop, onResponse = noop }) => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = useCallback(async () => {
		const cache = await caches.open(cacheId);
		await getCachedValue(cache);
		await fetchFreshData(cache);
	}, [cacheId, request]);

	const getCachedValue = useCallback(
		async cache => {
			const cachedRequest = await cache.match(request);

			if (cachedRequest) {
				const cachedData = await cachedRequest.clone().json();
				const mappedResult = await mapResponse(cachedData);
				setData(mappedResult);
				onCache(mappedResult);
				setIsLoading(false);
			}
		},
		[request, setData, onCache, mapResponse],
	);

	const fetchFreshData = useCallback(
		async cache => {
			setIsLoading(true);
			const response = await platformActions.net.fetch(request?.url, {...request})
			const result = await response.clone().json();
			await cache.put(request, response);
			const mappedResult = await mapResponse(result);
			setData(mappedResult);
			onResponse(mappedResult);
			setIsLoading(false);
		},
		[request, setData, onCache, mapResponse],
	);

	return { data, isLoading, refetch: fetchData };
};

export default useCachedRequest;
