import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
const Trade = Record({  
  id: null,
  types: null,
  title: null,
  color: null,
  systemTitle: null
},'trade');

export default Trade;
