import _ from 'lodash';
import { subscribeToLastUpdates } from '../lib/utils/utils';
import { getProjectStatePaths } from '../configureStorage/statePathes';
import { batchDispatch } from '../app/funcs';
import { legacyLoadMobileStorage } from '../app/actions';
import { setServerSdkAuthClientParams } from '../lib/api';
import { PROJECT_EVENTS } from './trackProjects';
import ClientServerConnectivityManager from '../lib/ClientServerConnectivityManager';
import serverSDK from '@cemento-sdk/server';
import { getAppState, lokiInstance } from '../configureMiddleware';
import { platformActions } from '../platformActions';

const PROJECTS_FIELDS = [   
  'id',
  'createdAt',
  'lastUpdate',
  'counter',
  'address',
  'city',
  'clients',
  'description',
  'images',
  'companyId',
  'company',
  'title',
  'clientAvatarUrl',
  'owner',
  'location',
  'permissions',
  'members',
  'deletedMembers',
  'type',
  'settings',
  'stage',
  'about',
  'tzLocation',
  'lang',
  'periodicCL_Alerts',
  'showOnAdmin',
  'country',
  'region',
];

export const getUserProjects = (viewer) => {
  const getPromise = async () => {
    const scopeParams = {
      scope: 'global',
    };

    const resourceParams = {
      subject: 'projects',
      getData: () => {
        return serverSDK.projects.getProjects({ fields: PROJECTS_FIELDS });
      },
    };

    const onData = (projects) => {
      batchDispatch([{ type: PROJECT_EVENTS.GET_PROJECTS, payload: { projects } }]);
    };

    const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, true);

    if (result) onData(result);
  };

  return {
    type: PROJECT_EVENTS.GET_PROJECTS_STARTED,
    payload: getPromise(),
  };
};

export const getProjectDetails = (viewer, projectId) => {
  const getPromise = async () => {
    const scopeParams = {
      scope: 'projects',
      scopeId: projectId,
    };

    const resourceParams = {
      subject: 'projects',
      getData: () => {
        return serverSDK.projects.getProject({ id: projectId });
      },
    };

    const onData = (data) => {
      batchDispatch([{ type: PROJECT_EVENTS.GET_PROJECT_DETAILS, payload: { projectId, project: data } }]);
    };

    const result = await subscribeToLastUpdates(viewer, scopeParams, resourceParams, onData, true);
    if (result) onData(result);
  };

  return {
    type: PROJECT_EVENTS.PROJECT_DETAILS_LOADING,
    payload: getPromise(),
  };
};

let IS_ALREADY_RUNNING_A_SAVE = false;

const loadProjectStorageProgressManager = {
  inProgress: {},
  set: (projectId, flag) => {
    if (flag) {
      loadProjectStorageProgressManager.inProgress[projectId] = flag;
    } else {
      delete loadProjectStorageProgressManager.inProgress[projectId];
    }
  },
  isInProgess: (projectId) => Boolean(loadProjectStorageProgressManager.inProgress[projectId]),
};

export const saveProjectStorage = (projectId) => {
  return () => {
    const getPromise = async () => {

      if (!IS_ALREADY_RUNNING_A_SAVE) {
        IS_ALREADY_RUNNING_A_SAVE = true;

        try {
          if (platformActions.app.isWeb()) {
            await lokiInstance.saveProjectDataToStorage(projectId);
            await lokiInstance.saveProjectDataToStorage('global');
          }
        } catch (error) {
          platformActions.sentry.notify(error, { projectId });
          console.log('saveProjectStorage error:', error);
        } finally {
          IS_ALREADY_RUNNING_A_SAVE = false;
        }
      }

      return { projectId };
    };

    return {
      type: PROJECT_EVENTS.SAVE_PROJECT_STORAGE,
      payload: getPromise(),
    };
  };
};

export const removeProjectsStorage = (projectIdsArray, projectStateToRemove) => {
  return () => {
    const getPromise = async () => {
      try {
        await Promise.all(
          projectIdsArray.map(async (projectId) => {
            await Promise.all(
              projectStateToRemove.map(async ([feature, ...featurePath]) => {
                try {
                  var configKey = '@' + feature + '_' + featurePath + ':' + projectId;
                  if (platformActions.app.getPlatform() == 'web') await platformActions.storage.removeItem(configKey);
                  else {
                    const realm = platformActions.localDB.getCementoDB();
                    const query = `id = "${configKey}"`;
                    realm.unset('reducerPersist', query);
                  }
                } catch (err) {
                  console.warn('REMOVE_PROJECTS_STORAGE error:' + projectId);
                  console.warn(err);
                }
              })
            );
          })
        );

      } catch (error) {
        console.log('REMOVE_PROJECTS_STORAGE error: ' + error);
        throw error;
      }
    };

    return {
      type: PROJECT_EVENTS.REMOVE_PROJECTS_STORAGE,
      payload: getPromise(),
    };
  };
};

export const loadProjectStorage = (projectId, forceLoad) => {
  return () => {
    const getPromise = async () => {
      if (loadProjectStorageProgressManager.isInProgess(projectId)) {
        return { projectSavedJson: null, projectId, didFind: false };
      }

      loadProjectStorageProgressManager.set(projectId, true);
      try {
        await platformActions.storage.recordAccess(projectId);

        var projectSavedJson = [];
        if (
          forceLoad ||
          !(getAppState().projects.projectReducersLoaded && getAppState().projects.projectReducersLoaded.get(projectId))
        ) {
          const platform = platformActions.app.getPlatform();
          let projectPathesToLoad = getProjectStatePaths();
          if (platform == 'web') projectPathesToLoad.unshift(['loki']);
          for (const [feature, ...featurePath] of projectPathesToLoad) {
            try {
              if (feature == 'loki') {
                await lokiInstance.loadProjectDataFromStorage(projectId, (loadedCollections) =>
                  batchDispatch([{ type: PROJECT_EVENTS.LOKI_DID_LOAD, payload: { loadedCollections, projectId, status: true } }])
                );
              } else {
                const configKey = '@' + feature + '_' + featurePath + ':' + projectId;
                let value = null;
                if (platform == 'web') value = await platformActions.storage.getItem(configKey);
                else {
                  const realm = platformActions.localDB.getCementoDB();
                  const query = `id = "${configKey}"`;
                  const items = realm.get('reducerPersist', query);
                  const item = items?.[0];
                  if (item?.json) {
                    value = item.json;
                  }
                  // This is a work around to make migration to Realm smooth for our users
                  // Code below falls over to the previous approach of storage management
                  // https://cemento.atlassian.net/browse/CEM-11732
                  // TODO: Remove it in future releases
                  else {
                    value = await legacyLoadMobileStorage({ configKey, platform });
                  }
                }

                if (value) projectSavedJson.push({ feature, featurePath, value });
              }
            } catch (err) {
              console.warn('PROJECT_STORAGE_LOAD error:' + projectId);
              console.warn(err);
            }
          }
        }
      } finally {
        loadProjectStorageProgressManager.set(projectId, false);
      }

      return { projectSavedJson, projectId, didFind: projectSavedJson.length > 0 };
    };

    return {
      type: PROJECT_EVENTS.LOAD_PROJECT_STORAGE,
      payload: getPromise(),
    };
  };
};

export const unloadProjectStorage = (projectId) => {
  return () => {
    const getPromise = async () => {
      if (platformActions.app.isWeb()) {
        await lokiInstance.unloadProjectDataFromStorage(projectId, (loadedCollections) =>
          batchDispatch([
            {
              type: PROJECT_EVENTS.LOKI_DID_LOAD,
              payload: { loadedCollections, projectId, status: false },
            },
          ])
        );
      }
    };

    return {
      type: PROJECT_EVENTS.UNLOAD_PROJECT_STORAGE,
      payload: getPromise(),
    };
  };
};

export const enterProject = (projectId, lastVisitedProjectId) => {
  return ({ dispatch }) => {
    const getPromise = async () => {
      setServerSdkAuthClientParams({ projectId });
      await dispatch(loadProjectStorage(projectId));

      return { projectId, lastVisitedProjectId };
    };

    return {
      type: PROJECT_EVENTS.ENTER_PROJECT,
      payload: getPromise(),
    };
  };
};

export const leaveProject = (projectId) => {
  return ({ dispatch }) => {
    ClientServerConnectivityManager.unregisterAllScopeServices('projects', projectId);
    dispatch(unloadProjectStorage(projectId));
    return {
      type: PROJECT_EVENTS.LEAVE_PROJECT,
      payload: { projectId },
    };
  };
};

export const setProjectIntl = (intl) => {
  return {
    type: PROJECT_EVENTS.SET_PROJECT_INTL,
    payload: { intl },
  };
};