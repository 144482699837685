import { platformActions } from '../platformActions';
import { envParams } from '../../common/configureMiddleware';
import * as propertyTypes from '../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { checkPropSettings, instanceDataToStringNoIntl, populateObject } from '../propertiesInstances/funcs';
import moment from 'moment-timezone';
import theme from '../../web/assets/css/theme';
import { getFormStatusParams } from '../forms/formsStatusStates';
import { getStatusParams } from '../issues/issueStates';
import { ORIGINAL_VALUE_KEY_TERM } from '../../web/views/Reports/tableWrapperHelpers';
import TableToolTip from '../../web/components/Table/TableToolTip';
import { isEmptyValue } from '../app/funcs';

export const columnTypes = { 'main': 1, 'sub': 2 };

const STATUS_COLORS = {
	expired: theme.brandDanger,
	warning: theme.brandWarning,
	active: theme.brandSuccess,
};
export function cellContentCalculator(
	inValue,
	columnFullProp,
	intl,
	cellObject,
	originalValue = null,
	instanceParentValueOrigin = null,
	noTooltip,
) {
	const { type, settings, values, businessType, universalId, id: columnId } = columnFullProp;
	let displayValue,
		displayType,
		textValue = '';
	let displayParams = Object.assign({}, cellObject._aggregatedValueDisplayParams);

	let value = !_.isNil(inValue) ? inValue : _.get(settings, 'defaultVal', null);

	if (_.isNil(value) && type != propertyTypes.CERTIFICATION && type != propertyTypes.SIGNATURE) {
		displayValue = null;
		displayType = null;
	} else {
		switch (type) {
			case propertyTypes.ARRAY:
				displayType = 'String';
				displayValue = '';
				break;

			case propertyTypes.SIGNATURE:
				const signaturesTemplates = _.get(columnFullProp, ['settings', 'signatures'], {});
				value = _.every(signaturesTemplates, (sign, key) => Boolean(_.get(value, [key, 'uri'])));
				displayType = 'Boolean';
				displayValue = value;
				textValue = instanceDataToStringNoIntl(columnFullProp, value);
				break;

			case propertyTypes.BOOLEAN:
				displayType = 'Boolean';
				displayValue = typeof value === 'boolean' ? value : null;
				textValue = instanceDataToStringNoIntl(columnFullProp, value);
				break;

			case propertyTypes.TIME_RANGE:
				displayValue = null;
				displayType = 'String';
				if (value.startTS && value.endTS) {
					const startTime = moment(value.startTS).format('HH:mm');
					const endTime = moment(value.endTS).format('HH:mm');
					displayValue = `${startTime} - ${endTime}`;
					displayParams.toolTipParams = { startTime, endTime };
					displayParams.direction = 'ltr';
				}
				break;

			case propertyTypes.SELECTION_LIST:
				let cellOptions = values || [];
				let optionTitle = value;
				if (value && typeof value == 'object') {
					if (businessType === 'employees') optionTitle = _.head(_.keys(optionTitle));
					else {
						let selectedOption = cellOptions.filter(option => option.id == Object.keys(optionTitle)[0])[0];
						optionTitle = (selectedOption || {}).getCementoTitle();
					}
				}

				displayType = 'String';
				displayValue = optionTitle;
				textValue = optionTitle;
				break;

			case propertyTypes.DATE:
			case propertyTypes.CERTIFICATION:
				let valueToRender = value;
				let cellSettings = settings;
				let info = checkPropSettings({ type, settings: cellSettings }, value, intl);

				if (type === propertyTypes.CERTIFICATION) {
					let valueArrayLength = value && value.length;
					valueToRender = _.get(value, [valueArrayLength - 1, 'certificationTTL']);
					if (info.missingRequirementList) valueToRender = Date.now();
				}

				displayType = 'Date';
				displayValue = valueToRender;
				textValue = instanceDataToStringNoIntl(columnFullProp, value);
				displayParams.missingRequirementList = info.missingRequirementList;
				displayParams.isWarning = info.isWarning;
				displayParams.isExpired = info.isExpired || info.missingRequirementList;
				displayParams.color = displayParams.isExpired
					? STATUS_COLORS.expired
					: displayParams.isWarning
					? STATUS_COLORS.warning
					: STATUS_COLORS.active;
				break;

			case propertyTypes.PICTURE:
			case propertyTypes.VIDEO:
			case propertyTypes.PDF:
			case propertyTypes.FILES_ARRAY:
			case propertyTypes.DRAWINGS_ARRAY:
				let filesContent = Array.isArray(value) ? value : value ? [value] : [];
				filesContent = filesContent.filter(f => Boolean(f && !f.isDeleted && !f.isArchive));

				displayType = 'Files';
				if (!filesContent || !filesContent.length) displayValue = null;
				else displayValue = filesContent.length;
				break;

			case propertyTypes.LOCATION:
				displayType = 'String';
				if (value) {
					displayValue = instanceDataToStringNoIntl(columnFullProp, value);
					textValue = displayValue;
				}
				break;

			case propertyTypes.NUMBER:
				displayType = 'Number';
				displayValue = Number(value);
				if (_.isNaN(displayValue)) displayValue = null;
				else {
					inValue = displayValue;
					textValue = String(displayValue);
				}
				break;

			default:
				displayType = 'String';
				displayValue = value;
				if (universalId === 'status') {
					displayType = 'Status';
					displayParams.items = [{ ...getFormStatusParams(cellObject), id: `status-${columnId}-${cellObject.id}` }];
				}
				textValue = displayValue;
				break;
		}

		textValue = String(textValue);

		if (universalId === 'status' || businessType === propertyTypes.BUSINESS_TYPES.status) {
			displayType = 'Status';
			let statusParams = universalId === 'issueState' ? getStatusParams(cellObject) : getFormStatusParams(cellObject);
			displayParams.items = [{ ...statusParams, id: `status-${columnId}-${cellObject.id}` }];
		} else if (businessType === propertyTypes.BUSINESS_TYPES.trades) displayType = 'Trade';
		if (!noTooltip && platformActions.app.getPlatform() === 'web') {
			displayParams.toolTipParams = {
				get titleOnHover() {
					return (
						<TableToolTip
							columnId={columnFullProp.id}
							columnoriginId={columnFullProp.originColumnId}
							columnSubjectName={columnFullProp.subjectName}
							instanceParentValueOrigin={instanceParentValueOrigin}
							originalValue={originalValue}
							cellObject={cellObject}
							universalId={universalId}
							shouldLimitResults={true}
							maxAmountOfElemnts={3}
						/>
					);
				},
			};
		}
	}

	const overrideDisplayParams = _.get(columnFullProp, 'generalDisplayParams');
	if (!_.isNil(overrideDisplayParams)) displayParams = _.merge(overrideDisplayParams, displayParams);
	return { displayValue, displayType, displayParams, [ORIGINAL_VALUE_KEY_TERM]: value, textValue };
}

export async function getDashboardData(projectId, type, name, extra, ignoreTotal, showZeroValues, ignoreTotalLabel) {
	const { apiServer } = envParams;
	let ret;
	let body = {
		projectId,
		type,
		name,
	};

	if (extra) Object.assign(body, extra);

	try {
		var resp = await platformActions.net.fetch(apiServer + '/v1/analytics/dashboards', {
			method: 'POST',
			body: JSON.stringify(body),
		});

		let jsonResp = await resp.json();

		if (!jsonResp) return null;

		let labels = null;
		let groupByMetaData = jsonResp.groupByMetaData;
		let extraData = jsonResp.extraData;
		if (jsonResp.groupByMetaData && jsonResp.groupByMetaData.length > 0)
			labels = _.values(jsonResp.groupByMetaData[jsonResp.groupByMetaData.length - 1]).map(x => x.title);

		let originalData = jsonResp.data;
		let numOfGroupBy = extra && extra.groupBy ? extra.groupBy.length : 1;
		// Remove null values cell
		let data = {};
		Object.keys(originalData).forEach(key => {
			if (originalData[key] != null && (originalData[key] != 0 || showZeroValues)) data[key] = originalData[key];
		});

		if (data && !ignoreTotal) {
			Object.keys(data).forEach(firstKey => {
				if (ignoreTotalLabel && firstKey === 'total') return;
				if (numOfGroupBy == 1 && !data[firstKey].total) data[firstKey] = { total: data[firstKey] };
				else if (numOfGroupBy == 2)
					Object.keys(data[firstKey]).forEach(secKey => {
						if (data[firstKey][secKey] && !data[firstKey][secKey].total)
							data[firstKey][secKey] = { total: data[firstKey][secKey] };
					});
			});
		}

		ret = { labels, data, groupByMetaData, extraData };
	} catch (error) {
		console.error(error);
		return null;
	}

	return ret;
}

export async function getChecklistReport(projectId, instanceType, newApi, startTS, endTS, userId) {
	let originalData;
	let success;
	let checklistItemMode = instanceType == 'checklistItem';
	const { apiServer } = envParams;
	try {
		let url = apiServer + '/v1/analytics/tables?projectId=' + projectId + '&v2=true' + (newApi ? '&newApi=true' : '');
		if (checklistItemMode) url += '&itemsMode=true';
		if (startTS) url += '&startTS=' + startTS;
		if (endTS) url += '&endTS=' + endTS;
		if (userId) url += '&userId=' + userId;

		let resp = await platformActions.net.fetch(url);
		originalData = await resp.getJson();
	} catch (error) {
		console.error(error);
		success = false;
		return null;
	}

	return originalData;
}

export function filterChecklist(originalData, checklistItemIdFilter) {
	// Group by [unit]
	let rows = {};
	let columns = {};
	let entireTradesIds = {};

	if (!originalData || !originalData.groupByMetaData || !originalData.data) return { rows, columns, entireTradesIds };

	let metadataGroup = {};

	let indexes = { building: 0, floor: 1, unit: 2, checklist: 3, checklistItem: 4 };
	let groupByParams = {
		[indexes.building]: 'building',
		[indexes.floor]: 'floor',
		[indexes.unit]: 'unit',
		[indexes.checklist]: 'checklist',
		[indexes.checklistItem]: 'checklistItem',
	};
	let orderMultiplaier = { building: 0, floor: 1, unit: 2, checklist: 3, checklistItem: 4 };

	//let params = {total:0, confirmed:1, issues:2, resolved:3, confirm2:4, partial:5, irrelevant:6};
	let params = { total: 0, confirmed: 1, issues: 2, resolved: 3, confirm2: 4, partial: 5, grade: 6, gradeItems: 100 };
	let initZeroParams = {
		total: true,
		confirmed: true,
		issues: true,
		resolved: true,
		confirm2: true,
		partial: true,
		grade: false,
		gradeItems: true,
	};
	let statusParams = {
		total: false,
		confirmed: true,
		issues: false,
		resolved: true,
		confirm2: true,
		partial: true,
		grade: false,
		gradeItems: false,
	};
	originalData.groupByMetaData.forEach((currGroup, i) => {
		metadataGroup[groupByParams[i]] = currGroup;
	});

	let checklistTradesList = {};

	const checklistItemMode = Boolean(checklistItemIdFilter);

	originalData.data.forEach(row => {
		// Keys
		const buildingId = row.groupBy[indexes.building];
		const floorId = row.groupBy[indexes.floor];
		const unitId = row.groupBy[indexes.unit];
		const originalChecklistId = row.groupBy[indexes.checklist];
		const checklistId = originalChecklistId; //'chklist-'.concat(originalChecklistId);

		const isChecklistItem = Boolean(row.groupBy[indexes.checklistItem]);
		const originalChecklistItemId = isChecklistItem ? row.groupBy[indexes.checklistItem] : null;
		const checklistItemId = originalChecklistItemId
			? 'checklistItem-'.concat(originalChecklistId).concat('-').concat(originalChecklistItemId)
			: null; // The prefix is added to fix a case where checklist and checklistItem have the same ID

		let id;
		let title;
		let itemNumber;
		if (isChecklistItem) {
			title = metadataGroup['checklistItem'][originalChecklistItemId].title;
			itemNumber = metadataGroup['checklistItem'][originalChecklistItemId].num;
			id = checklistItemId;
		} else {
			title = metadataGroup['checklist'][originalChecklistId].title;
			id = checklistId;
		}

		let rowId = id; // title.replaceAll('.', '&#46;');

		let val = {};
		if (row.values)
			if (!checklistItemMode || (checklistItemMode && isChecklistItem))
				// Init checklist params to zero if in checklistItemMode
				Object.keys(params).forEach(
					param =>
						(val[param] =
							row.values[params[param]] != null ? row.values[params[param]] : initZeroParams[param] ? 0 : null),
				);
			else Object.keys(params).forEach(param => (val[param] = initZeroParams[param] ? 0 : null));

		// Add the beforeRange and afterRange values if exists
		['beforeRange', 'afterRange'].forEach(currRange => {
			if (row.beforeRange)
				Object.keys(params).forEach(param => {
					if (!statusParams[param]) return;

					if (val[currRange] == null) val[currRange] = 0;
					if (row[currRange][params[param]]) val[currRange] += row[currRange][params[param]];
				});
		});

		// Deal with building checklist
		if (
			(!checklistItemMode && !val.total) ||
			(checklistItemMode && isChecklistItem && !val.total) ||
			(!unitId && !floorId && !buildingId)
		)
			// val.total = Case where there is a checklist without any items below it
			return;

		if (checklistItemMode && isChecklistItem && checklistItemIdFilter[originalChecklistItemId])
			[buildingId, floorId, unitId].forEach(locationId => {
				if (!locationId || !rows[locationId] || !rows[locationId]['values'][checklistId]) return;
				Object.entries(val).forEach(([valKey, val]) => {
					if (rows[locationId]['values'][checklistId].hasOwnProperty(valKey))
						rows[locationId]['values'][checklistId][valKey] += val;
					else rows[locationId]['values'][checklistId][valKey] = val;
				});
			});

		// Build the building
		if (buildingId) {
			if (!rows[buildingId])
				rows[buildingId] = {
					type: 'building',
					id: buildingId,
					title: metadataGroup['building'][buildingId].title,
					buildingId,
					order: metadataGroup['building'][buildingId].num,
					values: {},
					rowLevel: 1,
				};

			if (!rows[buildingId]['values'][rowId]) rows[buildingId]['values'][rowId] = Object.assign({}, val);
			//{confirmed: row.confirmed, issues: row.rejected, total: row.total}; // Set the only value
			else {
				rows[buildingId]['values'][rowId].resolved += val.resolved;
				rows[buildingId]['values'][rowId].confirm2 += val.confirm2;
				rows[buildingId]['values'][rowId].confirmed += val.confirmed;
				rows[buildingId]['values'][rowId].partial += val.partial;
				//rows[buildingId]['values'][rowId].irrelevant  += val.irrelevant;
				rows[buildingId]['values'][rowId].issues += val.issues;
				rows[buildingId]['values'][rowId].total += val.total;
				rows[buildingId]['values'][rowId].beforeRange += val.beforeRange;
				rows[buildingId]['values'][rowId].afterRange += val.afterRange;
				rows[buildingId]['values'][rowId].grade += val.grade;
			}

			rows[buildingId]['values'][rowId].gradeItems += val.grade != null ? 1 : 0;

			if (floorId) rows[buildingId]['values'][rowId].isLocationsAggrigation = true;
		}

		// Build the floor
		if (floorId) {
			if (!rows[floorId])
				rows[floorId] = {
					type: 'floor',
					id: floorId,
					parentId: buildingId,
					title: metadataGroup['floor'][floorId].title,
					floorId,
					buildingId,
					order: metadataGroup['floor'][floorId].num,
					values: {},
					rowLevel: 2,
				};

			if (!rows[floorId]['values'][rowId]) rows[floorId]['values'][rowId] = Object.assign({}, val);
			//{confirmed: row.confirmed, issues: row.rejected, total: row.total}; // Set the only value
			else {
				rows[floorId]['values'][rowId].resolved += val.resolved;
				rows[floorId]['values'][rowId].confirm2 += val.confirm2;
				rows[floorId]['values'][rowId].confirmed += val.confirmed;
				rows[floorId]['values'][rowId].partial += val.partial;
				//rows[floorId]['values'][rowId].irrelevant  += val.irrelevant;
				rows[floorId]['values'][rowId].issues += val.issues;
				rows[floorId]['values'][rowId].total += val.total;
				rows[floorId]['values'][rowId].beforeRange += val.beforeRange;
				rows[floorId]['values'][rowId].afterRange += val.afterRange;
				//rows[floorId]['values'][rowId].grade     += val.grade;
				rows[floorId]['values'][rowId].grade += val.grade;
			}

			rows[floorId]['values'][rowId].gradeItems += val.grade != null ? 1 : 0;

			if (unitId) rows[floorId]['values'][rowId].isLocationsAggrigation = true;
		}

		if (unitId) {
			// Build the unit
			if (!rows[unitId])
				rows[unitId] = {
					type: 'unit',
					id: unitId,
					parentId: floorId,
					title: metadataGroup['unit'][unitId].title,
					unitId,
					floorId,
					buildingId,
					order: metadataGroup['unit'][unitId].num,
					typeId: metadataGroup['unit'][unitId].typeId,
					values: {},
					rowLevel: 3,
				};
			rows[unitId]['values'][rowId] = Object.assign({}, val);
			if (val.grade != null) rows[unitId]['values'][rowId].gradeItems = 1;
		}

		if (!columns[rowId]) {
			// TODO: Return the section
			let order;
			let section;
			let parentIds;
			let parentId = originalChecklistId;

			let trades = {};
			if (!isChecklistItem) {
				order = metadataGroup['checklist'][originalChecklistId].num * 1000;
				section = metadataGroup['checklist'][originalChecklistId].parentTitle;
			} else {
				// parentIds = originalChecklistId;//metadataGroup['checklistItem'][originalChecklistItemId].parentIds;
				//[originalChecklistId].loopEach(parentId => {
				order =
					metadataGroup['checklist'][parentId].num * 1000 + metadataGroup['checklistItem'][originalChecklistItemId].num;
				section = metadataGroup['checklist'][originalChecklistId].parentTitle;

				// Add the trades
				let currTradeId = metadataGroup['checklistItem'][originalChecklistItemId].tradeId;
				if (metadataGroup['checklistItem'][originalChecklistItemId].tradeId) trades[currTradeId] = true;
				if (!checklistTradesList[parentId]) checklistTradesList[parentId] = {};
				if (!checklistTradesList[parentId][currTradeId]) checklistTradesList[parentId][currTradeId] = true;
				if (!entireTradesIds[currTradeId]) entireTradesIds[currTradeId] = true;
				// })
			}

			if (!columns[checklistId]) columns[checklistId] = { subColumns: {} };

			if (!isChecklistItem)
				columns[checklistId] = Object.assign(columns[checklistId], {
					id: checklistId,
					checklistId,
					checklistItemId,
					description: rowId,
					columnType: isChecklistItem ? columnTypes.sub : columnTypes.main,
					parentIds,
					type: unitId ? 'unit' : floorId ? 'floor' : 'building',
					title,
					itemNumber,
					order,
					section,
					trades,
				});
			else {
				//parentIds.loopEach(parentId => {
				columns[parentId].subColumns[checklistItemId] = {
					id: checklistItemId,
					parentId,
					checklistId: parentId,
					checklistItemId: originalChecklistItemId,
					description: rowId,
					columnType: isChecklistItem ? columnTypes.sub : columnTypes.main,
					parentIds,
					type: unitId ? 'unit' : floorId ? 'floor' : 'building',
					title,
					itemNumber,
					order,
					section,
					trades,
				};
				//})
			}
		}
	});

	// Add the trades to each column
	Object.keys(checklistTradesList).forEach(checklistId => {
		if (columns[checklistId]) columns[checklistId].trades = checklistTradesList[checklistId];
	});

	rows = _.values(rows);
	columns = _.values(columns);

	return { rows, columns, entireTradesIds };
}

export function preProcessData({
	propertiesTypes,
	propertiesMappings,
	subjectType,
	companiesMap,
	scopeId,
	intl,
	allProps,
}) {
	const subjectName = `${subjectType}Info`;
	let scopePropertiesTypes = _.get(propertiesTypes, [subjectName]);
	let newPropertiesTypes = propertiesTypes;

	let relevantPopulatedObjects = {};
	let innerAllProps = allProps;

	_.entries(allProps).forEach(([objectId, objectValues]) => {
		let companiesDailyLogSum = {
			'-employeeAttendanceNumber': 0,
			'-employeesAttendance': {},
			'-notes': [],
		};

		_.entries(objectValues.props).forEach(([propertyId, propertyData]) => {
			if (isEmptyValue(propertyData)) return;
			const { type, businessType, data } = propertyData;
			const currentPropertyDataSource = _.get(propertyData, ['fullProp', 'settings', 'dataSource'], 'instance');

			if (currentPropertyDataSource === 'object') {
				const currentPropertyUniversalId = _.get(propertyData, ['fullProp', 'universalId']);
				const objectValueBasedOnUniversalId = _.get(objectValues, currentPropertyUniversalId);
				if (objectValueBasedOnUniversalId)
					_.set(innerAllProps, [objectId, 'props', propertyId, 'data'], objectValueBasedOnUniversalId);
			}
			if (type === 'SelectionList' && businessType === 'employees') {
				if (!relevantPopulatedObjects[businessType]) {
					const { allProps: populatedEmployees } = populateObject({
						selectedProjectId: scopeId,
						subjectType: businessType,
						propertiesTypes,
						inPropertiesMappings: propertiesMappings,
						intl,
					});
					relevantPopulatedObjects[businessType] = populatedEmployees;
				}

				let employeeId = _.head(_.keys(data || {}));
				let employeeName = _.get(relevantPopulatedObjects, [businessType, employeeId, 'primaryProp', 'data']);

				_.set(innerAllProps, [objectId, 'props', propertyId, 'data'], employeeName);
			}
		});
	});

	newPropertiesTypes[subjectName] = scopePropertiesTypes;
	return { propertiesTypes: newPropertiesTypes, allProps: innerAllProps };
}

export const getSortFunc = ({ subjectType, contentType, primaryColumn, trades }) => {
	if (subjectType === 'posts') {
		const tradesMap = trades.toJS();
		return (rowA, rowB) => {
			try {
				let stringValueA = '';
				let stringValueB = '';
				if (rowA[primaryColumn.title] || rowB[primaryColumn.title]) {
					stringValueA = rowA[primaryColumn.title];
					stringValueB = rowB[primaryColumn.title];
				} else {
					const tradeIdA = rowA.values?.[primaryColumn.id]?.originalValue;
					const tradeIdB = rowB.values?.[primaryColumn.id]?.originalValue;
					stringValueA = tradesMap[tradeIdA]?.getCementoTitle() || rowA.values?.[primaryColumn.id]?.textValue;
					stringValueB = tradesMap[tradeIdB]?.getCementoTitle() || rowB.values?.[primaryColumn.id]?.textValue;
				}

				const result = stringValueA.localeCompare(stringValueB, undefined, {
					ignorePunctuation: true,
					sensitivity: 'accent',
					usage: 'sort',
				});

				return result;
			} catch (error) {
				return -1;
			}
		};
	}
	if (subjectType === 'locations' && contentType === 'info') {
		return (rowA, rowB) => {
			try {
				return rowA.order - rowB.order;
			} catch (error) {
				return 0;
			}
		};
	}

	return null;
};
