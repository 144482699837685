import { Record } from '../transit';

const Member = Record({
  id: null, // User ID in case he does have one
  isMember: true,  
  localDisplayName: null,
  localId: null,    // recordId: null,
  localAvatar: null, 
  displayName: null,
  email: null,
  phoneNumber: null,
  idNumber: null,
  thumbnailPath: null,
  avatar: null,
  projects: null,   
  trades: null,
  groups: null,
  title: null,
  companyId: null,
  suprv: null,
  workingArea: null,
  about: null,
  activated: null,
  backgroundImage: null,
  rank: null,
}, 'member');

export default Member;
 