import React from "react";
import Collapse from "@kunukn/react-collapse";

// Components
import GridItem from "../Grid/GridItem";
import Text from "./Text";
import EditStrip from "./EditStrip";
import Dragger from "./Dragger";

// Other
import theme from "../../assets/css/theme";
import collapse from "../../assets/img/icons/collapse.png";
import { hoistStatics, compose } from "recompose";
import { connect } from "react-redux";
import NoItemsFound from "./NoItemsFound";

class CollapsibleSection extends React.Component {
  constructor(props) {
    super(props);
    this.collapseTransition = this.collapseTransition.bind(this);
    this.setHoverTrue = this.setHoverTrue.bind(this);
    this.setHoverFalse = this.setHoverFalse.bind(this);
    this.setOpen = this.setOpen.bind(this);

    this.state = {
      collapseClassName: "collapse-css-transition",
      open: Boolean(props.open),
      deg: 0,
      hover: false,
    };
  }

  setHoverTrue() {
    this.setState({ hover: true });
  }

  setHoverFalse() {
    this.setState({ hover: false });
  }

  setOpen() {
    const { open } = this.state;
    this.setState({
      open: !open,
      collapseClassName: "collapse-css-transition",
    });
  }

	collapseTransition({ isMoving, hasReversed }) {
		const { open } = this.state;

		if (!isMoving && !hasReversed && open) this.setState({ collapseClassName: 'collapse-css-transition-visible' });
	}

	render() {
		const { rtl, children, section, keyId, onSelect = () => {}, isSelected = false, isFocused = false, mainContentStyle = {}, stripIcons = null, draggerId = null, sideCard = false, childrenOnly, mainContainerStyle = {} } = this.props;
		const { footerComponent, footerContainerStyle = {}, rowEndComponent } = this.props;
		const { open, hover, collapseClassName } = this.state;
		const { inFocus, selected, sectionTitleMainContainer, sectionTitleContainer, collapseIcon, sectionTitle, mainContent, dragIcon } = styles;
	
		if (childrenOnly)
			return <div style={{ ...mainContent, ...mainContentStyle }}>{children}</div>;
		
    let hoverOrOpen = open || hover;

    let mainContainer = Object.assign({}, styles.mainContainer);
    if (sideCard) mainContainer.paddingMultiplier = 3;

    return (
      <GridItem
        onClick={onSelect ? onSelect : undefined}
        key={keyId}
        xs={12}
        onMouseEnter={this.setHoverTrue}
        onMouseLeave={this.setHoverFalse}
        style={{
          ...mainContainer,
          ...(hover || isSelected || isFocused ? inFocus : {}),
          ...(isSelected ? { background: theme.backgroundColorSelected } : {}),
        }}
      >
        <div style={sectionTitleMainContainer} onClick={this.setOpen}>
          {Boolean(hover && draggerId) && (
            <Dragger
              dragId={draggerId}
              style={{
                ...dragIcon,
                [rtl ? "right" : "left"]: sectionTitleHeight * 0.41,
              }}
            />
          )}
          <div style={sectionTitleContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={collapse}
                style={{
                  ...collapseIcon,
                  transform: Boolean(hoverOrOpen)
                    ? "rotate(90deg)"
                    : rtl
                    ? "rotate(180deg)"
                    : "",
                  [rtl ? "right" : "left"]: theme.paddingSize,
                }}
              />
              <span
                style={{
                  ...sectionTitle,
                  ...((hover && !isFocused) || isSelected ? selected : {}),
                }}
              >
								<Text>{section.title}</Text>
							</span>
							{Boolean(rowEndComponent) && rowEndComponent}
						</div>
					</div>
          {Boolean(stripIcons && hover) && <EditStrip icons={stripIcons}/>}
				</div>

				{Boolean(true || open) &&
          <Collapse style={{ overflow: open ? 'visible' : 'hidden' }} className={collapseClassName} isOpen={open} onChange={this.collapseTransition}>
            <div style={mainContainerStyle}>
              <div style={{ ...mainContent, ...mainContentStyle }}>
                {Boolean(children && children.length !== 0) 
                  ? children
                  : <NoItemsFound compStyles={{ height: theme.headerHeight }}/>
                }
              </div>

              {Boolean(footerComponent) && (
                <div style={{ display: 'flex', minHeight: sectionTitleMainContainer.height, ...footerContainerStyle }}>
                  {footerComponent}
                </div>
              )}
            </div>
          </Collapse>
        }
			</GridItem>
		);
	}
}

const sectionTitleHeight = 50;

const styles = {
  mainContainer: {
    boxSizing: "border-box",
    minWidth: "390px",
    borderTop: "2px solid transparent",
    borderBottom: theme.borderLineHeaderInfo,
    paddingMultiplier: 6,
    get padding() {
      return `0 ${this.paddingMultiplier * theme.padding}px`;
    },
  },
  inFocus: {
    borderTop: "2px solid",
    borderBottom: "2px solid",
    marginBottom: "-1px",
    borderColor: theme.brandPrimary,
  },
  selected: {
    color: theme.brandPrimary,
  },
  sectionTitleMainContainer: {
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flex: 1,
    height: sectionTitleHeight,
    cursor: "pointer",
  },
  dragIcon: {
    alignSelf: "center",
    opacity: 0.15,
    height: sectionTitleHeight - 30,
    position: "absolute",
    cursor: "move",
  },
  sectionTitleContainer: {
    display: "flex",
    flex: 1,
    textOverflow: "ellipsis",
    height: "inherits",
    alignItems: "center",
  },
  collapseIcon: {
    padding: 0,
    height: 10,
    transition: "0.2s",
  },
  sectionTitle: {
    // color: theme.brandNeutralDark,
    fontWeight: theme.strongBold,
    fontSize: theme.fontSizeH5,
    marginLeft: theme.margin,
    marginRight: theme.margin,
    transform: "translateY(-1.4px)",
  },
  mainContent: {
    paddingBottom: theme.padding,
  },
};

const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

CollapsibleSection = enhance(CollapsibleSection);
export default CollapsibleSection;
