import React from "react";
import Text from "../../components/CementoComponents/Text";
import theme from "../../assets/css/theme";


export const CountBar = ({ value = 0, totalValue = 0, color = theme.brandPrimary, style }) => (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', ...style }}>
        <div style={{ width: `${100 * value / totalValue}%`, backgroundColor: theme.brandPrimary, display: 'flex', height: 10, borderRadius: '5px' }} />
        <Text style={{ marginRight: 5, marginLeft: 5, color, fontWeight: 500, fontSize: 16 }}>{value}</Text>
    </div>
)