import React from "react";
import { injectIntl } from 'react-intl';
import CustomInput from './CustomInput'

class IntlCustomInput extends React.Component {
  render() {
    const { labelText, values, intl } = this.props;
    let intlLabelText = labelText;
    if (typeof intlLabelText === 'object' && intlLabelText.defaultMessage)
      intlLabelText = intl.formatMessage(intlLabelText, values)
    return <CustomInput {...this.props} labelText={intlLabelText}/>
  }
}

export default injectIntl(IntlCustomInput);
