import immutable from 'immutable';
import invariant from 'invariant';
import transit from 'transit-immutable-js';
import _fp from 'lodash/fp';
import _  from 'lodash';
let records = [];
export let cementoRecords = {};

// Transit allows us to serialize / deserialize immutable Records automatically.
export const fromJSON = (string) => {
  let ret = string;

  if (typeof string === 'string') {
    let rec = transit.withRecords(records);
    ret = rec.fromJSON(string);
  }

  return ret;
}
export const toJSON = object => {
  let ret, ret2;
  try {
    ret = transit.withRecords(records);
    ret2 = ret.toJSON(object);
  }
  catch (err) {
    throw (err);
  }
  return ret2;
};

export const fromJSONnew = string => {
  var test = transit.withRecords(records).fromJSON(string)
  //var test = immutable.fromJS(string);
  //var test2 = immutable.Record(test, 'config')()
  return test;
};


/**
 * @template T extends { [propertyKey: string]: any }
 * @param {T} defaultValues 
 * @param {string} name 
 * @param {boolean} returnRecord 
 * @returns 
 */
// Record is just a wrapper for immutable.Record registering all Records.
// This is aspect-oriented programming. It's for cross-cutting concerns.
export const Record = (defaultValues, name, returnRecord = true) => {
  invariant(name && typeof name === 'string',
    'Transit Record second argument name must be a non empty string.');
  const ImmutableRecord = immutable.Record(defaultValues, name); // Need to keep doing this for backward compatibility
  records.push(ImmutableRecord);

  if (returnRecord)
    return ImmutableRecord;
  else {
    cementoRecords[name] = true;
    return class CementoStateObject extends CementoRecordObject {
      /**
      * @param {{ [propertyKey: string]: any }} properties 
      * @returns
      */
      constructor(properties) {
        super();
        Object.entries(defaultValues).forEach(([propertyName, propertyDefaultValue]) => {
          const isPropValueNil = _.isNil((properties || {})[propertyName]);
          if (isPropValueNil && _.isNil(propertyDefaultValue)) 
            return;
            
          this[propertyName] = isPropValueNil ? propertyDefaultValue : (properties || {})[propertyName];
        });
      }
    };
  }
};










 export class CementoRecordObject extends Object { // TODO: once we move to typescript include T as the return type
  constructor(properties) {
    super();
    Object.assign(this, properties);
  }

  /**
   * path.to.value || [path, to, value]
   * @typedef {string[] | string} PathToValue
   */

  /**
   *
   * @param {PathToValue} pathToValue
   * @param {any} value
   * @returns
   */
  setIn(pathToValue, value) {
    return _fp.setWith(((objectAtKey, key, parentObject) => parentObject[key] = !objectAtKey ? new CementoRecordObject : objectAtKey), pathToValue, value, this);
  }
  setNested(pathToValue, value) { return this.setIn(pathToValue, value) };
  set(pathToValue, value) { return this.setIn(pathToValue, value) };
  setMutate(pathToValue, value) { return _.setWith(this, pathToValue, value, ((objectAtKey, key, parentObject) => parentObject[key] = !objectAtKey ? new CementoRecordObject : objectAtKey)) };
  
  /**
   *
   * @param {PathToValue} pathToValue
   * @param {any} [defaultValue]
   * @returns
   */
  getIn(pathToValue, defaultValue) {
    if (Array.isArray(pathToValue) && pathToValue.length === 0)
      return this;

    let value = _.get(this, pathToValue);

    if (value === undefined)
      value = defaultValue;

    return value;
  }
  getNested(pathToValue, defaultValue) {
    if ((pathToValue || []).includes && pathToValue.includes('getTitle')) {
      const pathToTargetRoot = pathToValue.slice(0, pathToValue.length - 1);
      return (this.getIn(pathToTargetRoot) || {}).getCementoTitle();
    }
    else
      return this.getIn(pathToValue, defaultValue);
  };
  get(pathToValue, defaultValue) { return this.getIn(pathToValue, defaultValue) };
  
  /**
   *
   * @param {string[]} pathToValue
   * @returns
   */
  deleteIn(pathToValue) {
    pathToValue = Array.isArray(pathToValue) ? pathToValue : [pathToValue];
    const pathToTargetRoot = pathToValue.slice(0, pathToValue.length - 1);
    const targetRoot = this.getIn(pathToTargetRoot);
    
    let valueToSet;
    let didDelete = false;
    if (targetRoot) {
      const propertyToDeleteKey = pathToValue[pathToValue.length - 1];
      if (targetRoot[propertyToDeleteKey]) {
        valueToSet = new CementoRecordObject(targetRoot);
        delete valueToSet[propertyToDeleteKey];
        didDelete = true;
      }
    }

    return didDelete ? this.setIn(pathToTargetRoot, valueToSet) : this;
  }
  delete(pathToValue) { return this.deleteIn(pathToValue) };
  removeIn(pathToValue) { return this.deleteIn(pathToValue) };

  /**
   * @template T
   * @param {(itatee: T, index: number, array: T[]) => void} callback 
   * @returns 
   */
  forEach(callback) {
    return _fp.forEach(callback, Object.values(this));
  }

  /**
   * @template T
   * @param {(itatee: T, index: number, array: T[]) => void} callback 
   * @returns 
   */
  loopEach(callback) {
    const collectionClone = this.__cloneDeep(this);
    Object.entries(collectionClone).forEach(([key, value]) => callback?.(key, value, collectionClone));
  }

  __cloneDeep(object) {
    return cementoCloneDeep(object, CementoRecordObject);
  }

  get size() {
    return Object.keys(this).length;
  }
}

const cementoCloneDeep = (value, ObjectConstructor = Object, ArrayConstructor = Array) => {
  let clone = value;

  if (value && typeof value === 'object') {
    clone = new (Array.isArray(value) ? ArrayConstructor : ObjectConstructor);
    Object.entries(value).forEach(([key, value]) => clone[key] = cementoCloneDeep(value, ObjectConstructor, ArrayConstructor));
  }

  return clone;
}





// const ObjectClass = class {
//   /** 
//    * @param {{ [K in keyof T]: any }} properties 
//    * @returns T
//    */
//   constructor(properties) {
//     Object.entries(defaultValues).forEach(([propertyName, propertyDefaultValue]) => {
//       this[propertyName] = (properties || {})[propertyName] || propertyDefaultValue;
//     });
//   }
// }