import React, { Component } from "react";
import { Scrollbars } from "../Scrollbars/index"; //'react-custom-scrollbars';
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import theme from "../../assets/css/theme";

class MenuScrollbar extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      top: 0,
      vertHover: false,
      horzHover: false,
      horzDrag: false,
      vertDrag: false,
    };
    this.renderView = this.renderView.bind(this);
    //this.handleUpdate = this.handleUpdate.bind(this);
    this.renderThumbVertical = this.renderThumbVertical.bind(this);
    this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
    this.renderTrackVertical = this.renderTrackVertical.bind(this);
    this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
    this.setHoverHorizontal = this.setHoverHorizontal.bind(this);
    this.setDragHorizontal = this.setDragHorizontal.bind(this);
    this.setHoverVertical = this.setHoverVertical.bind(this);
    this.setDragVertical = this.setDragVertical.bind(this);
    this.getRef = this.getRef.bind(this);

    this.scrollSizeWithMargins = 20;
    this.scrollSize = 8;
    this.thumbStyle = {
      zIndex: theme.zIndexes.scrollBar,
      borderRadius: 4,
      //backgroundImage: 'url("https://raw.githubusercontent.com/rommguy/react-custom-scroll/227fd08727a1e51d38cbfe70bc115969dd9d00f3/example/giraffe-icon.png")',
      //backgroundRepeat: 'no-repeat no-repeat',
      //backgroundSize: 'cover'
    };
  }

  setHoverVertical() {
    this.setState({ vertHover: !this.state.vertHover });
  }
  setHoverHorizontal() {
    this.setState({ horzHover: !this.state.horzHover });
  }
  setDragHorizontal() {
    //  this.setState({horzDrag: !this.state.horzDrag})
  }
  setDragVertical() {
    //  this.setState({vertDrag: !this.state.vertDrag})
  }

  renderView({ style, ...props }) {
    const { rtl, isSmooth } = this.props;
    return (
      <div
        {...props}
        style={{
          ...style,
          scrollBehavior: isSmooth ? "smooth" : "none",
          margin: 0,
          overflow:'auto'
        }}
      />
    ); // marginRight: rtl ? 0 : -theme.nativeBrowserScrollbarSize, marginLeft: rtl ? -theme.nativeBrowserScrollbarSize : 0 }}/>);
  }

  renderTrackVertical({ style, ...props }) {
    return <div style={{ display: "none" }} />;

    const { rtl, withoutMargins, verticalStyle } = this.props;
    return (
      <div
        {...props}
        onMouseOver={this.setHoverVertical}
        onMouseOut={this.setHoverVertical}
        style={{
          ...style,
          top: 2,
          bottom: 2,
          left: verticalStyle ? verticalStyle.left : rtl ? 2 : null,
          right: verticalStyle ? verticalStyle.right : rtl ? null : 2,
          width: withoutMargins ? this.scrollSize : this.scrollSizeWithMargins,
        }}
      />
    );
  }
  renderThumbVertical({ style, ...props }) {
    return <div style={{ display: "none" }} />;

    const { zIndex } = this.props;
    const { vertHover, vertDrag } = this.state;
    let z = zIndex || this.thumbStyle.zIndex;
    return (
      <div
        onMouseDown={this.setDragVertical}
        onMouseUp={this.setDragVertical}
        style={{
          ...style,
          ...this.thumbStyle,
          zIndex: z,
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row-reverse",
          cursor: "grab",
        }}
        {...props}
      >
        <div
          style={{
            ...style,
            ...this.thumbStyle,
            zIndex: z,
            width: this.scrollSize,
            backgroundColor: vertDrag || vertHover ? "#9e6940" : "gray",
          }}
          {...props}
        />
      </div>
    );
  }

  renderTrackHorizontal({ style, ...props }) {
    return <div style={{ display: "none" }} />;

    const { withHorizontalScroll, withoutMargins } = this.props;
    if (withHorizontalScroll)
      return (
        <div
          {...props}
          onMouseOver={this.setHoverHorizontal}
          onMouseOut={this.setHoverHorizontal}
          style={{
            ...style,
            bottom: 2,
            borderRadius: 3,
            left: 2,
            right: 2,
            height: withoutMargins
              ? this.scrollSize
              : this.scrollSizeWithMargins,
          }}
        />
      );
    return <div />;
  }
  renderThumbHorizontal({ style, ...props }) {
    return <div style={{ display: "none" }} />;

    const { withHorizontalScroll, zIndex } = this.props;
    const { horzHover, horzDrag } = this.state;
    let z = zIndex || this.thumbStyle.zIndex;
    if (withHorizontalScroll)
      return (
        <div
          onMouseDown={this.setDragHorizontal}
          onMouseUp={this.setDragHorizontal}
          style={{
            ...style,
            ...this.thumbStyle,
            zIndex: z,
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column-reverse",
            cursor: "grab",
          }}
          {...props}
        >
          <div
            style={{
              ...style,
              ...this.thumbStyle,
              zIndex: z,
              height: this.scrollSize,
              backgroundColor: horzDrag || horzHover ? "#9e6940" : "gray",
            }}
            {...props}
          />
        </div>
      );
    return <div />;
  }

  getRef(r) {
    const { getScrollRef } = this.props;
    this.scrollRef = r;
    if (getScrollRef && this.scrollRef && this.scrollRef.view)
      getScrollRef(this.scrollRef.view, this.scrollRef);
  }

  render() {
    const { children, style, onScrollFrame, scrollbarsStyle } = this.props;
    return (
      <Scrollbars
        // rtl={String(this.props.rtl)}
        ref={this.getRef}
        onScrollFrame={onScrollFrame}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderThumbVertical={this.renderThumbVertical}
        renderTrackVertical={this.renderTrackVertical}
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderView={this.renderView}
        children={children}
        style={scrollbarsStyle}
        //{...this.props}
      />
    );
  }
}

const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);
export default enhance(MenuScrollbar);
