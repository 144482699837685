import _ from 'lodash';
import { onError } from '../app/funcs';
import { firebaseDeps, getAppState } from '../configureMiddleware';
import ExtraError, { errorCodes } from '../lib/errors/extraError';
import { platformActions } from '../platformActions';
import mime from 'mime';

let uploadVideoTasks = {};

export const uploadVideo = async (video, targetFileName, serverFolder, callback, videoId, statusCb) => {
  const isConnected = getAppState().getNested(['app', 'isConnected'], false);
  if (!isConnected) throw new ExtraError('uploadVideo - no reception');

  let ret = null;
  let downloadURL = null;
  let shortPath = null;
  const platform = platformActions.app.getPlatform();
  let fileUriString = video.uri;

  try {
    if (!fileUriString.includes(';base64,')) {
      shortPath = fileUriString.replace('file://', '');

      if (platform !== 'web') {
        const localDB = platformActions.localDB.getCementoDB();
        let urlCache = await localDB.get('urlCache', `id == "${shortPath}"`);
        const uri = urlCache[0]?.uri;
        if (uri) return videoId ? { videoId, uri } : uri;
      }

      // Check if video file still available
      try {
        let exist = await platformActions.fs.exists(shortPath);
        if (!exist) throw 'file is not exist';
      } catch (err) {
        console.log(err);
        throw new ExtraError('video upload file missing', { shortPath }, err, errorCodes.MISSING_FILE);
      }
    }

    const uploadPromise = (videoObj, targetFileName, serverFolder, shortPath, statusCb) => {
      return new Promise(async function (resolve, reject) {
        let uploadTask = null;

        setTimeout(() => {
          if (uploadTask && uploadTask.cancel) uploadTask.cancel();
          delete uploadVideoTasks[targetFileName];
          reject('Uploading video timed out');
        }, 3 * 60 * 1000); // Increased timeout for videos

        let contentType = null;
        let extension = null;

        if (platform !== 'web') {
          extension = fileUriString.split('.').pop();
          contentType = mime.getType(fileUriString);
        } else {
          contentType = fileUriString.split(';')[0].split(':')[1];
          extension = contentType.split('/')[1];
        }

        let fileName = videoObj.name || '';
        if (Array.isArray(fileName) && fileName.length) fileName = fileName[0];

        const customMetadata = { fileName };

        try {
          const fullFileName = `${targetFileName}_${Date.now()}.${extension.toLowerCase()}`;

          if (platform === 'web') {
            const base64Data = fileUriString.split(',')[1];
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });

            uploadTask = firebaseDeps
              .firebaseStorage()
              .ref(serverFolder)
              .child(fullFileName)
              .put(blob, { customMetadata, contentDisposition: 'inline', contentType });
          } else {
            uploadTask = firebaseDeps
              .firebaseStorage()
              .ref(serverFolder)
              .child(fullFileName)
              .putFile(shortPath, { contentType });
          }

          // This part should work for both web and mobile, adjust the callback to handle progress
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              statusCb?.({
                status: snapshot.state,
                progress,
              });
            },
            (error) => {
              platformActions.fs.exists(videoObj.uri).then((isFileExist) => {
                let message = 'Failed getting download URL';
                switch (error.code) {
                  case 'storage/unauthorized':
                    message = "User doesn't have permission to access the object";
                    break;
                  case 'storage/canceled':
                    message = 'User canceled the upload';
                    break;
                  case 'storage/unknown':
                    break;
                }
                onError({
                  errorMessage: message,
                  methodMetaData: {
                    args: { targetFileName, serverFolder, shortPath, videoId },
                    name: 'uploadVideo',
                  },
                  errorMetaData: {
                    filePath: videoObj.uri,
                    isFileExist,
                  },
                });
              });
            }
          );

          await uploadTask;
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(url);
        } catch (e) {
          console.warn('Error firebaseStorage.upload!!', e);
          reject(e);
        }
      });
    };

    if (fileUriString.startsWith('https://')) downloadURL = fileUriString;
    else {
      if (!uploadVideoTasks[targetFileName]) {
        uploadVideoTasks[targetFileName] = uploadPromise(
          { uri: fileUriString, type: video.type, name: video.name, extension: video.extension },
          targetFileName,
          serverFolder,
          shortPath,
          statusCb
        );
      }

      downloadURL = await uploadVideoTasks[targetFileName];
    }

    if (callback) callback(downloadURL);

    delete uploadVideoTasks[targetFileName];
    ret = videoId ? { videoId, uri: downloadURL } : downloadURL;
  } catch (error) {
    console.warn('uploadVideo', error, video);
    throw new ExtraError(
      'uploadVideo',
      { localVideoFile: video, targetFileName, serverFolder },
      error,
      error?.errorCode
    );
  } finally {
    try {
      if (platform !== 'web' && downloadURL && shortPath) {
        const localDB = platformActions.localDB.getCementoDB();
        localDB.set('urlCache', [
          {
            id: shortPath,
            uri: downloadURL,
            uploadTS: Date.now(),
          },
        ]);
        setTimeout(() => {
          platformActions.fs.deleteFile((platform === 'ios' ? '' : 'file://') + shortPath);
        }, 1000 * 10);
      }
    } catch (error) {
      console.warn('uploadVideo - failed to remove local video', error);
      throw new ExtraError('uploadVideo - failed to remove local video', { localVideoPath: shortPath }, error);
    }
  }

  return ret;
};
