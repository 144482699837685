/**
 * @typedef Job
 * @property {string} id
 * @property {() => any | Promise<any>} job
 * @property {{ retryTiming: number, timingMultiplier?: number }} [options];
 */

import { isEmptyValue } from './app/funcs';

class SimpleQueue {
  #syncInProgress = false;
  /** @type {Set<string>} */
  #processingJobs = new Set();
  /** @type {Job[]} */
  #queue = [];
  /** @type {Object<string, number>} */
  #jobRetryCounter = {};
  #maxRetryMultiplier = 10;

  constructor({ processOnEnqueu = true } = {}) {
    this.processOnEnqueu = processOnEnqueu;
  }

  /** @param {Job} job */
  enqueue = (job) => {
    if (!job.id) {
      return;
    }

    const existingJob = this.getJob(job.id);
    if (existingJob) { // if job is already in queue, move it to the start
      existingJob.job = job.job;
      this.#queue.sort((j1, j2) => {
        return j1.id === job.id ? 1 : j2.id === job.id ? -1 : 0;
      });
    } else if (!this.isJobInProgress(job.id)){
      this.#queue.push(job);
    }

    if (this.processOnEnqueu && this.idle) {
      this.processQueue();
    }
  }

  #next = () => {
    return this.#queue.shift();
  }

  #addJobInProgress = (jobId) => {
    this.#processingJobs.add(jobId);
  }

  #removeJobInProgress = (jobId) => {
    this.#processingJobs.delete(jobId);
  }

  /**
   * 
   * @param {Job} job 
   */
  dequeue = (job) => {
    if (!job?.id) {
      return;
    }

    this.#queue = this.#queue.filter(j => j.id !== job.id);
  }

  get hasMore() {
    return Boolean(this.#queue.length);
  }

  flush = () => {
    this.#queue = [];
    this.#processingJobs = new Set();
    this.#syncInProgress = false;
  }

  get idle() {
    return !this.#syncInProgress;
  }

  /**
   * Process a job and run side effects
   * @param {Job} job 
   */
  #processJob = (job) => {
    try {
      const runningJob = job.job();
      if (runningJob.then) {
        runningJob
          .then(() => {
            delete this.#jobRetryCounter[job.id]
          })
          .catch(() => {
            if (!isEmptyValue(job.options?.retryTiming)) {
              const jobRetryCounter = this.#jobRetryCounter[job.id] || 0;
              const multiplier = jobRetryCounter > 1 ? (job.options?.timingMultiplier || 1) * (jobRetryCounter || 1) : 1;
              setTimeout(() => this.enqueue(job), job.options.retryTiming * multiplier);
              if (jobRetryCounter < this.#maxRetryMultiplier) {
                this.#jobRetryCounter[job.id] = jobRetryCounter + 1;
              }
            } else {
              this.enqueue(job);
            }
          })
          .finally(() => this.#removeJobInProgress(job.id));
      }
    } catch (error) {
      this.enqueue(job);
    } finally {
      this.#removeJobInProgress(job.id);
    }
  }

  processQueue = async () => {
    if (!this.idle) {
      return;
    }
  
    this.#syncInProgress = true;

    while (this.hasMore) {
      const currJob = this.#next();
      if (this.isJobInProgress(currJob.id)) {
        continue;
      }

      this.#addJobInProgress(currJob.id);
      this.#processJob(currJob);
    }

    this.#syncInProgress = false;
  }

  get queue() {
    return [...this.#queue];
  }

  /**
   * 
   * @param {string} jobId 
   * @returns 
   */
  getJob = (jobId) => {
    return this.#queue.find(job => job.id === jobId);
  }

  hasJob = (jobId) => {
    return Boolean(this.getJob(jobId) || this.isJobInProgress(jobId));
  }

  isJobInProgress = (jobId) => {
    return this.#processingJobs.has(jobId);
  }
}

export default SimpleQueue;
