import React, { useCallback, useMemo, useState } from 'react';
import FilterMenuComponent from './FilterMenuComponent';
import FilterMenuButton from './FilterMenuButton';
import theme from '../../../assets/css/theme';
import _ from 'lodash';
import { encodeFilterToSearch, decodeFiltersFromSearch } from '../../../app/funcs';
import * as propertyTypes from '../../../../common/propertiesTypes/propertiesTypes';
import withRouterHOC from '../../Router/util/withRouterHOC';


export const BORDER = theme.borderLineNeutralLight + 40;

// export const filtersExample = [
//   {
//     title: 'View 1',
//     id: 'view1',
//     categories: [
//       {
//         title: displayTitle,
//         id: '-' separated path in obj,
//         options: [
//           {id: target value, title: diplayTitle},.
//         ]
//       },
//     ]
//   },
// ];


class FilterMenuHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData = (props, nextProps, skipSetState = false) => {
    const { firstMount, pathDelimeterOverwrite } = props;
    let newStateChanges = {};

    if (firstMount || props.isValDiff(nextProps, ['location', 'search']) || !_.isEqual(props.filters, nextProps.filters)) {
      const newValue = decodeFiltersFromSearch(_.get(nextProps, ['location', 'search'], ''), nextProps.filterUrlKey || undefined, pathDelimeterOverwrite || undefined).originalFilterObj;
      const correspondingViewId = (_.values(nextProps.filters).filter(view => {
        const allViewCategories = _.values(view.categories).map(c => c.id);
        const valueKeys = _.keys(newValue);
        return Boolean(valueKeys.length) && valueKeys.every(valId => allViewCategories.includes(valId));
      })[0] || {}).id;
      if (correspondingViewId)
        newStateChanges.value = { [correspondingViewId]: newValue };
      else
        newStateChanges.value = null;

      if (firstMount)
        newStateChanges.defaultResetValue = newStateChanges.value;
    }
    
    if (!skipSetState && Object.keys(newStateChanges).length)
      this.setState(newStateChanges);
  }

  handleFilterChange = newFilters => {
    const { history, location, filterUrlKey } = this.props;
    const defaultViewFilters = _.first(_.values(newFilters));
    const newFilter = _.entries(defaultViewFilters).reduce((acc, [key, val]) => {
      if (val['AND']) {
        return _.set(acc, [key], val);
      }
      val = _.isArray(val)
        ? val
        : val && _.isObject(val)
          ? _.values(val)
          : [val];

      return _.set(acc, [key], val);
    }, {});

    const newSearch = encodeFilterToSearch(newFilter, location.search, filterUrlKey || undefined);

    history.push({ search: newSearch });
  }

  render() {
    const { filters, hideEmptyCategory, buttonStyle } = this.props;
    const { value, defaultResetValue } = this.state;

    return (
      <FilterMenu 
        hideEmptyCategory={hideEmptyCategory}
        filters={filters}
        onChange={this.handleFilterChange}
        value={value}
        style={buttonStyle}
        defaultResetValue={defaultResetValue}
      />
    );
  }
}

export default withRouterHOC(FilterMenuHOC);

const FilterMenu = ({ value, filters, onChange, hideEmptyCategory, style, defaultResetValue }) => {
  const [isShow, setIsShow] = useState(false);

  const numberOfFilters = useMemo(() => {
    return _.values(value).reduce((numOfFilters, categories) => {
        _.values(categories).forEach(categoryOptions => _.keys(categoryOptions).length && numOfFilters++);
        return numOfFilters;
      },
      0,
    );
  }, [value]);
  
  
  const isAllCategoriesEmpty = useMemo(() => {
    return !_.values(filters).some(filter => _.values(filter.categories).some(category => category.type !== propertyTypes.SELECTION_LIST || Boolean((category.options || []).length)));
  }, [filters])
  
  const handleShowChange = useCallback(() => {
    if (!isShow && hideEmptyCategory && isAllCategoriesEmpty) {
      return;
    }
    
    setIsShow(!isShow);
  }, [isShow, hideEmptyCategory, isAllCategoriesEmpty]);
  
  return (
    <>
      <FilterMenuButton
        disabled={hideEmptyCategory && isAllCategoriesEmpty}
        onClick={handleShowChange}
        numberOfFilters={numberOfFilters}
        style={style}
      />
      <FilterMenuComponent
        hideEmptyCategory={hideEmptyCategory}
        isShow={isShow}
        filters={filters}
        value={value}
        onChange={onChange}
        onClose={handleShowChange}
        defaultResetValue={defaultResetValue}
      />
    </>
  )
}