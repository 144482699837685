import React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import { connectContext } from 'react-connect-context';
import { ProjectContext } from '../../../common/projects/contexts';
import classNames from "classnames";
import PropTypes from "prop-types";
import { CustomInput } from "../../components/index.js";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "../CementoComponents/Avatar";
import Text from "../CementoComponents/Text";
import headerLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import theme from "../../assets/css/theme";
import logo from "../../assets/img/logoMini.png";
import down from "../../assets/img/icons/down.png";
import buttonsMessages from '../../../common/app/buttonsMessages';
import systemMessages from '../../../common/app/systemMessages';
import { startToast, updateConnectivityViewerVisiblity, updateAppConfigurationMode } from '../../../common/app/actions';
import { signOut } from '../../../common/auth/actions';
import { SatelliteSharp } from "@material-ui/icons";
import {envParams} from '../../../common/configureMiddleware';
import { version } from '../../app/lastDeploy';



class HeaderSideLinks extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeMode = this.changeMode.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleConfirmSignOut = this.handleConfirmSignOut.bind(this);
    this.state = {
      open: false,
      anchorElement: null,
    }
  }

  handleClick(event) {
    this.setState({ open: !this.state.open, anchorElement: event.currentTarget });
  }

  handleClose() {
    this.setState({ open: false });
  }

  changeMode() {
    const { isCementoTeamViewer, configurationMode, updateAppConfigurationMode } = this.props;
    if (isCementoTeamViewer) {
      const newMode = configurationMode === 1 ? 0 : 1
      updateAppConfigurationMode({ mode: newMode })
    }
  }

  handleSignOut() {
    const { startToast } = this.props
    const { title, content, yes, no } = systemMessages.signOutAlert
    startToast({
      overlay: true,
      mandatory: true,
      title: title,
      message: content,
      actions: [
        { message: yes, onClick: this.handleConfirmSignOut, color: 'success' },
        { message: no }
      ]
    });
  }

  async handleConfirmSignOut() {
    const { signOut } = this.props
    await signOut()
  }

  render() {
    const { classes, rtl, viewer, isCementoTeamViewer, configurationMode, updateConnectivityViewerVisiblity, connectionViewerVisiblity } = this.props;
    const { open, anchorElement } = this.state;
    const mode = configurationMode;
    const displayName = (viewer||{}).displayName;
    const managerClasses = classNames({ [classes.managerClasses]: true });
    const { apiServer } = envParams;
    const isStaging = apiServer.includes('staging');

    return (
      <div style={{display:'flex', flex:1, flexDirection: 'row-reverse', alignItems:'center', height:'inherit'}}>
        <div style={{ display: 'flex', alignItems:'center', justifyContent:'center', height: theme.headerHeight, width:75, [rtl ? 'borderRight' : 'borderLeft']: theme.borderLineNeutralLight + '75' }}>
          <a href="https://www.cemento.ai/">
            <div style={{ width: 30, margin:10 }}>
              <Avatar type={'picture'} src={logo} avatarContainerStyle={{ zIndex:theme.zIndexes.headerSideLinks }}/>
            </div>
          </a>
          {isStaging && <div style={{
            position: 'absolute',
            top: 0,
            right: rtl ? 'auto' : 4,
            left: rtl ? 4 : 'auto',
            color: '#fff',
            letterSpacing: '0.4em',
            lineHeight: '1em',
            fontSize: '11px',
          }}>
            staging
          </div>}
        </div>
        <div style={{ margin: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', height: theme.headerHeight, cursor: "pointer" }} className={managerClasses + ' HeaderLinks-itemLink-260 ' + (open ? 'HeaderLinks-itemLinkActive-263 active ' : ' ') } onClick={this.handleClick}>
          <div style={{ width: 30 }}>
            <Avatar type={'user'} src={(viewer || {}).avatar} avatarContainerStyle={{ zIndex:theme.zIndexes.headerSideLinks }}/>
          </div>
          <Popper open={open} transition style={{zIndex: theme.tooltipMenusZIndex}} anchorEl={anchorElement}>
            {({ TransitionProps, placement }) => (
              <div style={{ zIndex: theme.tooltipMenusZIndex}}>            
                <Paper>
                  <ClickAwayListener onClickAway={() => {
                    this.handleClose();
                  }}>
                    <MenuList>
                      {Boolean(isCementoTeamViewer) && (
                        <>
                        <Text style={{
                          padding: '0 16px', color: 'rgba(0,0,0,0.87)', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: "400", lineHeight: "1.5em"
                        }}>
                          Version: {version}
                        </Text>
                        <MenuItem onClick={() => {throw 'This is a controlled crash test'}}>
                          Secret crash button
                        </MenuItem>
                        </>
                      )}
                      <MenuItem button={false} style={{ fontWeight: 600, color:theme.brandPrimary, cursor: 'default'}}>
                        <span style={{ borderBottom: '2px solid', borderColor: theme.brandPrimary, paddingLeft: 3, marginLeft: -3, paddingRight: 3, marginRight: -3 }}>
                          <Text>{systemMessages.userOptions}</Text>
                        </span>
                      </MenuItem>
                      {Boolean(isCementoTeamViewer) && [
                        <MenuItem onClick={this.changeMode}>Configuration buttons: {mode === 1 ? 'Visible' : 'Hidden'}</MenuItem>,
                        <MenuItem>
                          <CopyToClipboard text={this.props.accessToken}>
                            <Text>Copy JWT</Text>
                          </CopyToClipboard>
                        </MenuItem>,
                        <MenuItem onClick={() => {
                          updateConnectivityViewerVisiblity({visible:!connectionViewerVisiblity.getNested(['visible'])});
                        }}>
                            Toggle connection viewer
                        </MenuItem>
                      ]}
                    
                      <MenuItem onClick={this.handleSignOut}>
                          <Text>{buttonsMessages.signOut}</Text>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </div>
            )}
          </Popper>
          <div style={{ ...theme.defaultFont, padding: theme.paddingSize, fontWeight: 400, fontSize:13, color: '#ffffff'}}>{displayName}</div>
          <img style={{ height: 7 }} src={down}/>
        </div>
      </div>
    );
  }
}

HeaderSideLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    connectionViewerVisiblity: state.app.connectionViewerVisiblity,
    configurationMode: state.app.configurationMode,
  }
}

HeaderSideLinks = connect(mapStateToProps, { startToast, updateAppConfigurationMode, signOut, updateConnectivityViewerVisiblity })(HeaderSideLinks)

HeaderSideLinks = withStyles(theme.combineStyles(headerLinksStyle, sidebarStyle))(HeaderSideLinks);
export default HeaderSideLinks;
