import React, { useMemo } from "react";
import Text from "./Text";
import theme from "../../assets/css/theme";

const Paging = ({ currPage, style, dataCount, countPerPage, maxButtonsToShow = 5, onClick }) => {
  const pagesButtonsCount = Math.ceil((dataCount || 1) / countPerPage);
  const firstPageToShow = Math.max(
    currPage - Math.ceil(maxButtonsToShow / 2),
    0,
  );

  const allPagesButtonsArray = useMemo(() => 
    new Array(pagesButtonsCount)
      .fill(0)
      .map((_, i) => i),
  [pagesButtonsCount]);

  const pagesButtonsArray = allPagesButtonsArray.slice(
    firstPageToShow,
    firstPageToShow + maxButtonsToShow,
  );

  const prevPage = allPagesButtonsArray[currPage - 1] || 0;
  const nextPage = allPagesButtonsArray[currPage + 1] || undefined;
  const showPages = pagesButtonsArray.length > 1;

  if (!showPages) return null;

  return (
    <div style={style}>
      <PageButton
        page={prevPage}
        pageText={"<"}
        style={{ visibility: currPage === 0 ? 'hidden' : 'visible' }}
        onClick={currPage === 0 ? undefined : onClick}
      />
      {pagesButtonsArray.map((page, i) => (
        <PageButton
          key={page}
          isActive={page == currPage}
          page={page}
          pageText={page + 1}
          onClick={onClick}
        />
      ))}
      <PageButton
        page={nextPage}
        pageText={">"}
        style={{
          visibility: nextPage === undefined ? 'hidden' : 'visible',
        }}
        onClick={nextPage === undefined ? undefined : onClick}
      />
    </div>
  );   
}

export default Paging;

const PageButton = ({ page, pageText, isActive, onClick, style }) => {
  return (
    <>
    <div
      style={{ cursor: onClick ? "pointer" : 'unset', ...style }}
      className={"page-button" + (isActive ? " active" : "")}
      onClick={() => onClick?.(page)}
    >
      <Text noSelect={true}>
        {pageText}
      </Text>
    </div>
    <style jsx>{`
      .page-button {
        cursor: "pointer";
        color: ${theme.brandPrimary};
        font-size: 18px;
        margin: 5px;
        transition: all 200ms ease 0s;
      }

      .page-button.active {
        font-weight: ${theme.strongBold};
      }

      .page-button.active,
      .page-button:hover {
        transform: scale(1.3);
      }
    `}</style>
    </>
  );
}

