import React from "react";

class FloatingSticky extends React.Component {
  constructor(props) {
    super(props);
    this.defaultSelectedRowData = [];

    this.state = {
      settings: { 
        position: '-webkit-sticky', 
        position: 'sticky', 
        top: '5%',
        ...(this.props.style || {})
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ settings: { 
        position: '-webkit-sticky', 
        position: 'sticky', 
        top: '5%',
        ...(nextProps.style || {})
      }
    })
  }

  render() {
    const { children, style } = this.props;
    const { settings } = this.state;

    return (
      <div style={settings}>
        {children}
      </div>
    );
  }
}

export default FloatingSticky;