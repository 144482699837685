import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';

const RequiredAction = Record({  
  id: null,
  title: null,
  trade: null,
  subCategory: null,
}, 'requiredAction');

export default RequiredAction;