import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';

import {firebaseDeps} from '../configureMiddleware';
import {deleteDrawingApiCall, upsertDrawingApiCall} from './funcs';
import {ProjectContext} from '../projects/contexts';
import {endDrawingsListener, SET_DRAWINGS, startDrawingsListener} from './actions';
import propertiesMessages from "../propertiesTypes/propertiesMessages";
import useIntl from "../intl/useIntl";
import systemMessages from "../app/systemMessages";
import drawingsMessages from "./drawingsMessages";
import {DRAWINGS_KEY} from "./reducer";


const useDrawings = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { selectedProjectId, viewer } = useContext(ProjectContext);

  const subscribeToDrawings = useCallback(() => {
    dispatch(startDrawingsListener(viewer, selectedProjectId));
  }, [selectedProjectId]);

  const unsubscribeFromDrawings = useCallback(() => {
    dispatch(endDrawingsListener(selectedProjectId));
  }, [selectedProjectId]);

  const upsertDrawing = useCallback(async (updates = {}, original) => {
    const updatedDrawing = {
      ...original,
      ...updates,
    };
    const errors = [];
    if (!updatedDrawing.category) {
      errors.push(intl.formatMessage(propertiesMessages.errors.mandatory, {
          propName: intl.formatMessage(systemMessages.trade),
        }));
    }
    if (!updatedDrawing.uriRemote && !updatedDrawing.uri) {
      errors.push(intl.formatMessage(propertiesMessages.errors.mandatory, {
        propName: intl.formatMessage(drawingsMessages.file),
      }));
    }
    if (errors.length) {
      return {
        error: errors.join('\n'),
      };
    }
    const {
      specificLocations, category, uriRemote,
      uri, locationType, id,
      title, version, date,
      number, ext,
    } = updatedDrawing;

    let trade = null;
    if (category) {
      trade = _.isObject(category) ? category : { [category]: category };
    }
    const locationTypeString = _.first(_.keys(locationType));
    const preparedData = {
      id: id || uuidv4(),
      title,
      version,
      date,
      number,
      category: trade,
      specificLocations,
      locationType,
      ['-unitsTags']: locationTypeString === '-units' && updatedDrawing['-unitsTags'] ? updatedDrawing['-unitsTags'] : null,
      ['-floorsTags']: locationTypeString === '-floors' && updatedDrawing['-floorsTags'] ? updatedDrawing['-floorsTags'] : null,
      ['-buildingsTags']: locationTypeString === '-buildings' && updatedDrawing['-buildingsTags'] ? updatedDrawing['-buildingsTags'] : null,
      updatedTS: firebaseDeps.firebaseDatabase.ServerValue.TIMESTAMP,
    };
    if (uriRemote || uri) {
      preparedData.uri = uriRemote || uri;
      preparedData.ext = ext;
      preparedData.uriRemote = null;
      preparedData.uriPdf = null;
      preparedData.uriThumbnail = null;
      preparedData.multiPage = null;
      preparedData.processed = null;
    }
    const drawings = await upsertDrawingApiCall(selectedProjectId, preparedData);
    if (drawings) {
      setDrawings(drawings);

      return drawings[preparedData.id];
    }
  }, [selectedProjectId]);

  const deleteDrawing = useCallback(async (drawingId) => {
    const drawings = await deleteDrawingApiCall(selectedProjectId, drawingId);

    if (drawings) {
      setDrawings(drawings);
    }
  }, [selectedProjectId]);

  const setDrawings = useCallback((drawings) => {
    dispatch({
      type: SET_DRAWINGS,
      payload: {
        projectId: selectedProjectId,
        [DRAWINGS_KEY]: drawings,
      },
    });
  }, [selectedProjectId]);

  return {
    upsertDrawing,
    deleteDrawing,
    unsubscribeFromDrawings,
    subscribeToDrawings,
    setDrawings,
  }
};

export default useDrawings;