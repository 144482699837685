import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';

import Floor from './floor';

import { OrderedMap } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
}, 'floors');

const initialState = new InitialState;

export default function floorReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;
 
  switch (action.type) {

    case actions.FLOORS_LOADING + '_SUCCESS': {
      const { projectId } = action.payload;
      if (!state.getIn(['didLoad', projectId])) {
        state = state.setIn(['didLoad', projectId], true);
      }

      return state;
    }

    case actions.GET_FLOORS: {
      if (!action.payload?.projectId)
        return state;

      const { projectId, floors } = action.payload;
      if (floors) {
        let floorsMap = new OrderedMap();
        Object.entries(floors).forEach(([buildingId, buildingFloors = {}]) => {
          let buildingFloorsMap = new OrderedMap();
  
          Object.values(buildingFloors).forEach(floor => {
            floor = new Floor({ ...(floor.toJS ? floor.toJS() : floor) });
            buildingFloorsMap = buildingFloorsMap.set(floor.id, floor);
          });
  
          buildingFloorsMap = buildingFloorsMap.sortBy(floor => (floor.num * -1));
          floorsMap = floorsMap.set(buildingId, buildingFloorsMap);
        });
        
        state = state.setIn(['map', projectId], floorsMap);
      }
      
      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}