import { removeProjectsStorage } from '../projects/actions';
import { getSnapshotData } from '../lib/utils/utils';
import { DRAWINGS_KEY } from './reducer';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { batchDispatch } from '../app/funcs';

export const GET_DRAWING_SETTINGS = 'GET_DRAWING_SETTINGS';
export const SET_DRAWINGS = 'SET_DRAWINGS';
export const END_DRAWINGS_LISTENER = 'END_DRAWINGS_LISTENER';
export const DRAWINGS_LOADING = 'DRAWINGS_LOADING';
export const CREATE_DRAWING = 'CREATE_DRAWING';
export const UPDATE_DRAWING = 'UPDATE_DRAWING';
export const DELETE_DRAWING = 'DELETE_DRAWING';
export const CLEAN_DRAWINGS_CACHE_DATA = 'CLEAN_DRAWINGS_CACHE_DATA';
export const BUILDINGS = "buildings";
export const BUILDING = "building";
export const FLOORS = "floors";
export const FLOOR = "floor";
export const UNITS = "units";
export const UNIT = "unit";


// to avoid naming confusion between V1 "get" action, and V2 "subscribe" action
export const startDrawingsListener = (viewer, projectId) => getDrawings(viewer, projectId);

export function getDrawings(viewer, projectId) {
	const getPromise = async () => {
		const scopeParams = {
			scope: 'projects',
			scopeId: projectId
		};
		const resourceParams = {
			resourceName: DRAWINGS_KEY,
			queryParams: {
				version: 2,
			},
			forceMSClientConfig: true,
		}
	
		const onData = (data) => {
			batchDispatch([{ type: SET_DRAWINGS, payload: { projectId, [DRAWINGS_KEY]: data } }]);
		}

		const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
		if (result) {
			onData(result);
		}

				return {
			projectId,
		};
	}

	return {
		type: DRAWINGS_LOADING,
		payload: getPromise(),
	};
}

export const endDrawingsListener = (projectId, drawingType) => {
	ClientServerConnectivityManagerInstance.unregisterService({
		scope: 'projects',
		scopeId: projectId,
		subject: 'drawings',
		params: { locationType: drawingType, version: 2 },
	});
	return {
		type: END_DRAWINGS_LISTENER,
		payload: {},
	}
}

export function cleanDrawingsCachedData() {
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['drawings', 'map'],
      ];
      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));
    };
    return {
      type: CLEAN_DRAWINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}
