import { Record } from '../transit';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
const Property = Record({
  id: null,
  externalId: null,
  subExternalId: null,
  UIStruct: null,
  type: null,
  isExtra: null,
  extraTypes: null,     // [optional] []
  values: null,
  settings: null,       // [optional] {}
  title: null,
  mandatory: null,
  sectionId: null,
  companies: null,
  trades: null,
  valueScope: null,     // [optional] global/...multiProject?
  businessType: null,   // [optional] certification,trades,companies
  universalId: null,    // [optional] hightCertification/employeeTrade/employeeCompany/locationType/avatar
  hideOnMobile: null,
  editable: null,
  isPrimary: null,
  showOnNullValue: null,
  ordinalNo: null,
  defaultContentType: null,
  tags: null, // [optional] {}
  innerTypes: null, // [optional] { [propId]: [propId] }
  valuesListsIds: null,
  isCreatable: null,
  isLocalMock: null,  
  permissions: null,
}, 'property', false);

export default Property;
