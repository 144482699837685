import React, { useCallback, useMemo } from "react";
import { injectIntl } from 'react-intl';

import useTimeRangePicker from "../../../common/propertiesTypes/useTimeRangePicker";
import theme from "../../assets/css/theme";
import TimePicker from "./TimePicker";

import arrow_icon from '../../assets/img/icons/arrow.png';
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { NoValueComponent } from "./InputField";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import Text from '../CementoComponents/Text';
import _ from 'lodash';

const START_PICKER_ID = 'startPicker', END_PICKER_ID = 'endPicker';
/**
 * @typedef {START_PICKER_ID | END_PICKER_ID} PickerId
 * 
 * @typedef TimeRangePickerProps 
 * @property {'time'} [mode]
 * @property {boolean} [isDisabled]
 * @property {string} [fromLabel]
 * @property {string} [toLabel]
 * 
 * @param {TimeRangePickerProps & import('../../common/propertiesTypes/useTimeRangePicker').UseTimePickerConfig} props 
 * @returns 
 */
 let TimeRangePicker = (props) => {
  const { onChange, value, fromLabel = 'From', toLabel = 'to', isDisabled, intl, valueFontSize, noValueComponentValueStyle } = props;
  const { handleChange, timeFormatString } = useTimeRangePicker({ mode: 'time', onChange, value, intl });

  const _separator = useMemo(() => {
    const { separator = <TimeRangeDefaultSeparator /> } = props;

    if (typeof separator === 'string' || _.get(separator, 'defaultMessage'))
      return <Text style={{ fontSize: theme.fontSmallH6 }}>{separator}</Text>;

    return separator;
  }, [props.separator]);

  const handleInputChange = useCallback(
  /** 
   * @param {PickerId} pickerId
   * @param {Date} newDate 
   * @returns
   */
  (pickerId, newDate) => {
    handleChange(newDate, pickerId === END_PICKER_ID);
  }, [handleChange]);


  if (isDisabled && (!value || !(value.startTS || value.endTS)))
    return <NoValueComponent defaultText={propertiesMessages.empty} noValueComponentValueStyle={noValueComponentValueStyle}/>;

  const is24Hours = timeFormatString.toLowerCase().indexOf('a') === -1;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {[START_PICKER_ID, END_PICKER_ID].map(pickerId => {
        let pickerValue = null;
        if (value)
          pickerValue = pickerId === START_PICKER_ID ? value.startTS : value.endTS;

        const pickerValueDate = pickerValue ? new Date(pickerValue) : null;

        const isStartPicker = pickerId === START_PICKER_ID 
        return (
          <React.Fragment key={'rendered_picker_' + pickerId}>
            {/* <div style={Object.assign({ marginInlineEnd: theme.margin }, !isStartPicker && { marginInlineStart: theme.margin })}>
              {isStartPicker ? fromLabel : toLabel}
            </div> */}
            <div style={Object.assign({}, !isDisabled && { flex: 1 })}>
              <TimePicker
                is24Hours={is24Hours}
                onChange={(newDate) => handleInputChange(pickerId, newDate)}
                value={pickerValueDate}
                isDisabled={isDisabled}
    						noValueComponentValueStyle={noValueComponentValueStyle}
              />
            </div>
            {Boolean(isStartPicker && _separator) && (
              <div style={{ marginInline: isDisabled ? theme.margin : theme.margin * 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {_separator}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  )
}

TimeRangePicker = injectIntl(TimeRangePicker);
export default TimeRangePicker;



const TimeRangeDefaultSeparator = () => {
  const { isRTL } = useSelector(state => ({ isRTL: state.app.rtl }));
  
  return <img style={Object.assign({ height: theme.mediumFontSize }, isRTL && { transform: 'rotate(180deg)' })} alt="arrow" src={arrow_icon} />
}