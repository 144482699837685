import { removeProjectsStorage } from "../projects/actions";
import { getSnapshotData } from '../lib/utils/utils';
import { batchDispatch } from '../app/funcs';

export const GET_PROPERTIES_MAPPINGS = 'GET_PROPERTIES_MAPPINGS';
export const PROPERTIES_MAPPINGS_LOADING = 'PROPERTIES_MAPPINGS_LOADING';
export const CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA = 'CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA';


export function getPropertiesMappings(viewer, projectId, subjectName) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'projects', scopeId: projectId };
    const resourceParams = {
      resourceName: 'properties/mappings',
      firebasePath: `properties/mapping/projects/${projectId}${subjectName ? `/${subjectName}` : ''}`,
      queryParams: subjectName ? { subjectName } : undefined,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_PROPERTIES_MAPPINGS, payload: { projectId, subjectName, propertiesMappings: data } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      onData(result);
    }

    return { projectId };
  }

  return {
    type: PROPERTIES_MAPPINGS_LOADING,
    payload: getPromise(),
  };
}

export function cleanPropertiesMappingsCachedData() {
  return ({ dispatch, getState, realmInstance, platformActions }) => {
    const getPromise = async () => {
      let projectIdsArray = [];
      let projectStateToRemove = [
        ['propertiesMappings', 'locationsMapping'],
        ['propertiesMappings', 'groupsMapping'],
        ['propertiesMappings', 'map'],
      ];

      getState().getNested(['projects', 'map'], {}).loopEach((k, p) => projectIdsArray.push(p.id));
      dispatch(removeProjectsStorage(projectIdsArray, projectStateToRemove));

    };
    return {
      type: CLEAN_PROPERTIES_MAPPINGS_CACHE_DATA,
      payload: getPromise()
    };
  };
}

