import { useMemo } from 'react';
import { getUniqueId } from '../app/funcs';

/**
 * 
 * @param {string} prefix 
 * @returns 
 */
const useUniqueId = (prefix) => {
  const uniqueId = useMemo(() => getUniqueId(prefix), []);

  return uniqueId;
}

export default useUniqueId;