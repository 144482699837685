import { Mutex } from 'async-mutex';

/**
 * @typedef ProxyHandlerContext
 * @property {Mutex} mutex
 * @property {Realm} realm
 */

/**
 * Mutex overrides are wrapping some of the methods on realm to live by the 
 * mutex pattern, meaning that realm will only able to run one transaction at a time 
 * given that it throws an error when trying to run them concurently.
 * 
 * @type {{ [methodName: string]: (context: ProxyHandlerContext) => any }}
 */
const mutexOverrides = {
  beginTransactionMutex: ({ mutex, realm }) => {
    return () => new Promise(async resolve => {
      await mutex.acquire();
      realm.beginTransaction();
      resolve();
    });
  },
  commitTransactionMutex: ({ mutex, realm }) => () => {
    mutex.release();
    realm.commitTransaction();
  },
  cancelTransactionMutex: ({ mutex, realm }) => () => {
    mutex.release();
    realm.cancelTransaction();
  },
  writeMutex: ({ mutex, realm }) => {
    return (callback) => {
      return mutex.runExclusive(() => realm.write(callback));
    }
  },
}

/**
 * @type {{ [methodName: string]: (context: ProxyHandlerContext) => any }}
 */
const realmOverrides = {
  ...mutexOverrides,
}

/**
 * The goal of this function is to add method to the realm instance as it impossible to extend Realm class.. For some reason..
 * 
 * @param {Realm} realmInstance 
 */
const enhanceRealm = realmInstance => {
  const mutex = new Mutex();
  Object.entries(realmOverrides).forEach(([methodName, method]) => {
    realmInstance[methodName] = method({ realm: realmInstance, mutex });
  });

  return realmInstance;
}

export default enhanceRealm;