import { Navigate } from 'react-router-dom';
import UserValidator from '../../UserValidator';
import MainContainerPage from '../../layouts/MainContainerPage';
import Pages from '../../layouts/Pages';
import CompanyRouter from './CompanyRouter';
import GlobalRouter from './GlobalRouter';
import ProjectRouter from './ProjectRouter';

export default (RootComponent) => [
  {
    path: '/',
    element: RootComponent,
    children: [
      {
        path: 'main',
        name: 'Main',
        element: <MainContainerPage />,
        children: [
          {
            path: 'companyContainerPage',
            children: CompanyRouter,
          },
          {
            path: 'projectContainerPage',
            children: ProjectRouter,
          },
        ],
      },
      {
        path: 'pages',
        name: 'Pages',
        element: <Pages />,
        children: GlobalRouter,
      },
    ],
  },
  { index: true, path: '/*', element: <Navigate to='/pages/login-page' replace state={{ name: 'Register Page' }} /> },
];
