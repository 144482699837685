import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import systemMessages from '../app/systemMessages';
import moment from 'moment';

/**
 * @typedef TimePickerValue
 * @property {number} [startTS]
 * @property {number} [endTS]
 * 
 * @typedef UseTimePickerConfig
 * @property {TimePickerValue} [value]
 * @property {(newVal: TimePickerValue) => void} [onChange]
 * @property {'time'} mode
 * @property {any} intl
 * @property {{ defaultVal?: TimePickerValue }} [settings]
 * 
 * @param {UseTimePickerConfig} config 
 * @returns 
 */

const useTimeRangePicker = (config) => {
  const { value, mode, intl, settings } = config;

  const onChange = useCallback((newVal) => {
    if (config.onChange && !_.isEqual(newVal, value))
      config.onChange(newVal);
  }, [value]);

  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);
  
  const handleChange = useCallback(
  /**
   * @param {Date} newDate 
   * @param {boolean} isEndValue 
   */  
  (newDate, isEndValue) => {
    if (!_.get(newDate, 'getTime'))
      return;

    const newTS = newDate.getTime();
    
    let newVal = Object.assign({}, value);

    const currValueId = isEndValue ? 'endTS' : 'startTS';
    newVal[currValueId] = newTS;

    const otherValueId = isEndValue ? 'startTS' : 'endTS';
    if (!newVal[otherValueId] && _.get(settings, ['defaultVal', otherValueId]))
      newVal[otherValueId] = _.get(settings, ['defaultVal', otherValueId]);

    _.entries(newVal).forEach(([valueId, valueTS]) => {
      const valueDate = moment(valueTS);
      let adjustedDate = moment();
      ['hours', 'minutes', 'seconds'].forEach(timeType => adjustedDate.set(timeType, valueDate.get(timeType))); // adjusting the timestamp to be of the same day as the time choice;
      newVal[valueId] = adjustedDate.valueOf();
    });

    onChange(newVal);
  }, [value, onChange, settings]);

  /** @type {string} */
  const timeFormatString = useMemo(() => intl ? intl.formatMessage(systemMessages.onlyTimeFormat) : systemMessages.onlyTimeFormat.defaultMessage, [mode, Boolean(intl)]);

  return useMemo(() => ({ handleChange, timeFormatString, handleClear }), [handleChange, timeFormatString, handleClear]);
}

export default useTimeRangePicker;