import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// styles
import HomeBlackPNG from "../../assets/img/locations/home_black.png";
import HomeGrayPNG from "../../assets/img/locations/home_gray.png";
import HomeSelectedPNG from "../../assets/img/locations/home_selected.png";
import theme from "../../assets/css/theme";
import { BuildingContext } from "../../../common/buildings/contexts";
import NumberBadge from "../../components/CementoComponents/NumberBadge";

const houseHeight = 21;
const houseWidth = 24;

class Unit extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      unit: null,
      unitTitle: "",
      unitData: null,
      unitOpacity: 0,
      isActive: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const {
      units,
      checklistsStatusMap,
      buildingId,
      isActive,
      selectedLocation,
    } = props;
    let newStateChanges = {};

    if (
      (units || {}).getNested([buildingId]) !=
      nextProps.units.getNested([nextProps.buildingId])
    )
      newStateChanges.unit = nextProps.units.getNested(
        [nextProps.buildingId, nextProps.unitId],
        {}
      );

    let nextUnitData = (nextProps.checklistsStatusMap || {}).getNested([
      nextProps.unitId,
    ]);
    if (
      this.state.unitData != nextUnitData ||
      nextProps.checklistsStatusMap != checklistsStatusMap
    )
      newStateChanges.unitData = nextUnitData;

    if (
      (selectedLocation || {}).getNested(["unitId"]) !=
      nextProps.selectedLocation.getNested(["unitId"])
    ) {
      let newIsActive = Boolean(
        nextProps.selectedLocation.getNested(["unitId"]) == nextProps.unitId
      );
      if (isActive != newIsActive) newStateChanges.isActive = newIsActive;
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onMouseEnter() {
    this.setState({ isActive: true });
  }

  onMouseLeave() {
    this.setState({
      isActive: Boolean(
        this.props.selectedLocation.getNested(["unitId"]) == this.props.unitId
      ),
    });
  }

  onLocationClick() {
    const { onClick, floorId, buildingId, unitId } = this.props;
    const { unit } = this.state;
    if (onClick)
      onClick(
        { type: "unit", buildingId, floorId, unitId },
        (unit || {}).title
      );
  }

  render() {
    const {
      checklistsStatusMap,
      buildingBadges,
      classes,
      showBadges,
      isSelectionMode,
      locationsMap,
    } = this.props;
    const { unit, unitData, rtl, isActive } = this.state;

    let disabled = !unitData && checklistsStatusMap;
    let primaryPercent = 0;
    let primaryColor = "transparent";
    let secondaryPercent = 0;
    let secondaryColor = "transparent";

    if (unitData && unitData.primaryFill) {
      primaryPercent = unitData.primaryFill.percent;
      primaryColor = unitData.primaryFill.color;
    }

    if (unitData && unitData.secondaryFill) {
      secondaryPercent = unitData.secondaryFill.percent;
      secondaryColor = unitData.secondaryFill.color;
    }

    let fillText = unitData ? unitData.fillText : "";
    let recalculatedIsActive =
      isSelectionMode && !isActive
        ? Boolean(locationsMap.getNested(["units", unit.id, "selected"], false))
        : isActive;
    let imgSrc = recalculatedIsActive
      ? HomeSelectedPNG
      : disabled
      ? HomeGrayPNG
      : HomeBlackPNG;
    let badge = null;
    if (
      showBadges &&
      ((!checklistsStatusMap && buildingBadges && buildingBadges[unit.id]) ||
        (checklistsStatusMap && unitData && unitData.issuesCounter != 0))
    )
      badge = checklistsStatusMap ? unitData : buildingBadges[unit.id];
    return (
      <div
        title={(unit || {}).title}
        onClick={this.onLocationClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={unit.id}
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "50%",
                maxWidth: "60px",
              }}
            >
              <div
                style={{
                  background: "transparent",
                  height: 100 - primaryPercent - secondaryPercent + "%",
                  width: "100%",
                }}
              />
              <div
                style={{
                  background: secondaryColor,
                  height: secondaryPercent + "%",
                  width: "100%",
                }}
              />
              <div
                style={{
                  background: primaryColor,
                  height: primaryPercent + "%",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                alignSelf: "center",
                color: theme.brandInfo,
              }}
            >
              {fillText}
            </div>
            <img
              src={imgSrc}
              style={{
                height: houseHeight,
                width: houseWidth,
                maxWidth: "60px",
                zIndex: theme.zIndexes.unit,
              }}
            />
            {Boolean(badge) && (
              <NumberBadge
                data={badge}
                isActive={recalculatedIsActive}
                leftOrRightOffset={"-10px"}
                topOffset={"-10px"}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 7,
            marginBottom: 18,
            color: theme.brandNeutral,
          }}
        >
          <div
            className={
              classes.unitTitle +
              (recalculatedIsActive ? " " + classes.unitTitleSelected : "")
            }
          >
            {(unit || {}).title}
          </div>
        </div>
      </div>
    );
  }
}

const style = {
  unitTitle: {
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
    overflow: "hidden",
    fontSize: "11px",
    lineHeight: "15px",
    maxHeight: "30px",
    maxWidth: "75%",
  },
  unitTitleSelected: {
    color: theme.brandPrimary,
    fontWeight: 400,
  },
};

Unit = withStyles(style)(Unit);
Unit = injectIntl(Unit);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connectContext(BuildingContext.Consumer),
  connect(
    (state) => ({
      //selectedLocation: stte.ui.selectedLocation,
      rtl: state.app.rtl,
    }),
    {}
  )
);
export default enhance(Unit);
