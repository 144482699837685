// eslint-disable-next-line no-unused-vars
import React, { Component, useContext } from 'react';
import { injectIntl } from 'react-intl';
import Text from '../Text';
import theme from '../../../assets/css/theme';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import Image from '../Image';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import systemMessages from '../../../../common/app/systemMessages';
import { navigateToProject, getProjectById } from '../../../../common/projects/funcs';
import { CompanyTableContext } from '../../../../common/analytics/contexts';
import * as issueStates from '../../../../common/issues/issueStates.js';
import { CELL_SECTIONS } from './CellSections';
import { FILTER_URL_KEY, FILTER_MENU_PATH_DELIMETER } from '../../../app/constants';
import { hideLoading, startLoading } from '../../../../common/app/actions';
import { encodeFilterToSearch } from '../../../app/funcs';
import { v4 as uuidv4 } from "uuid";
import { getDispatch } from '../../../../common/configureMiddleware';

const graphFontSize = 10;
const lineOptions = {
	maintainAspectRatio: false,
	legend: { display: false },
	tooltips: { display: true, displayColors: false, callbacks: { title: () => '' } },
	scales: {
		yAxes: [{ stacked: true, ticks: { beginAtZero: true, precision: 0, fontSize: graphFontSize, max: 100 } }],
		xAxes: [{ ticks: { fontSize: graphFontSize } }],
	},
};
const border = `${theme.separatorColor} solid 1px`;

const ISSUES_STATUS = {
	'new': issueStates.ISSUE_STATE_OPENED,
	'open': issueStates.ISSUE_STATE_OPENED,
	'resolved': issueStates.ISSUE_STATE_RESOLVED,
	'closed': issueStates.ISSUE_STATE_CLOSED,
};

const ISSUES_SEVERITY = {
	'high': 3,
	'med': 2,
	'low': 1,
};

const getNavigateToPageOnClick = (cellData, projectId, onProjectClick, startTS, endTS) => {
	const { section: cellSection, path: cellPath } = cellData;
	const createdAt = { 'AND': [`>${startTS}`, `<${endTS || Date.now()}`] };
	let onClickHandler;
	let navigationPath;
	let issueStatus;
	let menuSubject;
	let paramQuery;
	let shouldFilter;

	const generateSearchQuery = params => {
		const query = encodeFilterToSearch(
			Object.entries(params).reduce((acc, [name, value]) => {
				acc[`values${FILTER_MENU_PATH_DELIMETER}${name}${FILTER_MENU_PATH_DELIMETER}originalValue`] = value;
				return acc;
			}, {}),
			'',
			FILTER_URL_KEY,
		);
		return query;
	};

	switch (cellSection) {
		case CELL_SECTIONS.PROJECT:
			if (onProjectClick) onClickHandler = () => onProjectClick(projectId);
			break;

		case CELL_SECTIONS.TOTAL_STATS:
			if (_.includes(cellPath, 'records')) navigationPath = 'records/analytics/records?itemType=records';
			else if (_.includes(cellPath, 'issues')) {
				issueStatus = ISSUES_STATUS[_.last(cellPath)];
				paramQuery = generateSearchQuery({
					issueState: issueStatus,
				});
				navigationPath = `issues/analytics/issues?itemType=tasks&${paramQuery}`;
			}
			break;

		case CELL_SECTIONS.USAGE:
			paramQuery = { createdAt };
			if (_.includes(cellPath, 'records')) {
				paramQuery = generateSearchQuery(paramQuery);
				navigationPath = `records/analytics/records?itemType=records&${paramQuery}`;
			} else if (_.includes(cellPath, 'issues')) {
				issueStatus = ISSUES_STATUS[_.last(cellPath)];
				if (issueStatus != issueStates.ISSUE_STATE_OPENED)
					_.set(paramQuery, ['issueState'], issueStatus)
				paramQuery = generateSearchQuery(paramQuery);
				navigationPath = `issues/analytics/issues?itemType=tasks&${paramQuery}`;
			}
			break;

		case CELL_SECTIONS.OPEN_TASKS_BY_TRADE:
			issueStatus = [ISSUES_STATUS['open'], ISSUES_STATUS['resolved']];
			paramQuery = generateSearchQuery({
				issueState: issueStatus,
				'trade': [_.last(cellPath)],
			});
			navigationPath = `issues/analytics/issues?itemType=tasks&${paramQuery}`;
			break;

		case CELL_SECTIONS.SAFETY_GRADE:
			navigationPath = 'safety/dashboard';
			break;

		case CELL_SECTIONS.OPEN_ISSUES:
			issueStatus = [ISSUES_STATUS['open'], ISSUES_STATUS['resolved']];

			paramQuery = generateSearchQuery({
				issueState: issueStatus,
				severity: [ISSUES_SEVERITY[_.last(cellPath)]],
			});

			navigationPath = `safety/postsAnalytics/issues?itemType=tasks&${paramQuery}`;
			break;

		case CELL_SECTIONS.WORKERS_CERTIFICATES:
			shouldFilter = true;
			menuSubject = 'employees';
			navigationPath = 'safety/analytics/';
			break;

		case CELL_SECTIONS.EQUIPMENT_CERTIFICATES:
			shouldFilter = true;
			menuSubject = 'equipment';
			navigationPath = 'safety/analytics/';
			break;

		case CELL_SECTIONS.ACTIVITY:
			if (_.includes(cellPath, 'issues')) {
				issueStatus = [ISSUES_STATUS['open'], ISSUES_STATUS['resolved']];
				paramQuery = generateSearchQuery({
					issueState: issueStatus,
					createdAt,
				});
				navigationPath = `safety/postsAnalytics/issues?itemType=tasks&${paramQuery}`;
			} else if (_.includes(cellPath, 'employees')) {
				shouldFilter = false;
				menuSubject = 'employees';
				navigationPath = 'safety/analytics/';
				break;
			} else if (_.includes(cellPath, 'equipment')) {
				shouldFilter = false;
				menuSubject = 'equipment';
				navigationPath = 'safety/analytics/';
				break;
			}
			break;
	}
	if (navigationPath)
		onClickHandler = async () => {
			let operationId;
			const dispatch = getDispatch();
			if (menuSubject) {
				operationId = uuidv4();
				dispatch(startLoading({
					title: systemMessages.loadingMessage,
					overlay: true,
					operationId,
				}));
				const projectMenus = await getProjectById({ projectId, fields: ['menus'] });
				for (const currMenu in _.get(projectMenus, ['menus'])) {
					if (
						_.get(projectMenus, ['menus', currMenu, 'subjectType']) === menuSubject &&
						!_.includes(navigationPath, currMenu)
					) {
						navigationPath = `${navigationPath}${currMenu}` + (shouldFilter ? '?filter=&isActive=true&isExpired=true' : '');
						break;
					}
				}
			}
			if (operationId)
				dispatch(hideLoading(operationId));
			navigateToProject({}, projectId, navigationPath, null, true);
		};
	return onClickHandler;
};

const getGradeColor = grade => {
	if (grade >= 90) return styles.gradeColorsList[0];
	if (grade >= 70) return styles.gradeColorsList[1];
	if (grade >= 50) return styles.gradeColorsList[2];
	return styles.gradeColorsList[3];
};

const getCountersColor = (value, type) => {
	let color = 'inherit';

	if (value == 0) color = `${theme.brandNeutralLight}80`;
	else {
		switch (type) {
			case 'low':
				color = theme.brandNeutralLight;
				break;
			case 'danger':
				color = theme.brandRealDanger;
				break;
			case 'warning':
				color = theme.brandWarning;
				break;
			case 'success':
				color = theme.brandSuccess;
				break;
			default:
				color = theme.brandNeutralDark;
		}
	}

	return color;
};

class CompanyTable extends Component {
	constructor(props) {
		super(props);
		this.recentUseTitle = '';
		this.state = {
			tableData: [],
		};
	}

	UNSAFE_componentWillMount() {
		this.setComponentData({}, this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setComponentData(this.props, nextProps);
	}

	setComponentData(props, nextProps) {
		const { intl } = nextProps;
		let stateChanges = {};

		if (
			nextProps.isValDiff(props, ['data']) ||
			nextProps.isValDiff(props, ['sections']) ||
			nextProps.isValDiff(props, ['columns']) ||
			nextProps.isValDiff(props, ['timeFilterTitle'])
		)
			stateChanges.tableData = this.getTableRows(nextProps);

		if (nextProps.isValDiff(props, ['timeFilterTitle'])) {
			let { timeFilterTitle } = nextProps;
			this.recentUseTitle = _.isString(timeFilterTitle) ? timeFilterTitle : intl.formatMessage(timeFilterTitle);
		}

		if (Object.values(stateChanges).length > 0) this.setState(stateChanges);
	}

	getTableRows(nextProps) {
		const { sections, columns, data, rtl, isPdfMode, intl, onProjectClick } =
			nextProps || this.props;

		if (data.length == 0) return [];

		let sectionsMap = _.groupBy(columns, col => col.section);

		let rows = [];
		let sectionsHeaders = [];
		let columnsHeaders = [];
		let sectionsWithBordersMap = {};

		sections.forEach((section, sectionIndex, sections) => {
			const isLastSection = sectionIndex === sections.length - 1;
			const isFirstSection = sectionIndex === 0;
			let sectionWidth = _.sumBy(sectionsMap[section.name], col => col.width || styles.cell.width);
			let withBorders = Boolean(section.withBorders);
			sectionsWithBordersMap[section.name] = withBorders;

			let title = section.title;
			if (section.injectTimeFilterTitle) title = intl.formatMessage(title, { value: this.recentUseTitle });
			const headerStyle = {
				width: sectionWidth,
				..._.get(section, 'headerCustomStyle', {}),
			}

			sectionsHeaders.push(
				<ColumnHeader
					isPdfMode={isPdfMode}
					title={title}
					isSection={true}
					style={headerStyle}
					withBorders={true}
					rtl={rtl}
					isFirstSection={isFirstSection}
					distanceFromEdge={0}
				/>,
			);
			columnsHeaders.push(
				...(sectionsMap[section.name] || []).map((col, index, cols) => {
					let isLastInSection = index + 1 == cols.length;
					let distanceFromEdge = _.get(cols, [index - 1, 'width'], 0);
					return (
						<ColumnHeader
							key={`table_section_${sectionIndex}`}
							getTitle={col.getTitle}
							title={col.title}
							style={col.width ? { width: col.width } : {}}
							withBorders={withBorders || isLastInSection}
							rtl={rtl}
							isFirstSection={isFirstSection}
							distanceFromEdge={distanceFromEdge}
						/>
					);
				}),
			);

			if (!isFirstSection && !isLastSection) {
				sectionsHeaders.push(
					<Cell
						isSeparator={true}
						key={`separator_sections_${sectionIndex}`}
						style={{ ..._.get(section, 'separatorCustomStyle', {}) }}
						rtl={rtl}
					/>,
				);
				columnsHeaders.push(<Cell isSeparator={true} key={`separator_header${sectionIndex}`} rtl={rtl} />);
			}
		});
		rows.push(sectionsHeaders, columnsHeaders);

		data.sort((a, b) => _.get(a, ['project', 'title'], '').localeCompare(_.get(b, ['project', 'title'], '')));
		const firstRowIndex = _.findIndex(data, i => i?.isSummaryRow);
		if (firstRowIndex > 0) {
			const firstRow = data[firstRowIndex];
			data.splice(firstRowIndex, 1);
			data.unshift(firstRow);
		}
		data.forEach((rowData, rowIndex) => {
			let row = [];
			_.entries(sectionsMap).forEach(([section, sectionsColumns], sectionIndex, sectionEntries) => {
				let isFirstSection = Boolean(sectionIndex == 0);
				let isLastSection = Boolean(sectionIndex + 1 == sectionEntries.length);

				sectionsColumns.forEach((cellData, index, cols) => {
					let isLastInSection = index + 1 == cols.length;
					let distanceFromEdge = _.get(cols, [index - 1, 'width'], 0);
					row.push(
						<Cell
							key={`section_${section}_cell_${cellData.name}_row_${rowData.id}`}
							onProjectClick={onProjectClick}
							rowIndex={rowIndex}
							rowData={rowData}
							cellData={cellData}
							rtl={rtl}
							isPdfMode={isPdfMode}
							intl={intl}
							withBorders={sectionsWithBordersMap[section] || isLastInSection}
							isFirstSection={isFirstSection}
							distanceFromEdge={distanceFromEdge}
						/>,
					);
				});
				if (!(isLastSection || isFirstSection)) {
					row.push(
						<Cell isSeparator={true} key={`separator_${rowData.id}_${section}`} rowIndex={rowIndex} rtl={rtl} />,
					);
				}
			});

			let rowStyle = { display: 'flex' };
			if (rowData?.isSummaryRow) _.assign(rowStyle, { marginBottom: 2 * theme.verticalMargin });
			row = (
				<div key={`row_${rowData.id}`} style={rowStyle}>
					{row}
				</div>
			);
			rows.push(row);
		});

		return rows;
	}

	render() {
		const { tableData } = this.state;
		const { rtl } = this.props;
		if (tableData.length == 0) return <></>;

		return (
			<div style={{ ...styles.tableContainer, direction: rtl ? 'rtl' : 'ltr' }}>
				{tableData.map((row, index) => {
					let stickyStyle = {};
					if (index < 2) {
						stickyStyle = { ...styles.stickyRow };
						if (index == 1) stickyStyle.top = styles.sectionHeader.height;
					}

					return (
						<div key={`table_row_${index}`} style={{ breakInside: 'avoid-page', display: 'flex', ...stickyStyle }}>
							<div style={{ display: 'inline-block' }}>
								<div key={`row_${index}`} style={{ ...styles.row, ...(Boolean(index < 2) && styles.headersRow) }}>
									{' '}
									{row}{' '}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

export default injectIntl(CompanyTable);

const Cell = props => {
	const {
		isSeparator,
		cellData,
		rowData,
		isSubRow,
		isLastSubRow,
		subRowsTotalNum,
		rtl,
		rowIndex,
		isPdfMode,
		intl,
		style,
		withBorders,
		onProjectClick,
		isFirstSection,
		distanceFromEdge,
		withTooltip = false,
		tooltipText,
	} = props;
	const isSummaryRow = _.get(rowData, ['isSummaryRow']);
	const { startTS, endTS } = useContext(CompanyTableContext);
	let customStyle = Object.assign({}, style || {}, _.get(props, ['cellData', 'customStyle'], {}));
	let onClick;
	const projectId = _.get(rowData, ['id']);

	if (cellData && _.get(rowData, ['id']) !== 'allProjectsStats')
		onClick = getNavigateToPageOnClick(cellData, projectId, onProjectClick, startTS, endTS);

	let colComponent = <></>;
	let borderStyle = {};

	if (withBorders || isSeparator) borderStyle[`border${rtl ? 'Left' : 'Right'}`] = border;
	if (rowIndex == 0) borderStyle.borderTop = null;

	if (isSeparator) customStyle = Object.assign(customStyle, styles.separatorCell);
	else if (cellData) {
		let {
			type,
			path,
			subRows,
			defaultValue,
			width,
			valueFormatter = _.identity,
			displayEmptyCell,
			getColor,
			counterColorType,
			hideConditionPath,
			summaryRowImageCustomStyle,
		} = cellData;
		let value = _.get(rowData, path, defaultValue);

		if (_.isNil(value) && displayEmptyCell)
			return (
				<div style={{ ...styles.cell, padding: 1, justifyContent: 'stretch', ...borderStyle }}>
					<div style={{ height: '100%', width: '100%', ...(!isPdfMode ? styles.emptyCell[rowIndex % 2] : {}) }}></div>
				</div>
			);

		let color = getColor ? getColor(value) : 'inherit';
		let colContent = valueFormatter(value, intl);

		if (_.isNumber(colContent)) colContent = String(Math.round(colContent));

		if (width) customStyle = Object.assign(customStyle, { width });
		if (isSubRow)
			customStyle = Object.assign(
				{ border: null, minHeight: styles.cell.height / subRowsTotalNum - styles.cell.paddingBottom, padding: 0 },
				customStyle,
				Boolean(isLastSubRow) && { alignItems: 'start' },
			);

		switch (type) {
			case 'image':
				customStyle = Object.assign(customStyle, { paddingTop: 0 });
				let imageStyle = { objectPosition: 'center top', objectFit: 'cover', cursor: onClick ? 'pointer' : 'inherit' };
				if (isSummaryRow && summaryRowImageCustomStyle) Object.assign(imageStyle, summaryRowImageCustomStyle);
				colComponent = (
					<div style={{ flex: 1, height: styles.cell.height }}>
						<Image src={colContent} imageStyle={imageStyle} />
					</div>
				);
				break;

			case 'string':
				let stringStyle = { color, paddingRight: rtl ? theme.padding : 0, paddingLeft: rtl ? 0 : theme.padding };
				if (isSummaryRow) {
					stringStyle.fontWeight = theme.strongBold;
				}
				colComponent = (
					<Text maxLines={1} style={stringStyle} withTooltip={withTooltip} tooltipText={tooltipText}>
						{colContent}
					</Text>
				);
				break;

			case 'grade': ///// TODO: MOVE TO EXTERNAL COMPONENT!
				let gradeColor = getGradeColor(colContent);
				let circularProgressbarStyle = buildStyles({
					textColor: gradeColor,
					pathColor: gradeColor,
					backgroundColor: theme.backgroundColorBright,
					textSize: theme.fontSizeH2,
				});
				if (colContent == 0) Object.assign(circularProgressbarStyle, { trailColor: gradeColor });
				if (isSummaryRow) Object.assign(circularProgressbarStyle.text, { fontWeight: theme.strongBold });

				colComponent = (
					<div style={{ height: styles.cell.height, width: styles.cell.height, padding: 1.5 * theme.padding }}>
						<CircularProgressbar
							value={colContent}
							text={colContent}
							background={true}
							styles={circularProgressbarStyle}
						/>
					</div>
				);
				break;

			case 'col':
				customStyle = Object.assign(customStyle, { flexDirection: 'column' });
				colComponent = subRows.map((subRow, subRowIndex, arr) => (
					<Cell
						key={`subCell_${subRowIndex}`}
						isFirstSection={isFirstSection}
						isLastSubRow={subRowIndex + 1 == arr.length}
						isSubRow={true}
						rowIndex={rowIndex}
						subRowsTotalNum={arr.length}
						cellData={Object.assign({ isSummaryRow }, subRow)}
						rowData={rowData}
						intl={intl}
						rtl={rtl}
					/>
				));
				break;

			case 'counter':
				color = getCountersColor(value, counterColorType);

				let counterWrapperStyle =
					colContent == 0
						? { color, textAlign: 'center', paddingRight: theme.padding / 2, paddingLeft: theme.padding / 2 }
						: {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: theme.fontSizeH2,
								height: theme.fontSizeH2,
								backgroundColor: theme.backgroundColorBright,
								borderRadius: '50%',
								borderWidth: 2,
								borderStyle: 'solid',
								borderColor: color,
						  };

				let isBigNum = Boolean(Number(colContent) > 999);
				if (isBigNum) colContent = '999+';

				let counterStyle = {
					color,
					textAlign: 'center',
					paddingRight: theme.padding / 2,
					paddingLeft: theme.padding / 2,
					...(colContent == 0
						? { fontSize: theme.fontSizeH5 }
						: { fontSize: isBigNum ? theme.mediumFontSize : theme.fontSizeH6 }),
				};

				if (isSummaryRow) {
					counterStyle.fontWeight = theme.strongBold;
					counterWrapperStyle.height = 1.1 * counterWrapperStyle.height;
					counterWrapperStyle.width = 1.1 * counterWrapperStyle.width;
					if (colContent == 0) colContent = '';
				}

				colComponent = (
					<div style={counterWrapperStyle}>
						<Text withTooltip={withTooltip} tooltipText={tooltipText} style={counterStyle}>
							{colContent}
						</Text>
					</div>
				);
				break;

			case 'graph':
				customStyle = Object.assign(customStyle, { paddingTop: theme.padding / 2 });
				let data = {};
				data.datasets = [{ data: colContent, borderColor: theme.brandPrimary, borderWidth: 1, lineTension: 0 }];
				data.labels = _.reverse(colContent).map((value, index, totalDays) => {
					let daysAgo = totalDays.length - 1 - index;
					let date = moment().subtract(daysAgo, 'days');
					return date.format(intl.formatMessage(systemMessages.onlyMonthAndDayFormat));
				});
				if (data.datasets && data.labels) {
					const colStyle = {
						width: (width || styles.cell.width) - theme.padding,
						height: styles.cell.height - 5
					};
					colComponent = (
						<div style={colStyle}>
							<Line options={lineOptions} data={data} />;
						</div>
					);
				}

		}
		if (hideConditionPath && _.get(rowData, hideConditionPath)) colComponent = <></>;
	}

	return (
		<>
			<div
				className={isSeparator || isSubRow ? '' : 'cell'}
				onClick={() => {
					if (onClick) onClick();
				}}
				style={{
					...styles.cell,
					...customStyle,
					...borderStyle,
					...(Boolean(!isSeparator) && {
						backgroundColor: _.isNil(rowIndex) || rowIndex % 2 ? theme.backgroundColorBright : theme.backgroundColor,
					}),
					...(Boolean(isFirstSection) && { position: 'sticky', [rtl ? 'right' : 'left']: distanceFromEdge || 0 }),
					cursor: onClick ? 'pointer' : 'inherit',
				}}
			>
				{colComponent}
			</div>
		</>
	);
};

const ColumnHeader = props => {
	let {
		getTitle,
		title = '',
		style = {},
		className,
		isSection,
		withBorders,
		rtl,
		isFirstSection,
		distanceFromEdge,
		isPdfMode,
	} = props;
	let borderStyle = {};

	if (getTitle) {
		title = getTitle();
	}
	if (withBorders) borderStyle[`border${rtl ? 'Left' : 'Right'}`] = border;
	if (isPdfMode && isSection) borderStyle[`borderTop`] = border;

	return (
		<div
			className={className ? className : null}
			style={{
				...styles.cell,
				...styles.header,
				...(isSection ? styles.sectionHeader : styles.columnHeader),
				...borderStyle,
				...style,
				...(Boolean(isFirstSection) && {
					position: 'sticky',
					[rtl ? 'right' : 'left']: distanceFromEdge || 0,
					zIndex: theme.zIndexes.tableColumnHeader + 1,
				}),
			}}
		>
			<Text style={{ flex: 1 }} enableTitle={true}>
				{title}
			</Text>
		</div>
	);
};

const styles = {
	tableContainer: {
		width: '100%',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 10,
	},
	headersRow: {
		marginBottom: null,
	},
	stickyRow: {
		backgroundColor: theme.backgroundColorBright,
		position: 'sticky',
		top: 0,
		zIndex: theme.zIndexes.companyTableRow,
	},
	cell: {
		overflow: 'hidden',
		borderTop: border,
		borderBottom: border,
		paddingTop: theme.padding,
		paddingBottom: theme.padding,
		display: 'flex',
		justifyContent: 'center',
		height: 80,
		width: 120,
		alignItems: 'center',
	},
	separatorCell: {
		width: 10,
		height: 'inherit',
		backgroundColor: theme.backgroundColorBright,
		border: null,
	},
	shadows: {
		top: { boxShadow: 'inset 0 8px 8px -8px rgba(0,0,0,0.2)' },
		left: { boxShadow: 'inset 8px 0 8px -8px rgba(0,0,0,0.2)' },
		right: { boxShadow: 'inset -8px 0 8px -8px rgba(0,0,0,0.2)' },
		bottom: { boxShadow: 'inset 0 -8px 8px -8px rgba(0,0,0,0.2)' },
	},
	reportSubCell: {
		padding: 2,
		lineHeight: '1.1em',
	},
	header: {
		display: 'flex',
		flex: null,
		alignItems: 'center',
		textAlign: 'start',
		padding: theme.padding / 2,
		margin: null,
		borderTop: null,
		backgroundColor: theme.backgroundColorBright,
		zIndex: theme.zIndexes.tableColumnHeader,
	},
	sectionHeader: {
		height: 55,
		fontSize: theme.fontSizeH5,
		fontWeight: theme.strongBold,
		color: theme.brandPrimary,
	},
	columnHeader: {
		fontSize: theme.fontSize,
		wordBreak: 'break-word',
		alignItems: 'start',
		minHeight: 55,
		maxHeight: 73,
		overflow: 'hidden',
	},
	gradeColorsList: [theme.bransSuccessDark, theme.brandSuccess, theme.brandWarning, theme.brandRealDanger],
	gradeCell: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '95%',
		width: 80,
	},
	emptyCell: [
		{
			background:
				'repeating-linear-gradient( -65deg, rgb(248,248,248) 0px, rgb(248,248,248) 5px, rgba(209, 209, 209) 5px, rgba(209, 209, 209) 6px)',
		},
		{
			background:
				'repeating-linear-gradient(-65deg, white 0px, white 5px, rgba(209, 209, 209) 5px, rgba(209, 209, 209)  6px)',
		},
	],
	counter: {
		size: theme.fontSizeH2,
	},
};
