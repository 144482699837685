import React from 'react';
import _ from 'lodash';

// Components
import InnerCollapsible from './InnerCollapsible';
import InnerCollapsibleRow from './InnerCollapsibleRow';
import Text from './Text';
import theme from '../../assets/css/theme';

/**
 * @typedef SummaryCardProps
 * @property {string | { id: string, defaultMessage: string }} [counterLabel]
 * @property {number | string | { id: string, defaultMessage: string }} [counterCount]
 */

/**
 * 
 * @param {SummaryCardProps & import('./InnerCollapsible').InnerCollapsibleProps} props 
 * @returns 
 */
const CardContainer = (props) => {
  const { labeledCounterProps, counterLabel, counterCount, children, ...rest } = props;

  return (
    <InnerCollapsible
      fullWidth
      noOpenMargin
      isCollapsible={false}
      rightSideOpenComp={
        <LabeledCounter 
          containerStyle={{ transform: 'translateY(2px)' }} 
          counter={counterCount} 
          counterTextStyle={{ fontSize: theme.fontSizeH4, color: theme.brandPrimary }} 
          label={counterLabel} 
          labelTextStyle={{ fontSize: theme.mediumFontSize, color: theme.brandPrimary, fontWeight: 'bold' }}  
        />
      }
      {...rest}
    >
      {children}
    </InnerCollapsible>
  );
}

export default CardContainer;










/**
 * 
 * @param {import('./InnerCollapsibleRow').InnerCollapsibleRowProps} props 
 * @returns 
 */
export const CardContainerRow = props => {
  const { children, ...rest } = props;

  return (
    <InnerCollapsibleRow fullWidth selectedStyle={{ background: theme.backgroundColorSelected }} {...rest}>
      {children}
    </InnerCollapsibleRow>
  );
}

/**
 * @typedef LabeledCounterProps
 * @property {number | string | { id: string, defaultMessage: string }} [counter]
 * @property {React.CSSProperties} [containerStyle]
 * @property {React.CSSProperties} [counterTextStyle]
 * @property {React.CSSProperties} [labelTextStyle]
 * @property {React.CSSProperties} [textStyle]
 * @property {string | { id: string, defaultMessage: string }} [label]
 */

/**
 * 
 * @param {LabeledCounterProps} props 
 * @returns 
 */
const LabeledCounter = props => {
  const { counter, containerStyle = {}, label, counterTextStyle = {}, labelTextStyle = {}, textStyle = {} } = props;

  if (_.isNil(counter))
    return null;

  return (
    <div style={Object.assign({ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }, containerStyle)}>
      <Text style={Object.assign({ fontWeight: theme.strongBold }, textStyle, counterTextStyle)}>{counter}</Text>
      {Boolean(label) && <Text style={Object.assign({}, textStyle, labelTextStyle )}>{label}</Text>}
    </div>
  );
}