import app from './app/reducer';
import auth from './auth/reducer';
import buildings from './buildings/reducer';
import config from './config/reducer';
import configurations from './configurations/reducer';
import contacts from './contacts/reducer';
import companies from './companies/reducer';
import device from './device/reducer';
import drawings from './drawings/reducer';
import checklists from './checklists/reducer';
import checklistItems from  './checklistItems/reducer';
import checklistItemsInstances from  './checklistItemsInstances/reducer';
import propertiesMappings from  './propertiesMappings/reducer';
import propertiesTypes from  './propertiesTypes/reducer';
import propertiesInstances from  './propertiesInstances/reducer';
import lastUpdates from './lastUpdates/reducer';
import members from './members/reducer';
import intl from './intl/reducer';

import floors from './floors/reducer';
import units from './units/reducer';
import users from './users/reducer';
import projects from './projects/reducer';
import posts from './posts/reducer';
import forms from './forms/reducer';
import reporting from './lib/reporting/reducer';
import safety from './safety/reducer';
import stages from './stages/reducer';
import trades from './trades/reducer';
import titles from './titles/reducer';
import employees from './employees/reducer';
import equipment from './equipment/reducer';
import quasiStatics from './quasiStatics/reducer';
import permissions from './permissions/reducer';
import ui from './ui/reducer';
import { SIGN_OUT } from './auth/actions';
import { combineReducers } from 'redux';
import { fieldsReducer as fields } from './lib/redux-fields';
import { firebaseReducer as firebase } from './lib/redux-firebase';
import { routerReducer as routing } from 'react-router-redux';
import { updateStateOnStorageLoad } from './configureStorage';
import {backgroundTabsReducer} from './tabs/reducer';

const resetStateOnSignOut = (reducer, initialState) => (state, action) => {
  // Reset app state on sign out, stackoverflow.com/q/35622588/233902.
  if (action.type === SIGN_OUT + "_SUCCESS") {
    // Preserve state without sensitive data.
    state = {
      app: state.app,
      config: initialState.config,
      contacts: initialState.contacts,
      device: initialState.device,
      intl: initialState.intl,
      routing: state.routing, // Routing state has to be reused.
    };
  }
  return reducer(state, action);
};

export default function configureReducer(initialState, platformReducers) {
  let reducer = combineReducers({
    ...platformReducers,
    app,
    auth,
    buildings,
    config,
    configurations,
    contacts,
    companies,
    device,
    drawings,
    checklists,
    checklistItems,
    checklistItemsInstances,
    propertiesTypes,
    propertiesMappings,
    propertiesInstances,
    employees,
    equipment,
    fields,
    firebase,
    floors,
    intl,
    lastUpdates,
    members,
    routing,
    users,
    units,
    ui,
    reporting,
    safety,
    stages,
    trades,
    titles,
    quasiStatics,
    permissions,
    projects,
    posts,
    forms,
    backgroundTabs: backgroundTabsReducer,
  });

  // The power of higher-order reducers, http://slides.com/omnidan/hor
  reducer = resetStateOnSignOut(reducer, initialState);
  reducer = updateStateOnStorageLoad(reducer);

  return reducer;
}
