import { Record } from '../transit';

const Checklist = Record({
  id: null,
  description: null,
  locations: null,
  ordinalNo: 0,
  contentType:null,
  tags: null,
  type: null,
  stage: null,
  stageId: null,
  duplicatable: false,
  universalId: null,
  originChecklist: null,
  createdTS: null,
  enableDistributionList: null,
  duplicationNo: null,
  isDeleted: null
}, 'checklist', false);

export default Checklist;
