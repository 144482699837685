import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { lokiInstance } from '../../configureMiddleware';
import { v4 as uuidv4 } from 'uuid';
import { platformActions } from '../../platformActions';
import { ProjectContext } from '../../projects/contexts';
import useRefed from '../../hooks/useRefed';

const CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME = 'checklistItemInstances';
const useChecklistItemInstances = props => {
	const [projectIdArg, setProjectId] = useState(props.projectId);
	const [checklistId, setChecklistId] = useState(props.checklistId);
	const [checklistItemId, setChecklistItemId] = useState(props.checklistItemId);
	const [locationId, setLocationId] = useState(props.locationId);
	const [rawQuery, setRawQuery] = useState(props.query);

	const isNative = platformActions.app.isNative();

	const { selectedProjectId, projectLokiLoaded } = useContext(ProjectContext);
	const projectId = projectIdArg || selectedProjectId;
	// state
	const [currViewChecklistsMap, setCurrViewChecklistsMap] = useState({});
	const [currViewChecklists, setCurrViewChecklists] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	// refs
	const lokiChecklistItemInstancesRef = useRef(null);

	const getLokiChecklistItemInstancesQuery = useCallback(() => {
    let lokiQuery = { projectId, '$and': [] };

    if (checklistId) {
      lokiQuery['$and'].push({
        ['$or']: [{ checklistId }],
      });
    }
    if (checklistItemId) {
      lokiQuery['$and'].push({
        ['$or']: [{ checklistItemId }],
      });
    }
    if (locationId) {
      lokiQuery['$and'].push({
        ['$or']: [{ locationId }],
      });
    }
    if (rawQuery) {
      lokiQuery['$and'].push(rawQuery);
    }

    return lokiQuery;
  }, [projectId, checklistId, checklistItemId, locationId, rawQuery]);

	const checklistItemInstancesQuery = useMemo(
		() => (isNative ? null : getLokiChecklistItemInstancesQuery()),
		[isNative, getLokiChecklistItemInstancesQuery],
	);

	const getChecklistItemInstancesMap = useCallback(() => {
		let nextChecklistItemInstances = [];

		if (projectId && lokiChecklistItemInstancesRef.current) {
			nextChecklistItemInstances = lokiChecklistItemInstancesRef.current.cementoFind(checklistItemInstancesQuery);
		}

		return {
			arr: nextChecklistItemInstances,
			map: _.keyBy(nextChecklistItemInstances, 'id'),
		};
	}, [checklistItemInstancesQuery, projectId, lokiChecklistItemInstancesRef.current]);

	const getChecklistItemInstancesMapRef = useRefed(getChecklistItemInstancesMap, [getChecklistItemInstancesMap]);

	const lokiChecklistItemInstancesListener = useCallback(collectionName => {
		if (collectionName === CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME) {
			const { arr, map } = getChecklistItemInstancesMapRef.current(); // using a ref because the function is passed to a listener and otherwise would run with old params
			setCurrViewChecklists(arr);
			setCurrViewChecklistsMap(map);
		}
	}, []);

	// didMount
	useEffect(() => {
		let cleanUp;
		if (!isNative) {
			const lokiChecklistItemInstances = lokiInstance.getCollection(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME);

			lokiChecklistItemInstancesRef.current = lokiChecklistItemInstances;

			lokiChecklistItemInstancesListener(CHECKLIST_ITEM_INSTANCES_COLLECTION_NAME);
			setIsLoading(false);
			const instancesListenerId = uuidv4();
			lokiChecklistItemInstances.cementoOn(instancesListenerId, lokiChecklistItemInstancesListener);

			cleanUp = () => {
				lokiChecklistItemInstances.cementoOff(instancesListenerId);
			};
		}

		// willUnmount
		return cleanUp;
	}, [projectLokiLoaded, checklistItemInstancesQuery]);

	const refetch = useCallback(({ projectId, checklistId, checklistItemId, locationId, query }) => {
    if (projectId) {
      setProjectId(projectId);
    }
    if (checklistId) {
      setChecklistId(checklistId);
    }
    if (checklistItemId) {
      setChecklistItemId(checklistItemId);
    }
    if (locationId) {
      setLocationId(locationId);
    }
    if (query && !_.isEqual(query, rawQuery)) {
      setRawQuery(query);
    }
  }, [rawQuery]);

	useEffect(() => {
		refetch(props);
	}, [props.projectId, props.checklistId, props.checklistItemId, props.locationId, props.query]);

	const memoizedValue = useMemo(
		() => ({
			checklistInstancesArr: currViewChecklists,
			checklistInstances: currViewChecklistsMap,
			isLoading,
			refetch,
		}),
		[currViewChecklists, currViewChecklistsMap, isLoading],
	);

	return memoizedValue;
};

export default useChecklistItemInstances;

export const ChecklistItemInstancesHOC = ({ projectId, checklistId, checklistItemId, locationId, query, children }) => {
  const checklistItemInstancesProps = useChecklistItemInstances({
    projectId,
    checklistId,
    checklistItemId,
    locationId,
    query,
  });

  return children(checklistItemInstancesProps);
};

export const withChecklistItemInstances = Component => {
	const Wrapped = props => {
		try {
			const checklistItemInstancesProps = useChecklistItemInstances({
				projectId: props.selectedProjectId || props.projectId || props.match?.params?.selectedProjectId,
			});
			return <Component {...checklistItemInstancesProps} {...props} />;
		} catch (error) {
			return null;
		}
	};
	return Wrapped;
};
