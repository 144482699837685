import { defineMessages } from 'react-intl';

export default defineMessages({  
  projectTitle: {
    id: 'pdf.title',
    defaultMessage: 'Project: <b>{title}</b>',
  },  
  projectAddress: {
    id: 'pdf.adress',
    defaultMessage: 'Address: <b>{address}</b>',
  },  
  reportCreator: {
    id: 'pdf.creator',
    defaultMessage: 'Report creator: {name} {companyName}',
  },  
  clickToOpen: {
    "defaultMessage": "Click to open",
    "id": "pdf.click",
  },  
  date: {
    id: 'pdf.date',
    defaultMessage: 'Date: {date}',
  },  
  subject: {
    id: 'pdf.subject.title',
    defaultMessage: 'Subject: <u>{title}</u>',
  },  
  location: {
    id: 'pdf.subject.location',
    defaultMessage: 'Location',
  },    
  assignTo: {
    id: 'pdf.assignTo.title',
    defaultMessage: 'Assign to',
  },  
  dueDate: {
    id: 'pdf.dueDate.title',
    defaultMessage: 'Due date',
  },  
  description: {
    id: 'pdf.description.title',
    defaultMessage: 'Description',
  },  
  imageAttched: {
    id: 'pdf.imageAttched.title',
    defaultMessage: 'Image {number}',
  },  
  comments: {
    id: 'pdf.comments.title',
    defaultMessage: 'History and comments (Based on Cemento system)',
  },  
  commentOwnerName: {
    id: 'pdf.commentsTable.owner.caption',
    defaultMessage: 'Name',
  },  
  commentDate: {
    id: 'pdf.commentsTable.date',
    defaultMessage: 'Date',
  },  
  commentData: {
    id: 'pdf.commentsTable.data',
    defaultMessage: 'Comment',
  },  
  openIssueReport: {
    id: 'pdf.subject.opened',
    defaultMessage: 'Opened task report',
  },
  closedIssueReport: {
    id: 'pdf.subject.closed',
    defaultMessage: 'Closed task report',
  },
  resolvedIssueReport: {
    id: 'pdf.subject.resolved',
    defaultMessage: 'Resolved task report',
  }, 
  multiReportSubject: {
    id: 'pdf.subject.multi',
    defaultMessage: 'Tasks report',
  }, 
  printPdfTitle: {
    defaultMessage: 'Print as PDF file',
    id: 'pdf.alert.title'
  },
  printPdfContant: {
    defaultMessage: 'Are you sure you want to print the following items?',
    id: 'pdf.alert.Contant'
  },
  toManyItemsTitle: {
    defaultMessage: 'Too many tasks',
    id: 'pdf.alert.toMuch.title'
  },
  noGroupsSelected: {
    "defaultMessage": "No groups selected, Please select groups to export",
    "id": "pdf.export.noGroupsSelected",
  },
  selectGroupsToExport: {
    "defaultMessage": "Select the groups you want to export",
    "id": "pdf.export.selectGroupsToExport",
  },
  postsExport:{
    tooManyPostsSelected: {
      defaultMessage: `PDF export is limited to 150 items, apply filters to reduce the amount, please. Current amount {numSelectedPosts}`,
      id: 'pdf.postsExport.tooManyPostsSelected',
    },
    generateAnyway: {
      defaultMessage: 'Generate form anyway',
      id: 'pdf.postsExport.generateAnyway'
    },
    cancelFormGeneration: {
      defaultMessage: 'Cancel form',
      id: 'pdf.postsExport.cancelFormGeneration'
    }
  },
  toManyItems: {
    defaultMessage: 'Number of tasks to be exported should not exceed 200. Please filter and retry.',
    id: 'pdf.alert.toMuch'
  },
  postReport: {
    id: 'pdf.subject.post',
    defaultMessage: 'Record report',
  }, 
  postsReport: {
    id: 'pdf.subject.posts',
    defaultMessage: 'Records report',
  }, 
  loading: {
    id: 'pdf.loading.single',
    defaultMessage: 'Generating file, please wait...',
  },
  creatingMultiPDF: {
    defaultMessage: 'Generating task {counter} out of {sumCounter}',
    id: 'pdf.loading.multi'
  },  
  noValue: {
    id: 'pdf.noValue',
    defaultMessage: '(Not specified)',
  },
  multiIssuesContent: {
    id: 'pdf.content.multiIssues',
    defaultMessage: 'Hello,\n\n Please find a detailed description of multiple tasks pdf file in {projectName} project.\nFor your convenience, you can resolve any task or add a comment using Cemento.AI application.\n\nThanks,\n{userName}\n\nAttached file: ',
  },
  multiPostsContent: {
    id: 'pdf.content.multiPosts',
    defaultMessage: 'Hello,\n\n Please find a detailed Records from {projectName} project.\nFor your convenience, you can read more detailes using Cemento.AI application.\n\nThanks,\n{userName}\n\nAttached file: ',
  },
  openedIssueContent: {
    id: 'pdf.content.openedIssue',
    defaultMessage: 'Hello,\n\n Please find a PDF file with detailed description of an open task in {projectName} project.\nFor your convenience, you can resolve task or comment using Cemento.AI application.\n\nThanks,\n{userName}\n\nAttached file: ',
  },
  resolvedIssueContent: {
    id: 'pdf.content.resolvedIssue',
    defaultMessage: 'Hello,\n\n Please find a PDF file with detailed description of a task in {projectName} project.\nFor your convenience, you can change the task status or comment using Cemento.AI application.\n\nThanks,\n{userName}\n\nAttached file: ',
  },
  postContent: {
    id: 'pdf.content.post',
    defaultMessage: 'Hello,\n\n Please find a PDF file with a message in {projectName} project.\nFor your convenience, you can comment on this message using Cemento.AI application.\n\nThanks,\n{userName}\n\nAttached file: ',
  },
  resolvedIssueStatus: {
    id: 'pdf.status.resolvedIssue',
    defaultMessage: 'Resolved task (pending approval)',
  },
  openedIssueStatus: {
    id: 'pdf.status.openedIssue',
    defaultMessage: 'Open task (pending resolution)',
  },
  closedIssueStatus: {
    id: 'pdf.status.closedIssue',
    defaultMessage: 'Closed task (approved)',
  },
  status: {
    id: 'pdf.status.caption',
    defaultMessage: 'Status',
  },
  number: {
    id: 'pdf.title.number',
    defaultMessage: 'No.',
  },
  checklistItemContent: {
    id: 'pdf.content.checklistItem',
    defaultMessage: 'Hello,\n\nPlease find a PDF Checklist-item report file in {projectName} project.\nFor your convenience, you can check this or other checklists in Cemento.AI application.\n\nThanks,\n{userName}',
  },
  checklistContent: {
    id: 'pdf.content.checklist',
    defaultMessage: 'Hello,\n\nPlease find a PDF Checklist report file in {projectName} project.\nFor your convenience, you can check this or other checklists in Cemento.AI application.\n\nThanks,\n{userName}',
  },
  checklistMailContent: {
    id: 'pdf.mailcontent.checklist',
    defaultMessage: 'Hi,<br><br>Attached please find quality control report, issued by {userName}, {companyName}.<br><br>Project name: {projectTitle}<br>Location: {locationTitle}<br>Checklist name: {checklistDescription}<br><br>For your convenience, you can find all the information and more at app.cemento.ai<br><br>Thanks, <br>Cemento Technologies<br>',
  },
  formMailContent: {
    id: 'pdf.mailcontent.form',
    defaultMessage: 'Hi,<br><br>Attached please find {formName} form, issued by {userName}, {companyName}.<br><br>Project name: {projectTitle}<br>Location: {locationTitle}<br><br>For your convenience, you can find all the information and more at app.cemento.ai<br><br>Thanks, <br>Cemento Technologies<br>',
  },
  checklistItemReport: {
    id: 'pdf.subject.checklistItem',
    defaultMessage: 'Quality control report',
  }, 
  checklistReport: {
    id: 'pdf.subject.checklist',
    defaultMessage: 'Quality control report',
  }, 
  generating: {
    defaultMessage: 'Generating, Please wait',
    id: 'pdf.loading.generating'
  },
  downloading: {
    defaultMessage: 'Downloading, Please wait',
    id: 'pdf.loading.downloading'
  },
  produceForm: {
    defaultMessage: 'Generate report',
    id: 'pdf.forms.produceForm'
  },
  exportRegistrationForm: {
    defaultMessage: 'Export certifications registration form',
    id: 'pdf.forms.exportRegistrationForm'
  },
  actions: {
    defaultMessage: 'Actions',
    id: 'pdf.forms.actions'
  },
  missingPdf: {
    title: {
      defaultMessage: 'File not found',
      id: 'pdf.missingPdf.title'
    },
    message: {
      defaultMessage: 'The file for this report was not found',
      id: 'pdf.missingPdf.message',
    },
  },
});