import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import theme from "../../assets/css/theme";

const NumberBadge = ({
  data,
  style,
  rtl,
  isActive,
  size = 20,
  leftOrRightOffset,
  topOffset,
  inactiveColor = theme.brandNeutral,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        height: size,
        width: size,
        maxWidth: "60px",
        ...style,
      }}
    >
      <div
        style={{
          position: "relative",
          [rtl ? "left" : "right"]: leftOrRightOffset,
          top: topOffset,
          height: "inherit",
          width: "inherit",
          maxWidth: "30px",
          maxHeight: "30px",
          textAlign: "center",
          backgroundColor: isActive ? theme.brandPrimary : inactiveColor,
          borderRadius: "50%",
          zIndex: theme.zIndexes.numberBadge,
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "inherit",
            width: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: theme.defaultFont,
            color: theme.backgroundColor,
            fontWeight: theme.strongBold,
            fontSize:
              data < 100 ? theme.smallFontSize + 1 : theme.smallFontSize,
          }}
        >
          {data}
        </div>
      </div>
    </div>
  );
};

const enhance = compose(connect((state) => ({ rtl: state.app.rtl }), {}));

export default enhance(NumberBadge);
