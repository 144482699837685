import { CLEAR_ALL_DATA } from '../app/actions';
import {ADD_CURRENT_TAB, REMOVE_CURRENT_TAB} from './actions';

const initialState = {
  tabIds: []
};

export const backgroundTabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CURRENT_TAB:
      return {
        ...state,
        tabIds: [...state.tabIds, action.payload]
      };
    case REMOVE_CURRENT_TAB:
      return {
        ...state,
        tabIds: state.tabIds.slice(0, state.tabIds.length - 1)
      };

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }

    default:
      return state;
  }
}