import React from 'react';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
	wrapper: {
		width: '100%',
		height: '100%',
		overflow: 'auto',
		paddingBottom: 'calc(50vh - 100px)',
	},
}));

const CardList = ({ children, ...props }) => {
	const classes = useStyles();

	return (
		<List className={classes.wrapper} {...props}>
			{children}
		</List>
	);
};

export default CardList;
