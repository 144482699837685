import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '350px',
	},
	media: {
		height: '200px',
		width: '100%',
	},
	bold: {
		fontWeight: 'bold',
	},
}));

const InfoBoxCard = ({ title, description, imageUrl, onClick }) => {
	const classes = useStyles();

	return (
		<Card onClick={onClick} className={classes.root}>
			<CardMedia className={classes.media} image={imageUrl} />
			<CardContent>
				<Typography className={classes.bold} variant='h6' color='textPrimary' component='p'>
					{title}
				</Typography>
				<Typography variant='body2' color='textSecondary' component='p'>
					{description}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default InfoBoxCard;
