import { defineMessages } from 'react-intl';

export default defineMessages({
  requiredActions: {
    other: {
      defaultMessage: "Other required action",
      id: 'safety.requiredActions.other'
    }
  },
  safetyTitle: {
    defaultMessage: 'Safety',
    id: 'safety.main.title'
  },
  MultiProjectView: {
    defaultMessage: 'Multi project',
    id: 'safety.main.MultiProjectView'
  },
  employees: {
    defaultMessage: 'Workers',
    id: 'safety.main.employees'
  },
  companies: {
    defaultMessage: 'Companies',
    id: 'safety.main.companies'
  },
  equipment: {
    defaultMessage: 'Equipment',
    id: 'safety.main.equipment'
  },
  reports: {
    defaultMessage: 'Reports',
    id: 'safety.main.reports'
  },
  expiredSoon: {
    defaultMessage: 'Expired/Expired soon only',
    id: 'safety.main.expiredSoon'
  },
  alerts: {
    defaultMessage: 'Alerts',
    id: 'safety.main.alerts'
  },
  activeOnly: {
    defaultMessage: 'Active only',
    id: 'safety.main.activeOnly'
  },
  addSafetyIssue: {
    defaultMessage: 'Add safety issue',
    id: 'safety.actions.addSafetyIssue'
  },
  expiredXDaysAgo: {
    defaultMessage: 'Expired {value, plural, =0{Today} one {Yesterday} other {# days ago}}',
    id: 'safety.main.expiredXDaysAgo'
  },
  validForXMoreDays: {
    defaultMessage: 'Valid for {value} more days',
    id: 'safety.main.validForXMoreDays'
  },
  missing: {
    defaultMessage: 'Missing',
    id: 'safety.main.missing'
  },
  notSigned: {
    defaultMessage: 'Not signed',
    id: 'safety.report.notSigned'
  },
  notActive: {
    defaultMessage: 'Not active',
    id: 'safety.report.notActive'
  },
  forms: {
    captions: {
      checklists: {
        "defaultMessage": "checklists",
        "id": "safety.forms.captions.checklists"
      },
      issues: {
        "defaultMessage": "Issues",
        "id": "safety.forms.captions.issues"
      },
    },
    status: {
      signed: {
        "defaultMessage": "Signed",
        "id": "safety.forms.status.signed"
      },
      edit: {
        "defaultMessage": "Draft",
        "id": "safety.forms.status.edit"
      },
      processing: {
        "defaultMessage": "Processing",
        "id": "safety.forms.status.processing"
      },
      awaitingSignature: {
        "defaultMessage": "Awaiting signature",
        "id": "safety.forms.status.awaitingSignature"
      },
      late: {
        "defaultMessage": "Late",
        "id": "safety.forms.status.late"
      },
      notSigned: {
        "defaultMessage": "Not signed",
        "id": "safety.forms.status.notSigned"
      },
    },
    subStatus: {
      dailyReport: {
        edit: {
          "defaultMessage": "Awaiting signature",
          "id": "safety.forms.subStatus.dailyReport.edit"
        },
        signed: {
          "defaultMessage": "View only",
          "id": "safety.forms.subStatus.dailyReport.signed"
        },
        late: {
          "defaultMessage": "Not today's report",
          "id": "safety.forms.subStatus.dailyReport.late"
        },
      }
    },
    buttons: {
      moveToSignature: {
        defaultMessage: 'Sign and send',
        id: 'safety.forms.buttons.moveToSignature'
      },
      preview: {
        defaultMessage: 'Review PDF',
        id: 'safety.forms.buttons.preview'
      },
      openPdf: {
        defaultMessage: 'Open PDF',
        id: 'safety.forms.buttons.openPdf'
      },
      newPost: {
        defaultMessage: 'New documentation',
        id: 'safety.forms.buttons.newPost'
      },
      newIssue: {
        defaultMessage: 'New issue',
        id: 'safety.forms.buttons.newIssue'
      },
    }
  },
  issues: {
    objectsIssues:{
      "defaultMessage": "Attached Issues",
      "id": "safety.issues.objectsIssues"
    },
    sectionTitle: {
      "defaultMessage": "Safety issues",
      "id": "safety.issues.sectionTitle"
    },
    openedIssues: {
      "defaultMessage": "Open issues",
      "id": "safety.issues.openedIssues"
    },
    closedIssues: {
      "defaultMessage": "Closed issues",
      "id": "safety.issues.closedIssues"
    },
    docs: {
      "defaultMessage": "Documentations",
      "id": "safety.issues.docs"
    },
    openedTodayByMeIssues: {
      "defaultMessage": "Items created during inspection",
      "id": "safety.issues.openedTodayByMeIssues"
    },
    otherOpenedIssues: {
      "defaultMessage": "Other issues",
      "id": "safety.issues.otherOpenedIssues"
    },
    closedTodayByMeIssues: {
      "defaultMessage": "Closed tasks during inspection",
      "id": "safety.issues.closedTodayByMeIssues"
    },
  },
  objectsNames: {
    posts: {
      defaultMessage: 'Post',
      id: 'safety.objects.posts'
    },
    issues: {
      defaultMessage: 'Issue',
      id: 'safety.objects.issues'
    },
    records:{
      defaultMessage: 'Record',
      id: 'safety.objects.records'
    },
    employees: {
      "defaultMessage": "Worker",
      "id": "safety.objects.employees"
    },
    equipment: {
      "defaultMessage": "Equipment",
      "id": "safety.objects.equipment"
    },
    members: {
      "defaultMessage": "Member",
      "id": "safety.objects.members"
    },
    companies: {
      "defaultMessage": "Company",
      "id": "safety.objects.companies"
    },
    locationsGroupsManagement: {
      "defaultMessage": "Group",
      "id": "safety.objects.locationsGroupsManagement"
    }
  },
  noReport: {
    title: {
      "defaultMessage": "Welcome to the safety screen",
      "id": "safety.noReport.title"
    },
    content: {
      "defaultMessage": "In the safety screen you can manage and view workers and equipment details, permits expiration and other issues.\nTo start using the safety screens, please contact Cemento team at info@cemento.ai",
      "id": "safety.noReport.content"
    }
  },
  expirations: {
    ok: {
      "defaultMessage": "Valid",
      "id": "safety.expirations.ok"
    },
    warning_3monthes: {
      "defaultMessage": "Valid at least for a month",
      "id": "safety.expirations.warning_3monthes"
    },
    warning: {
      "defaultMessage": "Warning",
      "id": "safety.expirations.warning"
    },
    expired: {
      "defaultMessage": "Expired",
      "id": "safety.expirations.expired"
    },
    total: {
      "defaultMessage": "Total",
      "id": "safety.expirations.total"
    },
  },
  fine: {
    amount: {
      "defaultMessage": "Fine amount",
      "id": "safety.fine.amount"
    },
    description: {
      "defaultMessage": "Description",
      "id": "safety.fine.description"
    },
    reciver: {
      "defaultMessage": "Fine reciver",
      "id": "safety.fine.reciver"
    },
    event_description: {
      "defaultMessage": "Case details",
      "id": "safety.fine.event_description"
    },
  },
  severity: {
    title: {
      '3': {
        "defaultMessage": "Critical danger",
        "id": "safety.severity.title_3"
      },
      '2': {
        "defaultMessage": "Unacceptable danger",
        "id": "safety.severity.title_2"
      },
      '1': {
        "defaultMessage": "Accaptable danger",
        "id": "safety.severity.title_1"
      },
    },
    smallTitle: {
      '3': {
        "defaultMessage": "Stop working on site immidiatly and fix the issue",
        "id": "safety.severity.smallTitle_3"
      },
      '2': {
        "defaultMessage": "Issue should be fixed as soon as possible",
        "id": "safety.severity.smallTitle_2"
      },
      '1': {
        "defaultMessage": "Not critical fix required (Documentation only)",
        "id": "safety.severity.smallTitle_1"
      },
    },
  },
  generalReport: {
    projectInfo: {
      "defaultMessage": "Project info",
      "id": "safety.generalReport.projectInfo"
    },
  },
  menu: {
    generalReport: {
      caption: {
        "defaultMessage": "General report",
        "id": "safety.menu.generalReport.caption"
      },
    },
    employeesPresence: {
      caption: {
        "defaultMessage": "Employees Presence (Carousels)",
        "id": "safety.menu.employeesPresence.caption"
      },
      smallText: {
        "defaultMessage": "{value} present employees",
        "id": "safety.menu.employeesPresence.smallText"
      },
      smallText_empty: {
        "defaultMessage": "No present ",
        "id": "safety.menu.employeesPresence.smallText_empty"
      },
    },
    issues: {
      caption: {
        "defaultMessage": "Open issues",
        "id": "safety.menu.issues.caption"
      },
      smallText: {
        "defaultMessage": "open issues",
        "id": "safety.menu.issues.smallText"
      },
      smallText_empty: {
        "defaultMessage": "No open issues",
        "id": "safety.menu.issues.smallText_empty"
      },
    },
    documentations: {
      caption: {
        "defaultMessage": "Documentations",
        "id": "safety.menu.documentations.caption"
      },
      smallText: {
        "defaultMessage": "{value} items",
        "id": "safety.menu.documentations.smallText"
      },
      smallText_empty: {
        "defaultMessage": "No items",
        "id": "safety.menu.documentations.smallText_empty"
      },
    },
    employees: {
      caption: {
        "defaultMessage": "Workers",
        "id": "safety.menu.employees.caption"
      },
      smallText: {
        "defaultMessage": "{value} expired/missing permits",
        "id": "safety.menu.employees.smallText"
      },
      smallText_valid: {
        "defaultMessage": "All permits are valid",
        "id": "safety.menu.employees.smallText_valid"
      },
      new: {
        "defaultMessage": "New worker",
        "id": "safety.menu.employees.new"
      },
    },
    equipment: {
      caption: {
        "defaultMessage": "Equipment",
        "id": "safety.menu.equipment.caption"
      },
      smallText: {
        "defaultMessage": "{value} expired reviews",
        "id": "safety.menu.equipment.smallText"
      },
      smallText_valid: {
        "defaultMessage": "All reviews are valid",
        "id": "safety.menu.equipment.smallText_valid"
      },
      new: {
        "defaultMessage": "New equipment",
        "id": "safety.menu.equipments.new"
      },
    },
    inspections: {
      caption: {
        "defaultMessage": "Inspections",
        "id": "safety.menu.inspections.caption"
      },
      safetyInspector: {
        smallText_today: {
          "defaultMessage": "Last safety inspector inspection was done today",
          "id": "safety.menu.inspections.safetyInspector.smallText_today"
        },
        smallText_yesterday: {
          "defaultMessage": "Last safety inspector inspection was done yesterday",
          "id": "safety.menu.inspections.safetyInspector.smallText_yesterday"
        },
        smallText: {
          "defaultMessage": "{value} days since last safety inspector inspections",
          "id": "safety.menu.inspections.safetyInspector.smallText"
        },
      },    
      safetyAssistant: {
        smallText_today: {
          "defaultMessage": "Last safety assistant inspection was done today",
          "id": "safety.menu.inspections.safetyAssistant.smallText_today"
        },
        smallText_yesterday: {
          "defaultMessage": "Last safety assistant inspection was done yesterday",
          "id": "safety.menu.inspections.safetyAssistant.smallText_yesterday"
        },
        smallText: {
          "defaultMessage": "{value} days since last safety assistant inspections",
          "id": "safety.menu.inspections.safetyAssistant.smallText"
        },
      }
    },
    company: {
      title: {
        defaultMessage: 'Overview',
        id: 'analytics.menu.company.title'
      },
      main: {
        defaultMessage: 'Company safety',
        id: 'analytics.menu.company.main'
      },
    }
  },
  search: {
    employees: {
      "defaultMessage": "Search for name or ID",
      "id": "safety.search.employees"
    },
    equipment: {
      "defaultMessage": "Search for equipment",
      "id": "safety.search.equipment"
    },
    trainings: {
      "defaultMessage": "Search for training type",
      "id": "safety.search.training"
    },
    document: {
      "defaultMessage": "Search for document type",
      "id": "safety.search.document"
    }
  },
  grade: {
    avgGrade: {
      "defaultMessage": "Avg. grade",
      "id": "safety.grade.avgGrade"
    },
    safetyGrade: {
      "defaultMessage": "Safety grade",
      "id": "safety.grade.safetyGrade"
    },
    details: {
      "defaultMessage": "Details",
      "id": "safety.grade.details"
    },
  },
  gradeDetails: {
    points: {
      "defaultMessage": "{value} points",
      "id": "safety.gradeDetails.points"
    },
    noIssues: {
      "defaultMessage": "There are no open safety issues",
      "id": "safety.gradeDetails.noIssues"
    },
    severity3overdue: {
      "defaultMessage": "{value} high severity issues open ({secValue} days overdues)",
      "id": "safety.gradeDetails.severity1overdue"
    },
    severity2overdue: {
      "defaultMessage": "{value} med severity issues open ({secValue} days overdues)",
      "id": "safety.gradeDetails.severity2overdue"
    },
    employeesExpired: {
      "defaultMessage": "{value} workers with expired/missing permit",
      "id": "safety.gradeDetails.employeesExpired"
    },
    equipmentExpired: {
      "defaultMessage": "{value} equipment with expired permit",
      "id": "safety.gradeDetails.equipmentExpired"
    },
    daysSinceLastInspection: {
      "defaultMessage": "{value} days since last safety survey",
      "id": "safety.gradeDetails.daysSinceLastInspection"
    },
    daysSinceLastForm: {
      "defaultMessage": "{value} days since last {formTitle}",
      "id": "safety.gradeDetails.daysSinceLastForm"
    },
  },
  newInspectionTour: {
    "defaultMessage": "New Inspection Tour",
    "id": "safety.newInspectionTour"
  },
  settings: {
    openedIssueSeverity3: {
      "defaultMessage": "Points per Unacceptable danger open issue",
      "id": "safety.settings.openedIssueSeverity3"
    },
    openedIssueSeverity2: {
      "defaultMessage": "Unacceptable Critical danger open issue",
      "id": "safety.settings.openedIssueSeverity2"
    },
    openedIssueDelaysDaySeverity3: {
      "defaultMessage": "Critical danger - points per day of opened issue after due date",
      "id": "safety.settings.openedIssueDelaysDaySeverity3"
    },
    openedIssueDelaysDaySeverity2: {
      "defaultMessage": "Unacceptable danger - points per day of opened issue after due date",
      "id": "safety.settings.openedIssueDelaysDaySeverity2"
    },
    workersInvalidCertification: {
      "defaultMessage": "Points per worker with expired permit",
      "id": "safety.settings.workersInvalidCertification"
    },
    equipmentInvalidCertification: {
      "defaultMessage": "Points per equipment with expired review",
      "id": "safety.settings.equipmentInvalidCertification"
    },
    maximumDaysBetweenSurveys: {
      "defaultMessage": "Maximum OK days between inspections",
      "id": "safety.settings.maximumDaysBetweenSurveys"
    },
    siteSafetySurveyPointsPerDayOverMaxDays: {
      "defaultMessage": "Points per day without inspections after maximum OK days limit",
      "id": "safety.settings.siteSafetySurveyPointsPerDayOverMaxDays"
    },
  },
  safetyTable: {
    project: {
      "defaultMessage": "Project",
      "id": "safety.safetyTable.section.project"
    },
    safetyGrade: {
      "defaultMessage": "Safety grade",
      "id": "safety.safetyTable.section.safetyGrade"
    },
    safetyStatus: {
      "defaultMessage": "Safety status",
      "id": "safety.safetyTable.section.safetyStatus"
    },
    recentUse: {
      "defaultMessage": "Activity {value}",
      "id": "safety.safetyTable.section.recentUse"
    },
    averageGrade: {
      "defaultMessage": "Average grade",
      "id": "safety.safetyTable.averageGrade"
    },
    currentGrade: {
      "defaultMessage": "Current grade",
      "id": "safety.safetyTable.currentGrade"
    },
    last14SafetyGrades: {
      "defaultMessage": "Last 14 days safety grades",
      "id": "safety.safetyTable.last14SafetyGrades"
    },
    openSafetyIssues: {
      "defaultMessage": "Open issues",
      "id": "safety.safetyTable.openSafetyIssues"
    },
    openSafetyIssuesHigh: {
      "defaultMessage": "Critical danger",
      "id": "safety.safetyTable.openSafetyIssuesHigh"
    },
    openSafetyIssuesMedium: {
      "defaultMessage": "Unacceptable danger",
      "id": "safety.safetyTable.openSafetyIssuesMedium"
    },
    openSafetyIssuesLow: {
      "defaultMessage": "Low danger",
      "id": "safety.safetyTable.openSafetyIssuesLow"
    },
    employeesCertificationsExpired: {
      "defaultMessage": "Expired",
      "id": "safety.safetyTable.employeesCertificationsExpired"
    },
    employeesCertificationsWarning: {
      "defaultMessage": "Expire soon",
      "id": "safety.safetyTable.employeesCertificationsWarning"
    },
    equipmentReviewsExpired: {
      "defaultMessage": "Expired",
      "id": "safety.safetyTable.equipmentReviewsExpired"
    },
    equipmentReviewsWarning: {
      "defaultMessage": "Expire soon",
      "id": "safety.safetyTable.equipmentReviewsWarning"
    },
    employeesCertifications: {
      "defaultMessage": "Workers certificates",
      "id": "safety.safetyTable.employeesCertifications"
    },
    equipmentReviews: {
      "defaultMessage": "Equipment certificates",
      "id": "safety.safetyTable.equipmentReviews"
    },
    weeklyOpenIssues: {
      "defaultMessage": "New issues and records",
      "id": "safety.safetyTable.weeklyOpenIssues"
    },
    weeklyEmployees: {
      "defaultMessage": "Workers certificates",
      "id": "safety.safetyTable.weeklyEmployees"
    },
    weeklyEquipment: {
      "defaultMessage": "Equipment certificates",
      "id": "safety.safetyTable.weeklyEquipment"
    },
    lastTour: {
      "defaultMessage": "Last tour: {value}",
      "id": "safety.safetyTable.lastTour"
    },
    inspectorTours: {
      "defaultMessage": "Inspections",
      "id": "safety.safetyTable.inspectorTours"
    },
    lastInspectorTour: {
      "defaultMessage": "Last inspection",
      "id": "safety.safetyTable.lastInspectorTour"
    },
    noInspectionTour: {
      "defaultMessage": "No inspection",
      "id": "safety.safetyTable.noInspectorTour"
    },
    daysAgo: {
      "defaultMessage": "({value, plural, =0{Today} one {Yesterday} other {# days ago}})",
      "id": "safety.safetyTable.daysAgo"
    },
    toursCount: {
      "defaultMessage": "{value, plural, =0{No tours from the past seven days} one{one tour from the past seven days} other {# tours from the past seven days}}",
      "id": "safety.safetyTable.toursCount"
    },
    customDates: {
      label: {
        "defaultMessage": "Dates",
        "id": "safety.safetyTable.timeFiltering.customDatesLabel"
      },
      title: {
        "defaultMessage": "in dates",
        "id": "safety.safetyTable.timeFiltering.customDatesTitle"
      }
    },
    today: {
      label: {
        "defaultMessage": "Today",
        "id": "safety.safetyTable.timeFiltering.todayLabel"
      },
      title: {
        "defaultMessage": "today",
        "id": "safety.safetyTable.timeFiltering.todayTitle"
      }
    },
    past24: {
      label: {
        "defaultMessage": "Past 24 hours",
        "id": "safety.safetyTable.timeFiltering.past24Label"
      },
      title: {
        "defaultMessage": "in the past 24 hours",
        "id": "safety.safetyTable.timeFiltering.past24Title"
      }
    },
    yesterday: {
      label: {
        "defaultMessage": "Yesterday",
        "id": "safety.safetyTable.timeFiltering.yesterdayLabel"
      },
      title: {
        "defaultMessage": "yesterday",
        "id": "safety.safetyTable.timeFiltering.yesterdayTitle"
      }
    },
    currentWeek: {
      label: {
        "defaultMessage": "Current week",
        "id": "safety.safetyTable.timeFiltering.currentWeekLabel"
      },
      title: {
        "defaultMessage": "this week",
        "id": "safety.safetyTable.timeFiltering.currentWeekTitle"
      }
    },
    past7Days: {
      label: {
        "defaultMessage": "Past 7 days",
        "id": "safety.safetyTable.timeFiltering.past7DaysLabel"
      },
      title: {
        "defaultMessage": "in the past 7 days",
        "id": "safety.safetyTable.timeFiltering.past7DaysTitle"
      }
    },
    lastWeek: {
      label: {
        "defaultMessage": "Last week",
        "id": "safety.safetyTable.timeFiltering.lastWeekLabel"
      },
      title: {
        "defaultMessage": "last week",
        "id": "safety.safetyTable.timeFiltering.lastWeekTitle"
      }
    },
    currentMonth: {
      label: {
        "defaultMessage": "Current month",
        "id": "safety.safetyTable.timeFiltering.currentMonthLabel"
      },
      title: {
        "defaultMessage": "this month",
        "id": "safety.safetyTable.timeFiltering.currentMonthTitle"
      }
    },
    past30Days: {
      label: {
        "defaultMessage": "Past 30 days",
        "id": "safety.safetyTable.timeFiltering.past30DaysLabel"
      },
      title: {
        "defaultMessage": "in the past 30 days",
        "id": "safety.safetyTable.timeFiltering.past30DaysTitle"
      }
    },
    lastMonth: {
      label: {
        "defaultMessage": "last month",
        "id": "safety.safetyTable.timeFiltering.lastMonthLabel"
      },
      title: {
        "defaultMessage": "last month",
        "id": "safety.safetyTable.timeFiltering.lastMonthTitle"
      }
    },
    projectBeginning: {
      label: {
        "defaultMessage": "beginning of the project",
        "id": "safety.safetyTable.timeFiltering.projectBeginningLabel"
      },
      title: {
        "defaultMessage": "from the beginning of the project",
        "id": "safety.safetyTable.timeFiltering.projectBeginningTitle"
      }
    },
  },
  certification: {
    instructorName: {
      defaultMessage: "Instructor's name",
      id: 'safety.certification.instructorName'
    },
    instructorId: {
      defaultMessage: "Instructor's ID number",
      id: 'safety.certification.instructorId'
    },
    instructorSignature: {
      defaultMessage: "Instructor's signature",
      id: 'safety.certification.instructorSignature'
    },
    workerName: {
      defaultMessage: "Worker's name",
      id: "safety.certification.workerName"
    },
    workerSignature: {
      defaultMessage: "Worker's signature",
      id: "safety.certification.workerSignature"
    },
    empty: {
      defaultMessage: "No certification",
      id: "safety.noCertification"
    },
  },
  expelledEmployees:{
    defaultMessage: "Expelled employee",
    id: "safety.expelledEmployees"
  },
  expelledEmployeesSubTitle:{
    defaultMessage: "Employee is expelled. Cannot add to project.",
    id: "safety.expelledEmployeesSubTitle"
  },
  employeesExpelDetails:{
    defaultMessage: "The employee was expelled from the project {projectTitle} on {date} by {expellingUser}, reason: {reason}.",
    id: "safety.employeesExpelDetails"
  },
});