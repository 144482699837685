import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import ImageCarousel from '../../../components/CementoComponents/ImageCarousel';
import InputField from '../../../components/CementoComponents/InputField';
import Text from '../../../components/CementoComponents/Text';
import systemMessages from '../../../../common/app/systemMessages';
import theme from '../../../assets/css/theme';
import editPen from '../../../assets/img/icons/editPen.png';
import MenuScrollbar from '../../../components/CementoComponents/MenuScrollbar';
import propertiesMessages from '../../../../common/propertiesTypes/propertiesMessages';
import { startToast } from '../../../../common/app/actions';
import * as propertyTypes from '../../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { getRelevantPropertyIdsByData} from '../../../../common/propertiesInstances/funcs.js';
import Button from '../../../app/standardComponents/Button';
import { isEmptyValue } from '../../../../common/app/funcs';
import reportsMessages from '../../../../common/reports/reportsMessages';
import safetyMessages from '../../../../common/safety/safetyMessages';
import projectsMessages from '../../../../common/projects/projectsMessages';
import CementoReactFragment from '../../../../common/app/components/CementoReactFragment';
import { safeToJS } from '../../../../common/permissions/funcs';
import PropertiesSection from './PropertiesSection';
import PropertiesPostsSelector from './PropertiesPostsSelector';
import PrevNextSwitch from './PropertiesPrevNextSwitch';
import PropertiesPropInput from './PropertiesPropInput';
import UglyPDFReadyToPrint from './UglyPDFReadyToPrint';
import useRefed from '../../../../common/hooks/useRefed';
import PrevFormHandler from '../../../../common/forms/PrevFormHandler';
import { getPropTypeByTag } from '../../../../common/propertiesTypes/funcs';
import { POST_TYPES_SUB_GROUP_TAG } from '../../../../common/app/constants';
import SignatureModal from "../../../../common/images/signatureModal.js"

const getPostsSelectionSectionTitle = (intl, sectionTitle, postsType) => {
	// I know this function contains duplicate code that is also present in inspection review page but its temporary code that should be removed soon
	let sectionTitleString = '';
	if (sectionTitle && sectionTitle.defaultMessage) sectionTitleString = intl.formatMessage(sectionTitle);
	else if (typeof sectionTitle === 'string') sectionTitleString = sectionTitle;

	const postTypeTitleString = intl.formatMessage(
		reportsMessages.selectors[postsType] ? reportsMessages.selectors[postsType] : reportsMessages.selectors.tasksOrDoc,
	);
	sectionTitleString = sectionTitleString ? `${sectionTitleString} - ${postTypeTitleString}` : postTypeTitleString;

	return sectionTitleString;
};

const defaultProps = {
	mode: 'page',
	onSave: async () => true,
	isCreateMode: false,
	editMode: false,
	useMenusScrollsBar: true,
	showSections: true,
	isEditable: true,
};

/** @param {ObjectPropertiesPageUIProps} props */
let ObjectPropertiesPageUI = props => {
	const {
		mode,
		subjectName,
		instances,
		useMenusScrollsBar,
		onCardLoad,
		objectTypeName,
		objectName,
		onInnerObjectSelect,
		isClosed,
		isCreateMode,
		isEditable,
		onDelete,
		onCancel,
		onSave,
		onClose,
		onPrint,
		onChange,
		tableMethods = {},
		statusBadgeParams,
		setCardHeaderParams,
		types,
		sections: propSections,
		mapping,
		filteredProperties,
		intl,
		shouldShowPostsSections,
		extraSections,
		popInnerCardQueue,
		selectedPosts: propsSelectedPosts = null,
		extraHeaderComponent,
		setHeaderParams,
		onGroupIdChange,
		onDisableChange,
		onEditModeChange,
		editMode,
		startToast,
		addCertButtonTitle,
		objectId,
		onDeleteObject,
		onObjectCreate,
		hideNavigation,
		initialGroupId,
		initialSubGroupId,
		showSections,
		scrollTargetSectionId,
		isForceUpdateEnabled, // TODO: remove and refactor usage
		onRef,
		formReportDate,
		formType,
		projectId,
		hideLocalMock,
		formTemplateId,
		isPermittedToSign,
		track

	} = props;

	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));
	const [shouldDisplaySignatureModal, setShouldDisplaySignatureModal] = useState(false);
	const [isDisabled, setIsDisabled] = useState(!(isCreateMode || editMode));
	const [updatesByPropId, setUpdatesByPropId] = useState({});
	const [allDataByPropId, setAllDataByPropId] = useState({});
	const [instancesByPropId, setInstancesByPropId] = useState({});
	const [presentFileParams, setPresentFileParams] = useState(null);
	const [selectedPosts, setSelectedPosts] = useState(propsSelectedPosts);
	const [selectedTabId, setSelectedTabId] = useState(null);
	const [relevantPropsByType, setRelevantPropsByType] = useState({});
	const [prevFormData, setPrevFormData] = useState({});
	/** @type [PropertiesSection[], React.Dispatch<React.SetStateAction<PropertiesSection[]>>] */
	const [sections, setSections] = useState([]);

	const didInitData = useRef(false);

	const inputsRefs = useRef({});

	const isCardOrModal = useMemo(() => ['card', 'modal'].includes(mode), [mode]);

	const useCollapsibleSections = useMemo(
		() => (!_.isNil(props.useCollapsibleSections) ? props.useCollapsibleSections : mode !== 'card'),
		[mode, props.useCollapsibleSections],
	);

	const shouldHideCertifications = useMemo(
		() => (!_.isNil(props.shouldHideCertifications) ? props.shouldHideCertifications : !isDisabled),
		[isDisabled, props.shouldHideCertifications],
	);

	const selectedGroupId = useMemo(() =>
		_.first(_.keys(allDataByPropId['groups']) || ''),
		[allDataByPropId['groups']]
	);

	const hasChanges = useMemo(() =>
		_.keys(_.omit(updatesByPropId, ['groups'])).length ||
			!_.isEqual(selectedPosts, propsSelectedPosts),
		[updatesByPropId, selectedPosts, propsSelectedPosts],
	);

	// useEffect(() => {
	// 	// For when in card or modal mode when onObjectCreate is available, to open the side card
	// 	if (onObjectCreate) onObjectCreate(selectedGroupId);
	// }, [selectedGroupId]);

	const lastSideCardPushParams = useRef(null);
	const pushSideCardIntoStack = useCallback(
		/** @type {OnInnerObjectSelectFunc} */
		(type, cardProps, ComponentToRender) => {
			if (onInnerObjectSelect)
				onInnerObjectSelect(type || 'nothingToDisplay', cardProps || {}, ComponentToRender || null);

			lastSideCardPushParams.current = [type, cardProps, ComponentToRender];
		},
		[onInnerObjectSelect],
	);

	useEffect(() => {
		if (!isCreateMode) setIsDisabled(true);
		if (lastSideCardPushParams.current) lastSideCardPushParams.current = null;
	}, [objectId, objectName, isCreateMode]);

	useEffect(() => {
		setIsDisabled(!isCreateMode && !editMode);
	}, [editMode, isCreateMode]);

	useEffect(() => {
		if (isDisabled) {
			setUpdatesByPropId({});
			setSelectedPosts(null);
		}
		if (onDisableChange) onDisableChange(isDisabled);
		if (onEditModeChange) onEditModeChange(!isDisabled);
		if (hideNavigation) hideNavigation(!isDisabled);
		if (lastSideCardPushParams.current) pushSideCardIntoStack(...lastSideCardPushParams.current);
	}, [isDisabled]);

	useEffect(() => {
		if (!isDisabled && !isForceUpdateEnabled && didInitData.current) {
			return;
		}
		if (_.keys(instances).length) {
			let newAllDataByPropId = {};
			let newInstancesByPropId = {};
			_.values(instances).forEach(instance => {
				newAllDataByPropId[instance.propId] = instance?.data;
				newInstancesByPropId[instance.propId] = instance;
			});

			setAllDataByPropId(newAllDataByPropId);
			setInstancesByPropId(newInstancesByPropId);
			didInitData.current = true;
			setSelectedPosts(propsSelectedPosts);
		}
	}, [instances, isDisabled]);

	useEffect(() => {
		// Init group id;
		if (!isDisabled && !allDataByPropId['groups']) {
			let groupIdToSet = null;
			if (initialGroupId) groupIdToSet = initialGroupId;
			else if (types?.['groups']?.values?.length === 1) groupIdToSet = _.first(types['groups'].values).id;

			if (groupIdToSet) handleDataUpdate('groups', { [groupIdToSet]: groupIdToSet });
			
			if(initialSubGroupId){
				const subGroupProps = getPropTypeByTag({ projectId, subjectName, groupId: groupIdToSet, tag: POST_TYPES_SUB_GROUP_TAG });
				const subGroupPropId = _.chain(subGroupProps).values().head().get(['id']).value();
				if (subGroupPropId) handleDataUpdate(subGroupPropId, { [initialSubGroupId]: initialSubGroupId });
			}
		}
	}, [types, allDataByPropId, isDisabled, initialGroupId, initialSubGroupId, projectId, subjectName]);

	const handleClose = useCallback(() => {
		if (onClose && ['modal', 'card'].includes(mode)) onClose();
	}, [onClose, mode]);

	const handleDelete = useCallback(async () => {
		if (onDelete) {
			const didDelete = await onDelete();
			if (didDelete) handleClose();
			if (onDeleteObject) onDeleteObject(didDelete, objectId);
		}
	}, [onDelete, handleClose, onDeleteObject, objectId]);

	const handleCancel = useCallback(async () => {
		const isCancelConfirmed = await new Promise(resolve => {
			if (!hasChanges || isDisabled)
				resolve(true);
			else
				startToast({
					overlay: true,
					mandatory: true,
					title: systemMessages.manage.leaveWithoutSave,
					message: systemMessages.manage.changesHaveNotBeenSaved,
					actions: [
						{ message: systemMessages.yes, color: 'success', onClick: () => resolve(true) },
						{ message: systemMessages.no, onClick: () => resolve(false) },
					],
				});
		});

		if (isCancelConfirmed) {
			setIsDisabled(true);
			if (onCancel) onCancel();
			if (isCreateMode) handleClose();
		}

		return isCancelConfirmed;
	}, [onCancel, handleClose, hasChanges, startToast, isCreateMode, isDisabled]);

	const handleInputRef = useCallback((id, element, component) => {
		if (!element)
			_.unset(inputsRefs.current, [id]);
		else if (element?.scrollIntoView)
			_.set(inputsRefs.current, [id], { element, component });
	}, []);

	const handleCheckInputsErrors = useCallback(() => {
		let errors = {};

		_.entries(inputsRefs.current).forEach(([propId, inputRef]) => {
			let errorsArr = inputRef.component.checkErrors();
			if (errorsArr) errors[propId] = errorsArr.join(', ');
		});

		return { hasErrors: Object.keys(errors).length > 0, errors };
	}, []);

	const handleDisplayInputsErrors = useCallback(
		errorsArr => {
			startToast({
				title: systemMessages.invalidDetailsDescription,
				type: 'error',
				values: { errors: errorsArr.join('\n') },
			});
		},
		[startToast],
	);

	const handleSave = useCallback(
		async (inUpdates, isSilent = false) => {
			
			const updates = {
				...inUpdates,
				...updatesByPropId,
			};
			let saveSuccess = true;

			const { hasErrors, errors } = handleCheckInputsErrors();
			if (hasErrors) {
				handleDisplayInputsErrors(Object.values(errors));
				saveSuccess = false;
			} else if (onSave) {
				saveSuccess = (await onSave(updates, selectedPosts, isSilent)) !== false;
			}

			if (saveSuccess) {
				if (isCreateMode && onObjectCreate)
					onObjectCreate(selectedGroupId, objectId);
				setIsDisabled(true);
			}


			return saveSuccess;
		},
		[
			onObjectCreate,
			onSave,
			handleCheckInputsErrors,
			handleDisplayInputsErrors,
			isCreateMode,
			updatesByPropId,
			selectedPosts,
			selectedGroupId,
			objectId,
		],
	);

	useEffect(() => {
		onRef?.({ handleSave });
	}, [onRef, handleSave]);

	const handlePrint = useCallback(async () => {
		if (onPrint) {
			const { src, description = '', pdfMode = false } = (await onPrint()) || {};
			if (src) setPresentFileParams({ files: [{ src, description }], pdfMode });
		}
	}, [onPrint, setPresentFileParams]);

	const headerActionsRef = useRefed({ handleSave, handleCancel, handlePrint, handleDelete, setIsDisabled }, [
		handleSave,
		handleCancel,
		handlePrint,
		handleDelete,
		setIsDisabled,
	]); // for header params to use the correct version of these functions

	const handleRecalcHeader = useCallback(() => {
		if (mode !== 'page') return;

		const headerComponent = (
			<>
				<div style={{ display: 'flex', alignItems: 'center', fontSize: theme.fontSizeH6, fontWeight: theme.strongBold }}>
					<Text style={{ marginLeft: 3 * theme.verticalMargin, marginRight: 2 * theme.verticalMargin }}>
						{objectName}
					</Text>
				</div>
				{isDisabled ? (
					<div
						style={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row-reverse',
							alignItems: 'center',
							height: '100%',
							margin: `0px ${theme.verticalMargin}px`,
						}}
					>
						<div
							style={{ display: 'flex', alignItems: 'center', [rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin }}
							onClick={() => headerActionsRef.current.setIsDisabled(false)}
						>
							<div style={{ cursor: 'pointer', margin: theme.verticalMargin }}>
								<img src={editPen} />
							</div>
						</div>
						{extraHeaderComponent}
						<UglyPDFReadyToPrint
							mode={mode}
							sections={sections}
							objectName={objectName}
							allDataByPropId={allDataByPropId}
							subjectName={subjectName}
							objectId={objectId}
						/>
					</div>
				) : (
					<div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', height: '100%', margin: `0px ${theme.verticalMargin}px` }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
								color: theme.brandPrimary,
							}}
							onClick={() => headerActionsRef.current.handleSave()}
						>
							<Text style={{ cursor: 'pointer', margin: theme.verticalMargin, fontSize: theme.fontSizeH6 }}>{propertiesMessages.save}</Text>
						</div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								[rtl ? 'paddingLeft' : 'paddingRight']: theme.verticalMargin,
							}}
							onClick={() => headerActionsRef.current.handleCancel()}
						>
							<Text style={{ cursor: 'pointer', margin: theme.verticalMargin, fontSize: theme.fontSizeH6 }}>
								{propertiesMessages.cancel}
							</Text>
						</div>
					</div>
				)}
			</>
		);

		if (setHeaderParams)
			setHeaderParams({ headerComponent, sideBarParams: { open: false } });
	}, [
		isDisabled,
		setHeaderParams,
		mode,
		objectName,
		subjectName,
		objectId,
		allDataByPropId,
		sections,
		extraHeaderComponent,
	]);

	useEffect(() => {
		handleRecalcHeader();
	}, [extraHeaderComponent, isDisabled, objectName, objectId, sections, mode, subjectName, allDataByPropId]);

	// CARD STUFF
	const handleShowNothingToDisplaySideCard = useCallback(() => {
		if (mode === 'modal') pushSideCardIntoStack();
	}, [pushSideCardIntoStack, mode]);

	useEffect(handleShowNothingToDisplaySideCard, [objectId, objectName]); // Show default 2nd side card

	const handleCardTabSelect = useCallback(
		/** @param {string} tabId */
		tabId => {
			setSelectedTabId(tabId);
			setTimeout(() => setSelectedTabId(null), 500);
		},
		[setSelectedTabId],
	);

	useEffect(() => {
		if (scrollTargetSectionId)
			handleCardTabSelect(scrollTargetSectionId);
	}, [objectId, scrollTargetSectionId]);

	const handleBackOrCloseClick = () => {
		lastSideCardPushParams.current = null;	
	}

	const getCardHeaderParams = useCallback(() => {
		let headerParams = {
			title: mode === 'modal' ? objectTypeName : objectName,
			statusBadgeParams,
			editable: isEditable,
			onCancel: () => headerActionsRef.current.handleCancel(),
			onSave: () => headerActionsRef.current.handleSave(),
			onBackOrClose: handleBackOrCloseClick,
			middleComponent: objectName && mode === 'modal' && (
				<PrevNextSwitch
					isShowArrows={isDisabled}
					title={objectName}
					onPrevClick={tableMethods.goPreviousRow}
					onNextClick={tableMethods.goNextRow}
				/>
			),
		};
		if (isPermittedToSign) headerParams.onSign = () => {
			track('Signing form in web', {
				formId: objectId,
				formType,
				formTemplateId
			})
			setShouldDisplaySignatureModal(true);
		}
		if (onDelete) headerParams.onDelete = () => headerActionsRef.current.handleDelete();

		if (onPrint) headerParams.onPrint = () => headerActionsRef.current.handlePrint();

		return headerParams;
	}, [tableMethods, isDisabled, handleDelete, handlePrint, objectName, mode, isEditable, statusBadgeParams, isPermittedToSign, objectId, formTemplateId, formType]);

	const handleUpdateCardParams = useCallback(() => {
		if (!onCardLoad) return;

		const headerParams = getCardHeaderParams();

		const tabs = sections
			.filter(
				currSection => !currSection.isCertificationSection || _.get(allDataByPropId, [currSection.properties[0]?.id]),
			)
			.map(currSection => ({ href: currSection.id, title: currSection.title }))
			.slice(0, 4);

		const tabParams = {
			onTabSelect: handleCardTabSelect,
			tabs,
		};

		onCardLoad(headerParams, tabParams, (editMode || isCreateMode), isCreateMode, objectTypeName);
	}, [sections, onCardLoad, editMode, isCreateMode, objectTypeName, getCardHeaderParams]);

	useEffect(() => {
		handleUpdateCardParams();
	}, [objectId, editMode, sections, isDisabled, objectTypeName, allDataByPropId, subjectName, statusBadgeParams]);

	useEffect(() => {
		if (setCardHeaderParams) setCardHeaderParams(getCardHeaderParams());
	}, [objectId, objectName]);

	// CARD STUFF END

	// INPUTS STUFF

	const handleDataUpdate = useCallback(
		/**
		 *
		 * @param {string} propId
		 * @param {any} data
		 */
		(propId, data, status) => {
			setUpdatesByPropId(currState => ({
				...currState,
				[propId]: data,
			}));
			setAllDataByPropId(currState => ({
				...currState,
				[propId]: data,
			}));

			if (onGroupIdChange && propId === 'groups' && _.keys(data)[0] !== selectedGroupId)
				onGroupIdChange(_.keys(data)[0]);

			onChange?.(propId, data, status);
		},
		[onGroupIdChange, selectedGroupId],
	);

	const handlePresentImage = useCallback(
		/**
		 *
		 * @param {string} description
		 * @param {string} src
		 * @param {boolean} pdfMode
		 */
		(description, src, pdfMode) => {
			setPresentFileParams({
				pdfMode,
				files: [{ src, description }],
			});
		},
		[setPresentFileParams],
	);

	const handlePresentFileClose = useCallback(() => {
		setPresentFileParams(null);
	}, [setPresentFileParams]);

	const handlePopInnerCardQueue = useCallback(() => {
		lastSideCardPushParams.current = null;
		popInnerCardQueue?.();
	}, [popInnerCardQueue]);

	const handleAddOrEditCert = useCallback(
		(props, addMode = false) => {
			const numOfProps = _.keys(props).length;
			const initialCertificationProp = numOfProps === 1 ? _.values(props)[0] : null;
			const certificationTypes = numOfProps > 1 ? props : null;

			const cardProps = {
				addMode,
				initialCertificationProp,
				certificationTypes,
				subjectName,
				editMode: true,
				instancesByPropertyId: instancesByPropId,
				onDone: async (certPropId, certData) => {
					const saveSuccess = await handleSave({ [certPropId]: certData });
					if (saveSuccess) handlePopInnerCardQueue();
					return saveSuccess;
				},
				onBackOrClose: handleBackOrCloseClick,
			};
			
			pushSideCardIntoStack('addCertification', cardProps);
		},
		[handlePopInnerCardQueue, pushSideCardIntoStack, subjectName, instancesByPropId, handleSave],
	);

	const handleToggleCancelCertification = useCallback(
		async (prop , isCertificationCanceled) => {
			let instanceData = _.get(instancesByPropId, [prop.id, 'data']);
			const clone = [ ...instanceData ];
			const lastCertIndex = instanceData.length - 1;

			const isSaveConfirmed = await new Promise(resolve => {
					startToast({
						overlay: true,
						mandatory: true,
						title: systemMessages.confirmSaveChangesAlert.title,
						message: systemMessages.confirmSaveChangesAlert.content,
						actions: [
							{ message: systemMessages.yes, color: 'success', onClick: () => resolve(true) },
							{ message: systemMessages.no, onClick: () => resolve(false) },
						],
					});
			});

			if (!isSaveConfirmed) return;

			if (isCertificationCanceled) {
				delete clone[lastCertIndex].isCanceled;
			} else {
				clone[lastCertIndex].isCanceled = true;
			}

			await handleSave({ [prop.id]: clone });
	}, [instancesByPropId])

	const handleRenderPostsComponent = useCallback(
		(postsType, isSafetyPostsOnly) => (
			<PropertiesPostsSelector
				isDisabled={isDisabled}
				postsType={postsType}
				isSafetyPostsOnly={isSafetyPostsOnly}
				onChange={newSelectedPosts => setSelectedPosts(newSelectedPosts)}
				openSideCard={pushSideCardIntoStack}
				selectedPosts={selectedPosts}
			/>
		),
		[isDisabled, setSelectedPosts, pushSideCardIntoStack, selectedPosts],
	);

	const handleRenderPostsComponentRef = useRefed(handleRenderPostsComponent, [handleRenderPostsComponent]);
	// INPUTS STUFF END

	const getSections = useCallback(() => {
		let index = 0;
		/** @type {{ [sectionId: string]: PropertiesSection }} */
		let sections = {};
		/** @type {{ [propId: string]: string }} */
		let objectRelevantPropsIdsMap = {};
		let relevantPropsByType = {};

		// TODO: use getRelevantProperties func(new prop mappings)
		const defaultSubjectName = subjectName || 'defaultSubject';
		const relevantPropTypesIdsArr = getRelevantPropertyIdsByData(
			{ [defaultSubjectName]: mapping },
			defaultSubjectName,
			selectedGroupId,
			allDataByPropId,
			types,
		);

		// Get all relevant columns

		relevantPropTypesIdsArr?.forEach?.(propId => {
			objectRelevantPropsIdsMap[propId] = propId;
		});

		//  Build props default ordinalNo from mapping
		let propertiesFallbackOrdinalNo = {};
		_.values(safeToJS(mapping)).forEach(groupingPropertyGroups => {
			_.keys(groupingPropertyGroups || {}).forEach(groupId => {
				groupingPropertyGroups.getNested2([groupId, 'properties'], []).forEach((mappedPropId, index) => {
					propertiesFallbackOrdinalNo[mappedPropId] = index;
				});
			});
		});

		_.forIn(relevantPropTypesIdsArr, (propId, key, collection) => {
			let currProp = types?.[propId];
			if (!currProp) return;
			
			const shouldHideGroupInput = (propId === 'groups' && selectedGroupId && isCardOrModal && isCreateMode);
			if (shouldHideGroupInput || hideLocalMock && currProp.isLocalMock)
				return;

			if (currProp && objectRelevantPropsIdsMap[propId])
				_.set(relevantPropsByType, [currProp.type, currProp.id], currProp);

			if (
				!currProp ||
				!objectRelevantPropsIdsMap[propId] ||
				(filteredProperties && !filteredProperties[currProp.id]) ||
				!currProp.sectionId ||
				currProp.isExtra ||
				(currProp.id === 'groups' && _.get(currProp, ['values', 'length']) <= 1) ||
				(shouldHideCertifications && currProp.type === propertyTypes.CERTIFICATION)
			)
				return;

			index = currProp.ordinalNo || _.values(collection).length;
			if (!sections[currProp.sectionId]) {
				let section = propSections?.[currProp.sectionId] || {};
				let sectionTitle = section.title?.id ? intl.formatMessage(section.title) : section.getNested(['getTitle'])
				sections[section.id] = {
					id: section.id,
					title: sectionTitle,
					properties: [],
					ordinalNo: index,
					isOpen: section.isOpen,
					isCertificationSection: Boolean(currProp.type === propertyTypes.CERTIFICATION),
					isSupportedCertBehaviour: Boolean(
						!_.get(currProp, ['settings', 'signatureBehaviour']) ||
							_.get(currProp, ['settings', 'signatureBehaviour', propertyTypes.UPLOAD_FILE, 'enabled'], false),
					),
					isRoleAppointmentSection: Boolean(
						currProp.type === propertyTypes.CERTIFICATION &&
							_.get(currProp, ['settings', 'certificationType']) === propertyTypes.CERTIFICATIONS_TYPES.roleAppointment,
					),
				};
			}

			const currentSectionOrdinalNum = _.get(sections, [currProp.sectionId, 'ordinalNo'], 0);
			if (currentSectionOrdinalNum < index) _.set(sections, [currProp.sectionId, 'ordinalNo'], index);

			const propValues = currProp.values
				? currProp.values.map(v => {
						return { id: v.id, title: v.getNested(['getTitle'], '') };
					})
				: null;
			sections[currProp.sectionId].properties.push({
				id: currProp.id,
				type: currProp.type,
				extraTypes: (currProp.extraTypes || []).map(extraPropId => {
					const prop = types?.[extraPropId] || {};
					const title = prop.getNested(['getTitle']);
					const propOBJ = prop.toJS ? prop.toJS() : prop;
					return { ...(propOBJ || {}), title, value: allDataByPropId[extraPropId] };
				}),
				prop: currProp,
				settings: currProp.settings,
				businessType: currProp.businessType,
				universalId: currProp.universalId,
				isPrimary: currProp.isPrimary,
				title: currProp.getNested(['getTitle']),
				values: propValues,
				ordinalNo:
					currProp.ordinalNo || currProp.ordinalNo === 0
						? currProp.ordinalNo
						: propertiesFallbackOrdinalNo[currProp.id],
			});

			
			if (currProp.type === propertyTypes.CERTIFICATION) {
        		const instanceData = instancesByPropId[currProp.id]?.data;
        		if (instanceData && _.last(instanceData)?.isCanceled) {
          			sections[currProp.sectionId].isCertificationCanceled = true;
        		}
     		}
		});

		let largestOrdinalNo = 0;
		// Reorder properties by ordinalNo
		let sortedSections = Object.values(sections);
		sortedSections.forEach(section => {
			section.properties = section.properties.sort((a, b) => a.ordinalNo - b.ordinalNo);
			section.ordinalNo = (section.properties[0] || {}).ordinalNo || section.ordinalNo;
			largestOrdinalNo = section.ordinalNo > largestOrdinalNo ? section.ordinalNo : largestOrdinalNo;
		});

		if (shouldShowPostsSections) {
			sortedSections.push({
				id: 'safetyTaskPostsSelect',
				title: getPostsSelectionSectionTitle(intl, safetyMessages.safetyTitle, 'tasks'),
				renderFunc: () => handleRenderPostsComponentRef.current('tasks', true),
				ordinalNo: ++largestOrdinalNo,
			});
			sortedSections.push({
				id: 'safetyRecordsPostsSelect',
				title: getPostsSelectionSectionTitle(intl, safetyMessages.safetyTitle, 'records'),
				renderFunc: () => handleRenderPostsComponentRef.current('records', true),
				ordinalNo: ++largestOrdinalNo,
			});
			sortedSections.push({
				id: 'taskPostsSelect',
				title: getPostsSelectionSectionTitle(intl, projectsMessages.dashboard, 'tasks'),
				renderFunc: () => handleRenderPostsComponentRef.current('tasks'),
				ordinalNo: ++largestOrdinalNo,
			});
			sortedSections.push({
				id: 'recordsPostsSelect',
				title: getPostsSelectionSectionTitle(intl, projectsMessages.dashboard, 'records'),
				renderFunc: () => handleRenderPostsComponentRef.current('records'),
				ordinalNo: ++largestOrdinalNo,
			});
		}

		if (extraSections) {
			extraSections.forEach(section => {
				if (section.renderFunc) sortedSections.push({ ...section, ordinalNo: ++largestOrdinalNo });
			});
		}

		sortedSections.sort((a, b) => a.ordinalNo - b.ordinalNo);

		return {
			objectRelevantPropsIdsMap,
			sections: sortedSections,
			relevantPropsByType,
		};
	}, [
		instancesByPropId,
		allDataByPropId,
		shouldShowPostsSections,
		shouldHideCertifications,
		extraSections,
		selectedGroupId,
		subjectName,
		mapping,
		types,
		filteredProperties,
		propSections,
	]);

	useEffect(() => {
		const { sections: newSections, relevantPropsByType: newRelevantPropsByType } = getSections();
		if (!_.isEqual(newSections, sections)) setSections(newSections);
		if (!_.isEqual(newRelevantPropsByType, relevantPropsByType)) setRelevantPropsByType(newRelevantPropsByType);
	}, [types, propSections, getSections, mapping, selectedGroupId, extraSections, isDisabled, allDataByPropId]);

	// RENDER

	const Wrapper = useMemo(() => (useMenusScrollsBar ? MenuScrollbar : CementoReactFragment), [useMenusScrollsBar]);

	if (isCardOrModal && isCreateMode) {
		if (!selectedGroupId) {
			// select group if there are more then one
			const groupsProp = types?.['groups'];
			if (!groupsProp) return null;
			return (
				<div style={{ padding: theme.padding + theme.margin }}>
					<Text style={{ ...styles.headerSectionStyle, [rtl ? 'marginLeft' : 'marginRight']: theme.verticalMargin - 2 }}>
						{propertiesMessages.selectGroup}
					</Text>
					<InputField
						id={'newObject_groupSelect'}
						key={'newObject_groupSelect'}
						inputKey={'newObject_groupSelect'}
						propId={'groups'}
						mode={'card'}
						disabled={isDisabled}
						prop={groupsProp}
						type={groupsProp.type}
						settings={groupsProp.settings}
						businessType={groupsProp.businessType}
						universalId={groupsProp.universalId}
						extraTypes={groupsProp.extraTypes}
						values={groupsProp.values}
						onChange={newData => handleDataUpdate('groups', newData)}
					/>
				</div>
			);
		}
	}

	return (
		<Wrapper scrollbarsStyle={{ overflowX: 'hidden' }} isSmooth={true}>
			{Boolean(shouldDisplaySignatureModal) && <SignatureModal
			formReportDate={formReportDate}
			formType={formType}
			formTemplateId={formTemplateId}
			signObjectId={objectId}
			projectId={projectId}
			handleClose={() => {setShouldDisplaySignatureModal(false);}} 
			isOpen={shouldDisplaySignatureModal}>
			</SignatureModal>}
			{subjectName === 'formsInfo' && <PrevFormHandler onPrevForm={setPrevFormData} formReportDate={formReportDate} formType={formType} />}
			{sections.map(section => {
				const isCertSectionWithoutData =
					section.isCertificationSection && isEmptyValue(allDataByPropId[section.properties[0]?.id]);
				if (isCertSectionWithoutData) return null;

				return ( 
					<PropertiesSection
						key={section.id}
						section={section}
						shouldScrollToSection={selectedTabId === section.id}
						onEditCert={prop => handleAddOrEditCert([prop])}
						onAddCert={prop => handleAddOrEditCert([prop], true)}
						useCollapsibleSections={useCollapsibleSections}
						showSection={showSections}
						onToggleCancelCertification={handleToggleCancelCertification}
						isCertificationCanceled={section.isCertificationCanceled}
					>
						{section.renderFunc
							? section.renderFunc()
							: section.properties?.map?.(prop => (
									<PropertiesPropInput
										prop={prop}
										key={prop.id + section.id}
										onChange={handleDataUpdate}
										onImageSelect={handlePresentImage}
										isDisabled={isDisabled}
										mode={mode}
										subjectName={subjectName}
										extraPropertiesTypes={types}
										openSideCard={pushSideCardIntoStack}
										onRef={(e, c) => handleInputRef(_.get(c, ['props', 'propId']) || prop.id, e, c)}
										value={allDataByPropId[prop.id]}
										optionalDefaultValue={prevFormData?.[prop.id]}
										isCreateMode={isCreateMode}
										isCertificationCanceled={section.isCertificationCanceled}
									/>
								))}
					</PropertiesSection>
				);
			})}
			{Boolean(
				addCertButtonTitle && !isDisabled && isCardOrModal && _.keys(relevantPropsByType.Certification).length,
			) && (
				<Button
					title={addCertButtonTitle}
					style={{ margin: `${theme.padding}px auto` }}
					onClick={() => handleAddOrEditCert(relevantPropsByType.Certification, true)}
				/>
			)}
			{Boolean(presentFileParams) && (
				<ImageCarousel
					pdfMode={presentFileParams.pdfMode}
					onClose={handlePresentFileClose}
					initialSlide={0}
					items={presentFileParams.files}
				/>
			)}
		</Wrapper>
	);
};

const enhance = compose(connect(null, { startToast }), injectIntl);
ObjectPropertiesPageUI = enhance(ObjectPropertiesPageUI);
ObjectPropertiesPageUI.defaultProps = defaultProps;

export default ObjectPropertiesPageUI;

const styles = {
	headerSectionStyle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: `${theme.padding + theme.margin}px 0px`,
		color: theme.brandPrimary,
		fontFamily: 'Assistant - Semi Bold',
		fontSize: theme.fontSizeH6,
		fontWeight: theme.strongBold,
		marginBottom: 0,
	},
};
