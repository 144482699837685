/** Cemento event */
export const CHANGE = 'change';
/** Cemento event */
export const SUBSCRIBE = 'subscribe';
/** Cemento event */
export const UNSUBSCRIBE = 'unsubscribe';
/** Cemento event */
export const ERROR = 'error';
/** 
 * - Cemento event 
 * - Fired whenever the client disconnected from the server for whatever reason and then reconnects on a same socket. (The socket id will be different)
 */
export const RECONNECT = 'reconnect';
/** 
 * - Cemento event
 * - Fired when the on connection established
 */
export const CONNECTION_ESTABLISHED = `connection established`;
/** 
 * - Cemento event
 * - Fired when the disconnection finished side effects
 */
export const DISCONNECTED = 'disconnected';
/** 
 * IO reserved event (cannot be fired manually) 
 * Fired whenever a link is established between the server and the client.
 * Doesn't matter if its a reconnection or a first connection.
 */
export const CONNECT = 'connect';
/** IO reserved event (cannot be fired manually) */
export const CONNECT_ERROR = 'connect_error';
/** 
 * - IO reserved event (cannot be fired manually) 
 * - Fired whenever the client disconnects from the server for whatever reason.
 */
export const DISCONNECT = 'disconnect';
/** IO reserved event (cannot be fired manually) */
export const DISCONNECTING = 'disconnecting';
/** IO reserved event (cannot be fired manually) */
export const NEW_LISTENER = 'newListener';
/** IO reserved event (cannot be fired manually) */
export const REMOVE_LISTENER = 'removeListener';
