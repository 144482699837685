import React from 'react';
import { useSelector } from 'react-redux';
import Text from '../../../components/CementoComponents/Text';
import theme from '../../../assets/css/theme';
import toggleDown from '../../../assets/img/icons/toggleDown.png';
import useHover from '../../../hooks/useHover';

const MIDDLE_FLEX_BASIS = '80%';
const PrevNextSwitch = props => {
	const { title, onNextClick, onPrevClick, isShowArrows = true } = props;

	return (
		<div
			style={{
				display: 'flex',
				minWidth: 'min-content',
				flexBasis: MIDDLE_FLEX_BASIS,
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: theme.fontSizeH5,
				height: '100%',
			}}
		>
			<Arrow onClick={onPrevClick || undefined} isShow={isShowArrows} direction={'right'} />
			<div
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'center',
					height: '100%',
					alignItems: 'center',
				}}
			>
				<Text style={{ color: theme.brandPrimary }}>{title}</Text>
			</div>
			<Arrow onClick={onNextClick || undefined} isShow={isShowArrows} />
		</div>
	);
};

export default PrevNextSwitch;


const Arrow = props => {
	const { onClick, isShow, direction = 'left' } = props;
	const { rtl } = useSelector(state => ({ rtl: state.app.rtl }));
	const [ref, isHover] = useHover();

	return (
		<div
			ref={ref}
			style={Object.assign({}, styles.arrowContainerStyle, (!onClick || !isShow) && { visibility: 'hidden' })}
			onClick={onClick}
		>
			<img
				src={toggleDown}
				style={Object.assign(
					{ transform: `rotate(${rtl ? direction === 'left' ? '' : '-' : direction === 'left' ? '-' : ''}90deg)` },
					styles.arrowImgStyle,
					isHover && { filter: 'invert(30%)' },
				)}
			/>
		</div>
	);
}


const styles = {
	arrowContainerStyle: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 45,
		height: '100%',
	},
	arrowImgStyle: {
		height: 7,
		transitionProperty: 'filter',
		transitionDuration: '0.2s',
	},
}