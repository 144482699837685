import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import { POSTS_VIEW_TYPES } from '../../app/constants';
import theme from '../../assets/css/theme';

import SidebarBuilding from '../../layouts/SidebarBuilding';
import PostsPage from '../Posts/PostsPage';
import { filterPostsByLocation, getFilteredRowsMap } from './funcs';
import withRouterHOC from '../../components/Router/util/withRouterHOC';

const sideBarStyles = {
	marginTop: theme.headerHeightSecondary,
	height: 'calc(100vh - ' + (theme.headerHeight + theme.headerHeightSecondary) + 'px)',
};

const PostsAnalytics = props => {
	const {
		selectedProjectId,
		filterPathDelimeter,
		categoryFilters,
		textFilter,
		viewType,
		contentType,
		filteredPosts,
		rtl,
		intl,
		match,
		location,
		uiParams,
		setHeaderParams,
		handleFilterChange,
		handleFilterClear,
		originalDataRows,
		getExcelExportComponent,
		InjectedHeaderComponents,
		TableComponent,
	} = props;

	const [selectedLocation, setSelectedLocation] = useState({
		buildingId: 'ALL',
		type: 'building',
	});

	const buildingSidebarStyle = useMemo(() => (uiParams.get('onlySideNav') ? sideBarStyles : {}), [uiParams]);

	const itemsMode = useMemo(() => {
		try {
			if (location?.search) {
				let urlQueryParams = new URLSearchParams(location.search);
				let urlFilter;
				if (urlQueryParams) urlFilter = urlQueryParams.get('itemType');

				return urlFilter || 'all';
			}
		} catch (error) {
			console.log('failed to get items mode:', error);
			return 'all';
		}
	}, [location]);

	const originalDataRowsMap = useMemo(() => {
		try {
			return _.keyBy(originalDataRows, 'id');
		} catch (error) {
			return {};
		}
	}, [originalDataRows]);

	const filteredRowsMap = useMemo(() => {
		try {
			return getFilteredRowsMap({
				searchQuery: location?.search,
				originalDataRows,
				delimeter: filterPathDelimeter,
			});
		} catch (error) {
			console.log('failed to filter rows:', error);
			return {};
		}
	}, [location?.search, originalDataRows, filteredPosts, filterPathDelimeter]);
	const visiblePosts = useMemo(() => {
		try {
			if (filteredRowsMap)
				return filteredPosts.filter(({ id }) => filteredRowsMap[id]);
		} catch (error) {
			console.log('failed to filter posts:', error);
			return filteredPosts;
		}
	}, [filteredRowsMap, filteredPosts]);

	const { visiblePostsByLocation, visibleRowsByLocation } = useMemo(() => {
		try {
			let showAll = props.showAll || (props['buildings']?.size == 1 && selectedLocation.type === 'building');
			const visiblePostsByLocation = filterPostsByLocation({
				posts: visiblePosts,
				buildingId: selectedLocation.buildingId,
				floorId: selectedLocation.floorId,
				unitId: selectedLocation.unitId,
				showAll,
				locationsAggregationView: true,
			});
			const visibleIds = {};
			visiblePostsByLocation.map(({ id }) => visibleIds[id] = true);
			let visibleParentRows = {};
			const visibleRowsByLocation = originalDataRows.filter(row => {
				const isVisible = visibleIds[row.id];
				if (isVisible) {
					visibleParentRows[row.parentId] = originalDataRowsMap[row.parentId];
				}
				return isVisible;
			});

			return {
				visiblePostsByLocation: visiblePostsByLocation,
				visibleRowsByLocation:[...Object.values(visibleParentRows), ...visibleRowsByLocation]
			};
		} catch (error) {
			console.log('failed to filter posts and rows:', error);
			return visiblePosts;
		}
	}, [visiblePosts, selectedLocation, filteredRowsMap]);
	return (
		<div
			style={{
				flexDirection: 'row',
				display: 'flex',
				height: `calc(100vh - ${theme.headerHeight + theme.headerHeightSecondary}px)`,
			}}
		>
			<SidebarBuilding
				navigationParams={selectedLocation}
				selectedLocation={selectedLocation}
				rtl={rtl}
				intl={intl}
				style={buildingSidebarStyle}
				location={location}
				selectedProjectId={selectedProjectId}
				contentType={contentType}
				showBadges={true}
				filteredPosts={visiblePosts}
				onChange={selectedLocation => setSelectedLocation(selectedLocation)}
			/>
			<div
				style={{
					height: '100%',
					width: 'calc(100vw - ' + theme.sidebarWidth + 'px)',
					flex: 1,
				}}
			>
				{viewType === POSTS_VIEW_TYPES.TILES ? (
					<PostsPage
						match={{
							params: {
								...match.params,
								...selectedLocation,
							},
						}}
						locationsAggregationView={true}
						itemsMode={itemsMode}
						data={visiblePostsByLocation}
						allPosts={filteredPosts}
						contentType={contentType}
						setHeaderParams={setHeaderParams}
						headerChildren={InjectedHeaderComponents}
						filterVal={textFilter}
						setFilterVal={handleFilterChange}
						clearFilterVal={handleFilterClear}
						allProjectViewMode={false}
						categoryFilters={categoryFilters}
						filterPathDelimeter={filterPathDelimeter}
						getExcelExportComponent={getExcelExportComponent}
					/>
				) : (
					React.cloneElement(TableComponent, { originalRows: visibleRowsByLocation })
				)}
			</div>
		</div>
	);
};

const enhance = compose(
	injectIntl,
	withRouterHOC,
	connect(state => ({
		rtl: state.app.rtl,
		uiParams: state.ui.uiParams,
	})),
);

export default enhance(PostsAnalytics);
