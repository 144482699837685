import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';

import Building from './building';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  map: OrderedMap(),
  didLoad: OrderedMap(),
}, 'buildings');

const initialState = new InitialState;

export default function buildingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {

    case actions.BUILDINGS_LOADING + '_SUCCESS': {
      const { projectId } = action.payload;
      if (!state.getIn(['didLoad', projectId])) {
        state = state.setIn(['didLoad', projectId], true);
      }

      return state;
    }
    
    case actions.GET_BUILDINGS: {
      if (!action.payload?.projectId)
        return state;
      const { projectId, buildings } = action.payload;

      if (action.payload?.buildings) {
        let buildingsMap = new OrderedMap();
  
        Object.values(buildings).forEach(building => {
          building = new Building({ ...(building.toJS ? building.toJS() : building) });
          buildingsMap = buildingsMap.set(building.id, building);
        });
        state = state.setIn(['map', projectId], buildingsMap);
      }

      return state;
    }

    case CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS": {
      return initialState;
    }
  }

  return state;
}