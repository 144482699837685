import { batchDispatch } from '../app/funcs';
import { updateUsingFirebaseProxy } from '../lib/api';
import {getSnapshotData} from '../lib/utils/utils';

export const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
export const CONFIGURATIONS_LOADING = 'CONFIGURATIONS_LOADING';
export const GET_NEW_FROM_ID = 'GET_NEW_FROM_ID';
export const UPDATE_CONFIG_TEMPLATE = 'UPDATE_CONFIG_TEMPLATE';
export const GET_COMPANY_CONFIGURATIONS = 'GET_COMPANY_CONFIGURATIONS';


export function updateConfigurations(configurations, scopeId, scope = "projects") {
	return ({ firebase, platformActions, apiServer }) => {
		const getPromise = async () => {
			let updates = {};
			Object.keys(configurations).forEach(configKey => {
				updates[`templates/configurations/${scope}/${scopeId}/${configKey}`] = configurations[configKey];
			});
			
			await updateUsingFirebaseProxy({ projectId: scopeId, type: 'templates/configurations', updates});
		
			await new Promise(resolve => { 
				setTimeout(async () => {
					await platformActions.net.fetch(apiServer + `/v1/services/templates/merge?templateSubject=configurations&scope=${scope}&scopeId=${scopeId}`);
					resolve();
				}, 5000)
			})

			return { scope, [(scope == 'projects') ? 'projectId' : 'companyId']: scopeId, configurations };
		};

		return {
			type: UPDATE_CONFIG_TEMPLATE,
			payload: getPromise()
		};
	};
}

export function getConfigurations(scope, scopeId, viewer, forceMSClientConfig = true) { 
	const getPromise = async () => {
		const scopeParams = { scope, scopeId };
		const isGlobal = scope === 'global';
		const resourceParams = {
			forceMSClientConfig,
			resourceName: 'configurations',
			firebasePath: isGlobal ? 'configurations/global' : `configurations/${scope}/${scopeId}`,
		}
	
		const onData = (data) => {
			batchDispatch([{ type: GET_CONFIGURATIONS, payload: { scopeType: scope, scopeId, configurations: data, immediateGlobalStorageSave: isGlobal } }]);
		}

		const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
		if (result) {
			onData(result);
		}

		return { scope, scopeId };
	}

	return {
		type: CONFIGURATIONS_LOADING,
		payload: getPromise(),
	};
}