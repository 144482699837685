import React from 'react';
import { onError } from '../../../common/app/funcs';
import { FocusManagerContext, FOCUS_ELEMENT_ID_ATTRIBUTE_NAME, IS_DISABLED_DATA_ATTRIBUTE } from './common';
import _ from 'lodash';

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.elementIdDataAttributeName = FOCUS_ELEMENT_ID_ATTRIBUTE_NAME;
    this.elementsRegisterCounts = {};
    this.state = {
      registerElement: this.handleRegisterElement,
      unregisterElement: this.handleUnregisterElement,
      setElementFocus: this.handleSetElementFocus,
      
      elementProps: {
        onClickCapture: this.handleElementClickCapture,
      },
      elementsState: {},
    }
  }

  /**
   * 
   * @param {string} elementId 
   * @param {string} parentElementId 
   * @returns 
   */
  initElementState = (elementId, parentElementId) => {
    if (this.currentlyFocusedElementId === elementId)
      this.currentlyFocusedElementId = null;

    return {
      id: elementId,
      parentId: parentElementId,
      isFocus: false,
      isFocusWithin: false,
    };
  }

  /**
   * 
   * @param {string} elementId 
   * @param {string} parentElementId 
   */
  handleRegisterElement = (elementId, parentElementId = null) => {
    this.setState(prevState => {
      const { elementsState } = prevState;

      let newElementsState = Object.assign({}, elementsState);

      if (!elementId) {
        onError({
          errorMessage: 'Cannot register element without an elementId',
          methodMetaData: {
            args: {elementId, parentElementId},
            name: 'FocusManager.Provider.handleRegisterElement',
          },
        });
        return;
      }

      if (this.elementsRegisterCounts[elementId]) {
        this.elementsRegisterCounts[elementId] += 1;
        return;
      }

      newElementsState[elementId] = this.initElementState(elementId, parentElementId || null);
      this.elementsRegisterCounts[elementId] = 1;

      return { elementsState: newElementsState };
    });
  }

  /**
   * @param {string} elementId 
   */
  handleUnregisterElement = (elementId) => {
    if (!elementId) {
      onError({
        errorMessage: 'Cannot unregister element without an elementId',
        methodMetaData: {
          args: { elementId },
          name: 'FocusManager.Provider.handleUnregisterElement',
        },
      });
      return;
    }

    this.setState(prevState => {
      const { elementsState } = prevState;

      if (this.elementsRegisterCounts[elementId] > 1) {
        this.elementsRegisterCounts[elementId] -= 1;
        return;
      }
  
      delete this.elementsRegisterCounts[elementId];

      let newElementsState = Object.assign({}, elementsState);

      delete newElementsState[elementId];
      
      return { elementsState: newElementsState };
    });
  }

  handleElementClickCapture = (e) => {
    const closestRegisteredElement = e.target && e.target.closest(`[data-${this.elementIdDataAttributeName}]`);
    const closestRegisteredElementId = _.get(closestRegisteredElement, ['dataset', this.elementIdDataAttributeName.toLowerCase()]); // lowerCase because when saved into the dataset it is being lowercased automatically
    const isElementDisabled = _.get(closestRegisteredElement, ['dataset', IS_DISABLED_DATA_ATTRIBUTE.toLowerCase()]);

    if (closestRegisteredElementId && this.currentlyFocusedElementId !== closestRegisteredElementId && isElementDisabled !== 'true')
      this.handleSetElementFocus(closestRegisteredElementId);
  }

  getResetedElementsState = (elementsState = this.state.elementsState) => {
    let initialElementsState = {};

    Object.values(elementsState || {}).forEach(elementState => initialElementsState[elementState.id] = this.initElementState(elementState.id, elementState.parentId));

    return initialElementsState;
  }

  handleSetElementFocus = (targetElementId) => {
    this.setState(prevState => {
      const { elementsState } = prevState;
  
      let newElementsState = Object.assign({}, this.getResetedElementsState(elementsState));
  
      let elementIdsArr = [targetElementId];
      while (elementIdsArr.length) {
        const currElementId = elementIdsArr.pop();
  
        const currElementState = newElementsState[currElementId];
        if (!currElementState)
          continue;
  
        const isTarget = currElementId === targetElementId;
  
        if (currElementState.parentId)
          elementIdsArr.push(currElementState.parentId);
  
        let newElementState = Object.assign({}, currElementState);
        
        if (isTarget) {
          newElementState.isFocus = true;
          this.currentlyFocusedElementId = currElementId;
        }
        else
          newElementState.isFocusWithin = true;
  
        newElementsState[currElementId] = newElementState;
      }
  
      return { elementsState: newElementsState };
    });
  }

  render() {
    const { children } = this.props;

    return (
      <FocusManagerContext.Provider value={this.state}>
        {children}
      </FocusManagerContext.Provider>
    );
  }
}

Provider.displayName = 'FocusManagerProvider';
export default Provider;