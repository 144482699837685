import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { signOut, loadAuth0UserDataFromAsyncStorage, getValidAuth0Token } from '../common/auth/actions';
import { getMyUserInfo } from '../common/users/actions';
import { startAuthLogin, retriveFCMToken } from '../common/lib/redux-firebase/actions';
import { valideTokenIssuer } from '../common/auth/funcs';
import InvalidTokenIssuer from './views/Auth/InvalidTokenIssuerPage';
import withRouterHOC from './components/Router/util/withRouterHOC';
import { didSDKServerInit, setServerSdkAuthClientParams } from '../common/lib/api';
import { withRouter } from 'react-router';

class UserValidator extends React.Component {
  constructor(props) {
    super(props);
    this.onLoginHandler = this.onLoginHandler.bind(this);
    this.state = {
      cardAnimaton: 'cardHidden',
      isValidTokenIssuer: true,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  async componentDidMount() {
    const { startAuthLogin, number, viewer, originViewer, loadAuth0UserDataFromAsyncStorage, signOut,} = this.props;
      
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );

    let isMicrosoftBrowser =
      /MSIE 10/i.test(navigator.userAgent) || // This is internet explorer 9 or 10
      /MSIE 9/i.test(navigator.userAgent) || // This is internet explorer 9 or 11
      /rv:11.0/i.test(navigator.userAgent) || // This is internet explorer 9 or 11
      /Edge\/\d./i.test(navigator.userAgent); // This is Microsoft Edge

    if (isMicrosoftBrowser) {
      this.props.history.push('/pages/browser-not-supported');
      return;
    }

    let reDirect =
      this.props.history.location.pathname +
      (this.props.history.location.search || '') +
      (this.props.history.location.hash || '');
    var auth0_data = await loadAuth0UserDataFromAsyncStorage();
    if (auth0_data && auth0_data.id_token) {
      startAuthLogin(number, auth0_data.id_token);
    }

    if (
      !this.props.history.location.pathname.includes('/pages/login-page') &&
      !viewer &&
      (!auth0_data || !auth0_data.id_token)
    ) {
      reDirect = '/pages/login-page';
    } else if (!this.didNotifyLogin) {
      if (!originViewer) this.onLoginHandler();
    }

    this.setState({ isValidTokenIssuer: valideTokenIssuer(this.props.authToken) });

    if (!reDirect || reDirect == '' || reDirect == '/') reDirect = '/main';

    if (reDirect) this.props.history.push(reDirect);
  }

  async onLoginHandler(props) {
    props = props || this.props;
    const { getMyUserInfo, originViewer, authToken, onLogin, getValidAuth0Token } = props;
    let shouldCallOnLogin = false;
    if (!this.didNotifyLogin) {
      this.didNotifyLogin = true;
      shouldCallOnLogin = true;
    }

    if (!originViewer) {
      const isValidTokenIssuer = valideTokenIssuer(authToken);
      if (!isValidTokenIssuer) return;

      let validAuthToken = ((await getValidAuth0Token(authToken)) || {}).id_token;
      if (validAuthToken) await getMyUserInfo(validAuthToken);
    }

    if (shouldCallOnLogin) {
      if (onLogin) onLogin();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { authToken, accessToken, loggedIn, startAuthLogin, location, onSignOut } = this.props;

    if (nextProps.accessToken != accessToken || (nextProps.accessToken && !didSDKServerInit())) {
      setServerSdkAuthClientParams({
        clientAuthToken: nextProps.accessToken,
      });
    }

    if (nextProps.authToken && (nextProps.authToken != authToken)) {
      startAuthLogin(nextProps.number, nextProps.authToken);// Re-Connect to firebase
    }
    if (loggedIn && !nextProps.loggedIn) {
      onSignOut();
      this.props.history.push('/pages/login-page');
    } else if (loggedIn && nextProps.loggedIn && location.pathname === '/pages/login-page') {
      setTimeout(() => {
        this.props.history.push('/main');
      }, 50);
    }

    let isValidTokenIssuer = this.state.isValidTokenIssuer;
    if (
      (this.props.isValDiff(nextProps, ['loggedIn']) && nextProps.loggedIn) ||
      this.props.isValDiff(nextProps, ['authToken'])
    ) {
      isValidTokenIssuer = valideTokenIssuer(nextProps.authToken);
      this.setState({ isValidTokenIssuer });
    }

    if (!nextProps.isLoggedIn && this.props.isValDiff(nextProps, ['loggedIn'])) {
      this.didNotifyLogin = false;
    }

    if (isValidTokenIssuer && !this.didNotifyLogin && nextProps.loggedIn && !nextProps.viewer) {
      this.onLoginHandler(nextProps);
    }
  }

  render() {
    const { loggedIn, storageLoaded, firebaseConnected } = this.props;
    const { isValidTokenIssuer } = this.state;
    if (!isValidTokenIssuer && loggedIn) return <InvalidTokenIssuer />;

    if (!storageLoaded || (loggedIn && !firebaseConnected)) return <div>Please wait</div>;

    return (
      <div>
        <Outlet />
      </div>
    );
  }
}

export default connect(state => ({
  loggedIn: state.auth.loggedIn,
  firebaseConnected: state.auth.firebaseConnected,
  viewer: state.users.viewer,
  accessToken: state.auth.accessToken,
  authToken: state.auth.authToken,
  number: state.auth.number,
  originViewer: state.users.originViewer,
  storageLoaded: state.app.storageLoaded,

}), { retriveFCMToken, startAuthLogin, loadAuth0UserDataFromAsyncStorage, getMyUserInfo, signOut, getValidAuth0Token })(withRouterHOC(UserValidator));

