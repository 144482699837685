import * as appActions from '../app/actions';
import * as actions from './actions';
import * as projectsAction from '../projects/actions'
import { REPLACE_USER } from '../auth/actions';

import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record({
  didLoad: OrderedMap(),
  eventsDidLoad: Map(),
}, 'equipment');

const initialState = new InitialState;

export default function equipmentReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) { 
    case actions.GET_EQUIPMENT: 
      return state.setIn(['didLoad', action.getNested(['payload','projectId'])], false);
    
    case PROJECT_EVENTS.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','equipment']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case actions.EQUIPMENT_DONE_LOADING: {
      if (!action.payload)
        return state;
      
      const { scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);

      return state;
    }
    
    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_EQUIPMENT:
    case appActions.CLEAN_CACHE: {      
      state = initialState;
      return state;
    }

    case actions.END_EQUIPMENT_LISTENER: {
      if (action.payload && action.payload.projectId)
        state = state.setIn(['didLoad', action.payload.projectId], false);
      return state;
    }
  }

  return state;
}