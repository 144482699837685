
import { platformActions } from '../platformActions';
import { envParams } from '../../common/configureMiddleware';


export async function fetchQuasiStatics(type) {
  let url = `${envParams.apiServer}/v1/quasiStatics`;

  if (type)
    url += `?type=${type}`;
  else
    type = 'quasiStatics';

  let res = await platformActions.net.fetch(url);
  res = await res.getJson();

  return { [type]: res };
}


