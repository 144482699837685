import { GridContainer, GridItem } from '../index';
import React, { useMemo, useState } from 'react';
import { CloseRounded } from '@material-ui/icons';
import theme from '../../assets/css/theme';

const VideoComponent = ({ videoURIs, onRemoveVideo }) => {
  if (!videoURIs?.length) {
    return null;
  }

  const [isHover, setIsHover] = useState();

  const removeButton = useMemo(() => {
    if (!onRemoveVideo || !isHover) return null;

    return (
      <div id='removeButton' style={{ position: 'absolute', display: 'flex', padding: 5 }}>
        <CloseRounded
          onClick={(e) => {
            e.preventDefault();
            onRemoveVideo();
          }}
          style={{
            margin: 5,
            fontSize: 16,
            borderRadius: '50%',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: theme.brandNeutral + '80',
            zIndex: 10,
          }}
        />
      </div>
    );
  }, [isHover]);

  return (
    <GridContainer spacing={16} style={{ marginTop: 0 }}>
      {videoURIs.map(
        (uri, i) =>
          Boolean(uri) && (
            <GridItem
              onMouseOver={() => {
                setIsHover(true);
              }}
              onMouseOut={() => {
                setIsHover(false);
              }}
              key={uri}
              xs={i === videoURIs.length - 1 && i % 2 === 0 ? 12 : 6}>
              {removeButton}
              <video style={{ maxWidth: '100%', maxHeight: '500px' }} controls>
                <source src={uri} type='video/mp4' />
              </video>
            </GridItem>
          )
      )}
    </GridContainer>
  );
};

export default VideoComponent;
