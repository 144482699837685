import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "Tasks",
    id: 'issues.issues.title'
  },
  task: {
    defaultMessage: 'Task',
    id: 'issues.task'
  },
  empty: {
    defaultMessage: 'There are no tasks to display.',
    id: 'issues.issues.empty'
  },
  createNew: {
    defaultMessage: 'Press the orange button to add a task!',
    id: 'issues.issues.createNew'
  },
  resolveAlertTitle: {
    defaultMessage: 'Resolve Task',
    id: 'issues.alert.resolveTitle'
  },
  updateAssignee: {
    defaultMessage: 'Update assignee',
    id: 'issues.alert.updateAssignee'
  },
  updateState: {
    defaultMessage: 'Update status',
    id: 'issues.alert.updateState'
  },
  reassignAlertMessage: {
    defaultMessage: 'Are you sure you want to reassign this task to {name}',
    id: 'issues.alert.reassignMessage'
  },
  resolveAlertMessage: {
    defaultMessage: 'Are you sure you want to resolve this task?',
    id: 'issues.alert.resolveMessage'
  },
  reOpenAlertMessage: {
    defaultMessage: 'Are you sure you want to reopen this task?',
    id: 'issues.alert.reOpenAlertMessage'
  }, 
  connectedChecklist: {
    defaultMessage: 'Connected checklist item',
    id: 'issues.alert.connectedChecklist'
  },
  alsoResolveItemAlertMessage: {
    defaultMessage: 'A checklist item was rejected following to this issue, do you wish to resolve this checklist item also?',
    id: 'issues.alert.alsoResolveItemAlertMessage'
  },
  alsoRejectItemMessage: {
    defaultMessage: 'A checklist item was rejected following to this issue, do you wish to reject this checklist item also?',
    id: 'issues.alert.alsoRejectItemMessage'
  },
  alsoConfirmItemAlertMessage: {
    defaultMessage: 'A checklist item was rejected following to this issue, do you wish to confirm this checklist item also?',
    id: 'issues.alert.alsoConfirmItemAlertMessage'
  },
  closeAlertTitle: {
    defaultMessage: 'Close Task',
    id: 'issues.alert.closeTitle'
  },
  closeAlertMessage: {
    defaultMessage: 'Are you sure you want to close this task?',
    id: 'issues.alert.closeMessage'
  },
  resolveButton: {
    defaultMessage: 'Resolve',
    id: 'issues.button.resolve'
  },
  forwardButton: {
    defaultMessage: 'Reassign',
    id: 'issues.button.Forward'
  },
  openButton: {
    defaultMessage: 'Task',
    id: 'issues.button.open'
  },
  closeButton: {
    defaultMessage: 'Close',
    id: 'issues.button.close'
  },
  reopenButton: {
    defaultMessage: 'Reopen',
    id: 'issues.button.reopen'
  },
  resolveStatus: {
    defaultMessage: 'Resolved',
    id: 'issues.status.resolve'
  },
  closeStatus: {
    defaultMessage: 'Closed',
    id: 'issues.status.close'
  },
  openStatus: {
    defaultMessage: 'Open',
    id: 'issues.status.open'
  },
  counterStatus: {
    300: {
      defaultMessage: 'Open tasks',
      id: 'issues.counterStatus.counterOpen'
    },
    200: {
      defaultMessage: 'Resolved tasks',
      id: 'issues.counterStatus.counterResolve'
    },
    100: {
      defaultMessage: 'Closed tasks',
      id: 'issues.counterStatus.counterClose'
    },
    1000: {
      defaultMessage: 'Records',
      id: 'issues.counterStatus.records'
    }
  },
  pastCounterStatus: {
    300: {
      defaultMessage: 'Opened tasks',
      id: 'issues.pastCounterStatus.counterOpen'
    },
    200: {
      defaultMessage: 'Resolved tasks',
      id: 'issues.pastCounterStatus.counterResolve'
    },
    100: {
      defaultMessage: 'Closed tasks/records',
      id: 'issues.pastCounterStatus.counterClose'
    },
    1000: {
      defaultMessage: 'Records',
      id: 'issues.pastCounterStatus.records'
    }
  },
  noDueDate: {
    defaultMessage: 'No due date',
    id: 'issues.dueDate.missing'
  },  
  taskInfoTitle: {
    defaultMessage: 'Task Info',
    id: 'issues.info.title'
  },  
  detailsTitle: {
    defaultMessage: 'Details',
    id: 'issues.details.title'
  }, 
  assignToTitle: {
    defaultMessage: 'Assigned to',
    id: 'issues.assignTo.title'
  }, 
  dueDateTitle: {
    defaultMessage: 'Due date',
    id: 'issues.deuDate.title'
  }, 
  assignTo: {
    defaultMessage: 'Assign to',
    id: 'issues.assignTo.selector'
  },  
  taskAssignToTitle: {
    defaultMessage: 'Assign Task',
    id: 'issues.newIssue.assignToTitle'
  }, 
  dueDate: {
  	defaultMessage: 'Due date:',
    id: 'issues.dueDate.selector'
  }, 
  trade: {
  	defaultMessage: 'Trade:',
    id: 'issues.trade.title'
  }, 
  dueDateTitle:
  {
    defaultMessage: 'Due Date',
    id: 'issues.titles.dueDate'
  },
  issuePlaceholder: {
    defaultMessage: 'Describe the task here',
    id: 'issues.newIssue.placeholder'
  },  
  descriptionTitle:
  {
    defaultMessage: 'Description:',
    id: 'issues.description.title'
  },   
  locationTitle:
  {
    defaultMessage: 'Location',
    id: 'issues.location.title'
  }, 
  location: 
  {
    defaultMessage: 'Location:',
    id: 'issues.location.selector'
  }, 
  noLocation: 
  {
    defaultMessage: 'Location not set',
    id: 'issues.location.noLocation'
  }, 
  noFloor: 
  {
    defaultMessage: 'Floor not set',
    id: 'issues.location.noFloor'
  }, 
  noUnit: 
  {
    defaultMessage: 'Unit not set',
    id: 'issues.location.noUnit'
  }, 
  postCovertetSucceeded: {
    defaultMessage: 'Message converted to task',
    id: 'issues.newIssue.postCovertetSucceeded'
  },
  updateIssue: {
    '100': {
      defaultMessage: 'Close task',
      id: 'issues.updateIssue.title.100'
    }, 
    '200': {
      defaultMessage: 'Resolve task',
      id: 'issues.updateIssue.title.200'
    }, 
    '300': {
      defaultMessage: 'Reopen task',
      id: 'issues.updateIssue.title.300'
    }, 
  },
  issueStateUpdateCameraText: {
    'mandatory': {
      defaultMessage: 'You have to add a photo for the record',
      id: 'issues.updateIssue.cameraText.mandatory'
    },
    '100': {
      defaultMessage: 'You may add a photo for the record',
      id: 'issues.updateIssue.cameraText.100'
    },
    '200': {
      defaultMessage: 'Take a photo to show the done work!',
      id: 'issues.updateIssue.cameraText.200'
    },
    '300': {
      defaultMessage: 'Take a photo of the task and show what\'s need to be fixed',
      id: 'issues.updateIssue.cameraText.300'
    },
  },
  issueUpdateSucceeded: {
    '100': {
      defaultMessage: 'Task closed successfully',
      id: 'issues.updateIssue.issueUpdateSucceeded.100'
    },
    '200': {
      defaultMessage: 'Task resolved successfully',
      id: 'issues.updateIssue.issueUpdateSucceeded.200'
    },
    '300': {
      defaultMessage: 'Task reopened successfully',
      id: 'issues.updateIssue.issueUpdateSucceeded.300'
    },  
  },
  issueStateUpdateCommentPlaceholder: {
    defaultMessage: 'Add notes here...',
    id: 'issues.updateIssue.issueStateUpdateCommentPlaceholder'
  },
  uploadingPleaseWait: {
    defaultMessage: "Updating, Please wait",
    id: 'units.updateIssue.uploadingPleaseWait'
  },
  unitNumber: {
    defaultMessage: "Unit {number}",
    id: 'units.location.unitNumber'
  },
  unitAndFloorNumber:
  {
    defaultMessage: "{floorNumber, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Floor, Unit {number}",
    id: 'units.location.unitAndFloorNumber'
  },
  floorNumber: {
    defaultMessage: "{floorNumber, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Floor",
    id: 'units.location.floorNumber'
  },
  floorTitleGeneral: {
    defaultMessage: "General, {floorTitle}",
    id: 'units.location.floorTitleGeneral'
  },
  buildingAndFloor: {
    defaultMessage: "{buildingTitle} - {floorNumber, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Floor",
    id: 'units.location.buildingAndFloor'
  },
  noImage: {
    defaultMessage: "No image",
    id: 'issues.issues.noPic'
  },
  noTrade: {
    defaultMessage: "No trade",
    id: 'issues.issues.noTrade'
  },
  source: {
    checklist : {
      "defaultMessage" : "Checklist requirement",
      "id": "issues.source.checkllist",
    },
    randomRecords: {
      "defaultMessage" : "Random records",
      "id": "issues.source.randomRecords",
    },
    checklistRejection : {
      "defaultMessage" : "Checklist rejection",
      "id": "issues.source.checklistRejection",
    },
    random: {
      "defaultMessage" : "Random tasks",
      "id": "issues.source.random",
    },
  },
  story: {
    title : {
      "defaultMessage" : "Timeline",
      "id": "issues.story.title",
    },
    by: {
      "defaultMessage" : "By {name}",
      "id": "issues.story.by",
    },
    created: {
      "defaultMessage": "Created",
      "id": "issues.story.created",
    },
    attachmentUploaded: {
      "defaultMessage": "Uploaded attachment",
      "id": "issues.story.attachmentUploaded",
    },
    comment: {
      "defaultMessage": "Comment Added",
      "id": "issues.story.comment",
    },
    uploaded: {
      "defaultMessage": "Uploaded {count} Images",
      "id": "issues.story.uploaded",
    },
    closed: {
      "defaultMessage": "Closed",
      "id": "issues.story.closed",
    },
    resolved: {
      "defaultMessage": "Resolved",
      "id": "issues.story.resolved",
    },
    reopend: {
      "defaultMessage": "Reopend",
      "id": "issues.story.reopend",
    },
    reassigned: {
      "defaultMessage": "Reassigned To {name}",
      "id": "issues.story.reassigned",
    }
  }
});
