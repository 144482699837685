import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { fromJSON } from './common/transit';
import { initialTransitState } from './common/initialState';
import configureStore from './common/configureStore';
import webActions from './web/webActions';
import { register } from './registerServiceWorker';
import App from './web/app/App.js';
import StandardInput from './web/components/CementoComponents/StandardInput';
import './web/assets/scss/material-dashboard-pro-react.css?v=1.3.0';
import 'react-virtualized/styles.css';
// import prototype implemntation
import './common/Prototype';

import ErrorBoundary from './web/app/ErrorBoundary';

import Text from './web/components/CementoComponents/Text';
import TradeBadge from './web/components/CementoComponents/TradeBadge';
import theme from './web/assets/css/theme';
import { init as initPlatformActions } from './common/platformActions.js';


let defaultLocale = webActions.app.getDeviceLang();
var initialState = fromJSON(initialTransitState);

initPlatformActions(webActions, initialState.config);

const store = configureStore({
	platformDeps: {
		/*createStorageEngine*/
	},
	initialState: {
		...initialState,
		device: initialState.device.set('isReactNative', false),
		intl: initialState.intl
			.set('currentLocale', defaultLocale)
			.set('defaultLocale', defaultLocale)
			.set('initialNow', Date.now()),
	},
	standardInputComponent: StandardInput,
	containerComponent: 'div',
	textComponent: Text,
	tradeComponent: TradeBadge,
	theme,
});

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(
	container,
);

root.render(
	<ErrorBoundary>
		<App store={store} />
	</ErrorBoundary>,
);

register();
