import LoginPage from '../../views/Pages/LoginPage';
import NotSupported from '../../views/Pages/NotSupported';

export default [
  {
    path: 'login-page',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    element: <LoginPage />,
  },
  {
    path: 'browser-not-supported',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    element: <NotSupported />,
  },
];
