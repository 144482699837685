import { Record } from '../transit';

const Contact = Record({
  id: null,
  displayName: null,
  email: null,
  avatar: null,
  phoneNumber: null,
  thumbnailPath: null,
  emailAddresses: {}, // for phone contacts who aren't user yet
  phoneNumbers: {}, 
}, 'contact');

export default Contact;
 