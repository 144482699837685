import theme from '../app/theme';
import issuesMessages from "./issuesMessages";
import postsMessages from "../posts/postsMessages";

export const ISSUE_STATE_CLOSED = 100;
export const ISSUE_STATE_RESOLVED = 200;
export const ISSUE_STATE_OPENED = 300;
export const POST_STATUS = 1;

export const ISSUE_STATUSES_ARRAY = [
  ISSUE_STATE_CLOSED,
  ISSUE_STATE_RESOLVED,
  ISSUE_STATE_OPENED,
]

export const POST_STATUS_COLORS = {
    [ISSUE_STATE_CLOSED]: theme.brandSuccess,
    [ISSUE_STATE_RESOLVED]: theme.brandWarning,
    [ISSUE_STATE_OPENED]: theme.brandDanger,
    [POST_STATUS]: theme.brandInfo
}

export const POST_STATUS_STRING = {
    [ISSUE_STATE_OPENED]: issuesMessages.openStatus,
    [ISSUE_STATE_RESOLVED]:  issuesMessages.resolveStatus,
    [ISSUE_STATE_CLOSED]:  issuesMessages.closeStatus,
    [POST_STATUS]: postsMessages.documentation
};

export function getPostColorByStatus(status) {
    return POST_STATUS_COLORS[status] || theme.brandInfo;
}


/**
 * 
 * @param {{
 *  status: number,
 *  type: string,
 *  reportDate: number,
 * }} form 
 * @returns 
 */
export const getStatusParams = (post) => {
  let statusParams = {
    message: POST_STATUS_STRING[post.issueState] || POST_STATUS_STRING[POST_STATUS],
    color: getPostColorByStatus(post.issueState)
  }

  return statusParams;
}