import { Navigate } from 'react-router-dom';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';
import LocationContainerPage from '../../../../views/Locations/LocationContainerPage';
import ObjectPropertiesPage from '../../../../views/Properties/ObjectPropertiesPage';

export default {
  path: 'info',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics/locations'} />,
    },
    {
      path: 'analytics/:reportId?',
      element: (
        <PropertyAnalytics
          key={'project_PropertyAnalytics_info_analytics'}
          contentType={'info'}
          section={'analytics'}
        />
      ),
    },
    {
      path: 'locationContainerPage',
      element: <LocationContainerPage />,
      children: [
        {
          path: ':buildingId/:floorId/:unitId',
          element: (
            <ObjectPropertiesPage
              key={'project_PropertyAnalytics_location_info_locationsInfo'}
              ontentType={'info'}
              subjectName={'locationsInfo'}
            />
          ),
        },
      ],
    },
  ],
};
