import React, { Component } from 'react';
import _ from 'lodash';
class HoverWrapper extends Component {
    constructor(props) {
        super(props);
        this.handleHover = this.handleHover.bind(this);
        this.handleHoverOff = this.handleHoverOff.bind(this);
        this.state = { hover: false, props: {}, hoverProps: {} };
    }

    UNSAFE_componentWillMount() {
        this.setComponentData({}, this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setComponentData(this.props, nextProps);
    }

    setComponentData(prevProps, nextProps) {
        let stateChanges = {};
        if (prevProps.props != nextProps.props)
            stateChanges.props = nextProps.props;

        if (prevProps.hoverProps != nextProps.hoverProps)
            stateChanges.hoverProps = { ..._.get(nextProps, 'props', {}), ..._.get(nextProps, 'hoverProps', {}) };

        if (_.values(stateChanges).length)
            this.setState(stateChanges);
    }

    handleHover() {
        const { onHover = _.noop } = this.props;
        this.setState({ hover: true });
        onHover();
    }

    handleHoverOff() {
        const { onHoverOff = _.noop } = this.props;
        this.setState({ hover: false });    
        onHoverOff();
    }

    render() {
        const { hover = false, props = {}, hoverProps = {} } = this.state;
        const { renderItem = _.noop, children } = this.props;

        const renderProps = hover ? hoverProps : props;
        return <div style={{ display: 'contents' }} onMouseEnter={this.handleHover} onMouseLeave={this.handleHoverOff}>
            {Boolean(typeof children === 'function') ? children(renderProps) : renderItem(renderProps)}
        </div>;
    }
}

export default HoverWrapper;