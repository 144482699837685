import * as actions from './actions';
import PropertySection from './propertySection';
import PropertySelectionValue from './propertySelectionValue';
import * as propertyTypes from './propertiesTypes';
import * as lastUpdatesActions from '../lastUpdates/actions'
import Property from './property';
import { CementoRecordObject, Record } from '../transit';
import _ from 'lodash';
import { platformActions } from '../platformActions';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
	didLoad: new CementoRecordObject,
  projectSections: new CementoRecordObject,
  projectProperties: new CementoRecordObject,
  lastUpdateTS: 0,
  lastUpdateAvailable: new CementoRecordObject,  
  specificPropertiesUpdatesAvailable: new CementoRecordObject 
}, 'propertiesTypes', false);

const initialState = new InitialState;

export default function propertiesReducer(state = initialState, action) {
  switch (action.type) {

    case actions.PROPERTIES_TYPES_LOADING + '_SUCCESS': {
      const { projectId } = action.payload;
      if (!state.getIn(['didLoad', projectId])) {
        state = state.setIn(['didLoad', projectId], true);
      }

      return state;
    }

    case lastUpdatesActions.GET_LAST_UPDATES: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects: _projects } = action.payload;
      const projects = _projects && _projects.toJS ? _projects.toJS() : _projects;

      Object.keys(projects).forEach(projectId => {
        let TS = projects.getNested([projectId, 'properties-types', 'lastUpdateTS'], DEFAULT_LAST_UPDATE_TS);

        let specificUpdatedPropsBySubject = _.omit(projects.getNested([projectId, 'properties-types']), 'lastUpdateTS');
        if (!_.isEmpty(specificUpdatedPropsBySubject))
          _.forIn(specificUpdatedPropsBySubject, (currSubjectProps, subjectName) => {
            state = state.setIn(['specificPropertiesUpdatesAvailable', projectId, subjectName], currSubjectProps);
          });

        let curr = state.getNested(['lastUpdateAvailable', projectId], null);
        if (curr < TS) state = state.setIn(['lastUpdateAvailable', projectId], TS);
      });

      return state;
    }

    case actions.GET_PROPERTIES_TYPES_BY_ID: {
      const { projectId, subjectName, property } = action.payload || {};
      if (!projectId || !subjectName || !property) {
        return state;
      }
      const values = property?.values
        ? property.values.map(v => new PropertySelectionValue(_.assign({}, v, { isDeleted: Boolean(v?.isUnselectable) })))
        : null;

      state = state.setIn(['projectProperties', projectId, subjectName, property.id], new Property({ ...property, values }));

      return state;
    }

    case actions.GET_PROPERTIES_TYPES: {
      if (!action.payload?.projectId)
        return state;

      const { types, subjectName, projectId } = action.payload;
      if (types) {
        let sectionsMap = state.getIn(['projectSections', projectId], new CementoRecordObject());
        sectionsMap = sectionsMap.setIn([subjectName], new CementoRecordObject());
        let propertiesMap = state.getIn(['projectProperties', projectId], new CementoRecordObject());
        propertiesMap = propertiesMap.setIn([subjectName], new CementoRecordObject());

        // TODO: Remove to function of "AddMockPropTypes"
        if (propertyTypes.MOCK_PROPERTY_TYPES[subjectName] && platformActions.app.getPlatform() === "web") {
          // Add default propTypes
          _.entries(propertyTypes.MOCK_PROPERTY_TYPES[subjectName]).forEach(([key, curr]) => {
            propertiesMap = propertiesMap.setIn([subjectName, key],
              new Property({
                ...curr,
                id: key,
                values: null,
                title: curr.title,
                editable: _.isNil(curr.editable) ? true : curr.editable
              }));
          });

          _.entries(propertyTypes.MOCK_PROPERTY_TYPES_SECTIONS[subjectName]).forEach(([key, curr]) => {
            sectionsMap = sectionsMap.setIn([subjectName, key],
              new PropertySection({ ...curr, id: key }));
          });
        }
        
        types && types.getNested(['sections'], {}).loopEach((key, curr) => {
          if (curr) {
            sectionsMap = sectionsMap.setIn([subjectName, key],
              new PropertySection({ ...curr, id: key }));
          }
        });
        types && types.getNested(['properties'], {}).loopEach((key, curr) => {
          if (!curr) return;

          let values = curr?.values
            ? curr.values.map(v => new PropertySelectionValue(_.assign({}, v, { isDeleted: Boolean(v?.isUnselectable) })))
            : null;

          propertiesMap = propertiesMap.setIn([subjectName, key],
            new Property({
              ...curr,
              id: key,
              values,
              title: curr.title,
              editable: _.isNil(curr.editable) ? true : curr.editable
            }));
          state = state.removeIn(['specificPropertiesUpdatesAvailable', projectId, subjectName, key, 'lastUpdateTS'])
        });

        state = state.setIn(['projectSections', projectId], sectionsMap);
        state = state.setIn(['projectProperties', projectId], propertiesMap);
      }

      return state;
    }
    case actions.PUT_SELECTION_LIST_OPTION + "_SUCCESS": 
    case actions.DELETE_SELECTION_LIST_OPTION + "_SUCCESS": {
      if (!action.payload)
        return state;

      const { projectId, subjectName, propId, changedValues } = action.payload;
      let originalValues = state.getNested(['projectProperties', projectId, subjectName, propId, 'values']);
      originalValues = originalValues && originalValues.toJS ? originalValues.toJS() : originalValues;
      let mergedValues = {};
      _.forIn(originalValues, (val, key) => _.set(mergedValues, [key], (val && val.toJS ? val.toJS() : val)));
      _.forIn(changedValues, (val, key) => _.set(mergedValues, [key], val));

      mergedValues = _.map(mergedValues, v => new PropertySelectionValue(_.assign({}, v, { isDeleted: v?.isUnselectable || v?.isDeleted })));

      return state.setIn(['projectProperties', projectId, subjectName, propId, 'values'], mergedValues);
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }

  return state;
}