import React from "react";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";

// Components
import Text from "../../components/CementoComponents/Text";

// Others
import theme from "../../assets/css/theme";
import plus_primary from "../../assets/img/icons/plus_primary.png";

class AddNewButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { onClick, propagateClick } = this.props;
    if (!propagateClick) e.stopPropagation();
    if (onClick) onClick();
  }

  render() {
    const { rtl, title, style = {}, values } = this.props;
    return (
      <>
        <div
          onClick={this.onClick}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ...style,
          }}
        >
          <div
            style={{
              [rtl ? "marginLeft" : "marginRight"]: theme.verticalMargin,
            }}
          >
            <img src={plus_primary} />
          </div>
          {Boolean(title) && <Text values={values}>{title}</Text>}
        </div>
      </>
    );
  }
}

const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

export default enhance(AddNewButton);
