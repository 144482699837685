const lokiCollections = [
	'lastUpdatesV2',
	'lastUpdateTS',
	'checklistItemsInstances',
	'propertyInstances',
	'members',
	'posts',
	'employees',
	'equipment',
	'forms',
	'checklistItemInstances',
	'logObjects',
	'comments'
];

export { lokiCollections };
