import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ClientServerConnectivityManagerInstance from '../../common/lib/ClientServerConnectivityManager';
import Text from '../components/CementoComponents/Text';
import { connect } from 'react-redux';
import { Map, OrderedMap } from 'immutable';

let statusToNotPresentTime = {
  "change - Fetching":true,
  "connection established":true,
  "subscribe":true
}

const ConnectivitySubscriptionTable = (props) => {
  const { connectionManager, scope = 'projects', currProjectId } = props;
  const [expandAll, setExpandAll] = useState(null);

  const services = useMemo(() => {
    let currMap = connectionManager.getIn([scope, currProjectId]);
    if (!currMap) return [];
    let ret = [];
    currMap.map((object, key) => {
      let curr = {
        id: key,
        status:object.status,
        size:object.size,
        timeSinceLastAction: object.timeSinceLastAction,
        history:[]
      };
      curr.history = connectionManager.getIn([`${scope}_history`, currProjectId, key], new Map()).map((object, ts) => {
        return object;
      }).toIndexedSeq().toArray();
        ret.push(curr);
    });
    return ret;
  }, [connectionManager]);
  
  return (
    <div>
      <div  style={{cursor: "pointer"}}>
        <Text onClick={() => setExpandAll(!Boolean(expandAll))}>{expandAll ? `Collapse` : `Expand`} view</Text>
      </div>
      <table style={{borderWidth:1, borderColor:'black'}}>
        <thead>
          <tr>
            <th>Subject</th>
            <th>status</th>
            <th>size</th>
            <th>timeSinceLastAction</th>
          </tr>
        </thead>
        <tbody>
          {services?.map((service) => {
            let mainRow = 
              <tr key={service.id}>
                <td>{service.id}</td>
                <td>{service.status}</td>
                <td>{service.size}</td>
                <td>{!statusToNotPresentTime[service.status] && service.timeSinceLastAction ? service.timeSinceLastAction/1000 : null}</td>
              </tr>
              if (!expandAll) return mainRow;
              let historyRows = service.history.map((history) => {
                return (
                  <tr key={service.id + history.time} style={{backgroundColor: 'yellow'}}>
                    <td>{service.id}</td>
                    <td>{history.status}</td>
                    <td>{history.size}</td>
                    <td>{!statusToNotPresentTime[history.status] && history.timeSinceLastAction ? history.timeSinceLastAction/1000 : null}</td>
                  </tr>
                )
              });
            return [mainRow, ...historyRows];
          })}
        </tbody>
      </table>
    </div>
  );
}

export default connect(
	state => ({
		connectionManager: state.app.connectionManager,
    currProjectId: state.ui.currProject,
	}),
)(ConnectivitySubscriptionTable);

