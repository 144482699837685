import React from 'react';
import { connectContext } from 'react-connect-context';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { Outlet } from 'react-router-dom';
import { getFullLocationTitle } from './funcs';
import { ProjectContext } from '../../../common/projects/contexts';
import { track } from '../../../common/lib/reporting/actions';
import { setHeaderTitle } from '../../../common/ui/actions';
import SidebarBuilding from '../../layouts/SidebarBuilding';
import theme from '../../assets/css/theme';
import { ALL_BUILDINGS_ID } from '../../../common/app/constants';
import withRouterHOC from '../../components/Router/util/withRouterHOC';

class LocationContainerPage extends React.Component {
  constructor(props) {
    super(props);
    this.saveParamsToState = this.saveParamsToState.bind(this);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.saveParamsToState({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.saveParamsToState(this.props, nextProps);
  }

  componentDidMount() {
    const { track, match } = this.props;
    const projectId = match.getNested(['params', 'selectedProjectId']);
    const contentType = match.getNested(['params', 'contentType']);
    track('enterLocationContainerPage', { projectId, contentType});
  }

  saveParamsToState(props, nextProps) {
    const { track, setHeaderTitle, buildings } = nextProps;
    let stateChanges = {};
    let nextBuildingId = nextProps.getNested(['match', 'params', 'buildingId']);
    let nextFloorId = nextProps.getNested(['match', 'params', 'floorId']);
    let nextUnitId = nextProps.getNested(['match', 'params', 'unitId']);
    let nextContentType = nextProps.getNested(['match', 'params', 'contentType']);
    let nextSelectedProjectId = nextProps.getNested(['match', 'params', 'selectedProjectId']);

    let urlBuildingIdChanged = props.getNested(['match', 'params', 'buildingId']) != nextBuildingId;
    let urlFloorIdChanged = props.getNested(['match', 'params', 'floorId']) != nextFloorId;
    let urlUnitIdChanged = props.getNested(['match', 'params', 'unitId']) != nextUnitId;
    let urlContentTypeChanged = props.getNested(['match', 'params', 'contentType']) != nextContentType;

    let buildingsChanged =
      props.getNested(['buildings', nextBuildingId]) != nextProps.getNested(['buildings', nextBuildingId]);
    let floorsChanged =
      props.getNested(['floors', nextBuildingId, nextFloorId]) !=
      nextProps.getNested(['floors', nextBuildingId, nextFloorId]);
    let unitsChanged =
      props.getNested(['units', nextBuildingId, nextUnitId]) !=
      nextProps.getNested(['units', nextBuildingId, nextUnitId]);

    if (props.buildings != nextProps.buildings) {
      let buildingIdsArray = buildings.keySeq ? buildings.keySeq().toArray() : Object.keys(buildings);
      stateChanges.isSingleBuilding = buildingIdsArray.length == 1;
      stateChanges.firstBuildingId = buildingIdsArray[0];
    }

    if (urlContentTypeChanged) stateChanges.headerParams = null;

    if (
      urlBuildingIdChanged ||
      urlFloorIdChanged ||
      urlUnitIdChanged ||
      buildingsChanged ||
      floorsChanged ||
      unitsChanged ||
      urlContentTypeChanged
    ) {
      let urlParamLocationId =
        nextUnitId == '_'
          ? nextFloorId == '_'
            ? nextBuildingId == '_'
              ? null
              : nextBuildingId
            : nextFloorId
          : nextUnitId;
      let nextContainerUrl =
        '/main/projectContainerPage/' +
        nextSelectedProjectId +
        '/' +
        nextProps.getNested(['match', 'params', 'contentType']) +
        '/locationContainerPage' +
        '/' +
        nextProps.getNested(['match', 'params', 'buildingId']) +
        '/' +
        nextProps.getNested(['match', 'params', 'floorId']) +
        '/' +
        nextProps.getNested(['match', 'params', 'unitId']);
      
      let nextTitle = getFullLocationTitle(nextProps);
      setHeaderTitle(nextTitle);

      let nextType =
        nextUnitId == '_' ? (nextFloorId == '_' ? (nextBuildingId == '_' ? null : 'building') : 'floor') : 'unit';

      if (urlParamLocationId && buildings.size > 0 && buildings.get(nextSelectedProjectId))
        stateChanges.allProjectViewMode =
          urlParamLocationId === 'all' ||
          (buildings.size === 1 && buildings.get(nextSelectedProjectId).keySeq().last() === urlParamLocationId);

      if (urlBuildingIdChanged || urlFloorIdChanged || urlUnitIdChanged)
        track('enterUnitPage', {
          unitTitle: nextTitle,
          unitId: nextUnitId || nextFloorId || nextBuildingId,
          type: nextType,
          contentType: nextContentType,
        });
    }

    if (Object.keys(stateChanges).length > 0) this.setState(stateChanges);
  }

  render() {
    const {
      navigationParams,
      match,
      filteredPosts,
      currViewPosts,
      location,
      intl,
      rtl,
      setHeaderParams,
      uiParams,
      filterVal,
      setFilterVal,
      clearFilterVal,
    } = this.props;
    const { isSingleBuilding, firstBuildingId, allProjectViewMode } = this.state;

    let URL = match.url.endsWith('/') ? match.url : match.url + '/';
    let query = this.props.getNested(['location', 'search'], '');
    let buildingId = navigationParams.getNested(['buildingId'], '_');
    let floorId = navigationParams.getNested(['floorId'], '_');
    let unitId = navigationParams.getNested(['unitId'], '_');
    let contentType = navigationParams.getNested(['contentType'], '_');
    let locationId = unitId == '_' ? (floorId == '_' ? buildingId : floorId) : unitId;
    let locationType = unitId == '_' ? (floorId == '_' ? 'building' : 'floor') : 'unit';

    let defaultBuildingId = isSingleBuilding ? firstBuildingId : ALL_BUILDINGS_ID;
    let selectedProjectId = match.getNested(['params', 'selectedProjectId']);
    let urlLocationParams = (buildingId == '_' ? defaultBuildingId : buildingId) + '/' + floorId + '/' + unitId;
    
    let onlySideNav = uiParams.get('onlySideNav');
    let sideBarWidth = theme.sidebarWidth;
    let itemsMode = null;
    if (location && location.search) {
      let urlQueryParams = new URLSearchParams(location.search);
      let urlFilter;
      if (urlQueryParams) urlFilter = urlQueryParams.get('itemType');

      itemsMode = urlFilter || 'all';
    }

    if (this.props.match.isCurrentPage('locationContainerPage')) {
      this.props.history.push(URL + urlLocationParams + query);
    }

    let buildingSidebarStyle = onlySideNav
      ? {
          marginTop: theme.headerHeightSecondary,
          height: 'calc(100vh - ' + (theme.headerHeight + theme.headerHeightSecondary) + 'px)',
        }
      : {};
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          backgroundColor: theme.backgroundColorBright,
          width: '100%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            height: 'calc(100vh - ' + (theme.headerHeight + (onlySideNav ? 0 : theme.headerHeightSecondary)) + 'px)',
            flexDirection: 'row',
            display: 'flex',
          }}>
          <SidebarBuilding
            rtl={rtl}
            intl={intl}
            style={buildingSidebarStyle}
            location={location}
            selectedProjectId={selectedProjectId}
            contentType={contentType}
            showBadges={
              contentType == 'issues' ||
              contentType == 'records' ||
              contentType == 'safety' ||
              contentType == 'drawings'
            }
            filteredPosts={filteredPosts}
          />
          <div
            style={{
              height: '100%',
              width: 'calc(100vw - ' + sideBarWidth + 'px)',
              flex: 1,
            }}>
            <Outlet
              context={{
                setHeaderParams: setHeaderParams,
                objectId: locationId,
                contentType: contentType,
                allProjectViewMode: allProjectViewMode,
                filteredPosts: filteredPosts,
                currViewPosts: currViewPosts,
                locationsAggregationView: true,
                itemsMode: itemsMode,
                data: filteredPosts,
                allPosts: currViewPosts,
                filterVal: filterVal,
                setFilterVal: setFilterVal,
                clearFilterVal: clearFilterVal,
                locationId: locationId,
                locationType: locationType,
                intl: intl,
                locationTitle: getFullLocationTitle(this.props),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const enhance = compose(
  injectIntl,
  withRouterHOC,
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      headerTitle: state.ui.headerTitle,
      rtl: state.app.rtl,
      uiParams: state.ui.uiParams,
    }),
    { setHeaderTitle, track }
  )
);
export default enhance(LocationContainerPage);
