
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { splitLogsToVisits, getEmployeesPresenceStatus, getEmployeesPresenceStatusColor, ENTRANCE, EXIT, msToHoursDurationString } from '../../siteControl/funcs';
import _ from 'lodash';
import moment from 'moment-timezone';

import siteControlMessages from '../../siteControl/siteControlMessages';

import getPlatformText from '../platformComponents/PlatformText';
import getPlatformContainer from '../platformComponents/PlatformContainer';
import getPlatformTheme from '../../platformTheme';
import { platformActions } from '../../platformActions';

const DEFAULT_TIME_VALUE = "?";
const PRESENCE_INDICATOR_WRAPPER_WIDTH = 5;

const EmployeesPresenceLogs = props => {
  const { logs, shouldSquash = false, withTopSeparator, shouldDisablePresenceIndicator, bigFontSize, smallFontSize } = props;
  const { Container, styles } = useMemo(getPlatformComponents, []);

  const visits = useMemo(() => splitLogsToVisits(logs, shouldSquash), [logs, shouldSquash]);

  let allLogsIndex = -1;
  let logsComponent;

  if (!_.isEmpty(visits)) {
    const sortedDates = _.sortBy(_.keys(visits));

    logsComponent = _.map(sortedDates, (dayTS) => {
      const todaysVisits = _.get(visits, [dayTS], []);

      if (!todaysVisits.length) {
        allLogsIndex++;
        return (
          <PresenceIndicatorWrapper key={`EmployeePresenceLog_${dayTS}_${allLogsIndex}`} disabled >
            <NoEntrance dayTS={dayTS} withTopSeparator={Boolean(allLogsIndex !== 0)} bigFontSize={bigFontSize} smallFontSize={smallFontSize} />
          </PresenceIndicatorWrapper>
        );
      }

      return _.map(todaysVisits, (innerLogs, index, arr) => {
        const isLastLog = Boolean(index === arr.length - 1);
        allLogsIndex++;
        return (
          <PresenceIndicatorWrapper
            key={`EmployeePresenceLog_${dayTS}_${allLogsIndex}`}
            logs={logs}
            dayTS={dayTS}
            disabled={shouldDisablePresenceIndicator} >
            <EmployeePresenceLog
              withSeparator={Boolean(allLogsIndex !== 0)}
              logs={innerLogs}
              dayTS={dayTS}
              isLastLog={isLastLog}
              bigFontSize={bigFontSize}
              smallFontSize={smallFontSize}
            />
          </PresenceIndicatorWrapper>
        );
      });
    });
  }
  return <>
    {
      Boolean(logsComponent) &&
      <Container style={withTopSeparator ? styles.withSeparator : undefined}>
        {logsComponent}
      </Container>
    }
  </>;
};


const EmployeePresenceLog = injectIntl((props) => {
  const { Container, styles } = useMemo(getPlatformComponents, []);
  const { logs, dayTS, withSeparator, isLastLog, bigFontSize, smallFontSize, intl } = props;
  const { entrance: _entrance, exit: _exit, } = logs || {};

  const hoursFormat = intl.formatMessage(siteControlMessages.presenceLogs.hoursFormat);
  const entrance = useMemo(() => (_entrance ? moment(_entrance).format(hoursFormat) : null), [_entrance]);
  const exit = useMemo(() => (_exit ? moment(_exit).format(hoursFormat) : null), [_exit]);
  const total = useMemo(() => msToHoursDurationString(_exit - _entrance), [_exit, _entrance]);
  
  const memoizedContainerStyle = useMemo(() => _.assign({}, styles.employeePresenceLog, Boolean(withSeparator) && styles.withSeparator), [withSeparator]);
  
  return (
    <Container style={memoizedContainerStyle}>
      <Day ts={dayTS} bigFontSize={bigFontSize} smallFontSize={smallFontSize} />
      <EntranceAndExit value={{ entrance, exit }} isLastLog={isLastLog} ts={dayTS} bigFontSize={bigFontSize} smallFontSize={smallFontSize} />
      <TotalTimeOnSite value={total} bigFontSize={bigFontSize} smallFontSize={smallFontSize} />
    </Container>
  );
});


const dateFormat = 'DD MMM'; //platformActions.isRTL() ? 'DD MMM' : 'MMM DD';
export const Day = ({ ts, textStyle, dayTitleStyle, dayDateStyle, containerStyle, bigFontSize, smallFontSize, ignoreTimezone = false }) => {
  const { Container, Text, styles } = useMemo(getPlatformComponents, []);

  const momentTs = useMemo(() => ignoreTimezone ? moment(Number(ts)).utc() : moment(Number(ts)), [ts, ignoreTimezone]);
  const dayTitle = useMemo(() => momentTs?.format('dd'), [momentTs]);
  const date = useMemo(() => momentTs?.format(dateFormat), [momentTs]);

  const memoizedContainerStyle = useMemo(() => _.assign({}, styles.dayContainer, containerStyle), [containerStyle]);
  const memoizedDayTitleStyle = useMemo(() => _.assign({}, styles.dayTitle, Boolean(textStyle) && textStyle, dayTitleStyle, Boolean(bigFontSize) && { fontSize: bigFontSize }), [textStyle, dayTitleStyle, bigFontSize]);
  const memoizedDateStyle = useMemo(() => _.assign({}, styles.dayDate, Boolean(textStyle) && textStyle, dayDateStyle, Boolean(smallFontSize) && { fontSize: smallFontSize }), [textStyle, dayDateStyle, smallFontSize]);

  return (
    <Container style={memoizedContainerStyle}>
      <Text style={memoizedDayTitleStyle}>{dayTitle}</Text>
      <Text style={memoizedDateStyle}>{date}</Text>
    </Container>
  );
};


const EntranceAndExit = ({ value = {}, isLastLog, ts, smallFontSize }) => {
  const { Container, Text, styles, theme } = useMemo(getPlatformComponents, []);
  
  const isToday = useMemo(() => moment(Number(ts)).isSame(Date.now(), 'day'), [ts]);
  
  const innerComponents = [ENTRANCE, EXIT]
    .map((eventType, index) => {
      const itemsValue = _.get(value, [eventType]);
      let messageIfMissing = siteControlMessages.presenceLogs.missing;
      let extraStyle = {};
      if (isToday && isLastLog && eventType === EXIT && !itemsValue) {
        messageIfMissing = siteControlMessages.presenceLogs.presentOnSite;
        extraStyle.color = theme.brandSuccess;
      }
      const memoizedEntranceAndExitTitleStyle = useMemo(() => _.assign({}, styles.entranceAndExitTitle, styles.withLighterTextColor, Boolean(smallFontSize) && { fontSize: smallFontSize }), [smallFontSize]);
      const memoizedEntranceAndExitValueStyle = useMemo(() => _.assign({}, styles.entranceAndExitValue, !itemsValue && styles.entranceAndExitMissingValue, extraStyle, Boolean(smallFontSize) && { fontSize: smallFontSize }), [smallFontSize]);

      return (
        <Container key={`log_${ts}_${index}`} style={styles.entranceAndExit}>
          <Text style={memoizedEntranceAndExitTitleStyle}>{siteControlMessages.presenceLogs[eventType]}</Text>
          <Text style={memoizedEntranceAndExitValueStyle}>{itemsValue || messageIfMissing}</Text>
        </Container>
      );
    });

  return (
    <Container style={styles.entranceAndExitContainer}>{innerComponents}</Container>
  );
};



const TotalTimeOnSite = ({ value: _value, smallFontSize, bigFontSize }) => {
  const { Container, Text, styles } = useMemo(getPlatformComponents, []);
  const value = _.isNil(_value) ? DEFAULT_TIME_VALUE : _value;
  const memoizedTotalTimeValueStyle = useMemo(() => _.assign({}, styles.totalTimeValue, Boolean(bigFontSize) && { fontSize: bigFontSize }), [bigFontSize]);
  const memoizedTotalTimeLabelStyle = useMemo(() => _.assign({}, styles.totalTimeLabel, styles.withLighterTextColor, Boolean(smallFontSize) && { fontSize: smallFontSize }), [smallFontSize]);
  return (
    <Container style={styles.totalTimeContainer}>
      <Text style={memoizedTotalTimeValueStyle}>{value}</Text>
      <Text style={memoizedTotalTimeLabelStyle}>{siteControlMessages.presenceLogs.hours}</Text>
    </Container>
  );
};


const NoEntrance = ({ dayTS, withTopSeparator, bigFontSize, smallFontSize }) => {
  const { Container, Text, styles } = useMemo(getPlatformComponents, []);
  const memoizedEmployeePresenceLogStyle = useMemo(() => {
    return _.assign({},
      styles.employeePresenceLog,
      styles.noEntranceContainer,
      Boolean(withTopSeparator) && styles.withSeparator,
      Boolean(bigFontSize) && { fontSize: bigFontSize }
    );
  }, [withTopSeparator, bigFontSize]);
  const memoizedNoEntranceTextStyle = useMemo(() => {
    return _.assign({},
      styles.noEntranceText,
      styles.withLighterTextColor,
      Boolean(smallFontSize) && { fontSize: smallFontSize }
    );
  }, [bigFontSize]);

  return (
    <Container style={memoizedEmployeePresenceLogStyle}>
      <Day ts={dayTS} textStyle={styles.withLighterTextColor} bigFontSize={bigFontSize} />
      <Text style={memoizedNoEntranceTextStyle}>{siteControlMessages.presenceLogs.noEntrance}</Text>
      <Container style={{ flex: 1 }} />
    </Container>
  );
};

const PresenceIndicatorWrapper = ({ children, logs, dayTS, disabled }) => {
  const { Container } = useMemo(getPlatformComponents, []);
  const presenceStatus = useMemo(() => disabled ? null : getEmployeesPresenceStatus({ logs, ts: dayTS }), [logs, dayTS, disabled]);
  const color = useMemo(() => getEmployeesPresenceStatusColor(presenceStatus), [presenceStatus]);

  if (disabled)
    return children;

  return (
    <Container style={{ borderLeftColor: color, borderLeftWidth: PRESENCE_INDICATOR_WRAPPER_WIDTH }}>
      {children}
    </Container>
  );
};

export default injectIntl(EmployeesPresenceLogs);

const getPlatformComponents = () => {
  const theme = getPlatformTheme();
  return {
    Text: getPlatformText(),
    Container: getPlatformContainer(),
    theme,
    styles: getStyles(theme),
  };
};

const getStyles = theme => {
  const isWebPlatform = platformActions.app.getPlatform() === 'web';
  const lighterBackgroundColor = `${theme.backgroundColor}70`;

  return {
    employeePresenceLog: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...isWebPlatform
        ? {
          height: theme.rowHeight,
          alignItems: 'stretch',
          paddingRight: theme.padding,
        }
        : {
          padding: theme.padding,
          paddingRight: 3 * theme.padding,
          paddingLeft: 3 * theme.padding,
        }
    },
    withSeparator: {
      borderTopWidth: isWebPlatform ? theme.lineHeight : theme.separatorHeight,
      borderTopColor: theme.darkSeparatorColor,
      borderTopStyle: 'solid',
    },
    noEntranceContainer: {
      justifyItems: 'space-evenly',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: lighterBackgroundColor,
      ...isWebPlatform && {
        height: theme.rowHeight,
      }
    },
    noEntranceText: {
      flex: 3,
      fontSize: theme.fontSizeH5,
      paddingRight: theme.padding,
      paddingLeft: theme.padding,
      display: 'flex',
      [isWebPlatform ? 'justifyContent' : 'textAlign']: 'center'
    },
    dayTitle: {
      textAlign: 'center',
      fontSize: theme.fontSizeH2,
      fontWeight: 'bold'
    },
    dayDate: {
      ...isWebPlatform && {
        fontSize: theme.fontSizeH6,
      },
      textAlign: 'center'
    },
    dayContainer: {
      flex: 1,
      justifyContent: 'space-evenly',
      display: 'flex',
      flexDirection: 'column'
    },
    totalTimeContainer: {
      flex: 1,
      justifyContent: 'space-evenly',
      display: 'flex',
      flexDirection: 'column',
      ...isWebPlatform && {
        paddingRight: theme.padding,
        paddingLeft: theme.padding,
      }
    },
    totalTimeValue: {
      textAlign: 'center',
      fontSize: theme.fontSizeH4,
      fontWeight: 'bold',
      ...!isWebPlatform && {
        fontSize: theme.fontSizeH5,
      }
    },
    totalTimeLabel: {
      ...isWebPlatform && {
        fontSize: theme.fontSizeH6,
      },
      textAlign: 'center',
      fontWeight: 'bold'
    },
    entranceAndExitContainer: {
      flex: 3,
      justifyItems: 'flex-start',
      ...isWebPlatform && {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
      },
    },
    entranceAndExit: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...isWebPlatform && {
        fontSize: theme.fontSizeH6
      }
    },
    entranceAndExitTitle: {
      flex: 1,
      ...isWebPlatform
        ? {
          justifyContent: 'flex-end',
          display: 'flex',
          padding: 0,
          paddingRight: theme.padding / 2,
          paddingLeft: theme.padding / 2,
        }
        : {
          textAlign: 'right',
          padding: theme.padding / 2,
        }
    },
    entranceAndExitValue: {
      flex: 1,
      ...isWebPlatform
        ? {
          padding: 0,
          paddingRight: theme.padding / 2,
          paddingLeft: theme.padding / 2,
        }
        : {
          padding: theme.padding / 2,
          ...platformActions.app.getPlatform() === 'android' && { textAlign: 'justify' }
        },
    },
    entranceAndExitMissingValue: {
      color: theme.brandRealDanger,
      fontWeight: 'bold'
    },
    withLighterTextColor: {
      color: `${theme.infoText}95`
    }
  };
};