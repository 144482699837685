import React from "react";
import { connectContext } from "react-connect-context";
import { ProjectContext } from "../../../common/projects/contexts";
import drawingsMessages from "../../../common/drawings/drawingsMessages";
import Text from "../../components/CementoComponents/Text";
// Assets
import theme from "../../assets/css/theme";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import Image from "../../components/CementoComponents/Image";
import Date from "../../components/CementoComponents/Date";
import color from "@material-ui/core/colors/lime";
import zoomIcon from "../../assets/img/icons/search_icon.png";
import { DrawingLocation } from "./DrawingCard";

class Drawing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  toggleHover = (hover) => this.setState({ hover });

  render() {
    const { hover } = this.state;
    const {
      drawing, onImageSelect, onDrawingSelect, intl,
      isSelected, locationTitle, rtl, viewMode, isLastChild
    } = this.props;

    return viewMode === "blocks" ? (
      <div
        style={{
          ...styles.blocks.container,
          ...(Boolean(isSelected) && styles.blocks.selectedContainer),
          [rtl ? 'marginRight' : 'marginLeft']: 20
        }}
        onClick={() => onDrawingSelect({ ...drawing, locationTitle })}
        onMouseEnter={() => this.toggleHover(true)}
        onMouseLeave={() => this.toggleHover(false)}
      >

        <div style={styles.blocks.header} title={locationTitle} >
          <Text style={{ fontWeight: 700, textTransform: "uppercase", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 }}>
            {(drawing.type && drawingsMessages.subtitles[drawing.type]) ? intl.formatMessage(drawingsMessages.subtitles[drawing.type]) : ""}
          </Text>
          {Boolean(drawing.name) && <Text style={{ flex: 1, fontWeight: 400, fontSize: 12, textAlign: "center" }}>
            {drawing.name}
          </Text>}
          <TradeBadge containerStyle={{ margin: 0, justifyContent: "flex-end", flex: 1 }} ids={[drawing.category || 0]} />
        </div>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #bac2c9", }} >
          <div style={{ overflow: "hidden", width: 140, position: "relative" }}>
            <Image imageStyle={styles.image} src={drawing.uriThumbnail || drawing.uri || ""} />
            {Boolean(hover) &&
              (<div style={styles.zoomIcon} onClick={() => onImageSelect?.(drawing)} >
                <img src={zoomIcon} style={{ height: 50, width: 50 }} />
              </div>)
            }
          </div>
        </div>

        <div style={{ ...styles.blocks.footer, overflow: "hidden", }}>
          {Boolean(drawing.title) && <Text>
            {drawing.title}
          </Text>}
          {/* <DrawingLocation drawing={drawing} style={{ display: "inline-block", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }} props={this.props}/> */}
        </div>
      </div >
    ) : (
        <div
          style={{
            ...styles.lines.container,
            borderBottom: (isLastChild ? 0 : theme.borderLineHeaderInfo),
            ...(Boolean(isSelected) && styles.lines.selectedContainer)
          }}
          onClick={() => onDrawingSelect({ ...drawing, locationTitle })}
          onMouseEnter={() => this.toggleHover(true)}
          onMouseLeave={() => this.toggleHover(false)}
        >
          <div style={{ overflow: "hidden", width: 100, position: "relative", [rtl ? 'marginLeft' : 'marginRight']: 37, ...styles.image }}>
            <Image imageStyle={{}} src={drawing.uriThumbnail || drawing.uri || ""} />
            {Boolean(hover) &&
              (<div style={{ ...styles.zoomIcon, border: 0 }} onClick={() => onImageSelect?.(drawing)} >
                <img src={zoomIcon} style={{ height: 50, width: 50 }} />
              </div>)}
          </div>
          <div style={{ width: "100%", padding: " 0px 10px" }}>
            <div style={styles.lines.header} title={locationTitle} >
              <Text style={{ textTransform: "uppercase" }}>
                <span style={{ fontWeight: 700, [rtl ? "marginLeft" : "marginRight"]: 10 }}>
                  {intl.formatMessage(drawingsMessages.subtitles[drawing.type]) + " "}

                </span>
                {locationTitle}
              </Text>
              <TradeBadge containerStyle={{ margin: 0, justifyContent: "flex-end" }} ids={[drawing.category]} />
            </div>
            <div style={styles.lines.footer}>
              <Text>
                {drawing.title}
              </Text>
              <Text style={{ fontWeight: 400, fontSize: 12 }}>
                {drawing.name || ""}
              </Text>
            </div>
          </div>
        </div >

      );
  }
};

const styles = {
  blocks: {
    container: {
      cursor: "pointer",
      display: "grid",
      gridTemplateRows: "40px 157px 95px",
      width: 318,
      height: 294,
      marginBottom: 20,
      borderRadius: "6px 0 6px 6px",
      border: theme.borderLineHeaderInfo,
      backgroundColor: theme.backgroundColorBright
    },
    selectedContainer: {
      cursor: "default",
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 20px 2px",
      border: ` 2px solid ${theme.brandPrimary}`,
      backgroundColor: theme.backgroundColorSelected
    },
    header: {
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      fontFamily: "Helvetica Neue - Roman",
      borderBottom: "1px solid #bac2c9",
      color: theme.headerColorDark
    },
    footer: {
      padding: "20px 27px 28px 18px"
    },
  },
  lines: {
    container: {
      width: "100%",
      height: 109,
      display: "flex",
      cursor: "pointer",
      paddingTop: 14,
      paddingBottom: 14,
      marginRight: 36,
      marginLeft: 36

    },
    selectedContainer: {
      cursor: "default",
      marginRight: 0,
      marginLeft: 0,
      paddingRight: 36,
      paddingLeft: 36,
      borderTop: ` 1px solid ${theme.brandPrimary}`,
      borderBottom: ` 1px solid ${theme.brandPrimary}`,

      backgroundColor: theme.backgroundColorSelected
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Helvetica Neue - Roman",
      color: theme.headerColorDark
    },
    footer: {
      padding: "5px 10px 15px10px",
    }
  },
  image: {
    overFlow: "hidden",
    objectFit: "contain",
    backgroundColor: theme.backgroundColorBright,
    border: theme.borderLineHeaderInfo,
  },
  zoomIcon: {
    cursor: "pointer",
    position: "absolute",
    zIndex: theme.zIndexes.drawingZoomIcon,
    height: "100%",
    width: "100%",
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: theme.backgroundColorBright,
    border: theme.borderLineHeaderInfo,
    opacity: 0.7,
    top: 0,
  }
};

export default connectContext(ProjectContext.Consumer)(Drawing);
