import React, { useState } from 'react';
import { InfoBox } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';
import Marker from './Marker';

const useStyles = makeStyles(() => ({
	boxStyle: {
		width: 350,
		borderRadius: 8,
		cursor: 'pointer',
		boxShadow: '0px 0px 0px 0px grey',
		transition: 'box-shadow 200ms linear',
		'&:hover': {
			boxShadow: '0px 0px 8px 0px grey',
		},
	},
}));

const MarkerWithInfo = ({ position, isActive, markerProps = {}, infoBoxProps = {}, rtl, children }) => {
	const [shouldShowInfoBox, setShouldShowInfoBox] = useState(false);

	const styles = useStyles();

	const onMouseOver = () => {
		setShouldShowInfoBox(true);
	};

	const onMouseOut = () => {
		setShouldShowInfoBox(false);
	};
	
	return (
		<>
			<Marker
				visible
				clickable
				position={position}
				draggable={false}
				animation='DROP'
				{...markerProps}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			/>
			{shouldShowInfoBox || isActive ? (
				<InfoBox
					position={position}
					options={{
						pixelOffset: rtl ? { height: -310, width: -365 } : { height: -310, width: 15 },
						closeBoxURL: '',
						boxClass: styles.boxStyle,
						enableEventPropagation: false,
						disableAutoPan: true,
					}}
					closeBoxUrl={''}
					{...infoBoxProps}
				>
					<div onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}>
						{children}
					</div>
				</InfoBox>
			) : null}
		</>
	);
};

export default MarkerWithInfo;
