import React from "react";
import { compose, hoistStatics } from "recompose";
import { connect } from "react-redux";
import { getCompaniesById } from "../../../common/companies/actions";

class CompanyName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      companyName: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    const { getCompaniesById } = nextProps;

    let newStateChanges = {};
    if (!nextProps.companyId) return null;

    if (
      props.companyId != nextProps.companyId ||
      props.getNested(["companies", nextProps.companyId, "name"]) !=
        nextProps.getNested(["companies", nextProps.companyId, "name"])
    ) {
      newStateChanges.companyName = nextProps.getNested([
        "companies",
        nextProps.companyId,
        "name",
      ]);
      if (!nextProps.getNested(["companies", nextProps.companyId]))
        getCompaniesById({ [nextProps.companyId]: nextProps.companyId });
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  render() {
    const { companyName } = this.state;
    const { style } = this.props;

    if (companyName) return <div style={style}>{companyName}</div>;
    else return null;
  }
}

const enhance = compose(
  connect(
    (state) => ({
      companies: state.companies.map,
    }),
    { getCompaniesById }
  )
);
export default enhance(CompanyName);
