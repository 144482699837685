import { Map, OrderedMap } from 'immutable';
import { DEFAULT_SYSTEM_LANGUAGE } from './app/constants';
import { getAppState } from './configureMiddleware';
import { platformActions } from './platformActions';
require('immutable-js-debuglog');
var _                       = require('lodash');
const newMode = false;


///////////////////////////////////////////////////////
////////////////////// getNested //////////////////////
///////////////////////////////////////////////////////
Map.prototype.getNested = function(path, defaultVal) {
  return baseGetNested(this, path, defaultVal)
}
OrderedMap.prototype.getNested = function(path, defaultVal) {
  return baseGetNested(this, path, defaultVal)
}
Object.defineProperty(Object.prototype, 'getNested', {
  value:  function(path, defaultVal) { return baseGetNested(this, path, defaultVal) },
  enumerable: false
});
Object.defineProperty(Object.prototype, 'isValDiff', {
  value:  function(otherObject, pathArray, defaultVal) { return (baseGetNested(this, pathArray, defaultVal) != baseGetNested(otherObject, pathArray, defaultVal)) },
  enumerable: false
});

String.prototype.baseFileName = function (delimiter) {
  return this.substr(0,this.lastIndexOf(delimiter)) || this + "";
}

function baseGetNested(obj, path, defaultVal) {
  if (newMode)
    return baseGetNested2(obj, path, defaultVal);
    var ret = obj;
        
    try {
      for (var i = 0; i < path.length; i++) {
				let currPath = path[i];
				if (currPath == 'getTitle')
					return ret.getCementoTitle ? ret.getCementoTitle() : defaultVal;
        if ((ret[currPath]) || (ret[currPath] === 0) || (ret[currPath] === false))
          ret = ret[currPath];
        else if (ret.get && ((ret.get(currPath)) || (ret.get(currPath) === 0) || (ret.get(currPath) === false)))
          ret = ret.get(currPath);
        else 
          return defaultVal;
      }
    }
    catch (err) {
      return defaultVal;
    } 
    
		//return (typeof ret === 'function') ? ret() : ret;
		return ret
}


///////////////////////////////////////////////////////
////////////////////// getNested //////////////////////
///////////////////////////////////////////////////////
Map.prototype.getNested2 = function(path, defaultVal) {
  return this.getIn(path, defaultVal);
}
OrderedMap.prototype.getNested2 = function(path, defaultVal) {
  return this.getIn(path, defaultVal);
}
Object.defineProperty(Object.prototype, 'getNested2', {
  value:  function(path, defaultVal) { return baseGetNested2(this, path, defaultVal) },
  enumerable: false
});
Object.defineProperty(Object.prototype, 'isValDiff2', {
  value:  function(otherObject, pathArray, defaultVal) { return (baseGetNested2(this, pathArray, defaultVal) != baseGetNested2(otherObject, pathArray, defaultVal)) },
  enumerable: false
});

Object.defineProperty(Object.prototype, 'getCementoTitle', {
  value:  function(isUseDefaultLangAsFallback = true) { 
    if (!this.title)
      return '';

    let title = this.title;
    if (typeof(title) === 'object') {
      let lang = getAppState() && getAppState().app && getAppState().app.lang ? getAppState().app.lang : platformActions.app.getLang();
      title = title[lang] || (isUseDefaultLangAsFallback ? title[DEFAULT_SYSTEM_LANGUAGE] : '') || '';
    }

    return title/* this.getNested2(['title']) ? this.getNested2(['title', getAppState().getNested2(['app','lang'], platformActions.app.getLang())], '') : ''; */
  },
  enumerable: false
});

// TODO: Change this to use getCemenoTitle
Object.defineProperty(Object.prototype, 'getCementoStringByLang', {
  value:  function() { 
    let currLang = getAppState().getNested2(['app','lang']);
    let ret = this.getNested2([currLang]) || this.getNested2([platformActions.app.getLang()]);
    if (ret)
      return ret;
    ret = Object.keys(this).length ? Object.values(this)[0] : '';
    return ret;
  },
  enumerable: false
});

function baseGetNested2(obj, path, defaultVal) {
  if (!path || path.length == 0)
    return obj || defaultVal;

  if (Boolean(obj[path[0]] && obj[path[0]].getIn)) {
    let mainPath =path[0];
    path.shift();
    let ret = obj[mainPath].getIn(path, defaultVal);
  
    return ret;
  }

  if (path && path[path.length - 1] == "getTitle") {
    path.pop();
    if (path.length)
      return _.get(obj, path, defaultVal || {}).getCementoTitle();
        else
      return obj.getCementoTitle();
  }
    
  let ret =  _.get(obj, path, defaultVal);
  return ret;
}


///////////////////////////////////////////////////////
////////////////////// setNested //////////////////////
///////////////////////////////////////////////////////
Map.prototype.setNested2 = function(path, setValue) {
  return baseSetNested2(this, path, setValue)
}
OrderedMap.prototype.setNested2 = function(path, setValue) {
  return baseSetNested2(this, path, setValue)
}
Object.defineProperty(Object.prototype, 'setNested2', {
  value:  function(path, setValue) { return baseSetNested2(this, path, setValue) },
  enumerable: false
});

function baseSetNested2(obj, path, setValue) {
  if (!path)
    return null;
  
  let ret = _.setWith(_.clone(obj), path, setValue, _.clone);
  return ret;
}


///////////////////////////////////////////////////////
////////////////////// setNested //////////////////////
///////////////////////////////////////////////////////
Map.prototype.setNested = function(path, setValue) {
  return baseSetNested(this, path, setValue)
}
OrderedMap.prototype.setNested = function(path, setValue) {
  return baseSetNested(this, path, setValue)
}
Object.defineProperty(Object.prototype, 'setNested', {
  value:  function(path, setValue, useLoadsh) { return baseSetNested(this, path, setValue, useLoadsh) },
  enumerable: false
});

function baseSetNested(obj, path, setValue, useLoadsh) {
  if (newMode || useLoadsh)
  return baseSetNested2(obj, path, setValue);
  if (!path)
    return null;

  if (path.length > 1) {
    let a = obj.getNested([path[0]])
    let editedValue = baseSetNested(a || {}, path.slice(1), setValue);
    if (obj.set) {
      obj = obj.set(path[0], editedValue)
    }
    else {
      obj = Object.assign({}, obj);
      obj[path[0]] = editedValue; //Object.assign({}, editedValue)
    }
    return obj;
  }
  else {
    let ret = null;
    if (obj.set)
      ret = obj.set(path[0], setValue);
    else {
      ret = Object.assign({}, obj)
      ret[path[0]] = setValue;
    }
    return ret
  }
}

///////////////////////////////////////////////////////
/////////////////// removeNested //////////////////////
///////////////////////////////////////////////////////
/* 
Object.defineProperty(Object.prototype, 'removeNested', {
  value: function removeNested(pathOrPaths, maxDepthReached = false) { return baseRemoveNested(this, pathOrPaths, maxDepthReached) },
  enumerable: false,
});

function baseRemoveNested(obj, pathOrPaths, maxDepthReached) {
  if (!pathOrPaths)
    return obj;

  if (Array.isArray(pathOrPaths[0])){
    if (maxDepthReached) return obj;
    for (let p of pathOrPaths) {
      obj = obj.removeNested(p, true);
    }
    return obj;
  }
  return _.omit(obj, pathOrPaths.join('.'));
} */


///////////////////////////////////////////////////////
//////////////////////// loop /////////////////////////
///////////////////////////////////////////////////////
Map.prototype.loopEach = function(func) {
  return baseForeach(this, func)
}
OrderedMap.prototype.loopEach = function(func) {
  return baseForeach(this, func)
}
Object.defineProperty(Object.prototype, 'loopEach', {
  value:  function(func) { return baseForeach(this, func) },
  enumerable: false
});

// If func returns FALSE during the iteration - the iteration will stop
function baseForeach(collection, func) {

  if (collection) {
    try {
      if (Array.isArray(collection)) {
        for (let i = 0; i < collection.length; i++) {
          if (func(i, collection[i], collection) === false)
            break;
        }
      } 
      else if (Map.isMap(collection) || OrderedMap.isOrderedMap(collection)) {
        collection.forEach((v, k) => func(k,v,collection));
      }
      else {
        let keysSet = Object.keys(collection)
        for (var i = 0; i < keysSet.length; i++) {
          let key = keysSet[i];
          let value = collection[key];
          if (func(key, value, collection) === false)
            break;
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  }
}


// ///////////////////////////////////////////////////////
// ////////////////// "interfaces get" ///////////////////
// ///////////////////////////////////////////////////////
// // TODO: Can be moved to permissions
// Object.defineProperty(Object.prototype, 'getCompanyId', {
//   value:  function(typeName) { 
//     switch (this.__proto__._name || typeName) {
//       case "company": return this.id;
//       case "member": return this.companyId;
//       case "user": return this.companyId;
//       case "post": return this.taggedCompanies;
//       default: return null;
//     }
//   },
//   enumerable: false
// });

// Object.defineProperty(Object.prototype, 'getTrades', {
//   value:  function(typeName) { 
//     switch (this.__proto__._name || typeName) {
//       case "checklistItem": return this.trade;
//       case "company": return this.trades;
//       case "member": return this.trades;
//       case "user": return this.trades;
//       case "post": return this.trade;
//       default: return null;
//     }
//   },
//   enumerable: false
// });

// Object.defineProperty(Object.prototype, 'getOwner', {
//   value:  function(typeName) { 
//     switch (this.__proto__._name || typeName) {
//       case "post": return this.owner;
//       default: return null;
//     }
//   },
//   enumerable: false
// });

function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

String.prototype.replaceAll = function(find, replace) {
  return this.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}


// safeToJs
Map.prototype.safeToJS = function(func) {
  return baseSafeToJs(this)
}
OrderedMap.prototype.safeToJS = function() {
  return baseSafeToJs(this)
}
Object.defineProperty(Object.prototype, 'safeToJS', {
  value:  function() { return baseSafeToJs(this) },
  enumerable: false
});

function baseSafeToJs(obj) {
  return obj && obj.toJS && typeof(obj.toJS) === 'function' ? obj.toJS() : obj;
}

// Only to keep in sync with common layer and native
Object.defineProperty(Object.prototype, "realmToObject", {
  value: function () {
    return this;
  },
  writable: true,
  configurable: true,
  enumerable: false,
});
