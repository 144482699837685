import { Navigate } from 'react-router-dom';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';

export default {
  path: 'settings',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics/members'} />,
    },
    {
      path: 'analytics/:reportId',
      element: (
        <PropertyAnalytics
          key={'company_PropertyAnalytics_settings_analytics'}
          contentType={'settings'}
          section={'analytics'}
        />
      ),
    },
  ],
};
