import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: "New Project",
    id: 'projects.newProject.title'
  },
  subTitle_1: {
    defaultMessage: "1. Address and name",
    id: 'newProject.subtitle.1'
  },
  subTitle_2: {
    defaultMessage: "2. Project type",
    id: 'newProject.subtitle.2'
  },
  subTitle_3: {
    defaultMessage: "3. Project image",
    id: 'newProject.subtitle.3'
  },
  subTitle_4: {
    defaultMessage: "4. Project size",
    id: 'newProject.subtitle.4'
  },  
  clientNamePlaceholder: {
    defaultMessage: "Client's Name",
    id: 'projects.newProject.clientNamePlaceholder'
  },
  address: {
    defaultMessage: "Project's Address",
    id: 'projects.newProject.address'
  },
  addressPlaceholder: {
    defaultMessage: 'Street name and number',
    id: 'projects.newProject.addressPlaceholder'
  },
  cityPlaceHolder: {
    defaultMessage: 'Neighborhood and city',
    id: 'projects.newProject.cityPlaceholder'
  },
  save: {
    defaultMessage: 'Save',
    id: 'projects.newProject.save'
  },
  addProject: {
    defaultMessage: 'ADD PROJECT',
    id: 'projects.newProject.addProject'
  },
  noMembers: {
    defaultMessage: 'No members',
    id: 'projects.newProject.noMembers'
  },
  projectMembers: {
    defaultMessage: 'Project Members',
    id: 'projects.newProject.projectMembers'
  },
  selectedMembers: {
    defaultMessage: 'Selected Members:',
    id: 'projects.newProject.selectedMembers'
  },
  projectName: {
    defaultMessage: "Project's Name",
    id: 'projects.newProject.projectName'
  },
  projectNamePlaceHolder: {
    defaultMessage: 'Give the project a name',
    id: 'projects.newProject.projectNamePlaceHolder'
  },
  editFloors: {
    defaultMessage: 'Edit floors',
    id: 'projects.editProject.editFloors'
  },
  editUnitsAndFloors: {
    defaultMessage: 'Edit units',
    id: 'projects.editProject.editUnitsAndFloors'
  },
  addUnits: {
    defaultMessage: 'Add units to {buildingTitle}',
    id: 'projects.editProject.addUnits'
  },
  units: {
    defaultMessage: 'Units',
    id: 'projects.editProject.units'
  },
  floors: {
    defaultMessage: 'Floors',
    id: 'projects.editProject.floors'
  },
  updateNameAndAddress: {
    defaultMessage: 'Update name and address',
    id: 'projects.editProject.updateNameAndAddress'
  },
  updateImage: {
    defaultMessage: 'Update image',
    id: 'projects.editProject.updateImage'
  },
  projectMembers: {
    defaultMessage: 'Project Members',
    id: 'projects.newProject.projectMembers'
  },
  singleHouse: {
    defaultMessage: 'Single House',
    id: 'projects.newProject.singleHouse'
  },
  singleHouseSubTitle: {
    defaultMessage: 'Single or multi family house',
    id: 'projects.newProject.singleHouseSubTitle'
  },
  houseComplex: {
    defaultMessage: 'House complex',
    id: 'projects.newProject.houseComplex'
  },
  houseComplexSubTitle: {
    defaultMessage: 'Multi-family community or multiple independent houses',
    id: 'projects.newProject.houseComplexSubTitle'
  },
  building: {
    defaultMessage: 'Building',
    id: 'projects.newProject.building'
  },
  locationTypes: {
    buildings: {
      defaultMessage: 'Buildings',
      id: 'projects.newProject.locationsTypes.buildings'
    },
    units: {
      defaultMessage: 'Units',
      id: 'projects.newProject.locationsTypes.units'
    },
    floors: {
      defaultMessage: 'Floors',
      id: 'projects.newProject.locationsTypes.floors'
    },
  },
  buildingSubTitle: {
    defaultMessage: 'Single multi store, multi apartment building',
    id: 'projects.newProject.buildingSubTitle'
  },
  buildingComplex: {
    defaultMessage: 'Multiple buildings',
    id: 'projects.newProject.buildingComplex'
  },
  buildingComplexSubTitle: {
    defaultMessage: 'Building complex',
    id: 'projects.newProject.buildingComplexSubTitle'
  },
  highestFloor: {
    defaultMessage: 'Highest Floor',
    id: 'projects.newProject.highestFloor'
  },
  highestFloorSubTitle: {
    defaultMessage: 'What floor is the roof?',
    id: 'projects.newProject.highestFloorSubTitle'
  },
  lowestFloor: {
    defaultMessage: 'Lowest Floor',
    id: 'projects.newProject.lowestFloor'
  },
  lowestFloorSubTitle: {
    defaultMessage: 'What floor is the lowest basement or entrance?',
    id: 'projects.newProject.lowestFloorSubTitle'
  },
  floorsWizardNote: {
    defaultMessage: "After the project is created, you can easily add units and manage each unit's details",
    id: 'projects.newProject.floorsWizardNote'
  },
  maxBlocks: {
    defaultMessage: 'Blocks amount',
    id: 'projects.newProject.maxBlocks'
  },
  maxBlocksSubTitle: {
    defaultMessage: 'How many block are in the projet (Each block may contain more then 1 unit)?',
    id: 'projects.newProject.maxBlocksSubTitle'
  },
});
