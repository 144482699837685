import React from "react";
import _ from "lodash";

// Components
import { PieChart } from "react-minimal-pie-chart";
import TradeBadge from "../../components/CementoComponents/TradeBadge";
import Text from "../../components/CementoComponents/Text";

// Icons
import trash from "../../assets/img/icons/trash.png";

// Others
import * as instancesStatus from "../../../common/checklistItemsInstances/clItemInstanceStatus";
import checklistItemMessages from "../../../common/checklistItems/checklistItemMessages";
import theme from "../../assets/css/theme";
import "../../assets/css/collapse.css";
import InnerCollapsible from "../../components/CementoComponents/InnerCollapsible.js";

class CollapsableChecklistSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleCollective = this.handleCollective.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.state = {};
  }

  handleCollective(e) {
    const { onCollectiveAction, defaultActionStatus } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onCollectiveAction(defaultActionStatus);
  }

  onDelete() {
    const { onDelete } = this.props;

    if (onDelete) onDelete();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      checklistSection,
      isEditMode,
      isSelected,
      isFocused,
      enableCollectiveAction,
      open,
      minMode,
    } = this.props;
    const {
      checklistSection: nextChecklistSection,
      isEditMode: nextIsEditMode,
      isSelected: nextIsSelected,
      isFocused: nextIsFocused,
      enableCollectiveAction: nextEnableCollectiveAction,
      open: nextOpen,
      minMode: nextMinMode,
    } = nextProps;

    if (
      (checklistSection.items || []).length !==
        (nextChecklistSection.items || []).length ||
      checklistSection.title !== nextChecklistSection.title ||
      checklistSection.locationTitle !== nextChecklistSection.locationTitle ||
      enableCollectiveAction !== nextEnableCollectiveAction ||
      isEditMode !== nextIsEditMode ||
      isSelected !== nextIsSelected ||
      isFocused !== nextIsFocused ||
      minMode !== nextMinMode ||
      open !== nextOpen ||
      !_.isEqual(checklistSection.trades, nextChecklistSection.trades) ||
      !_.isEqual(checklistSection.counters, nextChecklistSection.counters) ||
      Boolean(
        (nextChecklistSection.items || []).filter(
          (item, i) =>
            item.item.ordinalNo !==
            ((checklistSection.items[i] || {}).item || {}).ordinalNo
        ).length
      )
    )
      // Changed in items ordinalNo
      return true;

    return false;
  }

  render() {
    const { children, checklistSection, minMode, defaultActionStatus, intl, enableCollectiveAction, isEditMode, isSelected, isFocused, onClick, open, footerComponent, footerContainerStyle } = this.props;
    
    const pieColors = [];
    let emptyCounter = (checklistSection.counters || {}).total || 0;
    Object.keys(checklistSection.counters || {}).forEach((c) => {
      if (c == "total" || c == "grade") return;
      let value = checklistSection.counters[c] || 0;
      let color = instancesStatus.CHECKLIST_STATUS_COLORS[c];
      emptyCounter -= value;
      pieColors.push({ value, color });
    });
    if (pieColors.length == 0 && emptyCounter == 0)
      // There is no values in the pie and there is items in the checklist
      pieColors.push({
        value: 1,
        color:
          instancesStatus.CHECKLIST_STATUS_COLORS[
            instancesStatus.CLI_STATUS_CONFIRMED
          ],
      });
    // Fill the rest of the pie with count of empty values
    else pieColors.push({ value: emptyCounter, color: theme.brandNeutral });

    return (
      <InnerCollapsible
        closeOnSecondClick
        open={open}
        title={checklistSection.title}
        extraTitle={checklistSection.locationTitle}
        isEditMode={isEditMode}
        isSelected={isSelected}
        isFocused={isFocused}
        onClick={(!isSelected && onClick) ? onClick : undefined}
        leftSideComp={
            <PieChart
              data={pieColors}
              style={{ transform: "translateX(0.13em)" }}
            />
        }
        rightSideComp={
          <>
          {Boolean(checklistSection.trades) &&
            <TradeBadge ids={Object.keys(checklistSection.trades)} mode={minMode?'min':'auto'} maxBadges={minMode?3:null}/>}
          </>
        }
        rightSideOpenComp={
            Boolean(enableCollectiveAction && defaultActionStatus) && (
              <div
                style={{
                  padding: "0px " + theme.padding + "px",
                  margin: "0px " + theme.verticalMargin + "px",
                  borderRadius: 15,
                  border: "1px solid " + theme.brandPrimary,
                  backgroundColor: theme.backgroundColorBright,
                }}
                onClick={this.handleCollective}
              >
                <Text
                  style={{ whiteSpace: "nowrap", color: theme.brandPrimary }}
                  values={{
                    status: intl.formatMessage(
                      checklistItemMessages.statusActions[defaultActionStatus]
                    ),
                  }}
                >
                  {checklistItemMessages.statusApplyAll}
                </Text>
              </div>
            )

        }
        editIcons={[
          { icon: trash, onClick: this.onDelete, style: { height: "18px" } },
        ]}
        footerContainerStyle={footerContainerStyle}
        footerComponent={footerComponent}
      >
        {children}
      </InnerCollapsible>
    );
  }
}

export default CollapsableChecklistSection;
