import { Navigate } from 'react-router-dom';
import PropertyAnalytics from '../../../../views/Properties/PropertyAnalytics';
import CompanySafetyTable from '../../../../views/CompanySafety/CompanySafetyTable';

export default {
  path: 'safety',
  children: [
    {
      index: true,
      element: <Navigate to={'analytics'} />,
    },

    {
      path: 'analytics',
      element: <CompanySafetyTable />,
    },
    {
      path: 'analytics/:reportId',
      element: (
        <PropertyAnalytics key={'company_PropertyAnalytics_safety'} contentType={'safety'} section={'analytics'} />
      ),
    },
  ],
};
