import { schemasInfo, uploadObjectsDispatcher } from '../lib/offline-mode/config';
import { debugParams, fetchByTS, replaceMaxUpdateTSIfNeeded, notifyLongFunctionDuration } from '../lib/utils/utils';
import { platformActions } from "../platformActions";
import _ from 'lodash';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { getNewId } from '../lib/api';

export const CREATE_EQUIPMENT 		  		= 'CREATE_EQUIPMENT';
export const GET_EQUIPMENT   		  			= 'GET_EQUIPMENT';
export const END_EQUIPMENT_LISTENER   	= 'END_EQUIPMENT_LISTENER';
export const UPSERT_EQUIPMENT    				= 'UPSERT_EQUIPMENT';
export const DELETE_EQUIPMENT         	= 'DELETE_EQUIPMENT'
export const EQUIPMENT_DONE_LOADING			= 'EQUIPMENT_DONE_LOADING';
export const CLEAN_CACHED_EQUIPMENT    	= 'CLEAN_CACHED_EQUIPMENT';
export const GET_NEW_ID    							= 'GET_NEW_ID';
export const SAVE_EQUIPMENT             = 'SAVE_EQUIPMENT';

export function getEquipment(viewer, projectId, cleanAll, uid) {
	return ({ dispatch, realmInstance, lokiInstance, platformActions }) => {

		if (cleanAll)
			setEquipmentValues({}, 0, projectId, realmInstance, lokiInstance, platformActions, cleanAll);

		const saveFunc = (_data, isRevoke) => {
			if (debugParams.disableFetchByTSSave)
				return;
			return setEquipmentValues(_data, undefined, projectId, realmInstance, lokiInstance, platformActions, isRevoke);
		};

		fetchByTS(
			{ scope: 'projects', scopeId: projectId },
			{ 
				resourceName: 'equipment',
				firebasePath: `equipment/projects/${projectId}`,
				getLastUpdateTS: () => getLastUpdateTS(realmInstance, lokiInstance, projectId),
				isObjectUseTransactionalDB: true,
			},
			saveFunc,
			viewer,
		).then(() => dispatch({ type: EQUIPMENT_DONE_LOADING, payload: { scopeId: projectId } }));


		return {
			type: GET_EQUIPMENT,
			payload: { projectId }
		};
	};
}

export const endEquipmentListener = (projectId) => {
	ClientServerConnectivityManagerInstance.unregisterService({
		scope: 'projects',
		scopeId: projectId,
		subject: 'equipment',
	});

	return {
		type: END_EQUIPMENT_LISTENER,
		payload: { projectId },
	}
}

function getLastUpdateTS(realmInstance, lokiInstance, scopeId) {

  var lastUpdateTS = 0;

	if (platformActions.app.getPlatform() == "web") {
		let lastUpdateTSObj = {};
    let lastUpdateTSObjArr = lokiInstance.getCollection('equipment').chain().find({projectId: scopeId, isLocal: { '$ne' : true }}).simplesort("updatedTS", true).limit(1).data();
    if (lastUpdateTSObjArr.length) lastUpdateTSObj = lastUpdateTSObjArr[0];
    lastUpdateTS = lastUpdateTSObj.updatedTS;
	}
	else {
		lastUpdateTS = realmInstance.equipment.objects('equipment1').filtered('projectId = "' + scopeId + '"').max('updatedTS');
	}

  return lastUpdateTS || 0;
}

function setEquipmentValues(equipment, lastUpdateTS, projectId, realmInstance, lokiInstance, platformActions, cleanAll) {
	if (platformActions.app.getPlatform() == "web")
		saveToLoki(equipment, undefined, projectId, lokiInstance, undefined, cleanAll);
	else
		notifyLongFunctionDuration(() => saveToRealm(equipment, undefined, projectId, realmInstance, undefined, cleanAll),
			`saveToRealm ~ The function took too long`, `equipment`);
}

export async function removeEquipmentFromLoki(lokiInstance) {
	await lokiInstance.getCollection('equipment').cementoFullDelete();
}

function saveToRealm(equipment, lastUpdateTS, projectId, realmInstance, ignoreTimestamp, cleanAll) {
	if (!cleanAll && Object.keys(equipment || {}).length == 0) return;

	equipment = Object.values(equipment || {}).sort((equipmentA, equipmentB) => (equipmentA.updatedTS || 0) > (equipmentB.updatedTS || 0) ? -1 : 1);
	let currBatchMaxLastUpdateTS = _.get(equipment, [0, 'updatedTS'], 0);

	let realm = realmInstance.equipment;
	realm.write(() => {
		if (cleanAll) {
			let allEquipment = realm.objects('equipment1').filtered(`projectId = "${projectId}"`);
			realm.delete(allEquipment);
		}
		const deletedIdList = []

		equipment.forEach(curr => {
			if (curr && curr.id) {
				if (!curr.isDeleted)
					realm.create('equipment1', { ...curr.realmToObject(), projectId_objId: projectId + "_" + curr.id, projectId }, true);
				else {
					deletedIdList.push(curr.id)
				}
			}
			else
				console.warn('equipment missing ID'); // TODO: Send to bugsnag
		});
		if (deletedIdList?.length) {
			try {
				const allDeletedInstances = realm
					.objects('equipment1')
					.filtered(`projectId = "${projectId}" AND id IN {"${deletedIdList.join('", "')}"}`);

				realm.delete(allDeletedInstances);
			} catch (error) {
				console.log('____ERRROR', error);
			}
		}
		replaceMaxUpdateTSIfNeeded(currBatchMaxLastUpdateTS, realm, 'equipment1', `projectId = "${projectId}"`);
	});
}

function saveToLoki(equipment = {}, lastUpdateTS, projectId, lokiInstance, ignoreTimestamp, cleanAll) {
	if (!cleanAll && Object.keys(equipment || {}).length == 0) return;
	let allEquipment = [];
	let deletedIds = [];

	equipment = Object.values(equipment).sort((equipmentA, equipmentB) => equipmentA.updatedTS > equipmentB.updatedTS ? -1 : 1);
	
	(equipment).forEach(curr => {
		curr.isDeleted ? 
			deletedIds.push(curr.id) : 
			allEquipment.push({...curr.realmToObject(), projectId })
	});
	if (cleanAll) {
		lokiInstance.getCollection('equipment').cementoDelete({ projectId });
	}
	if (deletedIds.length > 0) lokiInstance.getCollection('equipment').cementoDelete({ projectId, id: { '$in' : deletedIds }});
	lokiInstance.getCollection('equipment').cementoUpsert(allEquipment);
}

export function removeEquipmentFromRealm(realmInstance) {
	let realm = realmInstance.equipment;
	let allEquipment = realm.objects('equipment1');
	realm.write(() => {
		realm.delete(allEquipment);
	});
}

export function upsertEquipment(inEquipment, projectId) {
	return ({ firebaseDatabase, removeEmpty, dispatch }) => {

		if (!inEquipment) return;

		let equipmentToSave = { ...inEquipment.realmToObject(), projectId };
		if (!equipmentToSave.id) {
			const id = getNewId();
			equipmentToSave.id = id;
		}

		equipmentToSave = removeEmpty(equipmentToSave, 'upsertEquipment');

		dispatch(saveEquipment(projectId, [equipmentToSave]));

		return {
			type: UPSERT_EQUIPMENT,
			payload: { equipment: equipmentToSave }
		};
	};
}

export function deleteEquipment(equipmentId, projectId) {
	return ({ dispatch }) => {
		const equipmentToSave = { id: equipmentId, isDeleted: true };
		
		dispatch(saveEquipment(projectId, [equipmentToSave]));

		return {
			type: DELETE_EQUIPMENT,
			payload: { equipment: equipmentToSave }
		};
	};
}

const saveEquipment = (projectId, newEquipment, originalEquipment = null) => {
	if (projectId && Object.values(newEquipment || {}).length) {
		newEquipment = Object.values(newEquipment).filter(Boolean).map(equipment => ({ ...equipment, projectId, projectId_objId: projectId + "_" + equipment.id }));
		
		uploadObjectsDispatcher({
			projectId,
			objectsToSave: newEquipment,
			originalObjects: originalEquipment,
			schemaInfo: schemasInfo.equipment,
		});
	}

	return {
		type: SAVE_EQUIPMENT,
		payload: { newEquipment, originalEquipment }
	}
}