import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, hoistStatics } from 'recompose';
import StandardInput from './StandardInput';
import theme from '../../assets/css/theme';
import safetyMessages from '../../../common/safety/safetyMessages';
import systemMessages from '../../../common/app/systemMessages';
import DateRangePicker from './DateRangePicker';

class TimeFilter extends Component {
	constructor(props) {
		super(props);
		this.getTimeStampFilter = this.getTimeStampFilter.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCustomDateSave = this.handleCustomDateSave.bind(this);

		let defaultFilter = Boolean(props.defaultOption && timeFilteringOptions[props.defaultOption])
			? props.defaultOption
			: defaultOption;
		const selectedTimeStampFilterId = _.get(timeFilteringOptions, [defaultFilter, 'id']);
		this.state = {
			isCustomDatesSelected: false,
			selectedTimeStampFilterOption: { [selectedTimeStampFilterId]: selectedTimeStampFilterId },
			...this.getTimeStampFilter(defaultFilter),
		};
	}

	getTimeStampFilter(timeFilter) {
		return {
			startTS: _.get(timeFilteringOptions, [timeFilter, 'getStartTS'], _.noop)(),
			endTS: _.get(timeFilteringOptions, [timeFilter, 'getEndTS'], _.noop)(),
		};
	}

	handleChange(timeFilter) {
		const { onChange } = this.props;
		const { startTS, endTS } = this.getTimeStampFilter(timeFilter);
		const selectedTimeStampFilter = timeFilteringOptions[timeFilter];
		const selectedTimeStampFilterId = _.get(timeFilteringOptions, [timeFilter, 'id']);
		const selectedTimeStampFilterOption = { [selectedTimeStampFilterId]: selectedTimeStampFilterId };

		let isCustomDatesSelected = selectedTimeStampFilter.id === 'CUSTOM_DATES';

		if (onChange && !isCustomDatesSelected) onChange(startTS, endTS, selectedTimeStampFilter);

		this.setState({
			startTS,
			endTS,
			selectedTimeStampFilterOption,
			isCustomDatesSelected
		});
	}
	
	handleCustomDateSave(newDateRange) {
		const { onChange, intl, rtl } = this.props;
		let { start: startTS, end: endTS } = newDateRange;
		const { startTS: defaultStartTS, endTS: defaultEndTS } = this.getTimeStampFilter(defaultOption);
		const dateFormatStr = intl.formatMessage(systemMessages.fullDateFormat);

		startTS = startTS || defaultStartTS;
		endTS = endTS || defaultEndTS;

		const timeFilterOption = {...timeFilteringOptions['CUSTOM_DATES']};

		timeFilterOption.tableTitle = rtl ? `${intl.formatMessage(timeFilterOption.tableTitle)} ${moment(endTS).format(dateFormatStr,)} - ${moment(startTS).format(dateFormatStr)}` : `${intl.formatMessage(timeFilterOption.tableTitle)} ${moment(startTS).format(dateFormatStr)} - ${moment(endTS).format(dateFormatStr,)}`;

		onChange?.(startTS, endTS, timeFilterOption);

		this.setState({
			startTS: startTS,
			endTS: endTS
		});
	}

	render() {
		const { selectedTimeStampFilterOption, isCustomDatesSelected } = this.state;
		const { intl, rtl, isClearable } = this.props;
		let input;
		if (isCustomDatesSelected)
			input = ( 
				<div style={{ padding: theme.padding, maxWidth: 250, flex: 1 }}>
					<DateRangePicker
						displayPicker
						onClear={() => this.handleChange(defaultOption)}
						onCancel={() => this.setState({ isCustomDatesSelected: false })}
						onChange={(newRange) => this.handleCustomDateSave(newRange)}
					/>
				</div>
			);
		else 
			input = (
				<StandardInput
					containerStyle={{ maxWidth: 250 }}
					type={'SelectionList'}
					key={'timeStampFilterSelection'}
					rtl={rtl}
					value={selectedTimeStampFilterOption}
					options={Object.values(timeFilteringOptions).map(option => ({
						...option,
						title: intl.formatMessage(option.title),
					}))}
					noBorder={true}
					isClearable={isClearable}
					onChange={val => this.handleChange(_.values(val)[0])}
					innerStyle={{
						marginLeft: theme.margin,
						marginRight: theme.margin,
						border: theme.borderLineHeaderSelection,
						borderRadius: theme.borderRadius,
					}}
					valueStyle={{ paddingLeft: theme.padding, paddingRight: theme.padding }}
					isSearchable={false}
					indicatorSeparator={_.noop}
				/>
			);
		return input;
	}
}

const enhance = compose(
	connect(state => ({ rtl: state.app.rtl }), {}),
	injectIntl,
);
export default enhance(TimeFilter);

export const timeFilteringOptions = {
	CUSTOM_DATES: {
		id: 'CUSTOM_DATES',
		title: safetyMessages.safetyTable.customDates.label,
		tableTitle: safetyMessages.safetyTable.customDates.title,
	},
	TODAY: {
		id: 'TODAY',
		title: safetyMessages.safetyTable.today.label,
		tableTitle: safetyMessages.safetyTable.today.title,
		getStartTS: () => moment().startOf('day').valueOf(),
	},
	PAST_24: {
		id: 'PAST_24',
		title: safetyMessages.safetyTable.past24.label,
		tableTitle: safetyMessages.safetyTable.past24.title,
		getStartTS: () => moment().subtract(24, 'hours').valueOf(),
	},
	YESTERDAY: {
		id: 'YESTERDAY',
		title: safetyMessages.safetyTable.yesterday.label,
		tableTitle: safetyMessages.safetyTable.yesterday.title,
		getStartTS: () => moment().subtract(1, 'days').startOf('day').valueOf(),
		getEndTS: () => moment().subtract(1, 'days').endOf('day').valueOf(),
	},
	CURRENT_WEEK: {
		id: 'CURRENT_WEEK',
		title: safetyMessages.safetyTable.currentWeek.label,
		tableTitle: safetyMessages.safetyTable.currentWeek.title,
		getStartTS: () => moment().startOf('week').valueOf(),
	},
	PAST_7_DAYS: {
		id: 'PAST_7_DAYS',
		title: safetyMessages.safetyTable.past7Days.label,
		tableTitle: safetyMessages.safetyTable.past7Days.title,
		getStartTS: () => moment().subtract(7, 'days').startOf('day').valueOf(),
	},
	LAST_WEEK: {
		id: 'LAST_WEEK',
		title: safetyMessages.safetyTable.lastWeek.label,
		tableTitle: safetyMessages.safetyTable.lastWeek.title,
		getStartTS: () => moment().subtract(1, 'weeks').startOf('week').valueOf(),
		getEndTS: () => moment().subtract(1, 'weeks').endOf('week').valueOf(),
	},
	CURRENT_MONTH: {
		id: 'CURRENT_MONTH',
		title: safetyMessages.safetyTable.currentMonth.label,
		tableTitle: safetyMessages.safetyTable.currentMonth.title,
		getStartTS: () => moment().startOf('month').valueOf(),
	},
	PAST_30_DAYS: {
		id: 'PAST_30_DAYS',
		title: safetyMessages.safetyTable.past30Days.label,
		tableTitle: safetyMessages.safetyTable.past30Days.title,
		getStartTS: () => moment().subtract(30, 'days').valueOf(),
	},
	LAST_MONTH: {
		id: 'LAST_MONTH',
		title: safetyMessages.safetyTable.lastMonth.label,
		tableTitle: safetyMessages.safetyTable.lastMonth.title,
		getStartTS: () => moment().subtract(1, 'months').startOf('month').valueOf(),
		getEndTS: () => moment().subtract(1, 'months').endOf('month').valueOf(),
	},
	PROJECT_BEGINNING: {
		id: 'PROJECT_BEGINNING',
		title: safetyMessages.safetyTable.projectBeginning.label,
		tableTitle: safetyMessages.safetyTable.projectBeginning.title,
		getStartTS: () => 0,
	},
};

export const defaultOption = 'CURRENT_WEEK';
