import React, {  } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import SideBarNav from "../../views/Menus/SideBarNav";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import analyticsMessages from "../../../common/analytics/analyticsMessages";

// core components
import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import theme from "../../assets/css/theme";
import { saveUIParams, draftValidator } from "../../../common/ui/actions";
import {connectContext} from 'react-connect-context';
import {ProjectContext} from '../../../common/projects/contexts';
import * as permissionsFunc from '../../../common/permissions/funcs';
import {encodeFilterToSearch} from '../../app/funcs';
import {FILTER_URL_KEY} from '../../app/constants';
import { withSavedMenus } from './useSavedMenus';
import { getUniqueId } from '../../../common/app/funcs.js';
import withRouterHOC from "../Router/util/withRouterHOC.js";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.id = getUniqueId("HeaderLinks");
    this.state = {
      openAvatar: false,
      miniActive: true,
      routesArray: [],
      sideBarOpen: false,
    };

    this.activeRoute = this.activeRoute.bind(this);
    this.convertRouteUrl = this.convertRouteUrl.bind(this);
    this.getRoutesArray = this.getRoutesArray.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      props.permissions !== nextProps.permissions ||
      props.location !== nextProps.location ||
      props.menus !== nextProps.menus ||
      props.isCementoTeamViewer !== nextProps.isCementoTeamViewer ||
      props.getNested(["navigationParams", "containerUrl"]) !==
        nextProps.getNested(["navigationParams", "containerUrl"]) || 
      props.getNested(['configurations', 'features']) !== nextProps.getNested(['configurations', 'features'])
    ) {
      newStateChanges.routesArray = this.getRoutesArray(nextProps);
    }

    const curr = Boolean(
      nextProps.getNested(["headerParams", "sideBarParams", "open"]) ||
        nextProps.getNested(["headerParams", "sideBarParams", "alwaysOpen"])
    );
    if (this.state.sideBarOpen !== curr && curr !== null)
      newStateChanges.sideBarOpen = curr;
    if (Object.keys(newStateChanges)?.length)
      this.setState(newStateChanges);
  }

  activeRoute(routeName, routeIndex, nextProps) {
    const { location, routes } = nextProps;
    let routeUrl = location.search
      ? routeName.replace(location.search, "")
      : routeName;
    let otherRouteContainSameUrl = routeUrl;
    (routes || []).map((r, index) => {
      if (index == routeIndex) return null;
      const { baseRoute } = this.convertRouteUrl(r, nextProps);
      otherRouteContainSameUrl =
				baseRoute.length > otherRouteContainSameUrl.length && location.pathname.includes(baseRoute)
					? baseRoute
					: otherRouteContainSameUrl;
    });
    let isActive = location.pathname.includes(routeUrl) && otherRouteContainSameUrl == routeUrl;
    return isActive;
  }

  getRoutesArray(nextProps) {
    const {
      routes, onRouteSelected, intl,
      navigationParams, isCementoTeamViewer, viewer,
      selectedProjectId, configurations
    } = nextProps;
    let activeRoute = null;
    let routesArray = [];
    (routes || []).forEach((prop, index) => {
      if (
        prop.redirect ||
        (prop.hide && !configurations.getNested(['features', prop.contentType, "isActive"], false) && !configurations.getNested(['features', prop.contentType, "web", "isVisible"], false) && !isCementoTeamViewer) ||
        (prop.cementoTeamOnly && !isCementoTeamViewer)
      )
        return null;
      if (
        prop.hideCondition &&
        navigationParams.getNested([prop.hideCondition.key]) === prop.hideCondition.value
      )
        return null;

      if (prop.checkPermissions?.length) {
        const isAllowed = permissionsFunc.getActionPermissions(viewer, selectedProjectId, ...prop.checkPermissions);

        if (!isAllowed) {
          return null;
        }
      }

      const { routeTo, baseRoute } = this.convertRouteUrl(prop, nextProps);

      let route = {
				...prop,
				routeTo,
				baseRoute,
				routeText: typeof prop.name == 'object' ? intl.formatMessage(prop.name) : prop.name,
				isActive: this.activeRoute(baseRoute, index, nextProps),
			};

      activeRoute = route.isActive ? route : activeRoute;
      routesArray.push(route);
    });

    if (activeRoute && onRouteSelected) onRouteSelected(activeRoute);
    return routesArray;
  }

  convertRouteUrl(routeObject, nextProps) {
		const { navigationParams, isSecondary, menus } = nextProps;

		let url = routeObject.path;
		let routeTo = url
			.replace(':selectedProjectId', navigationParams.getNested(['selectedProjectId']))
			.replace(':selectedCompanyId', navigationParams.getNested(['selectedCompanyId']));

		let baseRoute = routeTo.replace('/:section', '').replace('/:reportId', '');

		if (routeObject.contentType) {
			const { section, reportId } = getSpecificRouteParams({
				contentType: routeObject.contentType,
				menus,
			});
			baseRoute = baseRoute.replace(':contentType', routeObject.contentType);
			routeTo = routeTo
				.replace(':contentType', routeObject.contentType)
				.replace('/:section', section)
				.replace('/:reportId', reportId);
		} else {
			routeTo = routeTo.replace('/:section', '').replace('/:reportId', '');
		}

		let containerUrl = navigationParams.getNested(['containerUrl']) || '';
		routeTo = isSecondary
			? (containerUrl.endsWith('/') ? containerUrl.slice(0, containerUrl.length - 1) : containerUrl) + routeTo
			: routeTo;

		return { routeTo, baseRoute };
	}

  render() {
    const {
      classes,
      isSecondary,
      rtl,
      routingMode,
      navigationParams,
      headerParams,
      menus,
      draftValidator,
      selectedFav,
    } = this.props;
    const { routesArray, sideBarOpen } = this.state;

    let contentType =
    navigationParams?.getNested(["contentType"]) || this.props.contentType;
    let sideBarWidth = theme.sidebarWidth;
    let params = headerParams || {};

    if (!isSecondary)
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "inherit",
            height: "inherit",
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: theme.zIndexes.headerLinksSecondary,
          }}
        >
          <div
            style={{
              display: "flex",
              height: "inherit",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {Boolean(
              (!isSecondary && routesArray && routesArray.length > 0) ||
                isSecondary
            ) &&
              routesArray.map((curr, index) => {
                if (!curr) return null;
                let isActiveRoute = curr.isActive;
                const navLinkClasses = cx({
                  [classes.itemLink]: true,
                  [classes.itemLinkActive]: isActiveRoute,
                });
                const itemText =
                  classes.itemText +
                  " " +
                  cx({
                    [classes.itemTextActive]: isActiveRoute,
                    [classes.itemTextSecondary]: isSecondary,
                    [classes.itemTextSecondaryActive]:
                      isSecondary && isActiveRoute,
                  });

                let Link;
                Link = routingMode ? (
                  <div
                    key={this.id + index}
                    onClick={() =>
                      draftValidator(
                        this.props.history.push(
                          "/main/projectContainerPage/" +
                          navigationParams.getNested(["selectedProjectId"]) +
                            "/" +
                            curr.path
                        )
                      )
                    }
                    key={index}
                    to={curr.routeTo}
                    style={{ cursor: "pointer", height: "100%" }}
                    className={navLinkClasses}
                  >
                    <div className={itemText}>{curr.routeText}</div>
                  </div>
                ) : (
                  <NavLink
                    key={this.id + index}
                    to={curr.routeTo}
                    style={{ height: "100%" }}
                    className={navLinkClasses}
                  >
                    <div className={itemText}>{curr.routeText}</div>
                  </NavLink>
                );

                return Link;
              })}
          </div>
        </div>
      );

    return (
      <div style={{ zIndex: theme.zIndexes.headerLinks }}>
        {Boolean(params.headerComponent) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "inherit",
              height: theme.headerHeightSecondary,
              width: "calc(100vw - " + sideBarWidth + "px)",
              [rtl ? "marginRight" : "marginLeft"]: sideBarWidth,
              backgroundColor: theme.backgroundColorBright,
              boxShadow: (rtl ? -3 : 3) + "px 0 8px rgba(0, 0, 0, 0.3)",
              zIndex: theme.zIndexes.headerLinksSecondary,
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                fontWeight: 500,
                color: theme.headerColorDark,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                position: "relative",
              }}
            >
              {params.headerComponent}
            </div>
          </div>
        )}
        {Boolean(
          menus && menus[contentType] && !(params.sideBarParams || {}).hidden
        ) && (
          <SideBarNav
            options={menus[contentType]}
            id={contentType}
            disabled={
              !menus[contentType]
              //   || Object.keys(menus[contentType]).length < 2
            }
            caption={analyticsMessages.reports}
            selectedValue={selectedFav}
            onOpenOrClose={(currNavState) => {
              this.setState({ sideBarOpen: currNavState });
            }}
            independentMode={!params.headerComponent}
            style={{ top: theme.headerHeight }}
            onChange={(selectedFilterSet) => {
              let scope = navigationParams.getNested(["scope"], "project");
              let scopeId;
              if (scope == "company")
                scopeId = navigationParams.getNested(["selectedCompanyId"]);
              if (scope == "project")
                scopeId = navigationParams.getNested(["selectedProjectId"]);
              draftValidator(() => {
                let newUrl = `/main/${scope}ContainerPage/${scopeId}/`;
                if (selectedFilterSet.contentType && selectedFilterSet.contentType != contentType)
                  newUrl += `${selectedFilterSet.contentType}/${selectedFilterSet.type}/${selectedFilterSet.subjectType}`;
                else if (selectedFilterSet.type == "contentRoot")
                  newUrl += `${contentType}/`;
                else if (selectedFilterSet.type == "dashboardView")
                  newUrl += `${contentType}/dashboard`;
                else if (
                  selectedFilterSet.type === "none" &&
                  selectedFilterSet.subjectType
                )
                  newUrl += `${selectedFilterSet.subjectType}`;
                else if (selectedFilterSet.type != "buildingView")
                  newUrl += `${contentType}/${selectedFilterSet.type ? selectedFilterSet.type : 'analytics'}/${selectedFilterSet.id}`;
                else newUrl += `${contentType}/locationContainerPage`;

                if (selectedFilterSet.query) {
                  newUrl += `?${selectedFilterSet.query}`;
                }
                if (selectedFilterSet.values?.filter) {
                  const paramsToIgnore = ['itemType']
                  const newSearch = encodeFilterToSearch(selectedFilterSet.values?.filter || {}, location.search, FILTER_URL_KEY, paramsToIgnore);

                  if (newUrl.includes('?')) {
                    const lastChar = newUrl.slice(-1);
                    newUrl += (lastChar !== '?' && lastChar !== '&')
                      ? `&${newSearch}`
                      : newSearch;
                  }
                  else {
                    newUrl += `?${newSearch}`;
                  }
                }
                
                this.props.history.push(newUrl);
              });
            }}
            beforeChange={(selectedFilterSet) => {
              if (
                selectedFilterSet.type != "dashboardView" &&
                selectedFilterSet.type != "buildingView"
              )
                this.props.saveUIParams({
                  unselectCellAndBackOrCloseSideCard: new Date(),
                });
            }}
            {...(params.sideBarParams || {})}
            open={sideBarOpen}
          />
        )}
      </div>
    );
  }
}

const getSpecificRouteParams = ({ contentType, menus }) => {
	const SUPPORTED_TYPES = ['info'];
	let section = '';
	let reportId = '';
  
  if (!SUPPORTED_TYPES.includes(contentType) || !menus?.[contentType]) {
		return { section, reportId };
	}

	try {
		const [sectionId, sectionData] = Object.entries(menus[contentType])[0];
		section = `/${sectionId}`;
		reportId = `/${sectionData?.options[0]?.id}`;
	} catch (error) {
		console.warn(`Can't get section and report URL params for ${contentType}`, error);
	}
	return { section, reportId };
};

const styles = {
  itemText: {
    color: theme.brandNeutral,
    margin: 0,
    padding: 0,
    fontSize: "14px",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    position: "relative",
    display: "block",
    whiteSpace: "nowrap",
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
  },
  itemTextSecondary: {
    color: "#2e231d",
  },
  itemLink: {
    padding: 5,
    transition: "all .33s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    position: "relative",
    color: theme.backgroundColorBright,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(200, 200, 200, 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.paddingSize * 1.3,
    paddingLeft: theme.paddingSize * 1.3,
    minWidth: 80,
  },
  itemTextActive: {
    color: theme.backgroundColorBright,
    fontWeight: theme.bold,
  },
  itemTextSecondaryActive: {
    color: theme.brandPrimary,
  },
  itemLinkActive: {
    borderBottom: "3px solid " + theme.brandPrimary,
  },
};

const enhance = compose(
  withStyles(theme.combineStyles(headerStyle, styles)),
  withRouterHOC,
  withSavedMenus,
  connect(
    (state) => ({
      rtl: state.app.rtl,
      headerTitle: state.ui.headerTitle,
      uiParams: state.ui.uiParams,
      permissions: state.permissions.map,
    }),
    { saveUIParams, draftValidator }
  ),
  connectContext(ProjectContext.Consumer)
);
export default enhance(HeaderLinks);
