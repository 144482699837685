import React from "react";
import { injectIntl } from 'react-intl';
import systemMessages from '../../../common/app/systemMessages';
import moment from 'moment';
import { safeFormatMessage } from '../../../common/app/funcs'

class Date extends React.Component {
  render() {
    const { children, values, intl, format, ...rest } = this.props;
    let dateFormat = systemMessages.shortDateFormat;
    if (format?.custom)
      dateFormat = format.custom;
    else {
      if (format?.withTime)
        dateFormat = systemMessages.longDateFormat;
      if (format?.detailed)
        dateFormat = systemMessages.detailedDateFormat;
      if (format?.onlyDate)
        dateFormat = systemMessages.onlyDateFormat;
    }
    
    let inner = null;
    if (children && typeof children == 'number' || typeof children == 'string')
      inner = moment(children).format(safeFormatMessage(intl, dateFormat));
    
    return <div {...rest}>{inner}</div>
    
  }
}

export default injectIntl(Date);
