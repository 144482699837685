import { defineMessages } from 'react-intl';

export default defineMessages({
  connectedIssues: {
    defaultMessage: 'Linked tasks',
    id: 'checklist.itemInstance.connectedIssues'
  },
  log: {
    defaultMessage: 'Activity Log',
    id: 'checklist.itemInstance.log'
  },
  location: {
    defaultMessage: 'Location',
    id: 'checklist.itemInstance.location'
  },
  trade: {
    defaultMessage: 'Trade',
    id: 'checklist.itemInstance.trade'
  },  
  period: {
    defaultMessage: 'Period',
    id: 'checklist.itemInstance.period'
  },
  connectedChecklist: {
    defaultMessage: 'Connected checklist',
    id: 'checklist.itemInstance.connectedChecklist'
  },
  description: {
    defaultMessage: 'Description',
    id: 'checklist.itemInstance.description'
  },
  extraDescription: {
    defaultMessage: 'Extra Info',
    id: 'checklist.itemInstance.extraDescription'
  },
  stage: {
    "defaultMessage": "Stage",
    "id": "checklist.itemInstance.stage"
  },
  readPermissions: {
    "defaultMessage": "Company Read Permissions",
    "id": "checklist.itemInstance.readPermissions"
  },
  requirements: {
    extraData: {
      "defaultMessage": "Properties required",
      "id": "checklist.itemInstance.requirements.extraData"
    },
    img: {
      "defaultMessage": "Image required",
      "id": "checklist.itemInstance.requirements.img"
    },
    desc: {
      "defaultMessage": "Description required",
      "id": "checklist.itemInstance.requirements.desc"
    },
    file: {
      "defaultMessage": "Attachment required",
      "id": "checklist.itemInstance.requirements.file"
    },
    drawing: {
      "defaultMessage": "Drawing required",
      "id": "checklist.itemInstance.requirements.drawing"
    }, 
    signature: {
      "defaultMessage": "Signature required",
      "id": "checklist.itemInstance.requirements.signature"
    },
  },
  requirementsSupplied: {
    extraData: {
      "defaultMessage": "Item has properties",
      "id": "checklist.itemInstance.requirementsSupplied.extraData"
    },
    img: {
      "defaultMessage": "Item has an attachment image",
      "id": "checklist.itemInstance.requirementsSupplied.img"
    },
    desc: {
      "defaultMessage": "Item has description",
      "id": "checklist.itemInstance.requirementsSupplied.desc"
    },
    file: {
      "defaultMessage": "Item has an attachment file",
      "id": "checklist.itemInstance.requirementsSupplied.file"
    },
    drawing: {
      "defaultMessage": "Item has an attachment drawing",
      "id": "checklist.itemInstance.requirementsSupplied.drawing"
    },
    signature: {
      "defaultMessage": "Item has signature",
      "id": "checklist.itemInstance.requirements.signature"
    },
  },
  isPartial: {
    "defaultMessage": "Enable partial button",
    "id": "checklist.itemInstance.isPartial"
  },
  isIrrelevant: {
    "defaultMessage": "Enable irrelevant button",
    "id": "checklist.itemInstance.isIrrelevant"
  },
  weight: {
    "defaultMessage": "Weight",
    "id": "checklist.itemInstance.weight"
  },
  clearChecklist: {
    "defaultMessage": "Clear checklist",
    "id": "checklist.itemInstance.clearChecklist"
  },
  showSimilarItems: {
    defaultMessage: 'Show all similar items in the project',
    id: 'checklist.itemInstance.showSimilarItems'
  },
  exportItemsReport: {
    defaultMessage: 'Export similar items report',
    id: 'checklist.itemInstance.exportItemsReport'
  },
  addDocumentationReport: {
    defaultMessage: 'Attach a record',
    id: 'checklist.itemInstance.addDocumentationReport'
  },
  createTask: {
    defaultMessage: 'Create a task',
    id: 'checklist.itemInstance.createTask'
  },
  checkNumTitle: {
    "defaultMessage": "Check #{num} in checklist",
    "id": "checklist.itemInstance.checkNumTitle"
  },
  extraData: {
    edit: {
      defaultMessage: "Edit checklist item's properties",
      id: 'checklist.extraData.editExtraData'
    },
    save: {
      defaultMessage: "Save properties",
      id: 'checklist.extraData.saveExtraData'
    },
    missingExtraDataAlertTitle: {
      defaultMessage: 'Checklist Item could not be confirmed',
      id: 'checklists.extraData.missingExtraDataAlertTitle'
    },
    missingExtraDataAlertSubTitle: {
      defaultMessage: 'Some required properties were missing',
      id: 'checklists.extraData.missingExtraDataAlertSubTitle'
    },
    postMissingExtraDataAlertTitle: {
      defaultMessage: 'This post\'s checklist item could not be confirmed, some of it\'s required properties are missing',
      id: 'checklists.extraData.postMissingExtraDataAlertTitle'
    },
  },
  relevantIssues: {
    "defaultMessage": "Connected issues",
    "id": "checklist.relevant.relevantIssues"
  },
  relevantDocumentations: {
    "defaultMessage": "Connected records",
    "id": "checklist.relevant.relevantDocumentations"
  },
  relevantIssuesEmpty: {
    "defaultMessage": "No connected issues",
    "id": "checklist.relevant.relevantIssuesEmpty"
  },
  relevantDocumentationsEmpty: {
    "defaultMessage": "No connected records",
    "id": "checklist.relevant.relevantDocumentationsEmpty"
  },
  timelineEmpty: {
    "defaultMessage": "No activities yet",
    "id": "checklist.relevant.timelineEmpty"
  },
  status: {
    '99': {
      defaultMessage: 'Inspected',
      id: 'checklist.itemInstance.status.99'
    }, 
    '100': {
      defaultMessage: 'Verified',
      id: 'checklist.itemInstance.status.100'
    }, 
    '125': {
      defaultMessage: 'Partial',
      id: 'checklist.itemInstance.status.125'
    }, 
    '150': {
      defaultMessage: 'Resolved',
      id: 'checklist.itemInstance.status.150'
    }, 
    '200': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.status.200'
    }, 
    '201': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.status.200'
    }, 
    '300': {
      defaultMessage: 'Unchecked',
      id: 'checklist.itemInstance.status.300'
    }, 
    '301': {
      defaultMessage: 'Irrelevant',
      id: 'checklist.itemInstance.status.301'
    }, 
  },
  statusDetailed: {
    '99': {
      defaultMessage: 'Inspected',
      id: 'checklist.itemInstance.statusDetailed.99'
    }, 
    '100': {
      defaultMessage: 'Verified by General Contractor',
      id: 'checklist.itemInstance.statusDetailed.100'
    }, 
    '125': {
      defaultMessage: 'Partialy verified',
      id: 'checklist.itemInstance.statusDetailed.125'
    }, 
    '150': {
      defaultMessage: 'Resolved by Sub Contractor',
      id: 'checklist.itemInstance.statusDetailed.150'
    }, 
    '200': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.statusDetailed.200'
    }, 
    '201': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.statusDetailed.200'
    }, 
    '300': {
      defaultMessage: 'Unchecked',
      id: 'checklist.itemInstance.statusDetailed.300'
    }, 
    '301': {
      defaultMessage: 'Irrelevant',
      id: 'checklist.itemInstance.statusDetailed.301'
    }, 
  },
  periods: {
    '100': {
      defaultMessage: 'Daily',
      id: 'checklist.item.period.100'
    }, 
    '200': {
      defaultMessage: 'Weekly',
      id: 'checklist.item.period.200'
    }, 
    '300': {
      defaultMessage: 'Monthly',
      id: 'checklist.item.period.300'
    }, 
    '400': {
      defaultMessage: 'Quarterly',
      id: 'checklist.item.period.400'
    }, 
    '500': {
      defaultMessage: 'On-Demand',
      id: 'checklist.item.period.500'
    }, 
  },
  periodAlert: {
    defaultMessage: 'This item hasn\'t been checked yet.\n{daysCount} Days left',
    id: 'checklist.item.periodAlert'
  },
  immediatPeriodAlert: {
    defaultMessage: 'This item hasn\'t been checked yet.\nLast Day to check',
    id: 'checklist.item.immediatPeriodAlert'
  },
  attentionAlert: {
    defaultMessage: 'Attention - click for more info',
    id: 'checklist.item.attentionAlert'
  },
  previousStatusAlert: {
    rejected: {
      defaultMessage: 'Rejected in last check',
      id: 'checklist.item.previousStatusAlert.rejected'
    }, 
    rejectedPlus: {
      defaultMessage: 'Rejected + Issue assignd in last check',
      id: 'checklist.item.previousStatusAlert.rejectedPlus'
    }, 
    rejectedWithDate: {
      defaultMessage: 'Rejected in last check at {date}',
      id: 'checklist.item.previousStatusAlert.rejectedWithDate'
    }, 
  },
  statusApplyAll: {
    defaultMessage: 'Mark all {status}',
    id: 'checklist.itemInstance.statusApplyAll'
  },
  statusActions: {
    '99': {
      defaultMessage: 'Checked',
      id: 'checklist.itemInstance.statusActions.99'
    }, 
    '100': {
      defaultMessage: 'Checked',
      id: 'checklist.itemInstance.statusActions.100'
    }, 
    '125': {
      defaultMessage: 'Partial',
      id: 'checklist.itemInstance.statusActions.125'
    }, 
    '150': {
      defaultMessage: 'Resolve',
      id: 'checklist.itemInstance.statusActions.150'
    }, 
    '200': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.statusActions.200'
    }, 
    '201': {
      defaultMessage: 'Rejected',
      id: 'checklist.itemInstance.statusActions.201'
    }, 
    '300': {
      defaultMessage: 'Uncheck',
      id: 'checklist.itemInstance.statusActions.300'
    }, 
    '301': {
      defaultMessage: 'Irrelevant',
      id: 'checklist.itemInstance.statusActions.301'
    }, 
  },
  statusActionsSubText: {
    '200': {
      defaultMessage: 'Reject and open a follow up task',
      id: 'checklist.itemInstance.statusActionsSubText.200'
    }, 
    '201': {
      defaultMessage: 'Reject and open a follow up task',
      id: 'checklist.itemInstance.statusActionsSubText.201'
    }, 
  },
  cannotConfirmMessage: {
    'title': {
      defaultMessage: 'Opened task',
      id: 'checklist.cannotConfirmMessage.errorMessage.title'
    }, 
    'message': {
      defaultMessage: 'The checklist item status cannot be confirmed with an opened tasks. Please resolve the linked tasks and try again.',
      id: 'checklist.cannotConfirmMessage.errorMessage.message'
    }, 
  },
  cannotChangeMessage: {
    'title': {
      defaultMessage: 'Opened task',
      id: 'checklist.cannotChangeMessage.errorMessage.title'
    }, 
    'message': {
      defaultMessage: 'The checklist item status cannot be changed with a task that had not been closed. Please close the linked tasks and try again.',
      id: 'checklist.cannotChangeMessage.errorMessage.message'
    }, 
  },
  cannotBulkChangeMessage: {
    title: {
      "defaultMessage": "Change all statuses",
      "id": "checklist.cannotBulkChangeMessage.errorMessage.title"
    }, 
    message: {
      "defaultMessage": "There were items which requires some documentations or properties, therefore these items have not been change",
      "id": "checklist.cannotBulkChangeMessage.errorMessage.message"
    }, 
    messageComplete: {
      "defaultMessage": "All the items already have status, therefore no items have been change",
      "id": "checklist.cannotBulkChangeMessage.errorMessage.messageComplete"
    }, 
  },
  cannotResolveMessage: {
    'title': {
      defaultMessage: 'Opened task',
      id: 'checklist.cannotResolveMessage.errorMessage.title'
    }, 
    'message': {
      defaultMessage: 'The checklist item status cannot be resolved with an opened tasks. Please resolve the linked tasks and try again.',
      id: 'checklist.cannotResolveMessage.errorMessage.message'
    }, 
  },
  toasts: {
    cannotUploadDrawing: {
      defaultMessage: "This item's status can be updated in the mobile app only, since it require drawing attachment.",
      id: 'checklist.toasts.cannotUploadDrawing'
    },
    mandatoryData: {
      defaultMessage: "In order to updated this item's status, you must provide some mandatory data.",
      id: 'checklist.toasts.mandatoryData'
    }

  },
  checkAllMandatoryItemsBeforeSignModal: { // TODO: translate spanish;
    title: {
      defaultMessage: 'Unable to sign',
      id: 'checklist.checkAllMandatoryItemsBeforeSignModal.title',
    },
    content: {
      defaultMessage: 'This checklist contains mandatory items, in order to sign please complete the checks',
      id: 'checklist.checkAllMandatoryItemsBeforeSignModal.content',
    }
  },
  signaturesUploadInProgress: { // TODO: translate spanish;
    title: {
      defaultMessage: 'Some signatures are being uploaded',
      id: 'checklist.signaturesUploadInProgress.title',
    },
    content: {
      defaultMessage: 'Please try again in a few minutes...',
      id: 'checklist.signaturesUploadInProgress.content',
    }
  },
  missingStatusesAlert: {
    title: {
      defaultMessage: 'The checklist is partially filled',
      id: 'checklist.missingStatusesAlert.title',
    },
    content: {
      defaultMessage: 'Are you sure you wish to proceed?',
      id: 'checklist.missingStatusesAlert.content',
    }
  }
});
