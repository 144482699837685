import * as appActions from '../app/actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import * as usersActions from '../users/actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import { OrderedMap, Map } from 'immutable';
import { Record } from '../transit';
import { POST_EVENTS } from './trackPosts';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record({
  didLoad: OrderedMap(),
  sync: Map(),
  lastSynced: Map(),
  isAdmin: false
}, 'posts');

const initialState = new InitialState;

export default function postReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) { 
    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['posts'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], null)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }

    case lastUpdatesActions.SYNCED + "_POSTS_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }

    case POST_EVENTS.GET_POSTS: 
      return state.setIn(['didLoad', action.getNested(['payload','projectId'])], false);
    
    case PROJECT_EVENTS.LOKI_DID_LOAD: {
      if (action.getNested(['payload','loadedCollections','posts']))
        state = state.setIn(['didLoad', action.getNested(['payload','projectId'])], action.getNested(['payload','status']));
      return state;
    }

    case POST_EVENTS.POSTS_DONE_LOADING: {
      if (!action.payload)
        return state;
      
      const { scopeId } = action.payload;
      state = state.setIn(['didLoad', scopeId], true);

      return state;
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case appActions.CLEAN_PERMISSION_BASED_DATA + "_SUCCESS":
    case REPLACE_USER + "_SUCCESS":
    case appActions.CLEAN_CACHE: {      
      state = initialState;
      return state;
    }

    case usersActions.SET_ADMIN_MODE:  {
      const { newMode } = action.payload;
      return state.set('isAdmin', newMode);
    }
  }

  return state;
}