import { platformActions } from '../../../platformActions';
import useRealmPosts from './useRealmPosts';
import useLokiPosts from './useLokiPosts';
import useMemoizedValue from '../../../hooks/useMemoizedValue';
import { useContext, useMemo } from 'react';
import { ProjectContext } from '../../../projects/contexts';
import useComments from '../../../comments/hooks/useComments';

/**
 * @typedef {'records' | 'tasks'} PostsType
 *
 * @typedef {'safety'} ContentType
 *
 * @typedef Filters
 * @property {ContentType} [contentType]
 * @property {PostsType} [postsType]
 * @property {string[]} [inIds] - specific post ids
 * @property {boolean} [ignoreContentType]
 * @param {Filters} postsFilters
 * @typedef Options
 * @property {boolean} [includeComments]
 * @param {Options} [postOptions]
 * @returns
 */
const usePosts = (postsFilters, postOptions) => {
  const isWeb = platformActions.app.isWeb();
  const { selectedProjectId } = useContext(ProjectContext);

  const { currViewPosts, currViewPostsMap, filteredPosts } = (isWeb ? useLokiPosts : useRealmPosts)(postsFilters);

  const commentsFilters = useMemoizedValue({ projectId: selectedProjectId, parentIds: postsFilters?.inIds || [] });
  const { commentsArray: currViewComments } = useComments(commentsFilters, { disabled: !postOptions?.includeComments });

  return useMemo(() => ({
    currViewPosts,
    currViewPostsMap,
    filteredPosts,
    currViewComments
  }), [currViewPosts, currViewPostsMap, filteredPosts, currViewComments]);
};

export default usePosts;

/**
 * @callback ChildrenRenderProps
 * @param {{ filteredPosts: any[], currViewPosts: any[], currViewPostsMap: { [postId: string]: any } }}
 */
/**
 *
 * @param {{ filters: Filters, children: ChildrenRenderProps }} props
 * @returns {ChildrenRenderProps}
 */
export const PostsHOC = props => {
	const postsProps = usePosts(props.filters);

	return props.children(postsProps);
};