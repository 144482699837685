import * as actions from './actions';
import { getAppState } from '../configureMiddleware';
import Title from './title';
import { Map } from 'immutable';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  map: Map(),
  didLoad: false,
}, 'titles');

const initialState = new InitialState;

export default function titlesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TITLES_LOADING + '_SUCCESS': {
      if (!state.get('didLoad')) {
        state = state.set('didLoad', true);
      }

      return state;
    }
    case actions.GET_TITLES: {
      if (!action.payload)
        return state;

      const { titles } = action.payload;    
      if (!titles) {
        return state;
      }

      var newMap = new Map();
      (titles || {}).loopEach((key, currTitle) => { 
        if (currTitle) {
          newMap = newMap.set(currTitle.id, new Title({ 
            id: currTitle.id, 
            types: currTitle.types, 
            title: currTitle.title, 
            systemTitle: currTitle.getNested(['title', 'en'],'')
          })); 
        }
      })
      return state.set('map', newMap);
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
  }
  
  return state;
}
