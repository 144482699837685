import * as React from 'react';

const BurgerMenu = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height={32}
		style={{
			enableBackground: 'new 0 0 32 32',
		}}
		width={32}
		xmlSpace='preserve'
		{...props}
	>
		<path
			d='M4 10h24a2 2 0 0 0 0-4H4a2 2 0 0 0 0 4zm24 4H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4zm0 8H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4z'
			fill={props.fill || '#e57b2f'}
		/>
	</svg>
);

export default BurgerMenu;
