import { useContext, useMemo, useState } from "react";
import { connectContext } from "react-connect-context";
import { POSTS_TYPES, POSTS_TYPES_TO_HIDE_IN_CREATION_MENU, POST_INFO_SUBJECT_NAME } from "../../../common/app/constants";
import theme from "../../../common/app/theme";
import { ProjectContext } from "../../../common/projects/contexts";
import ObjectPropertiesPage from "../Properties/ObjectPropertiesPage";
import { getPostGroupId, isMultiTypesPostsConfigured } from "../../../common/propertiesTypes/funcs";

const PostProperties = props => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    editMode,
    isCreateMode,
    mode = "card",
    postId,
    post,
    createObjectMode,
    useSections,
    onRef,
    inputsContainerStyle,
    location
  } = props;

  const { groupId: _groupId, subGroupId } = useMemo(() => {
    const searchParams = new URLSearchParams(location?.search);
    const groupId = searchParams.get('groupId') || getPostGroupId(post);
    const subGroupId = searchParams.get('subGroupId');
    return { groupId, subGroupId };
  }, [location, post]);

  const {
    propertiesTypes,
    propertiesMappings,
    selectedProjectId: projectId
  } = useContext(ProjectContext);


  const availableGroupValues = _.get(propertiesTypes, [POST_INFO_SUBJECT_NAME, 'groups', 'values']);
  const groupId = _groupId || getPostGroupId(post);
  const isMultiTypesPostsConfig = isMultiTypesPostsConfigured(projectId);

  const isGroupExist = _.some(availableGroupValues, group => group.id === groupId);
  const isGroupHaveMappedProps = _.get(propertiesMappings, [POST_INFO_SUBJECT_NAME, 'groups', groupId, 'properties'], [])
    .filter(prop => prop !== 'groups')
    .length > 0;

  const onlyLocalMocksAvailable = useMemo(() =>
    _.every(propertiesTypes?.[POST_INFO_SUBJECT_NAME], prop => prop?.isLocalMock),
    [propertiesTypes?.[POST_INFO_SUBJECT_NAME]]
  );

  const shouldHidePostProperties =
    onlyLocalMocksAvailable ||
    (isGroupExist && !isGroupHaveMappedProps) ||
    (!isGroupExist && _.some(POSTS_TYPES_TO_HIDE_IN_CREATION_MENU, group => group === groupId)) ||
    (!isMultiTypesPostsConfig && groupId !== POSTS_TYPES.ISSUE);

  if (shouldHidePostProperties) {
    return <></>;
  }

  const shouldGroupBeSelectable = !shouldHidePostProperties && !isGroupExist;
  return (
    <div style={{
      transition: "all 150ms ease 0s",
      backgroundColor: theme.backgroundColorBright,
      display: isLoaded ? 'initial' : null,
      flex: 1
    }}>
      <ObjectPropertiesPage
        initialGroupId={shouldGroupBeSelectable ? undefined : groupId}
        initialSubGroupId={shouldGroupBeSelectable ? undefined : subGroupId}
        isCreateMode={isCreateMode}
        editMode={editMode}
        hideLocalMock
        onGroupsFound={(b) => {
          setIsLoaded(b);
        }}
        mode={mode}
        objectId={postId}
        subjectName={POST_INFO_SUBJECT_NAME}
        createObjectMode={createObjectMode}
        useSections={useSections}
        useMenusScrollsBar={false}
        onRef={onRef}
        inputsContainerStyle={{
          padding: "10px 0px",
          flexDirection: "column",
          backgroundColor: theme.backgroundColorBright,
          ...(inputsContainerStyle || {})
        }}
      />
    </div>
  );
};



export default connectContext(ProjectContext.Consumer)(PostProperties);
