import { Record } from '../transit';

const Building = Record({
  createdAt: null,
  id: null,
  title: null,
  address: null,
  location: {},
  locationType: null,
  minFloor: 0,
  maxFloor: 0,
  ordinalNo: null
}, 'building');

export default Building;
