import { Map, isImmutable } from 'immutable';
import { Record } from '../transit';
import * as lastUpdatesActions from '../lastUpdates/actions';

import * as appActions from '../app/actions';
import * as actions from './actions';
import { REPLACE_USER } from '../auth/actions';
import _ from 'lodash';
import { SUBJECT_NAMES } from '../propertiesTypes/propertiesTypes';
import { PROJECT_EVENTS } from '../projects/trackProjects';

const InitialState = Record({
  sync: Map(),
  lastSynced: Map(),
  didLoad: Map(),
  recentlySigningUsers: Map()
}, 'propertiesInstances');

const initialState = new InitialState;

export default function propertiesInstancesReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState;

  switch (action.type) {
    
    case lastUpdatesActions.GET_SYNCED: {
      if (!action.payload || !action.payload.projects)
        return state;

      const { projects } = action.payload;
      _.forIn(projects, (objectTypes, projectId) => {
        let syncOptions = _.get(objectTypes, ['propertiesInstances'], {})
        let TS = syncOptions.lastUpdateTS || 1;
        let curr = state.getNested(['sync', projectId, 'lastUpdateTS'], 0)
        if (curr < TS) {
          state = state.setIn(['sync', projectId], syncOptions);
        }
      })

      return state;
    }

    case lastUpdatesActions.SYNCED + "_PROPERTIES_INSTANCES_SUCCESS": {
      if (!action.payload || !action.payload.projectId || !action.payload.lastSynced)
        return state;

      const { projectId, lastSynced } = action.payload;
      state = state.setIn(['lastSynced', projectId], lastSynced);
      return state;
    }
    case PROJECT_EVENTS.LOKI_DID_LOAD: {
      const { projectId, loadedCollections, status } = action.payload || {};
      if (!projectId || !loadedCollections.propertyInstances)
        return state;

      Object.values(SUBJECT_NAMES).forEach(subjectName => {
        state = state.setIn(['didLoad', projectId, subjectName], status);
      });

      return state;
    }

    case actions.GET_PROPERTIES_INSTANCES: {
      const { scopeId, subjectName } = action.payload || {};
      if (!scopeId || !subjectName)
        return state;
      if (!state.getIn(['didLoad', scopeId]) && !isImmutable(state.getIn(['didLoad', scopeId]))) {
        state = state.setIn(['didLoad', scopeId], Map());
      }

      return state.setIn(['didLoad', scopeId, subjectName], false);
    }

    case actions.PROPERTIES_INSTANCES_DONE_LOADING: {     
      if (!action.payload || !action.payload.scopeId)
        return state;
      const { scopeId, subjectName } = action.payload;
      if (!isImmutable(state.getIn(['didLoad', scopeId]))) {
        state = state.setIn(['didLoad', scopeId], Map());
      }

      return state.setIn(['didLoad', scopeId, subjectName], true);
    }


    case actions.END_PROPERTIES_INSTANCES_LISTENER: {
      const { scopeId, subjectName } = action.payload || {};
      if (!scopeId || !subjectName)
        return state;
      if (!isImmutable(state.getIn(['didLoad', scopeId]))) {
        state = state.setIn(['didLoad', scopeId], Map());
      }
      return state.setIn(['didLoad', scopeId, subjectName], false);
    }

    case appActions.CLEAR_ALL_DATA + '_SUCCESS':
    case REPLACE_USER + "_SUCCESS":
    case actions.CLEAN_CACHED_INSTANCES:
    case appActions.CLEAN_CACHE: {      
      state = initialState;
      return state;
    }
    case actions.SET_RECENTLY_SIGNING_USERS: {
      const { userId, projectId, signaturesContext } = action.payload;
      if (userId && projectId && signaturesContext)
        state = state.setIn(['recentlySigningUsers', projectId, signaturesContext, userId], { lastUse: Date.now() });
      return state;
    }
  }

  return state;
}

