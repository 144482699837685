import { defineMessages } from 'react-intl';

export default defineMessages({
  objectsNames: {
    drawing: {
      "defaultMessage": "drawing",
      "id": "drawing.objects.drawing"
    }
  },
  title: {
    defaultMessage: "Drawings",
    id: 'drawing.tabs.title'
  },
  notActive: {
    title: {
      defaultMessage: "This feature is for premium only", 
      id: "drawing.notActive.title"
    },
    content: {
      defaultMessage: "In the drawings screen you can manage and view drawings.\nTo start using the drawings screens, please contact Cemento team at customers@cemento.ai",
      id: "drawing.noReport.content"
    }
  },
  projectNotFullyConfigured: {
    defaultMessage: "The project is not fully configured to use this page",
    id: "drawing.notConfigured"
  },
  add: {
    defaultMessage: "Add Drawing",
    id: 'drawing.add'
  },
  changed: {
    defaultMessage: "Notice, This drawing has been changed",
    id: 'drawing.warning.changed'
  },
  loading: {
    defaultMessage: 'Loading Drawings',
    id: 'drawing.tabs.loading'
  },
  file: {
    defaultMessage: 'File',
    id: 'drawing.file.title'
  },
  subtitles: {
    unit: {
      defaultMessage: "Unit drawings",
      id: 'drawing.subtitles.units'
    },
    floor: {
      defaultMessage: "Floor drawings",
      id: 'drawing.subtitles.floors'
    },
    building: {
      defaultMessage: "Building drawings",
      id: 'drawing.subtitles.building'
    },
    general: {
      defaultMessage: "General drawings",
      id: 'drawing.subtitles.general'
    },
    tenantsRequests: {
      defaultMessage: "Tenants requests",
      id: 'drawing.subtitles.tenantsRequests'
    }

  },
  empty: {
    general: {
      defaultMessage:"No drawings found",
      id:'drawing.empty.general'
    },
    unit: {
      defaultMessage: "There are no drawing for this unit yet",
      id: 'drawing.empty.unit'
    },
    floor: {
      defaultMessage: "There are no drawing for this floor yet",
      id: 'drawing.empty.floors'
    },
    building: {
      defaultMessage: "There are no drawing for the building itself yet",
      id: 'drawing.empty.building'
    }
  },
  card: {
    title: {
      defaultMessage: "Drawing Title",
      id: 'drawing.card.title'
    },
    creationDate: {
      defaultMessage: "Creation Date",
      id: 'drawing.card.creationDate'
    },
    location: {
      defaultMessage: "Location",
      id: 'drawing.card.location'
    }
  },
  sort: {
    trade: {
      defaultMessage: 'Trade',
      id: 'drawing.sort.trade'
    },
    updateDate: {
      defaultMessage: 'Update Date',
      id: 'drawing.sort.updateDate'
    },
    type: {
      defaultMessage: 'Drawing Type',
      id: 'drawing.sort.type'
    }
  },
  locations: {
    floor: {
      defaultMessage: 'Floor',
      id: 'drawing.locations.floor'
    },
    floors: {
      defaultMessage: 'Floors',
      id: 'drawing.locations.floors'
    },
    unit: {
      defaultMessage: 'Unit',
      id: 'drawing.locations.unit'
    },
  },
  locationTypeSwitch: {
    defaultMessage: 'Are you sure you want to switch location type? Selected specific locations will be cleared.',
    id: 'drawing.locationTypeSwitch',
  },
  delete: {
    defaultMessage: 'Are you sure you want to delete this drawing?',
    id: "drawing.delete.confirm",
  },
  selectFile: {
    defaultMessage: "Select file:",
    id: 'drawing.selectFile'
  },
  locationType: {
    defaultMessage: "Location type",
    id: 'drawing.locationType'
  },
  drawingTitle: {
    defaultMessage: "Title",
    id: 'drawing.drawingTitle'
  },
  specificLocations: {
    defaultMessage: "Specific locations",
    id: 'drawing.specificLocations'
  },
  trades: {
    defaultMessage: "Trades",
    id: 'drawing.trades'
  },
  number: {
    defaultMessage: "Number",
    id: 'drawing.number'
  },
  version: {
    defaultMessage: "Version",
    id: 'drawing.version'
  },
  date: {
    defaultMessage: "Date",
    id: 'drawing.date'
  },
  generalProperties: {
    defaultMessage: "General Properties",
    id: 'drawing.generalProperties'
  }
});