import React from 'react';
import { useSelector } from 'react-redux';
import { useJsApiLoader, GoogleMap } from '@react-google-maps/api';
import { injectIntl } from 'react-intl';

const containerStyle = {
	width: '100%',
	height: '100%',
	borderRadius: '5px 5px 0 0',
};

const defaultCenter = {
	lat: 32.0905654,
	lng: 34.7926571,
};

const MapComponent = ({ children, style = {}, center, ...props }) => {
	return (
		<GoogleMap
			mapContainerStyle={{ ...containerStyle, ...style }}
			center={center || defaultCenter}
			zoom={14}
			options={{
				styles: [
					{
						'featureType': 'poi',
						'stylers': [{ 'visibility': 'off' }],
					},
				],
			}}
			{...props}
		>
			{children}
		</GoogleMap>
	);
};

const Gmap = ({ libraries, intl, ...props }) => {
	const mapsApiKey = useSelector(state => state.config.googleServices.mapsApiKey);
	const { isLoaded } = useJsApiLoader({
		id: 'google-map',
		googleMapsApiKey: mapsApiKey,
		language: intl.defaultLocale,
		libraries,
	});

	if (!isLoaded) {
		// TODO: Proper loading screen
		return <div>Loading Map</div>;
	}
	return <MapComponent {...props} />;
};

export default injectIntl(Gmap);
