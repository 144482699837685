import { batchDispatch } from '../app/funcs';
import { getSnapshotData } from '../lib/utils/utils';

export const LOAD_PROJECT_QUASISTATICS = 'LOAD_PROJECT_QUASISTATICS';
export const CLEAN_CACHED_QUASISTATICS = 'CLEAN_CACHED_QUASISTATICS';
export const GET_QUASISTATICS = 'GET_QUASISTATICS';
export const QUASISTATICS_LOADING = 'QUASISTATICS_LOADING';

export function getQuasiStatics(viewer) { 
  const getPromise = async () => {
    const scopeParams = { scope: 'global' };
    const resourceParams = {
      resourceName: 'quasiStatics',
      firebasePath: `quasiStatics/global`,
      forceMSClientConfig: true,
    }
  
    const onData = (data) => {
      batchDispatch([{ type: GET_QUASISTATICS, payload: { quasiStatics: data, lastUpdateTS: 1 } }]);
    }

    const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
    if (result) {
      batchDispatch([{
        type: GET_QUASISTATICS,
        payload: { quasiStatics: result },
      }])
    }
  }

  return {
    type: QUASISTATICS_LOADING,
    payload: getPromise(),
  };
}
