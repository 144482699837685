const indicatorStyles = {
  ctn: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.6)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const  UploadProgressIndicator = ({ progress }) => {
  if (progress === null) {
    return null;
  }
  const { ctn } = indicatorStyles;

  return <div style={ctn}>
    {progress.toFixed(0)}%
  </div>
};

export default UploadProgressIndicator;