import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { connectContext } from "react-connect-context";
import { BarChart } from "@material-ui/icons";
import Datetime from "react-datetime";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import { ProjectContext, FiltersSortsContext } from '../../../common/projects/contexts';
import * as issueStates from '../../../common/issues/issueStates';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import { GridContainer, GridItem } from '../../components';
import issuesMessages from "../../../common/issues/issuesMessages";
import Posts from "./Posts.js";
import Sorts from "../Menus/Sorts";
import theme from "../../assets/css/theme";
import "../../assets/css/slider.css";
import FilterStatusLine from "../../components/CementoComponents/FilterStatusLine";
import { draftValidator, onDraftModeChange } from "../../../common/ui/actions";
import { exportPostsPDF } from "../../../common/posts/actions";
import { startToast } from "../../../common/app/actions";
import notificationsStyle from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import SplitViewPage from "../../layouts/SplitViewPage";
import systemMessages from "../../../common/app/systemMessages";
import Text from "../../components/CementoComponents/Text";
import postsMenuMessages from "../../../common/posts/postsMenuMessages";
import postsMessages from "../../../common/posts/postsMessages";
import newPostMessages from "../../../common/posts/newPostMessages";
import { lokiInstance } from "../../../common/configureMiddleware";

import pdfMessages from "../../../common/app/pdfMessages";
import TextFilter from "./TextFilter";
import ImageCarousel from "../../components/CementoComponents/ImageCarousel";
import FilterMenuHOC from "../../components/CementoComponents/FilterMenu";
import _ from "lodash";
import safetyMessages from "../../../common/safety/safetyMessages";
import analyticsMessages from "../../../common/analytics/analyticsMessages";
import { injectIntl } from "react-intl";
import AddNewButton from "../../components/CementoComponents/AddNewButton";
import { safeToJS } from "../../../common/permissions/funcs";
import { ALL_BUILDINGS_ID } from "../../../common/app/constants";
import { FILTER_URL_KEY,  } from "../../app/constants";
import qs from 'qs';
import {withChecklistItemInstances} from "../../../common/posts/hooks/useChecklistItemInstances";
import { isIssuePage } from './utils.js';
import withRouterHOC from "../../components/Router/util/withRouterHOC.js";
import { track } from "../../../common/lib/reporting/actions.js";

const maxExpandablePosts = 100;

const minPostWidth = 460;
export const postRowHeight = 195;
export const postRowHeightLines = 110;
export const sectionTitleHeight = 50;
const defaultExpandAll = false;

const membersToOptions = (members) => {
  members = members && members.toJS ? members.toJS() : members;

  let membersOptionsById = {};
  Object.values(members || {}).forEach((member) => {
    const option = { id: member.id, title: member.displayName };
    _.set(membersOptionsById, [member.id], option);
  });

  return membersOptionsById;
};

const companiesToOptions = (companies) => {
  companies = companies && companies.toJS ? companies.toJS() : companies;

  let companiesOptionsById = {};
  Object.values(companies).forEach((company) => {
    const option = { id: company.id, title: company.name };
    _.set(companiesOptionsById, [company.id], option);
  });

  return companiesOptionsById;
};

const tradesToOptions = (trades) => {
  trades = trades && trades.toJS ? trades.toJS() : trades;

  let companiesOptionsById = {};
  Object.values(trades || {}).forEach((trade) => {
    const option = { id: trade.id, title: trade.getCementoTitle() };
    _.set(companiesOptionsById, [trade.id], option);
  });

  return companiesOptionsById;
};

const subCategoriesToOptions = (subCategories) => {
  subCategories = safeToJS(subCategories);

  let subCategoriesOptionsById = {};

  _.values(subCategories).forEach((subCategoriesGroup) => {
    _.values(subCategoriesGroup).forEach((subCategory) => {
      const option = {
        id: subCategory.id,
        title: subCategory.getCementoTitle(),
      };
      _.set(subCategoriesOptionsById, option.id, option);
    });
  });

  return subCategoriesOptionsById;
};

const checklistsToOptions = (checklistsMap, posts, checklistInstancesMap, navigationParams) => {
  if (!checklistsMap || !posts || !checklistInstancesMap) {
    return {};
  }
  const filterByChecklistIds = qs.parse(
    navigationParams.getNested(['queryParams', 'postsFilter'], '?')
  )?.['checklistItemInstance-id']?.split(',') || [];

  const options = [];

  posts.forEach(post => {
    const {
      stageOrdinalNo,
      stageId,
      stageTitle,
      checklistOrdinalNo,
      checklistId,
      checklistTitle,
      checklistItemInstanceId
    } = post;

    if (!checklistItemInstanceId) {
      return;
    }
    const existingOption = options.find(o => o.stageId === stageId);

    if (existingOption) {
      const existingChecklist = existingOption.nested.find(o => o.checklistId === checklistId);

      if (existingChecklist) {
        existingChecklist.id = `${existingChecklist.id},${checklistItemInstanceId}`;
      } else {
        existingOption.nested.push({
          title: checklistTitle,
          id: checklistItemInstanceId,
          checklistId,
          ordinalNo: checklistOrdinalNo,
          checked: filterByChecklistIds?.includes(checklistItemInstanceId),
        });
      }
      existingOption.id = `${existingOption.id},${checklistItemInstanceId}`;
    } else {
      options.push({
        title: stageTitle,
        stageId,
        ordinalNo: stageOrdinalNo,
        id: checklistItemInstanceId,
        checked: filterByChecklistIds?.includes(checklistItemInstanceId),
        nested: [{
          title: checklistTitle,
          id: checklistItemInstanceId,
          checklistId,
          ordinalNo: checklistOrdinalNo,
          checked: filterByChecklistIds?.includes(checklistItemInstanceId),
        }]
      })
    }
  });

  options.forEach(o => {
    if (o.nested?.length) {
      o.nested.sort((a, b) => a.ordinalNo - b.ordinalNo);
    }
  });
  options.sort((a, b) => a.ordinalNo - b.ordinalNo);

  return options;
}

class PostsPage extends React.Component {
  constructor(props) {
    super(props);
    this.today = new Date().getTime();
    this.handleEditModeChange = this.handleEditModeChange.bind(this);
    this.getContainerRef = this.getContainerRef.bind(this);
    this.getScrollRef = this.getScrollRef.bind(this);
    this.getPostsListRef = this.getPostsListRef.bind(this);
    this.onWidthChange = this.onWidthChange.bind(this);
    this.calculatePostPerRow = this.calculatePostPerRow.bind(this);
    this.onRelativeDateChange = this.onRelativeDateChange.bind(this);
    this.onSortChangeDirection = this.onSortChangeDirection.bind(this);
    this.onCollapseExpandClick = this.onCollapseExpandClick.bind(this);
    this.lokiPostsListener = this.lokiPostsListener.bind(this);
    this.onAllGroupsCollapseOrExpand =
      this.onAllGroupsCollapseOrExpand.bind(this);
    this.exportAllSelectedGroupPosts =
      this.exportAllSelectedGroupPosts.bind(this);
    this.handleExportSelectionModeChange =
      this.handleExportSelectionModeChange.bind(this);
    this.closeSelectedPost = this.closeSelectedPost.bind(this);
    this.addPost = this.addPost.bind(this);
    this.setComponentData = this.setComponentData.bind(this);
    this.onPostSelect = this.onPostSelect.bind(this);
    this.blocksMode = this.blocksMode.bind(this);
    this.linesMode = this.linesMode.bind(this);
    this.chartMode = this.chartMode.bind(this);
    this.setPdfDisplay = this.setPdfDisplay.bind(this);

    this.state = {
      relativeDate: this.today,
      sortDirection: 1,
      scrollToTop: 0,
      postsPerRow: 0,
      postsArray: [],
      groupKeys: {},
      imagesModalObject: null,
      selectedPost: null,
      exportSelectionMode: false,
      expandAll: props.hasOwnProperty("expandAll")
        ? props.expandAll /* props.postsArray.length < maxExpandablePosts */
        : defaultExpandAll,
      viewMode: "blocks",
    };
  }

  handleEditModeChange(isEditMode) {
    this.setState({ isEditMode });
  }

  lokiPostsListener(collectionName) {
    const { selectedPost, isEditMode } = this.state;
    if (collectionName == 'posts' && selectedPost && !isEditMode) {
      const updatedSelectedPost = this.lokiPosts.cementoFind({id: _.get(selectedPost, ['id'])});
      this.setState({ selectedPost: _.head(updatedSelectedPost) });
    }
  }

  componentWillUnmount() {
    this.lokiPosts.cementoOff("postsPageListener");
  }

  componentDidMount() {
    const { track, selectedProjectId } = this.props;
    const { selectedPost } = this.state;
    const trackOptions = { projectId: selectedProjectId };
    if (selectedPost) trackOptions.postId = selectedPost.id;
    track('enterPostsPage', trackOptions);

    window.addEventListener(
      "resize",
      (() => {
        this.setState(this.onWidthChange());
      }).bind(this)
    );
    this.lokiPosts = lokiInstance.getCollection('posts');
    this.lokiPosts.cementoOn("postsPageListener", this.lokiPostsListener);
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
		if (
      (!prevState.selectedPost && this.state.selectedPost) || 
      (prevState.selectedPost && !this.state.selectedPost) ||
      prevState.isValDiff(this.state, ['viewMode'])
    )
			this.setState({ postsPerRow: this.calculatePostPerRow() });

		var shouldHeaderComponentUpdate =
			this.props.isValDiff(prevProps, ['uiParams']) ||
			this.props.isValDiff(prevProps, ['location']) ||
			this.props.isValDiff(prevProps, ['sorts']) ||
			this.props.isValDiff(prevProps, ['selectedProjectId']) ||
			this.props.isValDiff(prevProps, ['itemsMode']) ||
			this.props.isValDiff(prevProps, ['rtl']) ||
			this.props.isValDiff(prevProps, ['setHeaderParams']) ||
			this.props.isValDiff(prevProps, ['getExcelExportComponent']) ||
			this.state.isValDiff(prevState, ['isIssuesPage']) ||
      this.props.isValDiff(prevProps, ["categoryFilters"]) ||
			this.state.isValDiff(prevState, ['exportSelectionMode']) ||
      this.props.isValDiff(prevProps, ['headerChildren']);

		if (shouldHeaderComponentUpdate) this.recalcHeader();
	}

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state != nextState) return true;

    const shouldComponentUpdate =
      this.props.isValDiff(nextProps, ["uiParams"]) ||
      !_.isEqual(
        (this.props.match || {}).params,
        (nextProps.match || {}).params
      ) ||
      this.props.isValDiff(nextProps, ["data"]) ||
      this.props.isValDiff(nextProps, ["location"]) ||
      this.props.isValDiff(nextProps, ["sorts"]) ||
      this.props.isValDiff(nextProps, ["selectedProjectId"]) ||
      this.props.isValDiff(nextProps, ["itemsMode"]) ||
      this.props.isValDiff(nextProps, ["rtl"]) ||
      this.props.isValDiff(nextProps, ["postsArray", "length"]) ||
      this.props.isValDiff(nextProps, ["filterVal"]);

    return shouldComponentUpdate;
  }

  setComponentData(props, nextProps) {
    const { scrollToTop, selectedPost, isEditMode, isIssuesPage } = this.state;
    let newStateChanges = {};

    if (
      props.firstMount ||
      props.getNested(["uiParams", "sidebarLoaded"]) !=
        nextProps.getNested(["uiParams", "sidebarLoaded"])
    ) {
      newStateChanges = { ...this.onWidthChange() };
    }

    if (props.itemsMode != nextProps.itemsMode) {
      newStateChanges.dataSortFunc = (a, b) => {
        return (
          (b.editedAt || b.createdAt || 0) - (a.editedAt || a.createdAt || 0)
        );
      };
    }

    if (props.firstMount || props.isValDiff(nextProps, ['contentType']) || props.isValDiff(nextProps, ['itemsMode'])) {
      newStateChanges.isIssuesPage = this.getIsIssuesPage(nextProps);
    }

    let differentLocationParams =
      props.getNested(["match", "params", "buildingId"]) !=
        nextProps.getNested(["match", "params", "buildingId"]) ||
      props.getNested(["match", "params", "floorId"]) !=
        nextProps.getNested(["match", "params", "floorId"]) ||
      props.getNested(["match", "params", "unitId"]) !=
        nextProps.getNested(["match", "params", "unitId"]);

    let differentPostsData =
      props.getNested(["data"]) != nextProps.getNested(["data"]);
    if (differentPostsData)
      newStateChanges.selectedLocationId =
        nextProps.getNested(["match", "params", "unitId"]) ||
        nextProps.getNested(["match", "params", "floorId"]) ||
        nextProps.getNested(["match", "params", "buildingId"]);

    if (differentLocationParams || differentPostsData) {
      // Todo: Select locationPosts when working with DB
      newStateChanges.postsArray = nextProps.data;

      nextProps.data?.forEach((post) => {
        if (selectedPost && selectedPost.id == post.id && !isEditMode)
          newStateChanges.selectedPost = post;
      });

      if (differentLocationParams) {
        newStateChanges.scrollToTop = scrollToTop - 1;
      }

      if (props.filterVal != nextProps.filterVal && nextProps.filterVal)
        newStateChanges.expandAll = this.state.postsArray?.length || newStateChanges.postsArray?.length < maxExpandablePosts;
      else if (!nextProps.filterVal)
        newStateChanges.expandAll = nextProps.expandAll < defaultExpandAll;
    }

    if (differentLocationParams) {
      newStateChanges.selectedPost = null;
      newStateChanges.imagesModalObject = null;
    }

    if (props.filters != nextProps.filters || props.sorts != nextProps.sorts)
      newStateChanges.scrollToTop = scrollToTop - 1;

    if (props.selectedProjectId != nextProps.selectedProjectId ||
        props.members != nextProps.members ||
        props.trades != nextProps.trades ||
        props.companies != nextProps.companies ||
        props.floors != nextProps.floors ||
        props.units != nextProps.units ||
        props.itemsMode != nextProps.itemsMode ||
        props.isValDiff(nextProps, ['navigationParams', 'queryParams', 'buildingId']) ||
        isIssuesPage !== newStateChanges.isIssuesPage) {
      const buildingId = nextProps.getNested(['navigationParams', 'queryParams', 'buildingId'], ALL_BUILDINGS_ID);
      let floorsMap = {}, unitsMap = {};
      if (buildingId === ALL_BUILDINGS_ID) {
        nextProps.floors.loopEach((bId, b) => b.loopEach((id, value) => floorsMap[id] = value));
        nextProps.units.loopEach((bId, b) => b.loopEach((id, value) => unitsMap[id] = value));
      } else {
        floorsMap = nextProps.getNested(['floors', buildingId], {});
        unitsMap = nextProps.getNested(['units', buildingId], {});
      }

      newStateChanges.groupKeys = {
        dueDate: { key: "dueDate", valuePath: ["dueDate"], isDate: true },
        owner: {
          key: "owner",
          valuePath: ["owner", "id"],
          titleFetchLocation: nextProps.allMembers,
          titleFetchKeyPath: "displayName",
        },
        ownerCompany: {
          key: "ownerCompany",
          valuePath: ["owner", "companyId"],
          titleFetchLocation: nextProps.allCompanies,
          titleFetchKeyPath: "name",
        },
        createdAt: { key: "createdAt", valuePath: ["createdAt"], isDate: true },
        trade: {
          key: "trade",
          valuePath: ["trade", "id"],
          titleFetchLocation: nextProps.trades,
          titleFetchKeyPath: "getTitle",
        },
        subCategory: {
          key: "subCategory",
          valuePath: ["subCategory", "id"],
          titleFetchLocation: subCategoriesToOptions(nextProps.subCategories),
          titleFetchKeyPath: "title",
        },
        assignTo: {
          key: "assignTo",
          valuePath: ["assignTo", "id"],
          titleFetchLocation: nextProps.allMembers,
          titleFetchKeyPath: "displayName",
        },
        issueState: {
          key: "issueState",
          valuePath: ["issueState"],
          titleFetchLocation: issuesMessages.counterStatus,
          defaultGroupKey:
            newStateChanges.isIssuesPage ?? isIssuesPage
              ? issueStates.ISSUE_STATE_OPENED
              : null,
        },
        company: {
          key: "company",
          valuePath: ["assignTo", "id"],
          titleFetchLocation: nextProps.allMembers,
          valuePath2: [
            ["projects", nextProps.selectedProjectId, "companyId"],
            ["companyId"],
          ],
          titleFetchLocation2: nextProps.allCompanies,
          titleFetchKeyPath: "name",
        },
        floor: {
          key: "floor",
          valuePath: ["location", "floor", "id"],
          titleFetchLocation: floorsMap,
          sortFunc: (a, b) => a.localeCompare(b),
          titleFetchKeyPath: "description",
        },
        unit: {
          key: "unit",
          valuePath: ["location", "unit", "id"],
          titleFetchLocation: unitsMap,
          sortFunc: (a, b) => a.localeCompare(b),
          titleFetchKeyPath: "title",
        },
      };
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  onWidthChange(ref) {
    const { postsContainerWidth } = this.state;
    if (this.postsContainerRef || ref) {
      let prevWidth = postsContainerWidth;
      let currWidth = this.postsContainerRef
        ? this.postsContainerRef.clientWidth
        : ref.clientWidth;
      if (prevWidth / 10 != currWidth / 10) {
        let postsPerRow = this.calculatePostPerRow(currWidth);
        return { postsContainerWidth: currWidth, postsPerRow: postsPerRow };
      }
    }

    return {};
  }

  calculatePostPerRow(containerWidth, nextViewMode) {
    const { viewMode } = this.state;
    if (nextViewMode == "lines" || (viewMode == "lines" && !nextViewMode))
      return 1;
    let width =
      containerWidth ||
      (this.postsContainerRef
        ? this.postsContainerRef.clientWidth
        : minPostWidth);
    let countPerRow = Math.floor(width / minPostWidth);
    let postsPerRow = Math.max(1, countPerRow); //- (selectedPost ? 1 : 0);
    return postsPerRow;
  }

  closeSelectedPost() {
    this.setState({ selectedPost: null });
  }

  addPost() {
    const { isIssuesPage } = this.state;
    const { match, draftValidator } = this.props;

    let location = {};
    if (match.getNested(["params", "buildingId"], "_") != "_")
      location.building = { id: match.getNested(["params", "buildingId"]) };
    if (match.getNested(["params", "floorId"], "_") != "_")
      location.floor = { id: match.getNested(["params", "floorId"]) };
    if (match.getNested(["params", "unitId"], "_") != "_")
      location.unit = { id: match.getNested(["params", "unitId"]) };

    draftValidator(() =>
      this.setState({
        selectedPost: {
          isIssue: isIssuesPage ? true : null,
          mode: "draft",
          location,
        },
      })
    );
  }

  onPostSelect(post, skipDraftValidator = false) {
    const { draftValidator, onDraftModeChange } = this.props;
    const { selectedPost } = this.state;

    if (!selectedPost || skipDraftValidator) {
      this.setState({ selectedPost: post });
      return;
    }

    draftValidator(() => {
      onDraftModeChange(false);
      this.setState({ selectedPost: post });
    });
  }

  onSortChangeDirection() {
    this.setState({ sortDirection: this.state.sortDirection * -1 });
  }

  onCollapseExpandClick() {
    this.setState({ expandAll: !this.state.expandAll });
  }

  onAllGroupsCollapseOrExpand(isAllExpand) {
    this.setState({ expandAll: isAllExpand });
  }

  getContainerRef(node) {
    this.postsContainerRef = node;
  }

  getScrollRef(node) {
    this.postsScrollRef = node;
  }

  chartMode() {
    this.setState({ viewMode: "chart" });
  }

  linesMode() {
    this.setState({
      viewMode: "lines",
      postsPerRow: this.calculatePostPerRow(null, "lines"),
    });
  }

  blocksMode() {
    this.setState({
      viewMode: "blocks",
      postsPerRow: this.calculatePostPerRow(null, "blocks"),
    });
  }

  onRelativeDateChange(e) {
    let newVal = e.valueOf();
    this.setState({ relativeDate: newVal });
  }

  handleExportSelectionModeChange() {
    const { startToast } = this.props;
    const { exportSelectionMode } = this.state;
    let newMode = !exportSelectionMode;
    if (newMode) startToast({ title: pdfMessages.selectGroupsToExport });

    this.setState({ exportSelectionMode: newMode });
  }

  getPostsListRef(r) {
    this.getPostsListRef = r;
  }

  setPdfDisplay(pdfResponse) {
    const pdf = pdfResponse?.getJson?.() || pdfResponse;

    let pdfDisplay = pdf?.uri?.startsWith?.("http")
      ? pdf.uri
      : null;

    this.setState({ exportSelectionMode: false, pdfDisplay });
  }

  async exportAllSelectedGroupPosts() {
    const { selectedProjectId, viewer, exportPostsPDF, startToast } = this.props;
		let groupPosts = this.getPostsListRef.getSelectedGroupsPosts();
    
		if (!groupPosts || groupPosts.length == 0) {
			return startToast({ title: pdfMessages.noGroupsSelected });
		}
		if (groupPosts.length >= 150) {
      const isUserAdmin = _.get(viewer, ['adminMode']);
			return startToast({
				title: pdfMessages.postsExport.tooManyPostsSelected,
        overlay: isUserAdmin,
        values: { numSelectedPosts: groupPosts.length },
        actions: [
          { 
            message: pdfMessages.postsExport.generateAnyway, color: 'success', onClick: async () => {
              let pdf = await exportPostsPDF(groupPosts, selectedProjectId, viewer, this.setPdfDisplay);
              this.setPdfDisplay(pdf);
            }
          },
          { message: pdfMessages.postsExport.cancelFormGeneration },
        ]
			});
		}
		let pdf = await exportPostsPDF(groupPosts, selectedProjectId, viewer, this.setPdfDisplay);
		this.setPdfDisplay(pdf);
  }

  hidePdfDisplay = () => this.setState({ pdfDisplay: null });

  getIsIssuesPage = (props) => {
    const { contentType, itemsMode } = props || this.props;

    return isIssuePage(contentType, itemsMode);
  }

  getSortsMenu = () => {
    const { isIssuesPage } = this.state;
		const { intl, contentType } = this.props;
		let menu = [
			{ title: postsMenuMessages['trade'], key: 'trade', ordinalNo: 1 },
			{
				title:
          `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(analyticsMessages.viewType.filterByUser)}`,
				key: 'owner',
        ordinlaNo: 6,
			},
			{ title: postsMenuMessages['createdAt'], key: 'createdAt', ordinalNo: 7 },
      { title: postsMenuMessages['floor'], key: 'floor', ordinalNo: 9 },
      { title: postsMenuMessages['unit'], key: 'unit', ordinalNo: 10 },
		];

    if (contentType === 'safety') {
      menu.push({ title: newPostMessages.subCategory, key: 'subCategory', ordinalNo: 2 })
    }

    if (isIssuesPage) {
			menu.push(
				{
					title: `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(postsMenuMessages.company)}`,
					key: 'company',
					ordinalNo: 5,
				},
				{
					title: `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(
						analyticsMessages.viewType.filterByCompany,
					)}`,
					key: 'ownerCompany',
					ordinlaNo: 6.5,
				},
			);
      
		}

		if (isIssuesPage ) {
      menu.push(
        { title: postsMenuMessages['issueState'], key: 'issueState', ordinalNo: 3 },
        {
          title:
            `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(analyticsMessages.viewType.filterByUser)}`,
          key: "assignTo",
          ordinalNo: 4,
        },
        
        { title: postsMenuMessages['dueDate'], key: 'dueDate', ordinalNo: 8 },
      );
		}
    
		return menu;
	};

  // To add a filter, follow the 'Add here'
  getFilterMenu = (postsArray, props = this.props) => {
    const { isIssuesPage } = this.state;
    const {
      navigationParams,
      allMembers,
      trades,
      projectCompanies,
      allPosts,
      intl,
      subCategories,
      checklists,
      checklistInstances,
      selectedProjectId,
      categoryFilters
    } = props;

    if (categoryFilters) return categoryFilters
    
    postsArray = postsArray || allPosts;
    const properties = {
      trades: ["trade", "id"],
      subCategories: ["subCategory", "id"],
      owner: ["owner", "id"],
      assignTo: ["assignTo", "id"],
      issueState: ["issueState"],
      severity: ["severity"],
      ownerCompanies: ["owner", "companyId"],
      assigneeCompanies: ["assignTo", "companyId"],
      checklist: ['checklistItemInstance', 'id'],
      createdAt: ['createdAt'],
      // Add here if it is simple to get the value from the post...
      // [optionId]: pathArrToValueWithinThePost
    };

    let relevantOptionsIds = {};
    Object.values(postsArray || {}).forEach((post) => {
      Object.entries(properties).forEach(([optionPath, valPath]) => {
        const val = _.get(post, valPath);
        if (!val) return;
        _.set(relevantOptionsIds, [optionPath, val], val);
      });

      // ...Otherwise manually add here the relevant option ids to relevantOptionIds
    });
    const allEmployeesOptionsByEmployeeId = membersToOptions(allMembers);
    const tradeOptionsByTradeId = tradesToOptions(trades);
    const subCategoriesBysubCategoryId = subCategoriesToOptions(subCategories);
    const companyOptionsByCompanyId = companiesToOptions(projectCompanies);
    
    const checklistsOptionsById = checklistsToOptions(
      checklists?.getIn([selectedProjectId]),
      allPosts,
      checklistInstances,
      navigationParams,
    );
    const issueStateOptionsByIssueState = [
        issueStates.ISSUE_STATE_CLOSED,
        issueStates.ISSUE_STATE_OPENED,
        issueStates.ISSUE_STATE_RESOLVED,
      ].reduce(
        (acc, currIssueState) =>
          _.set(acc, [currIssueState], {
            id: currIssueState,
            title: issuesMessages.counterStatus[currIssueState],
          }),
        {}
      ),
      severityOptionsBySeverity = {
        ["1"]: { id: 1, title: safetyMessages.safetyTable.openSafetyIssuesLow },
        ["2"]: {
          id: 2,
          title: safetyMessages.safetyTable.openSafetyIssuesMedium,
        },
        ["3"]: {
          id: 3,
          title: safetyMessages.safetyTable.openSafetyIssuesHigh,
        },
      };
    // Add here and define your options objects

    const extractRelevantOptions = (optionObj, relevantOptionsPath) =>
      Object.values(optionObj || {}).filter((option) =>
        Boolean((relevantOptionsIds[relevantOptionsPath] || {})[option.id])
      );
    const contentType = navigationParams?.contentType;

    let filterCategories = {
      ["postOwner"]: {
        id: "owner-id",
        title:
          `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(analyticsMessages.viewType.filterByUser)}`,
        ordinalNo: 5,
        options: extractRelevantOptions(allEmployeesOptionsByEmployeeId, 'owner'),
        type: propertyTypes.SELECTION_LIST,
      },
      ["postOwnerCompany"]: {
        id: "owner-companyId",
        title:
          `${intl.formatMessage(postsMenuMessages.owner)} - ${intl.formatMessage(postsMenuMessages.company)}`,
        ordinalNo: 6,
        options: extractRelevantOptions(companyOptionsByCompanyId, 'ownerCompanies'),
        type: propertyTypes.SELECTION_LIST,
      },
      ["createdAt"]: {
        id: "createdAt",
        title: postsMenuMessages["createdAt"],
        ordinalNo: 10,
        type: propertyTypes.DATE_RANGE,
      },
      ["subCategory"]: {
        id: "subCategory-id",
        title: newPostMessages.subCategory,
        ordinalNo: 3,
        options: extractRelevantOptions(subCategoriesBysubCategoryId, 'subCategories'),
        type: propertyTypes.SELECTION_LIST,
      },
      trade: {
        id: "trade-id",
        title: systemMessages.trade,
        ordinalNo: 2,
        options: extractRelevantOptions(tradeOptionsByTradeId, 'trades'),
        type: propertyTypes.SELECTION_LIST,
        isHidden: contentType === 'safety',
      },
      // TODO: support checking if key has a value and value has values (right now the schema for posts has default values of [] for attachments and images which means that the object exist but it is empty)
      // attachments: {
      //   id: 'attachments',
      //   title: 'Attachements',
      //   ordinalNo: 7,
      //   options: [
      //     { id: 'attachments', title: 'PDF' },
      //     { id: 'images', title: 'Images' },
      //   ]
      // }
      // Add here the category if is generic...
    };

    // ...Otherwise add here based on itemType...
    if (isIssuesPage) {
      filterCategories = {
        ...filterCategories,
        ["postAssignee"]: {
          id: "assignTo-id",
          title:
            `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(analyticsMessages.viewType.filterByUser)}`,
          ordinalNo: 7,
          options: extractRelevantOptions(allEmployeesOptionsByEmployeeId, 'assignTo'),
          type: propertyTypes.SELECTION_LIST,
        },
        ["issueState"]: {
          id: "issueState",
          title: postsMenuMessages.issueState,
          ordinalNo: 1,
          options: Object.values(issueStateOptionsByIssueState),
          type: propertyTypes.SELECTION_LIST,
        },
        ["postAssigneeCompany"]: {
          id: "assignTo-companyId",
          title:
            `${intl.formatMessage(postsMenuMessages.assignTo)} - ${intl.formatMessage(postsMenuMessages.company)}`,
          ordinalNo: 8,
          options: extractRelevantOptions(companyOptionsByCompanyId, 'assigneeCompanies'),
          type: propertyTypes.SELECTION_LIST,
        },
        ["checklist"]: {
          id: "checklistItemInstance-id",
          title: postsMenuMessages.checklist,
          ordinalNo: 9,
          options: checklistsOptionsById,
          type: propertyTypes.SELECTION_LIST,
        },
      };
    }



    // ...Or maybe add here based on contentType.
    switch (contentType) {
      case "safety": {
        filterCategories = {
          ...filterCategories,
          ["severity"]: {
            id: "severity",
            title: newPostMessages.severity,
            ordinalNo: 4,
            options: Object.values(severityOptionsBySeverity),
            type: propertyTypes.SELECTION_LIST,
          },
        };
        break;
      }
      default:
        break;
    }
   
    return [
      {
        id: "defaultView",
        title: "defaultView",
        categories: Object.values(filterCategories),
      },
    ];
  };

  recalcHeader = () => {
    const {
      classes,
      rtl,
      setHeaderParams,
      filterVal,
      clearFilterVal,
      setFilterVal,
      headerChildren,
      filterPathDelimeter,
      contentType,
      intl,
    } = this.props;
    const {
      postsPerRow,
      sortDirection,
      viewMode,
      expandAll,
      relativeDate,
      exportSelectionMode,
      isIssuesPage,
    } = this.state;
    const standardSeparationMargin = `0 ${theme.verticalMargin}px`;
    let isCementoTeamViewer = this.props.getNested([
      "uiParams",
      "isCementoTeamViewer",
    ]);

    let headerComponent = (
			<GridItem
				xs={12}
				style={{
					paddingLeft: theme.paddingSize,
					paddingRight: theme.paddingSize,
					borderBottom: theme.borderLineNeutralLight + '40',
					height: theme.headerHeightSecondary,
				}}
			>
				<GridContainer
					style={{
						height: '100%',
						justifyContent: 'space-between',
						flexWrap: 'nowrap',
					}}
					alignContent='center'
					alignItems='center'
					justifyContent='center'
				>
					<GridItem
						xs={postsPerRow == 1 ? 6 : 8}
						md={postsPerRow == 1 ? 6 : 6}
						style={{
							display: 'flex',
							flexDirection: viewMode == 'chart' ? 'row-reverse' : 'row',
						}}
					>
						{viewMode !== 'chart' ? (
							<FilterStatusLine
                                match={this.props.match}
                                onCollapseExpandClick={this.onCollapseExpandClick}
                                expandStatus={!expandAll}
                                type={intl.formatMessage(postsMenuMessages[isIssuesPage ? 'dataTypeTasks' : 'dataTypeRecords'])}
                            />
						) : (
							<FormControl
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{'Last day to compare: '}
								<Datetime timeFormat={false} value={moment(relativeDate)} onChange={this.onRelativeDateChange} />
							</FormControl>
						)}
					</GridItem>
					{exportSelectionMode ? (
						<GridItem
							xs={postsPerRow == 1 ? 6 : 4}
							md={postsPerRow == 1 ? 6 : 3}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								paddingLeft: theme.verticalMargin,
								paddingRight: theme.verticalMargin,
							}}
						>
							<div style={{ cursor: 'pointer' }} onClick={this.handleExportSelectionModeChange}>
								<Text style={{ margin: 5, fontSize: 16 }}>{systemMessages.cancel}</Text>
							</div>
							<div style={{ cursor: 'pointer' }} onClick={this.exportAllSelectedGroupPosts}>
								<Text
									style={{
										margin: 5,
										fontSize: 16,
										color: theme.brandPrimary,
										[rtl ? 'marginLeft' : 'marginRight']: 0,
									}}
								>
									{postsMenuMessages.exportTitle}
								</Text>
							</div>
						</GridItem>
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end'
							}}
						>
							<TextFilter
								containerStyle={{ margin: standardSeparationMargin }}
								defaultValue={filterVal}
								onChange={setFilterVal}
								clearFilterVal={clearFilterVal}
							/>
							<Sorts
								hideSortDirection
								sortValuesFunc={(a, b) => (a.ordinalNo || 0) - (b.ordinalNo || 0)}
								direction={sortDirection}
								onSortChangeDirection={this.onSortChangeDirection}
								sortMenu={this.getSortsMenu()}
								sortByLocation={true}
							/>
							<FilterMenuHOC
								hideEmptyCategory
								filters={this.getFilterMenu()}
								filterUrlKey={FILTER_URL_KEY}
								buttonStyle={{ margin: theme.margin }}
								pathDelimeterOverwrite={filterPathDelimeter}
							/>

							{headerChildren}
							{this.props.getExcelExportComponent?.([
								{
									id: 'default',
									label: systemMessages.manage.pdfExport,
									getDataToExportFunc: this.handleExportSelectionModeChange,
								},
							])}
							{Boolean(false && isCementoTeamViewer) && (
								<BarChart
									className={classes.icons}
									style={{
										[rtl ? 'paddingRight' : 'paddingLeft']: theme.margin,
										cursor: 'pointer',
										color: viewMode == 'chart' ? '#211811' : '#bdbab8',
									}}
									onClick={this.chartMode}
								/>
							)}
							<AddNewButton
								values={{ contentType: safetyMessages.objectsNames[contentType] }}
								onClick={() => this.addPost(null, true)}
								title={postsMessages[isIssuesPage ? 'newIssue' : 'newPost']}
								style={{
									fontWeight: theme.strongBold,
									margin: standardSeparationMargin,
								}}
							/>
						</div>
					)}
				</GridContainer>
			</GridItem>
		);

    if (setHeaderParams)
      setHeaderParams({ headerComponent, sideBarParams: { open: false } });
  }

  onPostSave = (selectedPost) => {
    this.setState({ selectedPost });
  }

  render() {
    const { sorts, rtl, defaultGroupBy } = this.props;
    const {
      postsArray,
      selectedLocationId,
      selectedPost,
      postsPerRow,
      scrollToTop,
      sortDirection,
      viewMode,
      expandAll,
      groupKeys,
      dataSortFunc,
      relativeDate,
      exportSelectionMode,
      pdfDisplay,
    } = this.state;
  
    return (
      <SplitViewPage
        rtl={rtl}
        onSideClose={this.closeSelectedPost}
        getMainContainerRef={this.getContainerRef}
        getMainContainerScroll={this.getScrollRef}
        onEditModeChange={this.handleEditModeChange}
        ratio={
          viewMode == "blocks" ? (postsPerRow == 1 ? 1 / 2 : 1 / 3) : 1 / 3
        }
        Main={
          <>
            {Boolean(pdfDisplay) && (
              <ImageCarousel
                items={[{ src: pdfDisplay }]}
                pdfMode={true}
                onClose={this.hidePdfDisplay}
                toolbar={true}
              />
            )}
            <Posts
              getRef={this.getPostsListRef}
              viewMode={viewMode}
              relativeDate={relativeDate == this.today ? null : relativeDate}
              scrollToTop={scrollToTop}
              expandAll={expandAll}
              exportSelectionMode={exportSelectionMode}
              onAllGroupsCollapseOrExpand={this.onAllGroupsCollapseOrExpand}
              scrollRef={this.postsScrollRef}
              rowHeight={
                viewMode == "lines" ? postRowHeightLines : postRowHeight
              }
              sectionTitleHeight={sectionTitleHeight}
              postsPerRow={postsPerRow}
              onPostSelect={this.onPostSelect}
              selectedPostId={selectedPost ? selectedPost.id : null}
              selectedLocationId={selectedLocationId}
              postsArray={postsArray}
              sortDirection={sortDirection}
              groupBy={groupKeys[sorts && sorts[0] ? sorts[0] : defaultGroupBy]}
              dataSortFunc={dataSortFunc}
            />
          </>
        }
        SideStack={
          Boolean(selectedPost) && [
            {
              type: "post",
              props: {
                onPostSave: this.onPostSave,
                onSelect: this.onPostSelect,
                targetObject: selectedPost
              },
            },
          ]
        }
      />
    );
  }
}

const styles = {
  slider: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    background: "transparent",
  },
};

PostsPage = injectIntl(PostsPage);
PostsPage = withStyles(
  theme.combineStyles(notificationsStyle, validationFormsStyle, styles)
)(PostsPage);
const enhance = compose(
  withRouterHOC,
  withChecklistItemInstances,
  connectContext(ProjectContext.Consumer),
  connectContext(FiltersSortsContext.Consumer),
  connect(
    (state) => ({
      allCompanies: state.companies.map,
      trades: state.trades.map,
      allMembers: state.members.map,
      uiParams: state.ui.uiParams,
      rtl: state.app.rtl,
      subCategories: state.quasiStatics.subCategoriesMap,
      checklists: state.checklists.map,
    }),
    { draftValidator, startToast, exportPostsPDF, onDraftModeChange, track }
  )
);
export default enhance(PostsPage);

PostsPage.defaultProps = {
  defaultGroupBy: "trade",
};
