import Color from  'color';
const brandDanger = '#E57373';
export default {
  circleBorderRadius: 10,
  bold: 'bold',
  fontSize: 16,
  smallFontSize: 13,
  lineHeight: 1.5,
  rowHeight: 60,
  transparent: 'transparent',
  textColor: '#272727',
  //textColor: '#fff',
  foregroundColor: '#ffffff',//#616867',
  //foregroundColor: '#272727',//#616867',
  backgroundColor: '#f3f3f3',//'#dddddd',
  headerColor: '#272727',
  headerTextColor: '#fff',
  headerInfoText: '#f0f0f0', // TODO: Replace header with inverse 
  oldHeaderInfoText: '#e5e5e5',
  inverseTextColor: '#eee',
  inverseBackgroundColor: '#2C2C2C',
  placeholderTextColor: '#BDBDBD',
  //iconColor: '#FDFEFD',
  iconColor: '#272727',
  get commentsBackColor() { return this.easyLight(this.foregroundColor); },

  backgroundColorSelected: '#fffaf6',
  backgroundColorHover: '#f3e5da',

  brandPrimary: '#F77D04',
  brandNeutral: '#ECECEC',
  brandSuccess: '#81C784',
  bransSuccessDark: '#18b251',
  brandInfo: '#3A423A',
  brandWarning: '#FFB300',
  brandDanger,
  brandRealDanger: '#FF0000',
  softBackground: '#75777a',
  clickable: '#3a51c4',

  commentColor: '',
  get listHeader() { return ({ fontSize: this.smallFontSize, color: this.infoText, fontWeight: 'bold'})},

  activeOpacity: .5,
  disabledOpacity: .3,

  easyLighter: .05,
  easyDarken: .15,
  darken: .3,
  lighten: .3,

  separatorColor: '#E0E0E0',
  darkSeparatorColor: '#c3c3c3',
  get lighterDarkSeparatorColor() { return this.darkSeparatorColor + 90 },
  
  margin: 15,
  padding: 10,
  biggerPadding: 15,
  largePadding: 150,
  get paddingX2() { return this.padding * 2 },
  verticalMargin: 5,
  horizontalMargin: 2,
  smallMargin: 2,
  actionBarHeader: 40,
  //get placeholderTextColor() { return this.light(this.textColor); },
  //get infoText() { return this.light(this.textColor); },
  infoText: '#6b6565',
  borderRadius:8,
  thinBorderWidth: 1,
  mediumBorderWidth: 2,
  locationsBorderRadius: 5,
  newBorderRadius: 5,
  asteriskStyle: {
    color: brandDanger,
    position: 'absolute',
    left: -6,
    top: -6
  },
  shadow:{
    elevation: 2, shadowColor: "black", shadowRadius: 2, shadowOffset: { width: 0, height: 1 }, shadowOpacity: 0.2
  },
  hardShadow:{
    elevation: 2, shadowColor: "black", shadowRadius: 2, shadowOffset: { width: 20, height: 20 }, shadowOpacity: 0.8
  },

  get headerBarStyle() { 
    return {
      headerStyle: {
        backgroundColor: this.headerColor
      },
      headerTitleStyle: {
        color: this.headerTextColor
      },
      headerTintColor: this.headerTextColor,
    };
  }, 

  get headerTabsStyle() { 
    return {
      activeTintColor: this.brandPrimary,
      inactiveTintColor: this.headerTextColor,
      inactiveBackgroundColor: this.headerColor,
      style: {
        backgroundColor: this.headerColor,
      },
      indicatorStyle: {
        backgroundColor: this.brandPrimary,
      }
    };
  }, 

  get BottomTabsStyle() { 
    return {
        activeTintColor: this.brandPrimary,
        inactiveTintColor: this.headerTextColor,
        inactiveBackgroundColor: this.headerColor,
        labelStyle: {
          margin: 0,
          padding: 0
        },
        iconStyle : {
          margin: 0,
          padding: 0
        },
        style: {
          backgroundColor: this.headerColor,
        },
        indicatorStyle: {
          backgroundColor: this.brandPrimary,
          position: 'absolute',
          top: 0,
        }
    };
  }, 



  //get fontSmallH5() { return this.fontSize * 17.3; }, // 40Ï
  get fontSmallH6() { return this.smallFontSize * 1.1; }, // 14.3
  get fontVerySmall() { return this.smallFontSize - 2; }, // 11

  get fontSizeH1() { return this.fontSize * 2.5; }, // 40Ï
  get fontSizeH2() { return this.fontSize * 2; }, // 32
  get fontSizeH3() { return this.fontSize * 1.75; }, // 28
  get fontSizeH4() { return this.fontSize * 1.5; }, // 24
  get fontSizeH5() { return this.fontSize * 1.25; }, // 20
  get fontSizeH6() { return this.fontSize * 1.1; }, // 17.6
  get fontSizeH7() { return this.fontSize * 1; }, // 16





   easyDark(color) { return Color(color).darken(this.easyDarken); },
   dark(color) { return Color(color).darken(this.darken); },
   light(color) { return Color(color).lighten(this.lighten); },
   easyLight(color) { return Color(color).darken(this.easyLighter); },

   nothingToDisplayHeight: 70,
};
