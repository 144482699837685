import _ from 'lodash';
import { POSTS_TYPES, SAFETY_TRADE_ID } from '../app/constants';
import issuesMessages from '../issues/issuesMessages';
import safetyMessages from '../safety/safetyMessages';
import propertiesMessages from './propertiesMessages';

export const STRING                                                     = 'String';
export const NUMBER                                                     = 'Number';
export const BOOLEAN                                                    = 'Boolean';
export const DATE                                                       = 'Date';
export const PDF                                                        = 'PDF';
export const PICTURE                                                    = 'Picture';
export const VIDEO                                                      = 'Video';
export const CERTIFICATION                                              = 'Certification';
export const SELECTION_LIST                                             = 'SelectionList';
export const DRAWINGS_ARRAY                                             = 'DrawingsArray';
export const FILES_ARRAY                                                = 'FilesArray';
export const SIGNATURE                                                  = 'Signature';
export const COMPANIES_DAILY_LOG                                        = 'CompaniesDailyLog';
export const TIME_RANGE                                                 = 'TimeRange';
export const ARRAY                                                      = 'Array';
export const COMPLEX                                                    = 'Complex';
export const LOCATION                                                   = 'Location';
export const DATE_RANGE                                                 = 'DateRange';

export const VALID_PROPERTIES_ARRAY  = [ // Any property type added up there should also be added to this array
    STRING,
    NUMBER,
    BOOLEAN,
    DATE,
    PDF,
    PICTURE,
    VIDEO,
    CERTIFICATION,
    SELECTION_LIST,
    // DRAWINGS_ARRAY,
    FILES_ARRAY,
    SIGNATURE,
    COMPANIES_DAILY_LOG,
    TIME_RANGE,
    ARRAY,
    COMPLEX,
    LOCATION,
];

export const SUBJECT_TYPES = {
  locations: 'locations',
  employees: 'employees',
  equipment: 'equipment',
  checklistItems: 'checklistItems',
  posts: 'posts',
  companies: 'companies',
  projects: 'projects',
  forms: 'forms',
};

export const SUBJECT_NAMES = _.mapValues(SUBJECT_TYPES, (subjectType) => `${subjectType}Info`);

export const PROPERTIES_VIEW_TYPES = {
  array: 'array',
  string: 'string',
};

export const isMultiSupportedTypes = {
  [SELECTION_LIST]: SELECTION_LIST,
};

// SIGNING METHODS:

export const UPLOAD_FILE = 'picture'; // regular behaviour...
export const SIGN_ON_PICTURE = 'signOnPicture';
export const SIGN_ON_TEXT = 'cementoSign';

export const CEMENTO_SIGN_BEHAVIOUR = {
  complex: 'complex',
  oneSignature: 'oneSignature',
  oneMandatorySignature: 'oneMandatorySignature',
};
// CERTIFICATIONS LANGUAGES

export const LANGUAGES_MAP = {
  en: { code: 'en', name: 'English' },
  es: { code: 'es', name: 'Español' },
  he: { code: 'he', name: 'Hebrew' },
  ru: { code: 'ru', name: 'Russian' },
  zh: { code: 'zh', name: 'Chinese' },
  tr: { code: 'tr', name: 'Turkish' },
  ro: { code: 'ro', name: 'Romanian' },
  ti: { code: 'ti', name: 'Tigrinya' },
};

export const CERTIFICATIONS_TTL_TYPES = {
  'certificationMonthsTTL': 'certificationMonthsTTL',
  'certificationDaysTTL': 'certificationDaysTTL',
};

export const CERTIFICATIONS_TYPES = {
  roleAppointment: 'roleAppointment',
};

export const SEVERITY_VALUES = {
  '1': {
    id: '1',
    title: safetyMessages.severity.title['1'],
  },
  '2': {
    id: '2',
    title: safetyMessages.severity.title['2'],
  },
  '3': {
    id: '3',
    title: safetyMessages.severity.title['3'],
  },
};

export const STATUS_VALUES = {
  100: {
    id: 100,
    title: issuesMessages.closeStatus,
  },
  200: {
    id: 200,
    title: issuesMessages.resolveStatus,
  },
  300: {
    id: 300,
    title: issuesMessages.openStatus,
  },
};

export const UNIVERAL_IDS = {
  isActive: 'isActive',
  status: 'status',
  issueState: 'issueState',
  trade: 'trade.id',
};

export const BUSINESS_TYPES = {
  companies: 'companies',
  trades: 'trades',
  status: 'status',
  users: 'users',
  locations: 'locations',
  members: 'members',
  serialNumber: 'serialNumber',
  severity: 'severity',
  subTrade: 'subTrade',
};

export const TAGS = {
  counter: 'counter',
};

export const GROUP_PROP_ID = 'groups';

export const AVAILABLE_BUSINESS_TYPES = {
  serialNumber: BUSINESS_TYPES.serialNumber,
};

export const SYSTEM_SUBJECTS_TYPES = [
  'locationsInfo',
  'employeesInfo',
  'equipmentInfo',
  'projectsInfo',
  'companiesInfo',
  'formsInfo',
  'postsInfo',
];

export const MOCK_PROPERTY_TYPES_SECTIONS = {
  postsInfo: { 'general': { title: { 'he': 'מידע כללי', 'en': 'General' }, id: 'general' } },
};

export const MOCK_PROPERTY_TYPES = {
  postsInfo: {
    'title': {
      title: propertiesMessages.description,
      isLocalMock: true,
      id: 'title',
      type: STRING,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'title',
      settings: { 'widthRatio': 2, 'dataSource': 'object' },
      ordinalNo: 0.04,
    },
    'issueState': {
      title: propertiesMessages.status,
      isLocalMock: true,
      id: 'issueState',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'issueState',
      businessType: BUSINESS_TYPES.status,
      settings: { 'widthRatio': 1.25, 'dataSource': 'object' },
      ordinalNo: 0.03,
    },
    'createdAt': {
      title: propertiesMessages.createdAt,
      isLocalMock: true,
      id: 'createdAt',
      type: DATE,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'createdAt',
      settings: { 'dataSource': 'object' },
      ordinalNo: 0.08,
    },
    'trade': {
      isPrimary: true,
      pathInObject: 'trade.id',
      title: propertiesMessages.trade,
      isLocalMock: true,
      id: 'trade',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: UNIVERAL_IDS.trade,
      displayType: 'Trade',
      businessType: BUSINESS_TYPES.trades,
      settings: { 'dataSource': 'object', 'widthRatio': 4 },
      ordinalNo: 0.01,
    },
    'category': {
      title: propertiesMessages.category,
      isLocalMock: true,
      id: 'category',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'subCategory.id',
      businessType: BUSINESS_TYPES.subTrade,
      settings: { 'tradeId': SAFETY_TRADE_ID },
      ordinalNo: 0.02,
    },
    'locationId': {
      title: propertiesMessages.location,
      isLocalMock: true,
      id: 'locationId',
      type: LOCATION,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'locationId',
      settings: { 'widthRatio': 1.5, 'dataSource': 'object', 'showInFilters': false },
      ordinalNo: 0.05,
    },
    'assignTo': {
      title: propertiesMessages.assigneeUser,
      isLocalMock: true,
      id: 'assignTo',
      type: STRING,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'assignTo.displayName',
      settings: { 'dataSource': 'object' },
      ordinalNo: 0.06,
    },
    'dueDate': {
      title: propertiesMessages.dueDate,
      isLocalMock: true,
      id: 'dueDate',
      type: DATE,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'dueDate',
      settings: { 'dataSource': 'object' },
      ordinalNo: 0.09,
    },
    'checklistFullTitle': {
      title: propertiesMessages.checklistFullTitle,
      isLocalMock: true,
      id: 'checklistFullTitle',
      type: STRING,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'checklistFullTitle',
      settings: { 'widthRatio': 1.5, 'dataSource': 'object' },
      ordinalNo: 0.12,
    },
    'owner': {
      title: propertiesMessages.creatorUser,
      isLocalMock: true,
      id: 'owner',
      type: STRING,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'owner.displayName',
      settings: { 'dataSource': 'object' },
      ordinalNo: 0.1,
    },
    'assignToCompany': {
      title: propertiesMessages.assigneeCompany,
      isLocalMock: true,
      id: 'assignToCompany',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'assignTo.companyId',
      businessType: BUSINESS_TYPES.companies,
      ordinalNo: 0.07,
    },
    'ownerCompany': {
      title: propertiesMessages.creatorCompany,
      isLocalMock: true,
      id: 'ownerCompany',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'owner.companyId',
      businessType: BUSINESS_TYPES.companies,
      ordinalNo: 0.11,
    },
    'severity': {
      title: propertiesMessages.severity,
      isLocalMock: true,
      id: 'severity',
      type: SELECTION_LIST,
      sectionId: MOCK_PROPERTY_TYPES_SECTIONS.postsInfo.general.id,
      universalId: 'severity',
      businessType: BUSINESS_TYPES.severity,
      settings: { 'showAllValuesInFilters': true },
      ordinalNo: 0.13,
    },
  },
};

export const RECORDS_BLACKLIST_COLUMNS = [
  MOCK_PROPERTY_TYPES.postsInfo.assignTo.id,
  MOCK_PROPERTY_TYPES.postsInfo.dueDate.id,
  MOCK_PROPERTY_TYPES.postsInfo.issueState.id,
  MOCK_PROPERTY_TYPES.postsInfo.assignToCompany.id,
  MOCK_PROPERTY_TYPES.postsInfo.severity.id,
  MOCK_PROPERTY_TYPES.postsInfo.category.id,
];

export const NOT_SAFETY_POSTS_BLACKLIST_COLUMNS = [
  MOCK_PROPERTY_TYPES.postsInfo.severity.id,
  MOCK_PROPERTY_TYPES.postsInfo.category.id,
];

export const MOCK_PROPERTY_MAPPINGS = {
  postsInfo: {
    [MOCK_PROPERTY_TYPES.postsInfo.trade.id]: {
      [SAFETY_TRADE_ID]: {
        properties: [MOCK_PROPERTY_TYPES.postsInfo.trade.id],
      },
    },
    'groups': {
      [POSTS_TYPES.ISSUE]: {
        properties: [
          'groups',
          ..._.map(
            MOCK_PROPERTY_TYPES.postsInfo,
            (prop) => !NOT_SAFETY_POSTS_BLACKLIST_COLUMNS.includes(prop.id) && prop.id
          ).filter(Boolean),
        ],
      },
      [POSTS_TYPES.RECORD]: {
        properties: [
          'groups',
          ..._.map(
            MOCK_PROPERTY_TYPES.postsInfo,
            (prop) => !RECORDS_BLACKLIST_COLUMNS.includes(prop.id) && prop.id
          ).filter(Boolean),
        ],
      },
      [POSTS_TYPES.SAFETY_TASK]: {
        properties: ['groups', ..._.map(MOCK_PROPERTY_TYPES.postsInfo, (prop) => prop.id)],
      },
      [POSTS_TYPES.SAFETY_RECORD]: {
        properties: [
          'groups',
          ..._.map(
            MOCK_PROPERTY_TYPES.postsInfo,
            (prop) => !RECORDS_BLACKLIST_COLUMNS.includes(prop.id) && prop.id
          ).filter(Boolean),
        ],
      },
    },
  },
};

/**
 *
 * @param {string} type
 * @param {any} value
 * @returns
 */
export const normalizeInputValue = (type, value) => {
  let normalizedValue = value;

  if (value) {
    switch (type) {
      case STRING:
        if (typeof value !== 'string') normalizedValue = null;
        break;

      case SELECTION_LIST:
        if (typeof value !== 'object' || Array.isArray(value)) normalizedValue = null;
        break;

      default:
        break;
    }
  }

  return normalizedValue;
};

export const generateQueryParamsForInitialPropertyInstancesFetch = (subjectName) => {
  const queryParams = {};
  // Only fetch open formsInfo instances
  if (subjectName === 'formsInfo') {
    queryParams.parentStatus = [200, 250, 300];
  }
  return queryParams;
};
