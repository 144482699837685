import React from 'react';
import theme from '../../../assets/css/theme';
import Text from '../Text';


class TextToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setComponentData(this.props, nextProps);
  // }

  setComponentData = (props, nextProps) => {
    const { firstMount } = props;

    let newStateChanges = {};
    
    if (firstMount)
      newStateChanges.value = nextProps.defaultValue;

    if (Object.values(newStateChanges).length)
      this.setState(newStateChanges);
  }

  handleOptionClick = (selectedOption) => {
    const { onChange } = this.props;
    this.setState({ value: selectedOption });

    if (onChange)
      onChange(selectedOption);
  }

  render() {
    const { options } = this.props;
    const { value } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {(options).map((currOption, currIndex) => {
          let after = null;
          if (currIndex + 1 !== options.length)
            after = <div style={{ margin: '0 ' + theme.verticalMargin * 2 + 'px', color: theme.brandNeutral }}>|</div>

          const isActive = (value || {}).id === currOption.id;
          return (
            <>
            <Text onClick={() => this.handleOptionClick(currOption)} style={{ color: isActive ? theme.brandPrimary : theme.brandNeutral, cursor: 'pointer' }}>{currOption.title}</Text>
            {after}
            </>
          )
        })}
      </div>
    );
  }
}

export default TextToggle;