import { getAppLang } from './app/funcs';

const enhancePlatformActions = (platformActions) => {
  let enhancedPlatformActions = Object.assign({}, platformActions, {
    app: Object.assign({}, platformActions.app),
  });

  const isWeb = platformActions.app.getPlatform() === 'web';
  enhancedPlatformActions.app.isWeb = () => isWeb;

  const isNative = !isWeb;
  enhancedPlatformActions.app.isNative = () => isNative;

  const isAndroid = platformActions.app.getPlatform() === 'android';
  enhancedPlatformActions.app.isAndroid = () => isAndroid;

  const isIOS = platformActions.app.getPlatform() === 'ios';
  enhancedPlatformActions.app.isIOS = () => isIOS;

  enhancedPlatformActions.app.getLang = getAppLang;

  return enhancedPlatformActions;
};

let platformActions = null;
const init = (actions, config) => {
  platformActions = enhancePlatformActions(actions);
  platformActions.init(config);
  if (process.env.NODE_ENV === 'development') {
    globalThis.__platformActions = platformActions;
  }
};

export { platformActions, init };
