import React from 'react';
import dragger from '../../assets/img/icons/dragger.png';

function Dragger(props) {
  const { style = {}, keyId = null, dragId = 'dragger' } = props;

  return (
    <>
      <img key={keyId} src={dragger} id={dragId} style={{ ...draggerStyles, ...style }} />
    </>
  );
}

const draggerStyles = {
  cursor: 'move',
  opacity: 0.15,
  height: '20px',
};

export default Dragger;