import checklistItemMessages from '../checklistItems/checklistItemMessages.js';
import * as checklistTypes from './checklistTypes.js';
import * as cliStatus from '../checklistItemsInstances/clItemInstanceStatus.js';
import * as permissionsFunc from '../permissions/funcs';
import theme from '../app/theme';
import { getAppState } from '../configureMiddleware';
import { platformActions } from '../platformActions';
import { getDateString, isEmptyValue } from '../app/funcs';
import _ from 'lodash';
import { ALL_BUILDINGS_ID } from '../app/constants.js';


export function getChecklistItemInstances(checklistId, filteredChkInstances, targetTS) {
  let ret = filteredChkInstances.filter(x => !x.isDeleted && x.checklistId == checklistId && (!targetTS || x.targetTS == targetTS));
  return (ret.length) ? ret[0] : null;
}


function isItemRelevantInLocation(locationId, item) {
  let isRelevant = true;
  if (item.onlyLocations) {
    isRelevant = _.some(item.onlyLocations, locationsInCurrLocationType => {
      return _.some(locationsInCurrLocationType, (location, currLocationId) => currLocationId == locationId);
    });
  }
  return isRelevant;
}

export function getMandatoryOptionalItemsStatuses({ checklistId, checklistItems, inFilteredChkInstances, targetTS = null, locationId } = {}) {
  const filteredChkInstances = Object.values(inFilteredChkInstances.toJS ? inFilteredChkInstances.toJS() : inFilteredChkInstances);
  const allChecklistItems = Object.values(checklistItems || {})
    .filter(item => Boolean(_.get(item, ['checklistIds', checklistId] && isItemRelevantInLocation(locationId, item))));
  const allMandatoryChecklistItems = allChecklistItems
    .filter(item => Boolean(item && item.isMandatory));
  const allChecklistItemsIdsWithStatuses = filteredChkInstances
    .filter(itemInstance => !itemInstance.isDeleted && itemInstance.checklistId === checklistId && (!targetTS || itemInstance.targetTS === targetTS) && itemInstance.status !== cliStatus.CLI_STATUS_NONE)
    .map(itemInstance => itemInstance.checklistItemId);
  const mandatoryItemsWithInstances = allMandatoryChecklistItems
    .filter(item => allChecklistItemsIdsWithStatuses.includes(item.id));

  return { allItemsStatus: Boolean(allChecklistItemsIdsWithStatuses.length >= allChecklistItems.length - 1), mandatoryItemsStatus: Boolean(allMandatoryChecklistItems.length === mandatoryItemsWithInstances.length) };
  // -1 because there is an extra item that is hidden
}


export function getPrevAndCurrentInstances(checklistType, targetTS, inFilteredChkInstances, checklistId) {
  let currInstance = null;
  let prevInstance = null;

  let filteredChkInstances = Object.values(inFilteredChkInstances.toJS ? Object.values(inFilteredChkInstances.toJS()) : inFilteredChkInstances);
  let checklistItemInstancesFound = filteredChkInstances.filter(x => !x.isDeleted && x.checklistId == checklistId && x.targetTS <= targetTS).sort((a,b) => ((b.targetTS || 0) - (a.targetTS || 0)));
  if (checklistItemInstancesFound.length > 0) {
    if (checklistItemInstancesFound[0].targetTS == targetTS)
      currInstance = checklistItemInstancesFound[0];
    else
      prevInstance = checklistItemInstancesFound[0];
  }
  if (checklistItemInstancesFound.length > 1 && !prevInstance)
    prevInstance = checklistItemInstancesFound[1];

  return { currInstance, prevInstance };
}

// export function getLast_N_Instances(targetTS, N, filteredChkInstances, checklistId) {
//   var arrInstancesToReturn = [];
//   filteredChkInstances.forEach(instance => {
//     if ((!instance.targetTS || instance.targetTS <= targetTS) && (instance.getNested(["checklistId"]) == checklistId))
//       arrInstancesToReturn.push(instance);
    
//     return (arrInstancesToReturn.length < N)
//   })

//   for (var fillIndex = arrInstancesToReturn.length; fillIndex < N; fillIndex++)
//     arrInstancesToReturn.push(null);

//   return arrInstancesToReturn;
// }

export function prepareLocationsChecklistsStatusMap(locationsChecklistsStatus, projectId, projectBuildings, projectFloors, projectUnits) {
  const minPercentWidth = 15;
  // const yellow = '#f4c441' // ffe100
  // const green = '#1bad5f'
  // const red = '#E57373'; //'#f46541'
  // const gray = '#aaaaaa';
  var map = locationsChecklistsStatus.getNested([projectId, 'statusMap'], null)
  var checklistsStatusMap = {};

  if (!map)
    return null;

  map.loopEach((locationId, currLocation) => {

    if (currLocation) {

      let issuesCounter = currLocation.issuesCounter;
      let colorsFillData = {};
      let initDummy = 1;
      let totalItems = currLocation.totalItems;
      if (!currLocation.totalItems) {
        initDummy = 0;
        totalItems = 1;
      }

      colorsFillData[cliStatus.CLI_STATUS_REJECTED] = { percent: Math.floor(initDummy * currLocation.rejectedItems * 100 / currLocation.totalItems), color: theme.brandDanger };
      colorsFillData[cliStatus.CLI_STATUS_RESOLVED] = { percent: Math.floor(initDummy * currLocation.resolvedItems * 100 / currLocation.totalItems), color: theme.brandWarning };
      colorsFillData[cliStatus.CLI_STATUS_CONFIRMED] = { percent: Math.floor(!initDummy ? 100 : currLocation.confirmedItems * 100 / currLocation.totalItems), color: theme.brandSuccess };
      colorsFillData[cliStatus.CLI_STATUS_CONFIRMED_2] = { percent: Math.floor(initDummy * currLocation.confirmed2Items * 100 / currLocation.totalItems), color: theme.bransSuccessDark };

      var resolveOrConfirmed = (colorsFillData[cliStatus.CLI_STATUS_RESOLVED].percent || 0) + (colorsFillData[cliStatus.CLI_STATUS_CONFIRMED].percent || 0) + (colorsFillData[cliStatus.CLI_STATUS_CONFIRMED_2].percent || 0);
      let fillText = (0 < resolveOrConfirmed) ? (String(resolveOrConfirmed) + "%") : '';

      // TODO: Return this code if we ever want to have a minimum height of line again Fix the minwidth to be ganery
      // if (false & currLocation.rejectedItems > 0 && currLocation.confirmedItems >= 0) {
      //   secondaryFill.minimumActive = minPercentWidth > Math.floor(currLocation.rejectedItems * 100 / currLocation.totalItems);
      //   secondaryFill.percent = Math.max(minPercentWidth, Math.floor(currLocation.rejectedItems * 100 / currLocation.totalItems)); 
      //   primaryFill.decreasedBySecondary = secondaryFill.percent + primaryFill.percent > 100;
      //   primaryFill.percent = primaryFill.decreasedBySecondary ? 100 - minPercentWidth : primaryFill.percent; 
      // }

      checklistsStatusMap[locationId] = { colorsFillData, fillText, issuesCounter };
    }
  })

  checklistsStatusMap[ALL_BUILDINGS_ID] = { issuesCounter: 0 };
  (projectBuildings || {}).getNested([projectId], {}).loopEach((bid, b) => {
    let floorNumMap = {};
    (projectFloors || {}).loopEach((fid, f) => { floorNumMap[f.num] = fid; });
    (projectUnits || {}).loopEach((uid, u) => {
      let unitStatus = checklistsStatusMap[uid];
      if (unitStatus && unitStatus.issuesCounter) {

        if (!checklistsStatusMap[bid])
          checklistsStatusMap[bid] = { issuesCounter: 0 }
        checklistsStatusMap[bid].issuesCounter += unitStatus.issuesCounter;
        checklistsStatusMap[ALL_BUILDINGS_ID].issuesCounter += unitStatus.issuesCounter;

        if (u.floor.num) {
          let floorId = floorNumMap[u.floor.num];
          if (!checklistsStatusMap[floorId])
            checklistsStatusMap[floorId] = { issuesCounter: 0 }
          else if (!checklistsStatusMap[floorId].issuesCounter)
            checklistsStatusMap[floorId].issuesCounter = 0
          checklistsStatusMap[floorId].issuesCounter += unitStatus.issuesCounter;
        }
      }
    });
  })

  return checklistsStatusMap;
}


export function createItemIndications(targetTS, checklistType, intl, relevantPeriodTimes, currInstance, prevInstance) {

  var attentionBadge = null;
  var previousInstanceBadge = null;
  var millisecondsInDay = 24 * 3600000;

  if (targetTS && checklistType == checklistTypes.ROUTINE) {
    var prevInstanceOutsidePeriodRange = (!prevInstance);
    if (prevInstance && prevInstance.status != cliStatus.CLI_STATUS_NONE) {
      previousInstanceBadge = (prevInstance.status === cliStatus.CLI_STATUS_REJECTED) ? checklistItemMessages.previousStatusAlert.rejected :
        (prevInstance.status === cliStatus.CLI_STATUS_REJECTED_PLUS) ? checklistItemMessages.previousStatusAlert.rejectedPlus : null;
        if (previousInstanceBadge && prevInstance.targetTS)
          previousInstanceBadge = intl.formatMessage(checklistItemMessages.previousStatusAlert.rejectedWithDate, {date:getDateString(prevInstance.targetTS, intl)});

      prevInstanceOutsidePeriodRange = (!relevantPeriodTimes) || (prevInstance.targetTS < relevantPeriodTimes.start);
    }

    if (prevInstanceOutsidePeriodRange && (!currInstance || currInstance.status == cliStatus.CLI_STATUS_NONE) && relevantPeriodTimes) {
      var daysCount = Math.floor((relevantPeriodTimes.end - targetTS) / millisecondsInDay);
      attentionBadge = (daysCount <= relevantPeriodTimes.countOfDaysForAlert) ?
        (daysCount == 1 ?
          checklistItemMessages.immediatPeriodAlert :
          intl.formatMessage(checklistItemMessages.periodAlert, { daysCount: String(daysCount) })) :
        null;

    }
  }

  return ({ attentionBadge, previousInstanceBadge })
}

export function getToggleButtonStatusesArray(viewer, checklistItem, selectedProjectId, status, showOnlyHighestStatus, extraDataMode) {
  let statusesArray = [];
  let showClearAndReject = Boolean(extraDataMode);

  //let ret = permissionsFunc.checklitItemActionsPermission(viewer, checklistItem, selectedProjectId, "checklistItems", "confirm"); // TODO: Maybe we should use this func instead the general one
  let showConfirmed2 = false;
  let showConfirmed = false;
  if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm2", checklistItem)) {
    statusesArray.push({ status: cliStatus.CLI_STATUS_CONFIRMED_2, disabled: status <= cliStatus.CLI_STATUS_CONFIRMED_2 });
    showClearAndReject = showClearAndReject || status >= cliStatus.CLI_STATUS_CONFIRMED_2;
    showConfirmed2 = true;
  }

  if ((!showConfirmed2 || !showOnlyHighestStatus) && permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "confirm", checklistItem)) {
    statusesArray.push({ status: cliStatus.CLI_STATUS_CONFIRMED, disabled: status <= cliStatus.CLI_STATUS_CONFIRMED && status != cliStatus.CLI_STATUS_PARTIAL });
    showClearAndReject = showClearAndReject || status >= cliStatus.CLI_STATUS_CONFIRMED;
    showConfirmed = true;
  }

  if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "partial", checklistItem)) {
    statusesArray.push({ status: cliStatus.CLI_STATUS_PARTIAL, disabled: status <= cliStatus.CLI_STATUS_PARTIAL && status != cliStatus.CLI_STATUS_CONFIRMED });
    showClearAndReject = showClearAndReject || status >= cliStatus.CLI_STATUS_PARTIAL;
  }

  if (((!showConfirmed2 && !showConfirmed) || !showOnlyHighestStatus) && (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "resolve", checklistItem))) {
    statusesArray.push({ status: cliStatus.CLI_STATUS_RESOLVED, disabled: status <= cliStatus.CLI_STATUS_RESOLVED });
    showClearAndReject = showClearAndReject || status >= cliStatus.CLI_STATUS_RESOLVED;
  }

  let emptyStatus = status == cliStatus.CLI_STATUS_NONE || !status;

  if (statusesArray.length > 0) {
    statusesArray.push({ status: cliStatus.CLI_STATUS_REJECTED, disabled: status == cliStatus.CLI_STATUS_REJECTED || (!showClearAndReject && !emptyStatus) });
    if (permissionsFunc.getActionPermissions(viewer, selectedProjectId, "checklistItems", "irrelevant", checklistItem))
      statusesArray.push({ status: cliStatus.CLI_STATUS_IRRELEVANT, disabled: status == cliStatus.CLI_STATUS_IRRELEVANT || (!showClearAndReject && !emptyStatus) });
    statusesArray.push({ status: cliStatus.CLI_STATUS_NONE, disabled: emptyStatus || !showClearAndReject });
  }

  return statusesArray;
}


export function mapExtraDataBeforeUpload(projectId, checklistItem, extraDataValues = {}, currInstance, parentId) {
  let extraData = {};

  const projectProps = permissionsFunc.safeToJS(getAppState().getNested(['propertiesTypes', 'projectProperties', projectId], {}));
  Object.values(checklistItem.extraData || {})
    .forEach(({ id, subjectName }) => {
      const existingInstanceId = currInstance?.extraData?.[id]?.instanceId;
      const prop = projectProps[subjectName]?.[id];
      if (!prop) return;
      const data = extraDataValues[id];
      const isNilEmptyData = isEmptyValue(data);
      if (isNilEmptyData && !existingInstanceId) {
        return;
      }

      const instance = {
        propId: id,
        data: isNilEmptyData ? null : data,
        subjectName,
        propType: prop.type,
      };

      extraData[id] = instance;

      if (existingInstanceId) {
        instance.id = existingInstanceId;
      }

      if(subjectName == 'checklistItemsInfo')
        instance.parentId = checklistItem.id;
      else
        instance.parentId = parentId;
    });

  return extraData;
}
