import { realmInstance } from "../../configureMiddleware";
import { platformActions } from "../../platformActions";
import { saveLocal } from "../utils/utils";

/* 
  retryObjects is a collections of all types of objects that we tried to modify while being offline so we save the original object in this collection 
  so that when we retry we can compare the new object with the old one and update firebase with only the properties that have changed instead of having to replace the full object
*/

export const getRetryObjects = ({ objectType, projectId }) => {
  let retryObjects = {};
  let success = true;
  if (objectType && projectId) {
    if (platformActions.app.getPlatform() != 'web') {
      const realmRetriesCollection = realmInstance['retryObjects'].objects('retryObjects');

      const savedRetryObjects = realmRetriesCollection.filtered(`projectId == "${projectId}" AND type == "${objectType}"`);
      try {
        savedRetryObjects.forEach(retryObject => retryObjects[retryObject.id] = JSON.parse(retryObject.json));
      }
      catch (error) {
        success = false;
        platformActions.sentry.notify(`Something went horibly wrong with trying to get ${objectType} in retries`, { objectType, projectId, error });
        console.warn(`Something went horibly wrong with trying to get ${objectType} in retries`, { objectType, projectId, error });
      }
    }
  }
  else
    success = false;

  return { retryObjects, success };
}


export const setRetryObjects = ({ retryObjects, objectType, projectId }) => {
  const alreadyExistingRetryObjects = getRetryObjects({ objectType, projectId }).retryObjects;

  let objectsToSave = Object.values(retryObjects || {}).filter(o => Boolean(o && !alreadyExistingRetryObjects[o.id]));
  let success = true;
  try {
    objectsToSave = objectsToSave.map(objectToSave => ({ id: objectToSave.id, json: JSON.stringify(objectToSave), type: objectType, projectId }));
  }
  catch (error) {
    success = false;
    platformActions.sentry.notify(`Something went horibly wrong with trying to save ${objectType} in retries`, { retryObjects, objectType, projectId, error });
    console.warn(`Something went horibly wrong with trying to save ${objectType} in retries`, { retryObjects, objectType, projectId, error });
  }

  if (objectsToSave.length && projectId && objectType)
    saveLocal({
      objectsToSave,
      schemaName: 'retryObjects',
      schemaType: 'retryObjects',
      projectId,
    });

  return { retryObjects, success };
}

export const deleteRetryObjects = ({ retryObjects, projectId }) => {
  saveLocal({
    objectsToSave: Object.values(retryObjects || {}).map(o => ({ id: o.id, isDeleted: true })),
    schemaName: 'retryObjects',
    schemaType: 'retryObjects',
    projectId,
  });
}