import { isEmptyValue } from '../app/funcs';
import { getAppState } from '../configureMiddleware';
import ClientServerConnectivityManagerInstance from '../lib/ClientServerConnectivityManager';
import { platformActions } from '../platformActions';
import _ from 'lodash';

/**
 * 
 * @param {'projects' | 'companies' | 'global'} scope 
 * @param {string} scopeId 
 * @returns 
 */
export const getClientMSConfig = (scope, scopeId) => {
  let path = [];
  if (scope === 'global') {
    path = [
      "configurations",
      'global',
      "clientMS",
      "V2",
    ]
  } else {
    path = [
      "configurations",
      scope === 'companies' ? 'companiesMap' : 'map',
      scopeId,
      "clientMS",
      "V2",
    ];
  }

  return getAppState?.()?.getNested?.(path);
}


/**
 * 
 * @param {'projects' | 'companies' | 'global'} scope 
 * @param {string | undefined} scopeId 
 * @param {string} resourceName clientMS/(listeners | bulk) subjects
 * @param {'listeners' | 'bulk'} [type] listeners
 * @returns 
 */

export const shouldLastUpdateV2Fetch = (scope, scopeId, resource, params = undefined) => {
  let _shouldLastUpdateV2Fetch = false;
  _shouldLastUpdateV2Fetch = !ClientServerConnectivityManagerInstance.isSubjectRegistered({
    scope,
    scopeId,
    subject: resource,
    params,
  });

  return _shouldLastUpdateV2Fetch;
};

export const getLastUpdateUniqueId = (type, scopeId, subject) => [type, scopeId, subject].join('_');

/**
 * 
 * @param {string} type 
 * @param {string} scopeId 
 * @param {string} subject 
 * @param {{ lastUpdateAvailable?: number, lastUpdated?: number, lastSavedUpdate?: number  }} [dynamicData] 
 */
export const saveLocalLastUpdates = (type, scopeId, subject, dynamicData) => {
  const id = getLastUpdateUniqueId(type, scopeId, subject);
  const localDB = platformActions.localDB.getCementoDB();
  return localDB.set('lastUpdatesV2', [Object.assign({ id, scopeId, subject, type, projectId: scopeId }, dynamicData)]);
}

/**
 * 
 * @param {string} id 
 * @param {LastUpdatesQueryParams & { lastUpdateAvailable?: number, lastUpdated?: number, lastSavedUpdate?: number }} dynamicData 
 * @returns 
 */
export const saveLocalLastUpdatesById = (id, dynamicData) => {
  const localDB = platformActions.localDB.getCementoDB();
  return localDB.set('lastUpdatesV2', [Object.assign({ id }, dynamicData)]);
}

/**
 * 
 * @param {string} type 
 * @param {string} scopeId 
 * @param {string} subject 
 */
export const deleteLocalLastUpdates = (type, scopeId, subject) => {
  const id = getLastUpdateUniqueId(type, scopeId, subject);
  return deleteLocalLastUpdatesById(id);
}

/**
 * @param {{ type: string, scopeId: string, subject: string }[]} lastUpdatesParams
 * @returns 
 */
export const deleteMultiLocalLastUpdates = (lastUpdatesParams) => {
  const ids = lastUpdatesParams.map(({ type, scopeId, subject }) => getLastUpdateUniqueId(type, scopeId, subject));
  return deleteMultiLocalLastUpdatesById(ids);
}



/**
 * @param {string[]} ids 
 */
export const deleteMultiLocalLastUpdatesById = (ids) => {
  if (!ids?.length) {
    return;
  }

  const localDB = platformActions.localDB.getCementoDB();
  
  let query;
  if (platformActions.app.isWeb()) {
    query = { id: { $in: ids } };
  } else {
    query = `id IN {"${ids.join('", "')}"}`;
  }

  return localDB.unset('lastUpdatesV2', query);
}

/**
 * @param {string} id 
 */
export const deleteLocalLastUpdatesById = (id) => {
  return deleteMultiLocalLastUpdatesById([id]);
}



/**
 * @typedef LastUpdatesQueryParams
 * @property {string} [id]
 * @property {string} [type]
 * @property {string} [scopeId]
 * @property {string} [subject]
 * 
 * @param {LastUpdatesQueryParams} queryParams 
 * @returns 
 */
export const getLocalLastUpdates = (queryParams) => {
  const localDB = platformActions.localDB.getCementoDB();
  const isWeb = platformActions.app.isWeb();
  const query = Object.entries(queryParams).reduce((acc, [propName, value]) => {
    if (!isEmptyValue(value)) {
      if (isWeb) {
        acc[propName] = value;
      } else {
        const currQ = `${propName} = "${value}"`;
        acc += acc.length ? ` AND ${currQ}` : currQ;
      }
    }
    return acc;
  }, isWeb ? {} : '');
  return localDB.get('lastUpdatesV2', query, { shouldReturnRawData: true });
}

export const startLastUpdatesStatsListener = (() => {
  let prevViewer = null;
  let fbRef = null;
  let endListeners = null;

  return (viewer) => {
    if (viewer && (!prevViewer || (viewer.id !== prevViewer.id) || !fbRef)) {
      endListeners?.();
      const firebaseDB = platformActions.firebase.getFirebase()?.database();
      prevViewer = viewer;
      const basePath = `_internal/logs/membersLastUpdatesRequests/${viewer.id}`;
      fbRef = firebaseDB.ref(basePath);
      const handleListener = async (snapshot) => {
        const ts = new Date();
        const { subject, scopeId, type } = snapshot.val();
        const lastUpdatesQueryRes = getLocalLastUpdates({ type, scopeId, subject });
        const lastUpdatesObj = { dateTS: ts.toString() };
        lastUpdatesQueryRes?.forEach?.(({ type, scopeId, subject, ...rest }) => {
          _.set(lastUpdatesObj, [type, scopeId, subject], rest);
        });
        await firebaseDB.ref().update({ [`_internal/logs/membersLastUpdates/${viewer.id}/${ts}`]: lastUpdatesObj });
        await firebaseDB.ref().update({ [`${basePath}/${snapshot.key}`]: null });
      }

      fbRef.on('child_added', handleListener);
      fbRef.on('child_changed', handleListener);
      endListeners = () => {
        if (fbRef) {
          fbRef.off('child_added');
          fbRef.off('child_changed');
        }
      };
    }

    return endListeners;
  }
})();


/**
 * Gets the relevant last update docs from the local db for a reducer feature
 * Need this function because the last updates docs are saved in the local db by complex subject structure and the reducer features are very simple and not in the right format
 * 
 * @param {string} scopeId 
 * @param {string} feature 
 * @param {string[]} featurePath 
 * @param {any} featureValue 
 * @returns 
 */
export const getLastUpdateFromFeature = (scopeId, feature, featurePath, featureValue) => {
  if (!['map', 'projectSections', 'projectProperties', 'detailsMap', 'adminUsers'].some(key => featurePath.includes(key))) {
    return [];
  }

  let lastUpdateQuery = {
    scopeId,
  };

  switch (feature) {
    case 'propertiesMappings':
    case 'propertiesTypes': {
      const docs = getLocalLastUpdates({ scopeId });
      let subject = feature === 'propertiesTypes'
        ? 'properties/types'
        : 'properties/mappings';
      let docsToReturn = [];
      docs?.forEach(doc => {
        if (doc.subject.includes(subject)) {
          const subjectType = doc.subject.split('/').pop();
          if (featureValue?.getNested2([subjectType])) {
            docsToReturn.push(doc);
          }
        }
      });
      return docsToReturn;
    }

    case ('stages'): {
      lastUpdateQuery.subject = 'checklists/stages';
      break;
    }

    case ('checklists'): {
      lastUpdateQuery.subject = 'checklists/checklists';
      break;
    }

    case ('checklistItems'): {
      lastUpdateQuery.subject = 'checklists/items';
      break;
    }

    case ('forms'): {
      const docs = getLocalLastUpdates({ scopeId });
      let docsToReturn = [];
      docs?.forEach(doc => {
        if (doc.subject.includes('forms')) {
          docsToReturn.push(doc);
        }
      });
      return docsToReturn;
    }

    case ('permissions'): {
      lastUpdateQuery.subject = 'permissions/v3';
      break;
    }

    default: {
      lastUpdateQuery.subject = feature;
    }
  }

  return getLocalLastUpdates(lastUpdateQuery);
}
