import { Record } from '../transit';

const Stage = Record({
  createdAt: null,
  id: null,
  title: null,
  locations: null,
  includeInGrade: false,
  isDeleted: null,
  ordinalNo: null,
}, 'stage', false);

export default Stage;
