import * as actions from './actions';
import { Record } from '../transit';
import { CLEAR_ALL_DATA } from '../app/actions';

const InitialState = Record({
  appVersion: "0"
}, 'lastUpdates');

const initialState = new InitialState;

export default function lastUpdatesReducer(state = initialState, action) {
  switch (action.type) { 
    case actions.ON_CHECK_APP_VERSION + "_SUCCESS": {
      return state.set('appVersion', action.payload.appVersion);
    }

    case CLEAR_ALL_DATA + '_SUCCESS': {
      return initialState;
    }
	}
	 
  return state;
}
