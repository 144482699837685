export default class ExtraError {
  /**
   * 
   * @param  {[message: string, metaData: {}, error: Error, errorCode: string | number]} args 
   * @returns 
   */
  constructor(...args) {
  	this.metadata = {};

    if (!args)
  		return;

    this.metadata.errorMessage = args[0];

    if (args[1])
    	this.metadata = Object.assign({}, this.metadata, args[1]);

    if (args[2] && typeof args[2] === "object")
      this.innerError = args[2];
    else
      this.innerError = new Error(args[0]);
    
    this.errorCode = args[3] || errorCodes.GENERAL;
  }
};

export const errorCodes = {
  MISSING_FILE: 'MISSING_FILE',
  GENERAL: 'GENERAL'
};