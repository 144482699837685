import _ from "lodash";
export const TRACK = "TRACK";
export const BAD_TRACK = "BAD_TRACK";
export const NOTIFY_ERROR = "NOTIFY_ERROR";
export const INIT_USER_DATA = "INIT_USER_DATA";
export const TRACK_START = "TRACK_START";
export const TRACK_END = "TRACK_END";
export const ON_SET_VIEWER_SCOPE = "ON_SET_VIEWER_SCOPE";

export function initData(user) {
  return ({ sentry, initUserLogsData, logRocket, firebaseAnalytics }) => {
		let slimUser = _.pick(user, ['id', 'displayName', 'email', 'phoneNumber']);
		slimUser.username = slimUser.displayName;

		initUserLogsData(slimUser);

		if (logRocket) {
			logRocket.identify(user.id, {
				name: user.displayName,
				phoneNumber: user.phoneNumber,
			});
		}

		if (firebaseAnalytics) {
			firebaseAnalytics.setUserId(user.id);
			firebaseAnalytics.setUserProperties({ displayName: user.displayName });
		}

		if (sentry) {
			sentry.configureScope(function (scope) {
				scope.setUser(slimUser);
			});
		}
		return {
			type: INIT_USER_DATA,
			payload: { user },
		};
	};
}

export function trackStart(trackTitle) {
  trackTitle = trackTitle ? trackTitle : "sessionEnded";
  return {
    type: TRACK_START,
    payload: { trackTitle },
  };
}

export function trackEnd(trackTitle) {
  return ({ firebaseAnalytics }) => {
    trackTitle = trackTitle ? trackTitle : "sessionEnded";
    if (firebaseAnalytics) firebaseAnalytics.logEvent(trackTitle);
    return {
      type: TRACK_END,
      payload: { trackTitle },
    };
  };
}

export function setReportingScope(data) {
  return ({ getState }) => {
    if (!data.viewer)
      return {
        type: ON_SET_VIEWER_SCOPE + "_ERROR",
      };

    var viewer = data.viewer;

    var projectId = data.projectId;
    var project =
      getState().getNested(["projects", "map", projectId]) ||
      getState().getNested(["projects", "detailsMap", projectId]);
    var projectTitle = project ? project.title || project.address : null;

    var company = getState().getNested(
      ["companies", "map", viewer.companyId],
      {}
    );
    var companyTypes =
      company.getNested(["projects", projectId, "types"]) &&
      !company.getNested(["projects", projectId, "isDeleted"])
        ? company.getNested(["projects", projectId, "types"])
        : company.getNested(["types"]);

    var user = {};
    //user.id = viewer.id;
    //user.displayName = viewer.displayName;
    user.userTrades = [];
    user.userTitleId = viewer.title;
    user.userTradesIds = Object.values(viewer.trades || {});
    user.userCompanyId = viewer.companyId;
    user.userCompanyName = company.name;
    user.userCompanyTypes = Object.values(companyTypes || {});
    user.userTitle = getState().getNested(
      ["titles", "map", user.userTitleId, "systemTitle"],
      ""
    );
    user.userTradesIds.loopEach((i, id) => {
      user.userTrades.push(
        getState().getNested(["trades", "map", id, "systemTitle"], "")
      );
    });

    return {
      type: ON_SET_VIEWER_SCOPE,
      payload: { superProperties: { ...user, projectId, projectTitle } },
    };
  };
}

export function track(title, props, withMixpanelCounter) {
  return ({ sentry, writeLog, firebaseAnalytics }) => {
    if (!title)
      return {
        type: BAD_TRACK,
        payload: {},
      };

    writeLog("info", title, "mixpanelTrack", props);
    sentry.addBreadcrumb({
      category: 'mixpanelTrack',
      message: title.slice(0, 30),
      level: 'info',
      data: props,
      timestamp: Date.now(),
    });

    //let copyJson = {};
    //if (props && typeof props === 'object') {
    //	props = (props.set && props.get && props.get('id')) ? props.set(props.get('id')) : props;
    //	copyJson = props.toJS ? props.toJS() : props;
    //	copyJson = deepCopy(copyJson)
    //}
    return {
      type: TRACK,
      payload: { title, props, withMixpanelCounter },
    };
  };
}
