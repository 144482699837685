import { useCallback, useContext } from 'react';
import {ProjectContext} from '../../common/projects/contexts';
import * as permissionsFunc from '../../common/permissions/funcs';
import {useSelector} from 'react-redux';

const usePermissions = () => {
  const { selectedProjectId, viewer } = useContext(ProjectContext);
  const { permissions } = useSelector((state) => ({
    permissions: state.permissions.map,
  }))
  const checkPermissions = useCallback((permissionKey, actionKey, target) => {
    return permissionsFunc.getActionPermissions(viewer, selectedProjectId, permissionKey, actionKey, target);
  }, [selectedProjectId, viewer, permissions]);

  return {
    checkPermissions,
  };
};

export default usePermissions;