import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _, { update } from 'lodash';
import { envParams, firebaseDeps } from '../configureMiddleware';
import { createPost, updatePost } from '../posts/funcs';
import { uploadPropertiesInstances } from '../propertiesInstances/actions';
import { uploadFailedChecklistItemInstance } from '../checklistItemsInstances/funcs';
import { hideLoading, startLoading } from '../app/actions';
import systemMessages from '../app/systemMessages';
import { track } from '../lib/reporting/actions';
import { debugParams, writeLogOnce } from '../lib/utils/utils';
import { isContainExistingLocalFiles } from '../propertiesInstances/funcs';
import { updateUsingFirebaseProxy } from '../lib/api';

export const ON_CHECK_APP_VERSION = 'ON_CHECK_APP_VERSION';
export const GET_SYNCED = 'GET_SYNCED';
export const SYNCED = 'SYNCED';
export const END_SYNC_LISTENER = 'END_SYNC_LISTENER';
export const GET_LAST_UPDATES = 'GET_LAST_UPDATES';

let map = {}

function bouncerWrapper(key, callback) {
	let exists = map[key]
	if (exists) 
		return debouncedFunc(callback, key);
	else {
		map[key] = true;
		return callback?.()
	}
}

function debouncedFuncWrapper(callback, key) {
	return callback?.()
}

const debouncedFunc = AwesomeDebouncePromise(debouncedFuncWrapper, 500);

export function startSyncListener(viewerId) {
	return ({ firebaseDatabase, dispatch }) => {
		const getPromise = async () => {
			const path = `lastUpdates/sync/${viewerId}`;
			firebaseDatabase().ref(path).on('value', (snapshot) => {
				writeLogOnce('info', 'startSyncListener - listener Firebase', {
					viewerId,
					scope: 'global',
					type: 'lastUpdatesV1',
				});
				bouncerWrapper(path, () => {
					const data = snapshot.val();
					let global = null;
					if (data?.global) {
						global = { ...data.global };
						delete data.global
					}
					return dispatch({ type: GET_SYNCED, payload: { global, projects: data } });
				});
			});
		};

		return {
			type: GET_SYNCED,
			payload: getPromise()
		};
	};
}

export function endSyncListener(viewerId) {
	return ({ firebaseDatabase }) => {

		const getPromise = async () => {
			firebaseDatabase()
				.ref(`lastUpdates/sync/${viewerId}`)
				.off('value');
		};

		return {
			type: END_SYNC_LISTENER,
			payload: getPromise()
		};
	};

}


function getLocalObjectsForSync(realmInstance, objectType, projectId, isLocalOnly, locationId) {
	let query = `projectId = "${projectId}" `;
	if (isLocalOnly) query += ' AND isLocal = true';

	let instances, retryInstances;

	switch (objectType) {
		case 'posts':
			if (locationId) query += ` AND ( location.building.id = "${locationId}" OR location.floor.id = "${locationId}" OR location.unit.id = "${locationId}" )`;
			instances = realmInstance.posts.objects('post24').filtered(query);
			retryInstances = realmInstance.retry_posts.objects('post24').filtered(query);
			break;

		case 'checklistInstances':
			if (locationId) query += ` AND locationId = "${locationId}"`;
			instances = realmInstance.checklistItemsInstances.objects('checklistItemsInstance1').filtered(query);
			retryInstances = realmInstance.retry_checklistItemsInstances.objects('checklistItemsInstance1').filtered(query);
			break;

		case 'propertiesInstances':
			if (locationId) 
				query += ` AND ((parentId = "${locationId}" AND subjectName = "locationsInfo") OR (subjectName = "checklistItemsInfo"))`;

			instances = realmInstance.propertyInstances.objects('propertyInstance1').filtered(query);
			instances = instances.map(i => {
				try {
					if (!i)
						return;
					let realmObj = i.realmToObject();
					let data = JSON.parse(realmObj.data);
					let ret = { ...realmObj, data };
					return ret;
				}
				catch (err) {
					console.log('sync error', err);
					return;
				}
			}).filter(x => Boolean(x));

			break;
	}

	let localInstances = {}
	_.forIn(instances, instance => localInstances[instance.id] = instance.realmToObject());
	_.forIn(retryInstances, instance => localInstances[instance.id] = instance.realmToObject());

	return localInstances;
}

const isSomeLocalFilesExist = async (subjectInstances) => {
	for (const key in subjectInstances) {
		const instance = subjectInstances[key];
		const isLocalFilesExist = await isContainExistingLocalFiles(instance?.data);
		if (isLocalFilesExist) {
			return true;
		}
	}
	return false;
};

export function syncWithDB(objectType, viewer, projectId, syncParams) {
	return ({ realmInstance, firebase, platformActions, dispatch }) => {
		const actionType = `${SYNCED}_${_.toUpper(_.snakeCase(objectType))}`;
		
		const getPromise = async () => {
			if (platformActions.app.getPlatform() === 'web') {
				return { projectId, lastSynced: 0 };
			}

			if (!viewer || !viewer.id)
				throw ('missing viewer id');

			let loadingOpId = `sync_${Date.now}`;
			dispatch(startLoading({ title: systemMessages.syncing, overlay: true, operationId: loadingOpId }));

			const { lastUpdateTS: syncTS, onlyDiff = true, shouldUpload, log = true, locationId, shouldThrowOnLocalFiles } = syncParams;

			let eventProps = { objectType, projectId, syncParams:Object.assign({}, syncParams), viewer: { displayName: viewer.displayName, id: viewer.id } };
			let firebaseLog = { metadata: { syncParams } };
			let allLocalInstances = getLocalObjectsForSync(realmInstance, objectType, projectId, false, locationId);
			let onlyIsLocalInstances = _.pickBy(allLocalInstances, i => i.isLocal);
			dispatch(track('syncWithDB - start checking', eventProps));

			// // faking locally stuck Object:
			// localInstances.someFakeObjectId = { ..._.last(_.values(localInstances)), id: "someFakeObjectId", isLocal: true };
			// //

			if (log) {
				_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'total'], _.values(allLocalInstances).length);
				_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'beforeSync'], _.values(onlyIsLocalInstances).length);
			}

			if (onlyDiff) {
				// fetch remote instances
				let url = `${envParams.apiServer}/v1/${objectType}?projectId=${projectId}&fields=${JSON.stringify(['id', 'updatedTS'])}`;

				let remoteInstances = {};

				if (objectType == 'propertiesInstances') {
					let subjectsArray = realmInstance.propertyInstances.objects('propertyInstance1').filtered(`projectId = "${projectId}" DISTINCT(subjectName)`).map(x => x.subjectName);
					const propertiesSubjects = locationId ? ['locationsInfo', 'checklistItemsInfo'] : subjectsArray;

					await Promise.all(propertiesSubjects.map(async subjectName => {
						let currUrl = url + `&subjectName=${subjectName}`;
						if (subjectName == 'locationsInfo' && locationId)
							currUrl += `&locationId=${locationId}`;

						let subjectInstances = await platformActions.net.fetch(currUrl);
						subjectInstances = await subjectInstances.getJson();
						_.assign(remoteInstances, subjectInstances);
					}));
				} else {
					remoteInstances = await platformActions.net.fetch(url);
					remoteInstances = await remoteInstances.getJson();
				}
				
				if (log)
					_.set(firebaseLog, ['metadata', 'stats', 'remoteInstances', 'total'], _.values(remoteInstances).length);

				// filter only local that is different
				allLocalInstances = _.pickBy(allLocalInstances, currLocal => {
					let currRemote = _.get(remoteInstances, [currLocal.id]);
					return (!currRemote || currLocal.isLocal);
				});

				if (log) {
					_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'diff', 'total'], _.values(allLocalInstances).length);
					_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'diff', 'isLocal'], _.values(allLocalInstances).filter(i => i.isLocal).length);
				}

			}

			if (log) {
				let stringifiedAllLocalInstances = {};
				_.forEach(allLocalInstances, currLocalInstance => {
					stringifiedAllLocalInstances[currLocalInstance.id] = JSON.parse(JSON.stringify(currLocalInstance));
				});

				_.set(firebaseLog, ['instances'], stringifiedAllLocalInstances);
			}

			let uploadSuccess = false;
			if (shouldUpload) { // upload local instances
				try {
					switch (objectType) {
						case 'posts':
							await Promise.all(
                _.values(allLocalInstances).map(async (post) => {
                  const crudAction = post.editedAt ? updatePost : createPost;
                  await crudAction(viewer, projectId, post, true);
                })
              );
							break;

						case 'checklistInstances':
							await Promise.all(_.values(allLocalInstances).map(async checklistInstance => await uploadFailedChecklistItemInstance(checklistInstance)));
							break;

						case 'propertiesInstances': {
							let groupedBySubjectName = _.groupBy(allLocalInstances, i => i.subjectName);
							await Promise.all(_.toPairs(groupedBySubjectName).map(async ([subjectName, subjectInstances]) => {
								const isSomeFilesLocal = await isSomeLocalFilesExist(subjectInstances);
								if (isSomeFilesLocal && shouldThrowOnLocalFiles) {
									throw ("some instances contain url of local files");
								}
								await dispatch(uploadPropertiesInstances(projectId, _.values(subjectInstances), subjectName));
							}
							));
							break;
						}
					}
					uploadSuccess = true;
					if (log) {
						let localObjectsAfterUpload = getLocalObjectsForSync(realmInstance, objectType, projectId, true, locationId);
						_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'afterSync', 'count'], _.values(localObjectsAfterUpload).length);

						let stringifiedCurrLocalInstance = {};
						_.forEach(allLocalInstances, currLocalInstance => {
							stringifiedCurrLocalInstance[currLocalInstance.id] = JSON.parse(JSON.stringify(currLocalInstance));
						});
						_.set(firebaseLog, ['metadata', 'stats', 'localInstances', 'isLocal', 'afterSync', 'instances'], stringifiedCurrLocalInstance);
					}
				}
				catch (err) {
					uploadSuccess = false;
					if (log)
						_.set(firebaseLog, ['metadata', 'uploadError'], JSON.stringify(err));
					if (process.env.NODE_ENV != "production")
						console.error(`sync with db failed - ${objectType}`, err);
				}
				finally {
					if (log) {
						_.set(firebaseLog, ['metadata', 'actualSyncTS'], Date.now());
						_.set(firebaseLog, ['metadata', 'uploadSuccess'], uploadSuccess);
					}
					eventProps.uploadSuccess = uploadSuccess;
				}
			}


			/// save that shit to a separate firebase object in /sync/{viewer.id}/{projectId}/{type} = { metadata, instances }
			if (log) {
				const deviceName = platformActions.app.getPlatform() == "web" ? 'desktop' : platformActions.app.getModel() + "_" + platformActions.app.getUniqueID();
				const logPath = `_internal/logs/instancesSync/${viewer.id}/${projectId}/${objectType}/${syncTS}/${deviceName}`;
				eventProps.logPath = logPath;
				
        await updateUsingFirebaseProxy({
          enableFirebaseProxy: true,
          updates: { [logPath]: firebaseLog },
        });
        // firebase.update({ [logPath]: firebaseLog });
        dispatch(track('syncWithDB - sync finished', eventProps));
			}

			dispatch(hideLoading(loadingOpId));
			return { projectId, lastSynced: syncTS };
		};


		return {
			type: actionType,
			payload: getPromise()
		};
	};
}