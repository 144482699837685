class Post {
  constructor({
    assignTo,
    attachments,
    checklistItemInstance,
    comments,
    commentsCounter,
    createdAt,
    description,
    dueDate,
    editedAt,
    fine,
    id,
    images,
    isDeleted,
    isIssue,
    isReported,
    issueState,
    location,
    owner,
    refs,
    requiredAction,
    severity,
    subCategory,
    taggedCompanies,
    title,
    trade,
    type,
    updatedTS,
    projectId,
  }) {
    this.assignTo = assignTo;
    this.projectId = projectId;
    this.attachments = attachments;
    this.checklistItemInstance = checklistItemInstance;
    this.comments = comments;
    this.commentsCounter = commentsCounter;
    this.createdAt = createdAt;
    this.description = description;
    this.dueDate = dueDate;
    this.editedAt = editedAt;
    this.fine = fine;
    this.id = id;
    this.images = images;
    this.isDeleted = isDeleted;
    this.isIssue = isIssue;
    this.isReported = isReported;
    this.issueState = issueState;
    this.location = location;
    this.owner = owner;
    this.refs = refs;
    this.requiredAction = requiredAction;
    this.severity = severity;
    this.subCategory = subCategory;
    this.taggedCompanies = taggedCompanies;
    this.title = title;
    this.trade = trade;
    this.type = type;
    this.updatedTS = updatedTS;
  }
}

export default Post;