import React from "react";
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { signOut } from "../../../common/auth/actions";
import authMessages from "../../../common/auth/authMessages";
import buttonsMessages from "../../../common/app/buttonsMessages";
import theme from "../../../common/app/theme";
import Button from "../../components/CustomButtons/Button";

function InvalidTokenIssuer(props) {
  const { intl, signOut } = props;
  return (
    <div>
      <div style={{ flexDirection: "column", margin: theme.margin * 3 }}>
        <h3
          style={{
            fontWeight: "bold",
            fontSize: theme.fontSizeH3,
            color: theme.brandPrimary,
          }}>
          {intl.formatMessage(authMessages.invalidTokenIssuer.title)}
        </h3>
        <br />
        <h5 style={{ fontSize: theme.fontSizeH4, lineHeight: 1.4 }}>
          {intl.formatMessage(authMessages.invalidTokenIssuer.body)}
        </h5>
        <Button
        round
        onClick={async () => await signOut()}
        key="signOut"
        aria-label="Sign Out" >
        {intl.formatMessage(buttonsMessages.signOut)}
      </Button>
      </div>
    </div>
  )
}

InvalidTokenIssuer = connect(null, { signOut })(InvalidTokenIssuer);
InvalidTokenIssuer = injectIntl(InvalidTokenIssuer);
export default InvalidTokenIssuer;