import React from 'react';
import configureReducer from './configureReducer';
import configureMiddleware from './configureMiddleware';
import { compose, applyMiddleware, createStore } from 'redux';

export default function configureStore(options) {
  const {
    initialState,
    platformDeps = {},
    platformMiddleware = [],
    platformReducers = {},
    standardInputComponent = <></>,
    containerComponent = () => null,
    textComponent = () => null,
    tradeComponent = () => null,
    theme = {},
  } = options;

  const reducer = configureReducer(
    initialState,
    platformReducers
  );

  const middlewares = configureMiddleware(
    initialState,
    platformDeps,
    platformMiddleware,
    standardInputComponent,
    containerComponent,
    textComponent,
    tradeComponent,
    theme,
  );

    const middleware = applyMiddleware(...middlewares);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
    reducer,
    initialState,
    composeEnhancers(middleware)
  );

  // Enable hot reload where available.
  if (module.hot) {
    const replaceReducer = configureReducer =>
      store.replaceReducer(configureReducer(initialState, platformReducers));

    if (initialState.device.isReactNative) {
      module.hot.accept(() => {
        replaceReducer(require('./configureReducer').default);
      });
    } else {
      module.hot.accept('./configureReducer', () => {
        replaceReducer(require('./configureReducer'));
      });
    }
  }

  return store;
}
