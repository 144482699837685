import { Record } from '../transit';

const Drawing = Record({
	type: null,
	id: null,
	category: null,
	ext: null,
	title: null,
	date: null,
	uriThumbnail: null,
	uri: null,
	uriPdf: null,
	version: null,
	number: null,
	locationType: null,
	specificLocations: null,
	updatedTS: null,
	'-floorsTags': null,
	'-unitsTags': null,
	'-buildingsTags': null,
}, 'drawing');

export default Drawing;
