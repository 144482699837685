import { defineMessages } from 'react-intl';

export default defineMessages({
  intl: {
    defaultMessage: 'Intl',
    id: 'app.links.intl',
  },
  fields: {
    defaultMessage: 'Fields',
    id: 'app.links.fields',
  },
  firebase: {
    defaultMessage: 'Firebase',
    id: 'app.links.firebase',
  },
  home: {
    defaultMessage: 'Home',
    id: 'app.links.home',
  },
  signIn: {
    defaultMessage: 'Sign in',
    id: 'app.links.signIn',
  },
  me: {
    defaultMessage: 'Me',
    id: 'app.links.me',
  },
  offline: {
    defaultMessage: 'Offline',
    id: 'app.links.offline',
  },
  projects: {
    defaultMessage: 'Projects',
    id: 'app.links.projects',
  },
  project: {
    defaultMessage: 'Project',
    id: 'app.links.project',
  },
  newProject: {
    defaultMessage: 'New Project',
    id: 'app.links.newProject'
  },
  editProject: {
    defaultMessage: 'Edit Project',
    id: 'app.links.editProject'
  },
  newPost: {
    defaultMessage: 'New Record',
    id: 'app.links.newPost'
  },
  newIssue: {
    defaultMessage: 'New Task',
    id: 'app.links.newIssue'
  },
  postToIssue: {
    defaultMessage: 'Convert message to task',
    id: 'app.links.postToIssue'
  },
  contacts: {
    defaultMessage: 'Contacts',
    id: 'app.links.contacts'
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'app.links.comments'
  },
  projectMembers: {
    defaultMessage: 'Project Members',
    id: 'app.links.projectMembers'
  },
  postPage: {
    defaultMessage: 'Details',
    id: 'app.links.postPage'
  },
  userPage: {
    defaultMessage: 'Member Page',
    id: 'app.links.userPage'
  },
  editProfile: {
    defaultMessage: 'Edit Profile',
    id: 'app.links.editProfile'
  },
  suppliers: {
    defaultMessage: 'Suppliers',
    id: 'app.links.Suppliers'
  },
});
