import React from "react";
import theme from "../../../assets/css/theme";
import Text from "../Text";
import { BORDER } from ".";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";

const CategoryTab = ({
  title,
  valuesSlot,
  onClick,
  isActive,
  mainContainerStyle = {},
  rtl,
}) => {
  return (
    <div
      style={{
        ...styles.mainContainer,
        borderBottom: BORDER,
        ...(isActive ? styles.mainContainerActive : {}),
        ...(mainContainerStyle || {}),
      }}
      onClick={() => Boolean(onClick) && onClick()}
    >
      <div
        style={{
          ...styles.borderContainer,
          [`margin${rtl ? "Right" : "Left"}`]: theme.verticalMargin + 3,
        }}
      >
        <div
          style={{
            ...styles.titleContainer,
            ...(isActive ? styles.titleContainerActive : {}),
          }}
        >
          <Text style={{ ...styles.title }}>{title}</Text>
        </div>
        {Boolean(valuesSlot) && (
          <div
            style={{
              ...styles.valuesSlotContainer,
              ...(isActive ? styles.valuesSlotActive : {}),
            }}
          >
            {valuesSlot}
          </div>
        )}
      </div>
    </div>
  );
};

const enhance = compose(
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {}
  )
);

export default enhance(CategoryTab);

const CONTAINER_WIDTH = "98%";

const styles = {
  mainContainer: {
    height: 80,
    cursor: "pointer",
    width: CONTAINER_WIDTH,
    backgroundClip: "padding-box",
  },
  mainContainerActive: {
    backgroundColor: theme.brandPrimary,
  },
  borderContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    height: "100%",
    padding: theme.paddingSize,
    backgroundColor: "white",
  },
  titleContainer: {
    fontWeight: theme.strongBold,
    fontSize: theme.fontSizeH6,
  },
  titleContainerActive: {
    // color: theme.backgroundColorBright,
  },
  title: {
    whiteSpace: "pre-wrap",
  },
  valuesSlotContainer: {},
  valuesSlotActive: {},
};
