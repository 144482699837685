import React, { useEffect } from 'react';
import useDrawings from './useDrawings';

const DrawingsSubscriber = () => {
  const { subscribeToDrawings, unsubscribeFromDrawings } = useDrawings();

  useEffect(() => {
    subscribeToDrawings();

    return () => {
      unsubscribeFromDrawings();
    }
  }, []);

  return null;
};

export default DrawingsSubscriber;